import PropTypes from 'prop-types';

export default function CheckMark({ colour }) {
  const colourOptions = {
    successGreen: 'text-green-400',
    default: 'text-green-400',
  };

  const colourClassName = colourOptions?.[colour] || colourOptions.default;

  return (
    <div className="flex items-center">
      <i className={`fas fa-check ${colourClassName}`} />
    </div>
  );
}

CheckMark.propTypes = {
  colour: PropTypes.oneOf(['successGreen']),
};
