import classnames from 'classnames';
import PropTypes from 'prop-types';

export default function TabButton({ children, onClick, isActive, classNames }) {
  const {
    button: buttonClassNames,
    isActive: isActiveClassName,
    isNotActive: isNotActiveClassName,
    buttons: buttonsClassNames,
  } = classNames || {};

  return (
    <button
      type="button"
      onClick={onClick}
      className={classnames(
        'font-medium whitespace-nowrap w-full text-xs leading-5 transition ease-in-out duration-150',
        {
          [isActiveClassName]: isActive,
          [isNotActiveClassName]: !isActive,
        },
        buttonClassNames,
        buttonsClassNames
      )}
    >
      {children}
    </button>
  );
}

export const tabButtonProps = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  classNames: PropTypes.shape({
    buttons: PropTypes.string,
    button: PropTypes.string,
    isActive: PropTypes.string,
    isNotActive: PropTypes.string,
  }),
};

TabButton.propTypes = {
  ...tabButtonProps,
};
