/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import { useQuery } from '@redwoodjs/web';
import {
  LoadingComponent as DefaultLoadingComponent,
  Error,
  EmptyComponent as DefaultEmptyComponent,
} from 'src/components/Generic';
import { EMPTY_QUERY } from '../generic/emptyQuery';

const dataField = (data) => {
  return data[Object.keys(data)[0]];
};

const dataKeyInsideFirstField = (data) => {
  return data[Object.keys(data)[0]].data;
};

const isDataNull = (data) => {
  return dataField(data) === null;
};

const isDataEmptyArray = (data) => {
  const field = dataField(data);
  const objInField = dataKeyInsideFirstField(data);
  return (Array.isArray(field) && field.length === 0) || (Array.isArray(objInField) && objInField.length === 0);
};

const isEmpty = (data) => {
  return isDataNull(data) || isDataEmptyArray(data);
};

export const useApolloQuery = (props) => {
  const {
    initialQuery,
    queryParams,
    LoadingComponent = DefaultLoadingComponent,
    EmptyComponent = DefaultEmptyComponent,
    emptyComponentProps,
  } = props;

  const query = initialQuery || EMPTY_QUERY;

  const { loading, error, data, fetchMore } = useQuery(query, queryParams);
  // Sometimes the fetchMore function given from Apollo is called after the refreshing of useQuery
  // Other times useQuery is not being refreshed. With this I am sure that when is pagination
  // And the scroll length is modulus the data chunk total, it is not going to retrieve empty component
  const isFirstOffset = queryParams?.variables?.offset === 0 || !queryParams?.variables?.offset;

  const { fetchValues, hasFetchParamsChanged } = useUpdateFetchStates({
    useQueryValues: { loading, data, fetchMore },
    useQueryParams: queryParams,
  });

  if (!initialQuery) return props;

  return {
    ...props,
    data,
    fetchMore,
    loading,
    isFirstOffset,
    error,
    emptyComponentProps,
    LoadingComponent,
    EmptyComponent,
    fetchValues,
    hasFetchParamsChanged,
  };
};

const withApolloQuery = (Component, queryProps) => (props) => {
  return <WrappedComponent Component={Component} {...props} {...queryProps} />;
};

const WrappedComponent = ({ Component, ...props }) => {
  const newProps = useApolloQuery(props);
  const {
    loading,
    isFirstOffset,
    error,
    LoadingComponent,
    Error,
    EmptyComponent,
    data,
    emptyComponentText,
    emptyComponentProps,
    onlyRetrieveWithParamsChange,
    hasFetchParamsChanged,
  } = newProps;

  console.log('newProps', newProps);
  if (onlyRetrieveWithParamsChange && !hasFetchParamsChanged) return <Component {...props} {...newProps} />;
  if (loading && isFirstOffset) return <LoadingComponent classes="h-full" />;
  if (error) return <Error errorMessage={error.message} message={error.message} />;
  if (isFirstOffset && isEmpty(data)) return <EmptyComponent text={emptyComponentText} {...emptyComponentProps} />;
  return <Component {...props} {...newProps} />;
};

export default withApolloQuery;

withApolloQuery.propTypes = {
  queryParams: PropTypes.shape({
    fetchPolicy: PropTypes.string,
    variables: PropTypes.shape({
      offset: PropTypes.number,
      limit: PropTypes.number,
    }),
  }),
};

function useUpdateFetchStates({ useQueryValues, useQueryParams, useQueryValues: { data } }) {
  /**
   * There are some really strange edge cases in which useQuery is re-render without even
   * giving a loading status.
   * One example of this is when duplicating a row from the UI in the funnel list, that row
   * is also then taken to the db. When trying to paginate useQuery is called again, and breaking
   * the pagination https://www.loom.com/share/fe0bdb14396049f88c7be64d8ed8eb84
   */

  const [fetchValues, setFetchValues] = useState({});
  const [fetchParams, setFetchParams] = useState({});

  const hasFetchParamsChanged = !isEqual(useQueryParams, fetchParams);

  useEffect(() => {
    if (data && hasFetchParamsChanged) {
      setFetchValues(useQueryValues);
      setFetchParams(useQueryParams);
    }
  }, [data, fetchParams, fetchValues, hasFetchParamsChanged, useQueryParams, useQueryValues]);

  return {
    fetchValues,
    fetchParams,
    hasFetchParamsChanged,
  };
}
