/* global gql */

import { routes, Redirect } from '@redwoodjs/router';
import PropTypes from 'prop-types';

import DashboardBoxes from '../Dashboard/DashboardBoxes/DashboardBoxes';

export const QUERY = gql`
  query retrieveDefaultDashboard($accountId: String!, $dashboardId: String) {
    retrieveDefaultDashboard(accountId: $accountId, dashboardId: $dashboardId) {
      id
      layout
      hasEventsStream
      name
      defaultTimerange
      settings
      isDefaultDashboard
    }
    getDashboardNames(accountId: $accountId)
  }
`;

export const beforeQuery = ({ accountId, dashboardId }) => {
  return {
    variables: dashboardId ? { accountId, dashboardId } : { accountId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  };
};

export const Loading = () => <span />;

export const Empty = () => <div>Empty</div>;

export const Failure = ({ dashboardId }) => {
  return dashboardId ? <Redirect to={routes.dashboard()} /> : <DashboardBoxes />;
};

export const Success = ({ retrieveDefaultDashboard, getDashboardNames, dashboardId }) => {
  return (
    <DashboardBoxes
      defaultDashboard={retrieveDefaultDashboard}
      dashboardNames={getDashboardNames}
      dashboardId={dashboardId}
    />
  );
};

Failure.propTypes = {
  dashboardId: PropTypes.any,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

Success.propTypes = {
  retrieveDefaultDashboard: PropTypes.any,
  getDashboardNames: PropTypes.any,
  dashboardId: PropTypes.any,
};
