import { useState, useRef, useEffect, useCallback } from 'react';
import { isBrowser } from '@redwoodjs/prerender/browserUtils';
import 'src/css/splitPane.css';

// Class applied in <html> in index.html
export const DO_NOT_CLOSE_PANE_CLASSNAME = 'noCloseRightPane';
const RIGHT_DEFAULT_SIZE = isBrowser ? window.innerWidth / 2.4 : 0;
const noop = () => {};

/* ------------------------------------------------------------------------------------------------
 globalRecoil = {
    isRightPaneOpen,
    setIsRightPaneOpen,
    rightPaneDefaultSize,
    setRightPaneDefaultSize,
 }
 * -----------------------------------------------------------------------------------------------*/

export default function useRightPane({
  initialOpenState,
  initialType = null,
  rightDefaultSize = RIGHT_DEFAULT_SIZE,
  minWidth = 30,
  globalRecoil: gr,
} = {}) {
  const [isRightPaneOpen, setIsRightPaneOpen] = useState(initialOpenState);
  const initialSize = initialOpenState ? rightDefaultSize : 0;

  const [rightPaneDefaultSize, setRightPaneDefaultSize] = useState(initialSize);
  const [rightPaneType, setRightPaneType] = useState(initialType);

  const rightPaneRef = useRef();
  const resizer = document.querySelector('.Resizer.vertical');

  const repositionResizer = (current) => {
    if (isRightPaneOpen) {
      let width = rightDefaultSize;
      if (current) {
        width = current.offsetWidth;
      }

      if (resizer) {
        resizer.style.position = 'fixed';
        resizer.style.height = '100%';
        resizer.style.right = `${width}px`;
      }
    }
  };

  /* Trigger reposition Resizer when a new instance of rightPane is mounted */
  useEffect(() => {
    repositionResizer(rightPaneRef.current);
  }, [rightPaneRef.current]);

  /* Hide Resizer if rightPane is closed */
  useEffect(() => {
    if (resizer) {
      if (isRightPaneOpen) {
        resizer.style.display = 'block';
      } else {
        resizer.style.display = 'none';
      }
    }
  }, [isRightPaneOpen]);

  const rightPaneTransition = 'right 0.3s ease 0s';
  const rightPaneMinWidth = `${minWidth}vw`;
  const rightPaneStyles = {
    height: '100vh',
    backgroundColor: '#131D27',
    transition: rightPaneTransition,
    position: 'fixed',
    top: 0,
    right: '0',
    zIndex: 9999,
  };

  const onRightPaneDragStarted = () => {
    rightPaneStyles.transition = null;
    rightPaneStyles.minWidth = rightPaneMinWidth;
    repositionResizer(rightPaneRef.current);
  };

  const onRightPaneDragFinished = () => {
    rightPaneStyles.transition = rightPaneTransition;
    rightPaneStyles.minWidth = null;
    repositionResizer(rightPaneRef.current);
  };

  const onRightPaneOpen = useCallback(({ beforeOpen = noop, type = null } = {}) => {
    beforeOpen();

    setRightPaneDefaultSize(rightDefaultSize);
    if (gr && gr.setRightPaneDefaultSize) gr.setRightPaneDefaultSize(rightDefaultSize);

    setIsRightPaneOpen(true);
    if (gr && gr.setIsRightPaneOpen) gr.setIsRightPaneOpen(true);

    setRightPaneType(type)
    if (gr && gr.setRightPaneType) gr.setRightPaneType(type);

  }, []);

  const onRightPaneClose = useCallback(({ beforeClose = noop } = {}) => {
    beforeClose();

    setRightPaneDefaultSize(0);
    if (gr) gr.setRightPaneDefaultSize(0);

    setIsRightPaneOpen(false);
    if (gr) gr.setIsRightPaneOpen(false);
  }, []);

  const onOutsideClickResizerConditionHandler = (ev) => {
    return !ev.target.classList.contains('Resizer') && !ev.target.classList.contains(DO_NOT_CLOSE_PANE_CLASSNAME);
  };

  const onExtraConditionHandlerForPressingOutside = (ev, arrOfExceptionElements) => {
    ev.stopPropagation();

    const wasClickedInOneSelectedElement = arrOfExceptionElements
      .map((element) => {
        return element ? !element.contains(ev.target) : true;
      })
      .includes(false);

    // To let the user pres the resizer to drag, and not close the Pane
    return onOutsideClickResizerConditionHandler(ev) && !wasClickedInOneSelectedElement;
  };

  return {
    isRightPaneOpen,
    setIsRightPaneOpen,
    rightPaneType,
    setRightPaneType,
    rightPaneDefaultSize,
    setRightPaneDefaultSize,
    rightPaneOpenDefaultSize: rightDefaultSize,
    rightPaneStyles,
    onRightPaneDragStarted,
    onRightPaneDragFinished,
    onRightPaneOpen,
    onRightPaneClose,
    recoilGlobalState: gr,
    onOutsideClickResizerConditionHandler,
    onExtraConditionHandlerForPressingOutside,
    rightPaneRef,
  };
}
