import { useEffect, useMemo } from 'react';
import { routes, navigate } from '@redwoodjs/router';
import { useAuth } from '@redwoodjs/auth';
import { useTracking } from 'src/layouts/AppLayout/useAppLayout';
import AppLayoutSkeleton from 'src/layouts/AppLayout/AppLayoutSkeleton';
import { isBrowser } from '@redwoodjs/prerender/browserUtils';
import PropTypes from 'prop-types';

export default function LoginPage({ authTypeRedirection = 'login' }) {
  const { logIn, isAuthenticated, loading, signUp } = useAuth();

  const searchParams = useMemo(() => {
    const search = isBrowser ? window.location.search : '';
    return new URLSearchParams(search);
  }, []);

  useTracking();

  const auth0Redirection = () => {
    const appState = { targetUrl: searchParams.get('redirectTo'), n: new Date().getTime() };

    return authTypeRedirection === 'signup'
      ? signUp({ appState, prompt: 'signup' })
      : logIn({ appState, prompt: 'login' });
  };

  useEffect(() => {
    const notLogin = searchParams.get('notlogin');
    if (!loading && isAuthenticated) {
      navigate(routes.dashboard());
    }
    if (!notLogin) {
      if (!isAuthenticated) {
        setTimeout(auth0Redirection, 200);
      }
    }
  }, [loading, isAuthenticated, searchParams]);

  if (loading) {
    return <AppLayoutSkeleton />;
  }

  const authType = authTypeRedirection === 'signup' ? 'sign up' : 'login';
  const description = `Redirecting to ${authType} page...`;

  return (
    <div className="w-full h-screen box-border flex flex-wrap bg-wmxBgDark-300">
      <div className="w-full h-full flex flex-col">
        {/* Width and height fixed for browser compatibility */}
        <div className="flex justify-center pt-0 mb-0 w-16 h-16 m-auto">
          <img alt="Wild Audience Logo" src="/logo-white.png" />
        </div>
        <div className="flex flex-col justify-center m-auto mt-12 pt-8 md:pt-0 px-8 md:px-24 lg:px-32 text-white">
          <p className="font-sans text-center text-4xl mb-5">
            Welcome to <strong>Wildmetrics.</strong>
          </p>
          <p className="font-sans text-center text-md mb-5">{description}</p>
        </div>
      </div>
    </div>
  );
}

LoginPage.propTypes = {
  authTypeRedirection: PropTypes.oneOf(['login', 'signup']),
};
