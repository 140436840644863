import PropTypes from 'prop-types';

export default function TableButton({ onClick, children }) {
  return (
    <button type="button" className="group h-full w-full text-left" onClick={onClick}>
      <span className="p-2 rounded-md group-hover:bg-wmxPrimary-200">{children}</span>
    </button>
  );
}

TableButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
};
