/* global gql */

export const DELETE_ACCOUNT_MUTATION = gql`
  mutation DeleteAccountMutation($accountIdToDelete: String!, $disconnectAuth0: Boolean) {
    startDeleteAccountOrchestator(accountIdToDelete: $accountIdToDelete, disconnectAuth0: $disconnectAuth0) {
      accountId
    }
  }
`;

export const REST_INTEGRATION_MUTATION = gql`
  mutation trigerRestIntegration($accountId: String!, $turnToPro: Boolean) {
    trigerRestIntegration(accountId: $accountId, turnToPro: $turnToPro) {
      id
    }
  }
`;

export const REACTIVATE_TRIAL = gql`
  mutation reactivateTrial {
    reactivateTrial {
      id
    }
  }
`;
