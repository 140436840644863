/* eslint-disable react/prop-types */

import useLocalStorage from 'src/customHooks/useLocalStorage';
import { useUpdateIntegrationLocalStorage } from 'src/components/IntegrationTopic/integrationsHelpers';
import { INTEGRATION_STATUSES, PROVIDERS } from 'wmx-shared-code/accountGlobalVariables';
import { isAllowed } from 'wmx-shared-code/accountGlobalMethods.cjs';

import { Error, ProfileStripeLogo } from 'src/components/Generic';
import IntegrationStatusCard from 'src/components/IntegrationTopic/IntegrationStatusCard/IntegrationStatusCard';
import useAccount from 'src/customHooks/useAccount';

import { GET_STRIPE_INTEGRATION } from 'src/components/IntegrationTopic/integrationQueries';

export const QUERY = GET_STRIPE_INTEGRATION;

export const beforeQuery = (props) => {
  if (props.stripeStatus !== INTEGRATION_STATUSES.notConnected || !!props.shouldBeCached) {
    return { variables: props, fetchPolicy: 'cache-first' };
  }
  return { variables: props, fetchPolicy: 'network-only' };
};

export const Loading = ({ withStatus = true }) => {
  const { accountType } = useAccount();
  const allowed = isAllowed({ plan: accountType, provider: PROVIDERS.stripe });

  const [lastSession] = useLocalStorage('stripeIntegration');
  const { status } = lastSession || {};

  return lastSession ? (
    <IntegrationStatusCard
      status={status}
      withStatus={withStatus}
      provider={PROVIDERS.stripe}
      hasEditButton={false}
      logo={<ProfileStripeLogo classNames={{ width: 'w-14' }} />}
    />
  ) : (
    <IntegrationStatusCard
      status="Loading"
      provider={PROVIDERS.stripe}
      withStatus={withStatus}
      hasEditButton={false}
      allowed={allowed}
      logo={<ProfileStripeLogo classNames={{ width: 'w-14' }} />}
    />
  );
};

export const Empty = ({ withStatus = true }) => {
  const { accountType } = useAccount();
  const emptyStatus = INTEGRATION_STATUSES.notConnected;
  useUpdateIntegrationLocalStorage({ networkObj: { status: emptyStatus }, cacheKey: 'stripeIntegration' });
  const allowed = isAllowed({ plan: accountType, provider: PROVIDERS.stripe });

  return (
    <IntegrationStatusCard
      withStatus={withStatus}
      status={emptyStatus}
      provider={PROVIDERS.stripe}
      allowed={allowed}
      hasEditButton={false}
      logo={<ProfileStripeLogo classNames={{ width: 'w-14' }} />}
    />
  );
};

export const Failure = ({ error }) => <Error errorMessage={error.message} />;

export const Success = ({ getStripeIntegration: [stripeIntegration], withStatus = true }) => {
  const { accountType } = useAccount();
  const { status } = stripeIntegration;

  useUpdateIntegrationLocalStorage({ networkObj: stripeIntegration, cacheKey: 'stripeIntegration' });

  const allowed = isAllowed({ plan: accountType, provider: PROVIDERS.stripe });

  return (
    <IntegrationStatusCard
      status={status}
      allowed={allowed}
      withStatus={withStatus}
      hasEditButton={false}
      provider={PROVIDERS.stripe}
      logo={<ProfileStripeLogo classNames={{ width: 'w-14' }} />}
    />
  );
};
