import { useEffect, useState, useRef } from 'react';
import { useAuth } from '@redwoodjs/auth';
import PropTypes from 'prop-types';
import ProviderCardLayout from 'src/layouts/ProviderCardLayout/ProviderCardLayout';

const openSocketConnection = ({ provider, accountId }) => {
  return new WebSocket(
    `wss://0vecsb8hg8.execute-api.us-east-1.amazonaws.com/dev?provider=${provider}&accountid=${accountId}`
  );
};

const IntegrationsWebsocketActiveCampaign = () => {
  const { currentUser } = useAuth();
  const { accountId } = currentUser;
  const [messageState, setMesssage] = useState({});
  const ws = useRef(null);
  const provider = 'activecampaign';

  useEffect(() => {
    ws.current = openSocketConnection({ provider, accountId });
    ws.current.onopen = () => {
      ws.current.send(
        JSON.stringify(
          {
            action: 'status',
            provider,
            accountId,
          },
          null,
          '\t'
        )
      );
    };
    ws.current.onclose = () => {
      setTimeout(() => {
        ws.current = openSocketConnection({ provider, accountId });
      }, 1000);
    };
    ws.current.onmessage = (e) => {
      const message = JSON.parse(e.data);
      setMesssage(message);
    };

    return () => {
      ws.current.close();
    };
  }, []);

  const getCurrentProgress = ({ currentOffset, currentTotals, thisDatapointIsLast }) => {
    const percent = Math.ceil((currentOffset / currentTotals) * 100);
    if (percent <= 15) return 15;
    if (percent <= 100) return percent;
    if (thisDatapointIsLast) return 75;
    return 100;
  };

  const getCurrentPhase = (detail, datapoint) => {
    if (!datapoint.perPhase) return 'all';
    return (detail.meta.type === 'all' && 'firstPhase') || (detail.meta.type === 'resync' && 'resync');
  };

  const getTotals = (detail, datapoint) => {
    const currentPhase = getCurrentPhase(detail, datapoint);
    return datapoint.totals[currentPhase];
  };

  return (
    <div className="w-full">
      {(messageState?.items?.length || messageState['detail-type']) && (
        <ProviderCardLayout provider="ActiveCampaign">
          <ImportationProgress message={messageState} getCurrentProgress={getCurrentProgress} getTotals={getTotals} />
        </ProviderCardLayout>
      )}
    </div>
  );
};

const getLastProcess = (items) => {
  const orderedItems = items.sort((a, b) => {
    const dateA = new Date(a.startTime);
    const dateB = new Date(b.startTime);
    return dateA - dateB;
  });
  return orderedItems[orderedItems.length - 1];
};

function ImportationProgress({ message, getCurrentProgress, getTotals }) {
  const [totalCompleted, setTotalCompleted] = useState(false);

  const isAsyncStatusFromDynamoDb = message?.items?.length;

  /* ---
      Getting status async from dynamoDb.
      If message has items is because is retrieve data from dynamoDb and not from Event bus
  -- */
  if (isAsyncStatusFromDynamoDb) {
    const lastProcess = getLastProcess(message?.items);
    // const isInProgress = lastProcess.status === 'inProgress';
    const isCompleted = lastProcess.status === 'completed';
    const isFetchingDone = lastProcess.status === 'fetchingDone';

    if (lastProcess?.datapointsPlan) {
      const plan = JSON.parse(lastProcess.datapointsPlan);
      const initialPercent = (isFetchingDone && 75) || (isCompleted && 100) || 0;
      const progressBars = plan.map((datapoint) => {
        return (
          <>
            <ActiveCampaignProgressBar name={datapoint.name} initialPercent={initialPercent} />
            <div className="mb-4" />
          </>
        );
      });

      return (
        <>
          {progressBars}
          {isCompleted && <ACImportationCompleted />}
        </>
      );
    }

    if (isCompleted) {
      if (!totalCompleted) {
        setTotalCompleted(true);
      }
    }
  }

  /* ---
  All the cases below are sync events from Event bus
  --- */

  if (message['detail-type'] === 'IntegrationDone') {
    if (!totalCompleted) {
      setTotalCompleted(true);
    }
  }

  if (message['detail-type'] === 'FetchingDone') {
    const plan = message?.detail?.meta?.datapointsPlan;

    return plan.map((datapoint) => {
      return (
        <>
          <ActiveCampaignProgressBar name={datapoint.name} initialPercent={75} />
          <div className="mb-4" />
        </>
      );
    });
  }

  if (message['detail-type'] === 'EstimationReady') {
    const plan = message?.detail?.meta?.datapointsPlan;

    return plan.map((datapoint) => {
      return (
        <>
          <ActiveCampaignProgressBar name={datapoint.name} initialPercent={0} />
          <div className="mb-4" />
        </>
      );
    });
  }

  if (message['detail-type'] === 'RequestsSent') {
    const plan = message?.detail?.meta?.datapointsPlan;

    return plan.map((datapoint) => {
      const currentDatapointName = message?.detail?.currentBatch.fetchParams.name;
      const currentDatapointIndex = getIndex(plan, 'name', currentDatapointName);
      const thisDatapointIndex = getIndex(plan, 'name', datapoint.name);
      const thisDatapointIsLast = thisDatapointIndex === plan.length - 1;
      const isCompleted = thisDatapointIndex < currentDatapointIndex;
      const isCurrent = thisDatapointIndex === currentDatapointIndex;
      const currentTotals = getTotals(message?.detail, datapoint);
      const currentOffset = message?.detail?.fetchedItemsCount;
      const initialPercent = getCurrentProgress({ currentOffset, currentTotals, thisDatapointIsLast });

      return (
        <>
          <ActiveCampaignProgressBar
            name={datapoint.name}
            isCompleted={totalCompleted || isCompleted}
            initialPercent={isCurrent ? initialPercent : 0}
          />
          <div className="mb-4" />
        </>
      );
    });
  }

  if (totalCompleted) return <ACImportationCompleted />;

  return <h4 className="text-wmxText-100 text-xs">Waiting for importation process to start...</h4>;
}

const ActiveCampaignProgressBar = ({ name, initialPercent = 0, isCompleted }) => {
  const style = {
    width: `${isCompleted ? 100 : initialPercent}%`,
    transition: `width 1s ease-in-out`,
  };

  return (
    <>
      <div>
        <div className="flex justify-between w-100 mb-1">
          <div className="text-wmxText-100 text-xs">{name}</div>
          <div className="text-wmxText-100 text-xs">{initialPercent}%</div>
        </div>
        <div className="w-100 h-1 bg-wmxHighlightDark-75 relative">
          <div style={style} className="h-full bg-wmxSecondary-100" />
        </div>
      </div>
    </>
  );
};

function getIndex(array, attr, value) {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}

const ACImportationCompleted = () => {
  return (
    <>
      <p className="text-wmxText-100 text-xs ml-1 mb-4 mt-4"> ✅ Importation completed!</p>
    </>
  );
};

export default IntegrationsWebsocketActiveCampaign;

ImportationProgress.propTypes = {
  message: PropTypes.object,
  getCurrentProgress: PropTypes.func,
  getTotals: PropTypes.func,
};

ActiveCampaignProgressBar.propTypes = {
  name: PropTypes.string,
  initialPercent: PropTypes.number,
  isCompleted: PropTypes.bool,
};
