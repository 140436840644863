import useCurrentUser from 'src/customHooks/useCurrentUser';

export default function useProfileButtons({ row }) {
  const {
    stripeIntegration: { isReady: isUserWithStripe },
    baseAcUrl,
  } = useCurrentUser();

  const getStripeUri = (uriPath) => `https://dashboard.stripe.com/${uriPath}`;

  const { contactId, customerId, email = '' } = row.original;
  const emailQuery = email.split('@').join('%40');
  const uriPath = customerId ? `customers/${customerId}` : `search?query=${emailQuery}`;

  const stripeLinkOnlyCustomerId = customerId ? getStripeUri(`customers/${customerId}`) : null;
  const stripeLink = getStripeUri(uriPath);
  const acLink = `https://${baseAcUrl}.activehosted.com/app/contacts/${contactId}`;
  const withStripe = isUserWithStripe && stripeLink;

  return {
    acLink,
    withStripe,
    stripeLink,
    stripeLinkOnlyCustomerId,
    contactId,
  };
}
