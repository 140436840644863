import React from 'react';
import PropTypes from 'prop-types';

import { BoxSkeleton } from 'src/components/Generic/Skeletons';

function DashboardLoadingSkeleton({ dashboardRef }) {
  return (
    <>
      <BoxSkeleton
        speed={1}
        height={130}
        width={dashboardRef?.current?.getBoundingClientRect().width / 4}
        foregroundColor="#1C2631"
        backgroundColor="#304254"
      />
      <BoxSkeleton
        speed={1}
        height={130}
        width={dashboardRef?.current?.getBoundingClientRect().width / 4}
        foregroundColor="#1C2631"
        backgroundColor="#304254"
      />
      <BoxSkeleton
        speed={1}
        height={130}
        width={dashboardRef?.current?.getBoundingClientRect().width / 4}
        foregroundColor="#1C2631"
        backgroundColor="#304254"
      />
    </>
  );
}

DashboardLoadingSkeleton.propTypes = {
  dashboardRef: PropTypes.any,
};

export default DashboardLoadingSkeleton;
