/* eslint-disable no-undef */
import { ScaleLoader } from 'react-spinners';
import LoggedAsUserCell from 'src/lib/LoggedAs/LoggedAsUserCell';
import { Button } from 'src/components/Generic';
import { Flex, HelperText, HSpacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { useLoggedAs } from 'src/lib/LoggedAs/useLoggedAs';
import { useMemo } from 'react';

const LoggedAsBar = () => {
  const { loggedAs, logOut, loading, accountId, realAccountId } = useLoggedAs();

  const memoAccountId = useMemo(() => accountId, [accountId]);
  const memoloading = useMemo(() => loading, [loading]);

  if (!loggedAs) return <></>;

  return (
    <div className="bg-wmxAlert-200 box-border w-full fixed top-0 right-0 left-0 py-0.5" style={{ zIndex: '99' }}>
      <Flex justify="end">
        <HelperText secondary={false}>
          {!memoloading && (
            <>
              User: <LoggedAsUserCell id={memoAccountId} />
            </>
          )}
        </HelperText>
        <HSpacer />
        <Button
          disabled={memoloading}
          variant="transparent"
          size="xxs"
          onClick={() => {
            logOut({ realAccountId });
          }}
        >
          {(memoloading && <ScaleLoader size={2} height={5} width={2} color="white" />) || 'Log out'}
        </Button>
        <HSpacer />
      </Flex>
    </div>
  );
};

export default LoggedAsBar;
