import dayjs from 'dayjs';

export const pretiffyLastUpdate = (datetime, format = 'Date') => {
  let date1 = '';
  if (format === 'UnixTimestamp') {
    date1 = dayjs.unix(datetime);
  } else {
    date1 = dayjs(datetime);
  }

  const now = dayjs();
  const days = now.diff(date1, 'day');
  let minutes = 0;
  let seconds = 0;
  if (days < 0.5) {
    minutes = now.diff(date1, 'minute');
  }
  if (minutes < 1) {
    seconds = minutes / 60;
  }
  const years = days / 365;
  const months = days / 30;
  const weeks = days / 7;
  const hours = minutes / 60;

  switch (true) {
    case days >= 365:
      return `${years.toFixed(0)}yr${years >= 1.5 ? 's' : ''}`;
    case days >= 30:
      return `${months.toFixed(0)} month${months >= 1.5 ? 's' : ''}`;
    case days >= 7:
      return `${weeks.toFixed(0)}w${weeks >= 1.5 ? 's' : ''}`;
    case days >= 1:
      return `${days} day${days >= 1.5 ? 's' : ''}`;
    case hours >= 1:
      return `${hours.toFixed(0)}hr${hours >= 1.5 ? 's' : ''}`;
    case minutes >= 1:
      return `${minutes.toFixed(0)}min${minutes >= 1.5 ? 's' : ''}`;
    default:
      return `${seconds.toFixed(0)}sec${seconds >= 1.5 ? 's' : ''}`;
  }
};
