import PropTypes from 'prop-types';
import useAccount from 'src/customHooks/useAccount';
import { routes, navigate } from '@redwoodjs/router';
import { TRIAL_DAYS } from 'wmx-shared-code/accountGlobalVariables';
import { useSidebar } from 'src/layouts/SidebarLayout/SidebarLayout';
import { Button } from 'src/components/Generic';
import CircularProgressBar from '../CircularProgressBar/CircularProgressBar';
import { Flex, Subtitle, Title, HSpacer, Spacer } from '../Generic/LayoutUtils/LayoutUtils';

const TrialDaysLeftWidget = () => {
  const { collapsedSidebar } = useSidebar();
  const { daysLeft, trial } = useAccount();
  const total = TRIAL_DAYS;
  const percent = (daysLeft / total) * 100;
  if (trial)
    return (
      <>
        <WidgetContainer>
          <WidgetWrapper>
            <CircularProgressBar percent={percent} radius={(collapsedSidebar && 18) || 30}>
              <Flex col>
                <Title lg={!collapsedSidebar}>{daysLeft}</Title>
                {!collapsedSidebar && (
                  <>
                    <Spacer nm1 />
                    <Subtitle secondary>trial</Subtitle>
                  </>
                )}
              </Flex>
            </CircularProgressBar>
            <HSpacer size="sm" />
            <TextAndButton />
          </WidgetWrapper>
        </WidgetContainer>
      </>
    );
  return <></>;
};

const goToBilling = () => {
  navigate(routes.billing());
};

const TextAndButton = () => {
  const { isTrialExpired, acIntegration: { isReady: isAcConnected } = {} } = useAccount();
  const { collapsedSidebar } = useSidebar();
  const isButtonDisabled = !isAcConnected || isTrialExpired;

  return (
    <Flex col items="start" {...(collapsedSidebar && { justify: 'center' })}>
      {collapsedSidebar && <Spacer size="xs" />}
      {collapsedSidebar && <Subtitle secondary>trial</Subtitle>}
      <Spacer size="xs" />
      {!collapsedSidebar && (
        <Button disabled={isButtonDisabled} xs minWidth={0} onClick={goToBilling}>
          Upgrade
        </Button>
      )}
    </Flex>
  );
};

const WidgetContainer = ({ children }) => {
  return (
    <>
      <Flex>{children}</Flex>
    </>
  );
};

const WidgetWrapper = ({ children }) => {
  const { collapsedSidebar } = useSidebar();
  return (
    <>
      <Flex items="center" {...(collapsedSidebar && { col: true, justify: 'center' })}>
        {children}
      </Flex>
    </>
  );
};

WidgetContainer.propTypes = {
  children: PropTypes.any,
};
WidgetWrapper.propTypes = {
  children: PropTypes.any,
};

export default TrialDaysLeftWidget;
