/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';

import { usePopper } from 'react-popper';
import { getFixedRangeFromDynamicRange } from 'src/components/Generic/DatePicker/getTimeRange';
import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';
import { useUpgradeProDialog } from 'src/components/UpgradeProDialog/UpgradeProDialog';
import Badge from '../Badge/Badge';
import { FEATURES } from '../../../../../shared/accountGlobalVariables';

export const getDynamicRangeLabels = ({ allRanges, supportedRanges, shouldUpdateGlobalDatePicker }) => {
  const labels = allRanges.map((rangeText) => {
    const fixedTimeRange = getFixedRangeFromDynamicRange(rangeText);

    return {
      label: (
        <RangeLabel
          dynamicRangeText={rangeText}
          fixedTimeRange={fixedTimeRange}
          upgrade={!supportedRanges.includes(rangeText)}
          shouldUpdateGlobalDatePicker={shouldUpdateGlobalDatePicker}
        />
      ),
      value: fixedTimeRange,
      text: rangeText,
    };
  });

  return labels;
};

function RangeLabel({ dynamicRangeText, fixedTimeRange, upgrade, shouldUpdateGlobalDatePicker = true }) {
  const { open: openUpgradeProDialog } = useUpgradeProDialog();
  const setRecoilTimeRange = useSetRecoilState(RecoilTimeRange);
  const [newStart, newEnd] = fixedTimeRange;
  const [showBadge, setShowBadge] = useState(false);

  const newTimeRange = { start: newStart, end: newEnd };

  const handleClick = (rangeText, e) => {
    if (upgrade) {
      e.preventDefault();
      e.stopPropagation();
      openUpgradeProDialog(FEATURES.extendedDateRanges);
    }

    if (rangeText !== 'Custom' && !upgrade && shouldUpdateGlobalDatePicker) {
      setRecoilTimeRange({
        timeRange: newTimeRange,
        label: dynamicRangeText,
      });
    }
  };

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-start',
    strategy: 'fixed',
    modifiers: [{ name: 'offset', enabled: true, options: { offset: [5, -10] } }],
  });

  return (
    <label
      className="relative block cursor-pointer px-4 py-2 text-xs text-white hover:text-white  w-full"
      onClick={(e) => handleClick(dynamicRangeText, e)}
      onMouseEnter={() => upgrade && setShowBadge(true)}
      onMouseLeave={() => upgrade && setShowBadge(false)}
      ref={setReferenceElement}
    >
      {upgrade && showBadge && (
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <Badge size="xxs" absolute={false} variant="primary">
            <span className="text-wmxText-100"> PRO </span>
          </Badge>
        </div>
      )}
      <div>{dynamicRangeText}</div>
    </label>
  );
}

RangeLabel.propTypes = {
  dynamicRangeText: PropTypes.string,
  fixedTimeRange: PropTypes.array,
  upgrade: PropTypes.any,
  shouldUpdateGlobalDatePicker: PropTypes.bool,
};
