import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';
import { triggerTrackEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';

import {
  initialFixedTimeRange,
  getFixedRangeFromDynamicRange,
  getDatePickerLabels,
  getDatepickerDropdownOption,
} from 'src/components/Generic/DatePicker/getTimeRange';
import useAccountStatus from 'src/customHooks/useAccountStatus';

import useMouseOverLeave from 'src/customHooks/useMouseOverLeaver';
import { getDatePickerValueFromTimezone, getFixedTimerangeByTimezone } from 'src/lib/handleTimezone';
import { useDefaultTimeZone } from 'src/lib/localStorageKeys';

dayjs.extend(utc);

export const useDatepicker = ({ withCustomDatePicker = true }) => {
  const { accountType } = useAccountStatus();
  const [{ timeRange, label }, setTimeRange] = useRecoilState(RecoilTimeRange);
  const [dates, setDates] = useState({ start: '', end: '' });
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);
  const [showTimeRangeTooltip, setShowTimeRangeTooltip] = useState(false);

  const { get: getLocalStoraeDefaultTimezone } = useDefaultTimeZone();

  const dynamicRanges = getDatePickerLabels({ withCustomDatePicker, accountType });
  const optionButtonsProp = getDatepickerDropdownOption({ dynamicRanges });

  const { timezone: selectedTimezone } = getLocalStoraeDefaultTimezone();

  const getValues = (selectedlabel) => {
    if (selectedlabel !== 'Custom') {
      return getFixedRangeFromDynamicRange(selectedlabel);
    }
    return [timeRange.start, timeRange.end];
  };

  const setTimeRangeOption = (selectedlabel) => {
    if (selectedlabel === 'Custom') {
      setShowDatepicker(true);
      setShowTimeRangeTooltip(false);
    }

    const [start, end] = getValues(selectedlabel);

    setTimeRange({
      label: selectedlabel,
      timeRange: {
        start,
        end,
      },
    });
  };

  const dropDownContainer = useRef(null);
  const triggerDropdownBtnRef = useRef(null);

  const onRangeChange = (date) => {
    const setStartOfTimeRange = (endTimeRange) => dayjs(endTimeRange).startOf('day').toDate();
    const setEndOfTimeRange = (endTimeRange) => dayjs(endTimeRange).endOf('day').toDate();

    setDates((prevState) => {
      if (prevState.start && !prevState.end) {
        const { start } = prevState;
        const isStartAfterEnd = dayjs(start).isAfter(dayjs(date));
        const startTimeRange = isStartAfterEnd ? date : start;
        const endTimeRange = isStartAfterEnd ? start : date;

        const customTimeRange = { start: setStartOfTimeRange(startTimeRange), end: setEndOfTimeRange(endTimeRange) };

        return customTimeRange;
      }
      return { start: date };
    });
  };

  useEffect(() => {
    const isCustomDatePickerDefined = dates.start && dates.end;

    if (isCustomDatePickerDefined) {
      const startTimeRange = dates.start;
      const endTimeRange = dates?.end || dates.start;

      const startYYYYMMDD = dayjs(startTimeRange).format('YYYY-MM-DD');
      const endYYYYMMDD = dayjs(endTimeRange).format('YYYY-MM-DD');

      const newTimeRange = {
        start: dayjs.utc(startYYYYMMDD).startOf('day').format(),
        end: dayjs.utc(endYYYYMMDD).endOf('day').format(),
      };

      const timeRangeByTimezone = getFixedTimerangeByTimezone({ tz: selectedTimezone, timeRange: newTimeRange });

      setTimeRange({
        timeRange: timeRangeByTimezone,
        label: 'Custom',
      });
      setShowDatepicker(false);
      setCloseDialog(true);

      const eventPayload = {
        customTimeRange: timeRangeByTimezone,
      };
      triggerTrackEvent({ eventName: SEGMENT_TRACK_EVENTS_NAMES.datePickerClicked, payload: eventPayload });
    }
  }, [dates, setTimeRange]);

  const onDialogOpen = () => {
    setCloseDialog(false);
    if (label === 'Custom') {
      setShowDatepicker(true);
    }
  };

  const onDialogClose = () => {
    setShowDatepicker(false);
  };

  const onOpen = () => setShowDatepicker(true);
  const onClose = () => setShowDatepicker(false);

  const { start: startTimeRange, end: endTimeRange } = timeRange;
  const datePickerValue = getDatePickerValueFromTimezone(timeRange);

  const onDropdownHover = () => {
    if (!showDatepicker) setShowTimeRangeTooltip(true);
  };

  const onDropdownMouseLeave = () => {
    setShowTimeRangeTooltip(false);
  };

  useMouseOverLeave({
    actionOver: onDropdownHover,
    actionLeave: onDropdownMouseLeave,
    containerRef: triggerDropdownBtnRef,
  });

  const clickHandler = (event) => {
    const datepickerEl = document.querySelector('.rs-picker-menu');
    if (
      datepickerEl &&
      datepickerEl !== event.target &&
      !datepickerEl?.contains(event.target) &&
      dropDownContainer?.current !== event.target &&
      !dropDownContainer?.current?.contains(event.target)
    ) {
      setShowDatepicker(false);
      setCloseDialog(true);
    }
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', clickHandler);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', clickHandler);
    };
  }, []);

  // A hackish solution to make a click on the react-flow-pane to close the datepicker
  useEffect(() => {
    const pane = document.querySelector('.react-flow__pane');

    const listener = () => {
      setCloseDialog(true);
      setShowTimeRangeTooltip(false);
    };

    if (pane) {
      // eslint-disable-next-line no-unused-vars
      pane.addEventListener('click', listener);
    }
    // returned function will be called on component unmount
    return () => {
      if (pane) {
        pane.removeEventListener('click', listener);
      }
    };
  }, []);

  return {
    closeDialog,
    showTimeRangeTooltip,
    optionButtonsProp,
    setTimeRangeOption,
    onRangeChange,
    onDialogOpen,
    onDialogClose,
    onOpen,
    onClose,
    datePickerValue,
    initialFixedTimeRange,
    startTimeRange,
    endTimeRange,
    dropDownContainer,
    triggerDropdownBtnRef,
    label,
    showDatepicker,
    dynamicRanges,
  };
};
