import PropTypes from 'prop-types';

import { RowSkeletons } from 'src/components/Generic/Skeletons';

export default function OverviewSkeleton() {
  return <RowSkeletons rowsAmount={20} height={64} width="100%" foregroundColor="#1C2631" backgroundColor="#304254" />;
}

OverviewSkeleton.propTypes = {
  headerProps: PropTypes.shape({
    selectedConvWindow: PropTypes.any,
    setSelectedConvWindow: PropTypes.func,
  }),
};
