import PropTypes from 'prop-types';

import GetCountOfSquareCell from 'src/components/Cells/GetCountOfSquareCell/GetCountOfSquareCell';
import { useFunnelData } from 'src/components/Funnel/funnelHooks';
import { capitalize } from 'src/lib/generic/handlers';

const hasData = (itemsQuery) => {
  if (itemsQuery && itemsQuery.length > 0) {
    return true;
  }
  return false;
};

const getItemsQuery = (funnelPart, squareIdx) => {
  /* This function decides what type of query needs the count of people of the square */
  const { getSquareItems, funnelItems, isCurrentFrontEndPurchase } = useFunnelData();
  if (funnelPart !== 'top' || isCurrentFrontEndPurchase(capitalize(funnelPart), squareIdx)) {
    /* Format query to get the count of people
    fitlered by previous squares on the query */
    return funnelItems[`funnelItems${capitalize(funnelPart)}`]?.funnelPart;
  }
  /* Format query to get the count of people of the single square
   because is inside of an OR relation with the sibling squares */
  return getSquareItems(funnelPart, squareIdx);
};

const SquareLeadsCount = ({ timeRange, squareIdx, funnelPart }) => {
  const itemsQuery = getItemsQuery(funnelPart, squareIdx);
  if (!hasData(itemsQuery)) return <>0</>;

  return (
    <GetCountOfSquareCell
      funnelItems={itemsQuery}
      timeRange={timeRange}
      squareIdx={squareIdx}
      funnelPart={funnelPart}
    />
  );
};

SquareLeadsCount.propTypes = {
  timeRange: PropTypes.object,
  squareIdx: PropTypes.number,
  funnelPart: PropTypes.string,
};

export default SquareLeadsCount;
