import { useState } from 'react';
import dayjs from 'dayjs';
import useCurrentUser from 'src/customHooks/useCurrentUser';

import { ORDER_COLUMN_DIRECTIONS } from 'wmx-shared-code/reports/emailsOverview';

export default function useOrderBy({
  typeOfReport,
  initialOrderBy: initialOrderByParam,
  initialOrderDirection: initialOrderDirectionParam,
  getOrderByDefaults,
  onClick = () => {},
}) {
  const {
    stripeIntegration: { isReady: isUserWithStripe },
    hasTagsCustomerSegment: hasUserTagsCustomerSegment,
  } = useCurrentUser();

  const { orderByColumn: initialOrderBy, orderByDirection: initialOrderDirection } =
    getOrderByDefaults && typeOfReport
      ? getOrderByDefaults({
          typeOfReport,
          isUserWithStripe,
          hasUserTagsCustomerSegment,
        })
      : { orderByColumn: initialOrderByParam, orderByDirection: initialOrderDirectionParam };

  const [columnOrderBy, setColumnOrderBy] = useState(initialOrderBy);
  const [columnOrderByDirection, setColumnOrderByDirection] = useState(initialOrderDirection);

  const orderByColumn = ({ newOrderBy, newOrderByDirection }) => {
    setColumnOrderBy(newOrderBy);
    setColumnOrderByDirection(newOrderByDirection);
    onClick({ newOrderBy, newOrderByDirection });
  };

  const onOrderBy = {
    onHeaderClick: orderByColumn,
    columnOrderBy,
    columnOrderByDirection,
  };

  return {
    onOrderBy,
    columnOrderBy,
    setColumnOrderBy,
    columnOrderByDirection,
    setColumnOrderByDirection,
  };
}

const turnNumWithCommaToNumber = (value) => Number(value.split(',').join(''));
const isNumWithComma = (value) => !Number.isNaN(turnNumWithCommaToNumber(value));
const isStringifiedNumWithComma = (value) => typeof value === 'string' && isNumWithComma(value);
const getValueIsDate = (value) => dayjs(value).isValid();

export function orderByWithJs({ direction, data, orderByKey }) {
  const clonedArray = data.slice();

  const sorted = clonedArray.sort((rowA, rowB) => {
    const formatString = (value) => value.toUpperCase();
    const formatNumStrigified = (value) => turnNumWithCommaToNumber(value);

    const formatValue = (value) => {
      const isNumInString = isStringifiedNumWithComma(value);
      if (isNumInString) return formatNumStrigified(value);

      if (typeof value === 'string') return formatString(value);

      return value;
    };

    const unformattedValueA = rowA[orderByKey];
    const unformattedValueB = rowB[orderByKey];

    const isDate = getValueIsDate(unformattedValueA);

    if (isDate) return compareDateRecords(unformattedValueA, unformattedValueB, direction);

    const valueA = formatValue(unformattedValueA);
    const valueB = formatValue(unformattedValueB);

    if (valueA < valueB) {
      return direction === ORDER_COLUMN_DIRECTIONS.asc ? -1 : 1;
    }
    if (valueA > valueB) {
      return direction === ORDER_COLUMN_DIRECTIONS.asc ? 1 : -1;
    }

    return 0;
  });

  return sorted;
}

function compareDateRecords(dateA, dateB, direction) {
  const isDateAMoreRecent = dayjs(dateA).isAfter(dateB);

  if (isDateAMoreRecent) {
    return direction === ORDER_COLUMN_DIRECTIONS.asc ? 1 : -1;
  }

  if (!isDateAMoreRecent) {
    return direction === ORDER_COLUMN_DIRECTIONS.asc ? -1 : 1;
  }

  return 0;
}
