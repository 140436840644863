import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Tooltip, LoadingComponent } from 'src/components/Generic';
import { scrollbarStyles } from 'src/lib/generic/commonClasses';
import * as Popover from '@radix-ui/react-popover';
import { METADATA_HEADERS, METADATA_TYPES } from 'wmx-shared-code/tablesGlobalsVariables';
import { useEffect } from 'react';
import { Card, Flex, HelperText, HSpacer, Line, ScrollableArea, Spacer } from '../../LayoutUtils/LayoutUtils';
import { useWmxTable } from './TableContext';

const noop = () => {};

const getExtendProps = ({ extendArray, header, columnPosition, headerId: columnAccessor }) => {
  const isExtendArray = extendArray.length;
  const extendAccessors = isExtendArray ? extendArray.map(({ accessor }) => accessor) : [];
  const accessorIdx = extendAccessors.findIndex(
    (accessor) => accessor === columnPosition || accessor === header || accessor === columnAccessor
  );

  return accessorIdx !== -1 ? extendArray[accessorIdx] : {};
};

export const Table = () => {
  const {
    mainContainerRef,
    headerTooltips,
    extendRows,
    extendHeader,
    onRowClick,
    setNextChunk,
    hasMoreData,
    height,
    scrollableTarget,
    name,
    classNames,
    styles,
    autoInfiniteScrollOverflow,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useWmxTable();

  const infiniteScrollOverflow = autoInfiniteScrollOverflow ? 'auto' : 'unset';
  return (
    <div
      id={`${name}tableContainer`}
      ref={mainContainerRef}
      style={{ height }}
      className={`tableContainer w-full  ${
        (!autoInfiniteScrollOverflow && `overflow-scroll h-full ${scrollbarStyles}`) || 'h-auto'
      } ${classNames?.container || ''}`}
    >
      <InfiniteScroll
        dataLength={rows.length}
        next={setNextChunk}
        hasMore={hasMoreData}
        loader={<LoadingComponent />}
        style={{
          overflow: infiniteScrollOverflow,
          ...(styles?.container && { marginRight: styles?.container?.marginRight }),
        }}
        height={(autoInfiniteScrollOverflow && height) || null}
        scrollableTarget={scrollableTarget || `${name || ''}tableContainer`}
        className={`${autoInfiniteScrollOverflow && scrollbarStyles}`}
      >
        <table
          className={`${classNames?.table || ''} ${(autoInfiniteScrollOverflow && 'pr-4') || ''} table-auto`}
          style={{
            borderCollapse: 'separate',
            borderSpacing: '0 4px',
            ...(styles?.table && { paddingRight: styles?.table?.paddingRight }),
          }}
          {...getTableProps()}
        >
          <TableHeader
            headerGroup={headerGroups[0]}
            extendHeader={extendHeader}
            tooltips={headerTooltips}
            classNames={classNames?.header}
          />
          <tbody className={classNames?.body?.tbody || ''} style={styles?.body?.tbody} {...getTableBodyProps()}>
            <TableBody
              rows={rows}
              classNames={classNames?.body}
              prepareRow={prepareRow}
              extendRows={extendRows}
              onRowClick={onRowClick}
            />
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};

export const TableSettings = () => {
  const wmxTable = useWmxTable();
  if (!wmxTable) return <></>;

  const { columnsOptions } = wmxTable;

  return (
    <Flex justify="end" items="center">
      <HSpacer size="sm" />
      {columnsOptions && <ColumnsOptions />}
      <HSpacer size="sm" />
    </Flex>
  );
};

const ColumnHideController = ({ column, handleOnChange, disabled }) => {
  return (
    <>
      <Flex key={column.id}>
        <input
          disabled={disabled}
          className="cursor-pointer noCloseRightPane"
          name={`${column.id}-check`}
          id={`${column.id}-check`}
          type="checkbox"
          {...column.getToggleHiddenProps()}
          onChange={(e) =>
            handleOnChange({
              event: e,
              columnId: column.id,
              onChange: column.getToggleHiddenProps().onChange,
              checked: column.getToggleHiddenProps().checked,
            })
          }
        />
        <HSpacer size="xs" />
        <HelperText>
          <label htmlFor={`${column.id}-check`} className="cursor-pointer noCloseRightPane">
            {column.Header}
          </label>
        </HelperText>
      </Flex>
      <Spacer size="xs" />
    </>
  );
};

const ColumnsOptions = () => {
  const {
    compulsoryColumns,
    chargeMetadataColumns,
    invoiceMetadataColumns,
    customerMetadataColumns,
    addressColumns,
    restOfColumns,
    handleOnChange,
  } = useColumnOptions();

  return (
    <ColumnsOptionsPopOver
      trigger={
        <>
          <Spacer size="xs" />
          <HelperText>Edit Columns</HelperText>
          <Spacer size="xs" />
        </>
      }
    >
      <Flex items="start" justify="around">
        <Flex col items="start">
          <HelperText>Columns</HelperText>
          <Spacer size="xs" />
          <Line />
          <Spacer />
          {restOfColumns.map((column) => (
            <ColumnHideController
              key={`${column.Header}-column-key`}
              column={column}
              handleOnChange={handleOnChange}
              disabled={compulsoryColumns.includes(column.Header)}
            />
          ))}
        </Flex>
        <HSpacer size="md" />
        <Flex col items="start">
          {(chargeMetadataColumns.length > 0 ||
            invoiceMetadataColumns.length > 0 ||
            customerMetadataColumns.length > 0) && (
            <>
              <HelperText>Metadata</HelperText>
              <Spacer size="xs" />
              <Line />
              <Spacer size="xs" />
            </>
          )}

          <Flex items="start">
            {chargeMetadataColumns.length > 0 && (
              <>
                <Flex col items="start" justify="start">
                  <HelperText fontBold>{METADATA_HEADERS.charges}</HelperText>
                  <Spacer size="xs" />
                  {chargeMetadataColumns.map((column) => (
                    <ColumnHideController
                      key={`${column.Header}-column-key`}
                      column={column}
                      handleOnChange={handleOnChange}
                      disabled={compulsoryColumns.includes(column.Header)}
                    />
                  ))}
                </Flex>
                <HSpacer />
              </>
            )}

            {invoiceMetadataColumns.length > 0 && (
              <>
                <Flex col items="start" justify="start">
                  <HelperText fontBold>{METADATA_HEADERS.invoices}</HelperText>
                  <Spacer size="xs" />
                  {invoiceMetadataColumns.map((column) => (
                    <ColumnHideController
                      key={`${column.Header}-column-key`}
                      column={column}
                      handleOnChange={handleOnChange}
                      disabled={compulsoryColumns.includes(column.Header)}
                    />
                  ))}
                </Flex>
                <HSpacer />
              </>
            )}

            {(customerMetadataColumns.length > 0 || addressColumns.length > 0) && (
              <>
                <Flex col items="start" jusstify="start">
                  <HelperText fontBold>{METADATA_HEADERS.customers}</HelperText>
                  <Spacer size="xs" />
                  {customerMetadataColumns &&
                    customerMetadataColumns.map((column) => (
                      <ColumnHideController
                        key={`${column.Header}-column-key`}
                        column={column}
                        handleOnChange={handleOnChange}
                        disabled={compulsoryColumns.includes(column.Header)}
                      />
                    ))}
                  {addressColumns &&
                    addressColumns.map((column) => (
                      <ColumnHideController
                        key={`${column.Header}-column-key`}
                        column={column}
                        handleOnChange={handleOnChange}
                        disabled={compulsoryColumns.includes(column.Header)}
                      />
                    ))}
                </Flex>
                <HSpacer />
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </ColumnsOptionsPopOver>
  );
};

const ColumnsOptionsPopOver = ({ children, trigger }) => {
  return (
    <Popover.Root modal className="noCloseRightPane">
      <Popover.Trigger className="w-auto ml-auto noCloseRightPane">{trigger}</Popover.Trigger>
      <Popover.Content className="noCloseRightPane">
        <ScrollableArea height="600px">
          <Card classNames={{ container: 'noCloseRightPane' }} bg={100}>
            {children}
          </Card>
        </ScrollableArea>
        <Popover.Close className="fill-current text-wmxText-100" />
        <Popover.Arrow className="fill-current text-wmxBgDark-200" />
      </Popover.Content>
    </Popover.Root>
  );
};

export const tableClassNamesProps = {
  classNames: PropTypes.shape({
    container: PropTypes.string,
    table: PropTypes.string,
    header: PropTypes.shape({
      thead: PropTypes.string,
      headerCell: PropTypes.string,
      headerRow: PropTypes.string,
    }),
    body: PropTypes.shape({
      tbody: PropTypes.string,
      bodyRow: PropTypes.string,
      bodyCell: PropTypes.string,
    }),
  }),
};

const TableHeader = ({ headerGroup, tooltips = [], extendHeader = {}, classNames }) => {
  const { columns = [], defaultCellChildren } = extendHeader;

  const headers = headerGroup?.headers.map((header, columnPosition) => {
    const { Header: headerName, id: headerId } = header;

    const tooltipsProps = getExtendProps({
      extendArray: tooltips,
      header: headerName,
      headerId,
      columnPosition,
    });

    const { className, cellChildren: extendedChildren } = getExtendProps({
      extendArray: columns,
      header: headerName,
      headerId,
      columnPosition,
    });

    const cellChildren = extendedChildren || defaultCellChildren;

    return (
      <th
        key={headerName}
        className={`${classNames?.headerCell || ''} ${className || ''} sticky top-0 hover:z-50`}
        {...header.getHeaderProps()}
      >
        <div className="inline">
          {cellChildren ? cellChildren({ renderedCell: header.render('Header'), header }) : header.render('Header')}
        </div>
        {!!tooltips.length && <Tooltip icon="infoBox400" {...tooltipsProps} />}
      </th>
    );
  });

  return (
    <thead className={classNames?.thead}>
      <tr className={`${classNames?.headerRow || ''}`} {...headerGroup?.getHeaderGroupProps()}>
        {headers}
      </tr>
    </thead>
  );
};

const TableBody = ({ rows, prepareRow, extendRows = {}, classNames, onRowClick = noop }) => {
  const getCells = (row) => {
    const { columns = [], defaultCellChildren } = extendRows;

    return row.cells.map((cell, columnPosition) => {
      const { Header: header, id: headerId } = cell.column;

      const { className, cellChildren: extendedChildren } = getExtendProps({
        extendArray: columns,
        header,
        headerId,
        columnPosition,
      });

      const cellChildren = extendedChildren || defaultCellChildren;

      const cellChildrenParams = {
        renderedCell: cell.render('Cell'),
        cell,
        row,
        columnPosition,
      };

      return (
        <td
          key={cell.value}
          className={` ${classNames?.bodyCell || ''} ${className || ''} ${columnPosition > 0 && 'whitespace-nowrap'}`}
          {...cell.getCellProps()}
        >
          {cellChildren ? cellChildren({ ...cellChildrenParams }) : cell.render('Cell')}
        </td>
      );
    });
  };

  const tableRows = rows.map((row) => {
    prepareRow(row);

    const { getRowClassNames: getExtendedClassNames = noop } = extendRows;

    const extendedClassNames = getExtendedClassNames({ row });
    const rowCells = getCells(row);

    return (
      <tr
        key={row.id}
        onClick={(ev) => onRowClick({ ev, row })}
        className={`${classNames?.bodyRow || ''} ${extendedClassNames || ''}`}
        {...row.getRowProps()}
      >
        {rowCells}
      </tr>
    );
  });

  return tableRows;
};

function useColumnOptions() {
  const { hiddenColumns, setHiddenColumns, allColumns, compulsoryColumns } = useWmxTable();

  const handleOnChange = ({ event, checked, columnId, onChange }) => {
    onChange(event);
    setHiddenColumns({ ...hiddenColumns, [columnId]: !checked });
  };

  const chargeMetadataColumns = allColumns.filter((column) => {
    return column.metadata && column.metaType === METADATA_TYPES.charge;
  });

  const invoiceMetadataColumns = allColumns.filter((column) => {
    return column.metadata && column.metaType === METADATA_TYPES.invoice;
  });

  const customerMetadataColumns = allColumns.filter((column) => {
    return column.metadata && column.metaType === METADATA_TYPES.customer;
  });

  const addressColumns = allColumns.filter((column) => {
    return column.metadata && column.metaType === METADATA_TYPES.address;
  });

  const restOfColumns = allColumns.filter((column) => {
    return !column.metadata;
  });

  return {
    compulsoryColumns,
    chargeMetadataColumns,
    invoiceMetadataColumns,
    customerMetadataColumns,
    addressColumns,
    restOfColumns,
    handleOnChange,
  };
}

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  extendRows: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        // accessor could be columnIndex or header name.
        // Example of columnIndex would be 2, and of header name, 'Customers'
        className: PropTypes.string,
        cellChildren: PropTypes.func,
      })
    ),
    defaultCellChildren: PropTypes.func,
    getRowClassNames: PropTypes.func,
  }),
  extendHeader: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        // accessor could be columnIndex or header name.
        // Example of columnIndex would be 2, and of header name, 'Customers'
        className: PropTypes.string,
        cellChildren: PropTypes.func,
      })
    ),
    defaultCellChildren: PropTypes.func,
    borderRadiusHeaderTwClassName: PropTypes.oneOf(['none', 'sm', 'base', 'md', 'lg', 'xl', '2xl', '3xl', 'full']),
  }),
  headerTooltips: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      // accessor could be columnIndex or header name.
      // Example of columnIndex would be 2, and of header name, 'Customers'
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      children: PropTypes.any,
      containerClasses: PropTypes.string,
      styles: PropTypes.object,
    })
  ),
  onRowClick: PropTypes.func,
  scrollableTarget: PropTypes.string,
  name: PropTypes.string,
  height: PropTypes.number,
  setNextChunk: PropTypes.func,
  hasMoreData: PropTypes.bool,
  autoInfiniteScrollOverflow: PropTypes.bool,
  ...tableClassNamesProps,
  styles: PropTypes.shape({
    container: PropTypes.shape({
      marginRight: PropTypes.number,
    }),
    table: PropTypes.shape({
      paddingRight: PropTypes.number,
    }),
    body: PropTypes.shape({
      tbody: PropTypes.object,
    }),
  }),
};
