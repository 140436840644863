import PropTypes from 'prop-types';
import { useDashboard } from 'src/contexts/Dashboard';
import React from 'react';
import { formatDateTick } from 'src/lib/graphsHelpers/graphsPeriods';

import BarSkeleton from 'src/components/Generic/Skeletons/BarChartSkeleton/BarChartSkeleton';
import MetricChart from 'src/components/MetricChart/MetricChart';
import { useAmountFormatter } from 'src/lib/graphsHelpers/graphsHelpers';

const GRAPH_PADDING = 0;
// eslint-disable-next-line no-undef
export const QUERY = gql`
  query SEGMENTS_TIMESERIES($segmentId: String, $timeSeries: JSON) {
    segmentsTimeseries(segmentId: $segmentId, timeSeries: $timeSeries) {
      segmentTimeserie
      timeSeriePeriod
    }
  }
`;

export const beforeQuery = ({ timeSeries, segmentId }) => {
  return {
    variables: { timeSeries, segmentId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  };
};

export const Loading = () => {
  const { useMetricLoading, metricsGroupWidth } = useDashboard();
  const { tooltipPeopleFormatter } = useAmountFormatter();
  const result = useMetricLoading({
    metric: 'segmentsTimeseries',
    getValues: useTotals,
    // eslint-disable-next-line react/prop-types
    renderComponent: ({ value, formatXTick, formatYTick, timeSeriePeriod }) => (
      <MetricChart
        data={value}
        formatXTick={formatXTick}
        width={metricsGroupWidth - GRAPH_PADDING}
        formatYTick={formatYTick}
        timeSeriePeriod={timeSeriePeriod}
        tooltipFormatter={tooltipPeopleFormatter({ type: 'subscriber' })}
      />
    ),
    LoadingComponent: () => <BarSkeleton foregroundColor="#1C2631" backgroundColor="#304254" />,
  });
  return result;
};

export const Empty = () => <div>Empty</div>;

export const Failure = ({ error }) => <div style={{ color: 'red' }}>Error: {error.message}</div>;

Failure.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.any,
  }),
};

export const Success = ({ segmentsTimeseries }) => {
  const { value, formatXTick, formatYTick, timeSeriePeriod } = useTotals({ segmentsTimeseries });
  const { useUpdateMetric } = useDashboard();
  const { tooltipPeopleFormatter } = useAmountFormatter();

  useUpdateMetric({ metric: 'segmentsTimeseries', value: segmentsTimeseries });

  return (
    <MetricChart
      data={value}
      formatXTick={formatXTick}
      formatYTick={formatYTick}
      timeSeriePeriod={timeSeriePeriod}
      tooltipFormatter={tooltipPeopleFormatter({ type: 'subscriber' })}
    />
  );
};

Success.propTypes = {
  segmentsTimeseries: PropTypes.any,
};

function useTotals({ segmentsTimeseries }) {
  const { tickCurrencyFormatter } = useAmountFormatter();
  return {
    value: segmentsTimeseries?.segmentTimeserie,
    formatXTick: formatDateTick(segmentsTimeseries?.timeSeriePeriod),
    formatYTick: tickCurrencyFormatter,
    timeSeriePeriod: segmentsTimeseries?.timeSeriePeriod,
  };
}
