import { routes, navigate } from '@redwoodjs/router';

export default function NewCustomersTooltip() {
  return (
    <span>
      Set your own segment to track your&nbsp;
      <button
        type="button"
        onClick={() => navigate(routes.integrationsWithOpenedSegment({ segment: 'customer-segment' }))}
        className="underline"
      >
        customers
      </button>
    </span>
  );
}
