import { useEffect } from 'react';

import isEqual from 'lodash.isequal';

export default function useUpdateCurrentTableData({ freshData, tableData, setTableData, shouldUpdate = true }) {
  useEffect(() => {
    if (!freshData) return;
    const isDataEqual = isEqual(freshData, tableData);
    const isData = Object.keys(freshData).length;

    if (!isDataEqual && isData && shouldUpdate) setTableData(freshData);
  }, [setTableData, tableData, freshData, shouldUpdate]);
}
