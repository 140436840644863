import { useAuth } from '@redwoodjs/auth';
import MenuIcon from 'src/components/MenuIcon/MenuIcon';
import { useSidebar } from 'src/layouts/SidebarLayout/SidebarLayout';
import {
  triggerTrackEvent,
  useIdentifyUserPayload,
  SEGMENT_TRACK_EVENTS_NAMES,
} from 'src/lib/segmentJuneEvents/segmentJuneEvents';

const UserMenu = () => {
  const { loading, logOut } = useAuth();
  const { logOut: logOutEventName } = SEGMENT_TRACK_EVENTS_NAMES;

  const { collapsedSidebar } = useSidebar();
  const { setIsUserLoggedIn, userPayload } = useIdentifyUserPayload();

  if (loading) {
    return null;
  }

  const onLogOut = () => {
    triggerTrackEvent({ eventName: logOutEventName, payload: userPayload });

    setIsUserLoggedIn(false);
    logOut({ returnTo: `${process.env.REDIRECT_URI}` });
  };

  return (
    <button
      id="userButton"
      type="button"
      onClick={onLogOut}
      className={`menuLink w-full flex group relative mr-auto ml-auto py-1 md:py-3 hover:bg-wmxHighlightDark-100 hover:shadow-lg rounded-md mt-1 ${
        collapsedSidebar && 'justify-center'
      }`}
    >
      <div className="text-sm">
        <div type="button" id="userButton" className="flex items-center focus:outline-none pl-2 ">
          <MenuIcon
            svg={
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8.5" cy="8.5" r="7" stroke="#9CA3AF" strokeWidth="3" />
              </svg>
            }
          />
          {!collapsedSidebar && <span className="ml-2 text-wmxText-100">Logout</span>}
          {collapsedSidebar && (
            <span className="ml-2 text-wmxText-100 absolute opacity-0 group-hover:opacity-100 py-1 px-2 left-0 rounded-md transform translate-x-14 text-sm shadow-lg bg-wmxBgDark-300 pointer-events-none">
              Logout
            </span>
          )}
        </div>
      </div>
    </button>
  );
};

export default UserMenu;
