import { atom, selector } from 'recoil';
import { transformTypeOfItem, getQueryFormattedByTypeOfItem } from 'src/lib/funnelHelpers/funnelHelpers';
import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';
import { initialSegmentElement } from 'src/lib/segmentBuilder/initialSegmentElements';

const segmentBuilderElementClickedId = atom({
  key: 'segmentBuilderElementClickedId',
  default: initialSegmentElement,
});

const funnelData = atom({
  key: 'funnelData',
  default: {
    top: {
      squares: [
        // {
        //   typeOfItem: PropTypes.string = '',
        //   isSelected: PropTypes.bool = false,
        //   isEmpty: PropTypes.bool = true,
        //   isLastSquare: PropTypes.bool,
        //   panePath: PropTypes.arrayof(PropTypes.string) = [],
        //   segmentElements: [{ id: initialSegmentElement, value: {} }],
        //   funnelPart: PropTypes.oneOf(['top', 'middle', 'bottom']),
        //   readyToQuery: PropTypes.bool,
        //   countOfIdsInSquare: PropTypes.number,
        //   peopleInSquareFetchedByFunnelId: PropTypes.number,
        //   squareGlobalIdx: PropTypes.number,
        //   squareIdxInPart: PropTypes.number,
        //   funnelPartNameIdx: PropTypes.number, funnelPartName === 'top' ? 0 : funnelPartName === 'middle' ? 1 : 2
        // },
      ],
      funnelPartNameIdx: 0,
      //   peopleInFunnelPart: PropTypes.number,
      //   isLoadingFetchedData: PropTypes.bool,
    },
    // like top
    middle: {
      squares: [],
      funnelPartNameIdx: 1,
    },
    // like top
    bottom: {
      squares: [],
      funnelPartNameIdx: 2,
    },
  },
});

/* ------------------------------------------------------------------------------------------------
 * Gives the funnelPart and the globalSquareIndex of the minor square modified
 * -----------------------------------------------------------------------------------------------*/
const minorGlobalSquareModified = atom({
  key: 'minorGlobalSquareModified',
  default: {
    funnelPartName: null,
    funnelPartNameIdx: null, // 'top': 0, 'middle': 1, 'bottom': 2
    globalSquareIndex: null,
  },
});

const funnelByIdRetrieved = atom({
  key: 'funnelByIdRetrieved',
  default: {
    funnelId: null,
    funnelName: null,
    funnelItemsFromDb: [],
    modifiedAt: null,
  },
});

export const FUNNEL_PAGE_SOURCES = {
  new: 'new',
  byUrl: 'byUrl',
  clickedInList: 'clickedInList',
  clickedInSubMenu: 'clickedInSubMenu',
  newAndSaved: 'newAndSaved',
};

const funnelPageSource = atom({
  key: 'funnelPageSource',
  default: null, // PropTypes.oneOf(['clickedInList', 'byUrl', 'new', 'clickedInSubMenu', 'newAndSaved'])
});

const isEditViewRightPaneOpen = atom({
  key: 'isEditViewRightPaneOpen',
  default: false,
});

const editViewRightPaneDefaultSize = atom({
  key: 'editViewRightPaneDefaultSize',
  default: 0,
});

const isPresentationViewRightPaneOpen = atom({
  key: 'isPresentationViewRightPaneOpen',
  default: false,
});

const presentationViewRightPaneDefaultSize = atom({
  key: 'presentationViewRightPaneDefaultSize',
  default: 0,
});

const shouldFunnelInDbUpdate = atom({
  key: 'shouldFunnelInDbUpdate',
  default: {
    // PropTypes.Int -- a number that shows which number of update is the current one. When the number changes
    // before a certain time interval, we don't send the update to the database
    requestId: 0,
    shouldUpdate: false,
  },
});

/* ------------------------------------------------------------------------------------------------
 * clickedRowInPresentationView = {
   funnelPart: PropTypes.oneOf(['top', 'middle', 'bottom']),
   rowName: PropTypes.string,
   squareIndex: PropTypes.number,
   funnelItemNameInService: PropTypes.oneOf(
     ['automation', 'tag', 'stripeProduct', 'newContactsByList', 'newContacts', 'newCustomers']
    ),
 };
 * -----------------------------------------------------------------------------------------------*/
const clickedRowInPresentationView = atom({
  key: 'squareIndexOfClickedRowInPresentationView',
  default: null,
});

const currentFunnelPart = atom({
  key: 'currentFunnelPart',
  default: '',
});

const dataFromInitialFetch = atom({
  key: 'dataFromInitialFetch',
  default: null,
});

export const funnelItems = selector({
  key: 'funnelItems',
  get: ({ get }) => {
    const funnelDataState = get(funnelData);
    const { timeRange } = get(RecoilTimeRange);
    let queryObject = [];
    let partArray = [];

    const getQueryFromSquares = (funnelPart) => {
      if (funnelPart !== 'top') {
        return funnelDataState[funnelPart].squares
          .map((item) => {
            const { typeOfItem, readyToQuery } = item;
            if (readyToQuery) {
              return [
                {
                  [transformTypeOfItem(typeOfItem)]: {
                    ...getQueryFormattedByTypeOfItem(item, timeRange),
                  },
                },
              ];
            }

            return null;
          })
          .filter((x) => !!x);
      }

      funnelDataState[funnelPart].squares.forEach((item) => {
        const { typeOfItem, readyToQuery, panePath } = item;
        const squareName = panePath && panePath.length >= 2 && panePath[1].accessor;

        if (readyToQuery) {
          // frontendpurchase is added with a relation of AND
          if (squareName === 'frontEndPurchase') {
            const frontendPurchaseItem = [
              {
                [transformTypeOfItem(typeOfItem)]: {
                  ...getQueryFormattedByTypeOfItem(item, timeRange),
                },
              },
            ];

            if (queryObject.length > 0) {
              partArray = [queryObject, frontendPurchaseItem];
            } else {
              partArray = [frontendPurchaseItem];
            }
          } else {
            queryObject = [
              ...queryObject,
              {
                [transformTypeOfItem(typeOfItem)]: {
                  ...getQueryFormattedByTypeOfItem(item, timeRange),
                },
              },
            ];
            partArray = [queryObject];
          }
        }
      });
      // if the type of item is the same of an existing one we need to add the new items ides into an array of arrays
      // if the item type is new we need to add a new property

      return partArray;
    };

    /* ------------------------------------------------------------------------------------------------
     * Array of First Elements of Path Pane ['New Lead', 'Frontend Purchase', 'Application' ...]
     * -----------------------------------------------------------------------------------------------*/
    const getInfoFromEditedSquares = ({ funnelPart, lastFunnelPartIdx, serviceItemsOfCurrPart }) => {
      const currPartSquares = funnelDataState[funnelPart].squares;

      return currPartSquares.reduce(
        (accum, { panePath, readyToQuery, typeOfItem, squareGlobalIdx, segmentElements }, squarePartIndex) => {
          if (readyToQuery) {
            const squareName = panePath[1];
            const currServiceLength = serviceItemsOfCurrPart.length;
            const currPartSquaresLength = currPartSquares.length;

            const squareIndexInService =
              (funnelPart === 'top' && currServiceLength === 1) ||
              (funnelPart === 'top' && currServiceLength === 2 && squarePartIndex !== currPartSquaresLength - 1)
                ? 0
                : funnelPart === 'top' && currServiceLength === 2 && squarePartIndex === currPartSquaresLength - 1
                ? 1
                : squarePartIndex + lastFunnelPartIdx;

            const objToPush = {
              ...(panePath && panePath.length >= 2 && { squareName }),
              panePath,
              segmentElements,
              squarePartIndex,
              squareGlobalIdx,
              squareIndexInService,
              funnelItemNameInService: transformTypeOfItem(typeOfItem),
              squareFunnelPartName: funnelPart,
              funnelPartIdxOfCurrSquare: funnelPart === 'top' ? 0 : funnelPart === 'middle' ? 1 : 2,
            };

            accum.push(objToPush);
          }

          return accum;
        },
        []
      );
    };

    const itemsTop = getQueryFromSquares('top');
    const itemsMiddle = getQueryFromSquares('middle');
    const itemsBottom = getQueryFromSquares('bottom');

    const topInfoSquares = getInfoFromEditedSquares({
      funnelPart: 'top',
      lastFunnelPartIdx: 0,
      serviceItemsOfCurrPart: itemsTop,
    });

    const middleInfoSquares = getInfoFromEditedSquares({
      funnelPart: 'middle',
      lastFunnelPartIdx: itemsTop.length,
      serviceItemsOfCurrPart: itemsMiddle,
    });

    const bottomInfoSquares = getInfoFromEditedSquares({
      funnelPart: 'bottom',
      lastFunnelPartIdx: itemsTop.length + itemsMiddle.length,
      serviceItemsOfCurrPart: itemsBottom,
    });

    const totalService = [...itemsTop, ...itemsMiddle, ...itemsBottom];
    const isEmpty = totalService.length;

    return {
      totals: {
        service: totalService,
        squaresInfo: [...topInfoSquares, ...middleInfoSquares, ...bottomInfoSquares],
      },
      funnelItemsTop: {
        partialItems: itemsTop,
        service: itemsTop,
        funnelPart: itemsTop,
        squaresInfo: topInfoSquares,
      },
      funnelItemsMiddle: {
        partialItems: itemsMiddle,
        service: [...itemsTop, ...itemsMiddle],
        funnelPart: itemsMiddle.length ? [...itemsTop, ...itemsMiddle] : [],
        squaresInfo: middleInfoSquares,
      },
      funnelItemsBottom: {
        partialItems: itemsBottom,
        service: totalService,
        funnelPart: itemsBottom.length ? totalService : [],
        squaresInfo: bottomInfoSquares,
      },
      isEmpty,
    };
  },
});

export const numberOfBlocks = selector({
  key: 'numberOfBlocks',
  get: ({ get }) => {
    const funnelDataState = get(funnelData);
    const funnelParts = Object.values(funnelDataState);
    return funnelParts.map((part) => {
      return part.squares.filter((square) => {
        return !square.isEmpty;
      }).length;
    });
  },
});

export const currentSquare = selector({
  key: 'currentSquare',
  get: ({ get }) => {
    const funnelDataState = get(funnelData);
    const currentFunnelPartState = get(currentFunnelPart);
    const currentSquares = funnelDataState[currentFunnelPartState]?.squares;
    const index = funnelDataState[currentFunnelPartState]?.squares?.findIndex((item) => item.isSelected);
    if (index < 0) {
      return {
        currentSquare: null,
        currentSquareIndex: null,
        currentSegmentElements: [],
        currentTypeOfItem: null,
      };
    }
    return {
      currentSquare: currentSquares && currentSquares[index],
      currentSquareIndex: index && index,
      currentSegmentElements: currentSquares && currentSquares[index].segmentElements,
      currentTypeOfItem: currentSquares && currentSquares[index].typeOfItem,
    };
  },
});

/* ------------------------------------------------------------------------------------------------
 * Get the global idx of the last square of each part

 * Example Return

 * {
 *   top: 1,
 *   middle: 3,
 *   bottom: 6,
 * }
 * -----------------------------------------------------------------------------------------------*/
export const eachPartLastSquareGlobalIdx = selector({
  key: 'eachPartLastSquareGlobalIdx',
  get: ({ get }) => {
    const funnelDataState = get(funnelData);
    const parts = Object.keys(funnelDataState);
    let idxs = 0;
    let i = 0;
    let eachPartLastGlobalIdx = {};
    // eslint-disable-next-line consistent-return
    parts.forEach((part) => {
      const filteredSquares = funnelDataState[part]?.squares.filter((sq) => sq.readyToQuery);
      if (!filteredSquares) return [];
      const { length } = filteredSquares;
      const offset = i === 0 ? 1 : 0;
      const lastIdx = length > 0 ? length - offset : 0;
      idxs = lastIdx + idxs;
      eachPartLastGlobalIdx = { ...eachPartLastGlobalIdx, [part]: idxs };
      if (length > 0) {
        i += 1;
      }
    });
    return eachPartLastGlobalIdx;
  },
});

export const funnelItemsArgumentToDb = selector({
  key: 'funnelItemsArgumentToDb',
  get: ({ get }) => {
    const { timeRange } = get(RecoilTimeRange);
    const {
      totals: { service: totalService, squaresInfo: totalsSquareInfo },
    } = get(funnelItems);

    const getSegmentInContainsSelectForm = (segmentElements) => {
      const nonEmptySegmentElements = segmentElements.filter((el) => {
        return Object.keys(el.value).length > 0;
      });
      return nonEmptySegmentElements.reduce((accum, segmentItem) => {
        const { value: { condition = null, itemsIds, itemName: containsItemName } = {}, connection } =
          segmentItem || {};

        if (!connection || connection === 'AND') {
          // eslint-disable-next-line no-param-reassign
          accum[accum.length] = [];
        }

        const currentBlock = accum[accum.length - 1];

        const selectItemId = Array.isArray(itemsIds) ? itemsIds[0] : null;
        const conditionValue = condition === 'select' ? selectItemId : containsItemName;
        const itemToDb = { [condition]: conditionValue };

        // When deleting first item of segment builder, current block is undefined
        if (currentBlock) currentBlock.push(itemToDb);

        return accum;
      }, []);
    };

    let squareGlobalIdxInFunnelItems = 0;
    const funnelItemsToDb = totalService.map((funnelPart) => {
      return funnelPart.map(() => {
        let query = {};

        const currentSquareInFunnel = totalsSquareInfo[squareGlobalIdxInFunnelItems];
        const { funnelItemNameInService, panePath, segmentElements } = currentSquareInFunnel;

        const path = panePath.map(({ accessor }) => accessor);

        if (funnelItemNameInService === 'newCustomers' || funnelItemNameInService === 'newContacts') {
          query = {
            [funnelItemNameInService]: timeRange,
          };
        } else {
          const segment = getSegmentInContainsSelectForm(segmentElements);

          query = {
            [funnelItemNameInService]: segment,
          };
        }

        const squareFunnelItems = {
          query,
          path,
        };

        squareGlobalIdxInFunnelItems += 1;

        return squareFunnelItems;
      });
    });

    return funnelItemsToDb;
  },
});

/*
  Every time you add an atom in funnelAtoms, you should also reset in in funnelHooks.js
  in hook useRestartFunnelToDefaultValues
*/

export {
  segmentBuilderElementClickedId,
  funnelData,
  minorGlobalSquareModified,
  funnelByIdRetrieved,
  funnelPageSource,
  isEditViewRightPaneOpen,
  editViewRightPaneDefaultSize,
  isPresentationViewRightPaneOpen,
  presentationViewRightPaneDefaultSize,
  shouldFunnelInDbUpdate,
  clickedRowInPresentationView,
  currentFunnelPart,
  dataFromInitialFetch,
};
