import useCurrentUser from 'src/customHooks/useCurrentUser';
import { getLocalStorageValue, setLocalStorageValue } from 'src/customHooks/useLocalStorage';

const defaultTimeRangeLabel = 'defaultTimeRange';
const defaultTimezoneLabel = 'defaultTimezone';
const defaultCurrencyLabel = 'defaultCurrecy';

function LocalState({ isLoggedInAs, label }) {
  return {
    label,
    set: (objParams) => setLocalStorageValue(label, { ...objParams }, { isLoggedInAs }),
    get: () => getLocalStorageValue(label, { isLoggedInAs }),
  };
}

export const defaultTimeRange = LocalState({ label: defaultTimeRangeLabel });
export const defaultTimezone = LocalState({ label: defaultTimezoneLabel });
export const defaultCurrency = LocalState({ label: defaultCurrencyLabel });

export const useDefaultTimeRange = () => {
  const { loggedInAs } = useCurrentUser();

  return LocalState({ isLoggedInAs: loggedInAs, label: defaultTimeRangeLabel });
};
export const useDefaultTimeZone = () => {
  const { loggedInAs } = useCurrentUser();

  return LocalState({ isLoggedInAs: loggedInAs, label: defaultTimezoneLabel });
};
export const useDefaultCurrency = () => {
  const { loggedInAs } = useCurrentUser();

  return LocalState({ isLoggedInAs: loggedInAs, label: defaultCurrencyLabel });
};
