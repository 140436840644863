import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';
import { getFunnelByIdProps } from 'src/components/Cells/getFunnelByIdCell/GetFunnelByIdCell';

import GetCountOfCombinedLeadsCell from 'src/components/Cells/GetCountOfCombinedLeadsCell/GetCountOfCombinedLeadsCell';
import GetEmailsInAutomationOpensCell from 'src/components/Cells/GetEmailsInAutomationOpensCell/GetEmailsInAutomationOpensCell';
import GetFunnelCustomersAndRevenueCell from 'src/components/Cells/GetFunnelCustomersAndRevenueCell/GetFunnelCustomersAndRevenueCell';
import { useFunnelPresentationView } from 'src/components/Funnel/PresentationView/presentationViewHooks';

import FunnelCustomersAndRevenueMetric from 'src/components/Funnel/PresentationView/Metrics/FunnelCustomersAndRevenueMetric/FunnelCustomersAndRevenueMetric';
import EmailsInAutomationsOpenMetric from 'src/components/Funnel/PresentationView/Metrics/EmailsInAutomationsOpenMetric/EmailsInAutomationsOpenMetric';
import CombinedLeadsMetric from 'src/components/Funnel/PresentationView/Metrics/CombinedLeadsMetric/CombinedLeadsMetric';

import FunnelPresentationRow, {
  presentationRowProps,
} from 'src/components/Funnel/PresentationView/FunnelPresentationRow/FunnelPresentationRow';
import { presentationTableTwClasses } from 'src/components/Funnel/PresentationView/presentationViewHelpers';
import { HelperText } from 'src/components/Generic/LayoutUtils/LayoutUtils';

export default function FunnelPresentationPart({ rows, funnelPartName, dataFromInitialFetch }) {
  const { timeRange } = useRecoilValue(RecoilTimeRange);

  const { getIsFunnelItemDataChanged } = useFunnelPresentationView();

  const { initialFetchedData } = dataFromInitialFetch || {};
  const { funnelItems: fetchedFunnel } = initialFetchedData || {};

  const partRows = rows.map((rowProps, rowIdx) => {
    const { squareIndexOnFunnel, typeOfService, funnelItemsToFetch, squareGlobalIdx } = rowProps;

    const { opensInEmailsInAutomations, customersAndRevenue, people } =
      fetchedFunnel && fetchedFunnel[squareIndexOnFunnel] ? fetchedFunnel[squareIndexOnFunnel] : {};

    const isStripeBofu = typeOfService === 'revenue';
    const isAutomation = typeOfService === 'automation';

    const classNames = {
      ...(rowIdx < rows.length - 1 && { container: 'pb-2' }),
    };

    const isInitialFetchedDataChanged = getIsFunnelItemDataChanged({ globalSquareIndex: squareGlobalIdx });

    const serviceToCall = isAutomation ? (
      <GetEmailsInAutomationOpensCell funnelItems={funnelItemsToFetch} timeRange={timeRange} />
    ) : (
      <GetCountOfCombinedLeadsCell
        funnelItems={funnelItemsToFetch}
        timeRange={timeRange}
        squareIdx={squareIndexOnFunnel}
      />
    );

    const key = `key-${rowIdx}`;

    const metric = isInitialFetchedDataChanged ? (
      serviceToCall
    ) : !fetchedFunnel ? (
      'loading'
    ) : isAutomation ? (
      <EmailsInAutomationsOpenMetric opens={opensInEmailsInAutomations} />
    ) : (
      <CombinedLeadsMetric leadsCount={people} />
    );

    const stripeBofuComponent = isInitialFetchedDataChanged ? (
      <GetFunnelCustomersAndRevenueCell
        key={key}
        {...rowProps}
        funnelItems={funnelItemsToFetch}
        timeRange={timeRange}
        funnelPartName={funnelPartName.toLowerCase()}
      />
    ) : (
      <FunnelCustomersAndRevenueMetric
        key={key}
        {...rowProps}
        customers={customersAndRevenue?.customers}
        revenue={customersAndRevenue?.revenue}
        squareIndexOnFunnel={squareIndexOnFunnel}
      />
    );

    const isLast = rowIdx === rows.length - 1;
    return isStripeBofu ? (
      stripeBofuComponent
    ) : (
      <RowConnectorWrap>
        {rowIdx > 0 && <ConnectorTop />}
        <FunnelPresentationRow
          key={key}
          {...rowProps}
          classNames={classNames}
          funnelPartName={funnelPartName.toLowerCase()}
        >
          {metric}
        </FunnelPresentationRow>
        {!isLast && <ConnectorBottom />}{' '}
      </RowConnectorWrap>
    );
  });

  const { rotatedWordsWidth, funnelRowSeparationToBorder } = presentationTableTwClasses;

  return (
    <div className="flex flex-row my-8">
      <div className={`${rotatedWordsWidth} flex items-center justify-center`}>
        <div className="transform -rotate-90">
          <HelperText secondary={false}>{funnelPartName}</HelperText>
        </div>
      </div>
      <div className={`${funnelRowSeparationToBorder} border-l border-wmxHighlightDark-100`}>{partRows}</div>
    </div>
  );
}

export function RowConnectorWrap({ children }) {
  return <div className="relative">{children}</div>;
}

RowConnectorWrap.propTypes = {
  children: PropTypes.any,
};

export function ConnectorBottom() {
  return (
    <>
      <span className="absolute left-3 -ml-1 bottom-0 h-16 w-1 border-2 border-r-1 border-l-0  border-b-0 border-t-0 border-wmxPrimary-200 opacity-100 rounded-xl rounded-b-none rounded-r-none z-0" />
      <span className="absolute left-4 -ml-2 bottom-2 h-1 w-1 transform scale-125 translate-x-0.5 rounded-full bg-wmxPrimary-50 z-50" />
    </>
  );
}

export function ConnectorTop() {
  return (
    <>
      <span className="absolute left-3 -ml-1 top-0 h-16 w-1 border-2 border-r-1 border-l-0  border-b-0 border-t-0 border-wmxPrimary-200 opacity-100 rounded-xl rounded-b-none rounded-r-none z-0" />
      <span className="absolute left-4 -ml-2 top-0 h-1 w-1 transform scale-125 translate-x-0.5 rounded-full bg-wmxPrimary-50 z-50" />
    </>
  );
}

FunnelPresentationPart.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      ...presentationRowProps,
    })
  ),
  funnelId: PropTypes.string,
  funnelPartName: PropTypes.string,
  dataFromInitialFetch: PropTypes.shape({
    initialFetchedData: PropTypes.shape({
      funnelItems: PropTypes.array,
      ...getFunnelByIdProps,
    }),
    isInitialFetch: PropTypes.bool,
    setInitialFetch: PropTypes.func,
  }),
};
