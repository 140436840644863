import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { formatDatabaseRecords } from 'src/lib/handleTimezone';

import { setThousandsComma } from 'wmx-shared-code/currency/currency';
import { getAmountWithCurrencySymbol } from 'src/lib/generic/currency';

export { setThousandsComma };

dayjs.extend(utc);
dayjs.extend(timezone);

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const takeLastSAwayFromString = (str) => {
  if (typeof str !== 'string' || (str[str.length - 1] !== 's' && str[str.length - 1] === 'S')) return str;

  return str.substring(0, str.length - 1);
};

export const debounce = (callback, delay) => {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => callback(...args), delay);
  };
};

export const removeSelectedDuplicateFromArray = ({ array, duplication }) => {
  return array.reduce((accum, item) => {
    if (item === duplication && accum.includes(duplication)) return accum;

    accum.push(item);

    return accum;
  }, []);
};

export const makeId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getLastTwClassNameValue = (classNameString) => {
  if (!classNameString.includes('-')) {
    throw Error('Tailwind classname in getLastTwClassNameValue should include a hyphen (-)');
  }

  const tailwindValue = classNameString.split('-').pop();
  return Number(tailwindValue);
};

export const formatReportsTimestamp = (date, selectedFormat) => {
  const format = selectedFormat || 'D MMMM YYYY';
  return formatDatabaseRecords({ date, format });
};

export const formatCurrencyAmounts = ({ amount, defaultCurrency }) => {
  return getAmountWithCurrencySymbol({ currency: defaultCurrency, amount });
};

export const getOnlyNumbersInString = (str) => str.replace(/\D/g, '');

export const turnDateToUnix = (date) => dayjs(date).valueOf();
export const turndDateToISOString = (date) => dayjs(date).toISOString();
export const isBeforeDate = (dateA, dateB) => dayjs(dateA).isBefore(dateB);
