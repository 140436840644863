/* eslint-disable jsx-a11y/no-static-element-interactions */

import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import useDashboardLayout from 'src/customHooks/useDashboardLayout';
import { useDashboard } from 'src/contexts/Dashboard';

import { useLazyQuery } from '@redwoodjs/web/node_modules/@apollo/client';
import useAccount from 'src/customHooks/useAccount';
import { RETRIEVE_DASHBOARD } from 'src/components/Dashboard/dashboardHelpers/dashboardQueries';

import SegmentBuilderContainer from 'src/contexts/SegmentBuilder/SegmentBuilderContainer';
import { SEGMENTS_FEATURE } from 'src/pages/DashboardPage/DashboardPage';
import DashboardTabs from 'src/components/Dashboard/DashboardTabs/DashboardTabs';
import useGetDashboardDataOnLoad from 'src/customHooks/useGetDashboardDataOnLoad';
import DashboardLoadingSkeleton from '../dashboardHelpers/DashboardLoadingSkeleton';
import GridLayoutContainer from '../GridLayoutContainer/GridLayoutContainer';
import '../../../../../node_modules/react-grid-layout/css/styles.css';
import '../Stylesheet/DashboardBoxes.css';

export default function DashboardBoxes({ defaultDashboard, dashboardNames, dashboardId }) {
  const { creatingNewDashboard, dashboardNamesState, layoutState, metricGroupRef, showEvents, tabSelected } =
    useDashboard();
  const { accountId } = useAccount();

  const { handleLayoutChange, retrieveCallbackSuccess } = useDashboardLayout();
  const { getDashboardDataOnLoad } = useGetDashboardDataOnLoad();
  const newTabRef = useRef();
  const dashboardRef = useRef();

  useEffect(() => {
    getDashboardDataOnLoad(defaultDashboard, dashboardNames, dashboardId);
  }, [dashboardId, dashboardNames, defaultDashboard, getDashboardDataOnLoad]);

  const [retrieveDashboard, { loading }] = useLazyQuery(RETRIEVE_DASHBOARD, {
    variables: { accountId, label: tabSelected },
    onCompleted: retrieveCallbackSuccess,
    fetchPolicy: 'network-only',
  });

  if (loading && !creatingNewDashboard) {
    return (
      <Dashboard>
        <div className="flex flex-col w-full ">
          {dashboardNamesState.length ? (
            <DashboardTabs retrieveDashboard={retrieveDashboard} newTabRef={newTabRef} />
          ) : null}
          <div className="flex flex-row relative mt-2 w-full gap-3" ref={dashboardRef}>
            <DashboardLoadingSkeleton dashboardRef={dashboardRef} />
          </div>
        </div>
      </Dashboard>
    );
  }

  return (
    <Dashboard>
      <div className="flex flex-col w-full ">
        {dashboardNamesState.length ? (
          <DashboardTabs retrieveDashboard={retrieveDashboard} newTabRef={newTabRef} />
        ) : null}
        <SegmentBuilderContainer />
        <MetricsGroup ref={metricGroupRef}>
          {layoutState && (
            <GridLayoutContainer
              layoutState={layoutState}
              handleLayoutChange={handleLayoutChange}
              SEGMENTS_FEATURE={SEGMENTS_FEATURE}
              showEvents={showEvents}
              width={metricGroupRef?.current?.getBoundingClientRect().width}
            />
          )}
        </MetricsGroup>
      </div>
    </Dashboard>
  );
}

export function Dashboard({ children }) {
  return <div className="w-full flex ">{children}</div>;
}

export function RecentActivityLayout({ children }) {
  return <div className="w-full h-full">{children}</div>;
}

export const MetricsGroup = React.forwardRef(({ children }, ref) => {
  return (
    <div ref={ref} className="w-full pr-1">
      {children}
    </div>
  );
});

DashboardBoxes.propTypes = {
  dashboardMetrics: PropTypes.shape({
    partialCustomers: PropTypes.number,
    partialPreviousNewCustomers: PropTypes.number,
    revenuePrevious: PropTypes.number,
    previousSubscribers: PropTypes.number,
    partialSubscribers: PropTypes.number,
    totalCustomers: PropTypes.number,
    partialRevenue: PropTypes.number,
    totalSubscribers: PropTypes.number,
  }),
};

Dashboard.propTypes = {
  children: PropTypes.any,
};

RecentActivityLayout.propTypes = {
  children: PropTypes.any,
};

MetricsGroup.propTypes = {
  children: PropTypes.any,
};
DashboardBoxes.propTypes = {
  defaultDashboard: PropTypes.any,
  dashboardNames: PropTypes.any,
  dashboardId: PropTypes.any,
};
