import PropTypes from 'prop-types';

export default function BinSvg({ svgClassName, pathClassName, width = '30', height = '30' }) {
  return (
    <svg
      className={`fill-current ${svgClassName}`}
      width={width}
      height={height}
      viewBox="0 0 23 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.166748" rx="5" />
      <path
        className={pathClassName}
        d="M9.79175 11.2778V15.6111M12.5417 11.2778V15.6111M5.66675 8.38889H16.6667M15.9792 8.38889L15.3832 17.1581C15.3585 17.5225 15.2033 17.8636 14.9488 18.1126C14.6943 18.3616 14.3594 18.5 14.0116 18.5H8.32187C7.97409 18.5 7.63923 18.3616 7.38473 18.1126C7.13023 17.8636 6.975 17.5225 6.95031 17.1581L6.35425 8.38889H15.9792ZM13.2292 8.38889V6.22222C13.2292 6.03068 13.1568 5.84698 13.0279 5.71153C12.899 5.57609 12.7241 5.5 12.5417 5.5H9.79175C9.60941 5.5 9.43454 5.57609 9.30561 5.71153C9.17668 5.84698 9.10425 6.03068 9.10425 6.22222V8.38889H13.2292Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

BinSvg.propTypes = {
  height: PropTypes.any,
  pathClassName: PropTypes.string,
  svgClassName: PropTypes.string,
  width: PropTypes.any,
};
