import { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import useOrderByNotLoadingAnymore from 'src/customHooks/useOrderByNotLoadingAnymore';
import useUpdateCurrentTableData from 'src/customHooks/useUpdateCurrentTableData';

const OverviewReportsContext = createContext(null);

export default function OverviewReportsProvider({ children }) {
  // To use while the sorting is loading
  const [rightPaneCurrentData, setRightPaneCurrentData] = useState([]);
  const [isRightPaneFetchingData, setIsRightPaneFetchingData] = useState(false);

  const providerValue = {
    rightPane: {
      rightPaneCurrentData,
      setRightPaneCurrentData,
      isRightPaneFetchingData,
      setIsRightPaneFetchingData,
    },
  };

  return <OverviewReportsContext.Provider value={{ ...providerValue }}>{children}</OverviewReportsContext.Provider>;
}

OverviewReportsProvider.propTypes = {
  children: PropTypes.any,
};

export const useOverviewReportsContext = () => useContext(OverviewReportsContext);

export const useUpdateRightPaneCurrentData = ({ data: rightPaneData, shouldUpdate = true }) => {
  const {
    rightPane: { rightPaneCurrentData: currData, setRightPaneCurrentData: setCurrData },
  } = useOverviewReportsContext();

  useUpdateCurrentTableData({ freshData: rightPaneData, tableData: currData, setTableData: setCurrData, shouldUpdate });
};

export const useRightPaneNotLoadingAnymore = ({ isLoadingTable }) => {
  const {
    rightPane: { isRightPaneFetchingData, setIsRightPaneFetchingData },
  } = useOverviewReportsContext();

  useOrderByNotLoadingAnymore({
    isLoadingTable,
    isFetchingData: isRightPaneFetchingData,
    setIsFetchingData: setIsRightPaneFetchingData,
  });
};
