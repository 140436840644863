import { useEffect } from 'react';
import PropTypes from 'prop-types';
import getObjectPath from 'lodash.get';

import useInfiniteScroll from 'src/customHooks/useInfiniteScroll';

import withApolloQuery from 'src/lib/HOC/withApolloQuery';
import { Table } from 'src/components/Generic';
import { pretiffyLastUpdate } from 'src/lib/datetimeFormat/prettifyLastUpdate';
import { pretiffyTypeOfItem } from 'src/lib/funnelHelpers/funnelHelpers';

function SearchItemsTable({
  data,
  dataPath,
  fetchMore,
  queryParams,
  onRowClick,
  condition,
  itemAccessor,
  setTagsIdContains,
  onDoneClick,
}) {
  const { hasMoreData, dataChunk, fetchMoreData } = useInfiniteScroll({
    scrollLength: queryParams?.variables?.limit,
    incomingData: {
      dataArray: getObjectPath(data, dataPath),
      path: dataPath,
    },
    fetchMore,
    fetchMoreParams: queryParams,
  });

  useEffect(() => {
    if (condition === 'Contains') {
      const itemsId = dataChunk.map((item) => {
        return item.id;
      });
      setTagsIdContains(itemsId);
    }
  }, [dataChunk, condition]);

  const itemId = itemAccessor === 'stripeProduct' ? 'productId' : `${itemAccessor}Id`;
  const prettyAccesor = pretiffyTypeOfItem(itemAccessor);

  const columns = [
    {
      Header: `${prettyAccesor.replace(/\b\w/g, (c) => c.toUpperCase())} name`,
      accessor: 'itemName',
    },
    {
      ...(itemAccessor === 'tag' && {
        Header: 'People',
        accessor: 'people',
      }),
      ...(itemAccessor === 'automation' && {
        Header: 'Entered',
        accessor: 'people',
      }),
      ...(itemAccessor === 'list' && {
        Header: '',
        accessor: 'people',
      }),
      ...(itemAccessor === 'stripeProduct' && {
        Header: '',
        accessor: 'people',
      }),
    },
    {
      ...(itemAccessor === 'tag' && {
        Header: 'Last applied',
        accessor: 'last',
      }),
      ...(itemAccessor === 'automation' && {
        Header: 'Created',
        accessor: 'last',
      }),
      ...(itemAccessor === 'list' && {
        Header: 'Created',
        accessor: 'last',
      }),
      ...(itemAccessor === 'stripeProduct' && {
        Header: 'Created',
        accessor: 'last',
      }),
    },
  ];

  const tableData = dataChunk.map((item) => {
    return {
      itemName: item.name,
      people: item.count,
      last: pretiffyLastUpdate(item.timestamp),
      [itemId]: item[itemId],
    };
  });

  const classNames = {
    table: 'w-full table-auto',
    container: 'pb-4 pt-0 mt-2',
    header: {
      headerCell: 'text-white text-xs text-left border-t-0 p-1 bg-wmxHighlightDark-100 whitespace-nowrap',
      headerRow: 'bg-wmxBgDark-100',
      thead: 'text-white ',
    },
    body: {
      bodyCell: 'p-2 pr-4 text-white text-sm w-auto',
      bodyRow: 'border-b border-t border-black border-opacity-20 hover:bg-wmxBgDark-100',
    },
  };

  return (
    <>
      {condition === 'Contains' && (
        <div className="p-2 text-xs text-white mr-2 mt-2">All {prettyAccesor}s included:</div>
      )}
      <Table
        columns={columns}
        data={tableData.filter((item) => item.itemName)}
        hasMoreData={hasMoreData}
        setNextChunk={fetchMoreData}
        name="searchItems"
        classNames={classNames}
        onRowClick={({ row }) => onRowClick({ row, condition, dataChunk })}
        height={(condition === 'Contains' && 'calc(80vh - 285px)') || 'calc(80vh - 200px)'}
        autoInfiniteScrollOverflow
      />
      {condition === 'Contains' && data && (
        <button
          type="button"
          className="px-8 py-2 bg-wmxPrimary-100 text-base rounded-lg hover:bg-wmxPrimary-200 mt-4 disabled:opacity-50 text-white"
          onClick={onDoneClick}
        >
          Done
        </button>
      )}
    </>
  );
}

export default withApolloQuery(SearchItemsTable);

SearchItemsTable.propTypes = {
  data: PropTypes.object,
  itemAccessor: PropTypes.string,
  dataPath: PropTypes.string,
  queryParams: PropTypes.object,
  condition: PropTypes.oneOf(['Select', 'Contains']),
  fetchMore: PropTypes.func,
  setTagsIdContains: PropTypes.func,
  onRowClick: PropTypes.func,
  onDoneClick: PropTypes.func,
};
