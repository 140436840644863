import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { routes, navigate, useParams, useLocation } from '@redwoodjs/router';
import ReactSplitPane from 'react-split-pane';
import { useRecoilValue } from 'recoil';

import useRightPane from 'src/customHooks/useRightPane';
import useLocalStorage from 'src/customHooks/useLocalStorage';
import useOrderBy from 'src/components/Analyze/Overview/hooks/useOrderBy';
import { useOverviewReportsContext } from 'src/components/Analyze/Overview/hooks/overviewProvider';

import { useTriggerPageSeenEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';

import { EmptyComponent } from 'src/components/Generic';
import { OverviewQueryOptions } from 'src/components/Analyze/Overview/OverviewTableLayout/OverviewTableLayout';

import OverviewTable, {
  OverviewTable as OverviewTableWithoutHOC,
} from 'src/components/Analyze/Overview/OverviewTable/OverviewTable';

import OverviewSkeleton from 'src/components/Analyze/Overview/OverviewSkeleton/OverviewSkeleton';
import DetailModal from 'src/components/Analyze/DetailModal/DetailModal';
import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';
import FunnelRightPane from 'src/components/Funnel/FunnelRightPane/FunnelRightPane';
import {
  getAutomationTableProps,
  getEmailTableProps,
} from 'src/components/Analyze/Overview/OverviewTable/tableSettings';
import {
  getEmailsOverviewOrderByDefaults as getOrderByDefaults,
  reportTypes,
  triggerReportEngagementEvent,
} from 'src/components/Analyze/Overview/OverviewTable/tableSettingsHelpers';

import {
  EMAILS_QUERY_WITH_URL,
  EMAILS_LEADS_QUERY_WITH_URL,
  EMAILS_CUSTOMERS_QUERY,
  AUTOMATIONS_QUERY,
  AUTOMATIONS_LEADS_QUERY_WITH_URL,
  AUTOMATIONS_CUSTOMERS_QUERY,
  EMAILS_IN_AUTOMATION_WITH_URL,
} from 'src/components/Analyze/Overview/OverviewTable/tableQueries';
import MainContainerLayout from 'src/layouts/MainContainerLayout/MainContainerLayout';
import MainHeaderLayout from 'src/layouts/MainHeaderLayout/MainHeaderLayout';
import MainCardLayout from 'src/layouts/MainCardLayout/MainCardLayout';

const RIGHT_DEFAULT_SIZE = 956;
const RENDER_EACH_SCROLL_FOR_TABLE = 60;
export default function OverviewView({ isModalOpen, initialConvWindow }) {
  const {
    modalPropsByView,
    overviewTableProps,
    isRightPaneOpen,
    rightPaneDefaultSize,
    onRightPaneDragStarted,
    onRightPaneDragFinished,
    rightPaneStyles,
    rightPaneRef,
    onlyTypes,
    navigateBack,
    clickAnalyzePath,
    onRightPaneClosing,
    isRightPaneFetchingData,
    modalType,
    convWindowNum,
    // eslint-disable-next-line no-use-before-define
  } = useOverviewView({ isModalOpen, initialConvWindow });

  return (
    <>
      <ReactSplitPane
        split="vertical"
        className="bg-wmxBgDark-300"
        style={{ height: null }}
        maxSize={1100}
        size={rightPaneDefaultSize}
        primary="second"
        pane2Style={rightPaneStyles}
        onDragStarted={onRightPaneDragStarted}
        onDragFinished={onRightPaneDragFinished}
        allowResize={false}
      >
        <OverviewTable {...overviewTableProps} />

        {(isRightPaneOpen && (
          <FunnelRightPane mainContainerRef={rightPaneRef}>
            <DetailModal
              type={modalType}
              isOpen={isModalOpen}
              onModalClose={onRightPaneClosing}
              convWindow={convWindowNum}
              onlyTypes={onlyTypes}
              navigateBack={navigateBack}
              clickAnalyzePath={clickAnalyzePath}
              onRightPaneClosing={onRightPaneClosing}
              isRightPaneFetchingData={isRightPaneFetchingData}
              {...modalPropsByView}
            />
          </FunnelRightPane>
        )) || <></>}
      </ReactSplitPane>
    </>
  );
}

const useOverviewView = ({ isModalOpen, initialConvWindow }) => {
  const { pathname } = useLocation();
  const overviewType = pathname.split('/')[2];
  const overviewsPathNames = [reportTypes.emails, reportTypes.automations];
  const pathnameIdx = overviewsPathNames.findIndex((view) => view === overviewType);
  const activeButton = pathnameIdx !== -1 ? pathnameIdx : 0;
  const isEmailsInAutomationsModal =
    pathname.includes(reportTypes.automations) && pathname.includes(reportTypes.emails);
  const typeOfReport = overviewsPathNames[activeButton];

  const { timeRange, label: timeRangeLabel } = useRecoilValue(RecoilTimeRange);
  const [selectedConvWindow, setSelectedConvWindow] = useState(initialConvWindow || '7');

  const [lastSessionMetrics = {}] = useLocalStorage(overviewsPathNames[activeButton]);
  const localCacheData = lastSessionMetrics?.[timeRangeLabel]?.[selectedConvWindow];

  const { overviewModal, overviewTypeId: clickedItemId, modalType } = useParams();
  const {
    rightPane: { isRightPaneFetchingData },
  } = useOverviewReportsContext();

  const onHeaderClick = ({ newOrderBy, newOrderByDirection }) => {
    const payload = {
      detailType: 'Ordered column',
      column: newOrderBy,
      direction: newOrderByDirection,
    };

    return triggerReportEngagementEvent({ type: typeOfReport, payload });
  };

  const { columnOrderBy, columnOrderByDirection, onOrderBy } = useOrderBy({
    typeOfReport,
    getOrderByDefaults,
    onClick: onHeaderClick,
  });

  const isReportDetailOpen = !!modalType;
  const eventPageName =
    activeButton === 0
      ? SEGMENT_TRACK_EVENTS_NAMES.emailReportPage
      : activeButton === 1
      ? SEGMENT_TRACK_EVENTS_NAMES.automationReportPage
      : null;
  useTriggerPageSeenEvent({ pageName: eventPageName, shouldEventBeSent: !isReportDetailOpen });

  const {
    isRightPaneOpen,
    rightPaneDefaultSize,
    onRightPaneDragStarted,
    onRightPaneDragFinished,
    rightPaneStyles,
    onRightPaneOpen,
    onRightPaneClose,
    rightPaneRef,
  } = useRightPane({ rightDefaultSize: RIGHT_DEFAULT_SIZE });

  useEffect(() => {
    if (isModalOpen) onRightPaneOpen();
  }, [isModalOpen, onRightPaneOpen]);

  const onRightPaneOpening = ({ ev, type, itemId, activeOverview }) => {
    ev.stopPropagation();
    if (isEmailsInAutomationsModal) {
      navigate(
        routes.emailsInAutomationsModal({
          automationId: clickedItemId,
          overviewModal: reportTypes.emails,
          overviewTypeId: itemId,
          modalType: type,
          convWindow: selectedConvWindow,
        })
      );
    } else {
      navigate(
        routes.analyzeModal({
          overviewType: activeOverview,
          overviewTypeId: itemId,
          modalType: type,
          convWindow: selectedConvWindow,
        })
      );
    }

    onRightPaneOpen();
  };

  const onRightPaneClosing = () => {
    onRightPaneClose();
    navigate(routes.analyzeByOverview({ overviewType }));
  };

  const usePath = useCallback(() => {
    const pathArray = pathname.split('/').slice(2);
    const onlyTypes = pathArray.filter((item, idx) => {
      return idx % 2 === 0;
    });
    return [onlyTypes, pathArray];
  }, [pathname]);

  const [onlyTypes, pathArray] = usePath();

  const navigateTo = (index) => {
    const pathnameSplited = pathname.split('/').slice(2); // /reports/automations/1234/emails/1234/customers
    const overviewTypePath = pathnameSplited[index];
    const itemId = pathnameSplited[index - 1];
    const isOneLevelDeep = index + 2 <= 3;
    if (isOneLevelDeep) {
      onRightPaneClosing();
      return;
    }

    navigate(
      routes.analyzeModal({
        overviewType,
        overviewTypeId: itemId,
        modalType: overviewTypePath,
        convWindow: selectedConvWindow,
      })
    );
  };

  const navigateBack = () => {
    const pathnameSplited = pathname.split('/'); // /reports/automations/1234/emails/1234/customers
    const overviewTypePath = pathnameSplited[pathnameSplited.length - 3];
    const itemId = pathnameSplited[pathnameSplited.length - 4];
    const isOneLevelDeep = pathnameSplited.length === 5;

    if (isOneLevelDeep) {
      onRightPaneClosing();
      return;
    }

    navigate(
      routes.analyzeModal({
        overviewType,
        overviewTypeId: itemId,
        modalType: overviewTypePath,
        convWindow: selectedConvWindow,
      })
    );
  };

  const clickAnalyzePath = (item) => {
    const index = pathArray.indexOf(item);
    const clonedPathArray = [...pathArray];
    clonedPathArray.slice(0, index);
    navigateTo(index);
  };

  const tableData = [
    {
      name: overviewsPathNames[0],
      dataKey: EMAILS_QUERY_WITH_URL.queryName,
      initialQuery: EMAILS_QUERY_WITH_URL.query,
      getTableProps: getEmailTableProps,
      customersDetails: {
        initialQuery: EMAILS_CUSTOMERS_QUERY.query,
        dataKey: EMAILS_CUSTOMERS_QUERY.queryName,
        queryVariables: {
          campaignId: clickedItemId,
        },
      },
      opensDetails: {
        initialQuery: EMAILS_LEADS_QUERY_WITH_URL.query,
        dataKey: EMAILS_LEADS_QUERY_WITH_URL.queryName,
        queryVariables: {
          campaignId: clickedItemId,
        },
      },
    },
    {
      name: overviewsPathNames[1],
      dataKey: AUTOMATIONS_QUERY.queryName,
      initialQuery: AUTOMATIONS_QUERY.query,
      getTableProps: getAutomationTableProps,
      customersDetails: {
        initialQuery: AUTOMATIONS_CUSTOMERS_QUERY.query,
        dataKey: AUTOMATIONS_CUSTOMERS_QUERY.queryName,
        queryVariables: {
          automationId: clickedItemId,
        },
      },
      opensDetails: {
        initialQuery: AUTOMATIONS_LEADS_QUERY_WITH_URL.query,
        dataKey: AUTOMATIONS_LEADS_QUERY_WITH_URL.queryName,
        queryVariables: {
          automationId: clickedItemId,
        },
      },
      emailsDetails: {
        onCellClick: onRightPaneOpening,
        dataKey: EMAILS_IN_AUTOMATION_WITH_URL.queryName,
        initialQuery: EMAILS_IN_AUTOMATION_WITH_URL.query,
        queryVariables: {
          automationId: clickedItemId,
        },
      },
    },
  ];

  const convWindowNum = selectedConvWindow === '∞' ? 999999 : +selectedConvWindow;

  const headerProps = {
    selectedConvWindow,
    setSelectedConvWindow: (optionName) => {
      triggerReportEngagementEvent({
        type: typeOfReport,
        payload: { detailType: 'Conversion window changed', conversionWindowSelected: optionName },
      });

      return setSelectedConvWindow(optionName);
    },
  };

  const activeTable = tableData[activeButton].name;

  const tablesProps = {
    ...tableData[activeButton],
    active: activeButton,
    activeTable,
    headerProps,
    queryParams: {
      fetchPolicy: 'network-only',
      variables: {
        offset: 0,
        limit: RENDER_EACH_SCROLL_FOR_TABLE,
        timeRange,
        convWindow: convWindowNum,
        orderBy: columnOrderBy,
        orderByDirection: columnOrderByDirection,
      },
    },
    onCellClick: onRightPaneOpening,
    onOrderBy,
    withLayout: true,
  };

  const { dataKey } = tableData[activeButton];

  function TableLoadingComponent() {
    return localCacheData && localCacheData[dataKey]?.length > 0 ? (
      <OverviewTableWithoutHOC {...tablesProps} localCacheData={localCacheData} />
    ) : (
      <MainContainerLayout>
        <MainHeaderLayout />
        <MainCardLayout>
          <OverviewSkeleton headerProps={headerProps} />
        </MainCardLayout>
      </MainContainerLayout>
    );
  }

  const overviewTableProps = {
    ...tablesProps,
    isLoadingRightPaneTable: isRightPaneFetchingData,
    LoadingComponent: () => <TableLoadingComponent />,
    EmptyComponent: () => (
      <MainContainerLayout>
        <MainHeaderLayout>
          <OverviewQueryOptions {...tablesProps.headerProps} />
        </MainHeaderLayout>
        <MainCardLayout>
          <EmptyComponent />
        </MainCardLayout>
      </MainContainerLayout>
    ),
  };

  const activeModal = overviewModal === reportTypes.emails ? 0 : activeButton;
  const modalPropsByView = tableData[activeModal][`${modalType}Details`];

  return {
    modalPropsByView,
    overviewTableProps,
    isRightPaneOpen,
    rightPaneDefaultSize,
    onRightPaneDragStarted,
    onRightPaneDragFinished,
    rightPaneStyles,
    rightPaneRef,
    onlyTypes,
    navigateBack,
    clickAnalyzePath,
    onRightPaneClosing,
    isRightPaneFetchingData,
    modalType,
    convWindowNum,
  };
};

OverviewView.propTypes = {
  isModalOpen: PropTypes.bool,
  initialConvWindow: PropTypes.oneOf(['7', '30', '∞']),
};
