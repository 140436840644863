import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { navigate, routes } from '@redwoodjs/router';

import { useDashboard } from 'src/contexts/Dashboard';
import useCustomerSegment from 'src/customHooks/useCustomerSegment';
import useCurrentUser from 'src/customHooks/useCurrentUser';
import { useTimeSeries } from 'src/lib/graphsHelpers/graphsHelpers';

import { DefinePeopleErrorMessage } from 'src/layouts/PeopleLayout/PeopleLayout';
import Metric from 'src/components/Dashboard/MetricItem/MetricItem';
import NewCustomersChartCell from 'src/components/Cells/NewCustomersChartCell/NewCustomersChartCell';
import { Spacer, HSpacer } from '../Generic/LayoutUtils/LayoutUtils';

const NewCustomerMetric = ({ value, change, bg = true, page, prevValue }) => {
  const { timeRange, timeRangeLabel, editMode } = useDashboard();
  const { segmentName: customersSegmentName } = useCustomerSegment();
  const { getTimeSerie } = useTimeSeries();
  const timeseries = useMemo(() => getTimeSerie(timeRange, timeRangeLabel), [timeRange, getTimeSerie, timeRangeLabel]);
  const {
    hasTagsCustomerSegment: isSegmentInDatabaseCreated,
    stripeIntegration: { isReady: isUserWithStripe },
  } = useCurrentUser();
  const boxRef = useRef();
  const boxWidth = boxRef?.current?.getBoundingClientRect().width;
  const boxHeight = boxRef?.current?.getBoundingClientRect().height;

  const isAcUserWithoutSegment = !isSegmentInDatabaseCreated && !isUserWithStripe;

  const segmentBuilderTooltip = isUserWithStripe
    ? 'Amount of new customers generated over the time range. A contact becomes a customer when a first successful charge is made.'
    : isSegmentInDatabaseCreated
    ? 'Amount of new customers generated over the time range.'
    : 'In order to track customer conversions & revenue, please define your customer segment. Select the tag(s) that you apply when someone becomes a customer.';

  function WithoutSegmentMetricBody() {
    return (
      <Metric.Body>
        <Metric.Value>
          <DefineCustomerSegmentMessage onClick={() => navigate(routes.peopleCustomers())} />
        </Metric.Value>
      </Metric.Body>
    );
  }

  const isOpenButton = !editMode && !isAcUserWithoutSegment;

  return (
    <div ref={boxRef} className="h-full w-full">
      <Metric.Root bg={!bg ? 'transparent' : 'h-100'} editMode={editMode}>
        <Metric.Top openButton={isOpenButton}>
          <Metric.Title>{customersSegmentName}</Metric.Title>
          <Metric.Tooltip absolute={false}>{segmentBuilderTooltip}</Metric.Tooltip>
          {isOpenButton && <Metric.Button page={page} />}
        </Metric.Top>
        {isAcUserWithoutSegment ? (
          <WithoutSegmentMetricBody />
        ) : (
          <>
            <Metric.Body>
              <Metric.Value>{value}</Metric.Value>
              <Metric.Change>{change}</Metric.Change>
              <HSpacer />
            </Metric.Body>
            <Metric.MetricSubtitle size="xs">compared to {prevValue}</Metric.MetricSubtitle>

            {boxHeight > 150 && !editMode && (
              <>
                <Spacer size="xs" />
                <Metric.MetricChart>
                  <NewCustomersChartCell timeSeries={timeseries} boxHeight={boxHeight} boxWidth={boxWidth} />
                </Metric.MetricChart>
              </>
            )}
          </>
        )}
      </Metric.Root>
    </div>
  );
};

NewCustomerMetric.propTypes = {
  bg: PropTypes.bool,
  change: PropTypes.any,
  page: PropTypes.any,
  value: PropTypes.any,
  prevValue: PropTypes.any,
};

export default NewCustomerMetric;

export function DefineCustomerSegmentMessage({ onClick }) {
  return <DefinePeopleErrorMessage onClick={onClick} text="to start tracking customer conversions and revenue." />;
}

DefineCustomerSegmentMessage.propTypes = {
  onClick: PropTypes.func,
};
