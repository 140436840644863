import PropTypes from 'prop-types';

export default function DotsHorizontalSvg({ svgClassName = '' }) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 25 21"
      className={`${svgClassName} fill-current hover:text-opacity-50`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.44272 10.5H5.4525M12.2879 10.5H12.2976M19.133 10.5H19.1428M6.4206 10.5C6.4206 10.7321 6.31757 10.9546 6.13418 11.1187C5.9508 11.2828 5.70207 11.375 5.44272 11.375C5.18337 11.375 4.93464 11.2828 4.75126 11.1187C4.56787 10.9546 4.46484 10.7321 4.46484 10.5C4.46484 10.2679 4.56787 10.0454 4.75126 9.88128C4.93464 9.71719 5.18337 9.625 5.44272 9.625C5.70207 9.625 5.9508 9.71719 6.13418 9.88128C6.31757 10.0454 6.4206 10.2679 6.4206 10.5ZM13.2657 10.5C13.2657 10.7321 13.1627 10.9546 12.9793 11.1187C12.7959 11.2828 12.5472 11.375 12.2879 11.375C12.0285 11.375 11.7798 11.2828 11.5964 11.1187C11.413 10.9546 11.31 10.7321 11.31 10.5C11.31 10.2679 11.413 10.0454 11.5964 9.88128C11.7798 9.71719 12.0285 9.625 12.2879 9.625C12.5472 9.625 12.7959 9.71719 12.9793 9.88128C13.1627 10.0454 13.2657 10.2679 13.2657 10.5ZM20.1109 10.5C20.1109 10.7321 20.0078 10.9546 19.8245 11.1187C19.6411 11.2828 19.3923 11.375 19.133 11.375C18.8736 11.375 18.6249 11.2828 18.4415 11.1187C18.2581 10.9546 18.1551 10.7321 18.1551 10.5C18.1551 10.2679 18.2581 10.0454 18.4415 9.88128C18.6249 9.71719 18.8736 9.625 19.133 9.625C19.3923 9.625 19.6411 9.71719 19.8245 9.88128C20.0078 10.0454 20.1109 10.2679 20.1109 10.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

DotsHorizontalSvg.propTypes = {
  svgClassName: PropTypes.string,
};
