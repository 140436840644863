/* -------------------------------------------------------------------------------------------------
 * Right now it supports to receive an array like a normal response from a graphql function
 * And it also supports refectching an object with a data key with and an array
 * -----------------------------------------------------------------------------------------------*/

import { useState, useEffect, useRef } from 'react';
import getObjectPath from 'lodash.get';

export default function useInfiniteScroll({ scrollLength, incomingData, fetchMore, fetchMoreQuery, fetchMoreParams }) {
  const { dataArray: incomingDataArray, key: incomingDataKey, path: incomingPath } = incomingData;

  const [dataChunk, setDataChunk] = useState(incomingDataArray || []);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isDataChunkFinished, setIsDataChunkFinished] = useState(false);
  const isCancelled = useRef(false);

  const mergeIncomingData = (dataChunkLocal, newData) => {
    const alreadyFetchedData = dataChunkLocal.slice();

    if (newData) {
      alreadyFetchedData.push(...newData);
    } else {
      setHasMoreData(false);
    }

    return setDataChunk(alreadyFetchedData);
  };

  const setNewIncomingData = (newData, isPagination = false) => {
    /* -------------------------------------------------------------------------------------------------
     * Sometimes a
     * Apparently, when Apollo makes a new Query, sends a data
     * or incomingData in this case, with an empty Array and refreshes the dataChunk value.
     * -----------------------------------------------------------------------------------------------*/
    const isUseQueryTriggeredWithPagination = !!fetchMoreParams.variables.offset;

    /* -------------------------------------------------------------------------------------------------
     * This is to avoid that a cached result merges with other cached results.
     * Apparently, when Apollo makes a new Query, sends a data
     * or incomingData in this case, with an empty Array and refreshes the dataChunk value.
     * -----------------------------------------------------------------------------------------------*/

    /* -------------------------------------------------------------------------------------------------
     * But when the result is cached, this empty array process does not happen.
     * So here, we want to diferrentiate pagination process from cached process.
     * Pagination should merge results, while cached should just show that result, deleting
     * any previous information
     * -----------------------------------------------------------------------------------------------*/

    if (!isPagination && !isUseQueryTriggeredWithPagination) return setDataChunk(newData);

    return mergeIncomingData(dataChunk, newData);
  };

  const shouldFetchAgain = dataChunk.length % scrollLength === 0;

  useEffect(() => {
    if (hasMoreData && !shouldFetchAgain && !isCancelled.current) {
      setHasMoreData(false);
      setIsDataChunkFinished(true);
    }

    if (hasMoreData && isDataChunkFinished && !isCancelled.current) setHasMoreData(false);

    if (!hasMoreData && shouldFetchAgain && !isDataChunkFinished && !isCancelled.current) setHasMoreData(true);
  }, [hasMoreData, shouldFetchAgain, isDataChunkFinished]);

  useEffect(() => {
    if (incomingDataArray && !isCancelled.current) {
      setNewIncomingData(incomingDataArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingDataArray]);

  useEffect(() => {
    return () => {
      isCancelled.current = true;
    };
  }, []);

  const fetchMoreData = async () => {
    if (isCancelled.current) {
      return false;
    }

    setHasMoreData(shouldFetchAgain);

    const fetchNextChunk = async () => {
      if (typeof fetchMore !== 'function') return () => {};
      const fetchParams = { ...fetchMoreParams };

      if (fetchMoreQuery) {
        fetchParams.query = { ...fetchMoreQuery };
      }
      fetchParams.variables = { ...fetchMoreParams.variables };
      fetchParams.variables.offset = dataChunk.length;

      return fetchMore(fetchParams);
    };

    if (shouldFetchAgain) {
      const { data } = await fetchNextChunk();
      let dataToMerge;

      // if (data.length < 1) return false;

      if (incomingPath) {
        dataToMerge = getObjectPath(data, incomingPath);
      } else {
        dataToMerge = data[incomingDataKey];
      }

      if (isCancelled.current) {
        return false;
      }

      // When a fetch more comes back empty because the amount of the chunk is modulus 0 with scroll length
      if (!dataToMerge || dataToMerge.length === 0) setIsDataChunkFinished(true);

      return setNewIncomingData(dataToMerge, true);
    }

    return false;
  };

  return {
    fetchMoreData,
    hasMoreData,
    dataChunk,
  };
}
