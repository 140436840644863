import { useAccountPageContext, AccountPageProvider } from 'src/components/Account/AccountContext/useAccountContext';
import AccountsCell from 'src/components/Account/AccountsCell/AccountsCell';

const AccountsPage = () => {
  return (
    <AccountPageProvider>
      <AccountsList />
    </AccountPageProvider>
  );
};

const AccountsList = () => {
  const { columnOrderBy, columnOrderByDirection } = useAccountPageContext();

  return <AccountsCell orderByRelation={columnOrderBy} orderByDirection={columnOrderByDirection} />;
};

export default AccountsPage;
