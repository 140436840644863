import PropTypes from 'prop-types';
import CurrencyDropdown, {
  currenciesPropTypes,
  classNamesPropTypes,
} from 'src/components/CurrencyDropdown/CurrencyDropdown';

import { Error, LoadingComponent } from 'src/components/Generic';
import { GET_CURRENCIES } from 'src/pages/GeneralSettingsPage/GeneralSettingsQueries';

export const QUERY = GET_CURRENCIES;

export const Loading = (props) => {
  const initialOptionsButtons = [{ name: <LoadingComponent /> }];
  return <CurrencyDropdown {...{ ...props, currencies: initialOptionsButtons }} />;
};

export const Empty = () => <></>;

export const Failure = ({ error }) => <Error errorMessage={error.message} />;

export const Success = ({ getCurrencies: currencies, classNames, isOpen, setIsOpen }) => {
  return <CurrencyDropdown {...{ classNames, isOpen, setIsOpen, currencies }} />;
};

Loading.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  classNames: classNamesPropTypes,
};

Failure.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

Success.propTypes = {
  getCurrencies: currenciesPropTypes,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  classNames: classNamesPropTypes,
};
