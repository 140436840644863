const { ORDER_COLUMN_DIRECTIONS } = require('../reports/index');

const ORDER_BY_RECORDS_ENUM = {
  name: 'NAME',
  email: 'EMAIL',
  timestamp: 'TIMESTAMP',
  lastUpdated: 'LAST_UPDATED',
};

const SHARED_SETTINGS_BY_DATAPOINT = {
  tag: {
    URLName: 'tags',
    defaultOrderBy: {
      column: ORDER_BY_RECORDS_ENUM.lastUpdated,
      direction: ORDER_COLUMN_DIRECTIONS.desc,
    },
  },
  list: {
    URLName: 'lists',
    defaultOrderBy: {
      column: ORDER_BY_RECORDS_ENUM.lastUpdated,
      direction: ORDER_COLUMN_DIRECTIONS.desc,
    },
  },
  automation: {
    URLName: 'automations',
    defaultOrderBy: {
      column: ORDER_BY_RECORDS_ENUM.lastUpdated,
      direction: ORDER_COLUMN_DIRECTIONS.desc,
    },
  },
  campaign: {
    URLName: 'emails',
    defaultOrderBy: {
      column: ORDER_BY_RECORDS_ENUM.lastUpdated,
      direction: ORDER_COLUMN_DIRECTIONS.desc,
    },
  },
  contact: {
    URLName: 'contacts',
    defaultOrderBy: {
      column: ORDER_BY_RECORDS_ENUM.lastUpdated,
      direction: ORDER_COLUMN_DIRECTIONS.desc,
    },
  },
  automationsEvents: {
    URLName: 'automation-events',
    defaultOrderBy: {
      column: ORDER_BY_RECORDS_ENUM.timestamp,
      direction: ORDER_COLUMN_DIRECTIONS.desc,
    },
  },
  tagsAdded: {
    URLName: 'tags-contacts',
    defaultOrderBy: {
      column: ORDER_BY_RECORDS_ENUM.timestamp,
      direction: ORDER_COLUMN_DIRECTIONS.desc,
    },
  },
  subscriptions: {
    URLName: 'list-subscriptions',
    defaultOrderBy: {
      column: ORDER_BY_RECORDS_ENUM.timestamp,
      direction: ORDER_COLUMN_DIRECTIONS.desc,
    },
  },
  opens: {
    URLName: 'opens',
    defaultOrderBy: {
      column: ORDER_BY_RECORDS_ENUM.timestamp,
      direction: ORDER_COLUMN_DIRECTIONS.desc,
    },
  },
};

const errors = {
  notSupportedDatapointProvided: 'A not supported or wrong datapoint was provided. ',
};

module.exports = {
  SHARED_SETTINGS_BY_DATAPOINT,
  ORDER_BY_RECORDS_ENUM,
  ORDER_COLUMN_DIRECTIONS,
  errors,
};
