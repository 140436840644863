/* global gql */
import useInfiniteScroll from 'src/customHooks/useInfiniteScroll';

export const RECENT_ACTIVITIES_QUERY = {
  query: gql`
    query RECENT_ACTIVITY($limit: Int, $offset: Int) {
      recentActivities(offset: $offset, limit: $limit) {
        name
        timestamp
        type
        email
        amount
      }
    }
  `,
  queryName: 'recentActivities',
};

export const SCROLL_LENGTH = 100;

export default function useRecentActivities({ data, dataKey, fetchMore, queryParams }) {
  const { hasMoreData, dataChunk, fetchMoreData } = useInfiniteScroll({
    scrollLength: SCROLL_LENGTH,
    incomingData: {
      dataArray: data?.[dataKey],
      key: dataKey,
    },
    fetchMore,
    fetchMoreParams: queryParams,
  });

  return {
    hasMoreData,
    dataChunk,
    fetchMoreData,
  };
}
