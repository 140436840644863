import { useRef, useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';
import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';

import FunnelManagementLayout from 'src/layouts/FunnelManagementLayout/FunnelManagementLayout';
import FunnelManagementList, {
  FunnelManagementList as FunnelManagementListWithoutHOC,
} from 'src/components/Funnel/FunnelManagementList/FunnelManagementList';
import { twTableVerticalMeasurements } from 'src/components/Funnel/FunnelManagementList/listHelpers';
import { withFunnelListQuery } from 'src/lib/funnelHelpers/funnelHelpers';
import useLocalStorage from 'src/customHooks/useLocalStorage';
import { useTriggerPageSeenEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';

export default function FunnelManagementPage() {
  const { initialRows, mainCardLayoutRef } = useFunnelLayoutHeight();
  const [lastSessionMetrics = {}] = useLocalStorage('funnels');
  const { timeRange, label: timeRangeLabel } = useRecoilValue(RecoilTimeRange);

  const localCacheData = lastSessionMetrics?.[timeRangeLabel]?.tableRows || [];

  useTriggerPageSeenEvent({ pageName: SEGMENT_TRACK_EVENTS_NAMES.funnelListPage });

  return (
    <FunnelManagementLayout mainCardRef={mainCardLayoutRef}>
      {initialRows &&
        withFunnelListQuery(FunnelManagementList, {
          maxScroll: initialRows,
          fetchPolicy: 'no-cache',
          timeRange,
          onlyRetrieveWithParamsChange: true, // solving edge case. More in withApolloQuery component
          LoadingComponent: (props) => <FunnelManagementListWithoutHOC {...props} localCacheData={localCacheData} />,
        })}
    </FunnelManagementLayout>
  );
}

function useFunnelLayoutHeight() {
  const mainCardLayoutRef = useRef(null);
  const [initialRows, setInitialRows] = useState(null);

  useEffect(() => {
    const { height: cardLayoutHeight } = mainCardLayoutRef.current.getBoundingClientRect();
    const { headers, cells, table, container } = twTableVerticalMeasurements;

    const getVerticalValuesInPx = (componentObject) => {
      const verticalValues = Object.values(componentObject);
      return verticalValues.reduce((accum, num) => accum + num) * 4;
    };

    const containerVerticalValues = getVerticalValuesInPx(container);
    const tableVerticalValues = getVerticalValuesInPx(table);
    const headersVerticalValues = getVerticalValuesInPx(headers);
    const oneRowVerticalValue = getVerticalValuesInPx(cells);

    const spaceLeftForRows = cardLayoutHeight - tableVerticalValues - containerVerticalValues - headersVerticalValues;
    const amountOfRowsThatFitInContainer = spaceLeftForRows / oneRowVerticalValue;
    const ADDITIONAL_SAFETY_ROWS = 3;

    const rowsToFetch = Math.floor(amountOfRowsThatFitInContainer) + ADDITIONAL_SAFETY_ROWS;

    setInitialRows(rowsToFetch);
  }, []);

  return {
    initialRows,
    mainCardLayoutRef,
  };
}
