/* global gql */
import PropTypes from 'prop-types';
import { RevenuePerLead } from 'src/components/Funnel/PresentationView/Metrics/ConvRateOfCombinedLeads/ConvRateOfCombinedLeads';
import { EmptyComponent, LoadingComponent, Error } from 'src/components/Generic';

export const QUERY = gql`
  query GET_FUNNEL_CUSTOMERS_AND_REVENUE($funnelItems: [[FunnelItemsInput!]]!, $timeRange: [TimeRangeInput]) {
    getFunnelCustomersAndRevenue(funnelItems: $funnelItems, timeRange: $timeRange) {
      customers
      revenue
    }
  }
`;

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'cache-first' };
};

export const Loading = () => <LoadingComponent />;

export const Empty = () => <EmptyComponent />;

export const Failure = ({ error }) => <Error errorMessage={error.message} />;

export const Success = ({ getFunnelCustomersAndRevenue: [{ revenue: revenueWithoutFormat }], leadsCount }) => {
  return <RevenuePerLead revenueWithoutFormat={revenueWithoutFormat} leadsCount={leadsCount} />;
};

Success.propTypes = {
  getFunnelCustomersAndRevenue: PropTypes.arrayOf(PropTypes.object),
  leadsCount: PropTypes.number,
};
