/* eslint-disable no-use-before-define */
import { useAuth } from '@redwoodjs/auth';
import { useEffect, useState, useRef } from 'react';
import { INTEGRATION_STATUSES, PROVIDERS } from 'wmx-shared-code/accountGlobalVariables';
import { routes, navigate } from '@redwoodjs/router';
import dayjs from 'dayjs';

const DATAPOINTS = {
  activecampaign: {
    avgTimes: {
      Tags: 400,
      Lists: 120,
      Campaigns: 400,
      Automations: 1000,
      Contacts: 1200,
      Events: 3000,
    },
  },
  stripe: {
    avgTimes: {
      Customers: 400, // seg
      Charges: 460, // seg 5mins
      Invoices: 600,
      Subscriptions: 360, // seg 2min
      Prices: 240,
      Products: 200,
    },
  },
};

const openSocketConnection = ({ provider, accountId }) => {
  const stage = process.env.STAGE === 'prod' ? 'prod' : 'prev';
  const id = process.env.STAGE === 'prod' ? '8nlw39y5t4' : '8f91iikuuj';
  // const id = '8nlw39y5t4';
  return new WebSocket(
    `wss://${id}.execute-api.us-east-1.amazonaws.com/${stage}?provider=${provider}&accountid=${accountId}`
  );
};

export const useIntegrationProgress = ({ provider }) => {
  const { currentUser, reauthenticate } = useAuth();
  const { accountId } = currentUser;
  const {
    acIntegration: { isReady: acIsReady } = {},
    stripeIntegration: { status: stripeStatus },
  } = currentUser;
  const [totalCompleted, setTotalCompleted] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [datapointsPlan] = useState(Object.keys(DATAPOINTS[provider].avgTimes));
  const [startTime, setStartTime] = useState(null);
  const [update, setUpdate] = useState(1);
  const ws = useRef(null);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (!redirect || !totalCompleted) return;
    if (
      (provider === PROVIDERS.activeCampaign && stripeStatus !== INTEGRATION_STATUSES.importing) ||
      (provider === PROVIDERS.stripe && acIsReady)
    ) {
      navigate(routes.dashboard());
    }
  }, [totalCompleted, currentUser, provider, stripeStatus, acIsReady, redirect]);

  useEffect(() => {
    ws.current = openSocketConnection({ provider, accountId });
    ws.current.onopen = () => {
      ws.current.send(
        JSON.stringify(
          {
            action: 'status',
            provider,
            accountId,
          },
          null,
          '\t'
        )
      );
    };
    ws.current.onclose = () => {
      setTimeout(() => {
        ws.current = openSocketConnection({ provider, accountId });
      }, 1000);
    };
    ws.current.onmessage = (e) => {
      const message = e.data;
      const parsedMessage = JSON.parse(message);
      console.log('parsedMessage', parsedMessage);
      if (parsedMessage?.items?.length) {
        const orderedItems = parsedMessage.items.sort((a, b) => {
          const dateA = new Date(a.startTime);
          const dateB = new Date(b.startTime);
          return dateA - dateB;
        });
        const lastProcess = orderedItems[orderedItems.length - 1];
        const isCompleted = lastProcess.status === 'IntegrationDone';
        const isFailedStatus = lastProcess.status === 'failed';
        // if (isCompleted) redirectAfterDone();
        console.log('lastProcess', lastProcess);
        console.log('isFailedStatus', isFailedStatus);
        if (isFailedStatus) setIsFailed(true);
        if (lastProcess?.startTime) {
          console.log('startTime', lastProcess?.startTime);
          setStartTime(lastProcess.startTime);
        }
        // if (lastProcess?.datapointsPlan) {
        //   const plan = JSON.parse(lastProcess.datapointsPlan);
        //   if (datapointsPlan.length < 1) setDatapointsPlan(plan);
        // }
        if (isCompleted) {
          if (!totalCompleted) setTotalCompleted(true);
        }
      }
      if (parsedMessage['detail-type'] === 'FetchingFailure') {
        setIsFailed(true);
      }
      if (parsedMessage['detail-type'] === 'EstimationReady') {
        if (parsedMessage?.time) {
          setStartTime(parsedMessage?.time);
        }
        // if (parsedMessage?.detail?.meta?.datapointsPlan) {
        //   const plan = parsedMessage?.detail?.meta?.datapointsPlan;

        //   if (datapointsPlan.length < 1) setDatapointsPlan(plan);
        // }
      }
      if (parsedMessage['detail-type'] === 'IntegrationDone') {
        console.log('parsedMessage[detail-type]', parsedMessage['detail-type']);
        redirectAfterDone();
      }
      setUpdate((prev) => prev + 1);
    };

    return () => {
      ws.current.close();
    };
  }, []);

  const redirectAfterDone = () => {
    reauthenticate().then(() => {
      setRedirect(true);
      console.log('totalCompleted', totalCompleted);
      if (!totalCompleted) setTotalCompleted(true);
    });
  };

  const getTotalDuration = (datapoint) => {
    return DATAPOINTS[provider].avgTimes[datapoint];
  };

  const getCurrentProgress = ({ datapoint }) => {
    if (!startTime) return 0;
    const secFromStarting = dayjs.utc().unix() - dayjs.utc(startTime).local().unix();
    const totalAvgTime = getTotalDuration(datapoint);
    const currentProgressPercent = Math.ceil((secFromStarting / totalAvgTime) * 100);
    if (currentProgressPercent <= 0) return 0;
    if (currentProgressPercent >= 100) return 100;
    return currentProgressPercent;
  };

  return {
    datapointsPlan,
    getCurrentProgress,
    getTotalDuration,
    startTime,
    totalCompleted,
    acIsReady,
    update,
    isFailed,
  };
};
