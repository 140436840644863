import PropTypes from 'prop-types';
import { NavLink } from '@redwoodjs/router';
import { Flex, HSpacer } from '../Generic/LayoutUtils/LayoutUtils';

const Breadcrumb = ({ steps }) => {
  return (
    <Flex>
      <HSpacer size="xs" />
      {steps.map((step, i) => {
        const isLast = i === steps.length - 1;
        return (
          <>
            <NavLink
              className={`text-sm ${
                (isLast && 'text-wmxText-100') || 'text-wmxText-200'
              } rounded-md hover:bg-wmxHighlightDark-50 hover:text-wmxText-100 px-1.5 py-0.5`}
              to={step.route()}
            >
              {step.label}
            </NavLink>
            {!isLast && (
              <>
                <HSpacer size="xs" />
                <span className="text-sm text-wmxText-200">/</span>
                <HSpacer size="xs" />
              </>
            )}
          </>
        );
      })}
    </Flex>
  );
};

Breadcrumb.propTypes = {
  steps: PropTypes.array,
};

export default Breadcrumb;
