const ProviderCardLayout = ({ children, provider }) => {
  return (
    <div className=" w-full">
      <div className="p-4 justify-between">
        <div className="ml-1 leading-loose text-wmxText-200 mb-4 text-xs">{provider}</div>
        {children}
      </div>
    </div>
  );
};

export default ProviderCardLayout;
