/* eslint-disable react/prop-types */
import {
  addCampaignData,
  addAutomationData,
  withAutomationDetail,
  withCampaignDetail,
  addCampaignLink,
  addAutomationLink,
  getColumnsWithOrderBy,
  triggerReportEngagementEvent,
  reportTypes,
} from 'src/components/Analyze/Overview/OverviewTable/tableSettingsHelpers';
import CellLayout from 'src/components/Analyze/DetailModal/Tables/CellLayout/CellLayout';
import { NewCustomersTooltip } from 'src/components/Generic';
import { ColumnsTooltipText } from 'src/components/Generic/Tooltip/Tooltip';
import { ORDER_BY_EMAILS_ENUM, ORDER_COLUMN_DIRECTIONS } from 'wmx-shared-code/reports/emailsOverview';

const onClickClassName = 'hover:text-white cursor-pointer';

export const getEmailTableProps = ({
  dataChunk,
  onCellClick,
  onOrderBy,
  acUrl,
  isInsideModal: isEmailsInAutomation,
  activeTable: activeOverview,
  defaultCurrencyThreeLetters,
  isUserWithCustomerData,
}) => {
  const withElement = null;

  const extendedColumns = {
    columns: [
      {
        accessor: 0, // "Name"
        className: 'max-w-xs',
        cellChildren: (cellParams) => addCampaignLink({ ...cellParams, acUrl, withElement }),
      },
      {
        accessor: 'Opens',
        className: onClickClassName,
        cellChildren: (cellParams) =>
          withCampaignDetail({
            ...cellParams,
            onCellClick,
            typeOfModal: 'opens',
            activeOverview,
            withElement,
          }),
      },
      ...(isUserWithCustomerData
        ? [
            {
              accessor: 'Customers',
              className: onClickClassName,
              cellChildren: (cellParams) =>
                withCampaignDetail({
                  ...cellParams,
                  onCellClick,
                  typeOfModal: 'customers',
                  activeOverview,
                  withElement,
                }),
            },
          ]
        : []),
    ],
  };

  const modalExtendedColumns = {
    columns: extendedColumns.columns,
    defaultCellChildren: ({ renderedCell }) => <CellLayout>{renderedCell}</CellLayout>,
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      orderBy: ORDER_BY_EMAILS_ENUM.name,
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.asc,
      tooltipElement: <TooltipText text="The name of the email." />,
    },
    {
      Header: 'Leads',
      accessor: 'numberOfLeads',
      orderBy: ORDER_BY_EMAILS_ENUM.leads,
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
      tooltipElement: <TooltipText text="The amount of people who received this email." />,
    },
    {
      Header: 'Customers',
      accessor: 'numberOfCustomers',
      orderBy: ORDER_BY_EMAILS_ENUM.customers,
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
      tooltipElement: <CustomersTooltip isUserWithCustomerData={isUserWithCustomerData} />,
    },
    {
      Header: 'Opens',
      accessor: 'uniqueOpens',
      orderBy: ORDER_BY_EMAILS_ENUM.opens,
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
      tooltipElement: <TooltipText text="The amount of people that opened this email." />,
    },
    ...(isUserWithCustomerData
      ? [
          {
            Header: 'Revenue',
            accessor: 'revenue',
            orderBy: ORDER_BY_EMAILS_ENUM.revenue,
            orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
            tooltipElement: (
              <TooltipText
                text="The amount of revenue this email generated within the selected time period & conversion
                  window."
              />
            ),
          },
        ]
      : []),
    {
      Header: 'Conv. Rate',
      accessor: 'convRate',
      orderBy: ORDER_BY_EMAILS_ENUM.convRate,
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
      tooltipElement: (
        <TooltipText
          text="The percentage of people that opened the email and then also purchased within the
    selected time period & conversion window. This metric is calculated by dividing the
    customers by the opens."
        />
      ),
    },
  ];

  const columnsWithOrderByConfig = getColumnsWithOrderBy({ columns, onOrderBy });

  const extendedHeaders = {
    columns: columnsWithOrderByConfig,
  };

  return {
    name: 'emailTable',
    data: addCampaignData({ emails: dataChunk, isUserWithCustomerData, defaultCurrencyThreeLetters }),
    columns,
    extendRows: isEmailsInAutomation ? modalExtendedColumns : extendedColumns,
    extendHeader: extendedHeaders,
  };
};

export const getAutomationTableProps = ({
  dataChunk,
  onCellClick,
  onOrderBy,
  acUrl,
  activeTable: activeOverview,
  defaultCurrencyThreeLetters,
  isUserWithCustomerData,
}) => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      orderBy: ORDER_BY_EMAILS_ENUM.name,
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.asc,
      tooltipElement: <TooltipText text="The name of the automation." />,
    },
    {
      Header: 'Leads',
      accessor: 'numberOfLeads',
      orderBy: ORDER_BY_EMAILS_ENUM.leads,
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
      tooltipElement: <TooltipText text="The amount of people that entered the automation." />,
    },
    {
      Header: 'Customers',
      accessor: 'numberOfCustomers',
      orderBy: ORDER_BY_EMAILS_ENUM.customers,
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
      tooltipElement: <CustomersTooltip isUserWithCustomerData={isUserWithCustomerData} />,
    },
    {
      Header: 'Opens',
      accessor: 'uniqueOpens',
      orderBy: ORDER_BY_EMAILS_ENUM.opens,
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
      tooltipElement: <TooltipText text="The amount of times someone opened an email inside this automation." />,
    },
    ...(isUserWithCustomerData
      ? [
          {
            Header: 'Revenue',
            accessor: 'revenue',
            orderBy: ORDER_BY_EMAILS_ENUM.revenue,
            orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
            tooltipElement: (
              <TooltipText text="The amount of revenue this automation generated within the selected time period and conversion window." />
            ),
          },
        ]
      : []),
    {
      Header: 'Conv. Rate',
      accessor: 'convRate',
      orderBy: ORDER_BY_EMAILS_ENUM.convRate,
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
      tooltipElement: (
        <TooltipText text="The percentage of people that opened an email inside the automation and then also purchased within the selected time period and conversion window. This metric is calculated by dividing the customers by the opens." />
      ),
    },
  ];

  const columnsWithOrderByConfig = getColumnsWithOrderBy({ columns, onOrderBy });

  const extendedHeaders = {
    columns: columnsWithOrderByConfig,
  };

  const onRowClick = ({
    ev,
    row: {
      original: { automationId: itemId },
    },
  }) => {
    const payload = {
      automationClicked: itemId,
      detailType: 'Emails in automation detail opened',
    };

    triggerReportEngagementEvent({ type: reportTypes.automations, payload });
    return onCellClick({ ev, type: reportTypes.emails, itemId, activeOverview });
  };

  return {
    name: 'automationTable',
    data: addAutomationData({ automations: dataChunk, isUserWithCustomerData, defaultCurrencyThreeLetters }),
    onRowClick,
    columns,
    extendHeader: extendedHeaders,
    extendRows: {
      columns: [
        {
          accessor: 0, // "Name"
          className: 'max-w-xs',
          cellChildren: (cellParams) => addAutomationLink({ ...cellParams, acUrl }),
        },
        {
          accessor: 'Opens',
          className: onClickClassName,
          cellChildren: (cellParams) =>
            withAutomationDetail({
              ...cellParams,
              onCellClick,
              typeOfModal: 'opens',
              activeOverview,
            }),
        },
        ...(isUserWithCustomerData
          ? [
              {
                accessor: 'Customers',
                className: onClickClassName,
                cellChildren: (cellParams) =>
                  withAutomationDetail({
                    ...cellParams,
                    onCellClick,
                    typeOfModal: 'customers',
                    activeOverview,
                  }),
              },
            ]
          : []),
      ],
    },
  };
};

export const getOverviewTableClassNames = ({ activeTable, isInsideModal = false }) => {
  const automationRowHover = activeTable === reportTypes.automations ? ' cursor-pointer' : '';

  const overviewTableClassNames = {
    container:
      'overviewTable align-middle block max-w-full bg-transparent rounded-bl-lg rounded-br-lg mr-1 flex-grow px-4 pb-0',
    table: 'w-full pb-4',
    body: {
      tbody: 'bg-wmxBgDark-100 overflow-auto bg-wmxBgDark-400',
      bodyCell: `h-10 text-left pl-6 text-white my-1 py-3 ${
        (isInsideModal && 'text-xs') || 'text-sm'
      }  p-4 pb-4 first:rounded-l-md last:rounded-r-md `,
      bodyRow: `z-0 hover:bg-wmxBgDark-300 hover:bg-opacity-70 bg-wmxHighlightDark-100 hover:bg-wmxHighlightDark-200 ${automationRowHover} my-2 rounded-md shadow-2xl `,
    },
    header: {
      headerCell:
        'z-10 text-left h-12 pl-6 p-2 border-t-0 leading-4 text-white tracking-wider bg-wmxBgDark-200 text-xs font-normal',
    },
  };

  return overviewTableClassNames;
};

const TooltipText = ColumnsTooltipText;

const CustomersTooltip = ({ isUserWithCustomerData }) => {
  return !isUserWithCustomerData ? (
    <NewCustomersTooltip />
  ) : (
    <TooltipText
      text="The amount of customers that purchased within the selected time period & conversion
window."
    />
  );
};
