import PropTypes from 'prop-types';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { scrollbarStyles } from 'src/lib/generic/commonClasses';
import { Flex } from '../Generic/LayoutUtils/LayoutUtils';

const Dialog = ({ ariaParentId, close, dialogContentRef, children, isOpen }) => {
  return (
    <DialogOverlay
      className={`fixed ${scrollbarStyles} w-full`}
      onDismiss={close}
      isOpen={isOpen}
      style={{ zIndex: '99999' }}
    >
      <Flex items="center" justify="center" hfull grow>
        <DialogContent
          aria-label={ariaParentId}
          className="animate-fade-in-left w-full h-auto ml-auto mr-auto my-0 bg-transparent"
          ref={dialogContentRef}
        >
          {children}
        </DialogContent>
      </Flex>
    </DialogOverlay>
  );
};

Dialog.propTypes = {
  ariaParentId: PropTypes.any,
  children: PropTypes.any,
  close: PropTypes.any,
  dialogContentRef: PropTypes.any,
  isOpen: PropTypes.any,
};

export default Dialog;
