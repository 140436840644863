const { ORDER_COLUMN_DIRECTIONS } = require('./index');

const ORDER_BY_EMAILS_ENUM = {
  revenue: 'REVENUE',
  convRate: 'CONV_RATE',
  customers: 'CUSTOMERS',
  name: 'NAME',
  leads: 'LEADS',
  opens: 'OPENS',
};

const ORDER_BY_CUSTOMERS_ENUM = {
  email: 'EMAIL',
  createdAt: 'CREATED_AT',
  productName: 'PRODUCT_NAME',
  amount: 'AMOUNT',
};

module.exports = {
  ORDER_BY_EMAILS_ENUM,
  ORDER_COLUMN_DIRECTIONS,
  ORDER_BY_CUSTOMERS_ENUM,
};
