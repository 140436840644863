import PropTypes from 'prop-types';

import {
  useUpdateRightPaneCurrentData,
  useRightPaneNotLoadingAnymore,
} from 'src/components/Analyze/Overview/hooks/overviewProvider';
import useInfiniteScroll from 'src/customHooks/useInfiniteScroll';
import { tableClassNamesProps } from 'src/components/Generic/Tables/Table/Table';
import withApolloQuery from 'src/lib/HOC/withApolloQuery';
import CellLayout from 'src/components/Analyze/DetailModal/Tables/CellLayout/CellLayout';
import TableLink from 'src/components/Analyze/DetailModal/Tables/TableLink/TableLink';
import { ProfileActiveCampaignLogo } from 'src/components/Generic';
import { RightPaneHeader } from 'src/components/Funnel/FunnelRightPane/FunnelRightPane';

import * as WmxTable from 'src/components/Generic/Tables/WmxTable/WmxTable';

import { Navigation } from '../CustomersTable/CustomersTable';

export function LeadsTable({
  dataKey,
  scrollLength,
  queryParams,
  classNames,
  data,
  fetchMore,
  fetchMoreQuery,
  renderDefaultCell,
  isLoadingTable,
  navigateBack,
  onlyTypes,
  clickAnalyzePath,
  isRightPaneFetchingData,
  onRightPaneClosing,
}) {
  useUpdateRightPaneCurrentData({ data });
  useRightPaneNotLoadingAnymore({ isLoadingTable });

  const { dataChunk, hasMoreData, fetchMoreData } = useInfiniteScroll({
    scrollLength,
    incomingData: {
      dataArray: data?.[dataKey],
      key: dataKey,
    },
    fetchMore,
    fetchMoreQuery,
    fetchMoreParams: queryParams,
  });

  const isOpenedEmailsColumn = Object.keys(data?.[dataKey][0]).includes('openedEmails');
  const withOpenedEmailsColumn = {
    Header: 'Opened Emails',
    accessor: 'openedEmails',
  };

  const leadColumns = [
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Profiles',
      accessor: 'profiles',
    },
  ];

  if (isOpenedEmailsColumn) {
    leadColumns.push(withOpenedEmailsColumn);
  }

  const addContactLinkToActiveCampaign = ({ row, url }) => {
    const { contactId } = row.original;
    return (
      <CellLayout>
        {contactId && (
          <TableLink link={`https://${url}.activehosted.com/app/contacts/${contactId}`}>
            <ProfileActiveCampaignLogo classNames={{ raw: 'hover:opacity-70' }} />
          </TableLink>
        )}
      </CellLayout>
    );
  };

  const detailTableProps = {
    name: 'detailLeadsModal',
    columns: leadColumns,
    extendRows: {
      columns: [
        {
          accessor: 'Profiles',
          cellChildren: ({ renderedCell, row }) =>
            addContactLinkToActiveCampaign({
              renderedCell,
              row,
              url: data?.activeCampaignUrl,
            }),
        },
      ],
      defaultCellChildren: renderDefaultCell,
    },
    data: dataChunk,
    setNextChunk: fetchMoreData,
    hasMoreData,
    classNames: {
      ...classNames,
      body: {
        ...classNames.body,
        bodyCell: `${classNames.body.bodyCell}  last:text-right last:pr-6 last:pl-0 `,
      },
      header: {
        ...classNames.header,
        headerCell: `${classNames.header.headerCell} last:text-right last:pr-6 last:pl-0`,
      },
    },
    columnsOptions: true,
    compulsoryColumns: ['Email'],
    defaultColumns: ['Profiles'],
  };

  const navigationProps = { navigateBack, onlyTypes, clickAnalyzePath, isRightPaneFetchingData, onRightPaneClosing };

  return (
    <WmxTable.TableProvider tableProps={detailTableProps}>
      <RightPaneHeader onCrossButtonClick={onRightPaneClosing}>
        <Navigation {...navigationProps} />
      </RightPaneHeader>
      <WmxTable.Table />
    </WmxTable.TableProvider>
  );
}

export default withApolloQuery(LeadsTable);

LeadsTable.propTypes = {
  data: PropTypes.shape({
    activeCampaignUrl: PropTypes.string,
  }),
  renderDefaultCell: PropTypes.func,
  initialQuery: PropTypes.object,
  fetchMoreQuery: PropTypes.object,
  queryParams: PropTypes.shape({
    fetchPolicy: PropTypes.string,
    variables: PropTypes.shape({
      offset: PropTypes.number,
      limit: PropTypes.number,
      campaignId: PropTypes.string,
      automationId: PropTypes.string,
      timeRange: PropTypes.shape({
        start: PropTypes.object,
        end: PropTypes.object,
      }),
    }), // Going directly to withApolloQuery
  }),
  ...tableClassNamesProps,
};
