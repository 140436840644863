/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDashboard } from 'src/contexts/Dashboard';
import useDashboardLayout from 'src/customHooks/useDashboardLayout';
import { scrollbarStyles } from 'src/lib/generic/commonClasses';
import useDashboardQueriesHandlers from '../dashboardHelpers/useDashboardQueriesHandlers';

function DashboardTabs({ newTabRef, retrieveDashboard }) {
  const { dashboardNamesState, creatingNewDashboard } = useDashboard();

  const { createNewDashboard } = useDashboardLayout();

  return (
    <div className="h-8 ml-3 flex justify-center items-center max-w-max">
      <div className={`flex items-center overflow-scroll w-full h-full ${scrollbarStyles}`}>
        {dashboardNamesState?.map((el) => (
          <TabItem retrieveDashboard={retrieveDashboard} el={el} />
        ))}
      </div>
      {creatingNewDashboard && <NewTabInput newTabRef={newTabRef} />}
      {!creatingNewDashboard && <AddTabIcon createNewDashboard={createNewDashboard} />}
    </div>
  );
}

export default DashboardTabs;

function TabItem({ el, retrieveDashboard }) {
  // const { handleTabClick } = useDashboardLayout();
  const { editNameCallback } = useDashboardQueriesHandlers();
  const {
    tabSelected,
    editNameMode,
    setEditNameMode,
    creatingNewDashboard,
    setCreatingNewDashboard,
    setNewDashboardName,
    setEditMode,
    setTabSelected,
  } = useDashboard();
  const [editNameItem, setEditNameItem] = useState(false);

  useEffect(() => {
    if (!editNameMode) setEditNameItem(false);
  }, [editNameMode]);

  function handleTabClick(e) {
    if (creatingNewDashboard) {
      setCreatingNewDashboard(false);
      setNewDashboardName('');
    }
    setEditMode(false);
    setTabSelected(e.target.outerText);
    retrieveDashboard();
  }

  return editNameItem && editNameMode ? (
    <NewTabInput enterPressCallback={editNameCallback} />
  ) : (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <h1
      onClick={handleTabClick}
      onDoubleClick={() => {
        setEditNameItem(true);
        setEditNameMode(true);
      }}
      className={`mx-1 py-3 px-4 ${
        el === tabSelected ? 'border-2 border-wmxHighlightDark-50' : 'text-white'
      }  rounded cursor-pointer h-6 flex items-center justify-center text-white`}
    >
      {el}
    </h1>
  );
}

function NewTabInput({ newTabRef, enterPressCallback }) {
  const { newDashboardName, setNewDashboardName } = useDashboard();
  const { newTabHandler } = useDashboardLayout();

  return (
    <input // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      placeholder="name"
      ref={newTabRef}
      type="text"
      value={newDashboardName}
      onChange={(e) => setNewDashboardName(e.target.value)}
      onKeyPress={(e) => newTabHandler(e, enterPressCallback, newTabRef)}
      className="appearance-none w-20 h-6 focus-border-2 bg-wmxHighlightDark-50 text-white focus-border-wmxHighlightDark-50 text-sm p-2 rounded focus:outline-none"
    />
  );
}

function AddTabIcon() {
  const { createNewDashboard } = useDashboardLayout();

  return (
    <span
      role="button"
      className={`w-6 h-6 mr-3 ml-3 flex items-center justify-center rounded-full pointer-events-auto cursor-pointer bg-wmxPrimary-200 hover:bg-wmxPrimary-100 hover:shadow-md transition-all duration-250 `}
      onClick={createNewDashboard}
      tabIndex={0}
    >
      <span className="text-2xl px-3 plusIcon pointer-events-auto text-wmxText-200" />
    </span>
  );
}

DashboardTabs.propTypes = {
  newTabRef: PropTypes.any,
  retrieveDashboard: PropTypes.any,
};

TabItem.propTypes = {
  el: PropTypes.any,
  retrieveDashboard: PropTypes.any,
};

NewTabInput.propTypes = {
  newTabRef: PropTypes.any,
  enterPressCallback: PropTypes.any,
};
