import { useRecoilValue } from 'recoil';
import { useLocation } from '@redwoodjs/router';
import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';

import { usePeopleReportsContext, useTriggerPeoplePageVisited } from 'src/layouts/PeopleLayout/peopleReportsProvider';
import useOrderBy from 'src/components/Analyze/Overview/hooks/useOrderBy';

import { CUSTOMERS_PEOPLE_REPORT_QUERY } from 'src/components/PeopleReportsMenu/PeopleReportsTable/peopleReportsQueries';
import { errors } from 'wmx-shared-code/segments/segments';

import PeopleReportsTableLoadingSkeleton from 'src/components/PeopleReportsMenu/PeopleReportsTableLoadingSkeleton/PeopleReportsTableLoadingSkeleton';
import PeopleReportsTable, {
  PeopleReportsTable as PeopleReportsTableWithoutHOC,
  SegmentsHeader,
} from 'src/components/PeopleReportsMenu/PeopleReportsTable/PeopleReportsTable';

import { useSegmentBuilder } from 'src/contexts/SegmentBuilder/SegmentBuilder';
import useCustomerSegment from 'src/customHooks/useCustomerSegment';

import { getCustomersPeopleProps } from 'src/components/PeopleReportsMenu/PeopleReportsTable/peopleTableSettings';
import {
  getPeopleReportsOrderByDefaults as getOrderByDefaults,
  segmentDefaultTypes,
} from 'src/components/PeopleReportsMenu/PeopleReportsTable/peopleTableSettingsHelpers';
import { EmptyComponent } from 'src/components/Generic';
import { DefineCustomerSegmentMessage } from 'src/components/NewCustomerMetric/NewCustomerMetric';
import MainHeaderLayout from 'src/layouts/MainHeaderLayout/MainHeaderLayout';
import { PeopleQueryOptions, DefinePeopleErrorMessage } from 'src/layouts/PeopleLayout/PeopleLayout';
import MainContainerLayout from 'src/layouts/MainContainerLayout/MainContainerLayout';
import MainCardLayout from 'src/layouts/MainCardLayout/MainCardLayout';
import { useEffect } from 'react';

const RENDER_EACH_SCROLL_FOR_TABLE = 60;

export default function PeopleCustomersPage() {
  const { isAcCustomersSegmentNotDefined, onRightPaneOpen, onDialogOpen } = useSegmentBuilder();
  const { isTableFetching, setIsTableFetching, peopleTableData } = usePeopleReportsContext();
  const { timeRange } = useRecoilValue(RecoilTimeRange);
  const { segmentName: customerSegmentName, segmentId: customerSegmentId } = useCustomerSegment();

  useTriggerPeoplePageVisited({ segmentType: segmentDefaultTypes.customersPeople });
  useOpenRightPaneInFirstRender();

  const { columnOrderBy, columnOrderByDirection, onOrderBy } = useOrderBy({
    typeOfReport: segmentDefaultTypes.customersPeople,
    getOrderByDefaults,
    onClick: () => setIsTableFetching(true),
  });

  const customersPeopleProps = {
    dataKey: CUSTOMERS_PEOPLE_REPORT_QUERY.queryName,
    initialQuery: CUSTOMERS_PEOPLE_REPORT_QUERY.query,
    onOrderBy,
    title: customerSegmentName,
    LoadingComponent: () => <LoadingComponent />,
    EmptyComponent: () => <TableEmptyComponent />,
    queryParams: {
      fetchPolicy: 'cache-first',
      variables: {
        offset: 0,
        limit: RENDER_EACH_SCROLL_FOR_TABLE,
        orderBy: columnOrderBy,
        orderByDirection: columnOrderByDirection,
        timeRange,
      },
    },
    Error: (props) => <ErrorComponent {...props} />,
    getTableProps: getCustomersPeopleProps,
  };

  // eslint-disable-next-line react/prop-types
  function TableLayout({ children }) {
    return (
      <MainContainerLayout>
        <SegmentsHeader title={customerSegmentName} />
        <MainHeaderLayout>
          <PeopleQueryOptions />
        </MainHeaderLayout>
        <MainCardLayout>{children}</MainCardLayout>
      </MainContainerLayout>
    );
  }

  function LoadingComponent() {
    return isTableFetching ? (
      <PeopleReportsTableWithoutHOC {...customersPeopleProps} data={peopleTableData} isLoadingTable />
    ) : (
      <TableLayout>
        <PeopleReportsTableLoadingSkeleton />
      </TableLayout>
    );
  }

  const onCustomerSegmentMessageClick = () => {
    onRightPaneOpen();
    onDialogOpen();
  };

  // eslint-disable-next-line react/prop-types
  function ErrorComponent({ message }) {
    const isANonExistingTag = message === errors.nonExistingItemIdCallback({ itemNameInService: 'tag' });
    const { setEditingSegment, setNewEmptySegment } = useSegmentBuilder();

    const onClick = () => {
      onDialogOpen();
      onRightPaneOpen();
      setEditingSegment(true);
      setNewEmptySegment({ initSegment: { segmentId: customerSegmentId, segmentName: customerSegmentName } });
    };

    return (
      <TableLayout>
        <EmptyComponent>
          {isANonExistingTag ? (
            <>
              <span className="text-white  text-sm">
                One or more tags defined in this segment do not exist anymore.&nbsp;
              </span>
              <DefinePeopleErrorMessage text="to define your segment again." onClick={onClick} />
            </>
          ) : (
            <span>{message}</span>
          )}
        </EmptyComponent>
      </TableLayout>
    );
  }

  function TableEmptyComponent() {
    return (
      <TableLayout>
        <EmptyComponent>
          {isAcCustomersSegmentNotDefined && <DefineCustomerSegmentMessage onClick={onCustomerSegmentMessageClick} />}
        </EmptyComponent>
      </TableLayout>
    );
  }

  return isAcCustomersSegmentNotDefined ? <TableEmptyComponent /> : <PeopleReportsTable {...customersPeopleProps} />;
}

function useOpenRightPaneInFirstRender() {
  const { segmentId, segmentName, segmentElementsInDb } = useCustomerSegment();
  const { editSegment } = useSegmentBuilder();
  const { pathname } = useLocation();

  const [, , , editParam] = pathname.split('/');
  const isEditingSegment = editParam === 'edit';

  useEffect(() => {
    if (isEditingSegment) {
      editSegment({ segmentId, segmentName, segment: segmentElementsInDb });
    }
  }, []);
}
