import PropTypes from 'prop-types';

import { LoadingComponent } from 'src/components/Generic';
import { presentationTableTwClasses } from 'src/components/Funnel/PresentationView/presentationViewHelpers';

import { useFunnelPresentationView } from '../presentationViewHooks';

export default function FunnelPresentationRow({
  metric,
  metricHighlight,
  numberHighlight,
  classNames,
  children = null,
  squareIndexOnFunnel,
  funnelItemNameInService,
  funnelPartName,
}) {
  const { onRightPaneOpen, setClickedRow } = useFunnelPresentationView();

  const { container: containerClassName = '' } = classNames || {};
  const { metricsColumnStyles, totalColumnsWidth } = presentationTableTwClasses;

  const { width: metricsWidth, padding: metricsPadding } = metricsColumnStyles;

  const onButtonClick = () => {
    setClickedRow({
      squareIndex: squareIndexOnFunnel,
      funnelItemNameInService,
      funnelPart: funnelPartName,
      rowName: metric,
    });
    onRightPaneOpen();
  };

  const highlightsClass = 'px-2 ml-2 bg-wmxHighlightDark-100 rounded-lg';

  const childrenComponent =
    children === 'loading' ? <LoadingComponent /> : children === null ? <LoadingComponent /> : children;

  const rowButtonProps = {
    children,
    onButtonClick,
    childrenComponent,
    numberHighlight,
    highlightsClass,
    totalColumnsWidth,
  };

  return (
    <div className={`${containerClassName}`}>
      <div
        className={`flex flex-row bg-wmxHighlightDark-100 ${metricsPadding} rounded-lg py-3 pl-6 shadow-xl relative z-20`}
      >
        <div className={`flex items-center ${metricsWidth} pr-1`}>
          <span className="whitespace-nowrap overflow-ellipsis overflow-hidden">{metric}</span>
          {metricHighlight && <span className={`py-1 ${highlightsClass}`}>{metricHighlight}</span>}
        </div>
        <RowButton {...rowButtonProps} />
      </div>
    </div>
  );
}

function RowButton({
  children,
  onButtonClick,
  childrenComponent,
  numberHighlight,
  highlightsClass,
  totalColumnsWidth,
}) {
  return (
    <button
      type="button"
      disabled={children === 'loading' || children === null}
      className={`flex justify-center items-center bg-wmxHighlightDark-200 hover:bg-wmxBgDark-200 rounded-lg py-2 px-1 shadow-inner  h-full ${totalColumnsWidth}`}
      onClick={onButtonClick}
    >
      {childrenComponent}
      {numberHighlight && (
        <span className={`${highlightsClass} text-xs text-white text-opacity-70`}>{numberHighlight}</span>
      )}
    </button>
  );
}

RowButton.propTypes = {
  children: PropTypes.string,
  childrenComponent: PropTypes.any,
  highlightsClass: PropTypes.any,
  numberHighlight: PropTypes.any,
  onButtonClick: PropTypes.any,
  totalColumnsWidth: PropTypes.any,
};

export const presentationRowProps = {
  metric: PropTypes.string,
  metricHighlight: PropTypes.string,
  funnelItemsToFetch: PropTypes.array,
  metricsColumnStyles: PropTypes.shape({
    width: PropTypes.number,
    padding: PropTypes.number,
  }),
  numberContainerWidth: PropTypes.number,
  squareIndex: PropTypes.number,
  typeOfService: PropTypes.oneOf(['automation', 'revenue', '']),
  totalNumber: PropTypes.node,
  classNames: PropTypes.shape({
    container: PropTypes.string,
  }),
};

FunnelPresentationRow.propTypes = {
  ...presentationRowProps,
};
