import { SegmentSwitch } from '../Dashboard/DashboardBoxes/DashboardBoxes';
import LoadingComponent from '../Generic/LoadingComponent/LoadingComponent';

export const QUERY = gql`
  query PEOPLE_SEGMENTS_LIST($ids: [String]) {
    listPeopleSegments: listPeopleSegments(ids: $ids) {
      id
      segmentName
      dashboardSettings
    }
  }
`;

export const Loading = () => <LoadingComponent />;

export const Empty = () => <></>;

export const Failure = ({ error }) => <div style={{ color: 'red' }}>Error: {error.message}</div>;

export const Success = ({ listPeopleSegments }) => {
  return listPeopleSegments.map((item) => <SegmentSwitch segment={item} />);
};
