import PropTypes from 'prop-types';
import { setThousandsComma } from 'src/lib/generic/handlers';

export default function EmailsInAutomationsOpenMetric({ opens: opensNumber }) {
  const opens = setThousandsComma(opensNumber);

  return <>{opens}</>;
}

EmailsInAutomationsOpenMetric.propTypes = {
  opens: PropTypes.number,
};
