import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@redwoodjs/web';
import * as Tabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { useRecoilState, useRecoilValue } from 'recoil';
import FunnelsListInSubMenu from 'src/components/Funnel/FunnelsListInSubMenu/FunnelsListInSubMenu';
import { withFunnelListQuery } from 'src/lib/funnelHelpers/funnelHelpers';
import { pretiffyLastUpdate } from 'src/lib/datetimeFormat/prettifyLastUpdate';

import usePressOutsideContainer from 'src/customHooks/usePressOutsideContainer';
import useCurrentUser from 'src/customHooks/useCurrentUser';
import { triggerTrackEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';

import { CREATE_FUNNEL_IN_DB, useUpdateFunneRetrievedById } from 'src/components/Funnel/funnelQueriesAndMutations';

import AppLayout from 'src/layouts/AppLayout/AppLayout';
import MainHeaderLayout from 'src/layouts/MainHeaderLayout/MainHeaderLayout';
import FunnelLayoutRoute from 'src/layouts/FunnelLayoutRoute/FunnelLayoutRoute';
import { DatePicker } from 'src/components/Generic';
import {
  funnelItems,
  funnelItemsArgumentToDb as RecoilFunnelItemsArgumentToDb,
  funnelByIdRetrieved as RecoilFunnelByIdRetrieved,
  shouldFunnelInDbUpdate as RecoilShouldFunnelInDbUpdate,
  FUNNEL_PAGE_SOURCES,
} from 'src/atoms/funnelAtoms';
import { useUpdateFunnelInDb, useFunnelData } from 'src/components/Funnel/funnelHooks';

import './funnelLayout.css';

import { funnelBuilderFlowContainerClass } from 'src/pages/FunnelPage';

const editViewName = 'editView';
const presentationViewName = 'presentationView';

const FunnelLayout = ({ editView, presentationView, withFunnelId }) => {
  const { funnelPageSource } = useFunnelData();
  const { funnelItemsFromDb } = useFunnelNameUpdate();

  const isRetrievedAndEmpty =
    withFunnelId &&
    (funnelPageSource === FUNNEL_PAGE_SOURCES.clickedInList ||
      funnelPageSource === FUNNEL_PAGE_SOURCES.clickedInSubMenu) &&
    funnelItemsFromDb?.length === 0;
  const initialTab = isRetrievedAndEmpty || !withFunnelId ? editViewName : presentationViewName;

  const [activeTab, setActiveTab] = useState(initialTab);

  useUpdateActiveTabBecauseOfEmptyFunnel({ activeTab, setActiveTab });

  return (
    <AppLayout
      isHeader={false}
      submenu={{
        nav: <FunnelSubmenu />,
        classes: 'w-52',
        title: 'Funnels',
        isOpen: false,
      }}
    >
      <FunnelLayoutContainer>
        <FunnelSavingHeader />
        <TabsLayout
          withFunnelId={withFunnelId}
          active={activeTab}
          setActive={setActiveTab}
          header={
            <MainHeaderLayout>
              <Flex>
                <EditAnalizeTabs active={activeTab} />
                <TimeRangeOptions />
              </Flex>
            </MainHeaderLayout>
          }
          editView={editView}
          presentationView={presentationView}
        />
      </FunnelLayoutContainer>
    </AppLayout>
  );
};

FunnelLayout.propTypes = {
  editView: PropTypes.element,
  presentationView: PropTypes.element,
  withFunnelId: PropTypes.bool,
};

export default FunnelLayout;

const FunnelLayoutContainer = ({ children }) => {
  return (
    <div className="funnelLayoutContainer flex flex-col overflow-hidden relative w-full h-full rounded-lg">
      {children}
    </div>
  );
};

FunnelLayoutContainer.propTypes = {
  children: PropTypes.any,
};

/* ------------------------------------------------------------------------------------------------
 * Tabs Container Layout
 * -----------------------------------------------------------------------------------------------*/

const TabsLayout = ({ header, editView, presentationView, active, setActive }) => {
  const onTabsValueChange = (eventName) => {
    if (eventName === editViewName) clickEventInFunnelBuilder();

    const trackEventName =
      eventName === editViewName
        ? SEGMENT_TRACK_EVENTS_NAMES.clickedFunnelEditView
        : eventName === presentationViewName
        ? SEGMENT_TRACK_EVENTS_NAMES.clickedFunnelAnalyzeView
        : null;

    const trackEventPayload = {
      previousTab: active,
    };

    setActive(eventName);

    triggerTrackEvent({ eventName: trackEventName, payload: trackEventPayload });
  };
  return (
    <Tabs.Root defaultValue={active} value={active} onValueChange={onTabsValueChange} className="text-white">
      <Tabs.List className="flex flex-col z-20 w-auto ">{header}</Tabs.List>
      <Tabs.Panel value="editView" className="w-full h-full">
        {editView}
      </Tabs.Panel>
      <Tabs.Panel value="presentationView">{presentationView}</Tabs.Panel>
    </Tabs.Root>
  );
};

TabsLayout.propTypes = {
  active: PropTypes.any,
  editView: PropTypes.any,
  header: PropTypes.any,
  presentationView: PropTypes.any,
  setActive: PropTypes.func,
};

/* ------------------------------------------------------------------------------------------------
 * Children components
 * -----------------------------------------------------------------------------------------------*/

const TimeRangeOptions = () => {
  const { isRightPaneOpen } = useFunnelData();
  return (
    <div className="funnelTimeRange">
      {/* The Flex style is to play with rsuite styles getting the desired width of the <a> component */}
      <Flex>
        <DatePicker open placement="bottomStart" disabled={isRightPaneOpen} />
      </Flex>
    </div>
  );
};

const EditAnalizeTabs = ({ active }) => {
  const {
    funnelItemsBottom: { service: bottomFunnelService },
  } = useRecoilValue(funnelItems);
  const { isRightPaneOpen } = useFunnelData();
  const isThereAnySquareDefined = bottomFunnelService?.length;

  const activeTabClasses = 'bg-wmxPrimary-200 shadow-buttonHover ';
  const disabledClasses = 'cursor-not-allowed';
  const tabsStyles =
    'wmxTabs cursor-pointer px-2 py-1 bg-wmxBgDark-300 hover:bg-wmxPrimary-300 text-xs align-middle flex items-center border border-wmxBgDark-400';

  return (
    <div className="tabsWrap flex bg-wmxBgDark-400 mr-2 ml-2 ">
      <Tabs.Tab
        disabled={isRightPaneOpen}
        value="editView"
        className={classNames(tabsStyles, {
          [activeTabClasses]: active === editViewName,
          [disabledClasses]: isRightPaneOpen,
        })}
        onClick={clickEventInFunnelBuilder}
      >
        Edit
      </Tabs.Tab>
      <Tabs.Tab
        value="presentationView"
        disabled={!isThereAnySquareDefined || isRightPaneOpen}
        className={classNames(tabsStyles, {
          [activeTabClasses]: active === presentationViewName,
          [disabledClasses]: isRightPaneOpen,
          'text-white text-opacity-30': !isThereAnySquareDefined,
        })}
      >
        Analyze
      </Tabs.Tab>
    </div>
  );
};

EditAnalizeTabs.propTypes = {
  active: PropTypes.any,
};

const FunnelSavingHeader = () => {
  const [isInputClicked, setIsInputClicked] = useState(false);
  const { saveFunnel, isReadyToSave, isLoading, hasRetrievedFunnelBeenModified, isNewFunnel, lastSaved } =
    useSaveLogic();
  const { funnelNameInputValue, onInputChange, inputSize } = useFunnelNameUpdate();
  const inputRef = useRef();
  useEffect(() => {
    if (isInputClicked && inputRef) {
      inputRef.current.focus();
    }
  }, [isInputClicked, inputRef]);

  usePressOutsideContainer({
    closeContainerHandler: () => setIsInputClicked(false),
    containerRef: inputRef,
  });

  const onSubmitName = (e) => {
    e.preventDefault();
    saveFunnel();
    setIsInputClicked(false);
  };
  const inputNameClickHandler = () => {
    setIsInputClicked(true);
  };

  return (
    <div className="flex flex-row mr-8 ml-4 mt-2 mb-4">
      <FunnelLayoutRoute classNames={{ container: 'mr-3' }}>
        {isReadyToSave &&
          ((isInputClicked && ( // onSubmit avoids the website to refresh. Autocomplete that password managers doesn't detect it
            <form className="flex items-center" autoComplete="off" onSubmit={onSubmitName}>
              <input
                onChange={onInputChange}
                size={inputSize}
                // This is not good for accesibility
                className="text-wmxText-200 bg-transparent focus:outline-none text-sm bg-wmxBgDark-300 border border-wmxBgDark-200 px-2 py-1 rounded-sm"
                placeholder="Give me a name"
                value={funnelNameInputValue || ''}
                ref={inputRef}
              />
            </form>
          )) || (
            <button
              type="button"
              onClick={inputNameClickHandler}
              className="text-wmxText-200 bg-transparent focus:outline-none text-sm px-2 py-1 border border-transparent"
            >
              {funnelNameInputValue || 'Give me a name'}
            </button>
          ))}
      </FunnelLayoutRoute>
      {isReadyToSave &&
        ((isNewFunnel && !isLoading && <SaveButton onClick={saveFunnel} isReadyToSave={isReadyToSave} />) || (
          <UpdateStatus isLoading={isLoading} modified={hasRetrievedFunnelBeenModified} lastSaved={lastSaved} />
        ))}
    </div>
  );
};

const SaveButton = ({ onClick, isReadyToSave }) => {
  const onSaveColours = !isReadyToSave
    ? 'bg-opacity-50 opacity-30 bg-wmxText-100 text-wmxText-100'
    : 'bg-wmxPrimary-200 hover:bg-wmxPrimary-300 text-white';

  return (
    <button
      type="button"
      disabled={!isReadyToSave}
      onClick={onClick}
      className={`py-1 px-2 rounded ${onSaveColours} text-sm`}
    >
      <span className="text-white">Save</span>
    </button>
  );
};

const UpdateStatus = ({ isLoading, modified, lastSaved }) => {
  if (!modified && !isLoading) {
    return <SaveBadge text={`Saved: ${lastSaved} ago`} />;
  }
  return isLoading ? <SaveBadge text="Saving..." /> : <SaveBadge text="Saved" saved />;
};

function useSaveLogic() {
  const funnelItemsToDb = useRecoilValue(RecoilFunnelItemsArgumentToDb);
  const funnelByIdRetrieved = useRecoilValue(RecoilFunnelByIdRetrieved);
  const [saved, setSaved] = useState(false);
  const { funnelId: retrievedFunnelId, funnelName, modifiedAt: retrievedModifiedAt } = funnelByIdRetrieved;
  const {
    userFunnels: { hasAtLeastOneCreatedFunnel, setHasAtLeastOneCreatedFunnel },
  } = useCurrentUser();
  const [saveFunnelById, { loading: savingFunnelLoading, data: { createNewFunnelReport } = {} }] =
    useMutation(CREATE_FUNNEL_IN_DB);

  const { updatingFunnelInDbLoading, updatingFunnelInDbResponse } = useUpdateFunnelInDb();

  const modifiedAt = retrievedModifiedAt || updatingFunnelInDbResponse?.modifiedAt || createNewFunnelReport?.modifiedAt;

  useUpdateFunneRetrievedById({ dataMutationObj: updatingFunnelInDbResponse || createNewFunnelReport });

  const saveFunnel = () => {
    const updateUserFunnels = () => {
      if (!hasAtLeastOneCreatedFunnel) {
        setHasAtLeastOneCreatedFunnel(true);
      }
    };

    saveFunnelById({ variables: { funnelItemsToDb, funnelName } });
    updateUserFunnels();
    setSaved(!!createNewFunnelReport?.funnelId);

    const eventPayload = {
      funnelId: retrievedFunnelId,
      funnelName,
      isFirstFunnelSaved: hasAtLeastOneCreatedFunnel,
    };

    triggerTrackEvent({ eventName: SEGMENT_TRACK_EVENTS_NAMES.savedFunnel, payload: eventPayload });
  };
  const isReadyToSave = funnelName || funnelItemsToDb?.length > 0;
  const funnelId = retrievedFunnelId || updatingFunnelInDbResponse?.funnelId || createNewFunnelReport?.funnelId;
  const isLoading = updatingFunnelInDbLoading || savingFunnelLoading;
  const hasRetrievedFunnelBeenModified = funnelId && !!(updatingFunnelInDbResponse || createNewFunnelReport);
  const isNewFunnel = !funnelId && (!updatingFunnelInDbResponse || !createNewFunnelReport);

  return {
    saveFunnel,
    isReadyToSave,
    isLoading,
    hasRetrievedFunnelBeenModified,
    isNewFunnel,
    saved,
    lastSaved: pretiffyLastUpdate(modifiedAt),
  };
}

const SaveBadge = ({ text, saved }) => {
  return (
    <div className="mt-1 rounded-md flex items-center">
      <span className={`rounded-full h-1 w-1 ${(saved && 'bg-green-600') || 'bg-orange-400'} `} />
      <div className="text-xs text-wmxText-200 ml-2">{text}</div>
    </div>
  );
};

// Should follow the same structure as <Header /> like the others Layouts
const FunnelSubmenu = () => {
  return withFunnelListQuery(FunnelsListInSubMenu, { onlyNameList: true });
};

function clickEventInFunnelBuilder() {
  const event = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  const element = document.querySelector(`.${funnelBuilderFlowContainerClass}`);
  return setTimeout(() => element.dispatchEvent(event), 200);
}

SaveBadge.propTypes = {
  text: PropTypes.string,
  saved: PropTypes.bool,
};

UpdateStatus.propTypes = {
  isLoading: PropTypes.bool,
  modified: PropTypes.bool,
  lastSaved: PropTypes.string,
};

SaveButton.propTypes = {
  onClick: PropTypes.func,
  isReadyToSave: PropTypes.bool,
};

function useUpdateActiveTabBecauseOfEmptyFunnel({ active, setActive }) {
  const {
    funnelByIdRetrieved: { funnelItemsFromDb },
    funnelPageSource,
  } = useFunnelData();

  useEffect(() => {
    if (
      (funnelPageSource === FUNNEL_PAGE_SOURCES.byUrl || funnelPageSource === FUNNEL_PAGE_SOURCES.clickedInSubMenu) &&
      funnelItemsFromDb?.length === 0 &&
      active !== editViewName
    ) {
      setActive(editViewName);
    }
  }, [funnelPageSource, funnelItemsFromDb, active, setActive]);
}

function useFunnelNameUpdate() {
  const [funnelByIdRetrieved, setFunnelByIdRetrieved] = useRecoilState(RecoilFunnelByIdRetrieved);
  const [shouldFunnelInDbUpdate, setShouldFunnelInDbUpdate] = useRecoilState(RecoilShouldFunnelInDbUpdate);
  const { funnelId, funnelName, funnelItemsFromDb } = funnelByIdRetrieved;

  const [inputCharLength, setInputCharLength] = useState(funnelName ? funnelName.length : 0);

  const onFunnelInputChange = (ev) => {
    const newFunnelName = ev.target.value;
    setInputCharLength(newFunnelName?.length);
    setFunnelByIdRetrieved({ ...funnelByIdRetrieved, funnelName: newFunnelName });

    if (funnelId) {
      setShouldFunnelInDbUpdate({
        requestId: shouldFunnelInDbUpdate.requestId + 1,
        shouldUpdate: true,
      });
    }
  };

  return {
    funnelNameInputValue: funnelName,
    onInputChange: onFunnelInputChange,
    retrievedFunnelId: funnelId,
    funnelItemsFromDb,
    inputSize: inputCharLength,
  };
}
