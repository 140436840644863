import PropTypes from 'prop-types';
import { getLastTwClassNameValue } from 'src/lib/generic/handlers';
import MainCardLayout from '../MainCardLayout/MainCardLayout';

const paddingLayout = 'pl-6';
export const paddingLeftLayoutTw = getLastTwClassNameValue(paddingLayout);

const OnboardingLayout = ({ Left, Right }) => {
  return (
    <>
      {/* <div className="mr-4">{top}</div> */}
      <MainCardLayout>
        <div className="flex gap-4 h-full pt-8">
          <div className="w-2/4">{Left}</div>
          <div className="w-2/4">{Right}</div>
        </div>
      </MainCardLayout>
    </>
  );
};

export default OnboardingLayout;
OnboardingLayout.propTypes = {
  Left: PropTypes.any,
  Right: PropTypes.any,
};
