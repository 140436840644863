import { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';

import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';

import useOrderByNotLoadingAnymore from 'src/customHooks/useOrderByNotLoadingAnymore';
import useUpdateCurrentTableData from 'src/customHooks/useUpdateCurrentTableData';
import { useTriggerPageSeenEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';
import { segmentDefaultTypes } from 'src/components/PeopleReportsMenu/PeopleReportsTable/peopleTableSettingsHelpers';

const PeopleReportsContext = createContext(null);

export default function PeopleReportsProvider({ children }) {
  // To use while the sorting is loading
  const [isTableFetching, setIsTableFetching] = useState(false);
  const [peopleTableData, setPeopleTableData] = useState([]);
  const [reportTitle, setReportTitle] = useState('');

  const providerValue = {
    isTableFetching,
    setIsTableFetching,
    peopleTableData,
    setPeopleTableData,
    reportTitle,
    setReportTitle,
  };

  return <PeopleReportsContext.Provider value={{ ...providerValue }}>{children}</PeopleReportsContext.Provider>;
}

PeopleReportsProvider.propTypes = {
  children: PropTypes.any,
};

export const usePeopleReportsContext = () => useContext(PeopleReportsContext);

export const useStopOrderByLoading = ({ isLoadingTable }) => {
  const { isTableFetching, setIsTableFetching } = usePeopleReportsContext();

  useOrderByNotLoadingAnymore({
    isLoadingTable,
    isFetchingData: isTableFetching,
    setIsFetchingData: setIsTableFetching,
  });
};

export const useUpdatePeopleTableData = ({ freshData }) => {
  const { peopleTableData, setPeopleTableData } = usePeopleReportsContext();

  useUpdateCurrentTableData({ freshData, tableData: peopleTableData, setTableData: setPeopleTableData });
};

export const useTriggerPeoplePageVisited = ({ segmentType, payload }) => {
  const { timeRange, label } = useRecoilValue(RecoilTimeRange);

  const eventPageName = SEGMENT_TRACK_EVENTS_NAMES.segmentVisited;
  const defaultSegmentType = {
    [segmentDefaultTypes.allPeople]: 'All people segment',
    [segmentDefaultTypes.customersPeople]: 'Customers people segment',
    [segmentDefaultTypes.leadsPeople]: 'Leads people segment',
  };

  const segmentName = defaultSegmentType[segmentType] || segmentType;

  const eventPayload = {
    ...payload,
    timeRange: {
      label,
      range: {
        start: dayjs(timeRange.start).format('YYYY-MM-DD'),
        end: dayjs(timeRange.end).format('YYYY-MM-DD'),
      },
    },
    segmentName,
  };

  useTriggerPageSeenEvent({ pageName: eventPageName, payload: eventPayload });
};
