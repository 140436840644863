import PropTypes from 'prop-types';
import { navigate, routes } from '@redwoodjs/router';

import { INTEGRATION_STATUSES } from 'wmx-shared-code/accountGlobalVariables';
import IntegrationStatusColour from 'src/components/IntegrationTopic/IntegrationStatusColour/IntegrationStatusColour';
import { useIntegrationsContext } from 'src/contexts/Integrations';
import { Flex } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { formCSS } from 'src/lib/generic/commonClasses';

export default function VendorOverviewHeader() {
  const {
    settingsByClickedProvider,
    currentDatapointRecordsNames: selectedDatapoint,
    isCurrentDatapointRecordsTable: isSelectedDatapoint,
    currentProviderShown,
    onSearchBoxChange,
  } = useIntegrationsContext();
  const integrationStatus = INTEGRATION_STATUSES.ready;

  const { logo, title } = settingsByClickedProvider || {};
  const { datapointName } = selectedDatapoint || {};

  const providerButtonStates = {
    active: {
      onClick: () => {
        navigate(routes.providerSettings({ provider: currentProviderShown }));
      },
      className: 'hover:text-wmxText-100 hover:bg-wmxHighlightDark-50 cursor-pointer',
    },
    notClickable: {
      onClick: () => {},
      className: 'cursor-auto',
    },
  };

  const currentButtonState = isSelectedDatapoint ? 'active' : 'notClickable';
  const { onClick, className } = providerButtonStates[currentButtonState];

  return (
    <div className="flex flex-row">
      {logo}
      <div className="flex items-center ml-2 w-full">
        <button type="button" className={`text-wmxText-200 p-1 mx-2 rounded-md ${className}`} onClick={onClick}>
          {title}
        </button>
        {isSelectedDatapoint && <span className="text-wmxText-200">/</span>}
        {isSelectedDatapoint && <span className="text-wmxText-200 mx-2">{datapointName}</span>}
      </div>
      <div className="flex items-center justify-end mr-4">
        {isSelectedDatapoint ? (
          <SearchBox onInputChange={onSearchBoxChange} />
        ) : (
          <>
            <IntegrationStatusColour isAllowed status={integrationStatus} />
            <div className="text-wmxText-200 ml-2">Connected</div>
          </>
        )}
      </div>
    </div>
  );
}

function SearchBox({ onInputChange }) {
  return (
    <Flex justify="between">
      <div className="flex items-end w-56 mr-2">
        <input className={formCSS.input} type="text" placeholder="Search..." onChange={onInputChange} />
      </div>
    </Flex>
  );
}

SearchBox.propTypes = {
  onInputChange: PropTypes.func,
};
