import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as RadixSwitch from '@radix-ui/react-switch';
import React from 'react';

const colorsDefault = {
  bgActive: 'bg-wmxHighlightDark-200',
  bg: 'bg-gray-300',
  toggleBg: 'bg-wmxHighlightDark-100',
  toggleBgActive: 'bg-red-200',
};

export default function Switch({ active, handleChange, colors = colorsDefault }) {
  return (
    <>
      <RadixSwitch.Root
        checked={active}
        defaultChecked={active}
        onCheckedChange={handleChange}
        className={classNames(
          `${colors.bg} w-6 relative toggle-label block overflow-hidden h-3 rounded-full cursor-pointer`,
          {
            [colors.bgActive]: active,
          }
        )}
      >
        <RadixSwitch.Thumb
          className={classNames(
            ` toggle-checkbox top-0 absolute block w-3 h-3 rounded-full appearance-none cursor-pointer focus:outline-none`,
            {
              [colors.toggleBgActive]: active,
              'right-0': active,
              [colors.toggleBg]: !active,
            }
          )}
        />
      </RadixSwitch.Root>
    </>
  );
}

Switch.propTypes = {
  active: PropTypes.bool,
  colors: PropTypes.any,
  handleChange: PropTypes.func,
};
