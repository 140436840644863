/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';

import AppLayout from 'src/layouts/AppLayout/AppLayout';
import MainHeaderLayout from 'src/layouts/MainHeaderLayout/MainHeaderLayout';
import MainCardLayout from 'src/layouts/MainCardLayout/MainCardLayout';
import { DatePicker, Button } from 'src/components/Generic';

import { pretiffyLastUpdate } from 'src/lib/datetimeFormat/prettifyLastUpdate';
import { useDashboard } from 'src/contexts/Dashboard';
import useDashboardLayout from 'src/customHooks/useDashboardLayout';
import useDashboardQueriesHandlers from 'src/components/Dashboard/dashboardHelpers/useDashboardQueriesHandlers';

import { DashboardOptions } from 'src/components/Dashboard/DashboardOptions/DashboardOptions';

function DashboardLayoutContainer({ children }) {
  const { cancelNewTab, cancelEditName } = useDashboardLayout();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={() => {
        cancelNewTab();
        cancelEditName();
      }}
      className="flex flex-col h-full w-full justify-items-center items-center"
    >
      <MainHeaderLayout>
        <DashboardQueryOptions />
        <DashboardOptions />
      </MainHeaderLayout>
      <MainCardLayout>{children}</MainCardLayout>
    </div>
  );
}

const DashboardQueryOptions = () => {
  const { lastSessionTimestamp, editMode, layoutState, newDashboardName, creatingNewDashboard } = useDashboard();
  const { saveLayout } = useDashboardQueriesHandlers();

  const lastUpdated = pretiffyLastUpdate(lastSessionTimestamp);
  const isUpdatedOnlySecsAgo = lastUpdated.includes('sec');

  return (
    <div className="flex w-11/12">
      <DatePicker placement="bottomStart" />
      {lastUpdated && !isUpdatedOnlySecsAgo && (
        <span className="flex items-center text-white text-xs px-4">Last updated: {lastUpdated} ago</span>
      )}
      {editMode && (
        <div className="ml-auto mr-2 ">
          <Button
            onClick={saveLayout}
            variant="primary"
            type="submit"
            xs
            disabled={!((newDashboardName.length && layoutState.length) || !creatingNewDashboard)}
          >
            SAVE
          </Button>
        </div>
      )}
    </div>
  );
};

export default function DashboardLayout(props) {
  return (
    <AppLayout>
      <DashboardLayoutContainer {...props} />
    </AppLayout>
  );
}

DashboardLayoutContainer.propTypes = {
  children: PropTypes.any,
};
