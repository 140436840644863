import PropTypes from 'prop-types';
import Account from 'src/components/Account/Account';

// eslint-disable-next-line no-undef
export const QUERY = gql`
  query FindAccountById($id: String!) {
    account: account(id: $id) {
      id
      createdAt
      modifiedAt
      adminUserId
      status
      type
      trialStart
      trialEnd
    }
  }
`;

export const Loading = () => <div>Loading...</div>;

export const Empty = () => <div>Account not found</div>;

export const Failure = ({ error }) => <div style={{ color: 'red' }}>Error: {error.message}</div>;

Failure.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.any,
  }),
};

export const Success = ({ account }) => {
  return <Account account={account} />;
};

Success.propTypes = {
  account: PropTypes.any,
};
