const TrialExpiredPage = () => {
  return (
    <>
      <div className="text-md text-wmxText-100 w-full h-screen p-8">
        Your trial is expired. Please contact support to upgrade your account
      </div>
    </>
  );
};

export default TrialExpiredPage;
