import { formatDatabaseRecords } from 'src/lib/handleTimezone';

export const TIMESERIES_PERIODS = {
  hourly24h: 'hourly24h',
  hourly: 'hourly',
  daily: 'daily',
  monthly: 'monthly',
  weekly: 'weekly',
  yearly: 'yearly',
  dtd: 'dtd',
};

const PERIOD_FORMAT = {
  [TIMESERIES_PERIODS.hourly]: (date) => formatDatabaseRecords({ date, format: 'hh a' }),
  [TIMESERIES_PERIODS.daily]: (date) => formatDatabaseRecords({ date, format: 'MM/DD' }),
  [TIMESERIES_PERIODS.monthly]: (date) => formatDatabaseRecords({ date, format: 'MMM' }),
  [TIMESERIES_PERIODS.weekly]: (date, i) => `w${i + 1}`,
  [TIMESERIES_PERIODS.dtd]: (date) => formatDatabaseRecords({ date, format: 'MMM' }),
  [TIMESERIES_PERIODS.yearly]: (date) => formatDatabaseRecords({ date, format: 'YYYY' }),
};

export const formatDateTick = (period) => {
  return PERIOD_FORMAT[period];
};

export const PERIOD_DETAIL_FORMAT = {
  [TIMESERIES_PERIODS.hourly24h]: ({ start, end }) =>
    formatDatabaseRecords({ date: { start, end }, format: 'hh:mm a' }),
  [TIMESERIES_PERIODS.hourly]: ({ start }) => formatDatabaseRecords({ date: start, format: 'ddd DD hh a' }),
  [TIMESERIES_PERIODS.daily]: ({ start }) => formatDatabaseRecords({ date: start, format: 'ddd DD MMM' }),
  [TIMESERIES_PERIODS.monthly]: ({ start, end }) => {
    const startTimeRangeFormatted = formatDatabaseRecords({ date: start, format: 'DD MMM' });
    const endTimeRangeFormatted = formatDatabaseRecords({ date: end, format: 'DD MMM' });

    return `${startTimeRangeFormatted} to ${endTimeRangeFormatted}`;
  },
  // eslint-disable-next-line no-unused-vars
  [TIMESERIES_PERIODS.weekly]: ({ start }, i) => `w${i + 1}`,
  [TIMESERIES_PERIODS.dtd]: ({ start, end }) => {
    const startTimeRangeFormatted = formatDatabaseRecords({ date: start, format: 'DD MMM' });
    const endTimeRangeFormatted = formatDatabaseRecords({ date: end, format: 'DD MMM' });

    return `${startTimeRangeFormatted} to ${endTimeRangeFormatted}`;
  },
  [TIMESERIES_PERIODS.yearly]: ({ start }) => formatDatabaseRecords({ date: start, format: 'YYYY' }),
};
