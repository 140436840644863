import PropTypes from 'prop-types';
import { routes, navigate } from '@redwoodjs/router';

export default function FunnelLayoutRoute({ classNames = {}, children }) {
  const { container: containerClassName = '' } = classNames;

  return (
    <div className={`flex items-center ${containerClassName}`}>
      <button
        type="button"
        onClick={() => navigate(routes.funnelList())}
        className="funnelButtonPath text-wmxText-200 text-sm "
      >
        <span className="mr-1 px-2 py-1 border border-transparent rounded-md hover:bg-wmxHighlightDark-50 hover:text-wmxText-100">
          Funnels
        </span>
        /&nbsp;
      </button>
      {children}
    </div>
  );
}

FunnelLayoutRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  classNames: PropTypes.shape({
    container: PropTypes.string,
  }),
};
