import PropTypes from 'prop-types';
import ScaleLoader from 'react-spinners/ScaleLoader';

export default function LoadingComponent({ classes = '', color = '#3022D3', justifyFlex = 'justify-center' }) {
  return (
    <div className={`w-full flex flex-col ${justifyFlex} text-center ${classes}`}>
      <ScaleLoader height={15} width={2} margin={1} color={color} loading />
    </div>
  );
}

LoadingComponent.propTypes = {
  classes: PropTypes.string,
  color: PropTypes.string,
  // Tailwind does not let us concatenate strings to then purge the unused classes
  justifyFlex: PropTypes.oneOf([
    'justify-start',
    'justify-center',
    'justify-end',
    'justify-between',
    'justify-around',
    'justify-evenly',
  ]),
};
