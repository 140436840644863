/* global gql */

export const UPDATE_DEFAULT_TIME_RANGE = gql`
  mutation updateDefaultTimeRange($label: String!) {
    updateDefaultTimeRange(label: $label) {
      id
    }
  }
`;

export const UPDATE_DEFAULT_TIMEZONE = gql`
  mutation updateDefaultTimezone($timezone: String!) {
    updateDefaultTimezone(timezone: $timezone) {
      id
    }
  }
`;

export const UPDATE_CURRENCY = gql`
  mutation updateWorkspaceCurrency($currency: String!) {
    updateWorkspaceCurrency(currency: $currency) {
      currency
    }
  }
`;

export const GET_CURRENCIES = gql`
  query getCurrencies {
    getCurrencies {
      threeLetters
      name
    }
  }
`;
