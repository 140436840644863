/* eslint-disable no-new */
import { createPopper } from '@popperjs/core';
import { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import icons from './icons';

const noop = () => {};

export default function Tooltip({ icon, iconColor, iconSize, children, containerClasses, styles, stayOnMessageHover }) {
  const [tooltipShow, setTooltipShow] = useState(false);
  const btnRef = createRef();
  const tooltipRef = createRef();

  const openLeftTooltip = () => {
    createPopper(btnRef.current, tooltipRef.current, {
      placement: 'bottom-start',
    });
    setTooltipShow(true);
  };

  const closeLeftTooltip = () => {
    setTooltipShow(false);
  };

  const iconHandler = icons[icon];

  const iconElem = typeof icon === 'string' ? iconHandler({ bgColor: iconColor, size: iconSize }) : icon;
  const { marginRight = 'mr-0' } = styles?.button;

  return (
    <>
      <div className="inline-flex flex-wrap w-4">
        <div className="w-full text-center">
          <button
            type="button"
            className={`group ${marginRight} text-xs px-2`}
            style={{ transition: 'all .15s ease' }}
            onMouseEnter={openLeftTooltip}
            onMouseLeave={closeLeftTooltip}
            ref={btnRef}
          >
            {iconElem}
          </button>
          <TooltipMessage
            classNames={{
              container: `${tooltipShow ? '' : 'hidden '}`,
              thirdContainer: `${containerClasses || ''}`,
            }}
            containerProps={{
              ref: tooltipRef,
              onMouseEnter: stayOnMessageHover ? openLeftTooltip : noop,
              onMouseLeave: stayOnMessageHover ? closeLeftTooltip : noop,
            }}
          >
            {children}
          </TooltipMessage>
        </div>
      </div>
    </>
  );
}

Tooltip.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  children: PropTypes.any,
  containerClasses: PropTypes.string,
  styles: PropTypes.shape({
    button: PropTypes.shape({
      marginRight: PropTypes.string,
    }),
  }),
  stayOnMessageHover: PropTypes.bool,
};

Tooltip.defaultProps = {
  icon: 'strongInfoBox',
  styles: { button: {} },
};

export function TooltipMessage({ classNames, containerProps, subContainerProps, thirdContainerProps, children }) {
  return (
    <div
      className={`bg-wmxBgDark-100 border-0 block z-50 font-normal leading-normal text-sm w-64 text-left no-underline break-words rounded-lg ${
        classNames?.container || ''
      }`}
      {...containerProps}
    >
      <div className={`bg-wmxBgDark-300 ${classNames?.subContainer || ''}`} {...subContainerProps}>
        <div
          className={`tooltipContainer text-white p-3 shadow-2xl rounded-md ${classNames?.thirdContainer || ''}`}
          {...thirdContainerProps}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export const ColumnsTooltipText = ({ text }) => <span className="text-xs text-wmxText-100">{text}</span>;

TooltipMessage.propTypes = {
  classNames: PropTypes.shape({
    container: PropTypes.string,
    subContainer: PropTypes.string,
    thirdContainer: PropTypes.string,
  }),
  containerProps: PropTypes.object,
  subContainerProps: PropTypes.object,
  thirdContainerProps: PropTypes.object,
  children: PropTypes.any,
};

ColumnsTooltipText.propTypes = {
  text: PropTypes.string,
};
