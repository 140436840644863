import PropTypes from 'prop-types';

import withApolloQuery from 'src/lib/HOC/withApolloQuery';

import * as WmxTable from 'src/components/Generic/Tables/WmxTable';
import { ORDER_BY_PEOPLE_ENUM_VALUES } from 'src/components/PeopleReportsMenu/PeopleReportsTable/peopleReportsQueries';
import { ORDER_COLUMN_DIRECTIONS } from 'wmx-shared-code/reports/people';

import { usePeopleTableProps } from 'src/components/PeopleReportsMenu/PeopleReportsTable/usePeopleTableProps';
import MainHeaderLayout from 'src/layouts/MainHeaderLayout/MainHeaderLayout';
import { PeopleQueryOptions } from 'src/layouts/PeopleLayout/PeopleLayout';
import MainContainerLayout from 'src/layouts/MainContainerLayout/MainContainerLayout';
import MainCardLayout from 'src/layouts/MainCardLayout/MainCardLayout';
import { RadixTooltip, Button } from 'src/components/Generic';
import { Flex, HelperText, Subtitle } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { useSegmentBuilder } from 'src/contexts/SegmentBuilder/SegmentBuilder';

const peopleTableClassNames = {
  container: 'align-middle block max-w-full bg-transparent rounded-bl-lg rounded-br-lg mr-1 flex-grow px-4 pb-0',
  table: 'w-full pb-4',
  body: {
    tbody: 'bg-wmxBgDark-100 overflow-auto bg-wmxBgDark-400',
    bodyCell: `h-10 text-left pl-6 text-white text-sm p-3 first:rounded-l-md last:rounded-r-md `,
    bodyRow: `z-0 hover:bg-wmxBgDark-300 hover:bg-opacity-70 bg-wmxHighlightDark-100 hover:bg-wmxHighlightDark-200 my-2 rounded-md shadow-2xl `,
  },
  header: {
    headerCell:
      'z-10 text-left h-12 pl-6 p-2 border-t-0 leading-4 text-white tracking-wider bg-wmxBgDark-200 text-xs font-normal',
  },
};
export function PeopleReportsTable({
  data,
  dataKey,
  fetchMore,
  classNames,
  queryParams,
  onOrderBy,
  getTableProps,
  isLoadingTable = false,
  title,
}) {
  const tableProps = usePeopleTableProps({
    data,
    dataKey,
    fetchMore,
    title,
    classNames: classNames || peopleTableClassNames,
    queryParams,
    onOrderBy,
    getTableProps,
    isLoadingTable,
  });

  return (
    <WmxTable.TableProvider tableProps={tableProps}>
      <MainContainerLayout>
        <SegmentsHeader title={tableProps.title || title} titleTooltip={tableProps.titleTooltip} />
        <MainHeaderLayout>
          <PeopleQueryOptions />
          <WmxTable.TableSettings />
        </MainHeaderLayout>
        <MainCardLayout>
          <WmxTable.Table />
        </MainCardLayout>
      </MainContainerLayout>
    </WmxTable.TableProvider>
  );
}

function TitleTooltip({ content, children }) {
  return (
    <RadixTooltip content={content}>
      <button type="button" className="text-white hover:underline">
        {children}
      </button>
    </RadixTooltip>
  );
}

function SegmentTitle({ title, titleTooltip: content }) {
  const Container = content
    ? ({ children }) => <TitleTooltip content={content}>{children}</TitleTooltip>
    : ({ children }) => <>{children}</>;

  return (
    <Container>
      <SegmentTitleWrap>
        <Subtitle>{title}</Subtitle>
      </SegmentTitleWrap>
    </Container>
  );
}

export function SegmentTitleWrap({ children }) {
  return <div className="pl-6 py-2 pb-4">{children}</div>;
}

export function SegmentsHeader({ title, titleTooltip, type }) {
  const { addNewSegment, setEditMode, editMode } = useSegmentBuilder();

  return (
    <div className="pb-2">
      <Flex justify="between">
        <SegmentTitle title={title} type={type} titleTooltip={titleTooltip} />
        <Flex>
          <Button onClick={() => addNewSegment()}>Add segment</Button>
          <HelperText variant={(editMode && 'primary') || ''}>
            <Button variant="unstyled" onClick={() => setEditMode(!editMode)}>
              Edit
            </Button>
          </HelperText>
        </Flex>
      </Flex>
    </div>
  );
}

SegmentsHeader.propTypes = {
  title: PropTypes.string,
  titleTooltip: PropTypes.element,
  type: PropTypes.string,
};

TitleTooltip.propTypes = {
  children: PropTypes.any,
  content: PropTypes.element,
};

SegmentTitleWrap.propTypes = {
  children: PropTypes.any,
};

SegmentTitle.propTypes = {
  title: PropTypes.string,
  titleTooltip: PropTypes.element,
};

PeopleReportsTable.propTypes = {
  classNames: PropTypes.object,
  data: PropTypes.object,
  dataKey: PropTypes.string,
  fetchMore: PropTypes.func,
  getTableProps: PropTypes.func,
  isLoadingTable: PropTypes.bool,
  onOrderBy: PropTypes.shape({
    columnOrderBy: PropTypes.oneOf([...ORDER_BY_PEOPLE_ENUM_VALUES]),
    columnOrderByDirection: PropTypes.oneOf([...Object.values(ORDER_COLUMN_DIRECTIONS)]),
    onHeaderClick: PropTypes.func,
  }),
  queryParams: PropTypes.shape({
    fetchPolicy: PropTypes.string,
    variables: PropTypes.shape({
      offset: PropTypes.number,
      limit: PropTypes.number,
    }), // Going directly to withApolloQuery
  }),
  title: PropTypes.string,
};

export default withApolloQuery(PeopleReportsTable);
