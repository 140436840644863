import { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import usePressOutsideContainer from 'src/customHooks/usePressOutsideContainer';
import useCustomRef from 'src/customHooks/useCustomRef';

const MINIMUM_OPTIONS_TO_BE_ABLE_TO_SEE_IN_SCREEN = 4;
const MAX_LIST_CONTAINER_ABOVE_HEIGHT = 336; // 21rem

export default function Dropdown({
  classNames,
  classNamesByType,
  close,
  closeOnPressOutside = true,
  emptyOptionText,
  icon,
  isDoubleColumn,
  isOpen,
  listContainerRef,
  onClose = () => {},
  onOpen = () => {},
  onSelect,
  optionButtonsProp,
  optionsName,
  outsideContainerRef,
  selectedOption,
  setIsOpen,
  setSelectedOption,
  showSelectedValue,
  triggerDropdownBtnRef,
}) {
  const [isOpenControlled, setIsOpenControlled] = useState(false);

  const { refs, dropdownAbsoluteDiv, listContainer } = useDropdownListHeight({
    isDoubleColumn,
  });

  const setIsClosed = useCallback(() => {
    if (setIsOpen) setIsOpen(false);
    setIsOpenControlled(false);
  }, [setIsOpen]);

  const onDropdownClick = () => {
    if (setIsOpen) setIsOpen(!isOpen);
    setIsOpenControlled(!isOpenControlled);
  };

  useEffect(() => {
    if (isOpenControlled) onOpen();
    if (!isOpenControlled) onClose();
  }, [isOpenControlled, onClose, onOpen]);

  useEffect(() => {
    if (isOpen && setIsOpen) onOpen();
    if (!isOpen && setIsOpen) onClose();
  }, [isOpen, onClose, onOpen, setIsOpen]);

  useEffect(() => {
    if (close) setIsClosed();
  }, [close, setIsClosed]);

  usePressOutsideContainer({
    closeContainerHandler: setIsClosed,
    containerRef: outsideContainerRef || refs.mainContainer,
    extraConditionHandler: () => {
      return closeOnPressOutside;
    },
  });

  const doubleColumnClassNames = isDoubleColumn ? 'grid grid-cols-2 grid-flow-row gap-1 min-w-max' : '';

  return (
    <div className={classnames('relative h-auto ', classNames?.container)} ref={refs.mainContainer}>
      <span className="rounded-md shadow-sm h-full relative">
        <button
          type="button"
          ref={triggerDropdownBtnRef}
          className={classnames(
            'inline-flex h-full items-center rounded-md border border-gray-200 text-sm leading-5 font-medium  focus:outline-none  transition ease-in-out duration-150 flex-grow',
            { 'justify-start pl-8': icon, 'justify-center': !icon },
            classNamesByType?.buttonTrigger?.paddingY || 'py-1',
            classNames?.buttonTrigger
          )}
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onMouseDown={onDropdownClick}
        >
          {icon && (
            <div className="z-10 inset-y-0 left-0 pl-2 pt-0 text-gray-400">
              <i className={icon} />
            </div>
          )}
          <div className={`text-xs text-white ${!showSelectedValue && 'text-transparent'}`}>{selectedOption}</div>
          <div className="z-10 pr-2 pt-0 ml-2">
            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.20837 1.37502L4.00004 5.16669L0.791707 1.37502"
                stroke="#F9FAFB"
                strokeWidth="0.916667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </button>
      </span>
      {(isOpenControlled || isOpen) && (
        <div
          ref={listContainerRef}
          className={classnames(
            'absolute overflow-auto rounded-md z-50',
            !listContainer.top && 'shadow-2xl',
            classNamesByType?.list?.width || 'w-auto',
            classNamesByType?.list?.top || '-top-0',
            classNames?.list
          )}
          style={{ top: listContainer.top }}
        >
          <div
            ref={refs.dropdownAbsoluteDiv}
            className="rounded-md bg-wmxHighlightDark-100 ring-1 ring-black ring-opacity-5 shadow-2xl"
            style={{ height: dropdownAbsoluteDiv.height, maxHeight: dropdownAbsoluteDiv.maxHeight }}
          >
            <div
              ref={refs.buttonsContainer}
              className={`py-1 bg-wmxHighlightDark-100 ${doubleColumnClassNames}`}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <DropdownOptions
                {...{
                  classNames,
                  emptyOptionText,
                  onSelect,
                  optionButtonsProp,
                  optionsName,
                  selectedOption,
                  setIsClosed,
                  setSelectedOption,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export const dropdownPropTypes = {
  classNames: PropTypes.shape({
    container: PropTypes.string,
    buttonTrigger: PropTypes.string,
    list: PropTypes.string,
  }),
  classNamesByType: PropTypes.shape({
    list: PropTypes.shape({
      width: PropTypes.string,
      top: PropTypes.string,
    }),
    buttonTrigger: PropTypes.shape({
      paddingY: PropTypes.string,
    }),
  }),
  close: PropTypes.bool,
  closeOnPressOutside: PropTypes.bool,
  emptyOptionText: PropTypes.string,
  icon: PropTypes.node,
  isDoubleColumn: PropTypes.bool,
  isOpen: PropTypes.bool,
  listContainerRef: PropTypes.any,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onSelect: PropTypes.func,
  optionsName: PropTypes.array,
  optionButtonsProp: PropTypes.array,
  outsideContainerRef: PropTypes.any,
  selectedOption: PropTypes.any,
  setIsOpen: PropTypes.func,
  setSelectedOption: PropTypes.func,
  showSelectedValue: PropTypes.bool,
  triggerDropdownBtnRef: PropTypes.any,
};

Dropdown.propTypes = {
  ...dropdownPropTypes,
};

Dropdown.defaultProps = {
  showSelectedValue: true,
};

function DropdownOptions({
  classNames,
  emptyOptionText,
  onSelect,
  optionsName,
  optionButtonsProp,
  selectedOption,
  setIsClosed,
  setSelectedOption,
}) {
  const onOptionClick = (optionName, hasSubMenu) => {
    if (selectedOption !== optionName) {
      if (onSelect) onSelect(optionName);
      setSelectedOption(optionName);

      if (!hasSubMenu) setIsClosed();
    }
  };

  const optionsClasess = 'whitespace-nowrap text-left w-full ml-auto mr-0 block text-xs leading-5 ';
  const buttonsWithPadding = 'px-4 py-2';

  const optionButtons = !optionButtonsProp
    ? optionsName.map((name, idx) => (
        <button
          // eslint-disable-next-line react/no-array-index-key
          key={` ${name}-${idx} `}
          type="button"
          className={classnames(
            `${optionsClasess} ${buttonsWithPadding} text-wmxText-100  hover:bg-wmxBgDark-100  focus:outline-none ${
              classNames?.label || ''
            }`,
            {
              'bg-wmxHighlightDark-100': name === selectedOption,
            }
          )}
          role="menuitem"
          onMouseDown={() => onOptionClick(name)}
        >
          {name}
        </button>
      ))
    : optionButtonsProp.map((obj, idx) => {
        const objLabel = obj.label;

        return (
          <button
            // eslint-disable-next-line react/no-array-index-key
            key={` ${objLabel}-${idx} `}
            type="button"
            className={classnames(
              `${optionsClasess} text-wmxText-100  hover:bg-wmxBgDark-100  focus:outline-none ${classNames.label}`,
              {
                'bg-wmxHighlightDark-75': obj.value === selectedOption,
              }
            )}
            onClick={() => onOptionClick(obj.value, obj.hasSubmenu)}
            disabled={obj.upgrade}
          >
            {objLabel}
          </button>
        );
      });

  const emptyComponent = (
    <span className={`${optionsClasess} ${buttonsWithPadding} text-wmxText-200`}>{emptyOptionText}</span>
  );
  const emptyOptionsButtons = emptyOptionText && [emptyComponent];

  return optionButtons.length ? optionButtons : emptyOptionsButtons;
}

const getNumInPx = (num) => `${num}px`;

function useDropdownListHeight({ isDoubleColumn }) {
  const mainContainerRef = useRef(null);
  const [dropdownAbsoluteDivElement, dropdownAbsoluteDiv] = useCustomRef();
  const [buttonsContainerElement, buttonsContainerRef] = useCustomRef();

  const [mainContainerHeight, setMainContainerHeight] = useState(null);
  const [isDropdownListAbove, setIsDropdownListAbove] = useState(false);
  const [dropdownAbsoluteDivHeight, setDropdownAbsoluteDivHeight] = useState(null);
  const [listContainerTop, setListContainerTop] = useState(null);

  function useLifeCycleMainContainerHeight() {
    useEffect(() => {
      const { height: containerHeight } = mainContainerRef.current.getBoundingClientRect();

      setMainContainerHeight(containerHeight);
    }, []); // It only happens once in the beginning
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!dropdownAbsoluteDivElement || isDoubleColumn) return;

    const getDefaultListHeight = ({ allButtonsHeight }) => {
      const extraMargin = 8;
      return allButtonsHeight + extraMargin;
    };

    const getPxFromMainContainerToEndOfWindow = () => {
      const { top: listContainerYPosition, height: listContainerHeight } = dropdownAbsoluteDivElement.rect;
      const { top: mainContainerYPosition } = mainContainerRef.current.getBoundingClientRect();

      const mainContainerBottomYPosition = mainContainerYPosition + mainContainerHeight;
      const triggerDistanceToEndOfWindow = window.innerHeight - mainContainerBottomYPosition;

      return {
        triggerDistanceToEndOfWindow,
        listContainer: {
          height: listContainerHeight,
          yPosition: listContainerYPosition,
        },
        mainContainer: {
          height: mainContainerHeight,
          yPosition: mainContainerYPosition,
          bottomYPosition: mainContainerBottomYPosition,
        },
      };
    };

    const getButtonsHeight = () => {
      if (!buttonsContainerElement) return {};

      const buttonsElementsArray = [...buttonsContainerElement.HTMLChildren];
      const allButtonsHeight = buttonsElementsArray.reduce((accumNum, button) => {
        const { height: buttonHeight } = button.getBoundingClientRect();

        return accumNum + buttonHeight;
      }, 0);

      const oneButtonHeight = allButtonsHeight / buttonsElementsArray.length;
      const minimumButtonsToSeeInScreenHeight = oneButtonHeight * MINIMUM_OPTIONS_TO_BE_ABLE_TO_SEE_IN_SCREEN;

      return {
        oneButtonHeight,
        allButtonsHeight,
        minimumButtonsToSeeInScreenHeight,
      };
    };

    const handleHeightForAboveList = ({ oneButtonHeight, allButtonsHeight }) => {
      if (!isDropdownListAbove) setIsDropdownListAbove(true);

      const listHeight = getDefaultListHeight({ allButtonsHeight });
      const givenListHeight =
        listHeight > MAX_LIST_CONTAINER_ABOVE_HEIGHT ? MAX_LIST_CONTAINER_ABOVE_HEIGHT : listHeight;

      const extraMarginToSeparateFromTrigger = 4;
      const aboveTriggerTopNum = -(oneButtonHeight + givenListHeight + extraMarginToSeparateFromTrigger);

      setDropdownAbsoluteDivHeight(listHeight);
      setListContainerTop(aboveTriggerTopNum);
    };

    const { triggerDistanceToEndOfWindow, listContainer, mainContainer } = getPxFromMainContainerToEndOfWindow();
    const { oneButtonHeight, allButtonsHeight, minimumButtonsToSeeInScreenHeight } = getButtonsHeight();

    // With searchbox, the options change dynamically and we don't want to reach the end of the screen anymore.
    // This case happens when the height is longer and there is an empty space btw last option and then end of the list.
    const isLongerThanShouldBe =
      listContainer.height > allButtonsHeight && triggerDistanceToEndOfWindow > allButtonsHeight;
    const isGettingOutOfTheScreen = mainContainer.bottomYPosition + allButtonsHeight > window.innerHeight;
    const isOptionsListFittingInScreen = minimumButtonsToSeeInScreenHeight < triggerDistanceToEndOfWindow;

    if (!isOptionsListFittingInScreen) {
      handleHeightForAboveList({ allButtonsHeight, oneButtonHeight });
      return;
    }

    if (isDropdownListAbove && isOptionsListFittingInScreen) setIsDropdownListAbove(false);

    if (isGettingOutOfTheScreen && !isLongerThanShouldBe) {
      setDropdownAbsoluteDivHeight(triggerDistanceToEndOfWindow);
      return;
    }

    // sets the dropdown list to the height of all its options
    const buttonsHeightPlusMargin = getDefaultListHeight({ allButtonsHeight });
    setDropdownAbsoluteDivHeight(buttonsHeightPlusMargin);
  });

  useLifeCycleMainContainerHeight();

  return {
    listContainer: {
      top: isDropdownListAbove && getNumInPx(listContainerTop),
    },
    dropdownAbsoluteDiv: {
      height: getNumInPx(dropdownAbsoluteDivHeight),
      maxHeight: isDropdownListAbove && getNumInPx(MAX_LIST_CONTAINER_ABOVE_HEIGHT),
      ref: dropdownAbsoluteDiv,
    },
    refs: {
      mainContainer: mainContainerRef,
      dropdownAbsoluteDiv,
      buttonsContainer: buttonsContainerRef,
    },
  };
}
