import { useAuth } from '@redwoodjs/auth';
import { ACCOUNT_STATUSES, ACCOUNT_TYPES } from 'wmx-shared-code/accountGlobalVariables';

const useAccountStatus = () => {
  const {
    currentUser: { accountStatus, daysLeft, accountType },
  } = useAuth();
  return {
    trial: accountType === ACCOUNT_TYPES.trial,
    daysLeft,
    accountStatus,
    accountType,
    isTrialExpired: accountStatus === ACCOUNT_STATUSES.trialExpired.dbValue,
  };
};

export default useAccountStatus;
