import PropTypes from 'prop-types';
import getObjectPath from 'lodash.get';

import useInfiniteScroll from 'src/customHooks/useInfiniteScroll';
import useCurrentUser from 'src/customHooks/useCurrentUser';

import withApolloQuery from 'src/lib/HOC/withApolloQuery';
import * as WmxTable from 'src/components/Generic/Tables/WmxTable/WmxTable';
import { RightPaneHeader } from '../Funnel/FunnelRightPane/FunnelRightPane';
import { Flex } from '../Generic/LayoutUtils/LayoutUtils';

function PeopleDetailsTable({ queryParams, data, dataPath, fetchMore, getTableProps, onRightPaneClose, headerTitle }) {
  const {
    defaultCurrency: { threeLetters: defaultCurrencyThreeLetters },
  } = useCurrentUser();

  const { hasMoreData, dataChunk, fetchMoreData } = useInfiniteScroll({
    scrollLength: queryParams?.variables?.limit,
    incomingData: {
      dataArray: getObjectPath(data, dataPath),
      path: dataPath,
    },
    fetchMore,
    fetchMoreParams: queryParams,
  });

  const props = {
    ...getTableProps({ dataChunk, acUrl: data?.activeCampaignUrl, defaultCurrency: defaultCurrencyThreeLetters }),
    name: 'funnelPeopleDetail',
    hasMoreData,
    setNextChunk: fetchMoreData,
  };

  return (
    <WmxTable.TableProvider tableProps={props}>
      <RightPaneHeader onCrossButtonClick={onRightPaneClose}>
        <Flex justify="between">
          <span className="text-sm text-wmxText-200 px-2">{headerTitle}</span>
          <WmxTable.TableSettings />
        </Flex>
      </RightPaneHeader>
      <WmxTable.Table />
    </WmxTable.TableProvider>
  );
}

export default withApolloQuery(PeopleDetailsTable);

PeopleDetailsTable.propTypes = {
  data: PropTypes.shape({
    activeCampaignUrl: PropTypes.string,
    getDefaultCurrency: PropTypes.string,
  }),
  dataPath: PropTypes.string,
  fetchMore: PropTypes.func,
  getTableProps: PropTypes.func,
  headerTitle: PropTypes.any,
  onRightPaneClose: PropTypes.any,
  queryParams: PropTypes.shape({
    fetchPolicy: PropTypes.string,
    variables: PropTypes.shape({
      limit: PropTypes.number,
      offset: PropTypes.number,
      timeRange: PropTypes.shape({
        start: PropTypes.object,
        end: PropTypes.object,
      }),
    }), // Going directly to withApolloQuery
  }),
};
