import PropTypes from 'prop-types';
import AppLayout from 'src/layouts/AppLayout/AppLayout';
import OnboardingFlow from 'src/components/OnboardingFlow';
import './calendlyWidget.css';

const OnboardingPage = ({ slug }) => {
  return (
    <AppLayout>
      <OnboardingFlow slug={slug} />
    </AppLayout>
  );
};

OnboardingPage.propTypes = {
  slug: PropTypes.any,
};

export default OnboardingPage;
