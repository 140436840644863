/* global gql */

export const CREATE_PEOPLE_SEGMENT = gql`
  mutation createPeopleTagsSegment($segment: [[SegmentInput!]]!, $segmentName: String!) {
    createPeopleTagsSegment(segment: $segment, segmentName: $segmentName) {
      id
      segment
    }
  }
`;

export const GET_TAGS_CUSTOM_PRICES = gql`
  query GET_TAGS_CUSTOM_PROCE($tagIds: [String!]!) {
    getTagsCustomPricesByTagId(tagIds: $tagIds) {
      tagId
      customPrice
    }
  }
`;

export const GET_TAGS_CUSTOM_PRICES_BY_CONTAINS_KEWORD = gql`
  query GET_TAGS_CUSTOM_PRICES_BY_CONTAINS_KEWORD($containsKeywords: [String!]!) {
    getTagCustomPricesByContains(containsKeywords: $containsKeywords) {
      containsKeyword
      customPrice
    }
  }
`;

export const UPDATE_TAGS_CUSTOM_PRICE = gql`
  mutation updateTagsAmount($customPricesByTag: [CustomPriceByTag!]!) {
    updateTagsAmount(customPricesByTag: $customPricesByTag)
  }
`;

export const UPDATE_PEOPLE_SEGMENT = gql`
  mutation updatePeopleTagsSegment(
    $id: String!
    $segment: [[SegmentInput!]]
    $dashboardSettings: JSON
    $segmentName: String
  ) {
    updatePeopleTagsSegment(
      id: $id
      segment: $segment
      dashboardSettings: $dashboardSettings
      segmentName: $segmentName
    ) {
      id
      segment
      segmentName
      dashboardSettings
    }
  }
`;

export const DELETE_PEOPLE_SEGMENT = gql`
  mutation DELETE_PEOPLE_SEGMENT($segmentId: String!) {
    deletePeopleTagSegment(segmentId: $segmentId) {
      id
      segmentName
    }
  }
`;

export const GET_SEGMENT = gql`
  query GET_SEGMENT($id: String!) {
    getSegment(id: $id) {
      id
      segment
      segmentName
      dashboardSettings
    }
  }
`;

export const GET_USER_SEGMENT_READY_FOR_SEGMENT_BUILDER = gql`
  query GET_USER_SEGMENT_READY_FOR_SEGMENT_BUILDER($timeRange: TimeRangeInput!, $segmentId: String!) {
    getPeopleTagsSegmentReadyForSegmentBuilder(timeRange: $timeRange, segmentId: $segmentId) {
      segmentId
      segmentName
      segmentElements {
        itemName
        people
        itemsIds
        connection
        condition
      }
    }
  }
`;
