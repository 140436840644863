/* eslint-disable react/prop-types */

const icons = {
  strongInfoBox: ({ bgColor = 'text-wmxText-200', size = 'scale-75' } = {}) => (
    <i className={`fas fa-question ${bgColor} transform ${size} -translate-y-0.5 text-xxs`} />
  ),
  infoBox: ({ bgColor = 'text-wmxText-200' } = {}) => <i className={`fas fa-info-circle ${bgColor} text-xxs`} />,
};

export { icons as default };
