import PropTypes from 'prop-types';

export default function BackArrow({ onClick, classNames, styles }) {
  return (
    <div className={`${classNames?.container} flex items-center`} style={styles?.container}>
      <button type="button" className={`${classNames?.button} p-4`} onClick={onClick}>
        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.99989 13L1 7L7 1"
            stroke="white"
            strokeOpacity="0.55"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
}

BackArrow.propTypes = {
  onClick: PropTypes.func,
  classNames: PropTypes.shape({
    container: PropTypes.string,
    button: PropTypes.string,
    span: PropTypes.string,
  }),
  styles: PropTypes.shape({
    // Out Tailwind version does not support right and left with px still
    container: PropTypes.object,
    span: PropTypes.object,
  }),
};
