/* eslint-disable jsx-a11y/aria-role */

import { ACCOUNT_PAGE_PATH, BILLING_PAGE_PATH, CUSTOMERS_SEGMENT_PATH } from 'src/lib/routesPath';
import DashboardPage from 'src/pages/DashboardPage/DashboardPage';
import AnalyzePage from 'src/pages/AnalyzePage/AnalyzePage';
import IntegrationsPage from 'src/pages/IntegrationsPage/IntegrationsPage';
import FunnelManagementPage from 'src/pages/FunnelManagementPage/FunnelManagementPage';
import FunnelPage from 'src/pages/FunnelPage/FunnelPage';
import PeopleLeadsPage from 'src/pages/PeopleLeadsPage/PeopleLeadsPage';
import PeopleCustomersPage from 'src/pages/PeopleCustomersPage/PeopleCustomersPage';
import OnboardingPage from 'src/pages/OnboardingPage';
import AccountPage from 'src/pages/AccountPage/AccountPage';
import ActiveCampaignImportationPage from 'src/pages/ActiveCampaignImportationPage/ActiveCampaignImportationPage';
import BillingPage from 'src/pages/BillingPage/BillingPage';
import GeneralSettingsPage from 'src/pages/GeneralSettingsPage/GeneralSettingsPage';
import NewWildMailIntegrationPage from 'src/pages/NewWildMailIntegrationPage';
import NewWildMailConfirmationPage from 'src/pages/NewWildMailConfirmationPage';
import NewStripeIntegrationPage from 'src/pages/NewStripeIntegrationPage';
import NotFoundPage from 'src/pages/NotFoundPage';
import LoginPage from 'src/pages/LoginPage/LoginPage';
import TrialExpiredPage from 'src/pages/TrialExpiredPage';
import AccountNewAccountPage from 'src/pages/Account/NewAccountPage';
import AccountAccountPage from 'src/pages/Account/AccountPage';
import AccountEditAccountPage from 'src/pages/Account/EditAccountPage/EditAccountPage';
import AccountAccountsPage from 'src/pages/Account/AccountsPage/AccountsPage';
import FunnelListSkeleton from 'src/components/Funnel/FunnelManagementList/FunnelListSkeleton/FunnelListSkeleton';

import { BoxSkeletons } from 'src/components/Generic/Skeletons';

import { Router, Route, Private, Set } from '@redwoodjs/router';
import ExpiredLayout from 'src/layouts/ExpiredLayout/ExpiredLayout';
import PeopleLayout from 'src/layouts/PeopleLayout/PeopleLayout';
import { SegmentBuilderProvider } from 'src/contexts/SegmentBuilder/SegmentBuilder';
import SettingsLayout from './layouts/SettingsLayout/SettingsLayout';
import RedirectOldLoginHandlerPage from './pages/RedirectOldLoginHandlerPage/RedirectOldLoginHandlerPage';
import AppLayoutSkeleton from './layouts/AppLayout/AppLayoutSkeleton';
import PeopleSegmentPage from './pages/PeopleSegmentPage/PeopleSegmentPage';
import PeopleReportsProvider from './layouts/PeopleLayout/peopleReportsProvider';

const Routes = () => {
  const accountPath = '/settings/account';
  const billingPath = '/settings/billing';

  // We are using this paths are they are defined above in different places in the code
  // When you set a variable in the path param of a route with prerender, the html file is not created
  if (ACCOUNT_PAGE_PATH !== accountPath || BILLING_PAGE_PATH !== billingPath) return null;
  return (
    <Router pageLoadingDelay={500}>
      <Set
        private
        wrap={SettingsLayout}
        unauthenticated="dashboard"
        role="superadmin"
        whileLoadingAuth={() => <AppLayoutSkeleton />}
        whileLoadingPage={() => <AppLayoutSkeleton />}
      >
        <Route path="/admin/new" page={AccountNewAccountPage} name="newAccount" />
        <Route path="/admin/{id}/edit" page={AccountEditAccountPage} name="editAccount" />
        <Route path="/admin/{id}" page={AccountAccountPage} name="account" />
        <Route path="/admin" page={AccountAccountsPage} name="accounts" />
      </Set>
      <Private
        unauthenticated="login2"
        whileLoadingAuth={() => <AppLayoutSkeleton />}
        whileLoadingPage={() => <AppLayoutSkeleton />}
      >
        <Route path="/" page={DashboardPage} prerender name="index" />
        <Route path="/dashboard" page={DashboardPage} prerender name="dashboard" />
        <Route path="/dashboard/{dashboardId}" page={DashboardPage} name="dashboardTab" />
        <Route path="/reports/emails" page={AnalyzePage} name="emailsOverview" prerender />
        <Route path="/reports/automations" page={AnalyzePage} name="automationsOverview" prerender />
        <Route path="/reports/{overviewType}" page={AnalyzePage} name="analyzeByOverview" />
        <Route path="/reports/{overviewType}/{overviewTypeId}/{modalType}" page={AnalyzePage} name="analyzeModal" />
        <Route path="/funnel/new" page={FunnelPage} name="newFunnelPage" />
        <Route
          path="/funnel/list"
          page={FunnelManagementPage}
          name="funnelList"
          prerender
          whileLoadingAuth={() => <FunnelListSkeleton />}
        />
        <Route path="/funnel/{funnelId}" page={FunnelPage} name="funnelPageById" />
        <Route
          path="/reports/automations/{automationId}/{overviewModal}/{overviewTypeId}/{modalType}"
          page={AnalyzePage}
          name="emailsInAutomationsModal"
        />

        <Set wrap={[SegmentBuilderProvider, PeopleReportsProvider, PeopleLayout]}>
          <Route path="/segments/leads" page={PeopleLeadsPage} name="peopleLeads" />
          <Route path={CUSTOMERS_SEGMENT_PATH} page={PeopleCustomersPage} name="peopleCustomers" />
          <Route path="/segments/customers/edit" page={PeopleCustomersPage} name="peopleCustomersEdit" />
          <Route path="/segments/{segmentId}" page={PeopleSegmentPage} name="peopleSegmentPage" />
          <Route path="/segments" page={PeopleLeadsPage} name="peopleLeads" />
        </Set>

        <Set wrap={SettingsLayout}>
          <Route
            path="/settings/account" // If this changes from /settings/account, PLEASE update above variable related
            page={AccountPage}
            name="account"
            prerender
            whileLoadingAuth={() => <AppLayoutSkeleton />}
          />
          <Route
            path="/settings/billing" // If this changes from /settings/billing, PLEASE update above variable related
            page={BillingPage}
            prerender
            name="billing"
          />
          <Route path="/settings/general" page={GeneralSettingsPage} prerender name="generalSettings" />
          <Route
            path="/settings/integrations"
            page={IntegrationsPage}
            name="integrations"
            prerender
            whileLoadingAuth={() => (
              <BoxSkeletons
                boxesAmount={2}
                height={132}
                width={256}
                foregroundColor="#1C2631"
                backgroundColor="#304254"
              />
            )}
          />
          <Route
            path="/settings/integrations/{provider}"
            page={IntegrationsPage}
            name="providerSettings"
            prerender
            whileLoadingAuth={() => (
              <BoxSkeletons
                boxesAmount={2}
                height={132}
                width={256}
                foregroundColor="#1C2631"
                backgroundColor="#304254"
              />
            )}
          />
          <Route
            path="/settings/integrations/{provider}/{datapoint}"
            page={IntegrationsPage}
            name="datapointRecords"
            prerender
            whileLoadingAuth={() => (
              <BoxSkeletons
                boxesAmount={2}
                height={132}
                width={256}
                foregroundColor="#1C2631"
                backgroundColor="#304254"
              />
            )}
          />
          <Route
            path="/settings/integrations/{segment}"
            page={IntegrationsPage}
            name="integrationsWithOpenedSegment"
            prerender
            whileLoadingAuth={() => (
              <BoxSkeletons
                boxesAmount={2}
                height={132}
                width={256}
                foregroundColor="#1C2631"
                backgroundColor="#304254"
              />
            )}
          />
          <Route
            path="/settings/integrations/new/activecampaign"
            page={NewWildMailIntegrationPage}
            name="newWildMailIntegration"
            prerender
            whileLoadingAuth={() => (
              <BoxSkeletons
                boxesAmount={2}
                height={132}
                width={256}
                foregroundColor="#1C2631"
                backgroundColor="#304254"
              />
            )}
          />
          <Route
            path="/settings/integrations/new/wildmail/success"
            page={NewWildMailConfirmationPage}
            name="newWildMailConfirmation"
          />
          <Route path="/settings/integrations/new/stripe" page={NewStripeIntegrationPage} name="newStripeIntegration" />
          <Route
            path="/settings/integrations/new/stripe/{status}"
            page={NewStripeIntegrationPage}
            name="newStripeIntegrationResult"
          />
          <Route
            path="/settings/integrations/activecampaign/importation"
            page={ActiveCampaignImportationPage}
            name="newStripeIntegrationResult"
          />
        </Set>
        <Route
          path="/onboarding/{slug}"
          page={OnboardingPage}
          name="onboardingPage"
          prerender
          whileLoadingAuth={() => <AppLayoutSkeleton />}
          whileLoadingPage={() => <AppLayoutSkeleton />}
        />
        <Route
          path="/onboarding/esp"
          page={OnboardingPage}
          name="onboardingPageStep1"
          prerender
          whileLoadingAuth={() => <AppLayoutSkeleton />}
          whileLoadingPage={() => <AppLayoutSkeleton />}
        />
        <Route
          path="/onboarding/overview"
          page={OnboardingPage}
          name="onboardingPageOverview"
          prerender
          whileLoadingAuth={() => <AppLayoutSkeleton />}
          whileLoadingPage={() => <AppLayoutSkeleton />}
        />
      </Private>
      <Route notfound page={NotFoundPage} />

      <Route path="/welcome" page={LoginPage} prerender name="login2" whileLoadingAuth={() => <AppLayoutSkeleton />} />

      <Route
        path="/login"
        page={RedirectOldLoginHandlerPage}
        name="redirect"
        whileLoadingAuth={() => <AppLayoutSkeleton />}
        whileLoadingPage={() => <AppLayoutSkeleton />}
      />

      <Route
        path="/signup"
        page={() => <LoginPage authTypeRedirection="signup" />}
        name="signup"
        whileLoadingAuth={() => <AppLayoutSkeleton />}
        whileLoadingPage={() => <AppLayoutSkeleton />}
      />

      {/* To force cached redirect */}
      <Set wrap={ExpiredLayout}>
        <Route path="/trial-expired" page={TrialExpiredPage} name="trialExpired" />
      </Set>
    </Router>
  );
};

export default Routes;
