import PropTypes from 'prop-types';

export default function CopySvg({ svgClassName, pathClassName }) {
  return (
    <svg
      className={`fill-current ${svgClassName}`}
      width="30"
      height="30"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.833252" y="0.833374" rx="5" />
      <path
        className={pathClassName}
        d="M9.66658 16.3334H7.99992C7.55789 16.3334 7.13397 16.1578 6.82141 15.8452C6.50885 15.5327 6.33325 15.1087 6.33325 14.6667V8.00004C6.33325 7.55801 6.50885 7.13409 6.82141 6.82153C7.13397 6.50897 7.55789 6.33337 7.99992 6.33337H14.6666C15.1086 6.33337 15.5325 6.50897 15.8451 6.82153C16.1577 7.13409 16.3333 7.55801 16.3333 8.00004V9.66671M11.3333 19.6667H17.9999C18.4419 19.6667 18.8659 19.4911 19.1784 19.1786C19.491 18.866 19.6666 18.4421 19.6666 18V11.3334C19.6666 10.8913 19.491 10.4674 19.1784 10.1549C18.8659 9.8423 18.4419 9.66671 17.9999 9.66671H11.3333C10.8912 9.66671 10.4673 9.8423 10.1547 10.1549C9.84218 10.4674 9.66658 10.8913 9.66658 11.3334V18C9.66658 18.4421 9.84218 18.866 10.1547 19.1786C10.4673 19.4911 10.8912 19.6667 11.3333 19.6667Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CopySvg.propTypes = {
  svgClassName: PropTypes.string,
  pathClassName: PropTypes.string,
};
