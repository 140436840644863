/* eslint-disable jsx-a11y/control-has-associated-label */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { numberOfBlocks as RecoilNumberOfBlocksState } from 'src/atoms/funnelAtoms';
import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';

import FunnelTypeOfItemSquare from 'src/components/Funnel/FunnelTypeOfItemSquare/FunnelTypeOfItemSquare';
import FunnelPartLeadsCount from 'src/components/FunnelPartLeadsCount/FunnelPartLeadsCount';
import { LoadingComponent } from 'src/components/Generic';

import { useFunnelData } from 'src/components/Funnel/funnelHooks';
import { capitalize } from 'src/lib/generic/handlers';

export default function FunnelPartBox({ funnelOrderId, funnelPartName, squareItemProps }) {
  const numberOfBlocksState = useRecoilValue(RecoilNumberOfBlocksState);
  const { timeRange } = useRecoilValue(RecoilTimeRange);
  const [funnelPartSquares, setFunnelPartSquares] = useState([]);
  const {
    currentFunnelPart,
    setCurrentFunnelPart,
    toggleSelectedSquares,
    getNewSquare,
    addNewSquare,
    unsetAllPrevSquares,
    funnelData,
    funnelItems,
    isRightPaneOpen,
    onRightPaneOpen,
    minorGlobalSquareModified,
  } = useFunnelData();

  const funnelPart = funnelPartName.toLowerCase();
  const currentFunnelPartData = funnelData[funnelPart];
  const numberOfBlocks = numberOfBlocksState[funnelOrderId];

  const onSectionBoxClick = () => {
    const newFunnelPart = funnelPartName.toLowerCase();
    setCurrentFunnelPart(newFunnelPart);
    const isPartEmpty = funnelData[newFunnelPart]?.squares.length === 0;
    if (isPartEmpty) {
      const newSquare = getNewSquare(newFunnelPart);
      newSquare.isSelected = false;
      addNewSquare(newSquare, newFunnelPart);
    }
  };

  // useEffect(() => {
  //   const isPartEmpty = funnelData[currentFunnelPart]?.squares.length === 0;
  //   if (isPartEmpty) {
  //     const newSquare = getNewSquare(currentFunnelPart);
  //     newSquare.isSelected = false;
  //     addNewSquare(newSquare, currentFunnelPart);
  //   }
  // }, [currentFunnelPart]);

  const onClick = (squareIndex, funnelPartProp) => {
    if (!funnelData[funnelPartProp]?.squares[squareIndex]?.isSelected) {
      if (funnelPartName.toLowerCase() !== funnelPartProp) {
        setCurrentFunnelPart(funnelPartProp);
      }
      toggleSelectedSquares(funnelPartProp, squareIndex);
    }
  };

  // eslint-disable-next-line no-shadow
  const onNewSquareClick = (funnelPart) => {
    // unselect and set lastSquare to false
    unsetAllPrevSquares(funnelPart);
    const newSquare = getNewSquare(funnelPart);
    addNewSquare(newSquare, funnelPart);
    onRightPaneOpen(funnelPart);
  };

  const blocksOrBlock = numberOfBlocks === 1 ? 'step' : 'steps';

  useEffect(() => {
    setFunnelPartSquares(
      currentFunnelPartData.squares.map((stateProps, squareIndexInPart) => {
        const { segmentElements, peopleInSquareFetchedByFunnelId, squareGlobalIdx } = stateProps;
        const { globalSquareIndex: minorGlobalSquareIndexModified } = minorGlobalSquareModified;

        const isDataFetchedByFunnelId =
          minorGlobalSquareIndexModified === null || minorGlobalSquareIndexModified > squareGlobalIdx;

        const itemsCountBySegmentElements = segmentElements.reduce((accumulator, currentValue) => {
          return (currentValue?.value?.itemsIds?.length || 0) + accumulator;
        }, 0);

        const funnelSquareItemProps = {
          ...squareItemProps,
          ...stateProps,
          squareIndex: squareIndexInPart,
          onClick,
          funnelPartName,
          ...(isDataFetchedByFunnelId
            ? { peopleInSquareFetchedByFunnelId }
            : { peopleInSquareFetchedByFunnelId: null }),
          itemsCount: itemsCountBySegmentElements,
          onNewSquareClick,
        };

        const key = `${funnelPart}-${squareIndexInPart}`;
        return (
          <div key={key} className="my-8 ">
            <FunnelTypeOfItemSquare {...funnelSquareItemProps} />
          </div>
        );
      })
    );
  }, [funnelData, setFunnelPartSquares]);

  const { isLoadingFetchedData } = currentFunnelPartData;

  return (
    <div
      type="button"
      className={`funnelPartBox my-8 z-10 relative ${capitalize(currentFunnelPart) === funnelPartName && 'z-50'}`}
    >
      {funnelPart !== 'bottom' && (
        <>
          <span
            style={{ height: 'calc(50% + 3rem)' }}
            className="absolute -left-8 top-8 h-24 w-8 border-2 border-r-0 border-b-0 border-wmxPrimary-200 opacity-100 rounded-xl rounded-b-none rounded-r-none bottom-0 z-0"
          />
          <span className="absolute left-0 -ml-1 top-8 h-1 w-1 transform scale-125 translate-x-0.5 rounded-full bg-wmxPrimary-50 z-20" />
        </>
      )}
      <button
        type="button"
        onClick={onSectionBoxClick}
        disabled={isRightPaneOpen || isLoadingFetchedData}
        className="bg-wmxHighlightDark-100 flex justify-between items-stretch rounded-md w-60 z-10 relative shadow-2xl"
      >
        <div className="pl-6 pr-4 py-3 px-2">
          <h2 className="text-wmxText-100 text-left text-sm mb-1">{funnelPartName} of funnel</h2>
          <div className="flex flex-row justify-start">
            <span className="text-wmxText-200 text-xs text-left">
              {numberOfBlocks} {blocksOrBlock}
            </span>
            <div className="pr-2 ml-2 text-white text-md">
              <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.3111 7.09521L8.407 11.9993L3.50293 7.09521"
                  stroke="white"
                  strokeOpacity="0.55"
                  strokeWidth="0.934108"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="bg-wmxBgDark-400 rounded-md rounded-l-none flex flex-col items-start justify-center pl-3 py-3 pr-3 shadow-inner-md">
          <h2 className=" text-wmxText-100 text-base font-bold">
            <FunnelPartCountOfLeadsHandler
              itemsQuery={funnelItems[`funnelItems${funnelPartName}`].funnelPart}
              timeRange={timeRange}
              funnelPartName={funnelPartName}
            />
          </h2>
          <h2 className="text-wmxText-200 text-xs">people</h2>
        </div>
      </button>

      {capitalize(currentFunnelPart) === funnelPartName && (
        <div className="z-30">
          <span className="absolute left-4 ml-1.5 h-1 w-1 transform scale-125 translate-x-0.5 rounded-full bg-wmxPrimary-100 z-20" />
          <span className="absolute left-6 h-28 w-10 border-2 border-r-0 border-t-0 rounded-t-none rounded-r-none border-wmxPrimary-200 opacity-100 rounded-xl bottom-30 z-0" />
          <div className="flex flex-row ml-16 z-20 relative">{funnelPartSquares}</div>
        </div>
      )}

      {funnelPart !== 'bottom' && (
        <>
          <span
            style={{ height: 'calc(50% + 2rem)', bottom: '-5.5rem' }}
            className="absolute -left-8 h-24 w-10 border-2 border-r-0 border-t-0 rounded-t-none rounded-r-none border-wmxPrimary-200 opacity-100 rounded-xl -bottom-20 z-10"
          />
          <span
            style={{ bottom: '-5.5rem' }}
            className="absolute left-0 -ml-1 -bottom-20 h-1 w-1 transform scale-125 translate-x-0.5 rounded-full bg-wmxPrimary-50 z-10"
          />
        </>
      )}
    </div>
  );
}

FunnelPartBox.propTypes = {
  funnelOrderId: PropTypes.number,
  funnelPartName: PropTypes.string,
  numberOfPeople: PropTypes.string,
  squareItemProps: PropTypes.object,
  onRightPaneOpen: PropTypes.func,
  onRightPaneClose: PropTypes.func,
  isRightPaneOpen: PropTypes.bool,
};

function FunnelPartCountOfLeadsHandler({ itemsQuery, timeRange, funnelPartName }) {
  const hasData = () => {
    if (itemsQuery && itemsQuery.length > 0) {
      return true;
    }
    return false;
  };

  const { funnelData, minorGlobalSquareModified, funnelByIdRetrieved } = useFunnelData();

  const { funnelPartNameIdx: minorFunnelPartNameIdxModified } = minorGlobalSquareModified;
  const { funnelId } = funnelByIdRetrieved;

  const funnelPart = funnelPartName.toLowerCase();
  const {
    funnelPartNameIdx: currentFunnelPartIdx,
    peopleInFunnelPart = null,
    isLoadingFetchedData,
  } = funnelData[funnelPart];

  const isFunnelPartModified =
    minorFunnelPartNameIdxModified !== null && currentFunnelPartIdx >= minorFunnelPartNameIdxModified;

  return isLoadingFetchedData ? (
    <LoadingComponent />
  ) : !hasData(itemsQuery) ? (
    <>0</>
  ) : isFunnelPartModified || !funnelId ? (
    <FunnelPartLeadsCount itemsQuery={itemsQuery} timeRange={timeRange} funnelPartName={funnelPartName} />
  ) : (
    peopleInFunnelPart
  );
}

FunnelPartCountOfLeadsHandler.propTypes = {
  itemsQuery: PropTypes.array,
  timeRange: PropTypes.object,
  funnelPartName: PropTypes.string,
};
