import { useRecoilState } from 'recoil';
import { useAuth } from '@redwoodjs/auth';
import { getGuessedTimezone } from 'src/lib/handleTimezone';
import { getCurrency } from 'src/lib/generic/currency';

import {
  hasAtLeastOneCreatedFunnel as RecoilHasAtLeastOneCreatedFunnel,
  isUserLoggedIn as RecoilIsUserLoggedIn,
} from 'src/atoms/userAtom';

export default function useCurrentUser() {
  const { currentUser = {}, reauthenticate } = useAuth();
  const {
    accountCurrency: accountCurrencyThreeLetters,
    defaultCurrencyThreeLetters,
    defaultTimezone,
    hasTagsCustomerSegment: isCustomerSegmentInDatabaseCreated,
    stripeIntegration,
  } = currentUser || {};

  const [isUserLoggedIn, setIsUserLoggedIn] = useRecoilState(RecoilIsUserLoggedIn);

  const [hasAtLeastOneCreatedFunnel, setHasAtLeastOneCreatedFunnel] = useRecoilState(RecoilHasAtLeastOneCreatedFunnel);

  const userCurrency = accountCurrencyThreeLetters || defaultCurrencyThreeLetters;

  const defaultCurrencyObj = {
    ...getCurrency(userCurrency),
  };

  const isUserWithCustomerData = stripeIntegration?.isReady || isCustomerSegmentInDatabaseCreated;

  return {
    ...currentUser,
    defaultCurrency: defaultCurrencyObj,
    defaultTimezone: defaultTimezone || getGuessedTimezone(),
    isUserWithCustomerData,
    userFunnels: {
      hasAtLeastOneCreatedFunnel,
      setHasAtLeastOneCreatedFunnel,
    },
    user: {
      isUserLoggedIn,
      setIsUserLoggedIn,
    },
    reauthenticate,
  };
}
