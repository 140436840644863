/* global gql */

import { useAuth } from '@redwoodjs/auth';
import { Form, Label, TextField, FieldError, PasswordField } from '@redwoodjs/forms';
import { useMutation } from '@redwoodjs/web';
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useForm } from 'react-hook-form';
import { Flex, FlexCol, Title, Spacer } from 'src/components/Generic/LayoutUtils';
import { formCSS as formCSSGlobal } from 'src/lib/generic/commonClasses';
import { triggerTrackEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';
import useAccount from 'src/customHooks/useAccount';
import { Button, Badge } from 'src/components/Generic';
import { Text, HSpacer, Card, HelperText, Container, Line } from '../Generic/LayoutUtils/LayoutUtils';

const formCSS = {
  ...formCSSGlobal,
  input:
    'appearance-none w-50 mr-10 bg-wmxBgDark-300 block text-sm text-white p-2 rounded focus:outline-none focus:border-wmxHighlightDark-200 opacity-60 focus:opacity-100',
  inputError:
    'w-50 mr-10 bg-wmxBgDark-300 block p-2 border border-red-700 text-wmxText-100 text-sm rounded focus:outline-none',
  errorMessage: 'pl-1 block mt-1  text-sm text-wmxText-100',
};

const DELETE_ACCOUNT_MUTATION = gql`
  mutation DeleteAccountMutation($accountIdToDelete: String!, $disconnectAuth0: Boolean) {
    startDeleteAccountOrchestator(accountIdToDelete: $accountIdToDelete, disconnectAuth0: $disconnectAuth0) {
      accountId
    }
  }
`;

export const ChangeEmailForm = () => {
  const { currentEmail, updateEmail } = useAccount();
  const [disabled, setDisabled] = useState(true);

  const formMethodsEmail = useForm({
    defaultValues: { email: currentEmail },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { formState: emailState, setError } = formMethodsEmail;
  const { isDirty: emailIsDirty, isValid: emailIsValid } = emailState;

  useEffect(() => {
    if (!emailIsDirty) setDisabled(true);
    else setDisabled(false);
  }, [emailIsDirty, emailIsValid, emailState]);

  const onSubmitEmail = () => {
    const newEmail = formMethodsEmail.getValues('email');
    if (currentEmail === newEmail) {
      setError('email', {
        type: 'sameEmail',
        message: 'Please choose a different email',
      });
    }
    if (emailIsDirty) {
      updateEmail({ email: newEmail });
    }
  };
  return (
    <Form className="block" formMethods={formMethodsEmail} onSubmit={onSubmitEmail}>
      <Label className={formCSS.label}>Email</Label>
      <Spacer />

      <FlexCol>
        <Flex>
          <TextField
            name="email"
            className={formCSS.input}
            errorClassName={formCSS.inputError}
            validation={{
              required: 'Email is required',
              pattern: {
                // eslint-disable-next-line no-useless-escape
                value: /[^@]+@[^\.]+\..+/,
                message: 'Email is not formatted correctly',
              },
            }}
          />

          <Button variant="primary" disabled={disabled} type="submit">
            Update email
          </Button>
        </Flex>

        <FieldError name="email" className={formCSS.errorMessage} />
      </FlexCol>
    </Form>
  );
};

export const ChangePasswordForm = () => {
  const { currentEmail, updatePassword } = useAccount();
  const formMethodsPass = useForm({
    defaultValues: { password: '******' },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { formState: passState } = formMethodsPass;
  const { isDirty: passIsDirty, isValid: passIsValid } = passState;

  const onSubmitPassword = () => {
    if (passIsDirty) {
      updatePassword({ password: formMethodsPass.getValues('password'), email: currentEmail });
    }
  };

  return (
    <Form className="block" formMethods={formMethodsPass} onSubmit={onSubmitPassword}>
      <Label className={formCSS.label}>Password</Label>
      <Spacer />
      <Container size="sm">
        <HelperText>
          Should have at least 8 characters, one uppercase letter, one lowercase letter, one digit and one special
          character.
        </HelperText>
      </Container>
      <Spacer />
      <FlexCol>
        <Flex>
          <PasswordField
            name="password"
            className={formCSS.input}
            errorClassName={formCSS.inputError}
            onFocus={() => formMethodsPass.setValue('password', '', { shouldValidate: false })}
            validation={{
              required: 'Password is required',
              pattern: {
                value: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/,
                message: `Not secure enough`,
              },
            }}
          />
          <Button variant="primary" type="submit" disabled={!passIsDirty && !passIsValid}>
            Update password
          </Button>
        </Flex>
        <FieldError name="password" className={formCSS.errorMessage} />
      </FlexCol>
    </Form>
  );
};

export const DeleteAccount = () => {
  const { accountId } = useAccount();
  const { loading, logOut } = useAuth();

  const [deleteAccountMutation] = useMutation(DELETE_ACCOUNT_MUTATION, {
    onCompleted: () => {
      logOut({ returnTo: `${process.env.REDIRECT_URI}` });
    },
  });

  const [clicked, setClicked] = useState(false);
  return (
    <div className="border-wmxAlert-200 border rounded-md relative">
      <Card>
        <Badge variant="danger">Danger Zone</Badge>
        <Title>Delete account</Title>
        <Spacer size="xs" />
        <Text>Delete all your data and account.</Text>
        <Spacer size="md" />
        {!clicked && (
          <Button onClick={() => setClicked(true)} variant="danger">
            Delete
          </Button>
        )}
        {clicked && (
          <>
            <Flex>
              <Button
                onClick={() => {
                  triggerTrackEvent({ eventName: SEGMENT_TRACK_EVENTS_NAMES.workspaceDeleted });
                  deleteAccountMutation({ variables: { accountIdToDelete: accountId, disconnectAuth0: true } });
                }}
                variant="danger"
              >
                Confirm delete
              </Button>
              <HSpacer />
              <Button onClick={() => setClicked(false)} variant="transparent">
                No, cancel
              </Button>
            </Flex>
          </>
        )}
      </Card>
    </div>
  );
};

export const AccountDetails = () => {
  return (
    <Card>
      <Title>Account details</Title>
      <Spacer size="xs" />
      <Line />
      <Spacer size="sm" />
      <ChangeEmailForm />
      <Spacer size="sm" />
      <ChangePasswordForm />
      <Spacer size="sm" />
    </Card>
  );
};
