import PropTypes from 'prop-types';
import * as ScrollArea from '@radix-ui/react-scroll-area';

const SCROLLBAR_SIZE = 6;

const itemsAlign = (items) => {
  switch (items) {
    default:
      return 'items-center';
    case 'start':
      return 'items-start';
    case 'end':
      return 'items-end';
  }
};

const itemsJustify = (justify) => {
  switch (justify) {
    default:
      return 'justify-center';
    case 'start':
      return 'justify-start';
    case 'end':
      return 'justify-end';
    case 'between':
      return 'justify-between';
    case 'around':
      return 'justify-around';
  }
};

const getPadding = (p) => {
  switch (p) {
    case 'md':
      return 'p-8';
    case 'xs':
      return 'p-2';
    case 'sm':
      return 'p-4';
    case 'lg':
      return 'p-12';
    default:
      throw new Error(`Padding case: ${p} not defined`);
  }
};

const getPaddingX = (p) => {
  switch (p) {
    case 'md':
      return 'px-8';
    case 'sm':
      return 'px-4';
    case 'lg':
      return 'px-12';
    default:
      return '';
  }
};

const getPaddingY = (p) => {
  switch (p) {
    case 'md':
      return 'px-8';
    case 'sm':
      return 'px-4';
    case 'lg':
      return 'px-12';
    default:
      return '';
  }
};

const getShadow = (s) => {
  switch (s) {
    case 'md':
      return 'shadow-2xl';
    default:
      return '';
  }
};

const getBorderColor = (color) => {
  switch (color) {
    case 'white':
      return 'border-white';
    default:
      return 'border-wmxPrimary-100';
  }
};

const getBorder = (args) => {
  if (!args) return '';
  const { position, color } = args;
  switch (position) {
    case 'left':
      return `border-l-8 ${getBorderColor(color)}`;
    case 'top':
      return `border-t-8 ${getBorderColor(color)}`;
    default:
      return '';
  }
};

const itemsGrow = (grow) => {
  if (grow) return 'flex-grow';
  return 'flex-grow-0';
};

const itemsShrink = (shrink) => {
  if (shrink) return 'flex-shrink';
  return 'flex-shrink-0';
};

const itemsWrap = (wrap) => {
  if (wrap) return 'flex-wrap';
  return '';
};

const itemsGap = (gap) => {
  switch (gap) {
    case 1:
      return 'gap-1';
    case 2:
      return 'gap-2';
    case 3:
      return 'gap-3';
    default:
      return 'gap-0';
  }
};

export const Flex = ({
  children,
  items,
  justify = 'start',
  grow,
  wrap,
  shrink = false,
  col = false,
  hfull = false,
  wfull = false,
  gap = 0,
  className,
}) => {
  return (
    <div
      className={`flex
        ${(col && 'flex-col') || ''}
        ${itemsWrap(wrap)}
        ${itemsGrow(grow)}
        ${itemsShrink(shrink)}
        ${itemsAlign(items)}
        ${itemsJustify(justify)}
        ${itemsGap(gap)}
        ${hfull ? 'h-full' : ''}
        ${wfull ? 'w-full' : ''}
        ${className}
      `}
    >
      {children}
    </div>
  );
};

export const FlexCol = ({ children, items, justify, grow }) => {
  return <div className={`flex-col ${itemsGrow(grow)} ${itemsAlign(items)} ${itemsJustify(justify)}`}>{children}</div>;
};

export const Spacer = ({ size = 'sm', nm1 = false }) => {
  if (nm1) return <div className="-mt-1 -mb-1  overflow-auto" />;
  switch (size) {
    case 'lg':
      return <div className="mt-8 mb-8 overflow-auto" />;
    case 'md':
      return <div className="mt-4 mb-4  overflow-auto" />;
    case 'xs':
      return <div className="mt-1 mb-1  overflow-auto" />;
    default:
      return <div className="mt-2 mb-2  overflow-auto" />;
  }
};

export const HSpacer = ({ size = 'sm', lAuto = false, rAuto = false }) => {
  if (lAuto) return <div className="ml-auto overflow-auto" />;
  if (rAuto) return <div className="mr-auto overflow-auto" />;
  switch (size) {
    case 'lg':
      return <div className="mr-8 ml-8 overflow-auto inline" />;
    case 'md':
      return <div className="ml-4 mr-4  overflow-auto inline" />;
    case 'xs':
      return <div className="ml-1 mr-1  overflow-auto inline" />;
    default:
      return <div className="ml-2 mr-2  overflow-auto inline" />;
  }
};

export const Line = (props) => {
  return <div className=" h-0.5 w-full bg-wmxHighlightDark-50" {...props} />;
};

Line.propTypes = {
  props: PropTypes.any,
};

export const Card = ({
  children,
  bg,
  padding = 'md',
  paddingX,
  paddingY,
  classNames = {},
  shadow,
  border = null,
  ...props
}) => {
  const background = () => {
    switch (bg) {
      default:
        return 'bg-wmxBgDark-100';
      case 200:
        return 'bg-wmxBgDark-200';
      case 300:
        return 'bg-wmxBgDark-300';
      case 400:
        return 'bg-wmxBgDark-400';
      case 'h-100':
        return 'bg-wmxHighlightDark-100';
      case 'h-50':
        return 'bg-wmxHighlightDark-50';
      case 'transparent':
        return 'bg-transparent';
    }
  };
  return (
    <div
      style={{ minHeight: '10px' }}
      className={`${classNames?.container || ''} ${background(bg)} box-border rounded-lg ${getPadding(
        padding
      )} ${getPaddingX(paddingX)} ${getPaddingY(paddingY)} ${getShadow(shadow)}  ${getBorder(
        border
      )} w-full h-full flex-grow`}
      {...props}
    >
      {children}
    </div>
  );
};

export const Truncate = ({ children, width }) => {
  return (
    <div style={{ maxWidth: width }} className="truncate inline-block ml-1 mr-1">
      {children}
    </div>
  );
};

Truncate.propTypes = {
  children: PropTypes.any,
  width: PropTypes.any,
};

export const Title = ({ children, center = false, lg = false, sm = false }) => {
  const textCenter = (center && 'text-center') || '';
  const size = (lg && 'text-lg') || (sm && 'text-sm') || 'text-base';
  return <div className={`${size} text-wmxText-100 font-bold ${textCenter}`}>{children}</div>;
};

export const Subtitle = ({ children, fontBold = false, secondary = false, center = false, row }) => {
  const bold = (fontBold && 'font-bold') || '';
  const textCenter = (center && 'text-center') || '';
  const textColor = (secondary && 'text-wmxText-200') || 'text-wmxText-100';

  const props = {
    className: `text-sm ${textColor} ${bold} ${textCenter}`,
    children,
  };

  return row ? <span {...props} /> : <div {...props} />;
};

export const Text = ({ children }) => {
  return <p className="text-sm text-wmxText-200">{children}</p>;
};

export const HelperText = ({ children, fontBold, secondary = true, variant, ...props }) => {
  const bold = fontBold ? 'font-bold' : '';
  const type = secondary ? 'text-wmxText-200' : 'text-wmxText-100';
  if (variant === 'alert') {
    return (
      <div className={`text-xs ${bold} text-red-400`} {...props}>
        {children}
      </div>
    );
  }
  if (variant === 'primary') {
    return (
      <div className={`text-xs ${bold} text-wmxPrimary-50`} {...props}>
        {children}
      </div>
    );
  }
  return (
    <div className={`text-xs ${type} ${bold}`} {...props}>
      {children}
    </div>
  );
};

export const Link = ({ children, button = false, color = 100, underline = true, ...props }) => {
  const colorClass = () => {
    switch (color) {
      case 100:
        return 'text-wmxText-100';
      case 200:
        return 'text-wmxText-200';
      default:
        throw new Error('Case not covered');
    }
  };

  if (button) {
    return (
      <button
        type="button"
        className={`inline-block ml-1 ${(underline && 'underline') || ''} ${colorClass()}`}
        {...props}
      >
        {children}
      </button>
    );
  }
  return (
    <a className={`inline-block ml-1 ${(underline && 'underline') || ''} ${colorClass()}`} {...props}>
      {children}
    </a>
  );
};

Link.propTypes = {
  button: PropTypes.bool,
  children: PropTypes.any,
  color: PropTypes.number,
  href: PropTypes.string,
  underline: PropTypes.bool,
};

export const Container = ({ size = 'md', relative = false, children }) => {
  const relativeClass = (relative && 'relative') || '';

  const switcher = () => {
    switch (size) {
      case 'sm':
        return <div className={`max-w-sm ${relativeClass}`}>{children}</div>;
      case 'md':
        return <div className={`max-w-md ${relativeClass}`}>{children}</div>;
      case 'lg':
        return <div className={`max-w-lg ${relativeClass}`}>{children}</div>;
      case 'max':
        return <div className={` max-w-screen-2xl ${relativeClass}`}>{children}</div>;
      default:
        throw new Error('Size not defined');
    }
  };

  return switcher();
};

Container.propTypes = {
  children: PropTypes.any,
  relative: PropTypes.bool,
  size: PropTypes.string,
};

HelperText.propTypes = {
  children: PropTypes.any,
  fontBold: PropTypes.any,
  secondary: PropTypes.bool,
  variant: PropTypes.string,
};

export const Grid = ({ children }) => {
  return <div className="grid">{children}</div>;
};

const getSpan = (span = 1) => {
  // avoid to use col-span-${span} to not break the auto css cleaner
  switch (span) {
    case 2:
      return `col-span-2`;
    case 3:
      return `col-span-3`;
    case 4:
      return `col-span-4`;
    case 5:
      return `col-span-5`;
    case 6:
      return `col-span-6`;
    case 7:
      return `col-span-7`;
    case 8:
      return `col-span-8`;
    default:
      return `col-span-1`;
  }
};

const getOrder = (order = 1) => {
  switch (order) {
    case 'first':
      return `order-first`;
    case 'last':
      return `order-last`;
    default:
      return `order-none`;
  }
};

export const GridCol = ({ children, span, order }) => {
  return <div className={`${getSpan(span)} ${getOrder(order)}`}>{children}</div>;
};

GridCol.propTypes = {
  children: PropTypes.any,
  order: PropTypes.any,
  span: PropTypes.any,
};

export const SelectWithStyle = ({ children }) => {
  return (
    <div className="relative">
      {children}
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
};

export const ScrollableArea = ({ children, height = '40px', scrollRef }) => {
  return (
    <ScrollArea.Root ref={scrollRef}>
      <ScrollArea.Viewport style={{ height }} className="overflow-hidden">
        {children}
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar
        style={{
          display: 'flex',
          // ensures no selection
          userSelect: 'none',
          // disable browser handling of all panning and zooming gestures on touch devices
          touchAction: 'none',
          padding: 2,
          background: '--var()',
          transition: 'background 160ms ease-out',
          '&[dataOrientation="vertical"]': { width: SCROLLBAR_SIZE },
          '&[dataOrientation="horizontal"]': {
            flexDirection: 'column',
            height: SCROLLBAR_SIZE,
          },
        }}
        className="bg-wmxBgDark-300 w-full h-2"
        orientation="horizontal"
      >
        <ScrollArea.Thumb />
      </ScrollArea.Scrollbar>
      <ScrollArea.Scrollbar
        className="w-2 bg-wmxBgDark-300 h-full"
        orientation="vertical"
        style={{
          display: 'flex',
          // ensures no selection
          userSelect: 'none',
          // disable browser handling of all panning and zooming gestures on touch devices
          touchAction: 'none',
          padding: 2,
          background: '--var()',
          transition: 'background 160ms ease-out',
          '&[dataOrientation="vertical"]': { width: SCROLLBAR_SIZE },
          '&[dataOrientation="horizontal"]': {
            flexDirection: 'column',
            height: SCROLLBAR_SIZE,
          },
        }}
      >
        <ScrollArea.Thumb
          style={{
            flex: 1,
            borderRadius: SCROLLBAR_SIZE,
            // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              height: '100%',
              minWidth: 44,
              minHeight: 44,
            },
          }}
          className="bg-wmxHighlightDark-50"
        />
      </ScrollArea.Scrollbar>
      <ScrollArea.Corner />
    </ScrollArea.Root>
  );
};

ScrollableArea.propTypes = {
  children: PropTypes.any,
  height: PropTypes.any,
  scrollRef: PropTypes.any,
};

SelectWithStyle.propTypes = {
  children: PropTypes.any,
};

Grid.propTypes = {
  children: PropTypes.any,
};

Card.propTypes = {
  bg: PropTypes.any,
  border: PropTypes.any,
  children: PropTypes.any,
  classNames: PropTypes.object,
  padding: PropTypes.string,
  paddingX: PropTypes.any,
  paddingY: PropTypes.any,
  shadow: PropTypes.any,
};

HSpacer.propTypes = {
  lAuto: PropTypes.bool,
  rAuto: PropTypes.bool,
  size: PropTypes.string,
};

Spacer.propTypes = {
  nm1: PropTypes.bool,
  size: PropTypes.string,
};

FlexCol.propTypes = {
  children: PropTypes.any,
  grow: PropTypes.any,
  items: PropTypes.any,
  justify: PropTypes.any,
};

Text.propTypes = {
  children: PropTypes.any,
};

Title.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.any,
  classNames: PropTypes.shape({
    container: PropTypes.any,
    text: PropTypes.string,
  }),
  lg: PropTypes.bool,
  sm: PropTypes.bool,
};

Subtitle.propTypes = {
  row: PropTypes.bool,
  center: PropTypes.bool,
  children: PropTypes.any,
  fontBold: PropTypes.bool,
  secondary: PropTypes.bool,
};

Flex.propTypes = {
  children: PropTypes.any,
  col: PropTypes.bool,
  gap: PropTypes.number,
  grow: PropTypes.any,
  hfull: PropTypes.bool,
  items: PropTypes.any,
  justify: PropTypes.string,
  shrink: PropTypes.bool,
  wfull: PropTypes.bool,
  wrap: PropTypes.any,
  className: PropTypes.string,
};
