/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-undef */
import { routes, navigate } from '@redwoodjs/router';
import PropTypes from 'prop-types';

export const QUERY = gql`
  query INTEGRATIONS {
    getActiveCampaignIntegration {
      id
      status
    }
  }
`;

export const Loading = () => <div />;

export const Empty = () => {
  return (
    <>
      <div className="p-0">
        <p className="mb-5">Now you need to connect ActiveCampaign.</p>
        <button
          type="button"
          onClick={() => navigate(routes.newWildMailIntegration())}
          className="flex mr-4 bg-wmxPrimary-200 hover:bg-wmxPrimary-100 text-wmxText-100 text-xs font-semibold px-3 py-1 uppercase tracking-wide rounded max-w-xs disabled:opacity-25 disabled:cursor-not-allowed"
        >
          <div className="leading-loose">Connect ActiveCampaign</div>
        </button>
      </div>
    </>
  );
};

export const Failure = ({ error }) => <div>Error: {error.message}</div>;

export const Success = () => {
  return <></>;
};

Success.propTypes = {
  getActiveCampaignIntegration: PropTypes.shape({
    status: PropTypes.string,
  }),
};

Failure.propTypes = {
  error: PropTypes.object,
};
