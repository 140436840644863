import PropTypes from 'prop-types';

import useProfileButtons from 'src/customHooks/useProfileButtons';

import { ProfileActiveCampaignLogo, ProfileStripeLogo } from 'src/components/Generic';
import { formatReportsTimestamp } from 'src/lib/generic/handlers';
import CellLayout from 'src/components/Analyze/DetailModal/Tables/CellLayout/CellLayout';
import TableLink from 'src/components/Analyze/DetailModal/Tables/TableLink/TableLink';
import { getColumnsWithOrderBy as getColumnsOrderBy } from 'src/components/Analyze/Overview/OverviewTable/tableSettingsHelpers';

import { ORDER_COLUMN_DIRECTIONS, ORDER_BY_PEOPLE_ENUM } from 'wmx-shared-code/reports/people';
import {
  discoverCustomerMetadataSchema,
  formatValues,
  getCustomerMetadataProperties,
} from 'src/components/Generic/Tables/Table/tableUtils';

export const segmentDefaultTypes = {
  allPeople: 'allPeople',
  customersPeople: 'customersPeople',
  leadsPeople: 'leadsPeople',
};

export const getColumnsWithOrderBy = getColumnsOrderBy;

export const formatPeopleData = ({ rows }) => {
  const customerMetaDataSchema = discoverCustomerMetadataSchema({ items: rows });

  return rows.map((row) => {
    const { createdAt, email } = row;
    let customerMetaProperties = {};
    if (row.customerMetadata) {
      customerMetaProperties = getCustomerMetadataProperties({ item: row, customerMetaDataSchema });
    }

    const rowAndMeta = {
      ...row,
      ...formatValues({
        data: {
          ...customerMetaProperties,
        },
      }),
    };

    return {
      ...rowAndMeta,
      email: email || '-',
      profiles: 'Here will come the Logo',
      createdAt: createdAt ? formatReportsTimestamp(createdAt) : '-',
    };
  });
};

export function ProfilesButtons({ row, buttons = { stripe: true, ac: true } }) {
  const { withStripe, acLink, stripeLinkOnlyCustomerId, contactId } = useProfileButtons({ row });

  const invisibleStripeClassName = !stripeLinkOnlyCustomerId ? 'invisible' : '';
  const invisibleAcClassName = !contactId ? 'invisible' : '';

  const isStripeLogo = withStripe && buttons.stripe;
  const isAcLogo = acLink && buttons.ac;

  return (
    <CellLayout>
      {isStripeLogo && (
        <TableLink link={stripeLinkOnlyCustomerId} classnames={invisibleStripeClassName}>
          <ProfileStripeLogo classNames={{ raw: stripeLinkOnlyCustomerId ? 'hover:opacity-70' : 'opacity-50' }} />
        </TableLink>
      )}
      {isAcLogo && (
        <TableLink classnames={`${(isStripeLogo && 'ml-2') || ''} ${invisibleAcClassName}`} link={acLink}>
          <ProfileActiveCampaignLogo classNames={{ raw: contactId ? 'hover:opacity-70' : 'opacity-50' }} />
        </TableLink>
      )}
    </CellLayout>
  );
}

ProfilesButtons.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      contactId: PropTypes.string,
      customerId: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  buttons: PropTypes.shape({
    ac: PropTypes.bool,
    stripe: PropTypes.bool,
  }),
};

export const getPeopleReportsOrderByDefaults = ({ typeOfReport }) => {
  const peopleReportsDefault = {
    orderByColumn: ORDER_BY_PEOPLE_ENUM.createdAt,
    orderByDirection: ORDER_COLUMN_DIRECTIONS.desc,
  };

  const types = {
    [segmentDefaultTypes.allPeople]: peopleReportsDefault,
    [segmentDefaultTypes.customersPeople]: peopleReportsDefault,
    [segmentDefaultTypes.leadsPeople]: peopleReportsDefault,
  };

  return types[typeOfReport];
};
