/* global gql */

import { useState } from 'react';
import { Root, Trigger, Content, Item } from '@radix-ui/react-dropdown-menu';
import PropTypes from 'prop-types';
import ScaleLoader from 'react-spinners/ScaleLoader';

import { CrossButton } from 'src/components/Generic';
import SearchItemsTable from 'src/components/Funnel/SearchItems/SearchItemsTable/SearchItemsTable';
import GetCountOfContainsCell from 'src/components/Cells/GetCountOfContainsCell/GetCountOfContainsCell';
import { pretiffyTypeOfItem } from 'src/lib/funnelHelpers/funnelHelpers';

const RENDER_EACH_SCROLL_FOR_TABLE = 60;

const SEARCH_ITEMS_QUERY = gql`
  query SEARCH_ITEMS($orderBy: String, $keyword: String, $itemAccessor: String, $limit: Int, $offset: Int) {
    searchItems(orderBy: $orderBy, keyword: $keyword, itemAccessor: $itemAccessor, limit: $limit, offset: $offset) {
      id
      name
      count
      timestamp
    }
  }
`;

export default function SearchItemsFromDb({
  itemAccessor,
  typeOfItemName,
  onRowClick,
  onDoneClick,
  inputValue,
  setInputValue,
  setTagsIdContains,
  onDismiss,
}) {
  const dropdownItems = [
    {
      name: 'Select',
    },
    {
      name: 'Contains',
    },
  ];
  const initialDropdownItem = dropdownItems[0].name;

  const [selected, setSelected] = useState(initialDropdownItem);

  // This is because Reach for accesibilty somehow is making focus-visible believe that
  // every interaction comes from the keyboard
  const removeThisStyle = { outline: 'none' };

  const onInputChange = (ev) => {
    const newInputValue = ev.target.value;
    setInputValue(newInputValue);
  };

  const searchItemsTableProps = {
    initialQuery: SEARCH_ITEMS_QUERY,
    dataPath: 'searchItems',
    itemAccessor,
    queryParams: {
      fetchPolicy: 'cache-first',
      variables: {
        offset: 0,
        limit: (selected !== 'Contains' && RENDER_EACH_SCROLL_FOR_TABLE) || 0,
        itemAccessor,
        keyword: inputValue,
        orderBy: 'updatedAt',
      },
    },
    onRowClick,
    condition: selected,
    setTagsIdContains,
    LoadingComponent: () => <LoadingComponent selected={selected} itemAccessor={itemAccessor} />,
    emptyComponentText: `No ${pretiffyTypeOfItem(itemAccessor)}s that match the current search`,
    onDoneClick,
  };

  return (
    <div className="bg-wmxHighlightDark-100 rounded-2xl abs h-full">
      <Header typeOfItemName={typeOfItemName} onCrossButtonClick={onDismiss} />
      <div className="w-full p-4 px-6 xl:px-7 relative h-full">
        <div className="w-full flex flex-row">
          <div className="flex flex-col pr-4 ">
            <span className="text-white mb-2 text-xs">Condition</span>
            <DropdownMenu items={dropdownItems} selected={selected} setSelected={setSelected} />
          </div>
          <div className="flex flex-col w-full">
            <span className="text-white mb-2 text-xs">Search</span>
            <div className="inputWrapper relative">
              <input
                value={inputValue}
                onChange={onInputChange}
                style={removeThisStyle}
                className="text-white bg-wmxBgDark-100 p-2 w-full"
              />
              {selected === 'Contains' && inputValue && (
                <div className="bg-wmxSecondary-100 rounded-md absolute inset-y-0 right-0 p-1 top-1 bottom-1 px-2 mr-1">
                  <GetCountOfContainsCell itemAccessor={itemAccessor} keyword={inputValue} />
                </div>
              )}
            </div>
          </div>
        </div>
        {!(selected === 'Contains' && !inputValue) && <SearchItemsTable {...searchItemsTableProps} />}
      </div>
    </div>
  );
}

const Header = ({ typeOfItemName, onCrossButtonClick }) => {
  const topRightButtonsClassNames = {
    button: 'text-white text-opacity-50 bg-transparent hover:text-opacity-100',
    span: 'text-2xl',
  };

  return (
    <div>
      <div className="flex flex-row items-center pt-5 py-4 px-6 xl:px-7">
        <span className="w-full text-white text-base font-bold"> Search {typeOfItemName.toLowerCase()} </span>
        <CrossButton classNames={topRightButtonsClassNames} onClick={onCrossButtonClick} />
      </div>
      <hr className="border-wmxHighlightDark-50 w-full" />
    </div>
  );
};

const DropdownMenu = ({ items, selected, setSelected }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const itemsComponents = items.map(({ name }) => {
    const onSelect = () => setSelected(name);

    return (
      <Item key={name} className="text-white cursor-pointer hover:bg-wmxBgDark-300 p-2" onSelect={onSelect}>
        {name}
      </Item>
    );
  });

  const onDropdownOpenChange = () => setIsDropdownOpen(!isDropdownOpen);
  const arrowIconClassName = isDropdownOpen ? 'fa-angle-up' : 'fa-angle-down';

  return (
    <Root onOpenChange={onDropdownOpenChange}>
      <Trigger className="flex flex-row justify-between items-center w-32 bg-wmxBgDark-100 pl-4 pr-2 py-2 cursor-pointer">
        <span className="text-white">{selected}</span>
        <i className={`text-xs fas fa-fw ml-3 text-gray-300 ${arrowIconClassName}`} />
      </Trigger>
      <Content
        disableOutsidePointerEvents={false}
        className="searchItemsFromDbDropdown bg-wmxBgDark-100 w-32 shadow-2xl"
      >
        {itemsComponents}
      </Content>
    </Root>
  );
};

function LoadingComponent({ selected, itemAccessor }) {
  return (
    <div className="flex p-2">
      {selected === 'Select' && (
        <div className="text-xs text-white mr-2 mt-2">Loading suggested {pretiffyTypeOfItem(itemAccessor)}s: </div>
      )}
      {selected === 'Contains' && (
        <div className="text-xs text-white mr-2 mt-2">Loading included {pretiffyTypeOfItem(itemAccessor)}s: </div>
      )}
      <div className="mt-1">
        <ScaleLoader height={7} width={2} margin={1} color="#fff" loading />
      </div>
    </div>
  );
}

SearchItemsFromDb.propTypes = {
  itemAccessor: PropTypes.string,
  typeOfItemName: PropTypes.string,
  onDismiss: PropTypes.func,
  onDoneClick: PropTypes.func,
  onRowClick: PropTypes.func,
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  setTagsIdContains: PropTypes.func,
};

Header.propTypes = {
  typeOfItemName: PropTypes.string,
  onCrossButtonClick: PropTypes.func,
};

DropdownMenu.propTypes = {
  items: PropTypes.array,
  selected: PropTypes.oneOf(['Select', 'Contains']),
  setSelected: PropTypes.func,
};

LoadingComponent.propTypes = {
  selected: PropTypes.oneOf(['Select', 'Contains']),
  itemAccessor: PropTypes.string,
};
