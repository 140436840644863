import PropTypes from 'prop-types';
import useCurrentUser from 'src/customHooks/useCurrentUser';
import useInfiniteScroll from 'src/customHooks/useInfiniteScroll';
import useProfileButtons from 'src/customHooks/useProfileButtons';
import {
  discoverChargeMetadataSchema,
  discoverInvoiceMetadataSchema,
  discoverCustomerMetadataSchema,
  discoverAddressSchema,
  getMetadataColumns,
  getChargeMetadataProperties,
  getInvoiceMetadataProperties,
  getCustomerMetadataProperties,
  getAddressProperties,
  formatValues,
} from 'src/components/Generic/Tables/Table/tableUtils';

import { capitalize, formatCurrencyAmounts } from 'src/lib/generic/handlers';

import withApolloQuery from 'src/lib/HOC/withApolloQuery';
import {
  useUpdateRightPaneCurrentData,
  useRightPaneNotLoadingAnymore,
} from 'src/components/Analyze/Overview/hooks/overviewProvider';

import { tableClassNamesProps } from 'src/components/Generic/Tables/Table/Table';
import CellLayout from 'src/components/Analyze/DetailModal/Tables/CellLayout/CellLayout';
import TableLink from 'src/components/Analyze/DetailModal/Tables/TableLink/TableLink';
import { getColumnsWithOrderBy } from 'src/components/Analyze/Overview/OverviewTable/tableSettingsHelpers';
import { formatDatabaseRecords } from 'src/lib/handleTimezone';
import {
  ProfileStripeLogo,
  ProfileActiveCampaignLogo,
  BackArrow,
  LoadingComponent as LoadingIcon,
} from 'src/components/Generic';
import { ORDER_BY_CUSTOMERS_ENUM_VALUES } from 'src/components/Analyze/Overview/OverviewTable/tableQueries';
import { ORDER_COLUMN_DIRECTIONS, ORDER_BY_CUSTOMERS_ENUM } from 'wmx-shared-code/reports/emailsOverview';
import { RightPaneHeader } from 'src/components/Funnel/FunnelRightPane/FunnelRightPane';

import * as WmxTable from 'src/components/Generic/Tables/WmxTable/WmxTable';
import { Flex } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { METADATA_TYPES } from 'wmx-shared-code/tablesGlobalsVariables';

export function CustomersTable({
  dataKey,
  scrollLength,
  queryParams,
  classNames,
  data,
  fetchMore,
  renderDefaultCell,
  onOrderBy,
  isLoadingTable,
  navigateBack,
  onlyTypes,
  clickAnalyzePath,
  isRightPaneFetchingData,
  onRightPaneClosing,
}) {
  const {
    defaultCurrency: { threeLetters: defaultCurrencyThreeLetters },
    isUserWithCustomerData,
    stripeIntegration: { isReady: isUserWithStripe },
  } = useCurrentUser();

  useUpdateRightPaneCurrentData({ data });
  useRightPaneNotLoadingAnymore({ isLoadingTable });

  const metadataSchema = discoverChargeMetadataSchema({ items: data[dataKey] });
  const invoiceMetadataSchema = discoverInvoiceMetadataSchema({ items: data[dataKey] });
  const customerMetadataSchema = discoverCustomerMetadataSchema({ items: data[dataKey] });
  const addressSchema = discoverAddressSchema({ items: data[dataKey] });

  const { dataChunk, hasMoreData, fetchMoreData } = useInfiniteScroll({
    scrollLength,
    incomingData: {
      dataArray: data?.[dataKey],
      key: dataKey,
    },
    fetchMore,
    fetchMoreParams: queryParams,
  });

  const detailColumns = [
    {
      Header: 'Email',
      accessor: 'email',
      orderBy: ORDER_BY_CUSTOMERS_ENUM.email,
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.asc,
    },
    {
      Header: 'Date',
      accessor: 'purchaseDate',
      orderBy: ORDER_BY_CUSTOMERS_ENUM.createdAt,
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
    },
    ...(isUserWithCustomerData
      ? [
          {
            Header: 'Amount',
            accessor: 'amount',
            orderBy: ORDER_BY_CUSTOMERS_ENUM.amount,
            orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
          },
        ]
      : []),
    ...(isUserWithStripe
      ? [
          {
            Header: 'Product',
            accessor: 'productName',
            orderBy: ORDER_BY_CUSTOMERS_ENUM.productName,
            orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.asc,
          },
          {
            Header: 'Price',
            accessor: 'priceName',
            orderBy: ORDER_BY_CUSTOMERS_ENUM.productName,
            orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.asc,
          },
          {
            Header: 'Description',
            accessor: 'chargeDescription',
          },
          {
            Header: 'Name',
            accessor: 'name',
          },
          ...getMetadataColumns({ metadataSchema, metaType: METADATA_TYPES.charge }),
          ...getMetadataColumns({ metadataSchema: invoiceMetadataSchema, metaType: METADATA_TYPES.invoice }),
          ...getMetadataColumns({ metadataSchema: customerMetadataSchema, metaType: METADATA_TYPES.customer }),
          ...getMetadataColumns({ metadataSchema: addressSchema, metaType: METADATA_TYPES.address }),
        ]
      : []),
    {
      Header: 'Profiles',
      accessor: 'profiles',
    },
  ];

  // eslint-disable-next-line react/prop-types
  const CustomerLink = ({ row }) => {
    const { withStripe, stripeLink, acLink } = useProfileButtons({ row });

    return (
      <CellLayout>
        {withStripe && (
          <TableLink link={stripeLink}>
            <div className="w-10">
              <ProfileStripeLogo classNames={{ raw: 'hover:opacity-70' }} />
            </div>
          </TableLink>
        )}
        {acLink && (
          <TableLink classnames={`${(withStripe && 'ml-2') || ''}`} link={acLink}>
            <div className="w-10">
              <ProfileActiveCampaignLogo classNames={{ raw: 'hover:opacity-70' }} />
            </div>
          </TableLink>
        )}
      </CellLayout>
    );
  };

  // eslint-disable-next-line no-use-before-define
  const formattedFetchedData = formatCustomerData({
    rows: dataChunk,
    metadataSchema,
    invoiceMetadataSchema,
    customerMetadataSchema,
    addressSchema,
    defaultCurrencyThreeLetters,
  });

  const extendedHeaders = getColumnsWithOrderBy({ columns: detailColumns, onOrderBy });

  const detailTableProps = {
    name: 'detailCustomerModal',
    columns: detailColumns,
    extendHeader: { columns: extendedHeaders },
    extendRows: {
      columns: [
        {
          accessor: 'Profiles',
          // eslint-disable-next-line react/prop-types
          cellChildren: ({ renderedCell, row }) => {
            const linkProps = { renderedCell, row };
            return <CustomerLink {...linkProps} />;
          },
        },
      ],
      defaultCellChildren: renderDefaultCell,
    },
    data: formattedFetchedData,
    setNextChunk: fetchMoreData,
    hasMoreData,
    classNames: {
      ...classNames,
      body: {
        ...classNames.body,
        bodyCell: `${classNames.body.bodyCell}  last:text-right last:pr-2 last:pl-0 `,
      },
      header: {
        ...classNames.header,
        headerCell: `${classNames.header.headerCell} last:text-right last:pr-2 last:pl-0`,
      },
    },
    columnsOptions: true,
    compulsoryColumns: ['Email', 'Date', 'Amount', 'Profiles'],
    defaultColumns: ['Price'],
  };

  const navigationProps = { navigateBack, onlyTypes, clickAnalyzePath, isRightPaneFetchingData, onRightPaneClosing };

  return (
    <WmxTable.TableProvider tableProps={detailTableProps}>
      <RightPaneHeader onCrossButtonClick={onRightPaneClosing}>
        <Navigation {...navigationProps} />
      </RightPaneHeader>
      <WmxTable.Table />
    </WmxTable.TableProvider>
  );
}

export const Navigation = ({ navigateBack, onlyTypes, clickAnalyzePath, isRightPaneFetchingData }) => {
  return (
    <Flex justify="between">
      <div className="flex items-center">
        <BackArrow onClick={navigateBack} />
        {onlyTypes.map((item, idx) => {
          const isNotLast = onlyTypes.length - 1 > idx;
          const key = `${item}-${idx}`;

          return (
            <>
              <button
                key={key}
                type="button"
                className={`analyzePath px-1 py-1 mx-2 text-wmxText-200 text-sm border border-transparent rounded-md ${
                  (isNotLast && 'hover:text-wmxText-100 hover:bg-wmxHighlightDark-50 cursor-pointer') ||
                  'cursor-default pointer-events-none'
                }`}
                onClick={() => clickAnalyzePath(item)}
              >
                {capitalize(item)}
              </button>
              {isNotLast && (
                <span key={`${key}-slash`} className="text-sm text-wmxText-200">
                  /
                </span>
              )}
            </>
          );
        })}
        {isRightPaneFetchingData && (
          <div className="flex items-center">
            <LoadingIcon />
          </div>
        )}
      </div>
      <WmxTable.TableSettings />
    </Flex>
  );
};

Navigation.propTypes = {
  clickAnalyzePath: PropTypes.func,
  isRightPaneFetchingData: PropTypes.any,
  navigateBack: PropTypes.any,
  onlyTypes: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  }),
};

export default withApolloQuery(CustomersTable);

const formatCustomerData = ({
  rows,
  metadataSchema,
  invoiceMetadataSchema,
  customerMetadataSchema,
  addressSchema,
  defaultCurrencyThreeLetters,
}) => {
  const formatAmount = ({ revenueInCents }) => {
    return formatCurrencyAmounts({
      amount: revenueInCents / 100,
      defaultCurrency: defaultCurrencyThreeLetters,
    });
  };

  return rows.map((row) => {
    let metaProperties = {};
    if (row.metadata) {
      metaProperties = getChargeMetadataProperties({ item: row, metadataSchema });
    }
    let invoiceMetaProperties = {};
    if (row.invoiceMetadata) {
      invoiceMetaProperties = getInvoiceMetadataProperties({ item: row, invoiceMetadataSchema });
    }
    let customerMetaProperties = {};
    if (row.customerMetadata) {
      customerMetaProperties = getCustomerMetadataProperties({ item: row, customerMetadataSchema });
    }
    let addressProperties = {};
    if (row.address) {
      addressProperties = getAddressProperties({ item: row, addressSchema });
    }

    const rowAndMeta = {
      ...row,
      ...formatValues({
        data: {
          ...metaProperties,
          ...invoiceMetaProperties,
          ...customerMetaProperties,
          ...addressProperties,
        },
      }),
    };
    return {
      ...rowAndMeta,
      priceName: row?.priceName || '-',
      amount: formatAmount({ revenueInCents: row?.amount }),
      purchaseDate: formatDatabaseRecords({ date: row.purchaseDate, format: 'D MMMM YYYY' }),
    };
  });
};
CustomersTable.propTypes = {
  data: PropTypes.shape({
    activeCampaignUrl: PropTypes.string,
  }),
  dataKey: PropTypes.string,
  renderDefaultCell: PropTypes.func,
  renderEachScroll: PropTypes.number,
  onOrderBy: PropTypes.shape({
    onHeaderClick: PropTypes.func,
    columnOrderBy: PropTypes.oneOf([...ORDER_BY_CUSTOMERS_ENUM_VALUES]),
    columnOrderByDirection: PropTypes.oneOf([...Object.values(ORDER_COLUMN_DIRECTIONS)]),
  }),
  queryParams: PropTypes.shape({
    fetchPolicy: PropTypes.string,
    variables: PropTypes.shape({
      offset: PropTypes.number,
      limit: PropTypes.number,
      campaignId: PropTypes.string,
      automationId: PropTypes.string,
      convWindow: PropTypes.number,
      timeRange: PropTypes.shape({
        start: PropTypes.object,
        end: PropTypes.object,
      }),
    }), // Going directly to withApolloQuery
  }),
  ...tableClassNamesProps,
};
