import PropTypes from 'prop-types';

export default function PencilSvg({ svgClassName, pathClassName, width = 30, height = 30 }) {
  return (
    <svg
      className={`fill-current ${svgClassName}`}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.166626" y="0.487915" rx="5" />
      <path
        className={pathClassName}
        d="M13.8213 7.47613L16.1786 9.83347M14.8213 6.47613C15.1339 6.16353 15.5579 5.98792 16 5.98792C16.442 5.98792 16.866 6.16353 17.1786 6.47613C17.4912 6.78874 17.6668 7.21272 17.6668 7.6548C17.6668 8.09689 17.4912 8.52087 17.1786 8.83347L7.99996 18.0121H5.66663V15.6308L14.8213 6.47613Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

PencilSvg.propTypes = {
  height: PropTypes.number,
  pathClassName: PropTypes.string,
  svgClassName: PropTypes.string,
  width: PropTypes.number,
};
