import PropTypes from 'prop-types';

import useAccountStatus from 'src/customHooks/useAccountStatus';
import { Dropdown } from 'src/components/Generic';
import { dropdownPropTypes } from 'src/components/Generic/Dropdown/Dropdown';

import { getDatePickerLabels, getDatepickerDropdownOption } from 'src/components/Generic/DatePicker/getTimeRange';

// This component is locally scoped. Does not affect recoil time range
export default function TimeRangeLabelsDropdown({
  withCustomDatePicker,
  selectedLabel,
  setSelectedLabel,
  isDisabled,
  classNames,
}) {
  const { accountType } = useAccountStatus();

  const dynamicRanges = getDatePickerLabels({ withCustomDatePicker, accountType, shouldUpdateGlobalDatePicker: false });
  const optionButtonsProp = getDatepickerDropdownOption({ dynamicRanges });

  const dropdownProps = {
    optionButtonsProp,
    selectedOption: selectedLabel,
    setSelectedOption: setSelectedLabel,
    isDoubleColumn: true,
    classNames: {
      ...classNames?.dropdown,
      buttonTrigger: `border-none pl-1 pr-2 text-left justify-start ${
        isDisabled && 'cursor-not-allowed pointer-events-none'
      } ${classNames?.dropdown?.buttonTrigger}`,
      label: `dropDownLabel ${classNames?.dropdown?.label}`,
    },
    classNamesByType: { list: { width: 'w-auto' } },
    showSelectedValue: true,
  };
  return (
    <div className={`flex flex-row shadow-sm rounded-md ${classNames?.container || ''}`}>
      <Dropdown {...dropdownProps} />
    </div>
  );
}

TimeRangeLabelsDropdown.propTypes = {
  withCustomDatePicker: PropTypes.bool,
  selectedLabel: PropTypes.string,
  setSelectedLabel: PropTypes.func,
  isDisabled: PropTypes.bool,
  classNames: PropTypes.shape({
    container: PropTypes.string,
    dropdown: {
      ...dropdownPropTypes.classNames,
    },
  }),
};
