import PropTypes from 'prop-types';
import { useSidebar } from '../SidebarLayout/SidebarLayout';

export const MAIN_PADDING = '18';

export default function MainLayout({ children, classNames }) {
  const { mainRef, mainHeight, mainWidth } = useSidebar();

  return (
    <div
      id="main-content"
      ref={mainRef}
      style={{ maxWidth: mainWidth || 'none', right: 0, top: 0, height: mainHeight, padding: `${MAIN_PADDING}px` }}
      className="MainLayout bg-wmxBgDark-400 w-full relative"
    >
      <div className={`MainContentWrapper ${classNames?.container || ''} w-full h-full`}>{children}</div>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any,
  classNames: PropTypes.shape({
    container: PropTypes.string,
  }),
};
