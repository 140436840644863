const errors = {
  nonExistingItemIdCallback: ({ itemNameInService }) =>
    `There is at least a ${itemNameInService} selected that does not exist anymore.`,
};

const returns = {
  updatingTagCustomPrice: {
    successful: 'Succeeded',
    noTagsUpdated: 'No tags prices updated',
  },
};

module.exports = {
  errors,
  returns,
};
