import PropTypes from 'prop-types';
import { isBrowser } from '@redwoodjs/prerender/browserUtils';
import dayjs from 'dayjs';
import { DateRangePicker } from 'rsuite';

import { DatepickerDropdown } from 'src/components/Generic';
import './customize-datepicker.css';
import { triggerTrackEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';
import { formatDateToUserTimezone } from 'src/lib/handleTimezone';
import { useDatepicker } from './useDatepicker';

export default function DatePicker({
  placement,
  withRanges,
  disabled = false,
  additionalLabelText = 'Timerange',
  withCustomDatePicker = true,
}) {
  const {
    closeDialog,
    showTimeRangeTooltip,
    optionButtonsProp,
    triggerDropdownBtnRef,
    setTimeRangeOption,
    onRangeChange,
    onDialogOpen,
    onDialogClose,
    onOpen,
    onClose,
    datePickerValue,
    initialFixedTimeRange,
    startTimeRange,
    endTimeRange,
    dropDownContainer,
    label,
    showDatepicker,
    dynamicRanges,
  } = useDatepicker({ withCustomDatePicker });

  if (isBrowser) import('rsuite/lib/DateRangePicker/styles/index.js');

  const TimeRangeTooltip = () => {
    const datePickerLabelFormat = 'YYYY-MM-DD';
    const startFormatted = formatDateToUserTimezone({ ISODate: startTimeRange, format: datePickerLabelFormat });
    const endFormatted = formatDateToUserTimezone({ ISODate: endTimeRange, format: datePickerLabelFormat });

    return (
      <div className="absolute top-8 -right-16 bg-wmxBgDark-100 text-xs text-white py-1 px-2 rounded-md whitespace-nowrap shadow-2xl">
        {startFormatted} - {endFormatted}
      </div>
    );
  };
  const renderValue = () => <></>;

  const onTimeRangeLabelClick = (labelClicked) => {
    const eventPayload = {
      labelClicked,
    };

    triggerTrackEvent({ eventName: SEGMENT_TRACK_EVENTS_NAMES.datePickerClicked, payload: eventPayload });
  };

  return (
    <div
      className={`wmx-timerange report-time-range flex flex-col justify-center relative ${
        (disabled && 'cursor-not-allowed') || 'cursor-pointer'
      } `}
      ref={dropDownContainer}
    >
      <DatepickerDropdown
        label={additionalLabelText}
        dropdownProps={{
          optionButtonsProp,
          triggerDropdownBtnRef,
          close: closeDialog,
          onOpen: onDialogOpen,
          onClose: onDialogClose,
          onSelect: onTimeRangeLabelClick,
          selectedOption: label,
          setSelectedOption: setTimeRangeOption,
          isDoubleColumn: true,
          classNames: {
            buttonTrigger: `border-none pl-1 pr-2 text-left justify-start ${
              disabled && 'cursor-not-allowed pointer-events-none'
            }`,
            label: 'dropDownLabel',
          },
          styles: { list: { width: 'w-auto' } },
          showSelectedValue: true,
          closeOnPressOutside: (!showDatepicker && true) || false,
        }}
        showDatepicker={showDatepicker}
        datepicker={
          <DateRangePicker
            onSelect={onRangeChange}
            onEnter={onOpen}
            onExit={onClose}
            open
            value={datePickerValue || []}
            renderValue={renderValue}
            placement={placement}
            ranges={withRanges ? dynamicRanges : []}
            defaultValue={initialFixedTimeRange}
            isoWeek
            inline
          />
        }
      />
      {showTimeRangeTooltip && <TimeRangeTooltip />}
    </div>
  );
}

DatePicker.propTypes = {
  placement: PropTypes.string,
  withRanges: PropTypes.bool,
  disabled: PropTypes.bool,
  additionalLabelText: PropTypes.string,
  withCustomDatePicker: PropTypes.bool,
};

DatePicker.defaultProps = {
  placement: 'bottomEnd',
  withRanges: true,
};

function DatePickerButtonTrigger({ text, isOpen }) {
  const arrowType = isOpen ? 'up' : 'down';

  return (
    <div className="flex flex-row items-center">
      <span className="text-sm">{text}</span>
      <i className={`pl-2 text-white text-sm fas fa-chevron-${arrowType}`} />
    </div>
  );
}

DatePickerButtonTrigger.propTypes = {
  text: PropTypes.string,
  isOpen: PropTypes.bool,
};
