import { setThousandsComma } from 'wmx-shared-code/currency/currency';
import { getCurrency } from 'src/lib/generic/currency';

export const amountFormatterForGraphs = ({ defaultCurrencyThreeLetters, amount }) => {
  const [dividend, symbol] = getDividend(amount);
  const amountInK = `${amount / dividend}${symbol}`;

  const { amountFormatter } = getCurrency(defaultCurrencyThreeLetters);

  return amountFormatter({ amount: amountInK });
};

function getDividend(amount) {
  if (amount >= 1000) {
    return [1000, 'k'];
  }
  return [1, ''];
}

export const percentageChange = (v1, v2) => {
  if (Number.isNaN(v1) || Number.isNaN(v2)) return 0;
  if (v1 <= 0) return v2 * 100;

  const result = ((v2 - v1) / v1) * 100;

  return result >= 1 || result <= -1 ? result.toFixed(0) : result.toFixed(2);
};

export const getLengthOfString = (metric) => {
  if (!metric) return 0;
  let localMetric = metric;

  if (Number.isNaN(metric)) {
    localMetric = metric;
  } else {
    localMetric = metric?.toString();
  }

  return localMetric.length;
};

export const isBiggerThan = (metric, minLength, maxLength) => {
  if (!metric) return false;
  const length = getLengthOfString(metric);
  if (length >= minLength && length < maxLength) {
    return true;
  }
  return false;
};

export const divideHandler = (rev, subs) => {
  if (rev <= 0 || subs <= 0) {
    return 0;
  }

  return rev / subs;
};

export const getRevenuePerSubscriber = (rev, subs) => {
  return parseFloat(divideHandler(rev, subs));
};

export const getConversionRate = (rev, subs) => {
  return `${setThousandsComma(parseFloat(divideHandler(rev, subs) * 100).toFixed(2))}%`;
};
