import { Spacer } from 'src/components/Generic/LayoutUtils';
import { AccountDetails, DeleteAccount } from 'src/components/AccountSettings/accountSettingsComponents';
import { useTriggerPageSeenEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';

const AccountPage = () => {
  useTriggerPageSeenEvent({ pageName: SEGMENT_TRACK_EVENTS_NAMES.accountSettingsPage });

  return (
    <>
      <AccountDetails />
      <Spacer size="md" />
      <DeleteAccount />
    </>
  );
};

export default AccountPage;
