/* eslint-disable camelcase */

import { makeSegmentItemId } from 'src/components/IntegrationTopic/integrationsHelpers';
import { getSegmentItems } from 'src/lib/segmentBuilder/segmentBuilderHelpers';
import { DEFAULT_INITIAL_SEGMENT_ELEMENTS } from 'src/lib/segmentBuilder/initialSegmentElements';

export default function useNodeElements({
  onDialogOpen,
  onDialogClose,
  isDialogOpen,
  setSegmentBuilderElementClickedId,
  segmentElements,
  setSegmentElements,
  timeRange,
}) {
  const onRemoveElement = (nodeIdx) => {
    const isFirstElement = nodeIdx === 0;
    const isSegmentLongerThanOneElement = segmentElements.length > 1;

    const currentElementIsAnd = segmentElements[nodeIdx]?.connection === 'AND';
    const nextElementIsOr = segmentElements[nodeIdx + 1]?.connection === 'OR';

    const clonedElements = [...segmentElements];
    clonedElements.splice(nodeIdx, 1);

    const setDefaultSegmentElements = () => setSegmentElements(DEFAULT_INITIAL_SEGMENT_ELEMENTS);
    const handleRemoveOfFirstItemWithMoreItems = () => {
      clonedElements[nodeIdx] = { ...clonedElements[nodeIdx], connection: null };

      return clonedElements;
    };
    const handleDeletionInsideOrGroupWithPreviousANDConnection = () => {
      clonedElements[nodeIdx] = { ...clonedElements[nodeIdx], connection: 'AND' };

      return clonedElements;
    };

    if (isFirstElement && segmentElements.length === 1) return setDefaultSegmentElements();

    if (isFirstElement && isSegmentLongerThanOneElement) handleRemoveOfFirstItemWithMoreItems();
    if (currentElementIsAnd && nextElementIsOr) handleDeletionInsideOrGroupWithPreviousANDConnection();

    return setSegmentElements(clonedElements);
  };

  const getTypeOfItem = () => {
    return 'tag';
  };

  const getItemsToQuery = (nodeIdx) => {
    const segmentBuilderItemsIds = segmentElements[nodeIdx]?.value?.itemsIds;

    const segmentBuilderItemsToQuery =
      (segmentBuilderItemsIds && [
        {
          tag: { ids: [segmentBuilderItemsIds] },
        },
      ]) ||
      null;
    return segmentBuilderItemsToQuery;
  };

  const onClickItem = (elementId) => {
    setSegmentBuilderElementClickedId(elementId);
    onDialogOpen();
  };

  const onAndOrButtonsClick = (connection) => {
    const newId = makeSegmentItemId(4);

    const newElement = {
      id: newId,
      value: {},
      connection: connection.toUpperCase(),
    };

    const clonedElements = segmentElements.slice();
    clonedElements.push(newElement);

    setSegmentBuilderElementClickedId(newId);
    setSegmentElements(clonedElements);
    onDialogOpen();
  };

  const getNodeElements = ({ elementsStyles }) => {
    const firstItemIsDefined = segmentElements[0]?.value.itemName;
    return [
      {
        id: 'mainBuilder',
        data: {
          label: getSegmentItems({
            segmentElements,
            getTypeOfItem,
            getItemsToQuery,
            onRemoveElement,
            onClickItem,
            onDialogOpen,
            onDialogClose,
            isDialogOpen,
            timeRange,
            firstItemIsDefined,
            itemNameInService: 'tag',
            onAndOrButtonsClick,
            withItemsCount: false,
          }),
        },
        style: elementsStyles,
        position: { x: 0, y: 60 },
        draggable: false,
      },
    ];
  };

  return getNodeElements;
}
