import { useState, useCallback } from 'react';
import getObjectPath from 'lodash.get';

import useCurrentUser from 'src/customHooks/useCurrentUser';

const PROPS_DEFINING_STEP = ['name', 'comingSoon', 'accessor', 'final', 'segmentBuilder', 'disabled'];

const disableOptionHandler = (funnelPartData, someAccessors = [], everyAccessors = []) => {
  if (!funnelPartData && someAccessors.length < 1) return false;
  const accessorOnSamePart = [];
  funnelPartData.squares.forEach((square) => {
    if (square?.panePath) {
      square.panePath.forEach((path) => {
        accessorOnSamePart.push(path.accessor);
      });
    }
  });
  return (
    accessorOnSamePart.some((r) => someAccessors.indexOf(r) >= 0) ||
    (everyAccessors.length && everyAccessors.every((r) => accessorOnSamePart.indexOf(r) >= 0))
  );
};

/* ------------------------------------------------------------------------------------------------
 * Path Definition
 * -----------------------------------------------------------------------------------------------*/

const getAllFunnelPaths = ({ funnelPartData = null, isUserWithStripe } = {}) => {
  // const googleAds = {
  //   accessor: 'googleAds',
  //   name: 'Google ads',
  //   comingSoon: true,
  // };

  const facebookAds = {
    accessor: 'facebookAds',
    name: 'Facebook ads',
    comingSoon: true,
  };

  const typeForm = {
    accessor: 'typeForm',
    name: 'Typeform',
    comingSoon: true,
  };

  const calendly = {
    accessor: 'calendly',
    name: 'Calendly',
    comingSoon: true,
  };

  const newLead = {
    accessor: 'newLead',
    name: 'New Lead',
    ...(funnelPartData && { disabled: disableOptionHandler(funnelPartData, ['anyNewLead'], ['tag', 'list']) }),
    anyNewLead: {
      accessor: 'anyNewLead',
      name: 'All new leads',
      final: true,
      ...(funnelPartData && { disabled: disableOptionHandler(funnelPartData, ['tag', 'list', 'customField']) }),
    },
    tag: {
      accessor: 'tag',
      name: 'Tag',
      final: true,
      segmentBuilder: true,
      ...(funnelPartData && { disabled: disableOptionHandler(funnelPartData, ['tag']) }),
    },
    list: {
      accessor: 'list',
      name: 'List',
      final: true,
      segmentBuilder: true,
      ...(funnelPartData && { disabled: disableOptionHandler(funnelPartData, ['list']) }),
    },
    customField: {
      accessor: 'customField',
      name: 'Custom field',
      final: true,
      segmentBuilder: true,
      comingSoon: true,
      ...(funnelPartData && { disabled: disableOptionHandler(funnelPartData, ['customField']) }),
    },
  };

  // const UTM = {
  //   accessor: 'UTM',
  //   name: 'UTM',
  //   comingSoon: true,
  // };

  // const referrer = {
  //   accessor: 'referrer',
  //   name: 'Referrer',
  //   comingSoon: true,
  // };

  const topFunnel = {
    accessor: 'top',
    name: 'Top',
    newLead,
    frontEndPurchase: {
      accessor: 'frontEndPurchase',
      name: 'Frontend purchase',
      ...(funnelPartData && { disabled: disableOptionHandler(funnelPartData, ['frontEndPurchase']) }),
      ...(isUserWithStripe && {
        stripeProductItem: {
          accessor: 'stripeProductItem',
          name: 'Stripe Product',
          final: true,
          segmentBuilder: true,
        },
      }),
      tag: {
        accessor: 'tag',
        name: 'Tag',
        final: true,
        segmentBuilder: true,
      },
    },
    facebookAds,
  };

  const middleFunnel = {
    accessor: 'middle',
    name: 'Middle',
    automation: {
      accessor: 'automation',
      name: 'Automation',
      final: true,
      segmentBuilder: true,
    },
    application: {
      accessor: 'application',
      name: 'Application',
      tag: {
        accessor: 'tag',
        name: 'Tag',
        final: true,
        segmentBuilder: true,
      },
      typeForm,
    },
    call: {
      accessor: 'call',
      name: 'Call',
      tag: {
        accessor: 'tag',
        name: 'Tag',
        final: true,
        segmentBuilder: true,
      },
      calendly,
    },
    webinar: {
      accessor: 'webinar',
      name: 'Webinar',
      tag: {
        accessor: 'tag',
        name: 'Tag',
        final: true,
        segmentBuilder: true,
      },
    },
    trial: {
      accessor: 'trial',
      name: 'Trial',
      tag: {
        accessor: 'tag',
        name: 'Tag',
        final: true,
        segmentBuilder: true,
      },
      ...(isUserWithStripe && {
        stripeProductTrial: {
          accessor: 'stripeProductTrial',
          name: 'Stripe',
          final: true,
          segmentBuilder: true,
        },
      }),
    },
  };

  const bottomFunnel = {
    accessor: 'bottom',
    name: 'Bottom',
    ...(isUserWithStripe && {
      stripeProduct: {
        accessor: 'stripeProduct',
        name: 'Stripe',
        stripeProductItem: {
          accessor: 'stripeProductItem',
          name: 'Product',
          final: true,
          segmentBuilder: true,
        },
        anyProduct: {
          accessor: 'anyProduct',
          name: 'All products',
          final: true,
          segmentBuilder: false,
          ...(funnelPartData && { disabled: disableOptionHandler(funnelPartData, ['stripeProductItem', 'tag']) }),
        },
      },
    }),
    tag: {
      accessor: 'tag',
      name: 'Tag',
      final: true,
      segmentBuilder: true,
    },
  };

  const splittedByFunnel = {
    top: topFunnel,
    middle: middleFunnel,
    bottom: bottomFunnel,
  };

  return splittedByFunnel;
};

export const getItemConfig = ({ pathArray, isUserWithStripe }) => {
  const allDefinedTracks = getAllFunnelPaths({ isUserWithStripe });

  return getObjectPath(allDefinedTracks, pathArray);
};

export const getItemTitleWithRoute = ({ pathArray, isUserWithStripe }) => {
  const { name } = getItemConfig({ pathArray, isUserWithStripe });

  return name;
};

/* -------------------------------------------------------------------------------------------------
 * Functions Definition
 * -----------------------------------------------------------------------------------------------*/

const getOwnStepProps = ({ stepConfig }) => {
  const selfStepConfig = PROPS_DEFINING_STEP.reduce((accum, stepProp) => {
    // eslint-disable-next-line no-param-reassign
    accum[stepProp] = stepConfig[stepProp];

    return accum;
  }, {});

  return selfStepConfig;
};

const getNextStepsConfig = ({ nextPossibleSteps }) => {
  const accessorsByKey = Object.keys(nextPossibleSteps);
  const path = accessorsByKey.reduce((accum, accessorKey) => {
    if (PROPS_DEFINING_STEP.includes(accessorKey)) return accum;

    const stepConfig = nextPossibleSteps[accessorKey];
    const selfStepConfig = getOwnStepProps({ stepConfig });

    accum.push(selfStepConfig);

    return accum;
  }, []);

  return path;
};

const getNextSteps = ({ path, isUserWithStripe }, funnelPartData) => {
  const allDefinedTracks = getAllFunnelPaths({ funnelPartData, isUserWithStripe });
  const pathAccessors = path.map(({ accessor }) => accessor);

  const nextPossibleSteps = pathAccessors.reduce((steps, pathAccessor) => {
    return steps[pathAccessor];
  }, allDefinedTracks);

  return getNextStepsConfig({ nextPossibleSteps });
};

const getInitialStep = ({ initialPath, isUserWithStripe }, funnelPartData) => {
  const initialAccessor = initialPath.toLowerCase();
  const allDefinedPaths = getAllFunnelPaths({ funnelPartData, isUserWithStripe });

  const stepConfig = allDefinedPaths[initialAccessor];
  const selfStepConfig = getOwnStepProps({ stepConfig });

  return selfStepConfig;
};

/* -------------------------------------------------------------------------------------------------
 * Hook Definition
 * -----------------------------------------------------------------------------------------------*/

export const useFunnelTrackingPath = (onUpdateLocation, funnelPartData) => {
  const [trackingPath, setTrackingPath] = useState([]);
  const [nextSteps, setNextSteps] = useState([]);

  const {
    stripeIntegration: { isReady: isUserWithStripe },
  } = useCurrentUser();

  const goToPath = useCallback(
    (path) => {
      if (path.length === 1) {
        const initialPath = path[0].accessor;

        const initialLocation = getInitialStep({ initialPath, isUserWithStripe }, funnelPartData);
        setTrackingPath([initialLocation]);
      }
      const initialNextSteps = getNextSteps({ path, isUserWithStripe }, funnelPartData);
      setNextSteps(initialNextSteps);
      setTrackingPath(path);
    },
    [funnelPartData, isUserWithStripe]
  );

  const addStepToPath = ({ newPath }) => {
    const clonedtrackingPath = trackingPath.slice();
    clonedtrackingPath.push(newPath);
    setTrackingPath(clonedtrackingPath);

    const newNextSteps = getNextSteps({ path: clonedtrackingPath, isUserWithStripe }, funnelPartData);
    setNextSteps(newNextSteps);
    const direction = 'forward';
    onUpdateLocation(clonedtrackingPath, direction);
  };

  const removeStepsFromPath = ({ clickedStepAccessor }) => {
    const pathAccessors = trackingPath.map(({ accessor }) => accessor);
    const stepToReturnIdx = pathAccessors.findIndex((stepAccessor) => stepAccessor === clickedStepAccessor);

    const clonedtrackingPath = trackingPath.slice();
    const numOfRemovedSteps = clonedtrackingPath.length - 1 - stepToReturnIdx;
    const idxFromWhereRemoveSteps = stepToReturnIdx + 1;

    clonedtrackingPath.splice(idxFromWhereRemoveSteps, numOfRemovedSteps);
    setTrackingPath(clonedtrackingPath);

    const newNextSteps = getNextSteps({ path: clonedtrackingPath, isUserWithStripe }, funnelPartData);
    setNextSteps(newNextSteps);
    const direction = 'back';
    onUpdateLocation(clonedtrackingPath, direction);
  };

  return {
    trackingPath,
    currentLocation: trackingPath[0] ? trackingPath[trackingPath.length - 1] : {},
    nextSteps,
    addStepToPath,
    removeStepsFromPath,
    goToPath,
  };
};
