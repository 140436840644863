import { pretiffyLastUpdate } from 'src/lib/datetimeFormat/prettifyLastUpdate';

export const getTimeUpdated = () => {
  const deployTime = process.env.DEPLOY_TIME || new Date().toISOString();
  return pretiffyLastUpdate(deployTime);
};

export const getGitTag = () => {
  return process.env.GIT_TAG || 'v0.0.0';
};
