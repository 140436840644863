import PropTypes from 'prop-types';

export default function CrossButton({ onClick, classNames, styles, colour }) {
  const colourOptions = {
    red: '#DB3C57',
    black: '#F9FAFB',
    default: '#F9FAFB',
  };

  const colourStroke = colourOptions?.[colour] || colourOptions.default;
  const defaultCursorClassName = onClick ? '' : 'cursor-default';

  return (
    <div className={`${classNames?.container || ''}`} style={styles?.container}>
      <button
        type="button"
        className={`${classNames?.button || ''} ${defaultCursorClassName} p-4`}
        style={styles?.button}
        onClick={onClick}
      >
        <span style={styles?.span} className={`${classNames?.span || ''}`}>
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.852051 0.616211L11.8167 11.5809M0.852051 11.5809L11.8167 0.616211L0.852051 11.5809Z"
              stroke={colourStroke}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </button>
    </div>
  );
}

CrossButton.propTypes = {
  colour: PropTypes.oneOf(['red', 'black']),
  onClick: PropTypes.func,
  classNames: PropTypes.shape({
    container: PropTypes.string,
    button: PropTypes.string,
    span: PropTypes.string,
  }),
  styles: PropTypes.shape({
    container: PropTypes.object,
    button: PropTypes.object,
    span: PropTypes.object,
  }),
};
