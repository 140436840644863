import PropTypes from 'prop-types';
import { setThousandsComma } from 'src/lib/generic/handlers';

export default function CombinedLeadsMetric({ queryResult = null, leadsCount = null, squareIndexInService }) {
  const getCountFromResults = () => {
    const isSquareIdx =
      squareIndexInService !== '' && squareIndexInService !== null && typeof squareIndexInService !== 'undefined';

    return isSquareIdx ? queryResult[0][squareIndexInService] : queryResult[0][0];
  };

  const countWithoutFormat = leadsCount !== null ? leadsCount : getCountFromResults();

  const count = setThousandsComma(countWithoutFormat);

  return <>{count}</>;
}

CombinedLeadsMetric.propTypes = {
  queryResult: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  squareIndexInService: PropTypes.number,
  leadsCount: PropTypes.number,
};
