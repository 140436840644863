import PropTypes from 'prop-types';

const getSize = (size) => {
  switch (size) {
    case 'lg':
      return 'text-lg px-3 py-2 rounded-md';
    case 'md':
      return 'text-base px-3 py-2 rounded-md';
    case 'sm':
      return 'text-sm px-2 py-1 rounded-md';
    case 'xxs':
      return 'text-xxs px-1 py-0 rounded';
    default:
      return 'px-2 py-1 text-xs rounded-md';
  }
};

const renderSwitch = ({ variant, children, position = { top: '-10px', right: '10px' }, absolute, size, ...rest }) => {
  switch (variant) {
    case 'transparent':
      return (
        <span
          style={position}
          className={`${(absolute && 'absolute') || ''} bg-transparent text-wmxText-100 z-50 ${getSize(size)}`}
          {...rest}
        >
          {children}
        </span>
      );
    case 'outline':
      return (
        <span
          style={position}
          className={`${
            (absolute && 'absolute') || ''
          } bg-transparent border text-wmxText-100 border-white  z-50 ${getSize(size)}`}
          {...rest}
        >
          {children}
        </span>
      );
    case 'danger':
      return (
        <span
          style={position}
          className={`${(absolute && 'absolute') || ''} bg-wmxAlert-200 text-wmxText-100 z-50 ${getSize(size)}`}
          {...rest}
        >
          {children}
        </span>
      );
    case 'gray':
      return (
        <span
          style={position}
          className={`${(absolute && 'absolute') || ''} bg-wmxHighlightDark-50 text-wmxText-100 z-50 ${getSize(size)}`}
          {...rest}
        >
          {children}
        </span>
      );
    default:
      return (
        <span
          style={position}
          className={`${(absolute && 'absolute') || ''} bg-wmxSecondary-100 text-wmxText-100 z-50 ${getSize(size)}`}
          {...rest}
        >
          {children}
        </span>
      );
  }
};
const Badge = ({ children, variant = 'primary', absolute = true, position, size, ...rest }) => {
  return <>{renderSwitch({ children, rest, variant, absolute, position, size, ...rest })}</>;
};

Badge.propTypes = {
  badgeProps: PropTypes.object,
  children: PropTypes.any,
  variant: PropTypes.string,
  size: PropTypes.string,
  absolute: PropTypes.bool,
  position: PropTypes.object,
};

export default Badge;
