import PropTypes from 'prop-types';

const ProfileActiveCampaignLogo = ({ classNames = {} }) => {
  const { raw = '', width = 'w-12' } = classNames;

  return <img className={`${width} ${raw} block`} alt="AC Logo" src="/ac_logo.png" />;
};

export default ProfileActiveCampaignLogo;

ProfileActiveCampaignLogo.propTypes = {
  classNames: PropTypes.shape({
    width: PropTypes.string,
    raw: PropTypes.string,
  }),
};
