/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { INTEGRATION_STATUSES, PROVIDERS } from 'wmx-shared-code/accountGlobalVariables';

import useLocalStorage from 'src/customHooks/useLocalStorage';
import { useUpdateIntegrationLocalStorage } from 'src/components/IntegrationTopic/integrationsHelpers';

import { ProfileActiveCampaignLogo, Error } from 'src/components/Generic';
import IntegrationStatusCard from 'src/components/IntegrationTopic/IntegrationStatusCard/IntegrationStatusCard';

import { GET_AC_INTEGRATION } from 'src/components/IntegrationTopic/integrationQueries';

export const QUERY = GET_AC_INTEGRATION;

export const beforeQuery = (props) => {
  if (props.acStatus !== INTEGRATION_STATUSES.notConnected) {
    return { variables: props, fetchPolicy: 'cache-first' };
  }
  return { variables: props, fetchPolicy: 'network-only' };
};

export const Loading = ({ onEditClick, withStatus }) => {
  const [lastSession] = useLocalStorage('acIntegration');

  const { status, webhookId } = lastSession || {};
  return lastSession ? (
    <IntegrationStatusCard
      withStatus={withStatus}
      status={status}
      provider={PROVIDERS.activeCampaign}
      hasEditButton
      onEditClick={onEditClick}
      webhookId={webhookId}
      logo={<ProfileActiveCampaignLogo classNames={{ width: 'w-14' }} />}
    />
  ) : (
    <IntegrationStatusCard
      status="Loading"
      provider={PROVIDERS.activeCampaign}
      hasEditButton
      onEditClick={onEditClick}
      logo={<ProfileActiveCampaignLogo classNames={{ width: 'w-14' }} />}
    />
  );
};

export const Empty = ({ withStatus }) => {
  useUpdateIntegrationLocalStorage({
    networkObj: {
      status: INTEGRATION_STATUSES.notConnected,
    },
    cacheKey: 'acIntegration',
  });

  return (
    <IntegrationStatusCard
      status={INTEGRATION_STATUSES.notConnected}
      provider={PROVIDERS.activeCampaign}
      withStatus={withStatus}
      hasEditButton
      logo={<ProfileActiveCampaignLogo classNames={{ width: 'w-14' }} />}
    />
  );
};

export const Failure = ({ error }) => <Error errorMessage={error.message} />;

export const Success = ({ getActiveCampaignIntegration: acIntegration, onEditClick, withStatus }) => {
  const { status, webhookId } = acIntegration;

  useUpdateIntegrationLocalStorage({ networkObj: acIntegration, cacheKey: 'acIntegration' });

  return (
    <IntegrationStatusCard
      withStatus={withStatus}
      status={status}
      provider={PROVIDERS.activeCampaign}
      onEditClick={onEditClick}
      webhookId={webhookId}
      hasEditButton
      logo={<ProfileActiveCampaignLogo classNames={{ width: 'w-14' }} />}
    />
  );
};

Success.propTypes = {
  getActiveCampaignIntegration: PropTypes.any,
};
