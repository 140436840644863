import PropTypes from 'prop-types';
import { Redirect, routes } from '@redwoodjs/router';

import { useState, useEffect, useRef } from 'react';
import useCurrentUser from 'src/customHooks/useCurrentUser';
import useCustomerSegment from 'src/customHooks/useCustomerSegment';
import { usePeopleReportsContext } from 'src/layouts/PeopleLayout/peopleReportsProvider';

import AppLayout from 'src/layouts/AppLayout/AppLayout';
import SubmenuLink from 'src/components/SubmenuLink/SubmenuLink';
import MainHeaderLayout from 'src/layouts/MainHeaderLayout/MainHeaderLayout';
import { LoadingComponent, DatePicker, PencilSvg, BinSvg } from 'src/components/Generic';
import * as WmxTable from 'src/components/Generic/Tables/WmxTable';
import { useSidebar } from 'src/layouts/SidebarLayout/SidebarLayout';

import { Flex, HelperText, HSpacer, ScrollableArea } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { useSegmentBuilder } from 'src/contexts/SegmentBuilder/SegmentBuilder';
import SegmentBuilderContainer from 'src/contexts/SegmentBuilder/SegmentBuilderContainer';
import './peopleLayout.css';
import { DEFAULT_INITIAL_SEGMENT_ELEMENTS } from 'src/lib/segmentBuilder/initialSegmentElements';

export const SEGMENTS_FEATURE = true;
const ICON_SIZE = 18;
export default function PeopleLayout({ children, prerender = false }) {
  const layoutProps = {
    isHeader: false,
    prerender,
    submenu: {
      nav: <PeopleMenu />,
      title: 'Segments',
      isOpen: true,
    },
  };

  const { acIntegration: { isReady: isUserWithAc } = {} } = useCurrentUser();

  if (!isUserWithAc) {
    return <Redirect to={routes.onboardingPage({ slug: 'esp' })} />;
  }

  return (
    <AppLayout {...layoutProps}>
      <SegmentBuilderContainer />
      {children}
    </AppLayout>
  );
}

PeopleLayout.propTypes = {
  children: PropTypes.any,
  prerender: PropTypes.bool,
};

// Should follow the same structure as <Header /> like the others Layouts
const PeopleMenu = () => {
  const {
    stripeIntegration: { isReady: isUserWithStripe },
  } = useCurrentUser();
  const {
    segmentsList,
    loadingSegments,
    onDialogOpen,
    onRightPaneOpen,
    isAcCustomersSegmentNotDefined,
    setCurrentSegment,
    setSegmentElements,
    setIsCurrentCustomerSegment,
  } = useSegmentBuilder();
  const {
    segmentId: customerSegmentId,
    segmentName: customersSegmentName,
    segmentElementsInDb: customerSegmentElementsInDb,
  } = useCustomerSegment();

  const { mainHeight } = useSidebar();
  const scrollRef = useRef();
  const [scrollableAreaHeight, setScrollableAreaHeight] = useState(100);

  useEffect(() => {
    setScrollableAreaHeight(mainHeight - scrollRef.current.getBoundingClientRect().top);
  }, [mainHeight]);

  const onCustomerSegmentClick = () => {
    if (isAcCustomersSegmentNotDefined) {
      onRightPaneOpen();
      onDialogOpen();
      setCurrentSegment((prev) => ({ ...prev, segmentName: customersSegmentName }));
      setSegmentElements(DEFAULT_INITIAL_SEGMENT_ELEMENTS);
    }
    setIsCurrentCustomerSegment(true);
  };

  return (
    <nav id="navReports" className="w-full overflow-y-scroll">
      <ScrollableArea height={scrollableAreaHeight} scrollRef={scrollRef}>
        <Flex col items="start">
          <SubmenuLink page="peopleLeads" parentMatch="people/leads">
            Leads
          </SubmenuLink>

          <SubmenuLink page="peopleCustomers" parentMatch="people/customers" onClick={onCustomerSegmentClick}>
            {customersSegmentName}
            {!isUserWithStripe && (
              <EditSegmentButtons
                {...{
                  isCustomerSegment: true,
                  isDeletion: !!customerSegmentId,
                  segment: customerSegmentElementsInDb,
                  segmentId: customerSegmentId,
                  segmentName: customersSegmentName,
                }}
              />
            )}
          </SubmenuLink>
          {SEGMENTS_FEATURE && (
            <>
              {loadingSegments && <LoadingComponent />}
              {!loadingSegments &&
                segmentsList?.length > 0 &&
                segmentsList.map((segment) => {
                  const { id, segmentName, segment: segmentTagsDefinition } = segment;

                  return (
                    <SubmenuLink key={id} page="peopleSegmentPage" params={{ segmentId: segment.id }}>
                      {segment.segmentName}
                      <EditSegmentButtons {...{ segment: segmentTagsDefinition, segmentId: id, segmentName }} />
                    </SubmenuLink>
                  );
                })}
            </>
          )}
        </Flex>
      </ScrollableArea>
    </nav>
  );
};

export function PeopleHeader() {
  return (
    <MainHeaderLayout>
      <PeopleQueryOptions />
      <WmxTable.TableSettings />
    </MainHeaderLayout>
  );
}

export const PeopleQueryOptions = () => {
  const { isTableFetching } = usePeopleReportsContext();
  return (
    <div className="w-full flex flex-row items-center mb-0 sticky z-30 flex-grow-0">
      <div className="flex justify-start">
        <div className="mr-4">
          <DatePicker open placement="bottomStart" />
        </div>
        {isTableFetching && (
          <div className="flex items-center">
            <LoadingComponent />
          </div>
        )}
      </div>
    </div>
  );
};

function EditSegmentButtons({
  isCustomerSegment,
  isDeletion = true,
  segment: segmentTagsDefinition,
  segmentId,
  segmentName,
}) {
  const { editMode } = useSegmentBuilder();
  if (!editMode) return <></>;
  return (
    <Flex>
      <HSpacer />
      <EditButton {...{ segmentId, segmentName, segment: segmentTagsDefinition }} />
      {isDeletion && <DeleteButton {...{ segmentId, isCustomerSegment }} />}
    </Flex>
  );
}

EditSegmentButtons.propTypes = {
  isCustomerSegment: PropTypes.bool,
  isDeletion: PropTypes.bool,
  segment: PropTypes.array,
  segmentId: PropTypes.any,
  segmentName: PropTypes.string,
};

function EditButton({ segmentId, segmentName, segment: segmentTagsDefinition }) {
  const { editSegment } = useSegmentBuilder();
  const handleClick = () => editSegment({ segmentId, segmentName, segment: segmentTagsDefinition });

  return (
    <button className="hoverStrokeSecondary" type="button" onClick={(e) => handleClick(e)}>
      <PencilSvg pathClassName="stroke-gray" svgClassName="text-wmxBgDark-200" width={ICON_SIZE} height={ICON_SIZE} />
    </button>
  );
}

EditButton.propTypes = {
  segmentId: PropTypes.any,
  segment: PropTypes.array,
  segmentName: PropTypes.string,
};

function DeleteButton({ segmentId, isCustomerSegment }) {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const { getDeleteSegment } = useSegmentBuilder();

  const {
    handler: deleteSegment,
    data: segmentDeleted,
    isLoading: deletingSegment,
  } = getDeleteSegment({ isCustomerSegment });

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteConfirm(true);
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    deleteSegment({ segmentId });
  };

  if (deleteConfirm && deletingSegment) {
    return <HelperText>Deleting...</HelperText>;
  }

  if (segmentDeleted?.id === segmentId) return <></>;
  if (deleteConfirm) {
    return (
      <HelperText>
        <HSpacer size="xs" />
        <button type="button" onClick={(e) => handleDeleteClick(e)}>
          Delete?
        </button>
      </HelperText>
    );
  }
  return (
    <button className="hoverStrokeAlert" type="button" onClick={(e) => handleClick(e)}>
      <BinSvg pathClassName="stroke-gray" svgClassName="text-wmxBgDark-200" width={ICON_SIZE} height={ICON_SIZE} />
    </button>
  );
}

DeleteButton.propTypes = {
  segmentId: PropTypes.any,
  isCustomerSegment: PropTypes.bool,
};

export function DefinePeopleErrorMessage({ onClick, text }) {
  const { onRightPaneOpen, onDialogOpen, setEditingSegment } = useSegmentBuilder();

  const onDefaultClick = () => {
    onRightPaneOpen();
    onDialogOpen();
    setEditingSegment(true);
  };

  return (
    <>
      <span className="text-white text-sm">Click&nbsp;</span>
      <button type="button" className="text-white text-sm underline hover:pointer" onClick={onClick || onDefaultClick}>
        here
      </button>
      <span className="text-white text-sm">&nbsp;{text}</span>
    </>
  );
}

DefinePeopleErrorMessage.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};
