/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import { useMutation } from '@redwoodjs/web';
import WildMailIntegrationForm from 'src/components/IntegrationTopic/WildMailIntegrationForm/WildMailIntegrationForm';
// import StripeIntegrationForm from 'src/components/IntegrationTopic/StripeIntegrationForm'
import { QUERY } from 'src/components/IntegrationTopic/ActiveCampaignIntegrationCell/ActiveCampaignIntegrationCell';
import Breadcrumb from 'src/components/Breadcrumb/Breadcrumb';
import { routes } from '@redwoodjs/router';
import { Spacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';

const CREATE_INTEGRATION_MUTATION = gql`
  mutation CreateIntegrationMutation($input: CreateIntegrationInput!) {
    createIntegration(input: $input) {
      id
    }
  }
`;

const NewWildMailIntegration = ({ onSuccess }) => {
  const [createIntegration, { loading, error }] = useMutation(CREATE_INTEGRATION_MUTATION, {
    onCompleted: () => {
      onSuccess();
    },
    refetchQueries: [{ query: QUERY }],
    awaitRefetchQueries: true,
  });

  const onSave = (input) => {
    createIntegration({ variables: { input } });
    // onSuccess();
  };

  return (
    <>
      <Breadcrumb
        steps={[
          {
            route: () => routes.integrations(),
            label: 'Integrations',
          },
          {
            route: () => routes.newWildMailIntegration(),
            label: 'ActiveCampaign',
          },
        ]}
      />
      <Spacer size="md" />
      <div className="bg-wmxBgDark-100  shadow-md rounded-lg overflow-hidden m-auto ml-0">
        <header className="bg-wmxHighlightDark-100 text-white py-3 px-4">
          <h2 className="text-sm font-semibold">ActiveCampaign Integration</h2>
        </header>
        <div className="bg-wmxBgDark-100 p-4">
          <WildMailIntegrationForm onSave={onSave} loading={loading} error={error} />
        </div>
      </div>
    </>
  );
};

NewWildMailIntegration.propTypes = {
  onSuccess: PropTypes.func,
};

export default NewWildMailIntegration;
