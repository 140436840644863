/* global gql */
import { useMutation } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/toast';
import { ORDER_COLUMN_DIRECTIONS as orderColumnDirections } from 'wmx-shared-code/reports/emailsOverview';

export const GET_AC_INTEGRATION = gql`
  query getAcIntegration {
    getActiveCampaignIntegration {
      status
      webhookId
    }
  }
`;

export const GET_STRIPE_INTEGRATION = gql`
  query getStripeIntegration {
    getStripeIntegration {
      status
    }
  }
`;

export const DISCONNECT_INTEGRATION_MUTATION = gql`
  mutation disconnectIntegrationMutation {
    disconnectAcIntegration {
      accountId
    }
  }
`;

export const DISCONNECT_STRIPE_INTEGRATION_MUTATION = gql`
  mutation disconnectStripeIntegrationMutation {
    disconnectStripeIntegration {
      accountId
    }
  }
`;

export const RESYNC_AC_DATAPOINTS = gql`
  mutation resyncAcDatapoints($timeRange: TimeRangeInput!, $datapoints: [String!]) {
    triggerAcResync(timeRange: $timeRange, datapoints: $datapoints) {
      id
    }
  }
`;

export const ORDER_COLUMN_DIRECTIONS = orderColumnDirections;

export function useIntegrationMutations() {
  const onDisconnectionCompleted = () => {
    toast.success('Disconnecting integration');
  };

  const [disconnectAcIntegration, { loading: disconnectAcLoading }] = useMutation(DISCONNECT_INTEGRATION_MUTATION, {
    onCompleted: onDisconnectionCompleted,
    onError: ({ message } = {}) => toast.error(message),
    refetchQueries: [{ query: GET_AC_INTEGRATION }],
    awaitRefetchQueries: true,
  });

  const [disconnectStripeIntegration, { loading: disconectStripeLoading }] = useMutation(
    DISCONNECT_STRIPE_INTEGRATION_MUTATION,
    {
      onCompleted: onDisconnectionCompleted,
      onError: ({ message } = {}) => toast.error(message),
      refetchQueries: [{ query: GET_STRIPE_INTEGRATION }],
      awaitRefetchQueries: true,
    }
  );

  const [resyncAc, { loading: resyncLoading }] = useMutation(RESYNC_AC_DATAPOINTS, {
    onCompleted: () => toast.success('Resync has started'),
    onError: ({ message } = {}) => toast.error(message),
  });

  return {
    disconnectAcIntegration,
    disconnectStripeIntegration,
    resyncAc,
    isAnyMutationLoading: disconnectAcLoading || disconectStripeLoading || resyncLoading,
  };
}
