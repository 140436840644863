import NewStripeIntegration from 'src/components/IntegrationTopic/NewStripeIntegration';
import StripeIntegrationConfirmation from 'src/components/StripeIntegrationConfirmation';
import PropTypes from 'prop-types';

const NewStripeIntegrationPage = ({ status }) => {
  return (
    <>
      {!status && (
        <div className="bg-wmxBgDark-100 shadow-md rounded-lg overflow-hidden w-full m-auto p-8 ml-0">
          <div className="p-4">
            <p className="text-sm text-wmxText-100 mb-4">
              After clicking this button you will redirect to an Stripe authorization page and then will be redirected
              back.
            </p>
            <NewStripeIntegration />
          </div>
        </div>
      )}
      {status && <StripeIntegrationConfirmation status={status} />}
    </>
  );
};

NewStripeIntegrationPage.propTypes = {
  status: PropTypes.string,
};

export default NewStripeIntegrationPage;
