import { DialogContent, DialogOverlay } from '@reach/dialog';
import PropTypes from 'prop-types';
import '@reach/dialog/styles.css';
import 'src/css/reachModal.css';
import { useCallback } from 'react';
import * as Portal from '@radix-ui/react-portal';

export default function SearchItemsDialog({ ariaParentId, children, modalClass = '', className = '', onDismiss }) {
  const dialogContainerStyles = {
    padding: '0px',
    backgroundColor: 'transparent',
    left: 0,
    marginLeft: 0,
  };

  const dialogContentRef = useCallback((node) => {
    if (node !== null) {
      const dialogContent = node;
      if (dialogContent) {
        const windowWidth = window.innerWidth;
        const Pane = document.querySelector('.Pane2') || document.querySelector('.FunnelRightPane');
        const rightPaneWidth = Pane.offsetWidth;
        const sidebarsWrap = document.querySelector('#sidebar')?.offsetWidth;
        const modalWidth = dialogContent?.offsetWidth;
        const leftOffset = (windowWidth - rightPaneWidth - modalWidth - sidebarsWrap) / 2 + sidebarsWrap;
        dialogContent.style.left = `${leftOffset}px`;
      }
    }
  }, []);

  return (
    <DialogOverlay className="fixed overflow-hidden" onDismiss={onDismiss} style={{ zIndex: '999999999999' }}>
      <DialogContent
        aria-label={ariaParentId}
        style={dialogContainerStyles}
        className={`modalContainer ${modalClass} ${className} absolute animate-fade-in-left`}
        ref={dialogContentRef}
      >
        {children}
      </DialogContent>
    </DialogOverlay>
  );
}

SearchItemsDialog.propTypes = {
  ariaParentId: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  modalClass: PropTypes.string,
  className: PropTypes.string,
  onDismiss: PropTypes.func,
};
