import PropTypes from 'prop-types';

export default function Error({ errorMessage }) {
  return (
    <div className="w-full">
      <div className="border border-solid border-red-400 bg-wmxBgDark-300 rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-center items-center leading-normal">
        <p className="text-sm text-wmxText-200 flex items-center">
          🙄 Oh snap! Looks like we are having troubles processing your request. Please try it
          again.
        </p>
        <p className="text-xs text-wmxText-200 flex items-center">Error: {errorMessage}</p>
      </div>
    </div>
  );
}

Error.propTypes = {
  errorMessage: PropTypes.string,
};
