import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useSearchBox } from 'src/components/IntegrationTopic/integrationContextHooks';
import { Dropdown } from 'src/components/Generic';

import { Flex } from 'src/components/Generic/LayoutUtils/LayoutUtils';

import { formCSS } from 'src/lib/generic/commonClasses';

export default function DropdownWithSearchBar({ isOpen, setIsOpen, optionsName = [], ...dropdownProps }) {
  const { listContainerRef, dropdownSearchBarRef, searchBoxContainerRef } = useDropdownContainerWidth();
  const { searchBoxKeyword, resetSearchBoxValue, onSearchBoxChange } = useSearchBox({
    initialSearchKeword: '',
    debounce: 50,
  });

  const filteredOptionsName = optionsName.filter((name) => {
    if (typeof name !== 'string') return null;

    const lowerCaseName = name.toLowerCase();
    return lowerCaseName.includes(searchBoxKeyword.toLowerCase());
  });

  return (
    <div ref={dropdownSearchBarRef}>
      {isOpen && <SearchBox {...{ onInputChange: onSearchBoxChange, searchBoxContainerRef }} />}
      <Dropdown
        {...{
          emptyOptionText: 'No match for this word',
          isOpen,
          listContainerRef,
          onClose: resetSearchBoxValue,
          optionsName: searchBoxKeyword ? filteredOptionsName : optionsName,
          outsideContainerRef: dropdownSearchBarRef,
          setIsOpen,
          ...dropdownProps,
        }}
      />
    </div>
  );
}

DropdownWithSearchBar.propTypes = {
  optionsName: PropTypes.array,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

function SearchBox({ onInputChange, searchBoxContainerRef }) {
  return (
    <Flex justify="between">
      <div ref={searchBoxContainerRef} className="flex items-end mr-2">
        <input className={formCSS.input} type="text" placeholder="Search..." onChange={onInputChange} />
      </div>
    </Flex>
  );
}

SearchBox.propTypes = {
  onInputChange: PropTypes.func,
  searchBoxContainerRef: PropTypes.any,
};

function useDropdownContainerWidth() {
  const dropdownSearchBarRef = useRef(null);
  const listContainerRef = useRef(null);
  const searchBoxContainerRef = useRef(null);

  useEffect(() => {
    if (!listContainerRef.current || !searchBoxContainerRef.current) return;
    const { width: listButtonsWidth } = listContainerRef.current.getBoundingClientRect();

    const minWidth = 150;

    searchBoxContainerRef.current.style.width = `${listButtonsWidth}px`;
    searchBoxContainerRef.current.style.minWidth = `${minWidth}px`;
    listContainerRef.current.style.minWidth = `${minWidth}px`;
  });

  return {
    dropdownSearchBarRef,
    listContainerRef,
    searchBoxContainerRef,
  };
}
