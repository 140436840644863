import { useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { funnelItems } from 'src/atoms/funnelAtoms';
import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';
import usePressOutsideContainer from 'src/customHooks/usePressOutsideContainer';
import { useFunnelPresentationView } from 'src/components/Funnel/PresentationView/presentationViewHooks';
import {
  GET_FUNNEL_COMBINED_LEADS_PEOPLE,
  GET_FUNNEL_CUSTOMERS_WITH_REVENUE,
  GET_FUNNEL_EMAILS_IN_AUTOMATION,
} from 'src/components/Funnel/PresentationView/peopleDetailsQueries';

import { RightPane } from 'src/components/Funnel/FunnelRightPane/FunnelRightPane';
import PeopleDetailsTable from 'src/components/PeopleDetailsTable/PeopleDetailsTable';
import {
  useAutomationsProps,
  useCombinedLeadsProps,
  useCustomerProps,
} from 'src/components/Funnel/PresentationView/peopleDetailsSettings';
import { RowSkeletons } from 'src/components/Generic/Skeletons';

const RENDER_EACH_SCROLL_FOR_TABLE = 60;

export default function PeopleDetailsPane() {
  const mainContainerRef = useRef(null);
  const {
    funnelItemsBottom: { service: bottomFunnelService },
  } = useRecoilValue(funnelItems);
  const { timeRange } = useRecoilValue(RecoilTimeRange);
  const {
    onRightPaneClose,
    clickedRow: { squareIndex: squareIndexOnFunnel, funnelItemNameInService, rowName, funnelPart: rowFunnelPart },
    onOutsideClickResizerConditionHandler,
  } = useFunnelPresentationView();

  const funnelItemsToFetch = bottomFunnelService.slice(0, squareIndexOnFunnel + 1);

  const intialQueryParams = {
    fetchPolicy: 'cache-first',
    variables: {
      offset: 0,
      limit: RENDER_EACH_SCROLL_FOR_TABLE,
      timeRange,
      funnelItems: funnelItemsToFetch,
    },
  };

  const leadsTableProps = {
    initialQuery: GET_FUNNEL_COMBINED_LEADS_PEOPLE,
    dataPath: 'getCombinedLeadsPeople[0]',
    queryParams: intialQueryParams,
    getTableProps: useCombinedLeadsProps,
  };

  const customersTableProps = {
    initialQuery: GET_FUNNEL_CUSTOMERS_WITH_REVENUE,
    dataPath: 'getFunnelCustomersPeopleWithRevenue',
    queryParams: {
      ...intialQueryParams,
      variables: {
        ...intialQueryParams?.variables,
      },
    },
    getTableProps: useCustomerProps,
  };

  const automationTableProps = {
    initialQuery: GET_FUNNEL_EMAILS_IN_AUTOMATION,
    dataPath: 'getEmailOpensFromAutomationFunnel',
    queryParams: {
      ...intialQueryParams,
    },
    getTableProps: useAutomationsProps,
  };

  usePressOutsideContainer({
    closeContainerHandler: onRightPaneClose,
    containerRef: mainContainerRef,
    // To let the user pres the resizer to drag, and not close the Pane
    extraConditionHandler: onOutsideClickResizerConditionHandler,
  });

  const headerTitle =
    rowFunnelPart === 'bottom' && rowName === 'Revenue'
      ? 'Customers'
      : rowFunnelPart === 'bottom' && rowName !== 'Customers'
      ? `Customers - ${rowName}`
      : rowName;

  const tableProps = {
    ...(funnelItemNameInService === 'stripeProduct' || funnelItemNameInService === 'newCustomers'
      ? customersTableProps
      : funnelItemNameInService === 'automation'
      ? automationTableProps
      : leadsTableProps),
    onRightPaneClose,
    headerTitle,
    funnelItemNameInService,
    LoadingComponent: () => (
      <RowSkeletons rowsAmount={20} height={64} width="100%" foregroundColor="#1C2631" backgroundColor="#304254" />
    ),
  };

  return (
    <RightPane width="60%" mainContainerRef={mainContainerRef}>
      <PeopleDetailsTable {...tableProps} />
    </RightPane>
  );
}
