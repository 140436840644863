/* global gql */
export const GET_DATAPOINT_RECORDS = {
  query: gql`
    query getDatapointRecords(
      $datapoint: String!
      $limit: Int
      $offset: Int
      $orderBy: OrderByDatapointRecords
      $orderByDirection: OrderByDirection
      $searchKeyword: String
    ) {
      getDatapointRecords(
        datapoint: $datapoint
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        orderByDirection: $orderByDirection
        searchKeyword: $searchKeyword
      ) {
        itemId
        name
        email
        lastUpdated
        timestamp
        isEventsTable
        datapointName
        onDatapointEventName
        orderBy
        orderByDirection
      }
    }
  `,
  queryName: 'getDatapointRecords',
};
