/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-undef */
import { routes, navigate } from '@redwoodjs/router';
import PropTypes from 'prop-types';

export const QUERY = gql`
  query GET_STRIPE_INTEGRATIONS {
    getStripeIntegration {
      id
      status
    }
  }
`;

export const Loading = () => <div />;

export const Empty = () => {
  return (
    <>
      <button
        type="button"
        onClick={() => navigate(routes.newStripeIntegration())}
        className="flex bg-wmxPrimary-200 hover:bg-wmxPrimary-100 text-white text-xs font-semibold px-3 mr-4 py-1 uppercase tracking-wide rounded max-w-xs disabled:opacity-25 disabled:cursor-not-allowed"
      >
        <div className="ml-1 leading-loose">Connect Stripe</div>
      </button>
    </>
  );
};

export const Failure = ({ error }) => <div>Error: {error.message}</div>;

export const Success = ({ getStripeIntegration }) => {
  return <></>;
};

Success.propTypes = {
  getStripeIntegration: PropTypes.shape({
    status: PropTypes.string,
  }),
};

Failure.propTypes = {
  error: PropTypes.object,
};
