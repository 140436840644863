import ButtonHeader from 'src/components/Analyze/Overview/OverviewTable/ButtonHeader/ButtonHeader';
import TableButton from 'src/components/Analyze/Overview/OverviewTable/TableButton/TableButton';
import { formatCurrencyAmounts, setThousandsComma } from 'src/lib/generic/handlers';
import {
  ORDER_COLUMN_DIRECTIONS,
  ORDER_BY_EMAILS_ENUM,
  ORDER_BY_CUSTOMERS_ENUM,
} from 'wmx-shared-code/reports/emailsOverview';
import { triggerTrackEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';

export const reportTypes = {
  emails: 'emails',
  automations: 'automations',
};

export const triggerReportEngagementEvent = ({ type, payload }) => {
  const typesOfEvents = {
    [reportTypes.emails]: SEGMENT_TRACK_EVENTS_NAMES.emailReportEngaged,
    [reportTypes.automations]: SEGMENT_TRACK_EVENTS_NAMES.automationReportEngaged,
  };

  const isTypeAllowed = Object.keys(typesOfEvents).includes(type);

  if (!isTypeAllowed) return;

  const eventName = typesOfEvents[type];

  triggerTrackEvent({ eventName, payload });
};

const withModalOpening = ({ renderedCell, onCellClick, id, typeOfModal: type, activeOverview }) => {
  const onButtonClick = (ev) => {
    const typesOfItemIds = {
      [reportTypes.emails]: 'campaignClicked',
      [reportTypes.automations]: 'automationClicked',
    };

    const typeOfItemId = typesOfItemIds[activeOverview];
    const payload = {
      detailType: `${type} detail`,
      [typeOfItemId]: id,
    };

    triggerReportEngagementEvent({ type: activeOverview, payload });
    onCellClick({ ev, type, itemId: id, activeOverview });
  };

  return <TableButton onClick={onButtonClick}>{renderedCell}</TableButton>;
};

// TO DELETE AFTER IMPLEMENTATION
const getConversionRate = ({ numberOfCustomers, uniqueOpens, convRate }) => {
  const getConvRatePercentage = (convRateValue) => {
    return `${convRateValue.toFixed(2)} %`;
  };

  const numberPercentage = (numberOfCustomers / uniqueOpens) * 100;
  return convRate ? getConvRatePercentage(convRate) : getConvRatePercentage(numberPercentage);
};

const addLinkToRowName = ({ renderedCell, href, type }) => {
  const onLinkClick = (ev) => {
    ev.stopPropagation();
    triggerReportEngagementEvent({ type, payload: { detailType: 'Pressed in external link', href } });
  };

  return (
    <span className="group p-2 rounded-md hover:bg-wmxPrimary-200 block w-max relative mr-6 z-0">
      <a onClick={onLinkClick} href={href} target="_blank" rel="noreferrer">
        {renderedCell}
      </a>
      <div className="blankTargetIcon absolute -right-4 top-3 opacity-0 group-hover:opacity-100">
        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1H6V6" stroke="#F9FAFB" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6 1L1 6" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
    </span>
  );
};

const formatRevenue = ({ revenueInCents, defaultCurrencyThreeLetters }) => {
  return formatCurrencyAmounts({
    amount: revenueInCents / 100,
    defaultCurrency: defaultCurrencyThreeLetters,
  });
};

export const addCampaignData = ({ emails, isUserWithCustomerData, defaultCurrencyThreeLetters }) => {
  return emails.map((email) => {
    const {
      numberOfCustomers,
      uniqueOpens,
      automationId,
      numberOfLeads,
      convRate: convRateValue,
      revenue: revenueInCents,
    } = email;

    const leadsNumberWithComma = setThousandsComma(numberOfLeads);
    const customersNumberWithComma = setThousandsComma(numberOfCustomers);
    const opensWithComma = setThousandsComma(uniqueOpens);
    const convRate = getConversionRate({ numberOfCustomers, uniqueOpens, convRate: convRateValue });

    return {
      ...email,
      revenue: formatRevenue({
        revenueInCents,
        defaultCurrencyThreeLetters,
      }),
      category: automationId ? 'Automation' : 'Campaign',
      numberOfLeads: leadsNumberWithComma,
      numberOfCustomers: isUserWithCustomerData ? customersNumberWithComma : '-',
      convRate: isUserWithCustomerData ? convRate : '-',
      uniqueOpens: opensWithComma,
    };
  });
};

export const addAutomationData = ({ automations, isUserWithCustomerData, defaultCurrencyThreeLetters }) => {
  return automations.map((automation) => {
    const {
      numberOfCustomers,
      uniqueOpens,
      numberOfLeads,
      convRate: convRateValue,
      revenue: revenueInCents,
    } = automation;

    const convRate = getConversionRate({ numberOfCustomers, uniqueOpens, convRate: convRateValue });
    const leadsNumberWithComma = setThousandsComma(numberOfLeads);
    const customersNumberWithComma = setThousandsComma(numberOfCustomers);
    const opensWithComma = setThousandsComma(uniqueOpens);

    return {
      ...automation,
      numberOfLeads: leadsNumberWithComma,
      numberOfCustomers: isUserWithCustomerData ? customersNumberWithComma : '-',
      convRate: isUserWithCustomerData ? convRate : '-',
      uniqueOpens: opensWithComma,
      revenue: formatRevenue({ revenueInCents, defaultCurrencyThreeLetters }),
    };
  });
};

export const withAutomationDetail = ({ renderedCell, onCellClick, row, typeOfModal, activeOverview, withElement }) => {
  const { automationId: id } = row.original;

  if (withElement) return withElement({ renderedCell, onCellClick, id, typeOfModal });

  return withModalOpening({
    renderedCell,
    onCellClick,
    id,
    typeOfModal,
    activeOverview,
  });
};

export const withCampaignDetail = ({ renderedCell, onCellClick, row, typeOfModal, activeOverview }) => {
  const { campaignId: id } = row.original;

  // if (withElement) return withElement({ renderedCell, onCellClick, id, typeOfModal });

  return withModalOpening({
    renderedCell,
    onCellClick,
    id,
    typeOfModal,
    activeOverview,
  });
};

export const addAutomationLink = ({ renderedCell, row, acUrl, withElement }) => {
  const { automationId } = row.original;
  const href = `https://${acUrl}.activehosted.com/series/${automationId}`;

  if (withElement) return withElement({ renderedCell, href });

  return addLinkToRowName({ renderedCell, href, type: reportTypes.automations });
};

export const addCampaignLink = ({ renderedCell, row, acUrl, withElement }) => {
  const { campaignId } = row.original;
  const href = `https://${acUrl}.activehosted.com/campaign/${campaignId}/designer`;

  if (withElement) return withElement({ renderedCell, href });

  return addLinkToRowName({ renderedCell, href, type: reportTypes.emails });
};

export const getColumnsWithOrderBy = ({ columns, onOrderBy }) => {
  return columns.reduce((accum, { accessor, orderBy, className, triggerStyles }) => {
    if (!orderBy && !className) return accum;

    const orderByConfig = {
      accessor,
      className,
      ...(orderBy && {
        // eslint-disable-next-line react/prop-types
        cellChildren: ({ renderedCell: renderedHeader, header: headerObj }) => {
          const buttonHeaderProps = {
            renderedHeader,
            headerObj,
            style: triggerStyles,
            ...onOrderBy,
          };

          return <ButtonHeader {...buttonHeaderProps} />;
        },
      }),
    };

    accum.push(orderByConfig);

    return accum;
  }, []);
};

export const getEmailsOverviewOrderByDefaults = ({ typeOfReport, isUserWithStripe, hasUserTagsCustomerSegment }) => {
  const emailsDefault = {
    orderByColumn: isUserWithStripe
      ? ORDER_BY_EMAILS_ENUM.revenue
      : hasUserTagsCustomerSegment
      ? ORDER_BY_EMAILS_ENUM.customers
      : ORDER_BY_EMAILS_ENUM.opens,
    orderByDirection: ORDER_COLUMN_DIRECTIONS.desc,
  };

  const customersDefault = {
    orderByColumn: isUserWithStripe ? ORDER_BY_CUSTOMERS_ENUM.amount : ORDER_BY_CUSTOMERS_ENUM.createdAt,
    orderByDirection: ORDER_COLUMN_DIRECTIONS.desc,
  };

  const notSortedReport = {
    orderByColumn: null,
    orderByDirection: null,
  };

  const types = {
    emails: emailsDefault,
    automations: emailsDefault,
    customers: customersDefault,
    leads: notSortedReport,
    opens: notSortedReport,
  };

  return types[typeOfReport];
};
