/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactFlow, { Background } from 'react-flow-renderer';

export default function SegmentBuilder({ segmentElements, getNodeElements }) {
  const [elements, setElements] = useState();
  /* ------------------------------------------------------------------------------------------------
   * React Flow Elements Building
   * -----------------------------------------------------------------------------------------------*/
  useEffect(() => {
    const elementsStyles = {
      padding: 0,
      backgroundColor: 'transparent',
      width: 'auto',
      border: 'none',
    };

    const Pane = document.querySelector('.Pane2') || document.querySelector('.FunnelRightPane');
    const paneWidth = Pane.offsetWidth;

    const getNodeElementsParams = {
      initialYPosition: 25,
      defaultXPos: 94,
      elementsStyles,
      paneWidth,
    };

    const nodeElements = getNodeElements({ ...getNodeElementsParams });
    setElements([...nodeElements]);
  }, [segmentElements, getNodeElements]);

  return (
    <>
      <ReactFlow elements={elements} className="z-10 animate-fade-in-left">
        <Background />
      </ReactFlow>
    </>
  );
}

SegmentBuilder.propTypes = {
  getNodeElements: PropTypes.func,
  segmentElements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.shape({
          itemName: PropTypes.string,
          people: PropTypes.string, // count turned to string
          itemsIds: PropTypes.array,
          itemAccessor: PropTypes.string,
          condition: PropTypes.oneOf(['select', 'contains']),
        }),
        PropTypes.string,
      ]),
      connection: PropTypes.oneOf(['AND', 'OR']),
    })
  ),
};
