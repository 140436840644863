import PropTypes from 'prop-types';

const ProfileStripeLogo = ({ classNames }) => {
  const { raw = '', width = 'w-12' } = classNames;

  return <img className={`${width} ${raw}`} alt="Stripe Logo" src="/stripe_logo.png" />;
};

export default ProfileStripeLogo;

ProfileStripeLogo.propTypes = {
  classNames: PropTypes.shape({
    width: PropTypes.string,
    raw: PropTypes.string,
  }),
};
