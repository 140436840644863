import PropTypes from 'prop-types';
import Main from 'src/layouts/MainLayout/MainLayout';
import { useRef } from 'react';
import { BoxSkeleton } from 'src/components/Generic/Skeletons';
import { Dashboard } from 'src/components/Dashboard/DashboardBoxes/DashboardBoxes';
import { Flex, Spacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { SidebarProvider } from 'src/layouts/SidebarLayout/SidebarLayout';
import MainCardLayout from '../MainCardLayout/MainCardLayout';
import MainHeaderLayout from '../MainHeaderLayout/MainHeaderLayout';

export default function AppLayoutSkeleton() {
  const dashboardRef = useRef();
  return (
    <SidebarProvider>
      <div className="AppLayout flex w-full h-screen  min-h-screen justify-items-left bg-wmxBgDark-400 overflow-hidden">
        <Sidebar>
          <Flex col justify="start" items="start" grow>
            <img className="block w-10" alt="Wild Audience Logo" src="/logo-white.png" />
            <Spacer size="lg" />
            <BoxSkeleton height={20} width={160} foregroundColor="#1C2631" backgroundColor="#304254" />
            <Spacer />
            <BoxSkeleton height={20} width={130} foregroundColor="#1C2631" backgroundColor="#304254" />
            <Spacer />
            <BoxSkeleton height={20} width={120} foregroundColor="#1C2631" backgroundColor="#304254" />
            <Spacer />
            <BoxSkeleton height={20} width={160} foregroundColor="#1C2631" backgroundColor="#304254" />
            <Spacer />
          </Flex>
        </Sidebar>
        <Main>
          <MainHeaderLayout>
            <Spacer size="md" />
          </MainHeaderLayout>
          <MainCardLayout>
            <Dashboard>
              <div className="flex flex-col w-full">
                <div className="flex gap-3">
                  <BoxSkeleton height={25} width={73} foregroundColor="#1C2631" backgroundColor="#304254" />
                  <BoxSkeleton height={25} width={73} foregroundColor="#1C2631" backgroundColor="#304254" />
                  <BoxSkeleton height={25} width={73} foregroundColor="#1C2631" backgroundColor="#304254" />
                </div>
                <div className="flex flex-row relative mt-2 w-full gap-3" ref={dashboardRef}>
                  <BoxSkeleton
                    height={130}
                    width={dashboardRef?.current?.getBoundingClientRect().width / 4}
                    foregroundColor="#1C2631"
                    backgroundColor="#304254"
                  />
                  <BoxSkeleton
                    height={130}
                    width={dashboardRef?.current?.getBoundingClientRect().width / 4}
                    foregroundColor="#1C2631"
                    backgroundColor="#304254"
                  />
                  <BoxSkeleton
                    height={130}
                    width={dashboardRef?.current?.getBoundingClientRect().width / 4}
                    foregroundColor="#1C2631"
                    backgroundColor="#304254"
                  />
                </div>
              </div>
            </Dashboard>
          </MainCardLayout>
        </Main>
      </div>
    </SidebarProvider>
  );
}

const Sidebar = ({ children }) => {
  return (
    <div
      id="sidebar"
      style={{ width: 250 }}
      className="h-screen box-border menu bg-wmxBgDark-200 text-gray-300 flex-none	px-6 pb-6 pt-6 flex flex-col justify-between items-center p-32 z-50 border-r border-wmxBgDark-100 relative"
    >
      {children}
    </div>
  );
};

Sidebar.propTypes = {
  children: PropTypes.any,
};
