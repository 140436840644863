/* eslint-disable prefer-template */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, routes } from '@redwoodjs/router';

import { Flex, HSpacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { Button, LoadingComponent } from 'src/components/Generic';
import { ScaleLoader } from 'react-spinners';
import { useLoggedAs } from 'src/lib/LoggedAs/useLoggedAs';
import { formCSS } from 'src/lib/generic/commonClasses';
import { formatDatabaseRecords } from 'src/lib/handleTimezone';
import { useAccountPageContext, orderByEnum } from 'src/components/Account/AccountContext/useAccountContext';
import { ORDER_COLUMN_DIRECTIONS } from 'wmx-shared-code/reports/emailsOverview';
import { ACCOUNT_ORDER_BY } from 'wmx-shared-code/accountGlobalVariables';

const MAX_STRING_LENGTH = 150;

const truncate = (text) => {
  let output = text;
  if (text && text.length > MAX_STRING_LENGTH) {
    output = `${output.substring(0, MAX_STRING_LENGTH)} ...`;
  }
  return output;
};

const AccountsList = ({ accounts }) => {
  const {
    login: onLogginAs,
    loading: loadingLoggedAs,
    loggedAs,
    logOut: onLogoutAs,
    accountId: loggedAsAccountId,
    realAccountId,
  } = useLoggedAs();

  const [searchKeyword, setSearchKeyword] = useState('');

  const classNames = {
    container:
      'overviewTable align-middle block max-w-full bg-transparent rounded-bl-lg rounded-br-lg mr-1 flex-grow px-4 pb-0',
    table: 'w-full pb-4 table-auto spacingTable',
    body: {
      tbody: 'bg-wmxBgDark-100 overflow-auto bg-wmxBgDark-400',
      bodyCell: `h-10 text-left pl-6 text-white my-1 py-3 text-sm p-4 pb-4 first:rounded-l-md last:rounded-r-md my-2 `,
      bodyRow: `z-0 hover:bg-wmxBgDark-300 hover:bg-opacity-70 bg-wmxHighlightDark-100 hover:bg-wmxHighlightDark-200 my-2 rounded-md shadow-2xl overflow-auto `,
    },
    header: {
      headerCell:
        'z-10 text-left h-12 pl-6 p-2 border-t-0 leading-4 text-white tracking-wider bg-wmxBgDark-200 text-xs font-normal',
    },
  };
  if (loadingLoggedAs || loggedAs) return <LoadingComponent />;

  const onSubmitSearch = (e) => {
    e.preventDefault();
    setSearchKeyword(e.target[0].value);
  };

  return (
    <div className={classNames.container}>
      <table className={classNames.table}>
        <thead className={classNames.thead}>
          <tr className={classNames.header.headerRow}>
            {/* <th className={classNames.header.headerCell}>Id</th> */}
            <TableHeader
              name="Admin email"
              classNames={classNames}
              orderById={ACCOUNT_ORDER_BY.email}
              defaultOrderByDirection={ORDER_COLUMN_DIRECTIONS.asc}
            />
            <TableHeader
              name="Type"
              classNames={classNames}
              orderById={ACCOUNT_ORDER_BY.type}
              defaultOrderByDirection={ORDER_COLUMN_DIRECTIONS.asc}
            />
            <TableHeader
              name="Created"
              classNames={classNames}
              orderById={ACCOUNT_ORDER_BY.createdAt}
              defaultOrderByDirection={ORDER_COLUMN_DIRECTIONS.desc}
            />
            <th className={classNames.header.headerCell}>
              <form onSubmit={onSubmitSearch}>
                <Flex items="center">
                  <input className={formCSS.input} type="text" placeholder="Search..." />
                </Flex>
              </form>
            </th>
          </tr>
        </thead>
        <tbody className={classNames.body.tbody}>
          {accounts
            // eslint-disable-next-line react/prop-types
            .filter((account) => account.adminUser.email.indexOf(searchKeyword) > -1)
            .map((account) => (
              <tr className={classNames.body.bodyRow} key={account.id}>
                {/* <td className={classNames.body.bodyCell}>{truncate(account.id)}</td> */}
                <td className={classNames.body.bodyCell}>{truncate(account.adminUser.email)}</td>
                <td className={classNames.body.bodyCell}>{truncate(account.type)}</td>
                <td className={classNames.body.bodyCell}>
                  {formatDatabaseRecords({ date: account.createdAt, format: 'YYYY-MM-DD' })}
                </td>
                <td className={classNames.body.bodyCell}>
                  <nav className="rw-table-actions">
                    <Flex justify="end">
                      {loggedAsAccountId === account.id && loggedAs && (
                        <Button
                          disabled={loadingLoggedAs}
                          variant="danger"
                          onClick={() => onLogoutAs({ realAccountId })}
                        >
                          {(loadingLoggedAs && loggedAsAccountId === account.id && (
                            <ScaleLoader size={2} height={5} width={2} color="white" />
                          )) ||
                            'Log out'}
                        </Button>
                      )}
                      {loggedAsAccountId !== account.id && (
                        <Button
                          disabled={loadingLoggedAs}
                          onClick={() => onLogginAs({ loggedAsAccountId: account.id, realAccountId })}
                        >
                          {(loadingLoggedAs && <ScaleLoader size={2} height={5} width={2} color="white" />) || 'Log in'}
                        </Button>
                      )}
                      <HSpacer />
                      <Link
                        to={routes.editAccount({ id: account.id })}
                        title={'Edit account ' + account.id}
                        style={{ minWidth: `100px` }}
                        className="flex justify-center bg-wmxPrimary-100 hover:bg-wmxPrimary-200 text-white text-xs px-3 py-1 uppercase tracking-wide rounded max-w-xs disabled:opacity-25 disabled:cursor-not-allowed"
                      >
                        <div className="leading-loose text-center text-xs">Edit</div>
                      </Link>
                    </Flex>
                  </nav>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

AccountsList.propTypes = {
  accounts: PropTypes.shape({
    map: PropTypes.func,
  }),
};

export default AccountsList;

function TableHeader({ name, classNames, orderById, defaultOrderByDirection }) {
  const { columnOrderBy, setColumnOrderBy, columnOrderByDirection, setColumnOrderByDirection } =
    useAccountPageContext();

  const onClick = () => {
    const secondOptionOrderByDirection =
      defaultOrderByDirection === ORDER_COLUMN_DIRECTIONS.asc
        ? ORDER_COLUMN_DIRECTIONS.desc
        : ORDER_COLUMN_DIRECTIONS.asc;

    const newOrderByDirection =
      orderById === columnOrderBy && columnOrderByDirection === defaultOrderByDirection
        ? secondOptionOrderByDirection
        : defaultOrderByDirection;

    setColumnOrderBy(orderById);
    setColumnOrderByDirection(newOrderByDirection);
  };

  const arrowType = columnOrderByDirection === ORDER_COLUMN_DIRECTIONS.desc ? 'down' : 'up';
  const isOrderedByThisColumn = columnOrderBy === orderById;

  return (
    <th className={classNames.header.headerCell}>
      <button type="button" className="hover:underline" onClick={onClick}>
        {name}
      </button>
      {isOrderedByThisColumn && <i className={`pl-2 text-white text-xxs fas fa-chevron-${arrowType}`} />}
    </th>
  );
}

TableHeader.propTypes = {
  name: PropTypes.string,
  classNames: PropTypes.object,
  orderById: PropTypes.oneOf([...orderByEnum]),
  defaultOrderByDirection: PropTypes.oneOf(['ASC', 'DESC']),
};
