/* eslint-disable react/prop-types */
/* global gql */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import isEqual from 'lodash.isequal';

import useCurrentUser from 'src/customHooks/useCurrentUser';

import { EmptyComponent, Error } from 'src/components/Generic';
import { useFunnelData } from 'src/components/Funnel/funnelHooks';
import { getItemConfig } from 'src/components/Funnel/FunnelTrackingPane/funnelTrackingHooks';
import { transformTypeOfItem } from 'src/lib/funnelHelpers/funnelHelpers';
import { DEFAULT_INITIAL_SEGMENT_ELEMENTS } from 'src/lib/segmentBuilder/initialSegmentElements';

export const QUERY = gql`
  query getFunnelById(
    $funnelId: String!
    $funnelItemsFromDb: [[DbFunnelItems!]]!
    $timeRange: TimeRangeInput!
    $withFunnelItemsFromDbCalculated: Boolean!
  ) {
    getFunnelById(
      funnelId: $funnelId
      funnelItemsFromDb: $funnelItemsFromDb
      timeRange: $timeRange
      withFunnelItemsFromDbCalculated: $withFunnelItemsFromDbCalculated
    ) {
      squares {
        segmentElements {
          id
          value {
            itemName
            people
            itemsIds
            itemAccessor
            condition
          }
          connection
        }
        funnelPartIdx
        squarePartIdx
        globalIdx
        path
        itemAccessor
        funnelPartInString
        funnelItemNameInService
        peopleInSquare
        countOfIdsInSquare
        isLastSquareInFunnelPart
        peopleInFunnelPart
      }
      funnelItems {
        funnelItemNameInService
        people
        opensInEmailsInAutomations
        customersAndRevenue {
          customers
          revenue
        }
        funnelPartInString
        partialFunnelItemsQuery {
          tag {
            ids
          }
          automation {
            ids
          }
          stripeProduct {
            ids
          }
          newContactsByList {
            ids
          }
          newCustomers {
            start
            end
          }
          newContacts {
            start
            end
          }
        }
      }
    }
  }
`;

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'cache-and-network' };
};

export const Loading = (props) => {
  const {
    renderLoadingComponent,
    setDataFromInitialFetch,
    dataFromInitialFetch: dataFromInitialFetchState,
    setIsInitialFetch,
    isInitialFetch,
  } = props;

  const { funnelData, setFunnelData } = useFunnelData();

  // When user changes directly time range, we repeat the same query
  useEffect(() => {
    const funnelSquaresObj = getDefaultFunnelSquaresObj({ funnelData, isLoading: true });

    const isSameData = isEqual(funnelData, funnelSquaresObj);

    if (!isSameData) setFunnelData(funnelSquaresObj);
  }, [funnelData, setFunnelData]);

  useEffect(() => {
    if (!isInitialFetch) setIsInitialFetch(true);
  }, [isInitialFetch, setIsInitialFetch]);

  useEffect(() => {
    if (dataFromInitialFetchState !== null) setDataFromInitialFetch(null);
  }, [dataFromInitialFetchState, setDataFromInitialFetch]);

  return renderLoadingComponent(props);
};

export const Empty = () => <EmptyComponent />;

export const Failure = ({ error }) => <Error errorMessage={error.message} />;

export const Success = (props) => {
  const {
    getFunnelById: dataFromInitialFetch,
    renderSuccessComponent,
    setIsInitialFetch,
    setDataFromInitialFetch,
    dataFromInitialFetch: dataFromInitialFetchState,
  } = props;

  const {
    stripeIntegration: { isReady: isUserWithStripe },
  } = useCurrentUser();

  const { funnelData, setFunnelData } = useFunnelData();

  useEffect(() => {
    let didCancel = false;

    const isDataFromInitialChange = isEqual(dataFromInitialFetch, dataFromInitialFetchState);

    if (isDataFromInitialChange || didCancel) return () => {};

    setIsInitialFetch(false);
    setDataFromInitialFetch(dataFromInitialFetch);

    const { squares } = dataFromInitialFetch;

    const funnelSquaresObj = getDefaultFunnelSquaresObj({ funnelData, isLoading: false });

    const squaresFetchedData = squares.reduce((accumObj, square) => {
      const {
        squarePartIdx,
        funnelPartInString,
        funnelItemNameInService,
        path,
        peopleInSquare,
        peopleInFunnelPart = null,
        isLastSquareInFunnelPart,
        countOfIdsInSquare,
        segmentElements,
        globalIdx,
      } = square;

      const panePath = path.map((pathString, pathStringIdx) => {
        const pathToGetConfig = path.slice(0, pathStringIdx + 1);
        return getItemConfig({ pathArray: pathToGetConfig, isUserWithStripe });
      });

      const currentSquareData = {
        typeOfItem: transformTypeOfItem(funnelItemNameInService, 'toFrontend'),
        isSelected: squarePartIdx === 0,
        isEmpty: false,
        isLastSquare: isLastSquareInFunnelPart,
        segmentElements: segmentElements || DEFAULT_INITIAL_SEGMENT_ELEMENTS,
        funnelPart: funnelPartInString,
        panePath,
        readyToQuery: true,
        countOfIdsInSquare,
        peopleInSquareFetchedByFunnelId: peopleInSquare,
        squareGlobalIdx: globalIdx,
        squareIdxInPart: squarePartIdx,
        funnelPartNameIdx: funnelPartInString === 'top' ? 0 : funnelPartInString === 'middle' ? 1 : 2,
      };

      // eslint-disable-next-line no-param-reassign
      accumObj[funnelPartInString].squares[squarePartIdx] = currentSquareData;

      if (peopleInFunnelPart !== null) {
        // eslint-disable-next-line no-param-reassign
        accumObj[funnelPartInString].peopleInFunnelPart = peopleInFunnelPart;
      }

      return accumObj;
    }, funnelSquaresObj);

    setFunnelData({
      ...funnelData,
      ...squaresFetchedData,
    });

    return () => {
      didCancel = true;
    };
  });

  return renderSuccessComponent();
};

export const getFunnelByIdProps = {
  squares: PropTypes.arrayOf(
    PropTypes.shape({
      funnelPartIdx: PropTypes.number,
      squarePartIdx: PropTypes.number,
      path: PropTypes.arrayOf(PropTypes.string),
      itemAccessor: PropTypes.string,
      funnelPartInString: PropTypes.string,
      funnelItemNameInService: PropTypes.string,
      peopleInSquare: PropTypes.number,
    })
  ),
  funnelItems: PropTypes.arrayOf(
    PropTypes.shape({
      funnelItemNameInService: PropTypes.string,
      people: PropTypes.number,
      opensInEmailsInAutomations: PropTypes.number,
      customersAndRevenue: PropTypes.shape({
        customers: PropTypes.number,
        revenue: PropTypes.number,
      }),
      funnelPartInString: PropTypes.string,
      partialFunnelItemsQuery: PropTypes.arrayOf(
        PropTypes.arrayOf(
          PropTypes.shape({
            tag: PropTypes.shape({
              ids: PropTypes.arrayOf(PropTypes.array),
            }),
            automation: PropTypes.shape({
              ids: PropTypes.arrayOf(PropTypes.array),
            }),
            newContacts: PropTypes.shape({
              ids: PropTypes.arrayOf(PropTypes.array),
            }),
            newContactsByList: PropTypes.shape({
              ids: PropTypes.arrayOf(PropTypes.array),
            }),
            stripeProduct: PropTypes.shape({
              ids: PropTypes.arrayOf(PropTypes.array),
            }),
            newCustomers: PropTypes.shape({
              ids: PropTypes.arrayOf(PropTypes.array),
            }),
          })
        )
      ),
    })
  ),
};

Success.propTypes = {
  getFunnelById: PropTypes.shape({
    ...getFunnelByIdProps,
  }),
  renderContainer: PropTypes.func,
  renderSuccessComponent: PropTypes.func,
};

function getDefaultFunnelSquaresObj({ funnelData, isLoading }) {
  return {
    top: {
      ...funnelData.top,
      squares: [],
      isLoadingFetchedData: isLoading,
    },
    middle: {
      ...funnelData.middle,
      squares: [],
      isLoadingFetchedData: isLoading,
    },
    bottom: {
      ...funnelData.bottom,
      squares: [],
      isLoadingFetchedData: isLoading,
    },
  };
}
