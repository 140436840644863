import { useEffect } from 'react';

import { ORDER_COLUMN_DIRECTIONS, ORDER_BY_RECORDS_ENUM } from 'wmx-shared-code/datapointsRecords/datapointsRecords';

import useInfiniteScroll from 'src/customHooks/useInfiniteScroll';
import useTableHeight from 'src/customHooks/useTableHeight';

import { getColumnsWithOrderBy as getColumnsOrderBy } from 'src/components/Analyze/Overview/OverviewTable/tableSettingsHelpers';

import { getLastTwClassNameValue, formatReportsTimestamp, takeLastSAwayFromString } from 'src/lib/generic/handlers';
import { useIntegrationsContext } from 'src/contexts/Integrations';

export default function useDatapointRecordsTableProps({ data, dataKey, fetchMore, queryParams, onOrderBy }) {
  const containerMarginTop = 'mt-0';
  const twMarginTopNum = getLastTwClassNameValue(containerMarginTop);
  const { mainTableContainerRef, tableHeight } = useTableHeight({ twMarginTopNum });

  const dataArray = data?.[dataKey];
  const [{ datapointName, orderBy, orderByDirection, isEventsTable, onDatapointEventName } = {}] = dataArray || [];

  useUpdateCurrentDatapoint({ datapointName });
  useUpdateOrderBy({
    ...onOrderBy,
    newOrderByConfig: { orderBy, orderByDirection },
  });

  const recordNameColumn = isEventsTable ? onDatapointEventName : datapointName;

  const possibleHeaders = {
    recordName: {
      name: takeLastSAwayFromString(recordNameColumn), // Tag, List, Campaign
      accessor: 'name',
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.asc,
      orderBy: ORDER_BY_RECORDS_ENUM.name,
    },
    email: {
      name: 'Contact',
      accessor: 'email',
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.asc,
      orderBy: ORDER_BY_RECORDS_ENUM.email,
    },
    lastUpdated: {
      name: 'Last updated',
      accessor: 'lastUpdated',
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
      orderBy: ORDER_BY_RECORDS_ENUM.lastUpdated,
      display: !isEventsTable,
    },
    timestamp: {
      name: 'Last updated',
      accessor: 'timestamp',
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
      orderBy: ORDER_BY_RECORDS_ENUM.timestamp,
    },
  };

  const classNames = {
    container: `pt-0 ${containerMarginTop}`,
    table: 'w-full spacingTable',
    header: {
      headerCell:
        'z-10 text-left h-12 pl-6 p-2 border-t-0 leading-4 text-white tracking-wider bg-wmxBgDark-200 text-xs font-normal last:text-right last:pr-6 last:pl-0',
      thead: 'text-white',
    },
    body: {
      tbody: 'bg-wmxBgDark-400',
      bodyCell:
        'h-10 text-left pl-6 text-white my-1 py-3 text-sm truncate sp-4 pb-4 first:rounded-l-md last:rounded-r-md  last:pr-4 last:pl-0 last:text-right last:pr-6 last:pl-0',
      bodyRow: `z-0 hover:bg-wmxBgDark-300 hover:bg-opacity-70 bg-wmxHighlightDark-100 hover:bg-wmxHighlightDark-200 my-2 rounded-md shadow-2xl `,
    },
  };

  const scrollLength = queryParams?.variables?.limit;

  const { hasMoreData, dataChunk, fetchMoreData } = useInfiniteScroll({
    scrollLength,
    incomingData: {
      dataArray: data?.[dataKey],
      key: dataKey,
    },
    fetchMore,
    fetchMoreParams: queryParams,
  });

  const columns = Object.values(possibleHeaders).reduce(
    (accum, { name, accessor, orderBy: orderByParam, orderByDefaultDirection, display }) => {
      const isColumnVisible = !!dataArray[0]?.[accessor];
      if (isColumnVisible && display !== false) {
        const column = { Header: name, accessor, orderBy: orderByParam, orderByDefaultDirection };
        accum.push(column);
      }

      return accum;
    },
    []
  );

  const columnsWithOrderByConfig = getColumnsOrderBy({ columns, onOrderBy });

  const extendHeader = {
    columns: columnsWithOrderByConfig,
  };

  return {
    name: 'datapoint-records',
    data: formatAndOrderTableData({ rows: dataChunk, possibleHeaders }),
    hasMoreData,
    height: tableHeight,
    extendHeader,
    setNextChunk: fetchMoreData,
    mainContainerRef: mainTableContainerRef,
    classNames,
    columns,
  };
}

function formatAndOrderTableData({ rows, possibleHeaders }) {
  const formattedData = rows.map((row) => {
    const { lastUpdated: { accessor: lastUpdatedAccessor } = {}, timestamp: { accessor: timestampAccessor } = {} } =
      possibleHeaders;

    return {
      ...row,
      ...(lastUpdatedAccessor && {
        [lastUpdatedAccessor]: row[lastUpdatedAccessor]
          ? formatReportsTimestamp(row[lastUpdatedAccessor], 'D MMM YY - HH:mm:ss')
          : '-',
      }),
      ...(timestampAccessor && {
        [timestampAccessor]: row[timestampAccessor]
          ? formatReportsTimestamp(row[timestampAccessor], 'D MMM YY - HH:mm:ss')
          : '-',
      }),
    };
  });

  return formattedData;
}

function useUpdateCurrentDatapoint({ datapointName }) {
  const { currentDatapointRecordsNames, setCurrentDatapointRecordsNames } = useIntegrationsContext();

  useEffect(() => {
    if (datapointName && currentDatapointRecordsNames?.datapointName !== datapointName) {
      setCurrentDatapointRecordsNames({ ...currentDatapointRecordsNames, datapointName });
    }
  });
}

function useUpdateOrderBy({
  columnOrderBy,
  onHeaderClick,
  columnOrderByDirection,
  newOrderByConfig: { orderBy: newOrderBy, orderByDirection: newOrderByDirection } = {},
}) {
  useEffect(() => {
    if (columnOrderBy !== newOrderBy || columnOrderByDirection !== newOrderByDirection) {
      onHeaderClick({ newOrderBy, newOrderByDirection });
    }
  });
}
