import { routes, navigate } from '@redwoodjs/router';

import StripeStatusCheckCell from 'src/components/Cells/StripeStatusCheckCell/StripeStatusCheckCell';

const NewWildMailConfirmationPage = () => {
  return (
    <div className="bg-wmxBgDark-200 shadow-md rounded-lg overflow-hidden w-full m-auto p-8 ml-0">
      <div className="p-4 text-sm text-wmxText-100">
        <p className="mb-5">Yes! Your ActiveCampaign Account is connected!</p>
        <p className="mb-5">We are currently processing all your data in the background. This could take 10 minutes.</p>
      </div>
      <div className="flex flex-row p-4 pt-0">
        <StripeStatusCheckCell />
        <button
          type="button"
          onClick={() => navigate(routes.onboardingPage({ slug: 'esp' }))}
          className="flex bg-wmxPrimary-200 hover:bg-wmxPrimary-100 text-white text-xs font-semibold px-3 py-1 uppercase tracking-wide rounded max-w-xs disabled:opacity-25 disabled:cursor-not-allowed"
        >
          <div className="flex flex-row items-center">
            <div className="ml-1 leading-loose">Continue to onboarding</div>
            <div className="ml-2 fas fa-arrow-right" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default NewWildMailConfirmationPage;
