import PropTypes from 'prop-types';

import useCurrentUser from 'src/customHooks/useCurrentUser';

import GetRevenuePerLeadCell from 'src/components/Cells/GetRevenuePerLeadCell/GetRevenuePerLeadCell';
import { LoadingComponent } from 'src/components/Generic';
import { getAmountWithCurrencySymbol } from 'src/lib/generic/currency';

export default function ConvRateOfCombinedLeads({
  funnelItems,
  timeRange,
  getCombinedLeadsCount: combinedLeadsArray = null,
  revenueWithoutFormat = null,
  currSquareIndexInService,
  comparableSquareIndex = null,
  typeOfExtraService,
}) {
  if (combinedLeadsArray === null && revenueWithoutFormat === null)
    return <LoadingComponent justifyFlex="justify-start" />;

  const comparableIdx = comparableSquareIndex !== null ? comparableSquareIndex : currSquareIndexInService - 1;
  const prevCount = combinedLeadsArray[comparableIdx];
  const currCount = combinedLeadsArray[currSquareIndexInService];

  const conversionRate = prevCount !== 0 ? ((currCount / prevCount) * 100).toFixed(2) : '0.00';
  const percentage = `${conversionRate} %`;

  const className = `text-2xl font-bold`;
  const leadsCount = combinedLeadsArray[0];

  const revenuePerLeadComponent =
    revenueWithoutFormat !== null ? (
      <RevenuePerLead revenueWithoutFormat={revenueWithoutFormat} leadsCount={leadsCount} />
    ) : (
      <GetRevenuePerLeadCell leadsCount={leadsCount} funnelItems={funnelItems} timeRange={timeRange} />
    );

  return typeOfExtraService === 'revenue' ? revenuePerLeadComponent : <span className={className}>{percentage}</span>;
}

export function RevenuePerLead({ revenueWithoutFormat, leadsCount, classNames }) {
  const {
    defaultCurrency: { threeLetters: defaultCurrencyThreeLetters },
  } = useCurrentUser();

  const revenuePerLeadInCents = revenueWithoutFormat / leadsCount;
  const revenuePerLead = getAmountWithCurrencySymbol({
    currency: defaultCurrencyThreeLetters,
    amount: revenuePerLeadInCents / 100,
  });

  const isLongNumber = revenuePerLead.length > 11;
  const boxRightMargin = isLongNumber ? 'mr-1' : '';
  const numberFontSize = isLongNumber ? 'text-xl' : 'text-2xl';
  const className = `${numberFontSize} ${boxRightMargin} font-bold`;

  return <span className={`${className} ${classNames || ''}`}>{revenuePerLead}</span>;
}

ConvRateOfCombinedLeads.propTypes = {
  funnelItems: PropTypes.array,
  timeRange: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object,
  }),
  getCombinedLeadsCount: PropTypes.array,
  revenueWithoutFormat: PropTypes.number,
  currSquareIndexInService: PropTypes.number,
  comparableSquareIndex: PropTypes.number,
  typeOfExtraService: PropTypes.string,
};

RevenuePerLead.propTypes = {
  revenueWithoutFormat: PropTypes.number,
  leadsCount: PropTypes.number,
  classNames: PropTypes.string,
};
