import { useRecoilValue, useRecoilState } from 'recoil';
import {
  funnelData,
  isPresentationViewRightPaneOpen as RecoilIsRightPaneOpen,
  presentationViewRightPaneDefaultSize as RecoilRightPaneDefaultSize,
  minorGlobalSquareModified as RecoilMinorGlobalSquareModified,
  clickedRowInPresentationView,
  funnelPageSource as RecoilFunnelPageSource,
  dataFromInitialFetch as RecoilDataFromInitialFetch,
} from 'src/atoms/funnelAtoms';
import useRightPane from 'src/customHooks/useRightPane';

const RIGHT_DEFAULT_SIZE_PRESENTATION = window.innerWidth / 2;

export const useFunnelPresentationView = () => {
  const [isRightPaneOpen, setIsRightPaneOpen] = useRecoilState(RecoilIsRightPaneOpen);
  const [rightPaneDefaultSize, setRightPaneDefaultSize] = useRecoilState(RecoilRightPaneDefaultSize);
  const [clickedRow, setClickedRow] = useRecoilState(clickedRowInPresentationView);

  const {
    top: { squares: topFunnelSquares },
    middle: { squares: midFunnelSquares },
    bottom: { squares: bottomFunnelSquares },
  } = useRecoilValue(funnelData);
  const minorGlobalSquareModified = useRecoilValue(RecoilMinorGlobalSquareModified);
  const [funnelPageSource, setFunnelPageSource] = useRecoilState(RecoilFunnelPageSource);
  const [dataFromInitialFetch, setDataFromInitialFetch] = useRecoilState(RecoilDataFromInitialFetch);

  const initialGlobalRecoil = {
    isRightPaneOpen,
    setIsRightPaneOpen,
    rightPaneDefaultSize,
    setRightPaneDefaultSize,
  };

  const {
    rightPaneOpenDefaultSize,
    onRightPaneOpen,
    onRightPaneClose,
    onRightPaneDragStarted,
    onRightPaneDragFinished,
    rightPaneStyles,
    onOutsideClickResizerConditionHandler,
  } = useRightPane({
    rightDefaultSize: RIGHT_DEFAULT_SIZE_PRESENTATION,
    minWidth: 50,
    globalRecoil: initialGlobalRecoil,
    initialLeftPanePaddingRem: 8,
  });

  const getSquareNameFromFunnelItems = ({ funnelSquareIdxInCurrPart, funnelPartIdxOfCurrSquare }) => {
    const squaresByFunnelPart = [topFunnelSquares, midFunnelSquares, bottomFunnelSquares];
    const funnelPartSquare = squaresByFunnelPart[funnelPartIdxOfCurrSquare][funnelSquareIdxInCurrPart];

    const { segmentElements } = funnelPartSquare;

    const funnelItemName = segmentElements ? segmentElements[0].value.itemName : null;

    return {
      funnelItemName,
    };
  };

  const getIsFunnelItemDataChanged = ({ globalSquareIndex }) => {
    const { globalSquareIndex: minorGlobalSquareIndexModified } = minorGlobalSquareModified;

    return minorGlobalSquareIndexModified !== null && minorGlobalSquareIndexModified <= globalSquareIndex;
  };

  return {
    getSquareNameFromFunnelItems,
    clickedRow,
    setClickedRow,
    isRightPaneOpen,
    setIsRightPaneOpen,
    rightPaneDefaultSize,
    setRightPaneDefaultSize,
    dataFromInitialFetch,
    setDataFromInitialFetch,
    rightPaneOpenDefaultSize,
    funnelPageSource,
    setFunnelPageSource,
    onRightPaneOpen,
    onRightPaneClose,
    onRightPaneDragStarted,
    onRightPaneDragFinished,
    rightPaneStyles,
    onOutsideClickResizerConditionHandler,
    getIsFunnelItemDataChanged,
    minorGlobalSquareModified,
  };
};
