/* global gql */

import { ORDER_BY_PEOPLE_ENUM } from 'wmx-shared-code/reports/people';

const fragments = {
  people: gql`
    fragment people on PersonContact {
      contactId
      email
      createdAt
      customerId
    }
  `,
};

export const ORDER_BY_PEOPLE_ENUM_VALUES = Object.values(ORDER_BY_PEOPLE_ENUM);

export const ALL_PEOPLE_REPORT_QUERY = {
  query: gql`
    query ALL_PEOPLE_REPORT_QUERY(
      $offset: Int
      $limit: Int
      $orderBy: OrderByPeople
      $orderByDirection: OrderByDirection
      $timeRange: TimeRangeInput!
    ) {
      getAllPeople(
        offset: $offset
        limit: $limit
        orderBy: $orderBy
        orderByDirection: $orderByDirection
        timeRange: $timeRange
      ) {
        ...people
      }
    }
    ${fragments.people}
  `,
  queryName: 'getAllPeople',
};

export const CUSTOMERS_PEOPLE_REPORT_QUERY = {
  query: gql`
    query CUSTOMERS_PEOPLE_REPORT_QUERY(
      $offset: Int
      $limit: Int
      $orderBy: OrderByPeople
      $orderByDirection: OrderByDirection
      $timeRange: TimeRangeInput!
    ) {
      getAllCustomersPeople(
        offset: $offset
        limit: $limit
        orderBy: $orderBy
        orderByDirection: $orderByDirection
        timeRange: $timeRange
      ) {
        ...people
        customerMetadata
      }
    }
    ${fragments.people}
  `,
  queryName: 'getAllCustomersPeople',
};

export const SEGMENT_PEOPLE_REPORT_QUERY = {
  query: gql`
    query SEGMENT_PEOPLE_REPORT_QUERY(
      $offset: Int
      $limit: Int
      $orderBy: OrderByPeople
      $orderByDirection: OrderByDirection
      $timeRange: TimeRangeInput!
      $segmentId: String!
    ) {
      getPeopleInSegment(
        offset: $offset
        limit: $limit
        orderBy: $orderBy
        orderByDirection: $orderByDirection
        timeRange: $timeRange
        segmentId: $segmentId
      ) {
        ...people
        customerMetadata
      }
    }
    ${fragments.people}
  `,
  queryName: 'getPeopleInSegment',
};

export const LEADS_PEOPLE_REPORT_QUERY = {
  query: gql`
    query LEADS_PEOPLE_REPORT_QUERY(
      $offset: Int
      $limit: Int
      $orderBy: OrderByPeople
      $orderByDirection: OrderByDirection
      $timeRange: TimeRangeInput!
    ) {
      getAllLeadsPeople(
        offset: $offset
        limit: $limit
        orderBy: $orderBy
        orderByDirection: $orderByDirection
        timeRange: $timeRange
      ) {
        ...people
      }
    }
    ${fragments.people}
  `,
  queryName: 'getAllLeadsPeople',
};
