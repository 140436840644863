import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezonedayjs from 'dayjs/plugin/timezone';
import { defaultTimezone } from 'src/lib/localStorageKeys';

import { FIXED_TIMERANGES, ALL_LABEL_START } from 'wmx-shared-code/timeRange';

dayjs.extend(utc);
dayjs.extend(timezonedayjs);

export const getGuessedTimezone = () => dayjs.tz.guess();

export const formatDatabaseRecords = ({ date, format }) => {
  // In the best scenario, this should be returned. But this time I prefer not to break anything.
  // return formatDateToUserTimezone({ ISODate: date, format });

  const { timezone } = defaultTimezone.get();

  // only 24hours timeRange send an object
  if (typeof date !== 'object') {
    const utcDayjsObject = dayjs.utc(date);
    if (timezone) return dayjs.tz(utcDayjsObject, timezone).format(format);
    return dayjs(date).format(format);
  }
  const { start, end } = date;

  const utcDayjsStartObject = dayjs.utc(start);
  const utcDayjsEndObject = dayjs.utc(end);

  if (timezone) {
    const startWithTz = dayjs.tz(utcDayjsStartObject, timezone).format(format);
    const endWithTz = dayjs.tz(utcDayjsEndObject, timezone).format(format);
    const dayWithTz = dayjs.tz(utcDayjsStartObject, timezone).format('DD MMM');
    return `${dayWithTz} ${startWithTz}-${endWithTz}`;
  }
  const day = dayjs(start).format('DD MMM');
  const formattedStart = dayjs(start).format(format);
  const formattedEnd = dayjs(end).format(format);

  return `${day} ${formattedStart}-${formattedEnd}`;
};

export const getFixedTimerangeByTimezone = ({ tz, timeRange }) => {
  const { start, end } = timeRange;
  const startTimezoneRange = dayjs.tz(start, tz).toISOString();
  const endTimezoneRange = dayjs.tz(end, tz).toISOString();

  return { start: startTimezoneRange, end: endTimezoneRange };
};

export const getFixedTimeRangesByTimezone = (tz) => {
  const labels = Object.keys(FIXED_TIMERANGES);
  const labelsStartEnd = getStartAndEndOfLabelsPeriodByTimezone();
  return labels.reduce((accum, labelName) => {
    const [start, end] = labelsStartEnd[labelName];
    const { start: startTimezoneRange, end: endTimezoneRange } = getFixedTimerangeByTimezone({
      tz,
      timeRange: { start, end },
    });

    if (labelName === 'last24h') {
      // eslint-disable-next-line no-param-reassign
      accum[labelName] = [start, end];
    } else {
      // eslint-disable-next-line no-param-reassign
      accum[labelName] = [startTimezoneRange, endTimezoneRange];
    }
    return accum;
  }, {});
};

export const formatDatabaseTimeInput = ({ date, timezone: tz }) => {
  const { timezone: defaultTz } = defaultTimezone.get();
  const utcIsoString = dayjs.utc(date).toISOString();

  const timezone = tz || defaultTz;

  if (timezone) return dayjs.tz(utcIsoString, timezone).toISOString();
  return dayjs(date).toISOString();
};

// From an ISO String, takes the value to the selected time zone by user
// Example: { start: 2022-01-05T21:00:00.000Z, end: 2022-01-06T21:00:00.000Z }
// -- > In Javascript Date format in selected timezone [2022-01-06T21:00:00.000Z, 2022-01-07T00:00:00.000Z]
export const getDatePickerValueFromTimezone = ({ start: startTimeRange, end: endTimeRange }) => {
  const { timezone } = defaultTimezone.get();
  const startTimeRangeDate = new Date(startTimeRange);
  const endTimeRangeDate = new Date(endTimeRange);

  const startOfDayInTz = startTimeRangeDate.toLocaleString('en-US', { timeZone: timezone });
  const endOfDayInTz = endTimeRangeDate.toLocaleString('en-US', { timeZone: timezone });

  return [startOfDayInTz, endOfDayInTz];
};

export const now = () => dayjs.utc().toISOString();

function getStartAndEndOfLabelsPeriodByTimezone(tz) {
  const { timezone: defaultTz } = defaultTimezone.get();
  const timezone = tz || defaultTz;

  const nowDate = dayjs().toDate();
  const nowInSelectedTimezone = nowDate.toLocaleString('en-US', { timeZone: timezone });
  const dateYYYYMMDD = dayjs(nowInSelectedTimezone).format('YYYY-MM-DD');
  return getFixedTimeRangesDateMap({ nowInTimezone: dateYYYYMMDD });
}

export function getPeriodStart({ date, periodStart, substractNumber, periodSubstract }) {
  if (!substractNumber || !periodSubstract) {
    return dayjs.utc(date).startOf(periodStart).format();
  }

  return dayjs.utc(date).subtract(substractNumber, periodSubstract).startOf(periodStart).format();
}

export function getPeriodEnd({ date, periodEnd, substractNumber, periodSubstract }) {
  if (!substractNumber || !periodSubstract) {
    return dayjs.utc(date).endOf(periodEnd).format();
  }

  return dayjs.utc(date).subtract(substractNumber, periodSubstract).endOf(periodEnd).format();
}

export function getCurrentTime() {
  return dayjs.utc().format();
}

export function getPeriodStartFromCurrentTime({ periodSubstract, substractNumber }) {
  return dayjs.utc().subtract(substractNumber, periodSubstract).format();
}

function getPeriodStartAndEnd({ period, date, subtractConfig }) {
  const periodStart = typeof period === 'object' ? period.start : period;
  const periodEnd = typeof period === 'object' ? period.end : period;

  const { period: periodSubstract, number: substractNumber, start: startConfig, end: endConfig } = subtractConfig || {};

  const startSubtractNumber = startConfig ? subtractConfig.start.number : substractNumber;
  const startSubtractPeriod = startConfig ? subtractConfig.start.period : periodSubstract;
  const endSubtractNumber = endConfig ? subtractConfig.end.number : substractNumber;
  const endSubtractPeriod = endConfig ? subtractConfig.end.period : periodSubstract;

  const start =
    periodEnd === 'now'
      ? getPeriodStartFromCurrentTime({
          periodSubstract: startSubtractPeriod,
          substractNumber: startSubtractNumber,
        })
      : getPeriodStart({
          date,
          periodStart,
          periodSubstract: startSubtractPeriod,
          substractNumber: startSubtractNumber,
        });

  const end =
    periodEnd === 'now'
      ? getCurrentTime()
      : getPeriodEnd({ date, periodEnd, periodSubstract: endSubtractPeriod, substractNumber: endSubtractNumber });
  return [start, end];
}

function getFixedTimeRangesDateMap({ nowInTimezone: date }) {
  const labels = Object.keys(FIXED_TIMERANGES).reduce((accum, label) => {
    // eslint-disable-next-line no-param-reassign
    accum[label] = label;

    return accum;
  }, {});

  return {
    [labels.today]: getPeriodStartAndEnd({ period: 'day', date }),
    [labels.yesterday]: getPeriodStartAndEnd({ period: 'day', date, subtractConfig: { number: 1, period: 'd' } }),
    [labels.last24h]: getPeriodStartAndEnd({
      period: {
        start: 'hour',
        end: 'now',
      },
      subtractConfig: { start: { number: 24, period: 'hour' } },
    }),
    [labels.last7d]: getPeriodStartAndEnd({
      period: 'day',
      date,
      subtractConfig: { start: { number: 7, period: 'd' } },
    }),
    [labels.last30d]: getPeriodStartAndEnd({
      period: 'day',
      date,
      subtractConfig: { start: { number: 30, period: 'd' } },
    }),
    [labels.last3m]: getPeriodStartAndEnd({
      period: 'day',
      date,
      subtractConfig: { start: { number: 90, period: 'd' } },
    }),
    [labels.last6m]: getPeriodStartAndEnd({
      period: 'day',
      date,
      subtractConfig: { start: { number: 6, period: 'M' } },
    }),
    [labels.last12m]: getPeriodStartAndEnd({
      period: 'day',
      date,
      subtractConfig: { start: { number: 1, period: 'y' } },
    }),
    [labels.mtd]: getPeriodStartAndEnd({ period: { start: 'month', end: 'day' }, date }),
    [labels.ytd]: getPeriodStartAndEnd({ period: { start: 'year', end: 'day' }, date }),
    [labels.all]: [ALL_LABEL_START, getPeriodEnd({ date, periodEnd: 'day' })],
    [labels.custom]: [],
  };
}

export function formatDateToUserTimezone({ ISODate, tz, format }) {
  const { timezone: defaultTz } = defaultTimezone.get();
  const timezone = tz || defaultTz;

  const ISOStringDate = dayjs.utc(ISODate).tz(timezone).format(format);

  return ISOStringDate;
}

export function getTomorrowInUtc() {
  return dayjs.utc().add(1, 'day').toISOString();
}

export function turnDateFromUserTimezoneToUTCInISOString({ ISODate, tz }) {
  const { timezone: defaultTz } = defaultTimezone.get();
  const timezone = tz || defaultTz;

  const ISOStringDate = dayjs.tz(ISODate, timezone).toISOString();

  return ISOStringDate;
}

export const ISOStringFormat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
