/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';

import GetCountOfSegmentItemCell from 'src/components/Cells/GetCountOfSegmentItemCell/GetCountOfSegmentItemCell';

const hasData = (itemsQuery) => {
  if (itemsQuery && itemsQuery.length > 0) {
    return true;
  }
  return false;
};
export default function SquareLeadsCount({ itemsQuery, customTimeRange, squareIdx }) {
  const { timeRange } = useRecoilValue(RecoilTimeRange);

  if (!hasData(itemsQuery)) return <>0</>;

  return (
    <GetCountOfSegmentItemCell
      funnelItems={itemsQuery}
      timeRange={customTimeRange || timeRange}
      squareIdx={squareIdx}
    />
  );
}

SquareLeadsCount.propTypes = {
  itemsQuery: PropTypes.array,
  customTimeRange: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object,
  }),
  squareIdx: PropTypes.number,
};
