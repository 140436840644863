const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const quarterOfYear = require('dayjs/plugin/quarterOfYear');

dayjs.extend(utc);
dayjs.extend(quarterOfYear);

const ALL_LABEL_START = dayjs('1990').format();

const CUSTOM_LABEL = 'Custom';

const FIXED_TIMERANGES = {
  today: 'Today',
  last6m: 'Last 6m',
  yesterday: 'Yesterday',
  last12m: 'Last 12m',
  last24h: 'Last 24h',
  mtd: 'MTD',
  last7d: 'Last 7d',
  ytd: 'YTD',
  last30d: 'Last 30d',
  all: 'All',
  last3m: 'Last 3m',
  custom: CUSTOM_LABEL,
};

module.exports = {
  ALL_LABEL_START,
  FIXED_TIMERANGES,
  CUSTOM_LABEL,
};
