/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */

import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { modalButtons } from 'src/components/Generic/Modal/modalButtons';

const noop = () => {};

export default function Modal({
  isOpen,
  children,
  title,
  stopKeyEventPropagation,
  'aria-describedby': ariaDescribedBy,
  successButton,
  cancelButton,
  secondRightButton,
  classNames,
}) {
  const divToFocus = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const focusDiv = () => {
    divToFocus.current.focus();
  };

  useEffect(() => {
    if (isOpen && !isFocused) {
      focusDiv();
      return setIsFocused(true);
    }

    return setIsFocused(false);
  }, [isOpen]);

  const closeModalOnKeyPress = (ev) => {
    if (stopKeyEventPropagation) ev.nativeEvent.stopImmediatePropagation();

    const closeButton = successButton?.onClick || cancelButton?.onClick;

    switch (ev.key) {
      case 'Escape':
        return cancelButton?.onClick ? cancelButton.onClick(ev) : noop();
      case 'Enter':
        return closeButton ? closeButton(ev) : noop();
      default:
        return noop();
    }
  };

  const modalHeight = {
    height: 'calc(100vh - 6rem)',
    // inset: '3rem 2rem', Safari does not support inset. When possible do it through tailwind
    top: '3rem',
    bottom: '3rem',
    right: '2rem',
    left: '2rem',
  };

  const ifVisibleClassName = isOpen ? 'visible' : 'invisible';
  const topRightButtonsClassNames = {
    container: 'flex justify-center hover:bg-black hover:bg-opacity-3',
    button: 'flex flex-row items-center text-white text-opacity-50 bg-transparent p-2 hover:text-opacity-100',
    span: 'text-2xl',
  };

  const topRightButtonsStyles = { paddingTop: '0.1rem' };

  return (
    <div
      role="dialog"
      onKeyUp={closeModalOnKeyPress}
      ref={divToFocus}
      aria-labelledby="modalDialogTitle"
      aria-describedby={ariaDescribedBy}
      style={modalHeight}
      tabIndex={1}
      className={`${ifVisibleClassName} bg-wmxBgDark-100 fixed overflow-auto mx-auto z-50 rounded`}
      id="modalContainerScrollable"
    >
      <div className={classnames('text-opacity-0 mx-2 mb-2 flex flex-col items-center', classNames?.subContainer)}>
        <div className={classnames('w-full bg-wmxBgDark-100 sticky top-0 z-10', classNames?.titleContainer)}>
          <div className="z-30 absolute flex flex-row" style={{ right: '10px' }}>
            {secondRightButton && (
              <>
                <SecondButton
                  {...secondRightButton}
                  classNames={topRightButtonsClassNames}
                  styles={topRightButtonsStyles}
                />
                <Separator />
              </>
            )}
            {cancelButton &&
              modalButtons.cross({
                classNames: topRightButtonsClassNames,
                onClick: cancelButton.onClick,
                styles: topRightButtonsStyles,
              })}
          </div>
          <h2 id="modalDialogTitle" className={classnames('text-xl py-4 m-0', classNames?.title)}>
            {title}
          </h2>
        </div>
        <div className={classnames('flex flex-col items-center w-full px-10', classNames?.contentContainer)}>
          {children}
        </div>
        <div className="flex flex-row justify-end w-full">
          {/* {cancelButton && (
              <Button
                onClick={cancelButton.onClick}
                className={styles.danger}
                text={cancelButton.text}
              />
            )}
            {successButton && (
              <Button
                onClick={successButton.onClick}
                className={styles.success}
                text={successButton.text}
              />
            )} */}
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.any,
  stopKeyEventPropagation: PropTypes.bool,
  'aria-describedby': PropTypes.string,
  secondRightButton: PropTypes.oneOfType([
    PropTypes.bool, // Can only be false
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      props: PropTypes.shape({
        onClick: PropTypes.func,
        styles: PropTypes.object,
        classNames: PropTypes.object,
      }),
    }),
  ]),
  cancelButton: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.string,
    classNames: PropTypes.object,
  }),
  successButton: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.string,
  }),
  scrollingChildren: PropTypes.shape({
    length: PropTypes.number,
    loadEachScroll: PropTypes.number,
  }),
  classNames: PropTypes.shape({
    subContainer: PropTypes.string,
    contentContainer: PropTypes.string,
    title: PropTypes.string,
    titleContainer: PropTypes.string,
  }),
};

Modal.defaultProps = {
  title: 'Modal',
};

const Separator = () => {
  const styles = {
    width: '1px',
  };
  const classNames = 'bg-black bg-opacity-10 mx-2';

  return <div className={classNames} style={styles} />;
};

const SecondButton = ({ type, props, classNames, styles }) => {
  return modalButtons[type]({
    ...props,
    classNames,
    styles,
  });
};
