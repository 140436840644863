import { navigate, routes, useParams } from '@redwoodjs/router';
import WildMailStatusCheckCell from 'src/components/Cells/WildMailStatusCheckCell/WildMailStatusCheckCell';
import useCurrentUser from 'src/customHooks/useCurrentUser';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { INTEGRATION_STATUSES } from 'wmx-shared-code/accountGlobalVariables';

const StripeIntegrationConfirmation = ({ status }) => {
  const { acIntegration: { status: acStatus = INTEGRATION_STATUSES.notConnected } = {} } = useCurrentUser();

  const { error } = useParams();
  // initiate a query state with the search val
  const [errorState] = useState(error);
  // log the URL when the pathname changes
  useEffect(() => {
    console.error(errorState);
  }, [errorState]);

  const goBack = () => {
    navigate(routes.newStripeIntegration());
  };
  return (
    <div className="bg-wmxHighlightDark-100 shadow-md rounded-lg overflow-hidden w-full m-auto p-8 ml-0">
      <div className="p-4 text-sm text-wmxText-100">
        {status === 'success' && (
          <>
            <p className="mb-5">Yes! Your Stripe Account is connected!</p>
            <p className="mb-5">
              We are currently processing all your data in the background. This could take around 10 minutes.
            </p>

            <div className="flex flex-row pt-0">
              <WildMailStatusCheckCell />
              {acStatus !== INTEGRATION_STATUSES.notConnected && (
                <button
                  type="button"
                  onClick={() => navigate(routes.onboardingPage({ slug: 'esp' }))}
                  className="flex bg-wmxPrimary-200 hover:bg-wmxPrimary-100 text-white text-xs font-semibold px-3 py-1 uppercase tracking-wide rounded max-w-xs disabled:opacity-25 disabled:cursor-not-allowed"
                >
                  <div className="flex flex-row items-center">
                    <div className="ml-1 leading-loose">Continue to onboarding</div>
                    <div className="ml-2 fas fa-arrow-right" />
                  </div>
                </button>
              )}
            </div>
          </>
        )}
        {status === 'failure' && (
          <>
            <p className="mb-5 text-sm text-wmxText-100">
              {' '}
              <span className="mr-2 text-4xl block">🙄</span> Oh snap! Looks like we are having troubles processing your
              request. <br /> Please try it again.
            </p>
            <p className="mb-5 text-xs">
              <span className="mr-2 text-wmxText-100">Error details:</span> {errorState}
            </p>
            <button
              type="button"
              className="flex bg-wmxPrimary-200 hover:bg-wmxPrimary-100 text-white text-xs font-semibold px-3 py-1 uppercase tracking-wide rounded max-w-xs disabled:opacity-25 disabled:cursor-not-allowed"
              onClick={goBack}
            >
              Go back
            </button>
          </>
        )}
      </div>
    </div>
  );
};

StripeIntegrationConfirmation.propTypes = {
  status: PropTypes.string,
};

export default StripeIntegrationConfirmation;
