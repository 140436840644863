import PropTypes from 'prop-types';

import withApolloQuery from 'src/lib/HOC/withApolloQuery';
import * as WmxTable from 'src/components/Generic/Tables/WmxTable/WmxTable';
import { ORDER_COLUMN_DIRECTIONS, ORDER_BY_RECORDS_ENUM } from 'wmx-shared-code/datapointsRecords/datapointsRecords';
import useDatapointRecordsTableProps from './useDatapointRecordsTableProps';

function DatapointRecordsTable({ data, dataKey, queryParams, fetchMore, onOrderBy }) {
  const tableProps = useDatapointRecordsTableProps({
    data,
    dataKey,
    fetchMore,
    queryParams,
    onOrderBy,
  });

  return (
    <WmxTable.TableProvider tableProps={tableProps}>
      <WmxTable.Table />
    </WmxTable.TableProvider>
  );
}

export default withApolloQuery(DatapointRecordsTable);

DatapointRecordsTable.propTypes = {
  data: PropTypes.object,
  dataKey: PropTypes.string,
  queryParams: PropTypes.object,
  fetchMore: PropTypes.func,
  onOrderBy: PropTypes.shape({
    columnOrderBy: PropTypes.oneOf([...Object.values(ORDER_BY_RECORDS_ENUM)]),
    columnOrderByDirection: PropTypes.oneOf([...Object.values(ORDER_COLUMN_DIRECTIONS)]),
    onHeaderClick: PropTypes.func,
  }),
};
