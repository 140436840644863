import { useState } from 'react';
import { useMutation } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/toast';
import { useSetRecoilState } from 'recoil';

import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';
import useLocalStorage from 'src/customHooks/useLocalStorage';
import useCurrentUser from 'src/customHooks/useCurrentUser';
import { useTriggerPageSeenEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';

import { Card, Line, Spacer, Text, Title, Container } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { TimeRangeLabelsDropdown, DropdownWithSearchBar, LoadingComponent } from 'src/components/Generic';
import CurrenciesDropdownCell from 'src/components/Cells/CurrenciesDropdownCell/CurrenciesDropdownCell';
import { defaultTimeRange as localDefaultTimeRange, useDefaultTimeZone } from 'src/lib/localStorageKeys';

import {
  getFixedRangeFromDynamicRange,
  initialLabel as initialGlobalLabel,
} from 'src/components/Generic/DatePicker/getTimeRange';

import {
  UPDATE_DEFAULT_TIME_RANGE,
  UPDATE_DEFAULT_TIMEZONE,
} from 'src/pages/GeneralSettingsPage/GeneralSettingsQueries';
import { getDropdownClassNames } from './GeneralSettingsHelpers';

export default function GeneralSettingsPage() {
  useTriggerPageSeenEvent({ pageName: SEGMENT_TRACK_EVENTS_NAMES.generalSettingsPage });

  return (
    <>
      <Container size="max">
        <DefaultTimeRangeCard />
        <Spacer />
        <TimeZoneCard />
        <Spacer />
        <CurrencyCard />
      </Container>
    </>
  );
}

function TimeZoneCard() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [updateDefaultTimezone] = useMutation(UPDATE_DEFAULT_TIMEZONE, {
    onCompleted: () => {
      toast.success('Timezone updated');
    },
  });
  const { defaultTimezone, reauthenticate } = useCurrentUser();
  const initialOptionsButtons = [<LoadingComponent />];
  const { set: setLocalStoraeDefaultTimezone } = useDefaultTimeZone();

  const [selectedOption, setSelectedOption] = useState(defaultTimezone);
  const [optionsButtons, setOptionsButtons] = useState(initialOptionsButtons);

  const onDropdownOpen = async () => {
    if (optionsButtons.length === 1) {
      const countriesAndTimezones = await import('countries-and-timezones');

      const allTimezonesObj = countriesAndTimezones.getAllTimezones();
      const allTimezonesNames = Object.keys(allTimezonesObj);
      setOptionsButtons(allTimezonesNames);
    }
  };

  const onOptionClick = async (optionName) => {
    setSelectedOption(optionName);

    await updateDefaultTimezone({ variables: { timezone: optionName } });
    setLocalStoraeDefaultTimezone({ timezone: optionName });
    setTimeout(() => window.location.reload(), 1000);
    reauthenticate();
  };

  const { classNames: dropdownClassNames, classNamesByType } = getDropdownClassNames({ isDropdownOpen });

  return (
    <Card>
      <Title>Timezone</Title>
      <Spacer size="xs" />
      <Line />

      <Spacer />
      <Text>Choose the timezone you want Wildmetrics to show you your data.</Text>
      <Spacer size="sm" />
      <div className="flex flex-row shadow-sm rounded-md">
        <DropdownWithSearchBar
          {...{
            isOpen: isDropdownOpen,
            setIsOpen: setIsDropdownOpen,
            optionsName: optionsButtons,
            selectedOption,
            setSelectedOption: onOptionClick,
            classNames: dropdownClassNames,
            classNamesByType,
            onOpen: onDropdownOpen,
          }}
        />
      </div>
    </Card>
  );
}

function DefaultTimeRangeCard() {
  const { reauthenticate } = useCurrentUser();
  const [updateDefaultTimeRange, { loading }] = useMutation(UPDATE_DEFAULT_TIME_RANGE, {
    onCompleted: () => {
      toast.success('Default time range updated');
    },
  });

  const { label: defaultTimeRangeKey } = localDefaultTimeRange;

  const setTimeRange = useSetRecoilState(RecoilTimeRange);
  const [{ label: initialDefaultLabel }, setDefaultTimeRangeLastSession] = useLocalStorage(defaultTimeRangeKey);
  const [dropdownLabel, setDropdownLabel] = useState(initialDefaultLabel || initialGlobalLabel);

  const onOptionClick = async (clickedLabel) => {
    setDropdownLabel(clickedLabel);
    await updateDefaultTimeRange({ variables: { label: clickedLabel } });
    setDefaultTimeRangeLastSession({ label: clickedLabel });

    const [start, end] = getFixedRangeFromDynamicRange(clickedLabel);

    setTimeRange({
      label: clickedLabel,
      timeRange: { start, end },
    });

    reauthenticate();
  };

  const { classNames: dropdownClassNames, classNamesByType } = getDropdownClassNames();

  return (
    <Card>
      <Title>Default time range</Title>
      <Spacer size="xs" />
      <Line />

      <Spacer />
      <Text>
        Choose your default time range for Wildmetrics. Whenever you open Wildmetrics it will show you your dashboard &
        reports with this selected time range.
      </Text>
      <Spacer size="sm" />
      <TimeRangeLabelsDropdown
        withCustomDatePicker={false}
        selectedLabel={dropdownLabel}
        setSelectedLabel={onOptionClick}
        isDisabled={loading}
        classNames={{ dropdown: { container: dropdownClassNames.container || '' } }}
        classNamesByType={classNamesByType}
      />
    </Card>
  );
}

function CurrencyCard() {
  return (
    <Card>
      <Title>Default currency</Title>
      <Spacer size="xs" />
      <Line />

      <Spacer />
      <Text>Choose the currency you want Wildmetrics to show you your data.</Text>
      <Spacer size="sm" />
      <CurrenciesDropdownCell />
    </Card>
  );
}
