import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import getObjectPath from 'lodash.get';
import { routes, NavLink } from '@redwoodjs/router';

import useInfiniteScroll from 'src/customHooks/useInfiniteScroll';
import { useRestartFunnelToDefaultValues, useFunnelData, FUNNEL_PAGE_SOURCES } from 'src/components/Funnel/funnelHooks';
import useTableHeight from 'src/customHooks/useTableHeight';
import withApolloQuery from 'src/lib/HOC/withApolloQuery';
import { getLastTwClassNameValue } from 'src/lib/generic/handlers';

import LazyLoadingList from 'src/components/LazyLoadingList/LazyLoadingList';
import { EmptyComponent } from 'src/components/Generic';
import { transformTimeRangeToDate } from 'src/components/Funnel/FunnelManagementList/listHelpers';
import { subMenuNavMarginTop } from 'src/layouts/AppLayout/AppLayout';

const noop = () => {};

function FunnelsListInSubMenu({ queryParams, data, dataPath, fetchMore, emptyComponentText }) {
  const twMarginTopNum = getLastTwClassNameValue(subMenuNavMarginTop);

  const {
    funnelByIdRetrieved: { funnelId: retrievedFunnelId },
  } = useFunnelData();

  const { resetFunnel: resetFunnelToDefaultValues } = useRestartFunnelToDefaultValues();

  const { tableHeight, mainTableContainerRef } = useTableHeight({ twMarginTopNum });
  const { hasMoreData, dataChunk, fetchMoreData } = useInfiniteScroll({
    scrollLength: queryParams?.variables?.limit,
    incomingData: {
      dataArray: getObjectPath(data, dataPath),
      path: dataPath,
    },
    fetchMore,
    fetchMoreParams: queryParams,
  });

  const { setNextFunnelData } = useUpdateNextFunnelStatesInSteps();

  const getListProps = () => {
    const onRowClick = ({ id: funnelId, name: funnelName, funnelItems, modifiedAt }) => {
      resetFunnelToDefaultValues();
      setNextFunnelData({
        funnelId,
        funnelName,
        funnelItemsFromDb: transformTimeRangeToDate(funnelItems),
        modifiedAt,
      });
    };

    const funnels = dataChunk.map((funnelInfoFromDb) => {
      const { id: funnelId, name } = funnelInfoFromDb;
      const isFunnelAlreadyDisplayed = retrievedFunnelId === funnelId;

      const onClick = !isFunnelAlreadyDisplayed ? () => onRowClick(funnelInfoFromDb) : noop;

      return (
        <button key={funnelId} type="button" onClick={onClick} className="w-full mb-1">
          <NavLink
            to={routes.funnelPageById({ funnelId })}
            activeClassName="activeLink bg-wmxBgDark-100 shadow-lg"
            className="flex justify-start first:mt-0 text-xs link group relative px-4 py-2 text-wmxText-100 no-underline hover:bg-wmxBgDark-100 hover:shadow-lg w-full rounded-sm max-w-xs truncate"
          >
            {name}
          </NavLink>
        </button>
      );
    });

    return {
      name: 'subMenuFunnelList',
      height: tableHeight,
      mainContainerRef: mainTableContainerRef,
      hasMoreData,
      setNextChunk: fetchMoreData,
      data: funnels,
      autoInfiniteScrollOverflow: true,
    };
  };

  // eslint-disable-next-line no-use-before-define
  const listProps = getListProps();

  return listProps?.data.length ? (
    <LazyLoadingList {...listProps} />
  ) : (
    <EmptyComponent text={emptyComponentText} className={{ text: 'text-xs' }} />
  );
}

export default withApolloQuery(FunnelsListInSubMenu);

function useUpdateNextFunnelStatesInSteps() {
  const { setFunnelByIdRetrieved, setFunnelPageSource } = useFunnelData();

  const [nextFunnelData, setNextFunnelData] = useState(null);

  useEffect(() => {
    if (nextFunnelData) {
      setFunnelByIdRetrieved({
        ...nextFunnelData,
      });

      setFunnelPageSource(FUNNEL_PAGE_SOURCES.clickedInSubMenu);
      setNextFunnelData(null);
    }
  }, [nextFunnelData, setNextFunnelData, setFunnelPageSource, setFunnelByIdRetrieved]);

  return {
    setNextFunnelData,
  };
}

FunnelsListInSubMenu.propTypes = {
  dataPath: PropTypes.string,
  fetchMore: PropTypes.func,
  emptyComponentText: PropTypes.string,
  data: PropTypes.shape({
    getFunnelReportsByAccount: PropTypes.array,
  }),
  queryParams: PropTypes.shape({
    fetchPolicy: PropTypes.string,
    variables: PropTypes.shape({
      offset: PropTypes.number,
      limit: PropTypes.number,
      timeRange: PropTypes.shape({
        start: PropTypes.object,
        end: PropTypes.object,
      }),
    }), // Going directly to withApolloQuery
  }),
};
