/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-multi-assign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import { routes, Redirect } from '@redwoodjs/router';

export default function RedirectOldLoginhandlerPage() {
  /*
    Ther was some browser cache problem that keeps sending users to the old login page,
    this handler page force the browser to use the new page
  */
  return <Redirect to={routes.login2()} />;
}
