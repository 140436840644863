import { Fragment } from 'react';

import {
  makeSegmentItemId,
  getIsSegmentBuilderWithElements,
} from 'src/components/IntegrationTopic/integrationsHelpers';

import { setThousandsComma } from 'src/lib/generic/handlers';
import SegmentItem from 'src/components/Funnel/SegmentBuilder/SegmentItem/SegmentItem';
import AddSegmentItemBox from 'src/components/Funnel/SegmentBuilder/AddSegmentItemBox/AddSegmentItemBox';
import PropTypes from 'prop-types';

const previousIsGroup = (idx, segmentItems) => {
  return idx > 0 && segmentItems[idx - 1]?.length > 1;
};

const currentIsGroup = (idx, segmentItems) => {
  return segmentItems[idx]?.length > 1;
};

const isFirst = (idx) => {
  return idx === 0;
};

export const getSegmentItems = ({
  segmentElements,
  timeRange,
  onRemoveElement,
  getTypeOfItem,
  getItemsToQuery,
  onClickItem,
  onDialogClose,
  onDialogOpen,
  isDialogOpen,
  firstItemIsDefined,
  onAndOrButtonsClick,
  itemNameInService,
  withItemsCount,
}) => {
  const segmentItems = [];
  segmentItems[0] = [];
  let groupidx = 0;
  let nodeIdx = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const element of segmentElements) {
    const {
      id: nodeId,
      connection,
      value: { itemName: nodeName, peopleInTimeRange, condition },
    } = element;

    // create new group if is an AND and not the first item of all
    if (connection === 'AND' && nodeIdx > 0) {
      groupidx += 1;
      segmentItems[groupidx] = [];
    }

    const isLast = segmentElements.length - 1 === nodeIdx;

    const segmentBuilderItemsIds = segmentElements[nodeIdx]?.value?.itemsIds;

    const segmentItemProps = {
      segmentId: nodeId,
      name: nodeName,
      peopleInTimeRange,
      nodeIdx,
      customTimeRange: timeRange,
      onRemoveElement,
      itemNameInService,
      funnelItemIds: segmentBuilderItemsIds,
      condition,
      itemsCount: condition === 'contains' ? segmentBuilderItemsIds.length : null,
      withItemsCount,
      isFirstItem: nodeIdx === 0,
      isLast,
      isTheOnlyOne: segmentElements.length === 1,
      typeOfItem: getTypeOfItem(),
      itemsToQuery: getItemsToQuery(nodeIdx),
      onDialogOpen,
      onDialogClose,
      isDialogOpen,
      onClick: onClickItem,
      connection,
    };

    segmentItems[groupidx] = [...segmentItems[groupidx], segmentItemProps];
    nodeIdx += 1;
  }

  const lastIsGroup = segmentItems[segmentItems.length - 1].length > 1;
  return (
    <div className="w-full">
      {segmentItems.map((group, idx) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`key-${idx}`}>
            <div
              style={{ maxWidth: '478px' }}
              className={`ContainerGroup-${idx} m-auto relative ${
                (previousIsGroup(idx, segmentItems) && 'mt-10') || ''
              }`}
            >
              {group.length > 1 && (
                <span
                  className={`GroupBorderLines absolute ${
                    (isFirst(idx) && '-top-10') || 'top-16 mt-2'
                  } -bottom-11 left-0 right-0 border-2 border-dashed border-wmxPrimary-100 rounded-lg z-0`}
                />
              )}
              {group.map((itemProps, itemIdx) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={itemIdx} className="w-full flex flex-col items-center justify-items-center z-10 relative">
                  <SegmentItem
                    {...itemProps}
                    currentIsGroup={currentIsGroup(idx, segmentItems)}
                    firstOfGroup={itemIdx === 0}
                  />
                </div>
              ))}
            </div>
          </Fragment>
        );
      })}
      {firstItemIsDefined && (
        <AndOrButtons disabled={isDialogOpen} onClick={onAndOrButtonsClick} lastIsGroup={lastIsGroup} />
      )}
    </div>
  );
};

const AndOrConnectors = () => {
  return (
    <>
      <div className="absolute m-auto left-0 right-0 flex justify-center z-0">
        <svg width="125" height="43" viewBox="0 0 125 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M62.9297 0V15C62.9297 20.5228 67.4068 25 72.9297 25H114C119.522 25 124 29.4772 124 35V42.5"
            stroke="#3022D3"
          />
          <path
            d="M63 0V14.7059C63 20.2287 58.5228 24.7059 53 24.7059H11C5.47715 24.7059 0.999999 29.183 0.999999 34.7059V42"
            stroke="#3022D3"
          />
        </svg>
      </div>
    </>
  );
};

const AndOrButtons = ({ onClick, lastIsGroup, disabled }) => {
  return (
    <div className={`flex relative ${lastIsGroup && 'mt-10'}`}>
      <AndOrConnectors />
      <div className="AndOrbuttonsWrap pt-10 flex w-52 justify-between z-10 m-auto">
        <AddSegmentItemBox disabled={disabled} name="and" onClick={onClick} />
        <AddSegmentItemBox disabled={disabled} name="or" onClick={onClick} />
      </div>
    </div>
  );
};

AndOrButtons.propTypes = {
  onClick: PropTypes.func,
  lastIsGroup: PropTypes.bool,
  disabled: PropTypes.bool,
};

export function convertSegmentDataToFrontendFormat({ segmentElements }) {
  return segmentElements.map((fetchedElements) => {
    const { itemName, people, itemsIds, connection, condition } = fetchedElements;

    return {
      id: makeSegmentItemId(),
      value: {
        itemName,
        peopleInTimeRange: setThousandsComma(people),
        itemsIds,
        condition,
        itemAccessor: 'tag',
      },
      connection,
    };
  });
}

export const saveSegmentInDb = (newSegmentElements, saveSegmentHandler) => {
  const isSegmentBuilderWithElements = getIsSegmentBuilderWithElements(newSegmentElements);

  if (!isSegmentBuilderWithElements) return null;

  const itemsIdsToSend = newSegmentElements.reduce(
    (accum, { value: { itemsIds, condition, itemName }, connection }) => {
      // eslint-disable-next-line camelcase
      const OR_items = connection === 'OR' ? accum[accum.length - 1] : [];

      const itemToSend = condition === 'select' ? { select: itemsIds.toString() } : { contains: itemName };
      OR_items.push(itemToSend);

      if (connection === 'OR') {
        // eslint-disable-next-line camelcase, no-param-reassign
        accum[accum.length - 1] = OR_items;

        return accum;
      }

      accum.push(OR_items);

      return accum;
    },
    []
  );

  return saveSegmentHandler(itemsIdsToSend);
};
