import PropTypes from 'prop-types';
import classnames from 'classnames';

import TabButton from 'src/components/Generic/TabButton/TabButton';

export default function TabButtons({ buttons, classNames, activeState }) {
  const { container: containerClassNames } = classNames || {};
  const { activeButton, setActiveButton } = activeState;

  const tabsButtons = buttons.map(({ title, onClick, className: buttonClassName }, idx) => {
    const onClickButton = () => {
      if (onClick) onClick();
      setActiveButton(idx);
    };

    const tabButtonClassNames = {
      ...classNames,
      button: buttonClassName,
    };

    return (
      <TabButton
        key={title}
        onClick={onClickButton}
        isActive={activeButton === idx || activeButton === title}
        classNames={tabButtonClassNames}
      >
        {title}
      </TabButton>
    );
  });

  return (
    <div className={classnames('flex flex-row justify-start items-center my-2', containerClassNames)}>
      {tabsButtons}
    </div>
  );
}

TabButtons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
      className: PropTypes.string,
    })
  ).isRequired,
  classNames: PropTypes.shape({
    container: PropTypes.string,
    buttons: PropTypes.string,
    isActive: PropTypes.string,
    isNotACtive: PropTypes.string,
  }),
  activeState: PropTypes.shape({
    setActiveButton: PropTypes.func,
    activeButton: PropTypes.number,
  }),
};

TabButtons.defaultProps = {
  classNames: {},
  activeState: {
    setActiveButton: () => {},
    activeButton: 0,
  },
};
