import { atom, selector } from 'recoil';
import { localStorageEffect } from 'src/lib/generic/recoilLocalStorage';

export const getTwSidebarWidth = (collapsedSidebarState) => (collapsedSidebarState ? 80 : 250); // numbers are values of tailwind w-20 and w-64

export const collapsedSidebar = atom({
  key: 'collapsedSidebar',
  default: false,
  effects_UNSTABLE: [localStorageEffect('collapsed_sidebar')],
});

export const sidebarTwWidth = selector({
  key: 'sidebarTwWidth',
  get: ({ get }) => {
    const collapsedSidebarState = get(collapsedSidebar);
    return getTwSidebarWidth(collapsedSidebarState);
  },
});
