import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// import ReactSplitPane from 'react-split-pane';
import { useRecoilValue, useRecoilState } from 'recoil';

import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';
import { funnelByIdRetrieved as RecoilFunnelByIdRetrieved, FUNNEL_PAGE_SOURCES } from 'src/atoms/funnelAtoms';
import { useFunnelPresentationView } from 'src/components/Funnel/PresentationView/presentationViewHooks';

import GetFunnelByIdCell, { getFunnelByIdProps } from 'src/components/Cells/getFunnelByIdCell/GetFunnelByIdCell';
import FunnelPresentationTable from 'src/components/Funnel/PresentationView/FunnelPresentationTable/FunnelPresentationTable';
import FunnelOverviewKpis from 'src/components/Funnel/PresentationView/FunnelOverviewKpis/FunnelOverviewKpis';
import PeopleDetailsPane from 'src/components/PeopleDetailsPane/PeopleDetailsPane';
import withApolloQuery from 'src/lib/HOC/withApolloQuery';
import './funnelPresentationView.css';
import { Flex } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import MainCardLayout from 'src/layouts/MainCardLayout/MainCardLayout';

function FunnelPresentationView({ funnelId: urlFunnelId, data }) {
  const {
    isRightPaneOpen,
    minorGlobalSquareModified: { globalSquareIndex: minorGlobalSquareIndexModified },
    funnelPageSource,
    dataFromInitialFetch,
    setDataFromInitialFetch,
  } = useFunnelPresentationView();

  const { funnelItemsFromDb = [] } = useRecoilValue(RecoilFunnelByIdRetrieved);

  const { isInitialFetch, setIsInitialFetch } = useSetInitialFetchWhenFunnelIdChanges({ urlFunnelId });
  useSetFunnelRetrievedInfoByUrl({ data });

  const { timeRange } = useRecoilValue(RecoilTimeRange);

  const leftPaneContainerProps = {
    isInitialFetch,
    funnelItemsFromDb,
    funnelId: urlFunnelId,
    dataFromInitialFetch,
    withFunnelItemsFromDbCalculated:
      funnelPageSource === FUNNEL_PAGE_SOURCES.clickedInList ||
      funnelPageSource === FUNNEL_PAGE_SOURCES.clickedInSubMenu,
  };

  const hasInitialDataChanged = minorGlobalSquareIndexModified !== null;
  const shouldGetFunnelByIdBeCalled = urlFunnelId && (isInitialFetch || !hasInitialDataChanged);

  return (
    <FunnelPresentationLayout>
      {shouldGetFunnelByIdBeCalled ? (
        <Cell
          funnelId={urlFunnelId}
          timeRange={timeRange}
          setIsInitialFetch={setIsInitialFetch}
          setDataFromInitialFetch={setDataFromInitialFetch}
          {...leftPaneContainerProps}
        />
      ) : (
        <TableAndKPIWrap {...leftPaneContainerProps} />
      )}
      {(isRightPaneOpen && <PeopleDetailsPane />) || <></>}
    </FunnelPresentationLayout>
  );
}

export default withApolloQuery(FunnelPresentationView);

const FunnelPresentationLayout = ({ children }) => {
  return (
    <div className="FunnelPresentationViewLayout h-full w-full flex flex-col">
      <MainCardLayout>
        <div className="MainContainer pt-16 pb-6 pl-6">
          <Flex items="start" justify="start">
            {children}
          </Flex>
        </div>
      </MainCardLayout>
    </div>
  );
};

FunnelPresentationLayout.propTypes = {
  children: PropTypes.any,
};

FunnelPresentationView.propTypes = {
  funnelId: PropTypes.string,
  data: PropTypes.shape({
    activeCampaignUrl: PropTypes.string,
    getDefaultCurrency: PropTypes.string,
  }),
  queryParams: PropTypes.shape({
    fetchPolicy: PropTypes.string,
    variables: PropTypes.object,
  }),
};

export const funnelItemsFromDbProps = {
  funnelItemsFromDb: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.arrayOf(PropTypes.string), // path of accessors saved in db
        query: PropTypes.shape({
          // stripeProduct, newCustomers, newContacts, newContactsByList, automation, tag
          tag: PropTypes.arrayOf(
            PropTypes.arrayOf(
              PropTypes.shape({
                contains: PropTypes.string,
                select: PropTypes.string,
              })
            )
          ),
        }),
      })
    )
  ),
};

const TableAndKPIWrap = ({ funnelItemsFromDb, dataFromInitialFetch: initialFetchedData, isInitialFetch, funnelId }) => {
  const dataFromInitialFetch = {
    initialFetchedData,
    isInitialFetch,
  };

  const tableProps = {
    funnelItemsFromDb,
    dataFromInitialFetch,
    funnelId,
  };

  return (
    <>
      <FunnelPresentationTable {...tableProps} />
      <FunnelOverviewKpis {...tableProps} />
    </>
  );
};

const Cell = (props) => {
  const renderLoadingComponent = () => <TableAndKPIWrap {...props} />;
  const renderSuccessComponent = () => {
    return <TableAndKPIWrap {...props} />;
  };

  const cellProps = {
    ...props,
    renderLoadingComponent,
    renderSuccessComponent,
  };

  return <GetFunnelByIdCell {...cellProps} />;
};

TableAndKPIWrap.propTypes = {
  ...funnelItemsFromDbProps,
  funnelId: PropTypes.string,
  dataFromInitialFetch: PropTypes.shape({
    ...getFunnelByIdProps,
  }),
  isInitialFetch: PropTypes.bool,
};

function useSetFunnelRetrievedInfoByUrl({ data }) {
  const { setFunnelPageSource } = useFunnelPresentationView();
  const [{ funnelItemsFromDb = [] }, setFunnelByIdRetrieved] = useRecoilState(RecoilFunnelByIdRetrieved);

  useEffect(() => {
    if (data && funnelItemsFromDb.length === 0) {
      const { getSingleFunnelReportByAccount: { id: funnelId, name, funnelItems, modifiedAt } = {} } = data || {};

      setFunnelByIdRetrieved({
        funnelId,
        funnelName: name,
        funnelItemsFromDb: funnelItems,
        modifiedAt,
      });

      setFunnelPageSource(FUNNEL_PAGE_SOURCES.byUrl);
    }
  }, [data, funnelItemsFromDb, setFunnelByIdRetrieved, setFunnelPageSource]);
}

function useSetInitialFetchWhenFunnelIdChanges({ urlFunnelId }) {
  const { funnelPageSource } = useFunnelPresentationView();

  const isFunnelBeingFetchedByUrl = funnelPageSource === FUNNEL_PAGE_SOURCES.byUrl;
  const isFunnelClickedByListWithElements =
    funnelPageSource === FUNNEL_PAGE_SOURCES.clickedInSubMenu || funnelPageSource === FUNNEL_PAGE_SOURCES.clickedInList;

  const initialFetchState = !!urlFunnelId && (!!isFunnelBeingFetchedByUrl || !!isFunnelClickedByListWithElements);
  const [isInitialFetch, setIsInitialFetch] = useState(initialFetchState);

  useEffect(() => {
    if (urlFunnelId) {
      setIsInitialFetch(true);
    }
  }, [urlFunnelId]);

  return {
    isInitialFetch,
    setIsInitialFetch,
  };
}
