import PropTypes from 'prop-types';
import { ButtonOnHoverTooltip } from 'src/components/Generic/RadixTooltip/RadixTooltip';
import { ORDER_COLUMN_DIRECTIONS } from 'wmx-shared-code/reports/emailsOverview';

export default function ButtonHeader({
  renderedHeader,
  headerObj,
  onHeaderClick,
  columnOrderBy,
  columnOrderByDirection,
  style = {},
}) {
  const { orderBy: clickedOrderByColumn, orderByDefaultDirection, tooltipElement } = headerObj;
  const arrowType = columnOrderByDirection === ORDER_COLUMN_DIRECTIONS.desc ? 'down' : 'up';
  const isOrderedByThisColumn = clickedOrderByColumn === columnOrderBy;

  const newOrderByDirection = getNewOrderByDirection({
    defaultOrderByDirection: orderByDefaultDirection,
    columnOrderBy,
    clickedOrderByColumn,
    currOrderByDirection: columnOrderByDirection,
  });

  const showArrow = isOrderedByThisColumn ? 'visible' : 'invisible';
  const icon = <i className={`pl-2 text-white text-xxs fas fa-chevron-${arrowType} ${showArrow}`} />;

  return (
    <ButtonOnHoverTooltip
      iconElement={icon}
      onButtonClick={() => onHeaderClick({ newOrderBy: clickedOrderByColumn, newOrderByDirection })}
      style={style}
      tooltipElement={tooltipElement}
    >
      {renderedHeader}
    </ButtonOnHoverTooltip>
  );
}

ButtonHeader.propTypes = {
  renderedHeader: PropTypes.any,
  headerObj: PropTypes.shape({
    orderBy: PropTypes.string,
    orderByDefaultDirection: PropTypes.string,
    tooltipElement: PropTypes.any,
  }),
  onHeaderClick: PropTypes.func,
  columnOrderBy: PropTypes.string,
  columnOrderByDirection: PropTypes.string,
  style: PropTypes.shape({
    button: PropTypes.object,
  }),
};

function getNewOrderByDirection({
  defaultOrderByDirection,
  columnOrderBy,
  clickedOrderByColumn,
  currOrderByDirection,
}) {
  const secondOptionOrderByDirection =
    defaultOrderByDirection === ORDER_COLUMN_DIRECTIONS.asc
      ? ORDER_COLUMN_DIRECTIONS.desc
      : ORDER_COLUMN_DIRECTIONS.asc;

  const newOrderByDirection =
    columnOrderBy === clickedOrderByColumn && currOrderByDirection === defaultOrderByDirection
      ? secondOptionOrderByDirection
      : defaultOrderByDirection;

  return newOrderByDirection;
}
