import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactFlowProvider } from 'react-flow-renderer';

import { useRecoilValue } from 'recoil';
import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';
import useRightPane from 'src/customHooks/useRightPane';

export default function SegmentBuilderContainer({
  children,
  name,
  closeRightPane,
  onDoneClick,
  renderPeopleCount,
  disableButton,
}) {
  const { isRightPaneOpen } = useRightPane();
  const segmentBuilderRef = useRef();
  // For calculating height of dropdown absolute Div and be scrollable if necessary
  useEffect(() => {
    const mainContainerYPosition = segmentBuilderRef.current.getBoundingClientRect().top;
    const footerHeight = 102;
    const segmentBuilderHeight = window.innerHeight - mainContainerYPosition - footerHeight;
    const someBottomPadding = 8;
    segmentBuilderRef.current.style.height = `${segmentBuilderHeight - someBottomPadding}px`;
  }, [isRightPaneOpen]);

  const { timeRange } = useRecoilValue(RecoilTimeRange);

  const clickOnDoneButton = () => {
    closeRightPane();
    if (onDoneClick) onDoneClick();
  };

  // For avoiding React Flow console warning. The height is being changed in useEffect
  const styleForAvoidingConsoleWarn = { height: '100%' };

  return (
    <ReactFlowProvider>
      <div className="w-full h-full px-8 pt-2">
        <h2 className="w-full mb-4 text-center text-white text-xl">Choose your {name.toLowerCase()}</h2>
        <div className="SegmentBuilderWrap z-10" ref={segmentBuilderRef} style={styleForAvoidingConsoleWarn}>
          {children}
        </div>
        <SegmentBuilderFooter onDoneClick={clickOnDoneButton} disableButton={disableButton}>
          {renderPeopleCount(timeRange)}
        </SegmentBuilderFooter>
      </div>
    </ReactFlowProvider>
  );
}

/* ------------------------------------------------------------------------------------------------
 * Children components
 * -----------------------------------------------------------------------------------------------*/

export function SegmentBuilderFooter({ containerRef, onDoneClick, children, disableButton }) {
  return (
    <div ref={containerRef} className="flex flex-row justify-between py-4 px-4 w-full">
      <div className="flex flex-col">
        <span className="text-white text-3xl font-bold">{children}</span>
        <span className="text-wmxText-200 text-xl">people</span>
      </div>
      <div className="flex flex-col justify-center">
        <button
          type="button"
          disabled={disableButton}
          className="text-wmxText-100 px-8 py-2 bg-wmxPrimary-200 text-lg rounded-lg hover:bg-wmxPrimary-100 disabled:opacity-60"
          onClick={onDoneClick}
        >
          Save
        </button>
      </div>
    </div>
  );
}

/* ------------------------------------------------------------------------------------------------
 * Prop Types
 * -----------------------------------------------------------------------------------------------*/

SegmentBuilderContainer.propTypes = {
  closeRightPane: PropTypes.func,
  onDoneClick: PropTypes.func,
  name: PropTypes.string,
  children: PropTypes.any,
  // eslint-disable-next-line react/no-unused-prop-types
  funnelPartName: PropTypes.string,
  renderPeopleCount: PropTypes.func,
  disableButton: PropTypes.bool,
};

SegmentBuilderFooter.propTypes = {
  onDoneClick: PropTypes.func,
  containerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  disableButton: PropTypes.bool,
};
