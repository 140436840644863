import { atom } from 'recoil';

export const tableRows = atom({
  key: 'tableRows',
  default: null,
});

export const deletedTableRows = atom({
  key: 'deletedTableRows',
  default: [],
});

export const globalDataChunk = atom({
  key: 'globalDataChunk',
  default: [],
});
