/* eslint-disable react/prop-types */
import { GET_LAST_PROVIDERS_SYNC } from 'src/components/VendorOverviewTable/vendorOverviewQueries';
import { useUpdateMetric, metricsKey, useMetricLoading, useIntegrationsContext } from 'src/contexts/Integrations';
import { isBeforeDate, turnDateToUnix, turndDateToISOString } from 'src/lib/generic/handlers';

import { PROVIDERS } from 'wmx-shared-code/accountGlobalVariables';

const METRIC_NAME = metricsKey.lastSyncsByProvider;
export const emptyProviderSyncString = 'None';

// eslint-disable-next-line no-undef
export const QUERY = GET_LAST_PROVIDERS_SYNC;

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'network-only' };
};

export const Loading = () => {
  const FakeLoadingComponent = useMetricLoading({
    metric: METRIC_NAME,
    renderComponent: () => <span />,
    shouldUpdateContextValue: true,
  });

  return FakeLoadingComponent;
};

export const Empty = () => <span />;

export const Failure = ({ error }) => <span />;

export const Success = ({ getLastProvidersSync: lastProvidersSync }) => {
  const { settingsByProvider } = useIntegrationsContext();
  const providers = Object.keys(lastProvidersSync);

  const syncsFormattedValues = providers.reduce((accum, providerKey) => {
    const isSupportedProvider = Object.values(PROVIDERS).includes(providerKey);

    if (!isSupportedProvider) return accum;

    const { lastSuccessfulAPISync: apiSync, lastSuccessfulWebhookSync: webhookSync } =
      lastProvidersSync[providerKey] || {};
    const datapointsOverview = settingsByProvider[providerKey]?.datapointsOverview;

    const lastSuccessfulWebhookSync = getLastWebhookWhenDatapointUpdateWasMoreRecent({
      lastSuccessfulWebhookSync: webhookSync,
      datapointsOverview,
    });

    const syncsByProvider = {
      lastSuccessfulAPISync: apiSync || emptyProviderSyncString,
      lastSuccessfulWebhookSync: lastSuccessfulWebhookSync || emptyProviderSyncString,
    };

    // eslint-disable-next-line no-param-reassign
    accum[providerKey] = syncsByProvider;

    return accum;
  }, {});

  useUpdateMetric({
    metric: METRIC_NAME,
    newValue: { ...syncsFormattedValues },
    shouldUpdateContextValue: true,
  });

  return <span />;
};

/**
 * Use case covered here: when Stripe updates Contact datapoint and AC webhook was before last update.
 */
function getLastWebhookWhenDatapointUpdateWasMoreRecent({ lastSuccessfulWebhookSync, datapointsOverview }) {
  const lastUpdatedRowsInUnix = datapointsOverview.map(({ lastUpdated = null }) =>
    lastUpdated ? turnDateToUnix(lastUpdated) : null
  );

  const areAllNullValues = lastUpdatedRowsInUnix.every((row) => row === null);
  if (!lastUpdatedRowsInUnix.length || areAllNullValues) return lastSuccessfulWebhookSync;

  const mostRecentUpdatedDatapoint = Math.max(...lastUpdatedRowsInUnix);
  const isLastWebhookBeforeLastDatapointUpdate = isBeforeDate(lastSuccessfulWebhookSync, mostRecentUpdatedDatapoint);

  if (isLastWebhookBeforeLastDatapointUpdate) {
    const lastDatapointUpdateAsWebhookSync = turndDateToISOString(mostRecentUpdatedDatapoint);

    return lastDatapointUpdateAsWebhookSync;
  }

  return lastSuccessfulWebhookSync;
}
