import { useState } from 'react';
import PropTypes from 'prop-types';

import { Root, Trigger, Content, Item } from '@radix-ui/react-dropdown-menu';

import { useIntegrationsContext } from 'src/contexts/Integrations';

import Button from 'src/components/Generic/Button/Button';
import { getTomorrowInUtc, getPeriodStart, now } from 'src/lib/handleTimezone';

import { ALL_LABEL_START } from 'wmx-shared-code/timeRange';
import { DO_NOT_CLOSE_PANE_CLASSNAME } from 'src/customHooks/useRightPane';

export const RESYNC_DROPDOWN_CLASSNAME_IDENTIFIER = 'Resync-dropdown';

export default function ResyncIntegrationButton({ text = 'Resync', datapointName }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { settingsByClickedProvider, isAnyMutationLoading } = useIntegrationsContext();

  const { resyncIntegration, datapointResyncNames } = settingsByClickedProvider;

  const timeRangesConfig = [
    { name: 'Last 24hs', periodStart: 'day', substractNumber: 1, periodSubstract: 'd' },
    { name: 'Last 3d', periodStart: 'day', substractNumber: 3, periodSubstract: 'd' },
    { name: 'Last 7d', periodStart: 'day', substractNumber: 7, periodSubstract: 'd' },
    { name: 'All', labelStart: ALL_LABEL_START },
  ];

  const dropdownItems = timeRangesConfig.map(({ name, periodStart, substractNumber, periodSubstract, labelStart }) => {
    return {
      name,
      startTimeRange: labelStart || getPeriodStart({ date: now(), periodStart, substractNumber, periodSubstract }),
    };
  });

  const onDropdownOpenChange = () => setIsDropdownOpen(!isDropdownOpen);

  const itemsComponents = dropdownItems.map((timeRangeObj) => {
    const { name, startTimeRange } = timeRangeObj;

    const onSelect = () => {
      return resyncIntegration({
        variables: {
          timeRange: { start: startTimeRange, end: getTomorrowInUtc() },
          datapoints: datapointName ? [datapointName] : datapointResyncNames,
        },
      });
    };

    return (
      <Item
        key={name}
        className={`${DO_NOT_CLOSE_PANE_CLASSNAME} rounded text-white text-sm cursor-pointer hover:bg-wmxBgDark-300 p-2`}
        onSelect={onSelect}
      >
        {name}
      </Item>
    );
  });

  return (
    <Root onOpenChange={onDropdownOpenChange}>
      <Trigger asChild>
        <Button disabled={isAnyMutationLoading} variant="primary">
          {text}
        </Button>
      </Trigger>
      <Content className="bg-wmxBgDark-100 shadow-2xl rounded w-24">{itemsComponents}</Content>
    </Root>
  );
}

ResyncIntegrationButton.propTypes = {
  text: PropTypes.string,
  datapointName: PropTypes.string,
};
