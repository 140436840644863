import PropTypes from 'prop-types';

import withApolloQuery from 'src/lib/HOC/withApolloQuery';
import { Divider } from 'src/components/Generic';
import * as WmxTable from 'src/components/Generic/Tables/WmxTable/WmxTable';
import { useUpdateMetric, metricsKey, useIntegrationsContext } from 'src/contexts/Integrations';
import useVendorOverviewTableProps from './useVendorOverviewTableProps';

import { Flex } from '../Generic/LayoutUtils/LayoutUtils';

export function VendorOverviewTable({ data = {}, queryName }) {
  const { currentProviderShown, settingsByClickedProvider } = useIntegrationsContext();
  const dataChunk = data?.[queryName] || data?.[currentProviderShown] || [];

  useUpdateMetric({
    metric: metricsKey.datapointsOverviewTable,
    newValue: { [currentProviderShown]: dataChunk },
    shouldUpdateContextValue: true,
  });

  const { datapointsOverview = [] } = settingsByClickedProvider;
  const tableProps = useVendorOverviewTableProps({ data: datapointsOverview });

  return (
    <WmxTable.TableProvider tableProps={tableProps}>
      <Flex justify="between" className="mt-8">
        <span className="text-sm text-wmxText-200 px-2">Datapoints overview</span>
        <WmxTable.TableSettings />
      </Flex>
      <Divider />
      <WmxTable.Table />
    </WmxTable.TableProvider>
  );
}

export default withApolloQuery(VendorOverviewTable);

VendorOverviewTable.propTypes = {
  data: PropTypes.shape({ datapointsOverviewByCount: PropTypes.array }),
  queryName: PropTypes.string,
};
