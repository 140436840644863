import PropTypes from 'prop-types';
import React from 'react';

import { setThousandsComma } from 'wmx-shared-code/currency/currency';

import { percentageChange } from 'src/lib/chargesTransformations/chargesTransformations';
import useCurrentUser from 'src/customHooks/useCurrentUser';
import { useDashboard } from 'src/contexts/Dashboard';
import NewCustomerMetric from '../NewCustomerMetric/NewCustomerMetric';
import MetricSkeleton from '../Generic/Skeletons/MetricSkeleton/MetricSkeleton';

// eslint-disable-next-line no-undef
export const QUERY = gql`
  query DASHBOARD_CUSTOMERS($timeRange: TimeRangeInput) {
    newCustomersMetrics(timeRange: $timeRange) {
      partialCustomers
      partialPreviousNewCustomers
    }
  }
`;

export const beforeQuery = ({ timeRange }) => {
  return { variables: { timeRange }, fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-and-network' };
};
export const Loading = (props) => {
  const { useMetricLoading } = useDashboard();
  const result = useMetricLoading({
    metric: 'newCustomersMetrics',
    getValues: useNewCustomer,
    // eslint-disable-next-line react/prop-types
    renderComponent: ({ value, prev, prevValue }) => (
      <NewCustomerMetric value={value} change={prev} prevValue={prevValue} {...props} />
    ),
    LoadingComponent: () => <MetricSkeleton foregroundColor="#1C2631" backgroundColor="#304254" />,
  });
  return result;
};

export const Empty = () => <div>Empty</div>;

export const Failure = ({ error }) => <div style={{ color: 'red' }}>{error.message}</div>;

Failure.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.any,
  }),
};

export const Success = ({ newCustomersMetrics, ...props }) => {
  const { useUpdateMetric } = useDashboard();
  useUpdateMetric({ metric: 'newCustomersMetrics', value: newCustomersMetrics });
  const { value, prev, prevValue } = useNewCustomer({ newCustomersMetrics });

  return <NewCustomerMetric value={value} change={prev} prevValue={prevValue} {...props} />;
};

Success.propTypes = {
  newCustomersMetrics: PropTypes.shape({
    partialCustomers: PropTypes.any,
    partialPreviousNewCustomers: PropTypes.any,
  }),
};

function useNewCustomer({ newCustomersMetrics }) {
  const {
    hasTagsCustomerSegment: isSegmentInDatabaseCreated,
    stripeIntegration: { isReady: isUserWithStripe },
  } = useCurrentUser();
  if (!newCustomersMetrics) return {};
  const { partialCustomers, partialPreviousNewCustomers } = newCustomersMetrics;
  const isAcUserWithoutSegment = !isSegmentInDatabaseCreated && !isUserWithStripe;

  const value = isAcUserWithoutSegment ? '-' : setThousandsComma(partialCustomers);
  const prev = isAcUserWithoutSegment ? '' : percentageChange(partialPreviousNewCustomers, partialCustomers);
  const prevValue = isAcUserWithoutSegment ? '-' : setThousandsComma(partialPreviousNewCustomers);

  return { value, prev, prevValue };
}
