/* eslint-disable react/prop-types */
import { GET_LAST_DAILY_RESYNC } from 'src/components/VendorOverviewTable/vendorOverviewQueries';
import { useUpdateMetric, metricsKey, useMetricLoading } from 'src/contexts/Integrations';

import { PROVIDERS } from 'wmx-shared-code/accountGlobalVariables';

const METRIC_NAME = metricsKey.lastDailyResync;

// eslint-disable-next-line no-undef
export const QUERY = GET_LAST_DAILY_RESYNC;

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'network-only' };
};

export const Loading = () => {
  const FakeLoadingComponent = useMetricLoading({
    metric: METRIC_NAME,
    renderComponent: () => <span />,
    shouldUpdateContextValue: true,
  });

  return FakeLoadingComponent;
};

export const Empty = () => {
  useErrorMetric();

  return <span />;
};

export const Failure = () => {
  useErrorMetric();

  return <span />;
};

export const Success = ({ getLastDailyResync: lastDailyResync }) => {
  const providers = Object.keys(lastDailyResync);

  useSetMetric({ lastDailyResync, providers });

  return <span />;
};

function useSetMetric({ lastDailyResync, providers }) {
  const resyncFormattedValues = providers.reduce((accum, providerKey) => {
    const isSupportedProvider = Object.values(PROVIDERS).includes(providerKey);

    if (!isSupportedProvider) return accum;

    const { wasDailyResyncSuccessful, failedDatapoints = [] } = lastDailyResync[providerKey] || {};

    const dailyResyncByProvider = {
      wasDailyResyncSuccessful,
      failedDatapointsInLastDailyResync: failedDatapoints || [],
    };

    // eslint-disable-next-line no-param-reassign
    accum[providerKey] = dailyResyncByProvider;

    return accum;
  }, {});

  useUpdateMetric({
    metric: METRIC_NAME,
    newValue: { ...resyncFormattedValues },
    shouldUpdateContextValue: true,
  });
}

function useErrorMetric() {
  const providers = Object.keys(PROVIDERS);
  const lastDailyResync = providers.reduce((accum, providerKey) => {
    // eslint-disable-next-line no-param-reassign
    accum[providerKey] = { wasDailyResyncSuccessful: false };

    return accum;
  }, {});

  useSetMetric({ lastDailyResync, providers });
}
