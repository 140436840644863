import { useEffect } from 'react';

export default function useMouseOverLeave({ containerRef, actionOver, actionLeave, conditionHandler = () => true }) {
  useEffect(() => {
    const containerReactElem = containerRef.current;

    const handleMouseHover = (event) => {
      const condition = conditionHandler(event) || false;

      if (condition) {
        actionOver();
      }
    };

    const handleMouseLeave = (event) => {
      const condition = conditionHandler(event) || false;

      if (condition) {
        actionLeave();
      }
    };
    // add when mounted
    containerRef.current.addEventListener('mouseenter', handleMouseHover);
    // add when mounted
    containerRef.current.addEventListener('mouseleave', handleMouseLeave);
    // return function to be called when unmounted
    return () => {
      containerReactElem.removeEventListener('mouseenter', handleMouseHover);
      containerReactElem.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [actionLeave, actionOver, conditionHandler, containerRef]);
}
