import PropTypes from 'prop-types';
import { useMemo } from 'react';

const CircularProgressBar = ({ percent = 0, radius = 10, children }) => {
  const circumference = useMemo(() => {
    return radius * 2 * Math.PI;
  }, [radius]);
  return (
    <div
      x-data="scrollProgress"
      className="relative inline-flex items-center justify-center overflow-hidden rounded-full"
    >
      <svg
        style={{
          height: radius * 2,
          width: radius * 2,
        }}
      >
        <circle
          className="text-gray-300"
          strokeWidth="5"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={radius}
          cy={radius}
        />
        <circle
          className="text-blue-600"
          strokeWidth="5"
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (percent / 100) * circumference}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          transform={`rotate(-50 ${radius} ${radius})`}
          r={radius}
          cx={radius}
          cy={radius}
        />
      </svg>
      <span className="absolute">{children}</span>
    </div>
  );
};

CircularProgressBar.propTypes = {
  children: PropTypes.any,
  percent: PropTypes.number,
  radius: PropTypes.number,
};

export default CircularProgressBar;
