import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, FormError, FieldError, Label, DatetimeLocalField, SelectField } from '@redwoodjs/forms';
import { routes, navigate } from '@redwoodjs/router';
import { useMutation } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/toast';

import { Button } from 'src/components/Generic';

import {
  ACCOUNT_TYPES,
  ACCOUNT_STATUSES_BY_TYPE,
  INTEGRATION_STATUSES,
  INTEGRATION_DATAPHASES,
  INTEGRATION_DATAPHASES_LABELS,
} from 'wmx-shared-code/accountGlobalVariables';

import usePressOutsideContainer from 'src/customHooks/usePressOutsideContainer';

import {
  Card,
  Flex,
  Spacer,
  HSpacer,
  Text,
  Line,
  SelectWithStyle,
} from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { triggerTrackEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';
import { DELETE_ACCOUNT_MUTATION, REST_INTEGRATION_MUTATION } from 'src/components/Account/Account/accountMutations';
import { formCSS } from 'src/lib/generic/commonClasses';

import './accountForm.css';
import { useLoggedAs } from 'src/lib/LoggedAs/useLoggedAs';
import { ScaleLoader } from 'react-spinners';

const modFormCSS = {
  ...formCSS,
  label: 'block mt-0 text-white text-sm',
  labelError: 'block mt-0 text-white text-sm',
};

// eslint-disable-next-line consistent-return
const formatDatetime = (value) => {
  if (value) {
    return value.replace(/:\d{2}\.\d{3}\w/, '');
  }
};

const AccountForm = ({ onSave, account, error, loading }) => {
  const deleteButtonRef = useRef(null);
  const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);
  const [localType, setLocalType] = useState(account.type);
  const { login: onLogginAs, realAccountId, accountId: loggedAsAccountId, loading: loadingLoggedAs } = useLoggedAs();
  const accountEmail = account?.adminUser?.email;
  const [restTriggered, setRestTriggered] = useState(false);

  const [deleteAccount] = useMutation(DELETE_ACCOUNT_MUTATION, {
    onCompleted: () => {
      toast.success('Deleting account');
    },
    // This refetches the query on the list page. Read more about other ways to
    // update the cache over here:
    // https://www.apollographql.com/docs/react/data/mutations/#making-all-other-cache-updates
    // refetchQueries: [{ query: QUERY }],
    // awaitRefetchQueries: true,
  });

  const [triggerRestIntegration, { loading: triggerRestIntegrationLoading, data: triggerRestIntegrationData }] =
    useMutation(REST_INTEGRATION_MUTATION, {
      onCompleted: () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        toast.success(`Rest importation triggered...`);
      },
      onError: () => {
        toast.error(`Something fail, retry...`);
      },
    });

  const onDeleteClick = async (accountId) => {
    // eslint-disable-next-line no-restricted-globals
    await deleteAccount({ variables: { accountIdToDelete: accountId, disconnectAuth0: false } });
    navigate(routes.accounts());
  };

  const onRestClick = (accountId) => {
    triggerRestIntegration({ variables: { accountId, turnToPro: false } });
    setRestTriggered(true);
  };

  const sendUpgradeTrackingEvent = (formData) => {
    if (localType === account.type) return;

    const payload = {
      ...formData,
      upgradedTo: localType,
      previousPlan: account.type,
    };
    triggerTrackEvent({ eventName: SEGMENT_TRACK_EVENTS_NAMES.upgradedPlan, payload });
  };

  const onSubmit = (data) => {
    sendUpgradeTrackingEvent(data);
    onSave(data, account?.id, accountEmail);
  };

  const onTypeChange = (e) => {
    setLocalType(e.target.value);
  };

  const deleteBtnText = isDeleteButtonClicked ? 'Confirm Delete' : 'Delete';
  const deleteBtnOnClick = isDeleteButtonClicked
    ? () => onDeleteClick(account.id)
    : () => setIsDeleteButtonClicked(true);

  usePressOutsideContainer({
    closeContainerHandler: () => setIsDeleteButtonClicked(false),
    containerRef: deleteButtonRef,
  });

  const isIntegration = !!account?.integration;

  return (
    <Card>
      <Form onSubmit={onSubmit} error={error}>
        <FormError
          error={error}
          wrapperClassName="rw-form-error-wrapper"
          titleClassName="rw-form-error-title"
          listClassName="rw-form-error-list"
        />
        <Flex>
          <Flex col items="start">
            <Label name="adminUserId" className={modFormCSS.label} errorClassName={formCSS.labelError}>
              User
            </Label>
            <Text>{accountEmail}</Text>
          </Flex>
          <HSpacer size="md" />
          <Flex col items="start">
            <Label name="createdAt" className={modFormCSS.label} errorClassName={formCSS.labelError}>
              Created at
            </Label>
            <Text>{formatDatetime(account?.createdAt)}</Text>
          </Flex>
          <HSpacer size="md" />
          <Flex col items="start">
            <Label name="adminUserId" className={modFormCSS.label} errorClassName={formCSS.labelError}>
              Admin user id
            </Label>
            <Text>{account?.adminUserId}</Text>
          </Flex>
          <HSpacer size="md" />
          <Flex col items="start">
            <Label name="dataPhase" className={modFormCSS.label} errorClassName={formCSS.labelError}>
              AC Data
            </Label>
            <Text>
              {isIntegration ? INTEGRATION_DATAPHASES_LABELS[account?.integration?.dataPhase] : 'No integration found'}
            </Text>
          </Flex>
        </Flex>
        <Spacer />
        <Line />
        <Spacer />

        <Label name="type" className={formCSS.label} errorClassName={formCSS.labelError}>
          Type
        </Label>

        <SelectWithStyle>
          <SelectField
            name="type"
            validation={{ required: true }}
            defaultValue={account.type}
            className={formCSS.select}
            errorClassName={formCSS.inputError}
            onChange={(e) => onTypeChange(e)}
          >
            {Object.values(ACCOUNT_TYPES).map((option) => (
              <option>{option}</option>
            ))}
          </SelectField>
        </SelectWithStyle>

        <FieldError name="type" className="rw-field-error" />

        <Label name="status" className={formCSS.label} errorClassName={formCSS.labelError}>
          Status
        </Label>

        <SelectWithStyle>
          <SelectField
            name="status"
            validation={{ required: true }}
            defaultValue={account.status}
            className={formCSS.select}
            errorClassName={formCSS.inputError}
          >
            {Object.values(ACCOUNT_STATUSES_BY_TYPE[localType]).map((option) => (
              <option value={option.dbValue}>{option.label}</option>
            ))}
          </SelectField>
        </SelectWithStyle>

        <FieldError name="status" className="rw-field-error" />

        {localType === ACCOUNT_TYPES.trial && (
          <>
            <Label name="trialEnd" className={formCSS.label} errorClassName={formCSS.labelError}>
              Trial end
            </Label>
            <DatetimeLocalField
              name="trialEnd"
              defaultValue={formatDatetime(account?.trialEnd)}
              className={formCSS.input}
              errorClassName={formCSS.inputError}
              validation={{ required: false }}
            />

            <FieldError name="trialEnd" className="rw-field-error" />
          </>
        )}

        <Spacer size="md" />

        <Flex justify="between">
          <Button disabled={loading} type="submit">
            Save
          </Button>
          <HSpacer />
          <Flex justify="end">
            {loggedAsAccountId !== account.id && (
              <>
                <Button
                  disabled={loadingLoggedAs}
                  onClick={() => onLogginAs({ loggedAsAccountId: account.id, realAccountId })}
                >
                  {(loadingLoggedAs && <ScaleLoader size={2} height={5} width={2} color="white" />) || 'Log in'}
                </Button>
                <HSpacer />
              </>
            )}
            <Button
              type="button"
              variant="danger"
              title={`Delete account ${account.id}`}
              onClick={deleteBtnOnClick}
              buttonRef={deleteButtonRef}
            >
              {deleteBtnText}
            </Button>

            {localType === ACCOUNT_TYPES.pro &&
              account.integration?.status === INTEGRATION_STATUSES.ready &&
              account.integration?.dataPhase === INTEGRATION_DATAPHASES.firstPhaseDone &&
              !restTriggered && (
                <>
                  <HSpacer />
                  <Button
                    disabled={triggerRestIntegrationData}
                    type="button"
                    title="Import rest"
                    onClick={() => onRestClick(account.id)}
                  >
                    {(triggerRestIntegrationLoading && <ScaleLoader size={2} height={5} width={2} color="white" />) ||
                      'Import Rest'}
                  </Button>
                </>
              )}
            {account.integration?.dataPhase === INTEGRATION_DATAPHASES.importingRest ||
              (restTriggered && (
                <>
                  <HSpacer />
                  <Button disabled type="button" title="Importing rest">
                    Importing rest...
                  </Button>
                </>
              ))}
          </Flex>
        </Flex>
      </Form>
    </Card>
  );
};

AccountForm.propTypes = {
  account: PropTypes.shape({
    adminUser: PropTypes.shape({
      email: PropTypes.any,
    }),
    adminUserId: PropTypes.any,
    createdAt: PropTypes.any,
    customerId: PropTypes.any,
    id: PropTypes.any,
    integration: PropTypes.shape({
      dataPhase: PropTypes.any,
      status: PropTypes.any,
    }),
    modifiedAt: PropTypes.any,
    status: PropTypes.any,
    trialEnd: PropTypes.any,
    trialStart: PropTypes.any,
    type: PropTypes.any,
  }),
  error: PropTypes.any,
  loading: PropTypes.any,
  onSave: PropTypes.func,
};

export default AccountForm;
