import PropTypes from 'prop-types';

export default function TableLink({ children, link, classnames }) {
  return (
    <a
      className={`${classnames || ''} underline mb-2 text-xs text-wmxText-200 my-1`}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
}

TableLink.propTypes = {
  children: PropTypes.any,
  link: PropTypes.string,
  classnames: PropTypes.string,
};
