import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

export default function RowSkeleton({
  height,
  width,
  backgroundColor,
  foregroundColor,
  firstRowClassName: firstRowClassNameParam,
}) {
  const firstRowClassName = firstRowClassNameParam || 'first:mt-2';

  return (
    <div className={`my-1 ${firstRowClassName}`}>
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <rect x="0" y="0" rx="8" ry="8" width="100%" height="100%" />
      </ContentLoader>
    </div>
  );
}

RowSkeleton.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  backgroundColor: PropTypes.string,
  foregroundColor: PropTypes.string,
  firstRowClassName: PropTypes.string,
};

export function RowSkeletons(props) {
  const { rowsAmount, className, styles = {} } = props;

  const rows = [];

  for (let i = 0; i < rowsAmount; i += 1) {
    rows.push(<RowSkeleton key={i} {...props} />);
  }

  const paddingTop = styles?.paddingTop || 'pt-12';
  const defaultClassName = `flex flex-col px-4 ${paddingTop}`;
  const divClassName = className || defaultClassName;

  // resembles our Tables paddings
  return <div className={divClassName}>{rows}</div>;
}

RowSkeletons.propTypes = {
  rowsAmount: PropTypes.number,
  className: PropTypes.string,
  styles: PropTypes.shape({
    paddingTop: PropTypes.string,
  }),
};
