import React from 'react';
import PropTypes from 'prop-types';

import { useDashboard } from 'src/contexts/Dashboard';
import useCurrentUser from 'src/customHooks/useCurrentUser';

import MetricSkeleton from 'src/components/Generic/Skeletons/MetricSkeleton/MetricSkeleton';
import RevenuePerLeadMetric from 'src/components/RevenuePerLeadMetric/RevenuePerLeadMetric';

import { getAmountWithCurrencySymbol } from 'src/lib/generic/currency';
import { getRevenuePerSubscriber, percentageChange } from 'src/lib/chargesTransformations/chargesTransformations';

// eslint-disable-next-line no-undef
export const QUERY = gql`
  query DASHBOARD_REVENUE_PER_LEAD($timeRange: TimeRangeInput) {
    revenuePerLeadMetrics(timeRange: $timeRange) {
      partialRevenue
      revenuePrevious
      partialSubscribers
      previousSubscribers
    }
  }
`;

export const beforeQuery = ({ timeRange }) => {
  return { variables: { timeRange }, fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-and-network' };
};

export const Loading = () => {
  const { useMetricLoading } = useDashboard();
  const result = useMetricLoading({
    metric: 'revenuePerLeadMetrics',
    getValues: useRevenue,
    // eslint-disable-next-line react/prop-types
    renderComponent: ({ value, prev, prevValue }) => (
      <RevenuePerLeadMetric value={value} change={prev} prevValue={prevValue} />
    ),
    LoadingComponent: () => <MetricSkeleton foregroundColor="#1C2631" backgroundColor="#304254" />,
  });
  return result;
};

export const Empty = () => <div>Empty</div>;

export const Failure = ({ error }) => <div style={{ color: 'red' }}>Error: {error.message}</div>;

Failure.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.any,
  }),
};

export const Success = ({ revenuePerLeadMetrics }) => {
  const { value, prev, prevValue } = useRevenue({ revenuePerLeadMetrics });
  const { useUpdateMetric } = useDashboard();
  useUpdateMetric({ metric: 'revenuePerLeadMetrics', value: revenuePerLeadMetrics });
  return <RevenuePerLeadMetric value={value} change={prev} prevValue={prevValue} />;
};

Success.propTypes = {
  revenuePerLeadMetrics: PropTypes.any,
};

function useRevenue({ revenuePerLeadMetrics }) {
  const {
    defaultCurrency: { threeLetters: defaultCurrencyThreeLetters },
  } = useCurrentUser();

  if (!revenuePerLeadMetrics) return {};

  const { partialRevenue, partialSubscribers, revenuePrevious, previousSubscribers } = revenuePerLeadMetrics;

  return {
    value: getAmountWithCurrencySymbol({
      currency: defaultCurrencyThreeLetters,
      amount: getRevenuePerSubscriber(partialRevenue / 100, partialSubscribers),
    }),
    prev: percentageChange(
      getRevenuePerSubscriber(revenuePrevious / 100, previousSubscribers),
      getRevenuePerSubscriber(partialRevenue / 100, partialSubscribers)
    ),
    prevValue: getAmountWithCurrencySymbol({
      currency: defaultCurrencyThreeLetters,
      amount: getRevenuePerSubscriber(partialRevenue / 100, previousSubscribers),
    }),
  };
}
