import PropTypes from 'prop-types';

export default function EmptyComponent({ text, children, className = {} }) {
  const textToShow = text || 'No data to show. Increase time period.';
  return (
    <div className={`flex items-center justify-center w-full h-full ${className?.container || ''}`}>
      {children || <h2 className={`${className?.text || 'text-wmxText-100'}`}>{textToShow}</h2>}
    </div>
  );
}

EmptyComponent.propTypes = {
  text: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.shape({
    container: PropTypes.string,
    text: PropTypes.string,
  }),
};
