import { useMemo } from 'react';
import { routes, NavLink, useMatch, useLocation, useParams } from '@redwoodjs/router';
import PropTypes from 'prop-types';
import './SubmenuLink.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { isEmpty } from 'lodash';

const ACTIVE_CLASSES = 'SubmenuLink SubmenuLink-active bg-wmxHighlightDark-100 shadow-lg';

export default function SubmenuLink({ page, children, parentMatch, params = {}, disabled, onClick }) {
  const { pathname } = useLocation();
  const currentParams = useParams();
  const matchInfo = useMatch(routes[page]()).match;

  const isActive = useMemo(() => {
    if (parentMatch) return pathname.indexOf(parentMatch) > -1;
    if (!isEmpty(params)) return currentParams === params;
    return matchInfo;
  }, [currentParams, matchInfo, params, parentMatch, pathname]);

  const disabledClasses = disabled ? ' cursor-not-allowed	pointer-events-none opacity-30' : '';

  return (
    <NavLink
      to={routes[page](params)}
      active={isActive?.toString()}
      activeClassName={`${ACTIVE_CLASSES}`}
      className={`SubmenuLink text-sm link group relative pl-4 py-2 align-middle text-wmxText-100 no-underline hover:bg-wmxHighlightDark-100 hover:shadow-lg flex items-center rounded-sm mt-2  w-full ${
        isActive && ACTIVE_CLASSES
      } ${disabled ? disabledClasses : ''}`}
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
}

SubmenuLink.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  page: PropTypes.string,
  params: PropTypes.object,
  parentMatch: PropTypes.string, // string with a matching parent path to set as active, for example "/settings/user/", prefered over nestedPages
  onClick: PropTypes.func,
};
