import { Text, Spacer, HSpacer, Title } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { SideBarLayoutContainer, SidebarProvider } from 'src/layouts/SidebarLayout/SidebarLayout';
import useAccount from 'src/customHooks/useAccount';
import { Button } from 'src/components/Generic';

export default function NotFoundPage() {
  const { currentEmail, help } = useAccount();

  function getHelp() {
    help({ email: currentEmail });
  }

  return (
    <div className="flex h-screen w-screen justify-center items-center bg-wmxBgDark-400">
      <SidebarProvider>
        <SideBarLayoutContainer />
      </SidebarProvider>

      <div className="flex bg-wmxBgDark-400 h-full w-full rounded-md justify-center items-center p-6">
        <div className="flex flex-col bg-wmxBgDark-200 h-full w-full rounded-md justify-center items-center">
          <Title lg>Pardon the error.</Title>
          <Spacer size="md" />
          <Text>We couldn't quite find the page you're looking for.</Text>
          <Spacer size="md" />
          <div className="flex">
            <Button
              variant="transparent"
              onClick={() => {
                window.location.reload();
              }}
            >
              REFRESH
            </Button>
            <HSpacer size="xs" />
            <Button onClick={getHelp}>MESSAGE US</Button>
          </div>
          <Spacer size="lg" />
          <Spacer size="lg" />
          <Text sm>Status Code: 404</Text>
        </div>
      </div>
    </div>
  );
}
