import { navigate, routes } from '@redwoodjs/router';
import NewWildMailIntegration from 'src/components/IntegrationTopic/NewWildMailIntegration/NewWildMailIntegration';

const onSuccess = () => {
  navigate(routes.integrations());
};

const NewWildMailIntegrationPage = () => {
  return <NewWildMailIntegration onSuccess={onSuccess} />;
};

export default NewWildMailIntegrationPage;
