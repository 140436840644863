import PropTypes from 'prop-types';

import { Dropdown } from 'src/components/Generic';
import { dropdownPropTypes } from 'src/components/Generic/Dropdown/Dropdown';

export default function LabelDropdown({ label, dropdownProps, classNames }) {
  const {
    bgColor = '',
    containerPadding = 'pl-2',
    border = 'shadow-sm rounded-md',
    labelPadding = 'px-2',
    textColor = 'text-wmxText-200',
    textSize = 'text-xs',
  } = classNames;

  const addToDropdownProps = { ...dropdownProps };
  addToDropdownProps.classNames.buttonTrigger = `${addToDropdownProps.classNames?.buttonTrigger} rounded-l-none`;

  return (
    <div className={`flex flex-row ${bgColor} ${containerPadding} ${border}`}>
      <div className={`flex items-center ${labelPadding}`}>
        <span className={`${textColor} ${textSize}`}>{label}</span>
      </div>
      <Dropdown {...dropdownProps} />
    </div>
  );
}

LabelDropdown.propTypes = {
  dropdownProps: PropTypes.shape({
    ...dropdownPropTypes,
  }),
  label: PropTypes.string,
  classNames: PropTypes.shape({
    bgColor: PropTypes.string,
    containerPadding: PropTypes.string,
    textSize: PropTypes.string,
    textColor: PropTypes.string,
    border: PropTypes.string,
    labelPadding: PropTypes.string,
  }),
};

LabelDropdown.defaultProps = {
  classNames: {
    container: '',
    label: '',
  },
};
