import { useAuth } from '@redwoodjs/auth';

import { Button } from 'src/components/Generic';

import { getStripeState } from 'src/components/IntegrationTopic/integrationsHelpers';
import { INTEGRATION_ORIGINS, REDIRECTS_STRIPE } from 'wmx-shared-code/accountGlobalVariables';

const NewStripeIntegration = () => {
  const { currentUser } = useAuth();
  const { accountId } = currentUser;

  const onButtonClick = () => {
    window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${
      process.env.REDWOOD_ENV_STRIPE_CLIENT_ID
    }&scope=read_only&state=${getStripeState({ accountId, origin: INTEGRATION_ORIGINS.integrations })}&redirect_uri=${
      REDIRECTS_STRIPE[process.env.STAGE]
    }`;
  };

  return (
    <>
      <div className="p-4">
        <Button onClick={() => onButtonClick()}>
          <div className="ml-1 leading-loose">Connect with Stripe</div>
        </Button>
      </div>
    </>
  );
};

export default NewStripeIntegration;
