import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezonedayjs from 'dayjs/plugin/timezone';

import useCurrentUser from 'src/customHooks/useCurrentUser';
import { getAmountWithCurrencySymbol } from 'src/lib/generic/currency';

import { TIMESERIES_PERIODS } from 'src/lib/graphsHelpers/graphsPeriods';
import { defaultTimezone } from 'src/lib/localStorageKeys';
import { amountFormatterForGraphs } from '../chargesTransformations/chargesTransformations';
import {
  formatDatabaseTimeInput,
  formatDateToUserTimezone,
  ISOStringFormat,
  turnDateFromUserTimezoneToUTCInISOString,
} from '../handleTimezone';

dayjs.extend(utc);
dayjs.extend(timezonedayjs);

export function useAmountFormatter() {
  const {
    defaultCurrency: { threeLetters: defaultCurrencyThreeLetters },
  } = useCurrentUser();

  return {
    tickCurrencyFormatter: (amount) => amountFormatterForGraphs({ defaultCurrencyThreeLetters, amount: amount / 100 }),
    tooltipCurrencyFormatter: (amount) => {
      return getAmountWithCurrencySymbol({ currency: defaultCurrencyThreeLetters, amount: amount / 100 });
    },
    tickPeopleFormatter: (data) => data,
    tooltipPeopleFormatter:
      ({ type }) =>
      (data) =>
        `${data} ${(type && type) || ''}${(type && data > 1 && 's') || ''}`,
  };
}

export function useTimeSeries() {
  return {
    getTimeSerie: getTimeserieAndPeriod,
  };
}

function getTimeserieAndPeriod(timeRange, timeRangeLabel) {
  const timeSeriePeriod = getTimeSeriePeriod(timeRange, timeRangeLabel);
  const [timeSerie, prevTimeSerie] = getTimeSerie({ timeSeriePeriod, timeRange, timeRangeLabel });
  return { timeSerie, prevTimeSerie, timeSeriePeriod, timeRangeLabel, timeRange };
}

function getTimeSeriePeriod(timeRange, timeRangeLabel) {
  const { start, end } = getTimeRangeInDayjsUtcObject(timeRange);
  const months = end.diff(start, 'month');
  const days = end.diff(start, 'days');

  if (timeRangeLabel && ['QTD', 'YTD'].includes(timeRangeLabel)) return TIMESERIES_PERIODS.dtd;
  if (days <= 1) return TIMESERIES_PERIODS.hourly;
  if (months <= 1) return TIMESERIES_PERIODS.daily;
  if (months <= 3) return TIMESERIES_PERIODS.monthly;
  if (months > 12) return TIMESERIES_PERIODS.yearly;
  return TIMESERIES_PERIODS.monthly;
}

function getTimeSerie({ timeRange, timeSeriePeriod }) {
  const timeSeriePeriodMap = {
    [TIMESERIES_PERIODS.hourly]: () => getHourlyTimeSeries(timeRange),
    [TIMESERIES_PERIODS.daily]: () => getDailyTimeSeries(timeRange),
    [TIMESERIES_PERIODS.monthly]: () => getMonthlyTimeSeries(timeRange),
    [TIMESERIES_PERIODS.weekly]: () => getWeeklyTimeSeries(timeRange),
    [TIMESERIES_PERIODS.dtd]: () => getDtdMonthlySeries(timeRange),
    [TIMESERIES_PERIODS.yearly]: () => getYearlyTimeSeries(timeRange),
  };

  const getSelectedTimeSerie = timeSeriePeriodMap[timeSeriePeriod];
  return getSelectedTimeSerie();
}

function getHourlyTimeSeries(timeRange) {
  const { start: startTimeRange, end: endTimeRange } = timeRange;
  const hours = [];

  const numHours = getDiffOfUnits({ start: startTimeRange, end: endTimeRange, unit: 'hours' });

  const { start: startTimeRangeInUTC } = getTimeRangeInDayjsUtcObject(timeRange);

  // is yesterday or only one day custom time range
  if (numHours < 1) return [{ start: startTimeRange, end: endTimeRange }];

  // eslint-disable-next-line no-plusplus
  for (let dayCounter = 0; dayCounter < numHours; dayCounter++) {
    const startDate = startTimeRangeInUTC.add(dayCounter * 1, 'hours');
    const oneSecondLessThanOneHour = 3599;
    const endDate = startDate.add(oneSecondLessThanOneHour, 'seconds');

    hours.push({
      start: startDate,
      end: endDate,
    });
  }

  return [hours];
}

function getDailyTimeSeries(timeRange) {
  const { start: startTimeRange, end: endTimeRange } = timeRange;
  const days = [];

  const numDays = getDiffOfUnits({ start: startTimeRange, end: endTimeRange, unit: 'days' });

  const { start: startTimeRangeInUTC } = getTimeRangeInDayjsUtcObject(timeRange);

  // is yesterday or only one day custom time range
  if (numDays < 1) return [{ start: startTimeRange, end: endTimeRange }];

  // eslint-disable-next-line no-plusplus
  for (let dayCounter = 0; dayCounter < numDays; dayCounter++) {
    const startDate = startTimeRangeInUTC.add(dayCounter * 24, 'hours');
    const oneSecondLessThanOneDay = 86399;
    const endDate = startDate.add(oneSecondLessThanOneDay, 'seconds');

    days.push({
      start: startDate,
      end: endDate,
    });
  }

  return [days];
}

function getWeeklyTimeSeries(timeRange) {
  const weeks = [];
  const { start: startTimeRange, end: endTimeRange } = timeRange;

  const numWeeks = getDiffOfUnits({ start: startTimeRange, end: endTimeRange, unit: 'weeks' });

  const { start: startTimeRangeInUTC } = getTimeRangeInDayjsUtcObject(timeRange);

  let startDate;
  // eslint-disable-next-line no-plusplus
  for (let weekCounter = 0; weekCounter < numWeeks; weekCounter++) {
    const addedWeeks = startTimeRangeInUTC.add(weekCounter, 'week');

    if (weekCounter === 0) {
      startDate = startTimeRange;
    } else {
      startDate = getDateStartOfUnit({ date: addedWeeks, unit: 'week' });
    }

    const endDate = getDateEndOfUnit({ date: addedWeeks, unit: 'week' });

    weeks.push({ start: startDate, end: endDate });
  }

  return [weeks];
}

function getMonthlyTimeSeries(timeRange) {
  const { start: startTimeRange, end: endTimeRange } = timeRange;
  const months = [];
  const prevMonths = [];

  const timeRangeBeginningOfMonthString = getDateStartOfUnit({ date: startTimeRange, unit: 'month' });
  const timeRangeEndOfMonthString = getDateEndOfUnit({ date: endTimeRange, unit: 'month' });

  const numMonths = getDiffOfUnits({
    start: timeRangeBeginningOfMonthString,
    end: timeRangeEndOfMonthString,
    unit: 'month',
  });

  let startDate;
  let endDate;

  const { start: startTimeRangeInUTC, end: endTimeRangeInUTC } = getTimeRangeInDayjsUtcObject(timeRange);

  // eslint-disable-next-line no-plusplus
  for (let monthsCounter = 0; monthsCounter < numMonths; monthsCounter++) {
    const isLastMonth = monthsCounter === numMonths - 1;
    const nextMonthInISOUtc = addMonths({ startRangeInISO: startTimeRangeInUTC, monthsToAdd: monthsCounter });

    if (monthsCounter === 0) {
      startDate = startTimeRange;
    } else {
      startDate = getDateStartOfUnit({ date: nextMonthInISOUtc, unit: 'month' });
    }
    if (isLastMonth) {
      endDate = endTimeRange;
    } else {
      endDate = getDateEndOfUnit({ date: nextMonthInISOUtc, unit: 'month' });
    }

    months.push({
      start: startDate,
      end: endDate,
    });
  }

  // eslint-disable-next-line no-plusplus
  for (let monthsCounter = 0; monthsCounter < numMonths; monthsCounter++) {
    const isLastMonth = monthsCounter === numMonths - 1;
    const monthsToSubtract = 1;
    const monthsToAdd = monthsCounter - monthsToSubtract;
    const nextMonthInISOUtc = addMonths({ startRangeInISO: startTimeRangeInUTC, monthsToAdd });

    if (monthsCounter === 0) {
      startDate = startTimeRangeInUTC.subtract(monthsToSubtract, 'month');
    } else {
      startDate = getDateStartOfUnit({ date: nextMonthInISOUtc, unit: 'month' });
    }
    if (isLastMonth) {
      endDate = endTimeRangeInUTC.subtract(monthsToSubtract, 'month');
    } else {
      endDate = getDateEndOfUnit({ date: nextMonthInISOUtc, unit: 'month' });
    }

    prevMonths.push({
      start: startDate,
      end: endDate,
    });
  }

  return [months, prevMonths];
}

function getYearlyTimeSeries(timeRange) {
  const { start: startTimeRange, end: endTimeRange } = timeRange;
  const years = [];
  const prevYears = [];

  const { start: startTimeRangeInUTC, end: endTimeRangeInUTC } = getTimeRangeInDayjsUtcObject(timeRange);

  const timeRangeBeginningOfYearString = getDateStartOfUnit({ date: startTimeRange, unit: 'year' });
  const timeRangeEndOfYearString = getDateEndOfUnit({ date: endTimeRange, unit: 'year' });

  // We calculate the diff from the beginning and end of year to get the exact amount of years we need to include
  const numYears = getDiffOfUnits({
    start: timeRangeBeginningOfYearString,
    end: timeRangeEndOfYearString,
    unit: 'years',
  });

  let startDate;
  let endDate;

  // eslint-disable-next-line no-plusplus
  for (let yearsCounter = 0; yearsCounter < numYears; yearsCounter++) {
    const isLastYear = yearsCounter === numYears - 1;
    const addedYears = startTimeRangeInUTC.add(yearsCounter, 'year');

    if (yearsCounter === 0) {
      startDate = startTimeRange;
    } else {
      startDate = getDateStartOfUnit({ date: addedYears, unit: 'year' });
    }
    if (isLastYear) {
      endDate = endTimeRange;
    } else {
      endDate = getDateEndOfUnit({ date: addedYears, unit: 'year' });
    }

    years.push({
      start: startDate,
      end: endDate,
    });
  }

  // eslint-disable-next-line no-plusplus
  for (let yearsCounter = 0; yearsCounter < numYears; yearsCounter++) {
    const isLastYear = yearsCounter === numYears - 1;
    const yearsToSubtract = 1;
    const yearsToAdd = yearsCounter - yearsToSubtract;
    const addedYears = startTimeRangeInUTC.add(yearsToAdd, 'year');

    if (yearsCounter === 0) {
      startDate = startTimeRangeInUTC.subtract(yearsToSubtract, 'year');
    } else {
      startDate = getDateStartOfUnit({ date: addedYears, unit: 'year' });
    }
    if (isLastYear) {
      endDate = endTimeRangeInUTC.subtract(yearsToSubtract, 'year');
    } else {
      endDate = getDateEndOfUnit({ date: addedYears, unit: 'year' });
    }

    prevYears.push({
      start: startDate,
      end: endDate,
    });
  }

  return [years, prevYears];
}

function getDtdMonthlySeries(timeRange) {
  const { start: startTimeRange, end: endTimeRange } = timeRange;
  const months = [];
  const prevMonths = [];

  const { start: startTimeRangeInUTC, end: endTimeRangeInUTC } = getTimeRangeInDayjsUtcObject(timeRange);

  const numMonths = getDiffOfUnits({
    start: startTimeRange,
    end: endTimeRange,
    unit: 'month',
    round: 'up',
  });

  let startDate;
  let endDate;

  // eslint-disable-next-line no-plusplus
  for (let monthsCounter = 0; monthsCounter < numMonths; monthsCounter++) {
    const isLastMonth = monthsCounter === numMonths - 1;
    const nextMonthInISOUtc = addMonths({ startRangeInISO: startTimeRangeInUTC, monthsToAdd: monthsCounter });

    if (monthsCounter === 0) {
      startDate = startTimeRange;
    } else {
      startDate = getDateStartOfUnit({ date: nextMonthInISOUtc, unit: 'month' });
    }
    if (isLastMonth) {
      endDate = endTimeRange;
    } else {
      endDate = getDateEndOfUnit({ date: nextMonthInISOUtc, unit: 'month' });
    }

    months.push({
      start: startDate,
      end: endDate,
    });
  }

  // eslint-disable-next-line no-plusplus
  for (let monthsCounter = 0; monthsCounter < numMonths; monthsCounter++) {
    const monthsToSubtract = 1;
    const monthsToAdd = monthsCounter - monthsToSubtract;
    const nextMonthInISOUtc = addMonths({ startRangeInISO: startTimeRangeInUTC, monthsToAdd });

    if (monthsCounter === 0) {
      startDate = startTimeRangeInUTC.subtract(monthsToSubtract, 'month');
    } else {
      startDate = getDateStartOfUnit({ date: nextMonthInISOUtc, unit: 'month' });
    }
    if (monthsCounter === numMonths) {
      endDate = endTimeRangeInUTC.subtract(monthsToSubtract, 'month');
    } else {
      endDate = getDateEndOfUnit({ date: nextMonthInISOUtc, unit: 'month' });
    }

    prevMonths.push({
      start: startDate,
      end: endDate,
    });
  }

  return [months, prevMonths];
}

function getTimeRangeInDayjsUtcObject({ start, end }) {
  // We create the dayjs object in utc to not be converted to local (user's browser) timezone
  const startTimeRangeInUTC = dayjs.utc(start);
  const endTimeRangeInUTC = dayjs.utc(end);

  return {
    start: startTimeRangeInUTC,
    end: endTimeRangeInUTC,
  };
}

function turnIsoStringToLocalTimezone({ date }) {
  const { timezone: userTimezone } = defaultTimezone.get() || {};

  // 2021-07-31T22:00:00.000Z in Africa/Cairo --> 2021-08-01T00:00:00.000Z
  // 2021-09-05T22:00:00.000Z in Africa/Cairo --> 2021-09-06T00:00:00.000Z
  // 2021-09-22T21:59:59.000Z in Africa/Cairo --> 2021-09-23T00:00:00.000Z

  // 2021-08-01T03:00:00.000Z in America/Buenos_Aires --> 2021-08-01T00:00:00.000Z
  // 2021-09-06T03:00:00.000Z in America/Buenos_Aires --> 2021-09-06T00:00:00.000Z
  // 2021-09-23T02:59:59.000Z in America/Buenos_Aires --> 2021-09-23T00:00:00.000Z
  return formatDateToUserTimezone({ ISODate: date, tz: userTimezone, format: ISOStringFormat });
}

function getDateStartOfUnit({ date, unit }) {
  const startTimeRangeInBeginningOfDay = turnIsoStringToLocalTimezone({ date });

  const dateInString = dayjs.utc(startTimeRangeInBeginningOfDay).startOf(unit).toISOString();

  return formatDatabaseTimeInput({ date: dateInString });
}

function getDateEndOfUnit({ date, unit }) {
  const endTimeRangeInBeginningOfDay = turnIsoStringToLocalTimezone({ date });

  const dateInString = dayjs.utc(endTimeRangeInBeginningOfDay).endOf(unit).toISOString();

  return formatDatabaseTimeInput({ date: dateInString });
}

function getDiffOfUnits({ start, end, unit, round = 'near' }) {
  const { start: startTimeRangeInUTC, end: endTimeRangeInUTC } = getTimeRangeInDayjsUtcObject({ start, end });

  const roundTypes = { near: Math.round, up: Math.ceil };
  const selectedRoundType = roundTypes[round];

  // We round the number because sometimes for different reasons, the difference can have some small differences
  const diffWithDecimals = endTimeRangeInUTC.diff(startTimeRangeInUTC, unit, true);
  const diffRounded = selectedRoundType(diffWithDecimals);

  return diffRounded;
}

// We take into account the Daylight saving time in this function that involves month counting
function addMonths({ startRangeInISO, monthsToAdd }) {
  const takeISOInLocalTime = turnIsoStringToLocalTimezone({ date: startRangeInISO });
  const startUtcObject = dayjs.utc(takeISOInLocalTime);
  const addedMonths = startUtcObject.add(monthsToAdd, 'month').toISOString();

  const nextMonth = turnDateFromUserTimezoneToUTCInISOString({ ISODate: addedMonths });

  return nextMonth;
}
