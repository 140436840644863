import React, {
  useState,
  createContext,
  useContext,
  useMemo,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import { routes, navigate } from '@redwoodjs/router';

import Menu from 'src/components/Menu/Menu';
import SecondaryMenu from 'src/components/SecondaryMenu/SecondaryMenu';
import { getGitTag } from 'src/lib/generic/envHandlers';
import { useRecoilState, useRecoilValue } from 'recoil';
import TrialDaysLeftWidget from 'src/components/TrialDaysLeftWidget';
import { subMenuNavMarginTop } from 'src/layouts/AppLayout/AppLayout';
import { isBrowser } from '@redwoodjs/prerender/browserUtils';

import { collapsedSidebar as RecoilCollapsedSidebar, getTwSidebarWidth } from 'src/atoms/sidebar';
import { Spacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import useAccount from 'src/customHooks/useAccount';
import useLocalStorage from 'src/customHooks/useLocalStorage';

const SidebarContext = createContext(null);

const useLocalSidebarState = (key) => {
  const [storedValueNew, setValue] = useLocalStorage(key);
  if (!isBrowser) return [{ value: false }, () => {}];
  return [storedValueNew, setValue];
};

const SUBMENU_WIDTH = {
  open: '240',
  close: '24',
};

export const SidebarProvider = ({ children }) => {
  const [collapsedSidebarState, setCollapsedBarState] = useState(false);
  const [storedValueNew, setValue] = useLocalSidebarState('collapsed_sidebar');
  const [sidebarTwWidth, setSidebarTwWidth] = useState(() => (storedValueNew.value ? 80 : 250));
  const [mainWidth, setMainWidth] = useState(null);
  const [mainHeight, setMainHeight] = useState(null);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(true);
  const [subMenuWidth, setSubMenuWidth] = useState(0);
  const [haveSubmenu, setHaveSubmenu] = useState(false);

  useEffect(() => {
    if (!haveSubmenu) return;
    setSubMenuWidth(SUBMENU_WIDTH[isSubmenuOpen ? 'open' : 'close']);
  }, [isSubmenuOpen, haveSubmenu]);

  const collapsedSidebar = useMemo(
    () => storedValueNew.value || collapsedSidebarState,
    [storedValueNew, collapsedSidebarState]
  );

  const setCollapsedBar = (value) => {
    setValue(value);
    setCollapsedBarState(value);
    setSidebarTwWidth(value ? 80 : 250);
  };

  const mainRef = useRef(null);
  const subMenuRef = useRef(null);

  useEffect(() => {
    setMainHeightHandler();
  }, [mainRef]);

  const setMainWidthHandler = (e) => {
    const mainWidthLocal = window.innerWidth - sidebarTwWidth - subMenuWidth;
    setMainWidth(mainWidthLocal);
  };

  const setMainHeightHandler = () => {
    if (!mainRef.current) {
      return;
    }
    const { y: yPosition } = mainRef.current.getBoundingClientRect();
    setMainHeight(window.innerHeight - yPosition);
  };

  useEffect(() => {
    setMainWidthHandler();
  }, [subMenuWidth, sidebarTwWidth]);

  const widthAndHeightHandler = () => {
    setMainWidthHandler();
    setMainHeightHandler();
  };

  useEffect(() => {
    window.addEventListener('resize', widthAndHeightHandler);

    return () => {
      window.removeEventListener('resize', widthAndHeightHandler);
    };
  }, [widthAndHeightHandler]);

  // useEffect(() => {
  //   window.addEventListener('resize', setMainHeightHandler);

  //   return () => {
  //     window.removeEventListener('resize', setMainHeightHandler);
  //   };
  // }, []);

  return (
    <SidebarContext.Provider
      value={{
        sidebarTwWidth,
        collapsedSidebar,
        setCollapsedBar,
        mainRef,
        mainWidth,
        mainHeight,
        subMenuWidth,
        isSubmenuOpen,
        setIsSubmenuOpen,
        subMenuRef,
        setHaveSubmenu,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

SidebarProvider.propTypes = {
  children: PropTypes.any,
};

export const useSidebar = () => useContext(SidebarContext);

export default function SidebarLayout({ submenu = {} }) {
  return (
    <SidebarProvider>
      <SideBarLayoutContainer submenu={submenu} />
    </SidebarProvider>
  );
}

export function SideBarLayoutContainer({ submenu = {} }) {
  const { trial } = useAccount();
  const { setIsSubmenuOpen, setHaveSubmenu } = useSidebar();

  useEffect(() => {
    setIsSubmenuOpen(submenu.isOpen);
    setHaveSubmenu(Object.keys(submenu).length > 0);
  }, []);

  return (
    <>
      <Sidebar>
        <HeaderLogo />
        <Menu />
        {trial && (
          <>
            <Spacer size="md" />
            <TrialDaysLeftWidget />
            <Spacer size="sm" />
          </>
        )}

        <SecondaryMenu />
      </Sidebar>
      <ButtonCollapseSidebar />
      {submenu?.nav && <Submenu nav={submenu.nav} title={submenu.title} />}
    </>
  );
}

const Sidebar = ({ children }) => {
  const { sidebarTwWidth, collapsedSidebar } = useSidebar();
  return (
    <div
      id="sidebar"
      style={{ width: sidebarTwWidth }}
      className={`h-screen menu bg-wmxBgDark-200 text-gray-300 flex-none	 ${
        (collapsedSidebar && 'px-4') || 'px-6'
      } pb-6 pt-6 flex flex-col justify-between items-center p-32 z-50 border-r border-wmxBgDark-100 h-screen relative`}
    >
      {children}
    </div>
  );
};

function HeaderLogo() {
  const { collapsedSidebar } = useSidebar();
  return (
    <button
      type="button"
      className={`w-full flex flex-col pl-2 ${collapsedSidebar && 'items-center '}`}
      onClick={() => navigate(routes.dashboard())}
    >
      <img
        className={`block ${(collapsedSidebar && 'w-8') || 'w-10'}`}
        alt="Wild Audience Logo"
        src="/logo-white.png"
      />
      {!collapsedSidebar && (
        <div
          className={`logoTitle mt-2 text-sm ${
            (collapsedSidebar && 'text-center transform scale-75') || 'text-left '
          } `}
        >
          <>
            <span className="wildmetrics font-bold mr-2 ">Wildmetrics</span>
            <span
              className={`versionContainer text-wmxText-200 ${(collapsedSidebar && 'block text-xs') || 'text-sm'} `}
            >
              {getGitTag()}
            </span>
          </>
        </div>
      )}
    </button>
  );
}

const Submenu = ({ nav, classes, title }) => {
  const { subMenuWidth, subMenuRef, isSubmenuOpen, setIsSubmenuOpen } = useSidebar();
  // const animate = isSubmenuOpen ? 'animate-fade-in-left' : 'animate-fade-out-left';
  return (
    <div
      ref={subMenuRef}
      style={{ width: `${subMenuWidth}px` }}
      className={`${
        classes || ''
      } pb-6 pt-6 h-screen flex-none box-border menu bg-wmxBgDark-200 shadow-sidebar text-gray-300  flex flex-col justify-start items-start z-20  ${
        (isSubmenuOpen && 'px-4') || 'px-2'
      }`}
    >
      <div className="subnavHeader w-full border-wmxBgDark-200 border-b-2 flex justify-between items-center pt-6 pb-4">
        {isSubmenuOpen && (
          <div className={`text-sm text-wmxText-100 text-left font-bold align-middle px-4`}>{title}</div>
        )}
        <ButtonCollapseSubMenu setIsSubmenuOpen={setIsSubmenuOpen} isSubmenuOpen={isSubmenuOpen} />
      </div>
      {isSubmenuOpen && (
        <>
          <div className={`h-0.5 w-full bg-wmxBgDark-100`} />
          <div className={`${subMenuNavMarginTop} w-full`}>{nav}</div>
        </>
      )}
    </div>
  );
};

const ButtonCollapseSubMenu = () => {
  const { isSubmenuOpen, setIsSubmenuOpen } = useSidebar();
  return (
    <button
      type="button"
      onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}
      className={`hover:bg-wmxHighlightDark-100 rounded-full p-1 flex items-center justify-center h-6 w-6 ${
        !isSubmenuOpen && 'transform -rotate-180 -translate-x-2'
      }`}
    >
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.99989 13L1 7L7 1"
          stroke="white"
          strokeOpacity="0.55"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

const ButtonCollapseSidebar = () => {
  const { collapsedSidebar, setCollapsedBar } = useSidebar();

  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => setCollapsedBar(!collapsedSidebar)}
        className={`bg-wmxBgDark-200 hover:bg-wmxHighlightDark-100 rounded-full p-1 flex items-center justify-center h-6 w-6 absolute bottom-6 -right-2 z-50 ${
          (collapsedSidebar && 'transform -rotate-180 translate-x-1') || ''
        }`}
      >
        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.99989 13L1 7L7 1"
            stroke="white"
            strokeOpacity="0.55"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
};

Sidebar.propTypes = {
  children: PropTypes.any,
};

Submenu.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.string,
  nav: PropTypes.object,
  isSubmenuOpen: PropTypes.bool,
  setIsSubmenuOpen: PropTypes.func,
  sidebarTwWidth: PropTypes.number,
};

ButtonCollapseSubMenu.propTypes = {
  isSubmenuOpen: PropTypes.bool,
  setIsSubmenuOpen: PropTypes.func,
};

SidebarLayout.propTypes = {
  submenu: PropTypes.object,
};

SideBarLayoutContainer.propTypes = {
  submenu: PropTypes.object,
};
