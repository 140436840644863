import PropTypes from 'prop-types';

import { useRecoilValue } from 'recoil';
import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';
import SquareLeadsCount from 'src/components/SquareLeadsCount/SquareLeadsCount';
import { useFunnelData } from 'src/components/Funnel/funnelHooks';
import { pretiffyTypeOfItem, isAnyTypeOFItem } from 'src/lib/funnelHelpers/funnelHelpers';
import { capitalize } from 'src/lib/generic/handlers';
import { useEffect, useState } from 'react';

export default function FunnelTypeOfItemSquare({
  isEmpty,
  squareIndex,
  isSelected,
  isLastSquare,
  onClick,
  typeOfItem,
  panePath,
  itemsCount,
  peopleInSquareFetchedByFunnelId = null,
  funnelPart,
  onNewSquareClick,
}) {
  const containerBorder = isSelected ? 'border-wmxPrimary-300' : 'border-wmxSecondary-300';
  const containerShadow = isSelected ? 'shadow-2xl' : 'shadow-xl';
  const containerBg = isSelected
    ? 'bg-wmxHighlightDark-100 opacity-100 transform scale-110 origin-top'
    : 'bg-wmxHighlightDark-100 opacity-80';

  const {
    deleteSquare,
    isCurrentFrontEndPurchase,
    isCurrentAnyProduct,
    nextIsFrontEndPurchase,
    funnelData,
    isSquareReadyToQuery,
    setClickedElementId,
    isRightPaneOpen,
  } = useFunnelData();

  const [isANDOR, setIsANDOR] = useState('or');

  const shouldShowLine = () => {
    return (
      (!isEmpty &&
        !isCurrentFrontEndPurchase(capitalize(funnelPart), squareIndex) &&
        isSquareReadyToQuery(funnelPart, squareIndex) &&
        !isCurrentAnyProduct(capitalize(funnelPart), squareIndex) &&
        !isRightPaneOpen) ||
      !isLastSquare
    );
  };
  const shouldShowPlus = () => {
    return (
      !isEmpty &&
      isLastSquare &&
      !isCurrentFrontEndPurchase(capitalize(funnelPart), squareIndex) &&
      isSquareReadyToQuery(funnelPart, squareIndex) &&
      !isCurrentAnyProduct(capitalize(funnelPart), squareIndex) &&
      !isRightPaneOpen
    );
  };

  useEffect(() => {
    if (funnelPart !== 'top' || nextIsFrontEndPurchase(capitalize(funnelPart), squareIndex)) {
      setIsANDOR('and');
    } else {
      setIsANDOR('or');
    }
  }, [nextIsFrontEndPurchase, funnelData, funnelPart, squareIndex]);

  // eslint-disable-next-line no-shadow
  const onEmptyClickHandler = (squareIndex, funnelPart) => {
    setClickedElementId('first');
    onClick(squareIndex, funnelPart);
  };

  return (
    <div className={`group flex flex-row z-20 ${isRightPaneOpen && 'cursor-not-allowed'}`}>
      <div
        className={`group-hover:shadow-2xl group-hover:opacity-100 w-36 h-36 border-2 border-dashed transition-all duration-250 rounded-lg relative ${
          isRightPaneOpen && 'cursor-not-allowed'
        } ${containerBorder} ${containerShadow} ${containerBg}`}
      >
        {!isEmpty ? (
          <SquareFilledWithData
            onClick={() => onClick(squareIndex, funnelPart)}
            isSelected={isSelected}
            typeOfItem={typeOfItem}
            panePath={panePath}
            itemsCount={itemsCount}
            squareIndex={squareIndex}
            funnelPart={funnelPart}
            peopleInSquareFetchedByFunnelId={peopleInSquareFetchedByFunnelId}
          />
        ) : (
          <EmptySquare onClick={() => onEmptyClickHandler(squareIndex, funnelPart)} isSelected={isSelected} />
        )}
        {(!isEmpty || squareIndex > 0) && (
          <DeleteItemButton
            disabled={isRightPaneOpen}
            onClick={() => deleteSquare(squareIndex)}
            classNames="absolute right-2 top-2 opacity-50 hover:opacity-100"
          />
        )}
      </div>
      <div className="flex flex-row items-center justify-center">
        {shouldShowLine() && (
          <>
            {isLastSquare && <hr className="border-wmxPrimary-200  w-6 " />}
            {!isLastSquare && (
              <>
                <hr className="border-wmxPrimary-200 border-1 w-8 " />
                {(isANDOR === 'and' && <Badge>AND THEN</Badge>) || <Badge>OR</Badge>}

                <hr className="border-wmxPrimary-200 border-1 w-8 " />
              </>
            )}
          </>
        )}
        {shouldShowPlus() && <NewSquare onClick={() => onNewSquareClick(funnelPart)} />}
      </div>
    </div>
  );
}

function Badge({ children }) {
  return <span className="rounded-lg bg-wmxBgDark-300 px-2 py-1 text-xxs text-white">{children}</span>;
}

/* ------------------------------------------------------------------------------------------------
 * Children components
 * -----------------------------------------------------------------------------------------------*/

function SquareFilledWithData({
  typeOfItem,
  itemsCount,
  onClick,
  panePath,
  squareIndex,
  funnelPart,
  peopleInSquareFetchedByFunnelId = null,
}) {
  const { timeRange } = useRecoilValue(RecoilTimeRange);
  const { isSquareReadyToQuery, isRightPaneOpen } = useFunnelData();
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={isRightPaneOpen}
      className="w-full h-full flex flex-col justify-between p-4"
    >
      <div className="typeOfItemData">
        <div className="flex">
          <div className="text-wmxText-200 text-left text-xs">
            <span>{panePath[1].name}</span>
          </div>
        </div>
        <div className="w-full pb-2 mt-1 flex flex-col items-start">
          {typeOfItem && panePath[panePath.length - 1].final && (
            <h4 className="text-white text-left text-sm font-bold">
              {(itemsCount > 0 && itemsCount) || (!isAnyTypeOFItem(typeOfItem) ? 0 : '')}{' '}
              {pretiffyTypeOfItem(typeOfItem)}
              {itemsCount > 1 && 's'}
            </h4>
          )}
        </div>
      </div>
      {(isSquareReadyToQuery(funnelPart, squareIndex) || peopleInSquareFetchedByFunnelId !== null) && (
        <LeadsCountHandler
          timeRange={timeRange}
          squareIdx={squareIndex}
          funnelPart={funnelPart}
          peopleInSquareFetchedByFunnelId={peopleInSquareFetchedByFunnelId}
        />
      )}
    </button>
  );
}

function EmptySquare({ onClick, isSelected }) {
  const { isRightPaneOpen } = useFunnelData();
  return (
    <button
      type="button"
      disabled={isRightPaneOpen}
      onClick={onClick}
      className="h-full w-full flex items-center justify-center"
    >
      {!isSelected && <PlusButton bgColor="wmxBgPrimary-200" onClick={onClick} />}
    </button>
  );
}

function PlusButton({ className }) {
  return (
    <span
      className={`w-6 h-6 flex items-center justify-center rounded-full pointer-events-auto cursor-pointer bg-wmxPrimary-200 group-hover:bg-wmxPrimary-100 group-hover:shadow-md transition-all duration-250  ${className} `}
    >
      <span className="text-2xl px-3 plusIcon pointer-events-auto text-wmxText-200" />
    </span>
  );
}

function NewSquare({ className, onClick }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={` w-6 h-6 flex items-center justify-center pointer-events-auto cursor-pointer rounded-full bg-wmxPrimary-200 hover:bg-wmxPrimary-100 transition-all duration-250 text-wmxText-200 ${className} `}
    >
      <span className="text-2xl px-3 plusIcon" />
    </button>
  );
}

const DeleteItemButton = ({ onClick, classNames, disabled }) => {
  return (
    <div className={`DeleteItemButton-Wrapper ${classNames}`}>
      <button
        disabled={disabled}
        type="button"
        onClick={onClick}
        className="text-xs ml-auto text-gray-300 hover:text-red-400"
      >
        <i className="fas fa-minus-circle fa-fw fa-xs" />
      </button>
    </div>
  );
};

function LeadsCountHandler({ timeRange, squareIdx, funnelPart, peopleInSquareFetchedByFunnelId }) {
  return (
    <div className="w-full flex flex-col items-end">
      <h4 className="text-white text-left text-base font-bold">
        {peopleInSquareFetchedByFunnelId !== null ? (
          peopleInSquareFetchedByFunnelId
        ) : (
          <SquareLeadsCount timeRange={timeRange} squareIdx={squareIdx} funnelPart={funnelPart} />
        )}
      </h4>
      <h4 className="text-wmxText-200 text-left text-xs">people</h4>
    </div>
  );
}

/* ------------------------------------------------------------------------------------------------
 * Proptypes
 * -----------------------------------------------------------------------------------------------*/

const dataToFillSquareProps = {
  dataToFillSquare: PropTypes.shape({
    funnelMetricName: PropTypes.string,
    itemTypeName: PropTypes.string,
    numberOfPeople: PropTypes.string,
    peopleInSquareFetchedByFunnelId: PropTypes.number,
  }),
};

FunnelTypeOfItemSquare.propTypes = {
  onClick: PropTypes.func,
  isEmpty: PropTypes.bool,
  isSelected: PropTypes.bool,
  squareIndex: PropTypes.number,
  ...dataToFillSquareProps,
};

SquareFilledWithData.propTypes = {
  ...dataToFillSquareProps,
};

EmptySquare.propTypes = {
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
};

PlusButton.propTypes = {
  className: PropTypes.string,
};

Badge.propTypes = {
  children: PropTypes.any,
};

NewSquare.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

DeleteItemButton.propTypes = {
  classNames: PropTypes.string,
  onClick: PropTypes.func,
};

LeadsCountHandler.propTypes = {
  timeRange: PropTypes.object,
  squareIdx: PropTypes.number,
  funnelPart: PropTypes.string,
  peopleInSquareFetchedByFunnelId: PropTypes.number,
};
