import { useEffect, useState } from 'react';
import UserMenu from 'src/components/UserMenu/UserMenu';
import MenuLink from 'src/components/MenuLink/MenuLink';
import MenuIcon from 'src/components/MenuIcon/MenuIcon';
import { getTimeUpdated, getGitTag } from 'src/lib/generic/envHandlers';

import { triggerTrackEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';

const HelpMenuFooter = () => {
  return (
    <>
      <div className="HelpMenuFooter mt-6 pl-2">
        <div className=" text-wmxText-200 text-xs">{`WMX ${getGitTag()}`}</div>
        <div className=" text-wmxText-200 text-xs mt-1">{`Updated ${getTimeUpdated()} ago`}</div>
      </div>
    </>
  );
};

const MenuDivider = () => {
  return <div className=" h-0.5 w-full bg-wmxBgDark-100 my-2" />;
};

export default function SecondaryMenu() {
  const [openPopover, setOpenPopover] = useState(false);

  const handleBaconClose = () => {
    const BeaconFabButtonFrame = document.querySelector('.BeaconFabButtonFrame');
    BeaconFabButtonFrame.style.visibility = 'hidden';
  };

  useEffect(() => {
    if (window.Beacon) {
      window.Beacon('on', 'close', handleBaconClose);
    }
    return () => {
      if (window.Beacon) {
        window.Beacon('off', 'close');
      }
    };
  }, []);

  const BeaconHandler = () => {
    window.Beacon('open');
    const BeaconFabButtonFrame = document.querySelector('.BeaconFabButtonFrame');
    BeaconFabButtonFrame.style.visibility = 'visible';
    setOpenPopover(false);
  };

  const supportMessageOnClick = () => {
    BeaconHandler();

    triggerTrackEvent({ eventName: SEGMENT_TRACK_EVENTS_NAMES.supportMessageSent });
  };

  const HelpSubmenu = () => {
    const helpLinkClasses =
      'MenuLink w-full link group relative pl-2 py-1 md:py-3 align-middle text-wmxText-100 no-underline hover:bg-wmxHighlightDark-100 hover:shadow-lg flex items-center ';

    return (
      <div className="w-60">
        <MenuLink
          externalLink="https://docs.wildmetrics.io/"
          label="Help documentation"
          className={helpLinkClasses}
          collapsable={false}
        />
        <MenuLink
          customOnClick={supportMessageOnClick}
          label="Send us a message"
          className={helpLinkClasses}
          collapsable={false}
        />
        <MenuDivider />
        <MenuLink
          externalLink="https://status.wildmetrics.io"
          label="Status"
          className={helpLinkClasses}
          collapsable={false}
        />
        <MenuLink
          externalLink="https://open.wildmetrics.io/f/changelog"
          label="Changelog"
          className={helpLinkClasses}
          collapsable={false}
        />
        <MenuLink
          externalLink="https://open.wildmetrics.io/"
          label="Roadmap"
          className={helpLinkClasses}
          collapsable={false}
          onExternalLinkClick={() => triggerTrackEvent({ eventName: SEGMENT_TRACK_EVENTS_NAMES.roadmapViewed })}
        />
        <MenuLink
          externalLink="https://open.wildmetrics.io/b/feature-requests"
          label="Submit feature request"
          className={helpLinkClasses}
          collapsable={false}
          onExternalLinkClick={() =>
            triggerTrackEvent({ eventName: SEGMENT_TRACK_EVENTS_NAMES.featureRequestSubmitted })
          }
        />
        <MenuLink
          externalLink="https://open.wildmetrics.io/b/bugs/"
          label="Report a bug"
          className={helpLinkClasses}
          collapsable={false}
          onExternalLinkClick={() => triggerTrackEvent({ eventName: SEGMENT_TRACK_EVENTS_NAMES.bugReported })}
        />
        <MenuDivider />
        <HelpMenuFooter />
      </div>
    );
  };

  return (
    <ul className="list-reset mb-0 w-full">
      <MenuLink
        icon={
          <MenuIcon
            svg={
              <svg width="22" height="18" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.0557 6.46774C13.7223 6.85264 13.7223 7.81489 13.0557 8.19979L5.389 12.6261C4.72233 13.011 3.889 12.5299 3.889 11.7601L3.889 2.90742C3.889 2.13762 4.72233 1.65649 5.389 2.04139L13.0557 6.46774Z"
                  fill="#9CA3AF"
                />
                <path
                  d="M10.7227 13.5323C10.056 13.1474 10.056 12.1851 10.7227 11.8002L18.3893 7.37386C19.056 6.98896 19.8893 7.47008 19.8893 8.23988L19.8893 17.0926C19.8893 17.8624 19.056 18.3435 18.3893 17.9586L10.7227 13.5323Z"
                  fill="#D1D5DB"
                />
              </svg>
            }
          />
        }
        page="account"
        label="Settings"
        parentMatch="/settings"
        className="w-full link group relative pl-2 py-1 md:py-3 align-middle text-wmxText-100 no-underline hover:bg-wmxHighlightDark-100 hover:shadow-lg  flex items-center  rounded-md mt-1"
      />
      <MenuLink
        icon={
          <MenuIcon
            svg={
              <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.42875 16.2624C6.34676 16.2624 7.10267 15.5234 7.10267 14.6124C7.10267 13.7014 6.34676 12.9624 5.42875 12.9624C4.51074 12.9624 3.75483 13.7014 3.75483 14.6124C3.75483 15.5234 4.51074 16.2624 5.42875 16.2624Z"
                  fill="#9CA3AF"
                  stroke="#9CA3AF"
                  strokeWidth="0.4"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.29898 5.34659C0.296534 5.40114 0.316493 5.45431 0.354225 5.49377C0.391957 5.53323 0.444181 5.55555 0.498779 5.55555H2.70889C2.81435 5.55555 2.90168 5.47368 2.90848 5.36845C2.97109 4.39935 3.34371 3.73669 3.85482 3.3139C4.36989 2.88784 5.04314 2.69114 5.72588 2.69114C7.28728 2.69114 8.50778 3.69315 8.50778 5.25202C8.50778 6.53341 7.76614 7.46017 6.78104 8.04824L6.781 8.04827C6.15097 8.42457 5.59675 8.80678 5.14925 9.30714C4.69886 9.81074 4.36372 10.4257 4.15682 11.2587C4.14199 11.3184 4.15547 11.3816 4.19338 11.4301C4.23128 11.4786 4.28939 11.5069 4.35092 11.5069H6.60805C6.68696 11.5069 6.75849 11.4605 6.79067 11.3885C7.09616 10.7043 7.61501 10.2052 8.4607 9.68514C10.0546 8.73148 11.0127 7.34247 11.0127 5.35555C11.0127 3.95808 10.4925 2.73915 9.56354 1.87053C8.63533 1.0026 7.31231 0.496564 5.72588 0.496564C4.26535 0.496564 2.94313 0.940182 1.96624 1.77389C0.986966 2.60963 0.36712 3.82719 0.29898 5.34659Z"
                  fill="#9CA3AF"
                  stroke="#9CA3AF"
                  strokeWidth="0.4"
                  strokeLinejoin="round"
                />
              </svg>
            }
          />
        }
        className="w-full link group relative pl-2 py-1 md:py-3 align-middle text-wmxText-100 no-underline hover:bg-wmxHighlightDark-100 hover:shadow-lg  flex items-center  rounded-md mt-1"
        label="Help"
        submenuPopOver={<HelpSubmenu />}
        openPopover={openPopover}
        setOpenPopover={setOpenPopover}
      />
      <UserMenu />
    </ul>
  );
}
