import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import useCurrentUser from 'src/customHooks/useCurrentUser';

import { Line, Spacer, Text, Title } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { formatCurrencyAmounts, getOnlyNumbersInString } from 'src/lib/generic/handlers';
import { BoxSkeletons } from 'src/components/Generic/Skeletons';
import { DEFAULT_INITIAL_SEGMENT_ELEMENTS } from 'src/lib/segmentBuilder/initialSegmentElements';
import { useSegmentBuilder } from './SegmentBuilder';

export default function TagPricesBox({ containerRef, containerRect = {} }) {
  const { segmentElements, loadingPricesByTags } = useSegmentBuilder();

  const segmentElementsConfig = segmentElements.map(({ value }) => ({
    name: value?.itemName,
    tagId: value?.itemsIds?.[0],
    isContains: value?.condition === 'contains',
  }));

  const tagItemsWithPriceInput = segmentElementsConfig.map(({ name, tagId, isContains }) => {
    return (
      <React.Fragment key={`${tagId}-${name}`}>
        <TagPrice {...{ name, tagId, isContains }} />
      </React.Fragment>
    );
  });

  const isDefaultSegmentsElements = isEqual(segmentElements, DEFAULT_INITIAL_SEGMENT_ELEMENTS);
  if (!segmentElements.length || isDefaultSegmentsElements) return <></>;

  return (
    <div ref={containerRef} className="py-5">
      <Title>Revenue</Title>
      <Spacer size="xs" />
      <Line />
      <Spacer />
      <Text>
        You can define revenue for tags. Revenue will be calculated whenever a contact receives the tags above.
      </Text>
      <Spacer size="sm" />
      {loadingPricesByTags ? (
        <BoxSkeletons
          {...{
            boxesAmount: 1,
            width: containerRect?.width,
            foregroundColor: '#1C2631',
            backgroundColor: '#304254',
          }}
        />
      ) : (
        <div style={{ maxHeight: '12em' }} className="bg-wmxBgDark-100 p-4 rounded-lg overflow-y-scroll">
          {tagItemsWithPriceInput}
        </div>
      )}
    </div>
  );
}

TagPricesBox.propTypes = {
  containerRef: PropTypes.any,
  containerRect: PropTypes.shape({
    height: PropTypes.number,
  }),
};

function TagPrice({ tagId, name, isContains }) {
  const {
    defaultCurrency: { symbol: userCurrencySymbol },
  } = useCurrentUser();

  const inputConfig = useInput({ tagId, containsKeyword: isContains && name });

  const tagTitle = isContains ? `Tags containing "${name}"` : name;

  return (
    <div className="flex flex-row py-1" lang="en-US">
      <span className="flex items-center text-white text-sm w-full">{tagTitle}</span>
      <input
        ref={inputConfig.ref}
        value={inputConfig.value}
        type="text"
        className="w-48 p-2 text-sm text-white bg-wmxHighlightDark-50  border border-wmxBgDark-100 rounded focus:outline-none focus:border-wmxHighlightDark-200"
        placeholder={`${userCurrencySymbol}100.00`}
        onChange={inputConfig.onChange}
        onFocus={inputConfig.onFocus}
        onBlur={inputConfig.onBlur}
      />
    </div>
  );
}

TagPrice.propTypes = {
  tagId: PropTypes.string,
  name: PropTypes.string,
  isContains: PropTypes.bool,
};

function useInput({ tagId, containsKeyword }) {
  const inputRef = useRef();
  const {
    defaultCurrency: { threeLetters: defaultCurrencyThreeLetters },
  } = useCurrentUser();
  const { customPricesByTag, setCustomPrice, getIsPriceDefined } = useSegmentBuilder();

  const formatInputValueToCurrencyValue = ({ inputValue }) => {
    const formattedAmount = formatCurrencyAmounts({
      amount: inputValue / 100,
      defaultCurrency: defaultCurrencyThreeLetters,
    });

    return formattedAmount;
  };

  const findTagByContainsOrTagId = ({ tagId: stateTagId, containsKeyword: stateContainsKeyword }) => {
    const isTagId = getIsPriceDefined(tagId);
    const isContains = getIsPriceDefined(containsKeyword);

    return (isTagId && tagId === stateTagId) || (isContains && stateContainsKeyword === containsKeyword);
  };

  const { customPrice: tagCustomPrice = '' } = customPricesByTag.find(findTagByContainsOrTagId) || {};

  const initialValue = tagCustomPrice > 0 ? formatInputValueToCurrencyValue({ inputValue: tagCustomPrice }) : '';
  const [inputValue, setInputValue] = useState(initialValue === 0 ? '' : initialValue);
  const [onFocus, setOnFocus] = useState(false);

  const onInputChange = (ev) => {
    const { value: targetValue } = ev.target;
    if (!targetValue) return setInputValue(''); // When user deletes everything in input, we don't format the value

    const stringOnlyWithNums = getOnlyNumbersInString(targetValue);

    const newValue = inputValue ? stringOnlyWithNums : `${targetValue}00`;
    const formattedInputValue = formatInputValueToCurrencyValue({ inputValue: newValue });

    return setInputValue(formattedInputValue);
  };

  const onInputFocus = () => {
    setOnFocus(true);
    if (inputValue === '') {
      const formattedValue = formatInputValueToCurrencyValue({ inputValue: 0 });
      setInputValue(formattedValue);
    }

    inputRef.current.setSelectionRange(0, inputValue.length);
  };

  const onInputBlur = () => {
    setOnFocus(false);
    const stringOnlyWithNums = getOnlyNumbersInString(inputValue);
    setCustomPrice({ value: stringOnlyWithNums, tagId, ...(containsKeyword && { containsKeyword }) });
  };

  // Sometimes the state does not get to update when loading is not there anymore,
  //  so we update it if the input is not on focus
  function useUpdateValueComingFromDb() {
    useEffect(() => {
      const tagCustomPriceForInputValue = formatInputValueToCurrencyValue({ inputValue: tagCustomPrice });

      if (!onFocus && tagCustomPriceForInputValue !== inputValue) {
        setInputValue(tagCustomPriceForInputValue);
      }
    });
  }

  useUpdateValueComingFromDb();

  return {
    onBlur: onInputBlur,
    onChange: onInputChange,
    onFocus: onInputFocus,
    ref: inputRef,
    value: inputValue,
  };
}
