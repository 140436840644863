import 'focus-visible';
import { AuthProvider } from '@redwoodjs/auth';
import { isBrowser } from '@redwoodjs/prerender/browserUtils';
import { FatalErrorBoundary } from '@redwoodjs/web';
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo';
import FatalErrorPage from 'src/pages/FatalErrorPage/FatalErrorPage';
import { RecoilRoot } from 'recoil';
import Routes from 'src/Routes';
import { Auth0Client } from '@auth0/auth0-spa-js';

import SetAppInitialValues from 'src/components/SetAppInitialValues/SetAppInitialValues';

import './index.css';

const auth0 = new Auth0Client({
  domain: process.env.AUTH0_DOMAIN,
  client_id: process.env.AUTH0_CLIENT_ID,
  cacheLocation: 'localstorage',
  redirect_uri: isBrowser ? window.location.origin : process.env.REDIRECT_URI,
  audience: process.env.AUTH0_AUDIENCE,
  scope: 'openid profile email',
});

const App = () => {
  return (
    <FatalErrorBoundary page={FatalErrorPage}>
      <AuthProvider client={auth0} type="auth0">
        <RedwoodApolloProvider>
          <RecoilRoot>
            <SetAppInitialValues>
              <Routes />
            </SetAppInitialValues>
          </RecoilRoot>
        </RedwoodApolloProvider>
      </AuthProvider>
    </FatalErrorBoundary>
  );
};

export default App;
