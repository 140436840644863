/* global gql */

const fragments = {
  combinedLeadsPeople: gql`
    fragment combinedLeadsPeople on FunnelLeads {
      contactId
      contactEmail
      timestamp
    }
  `,
};

export const GET_FUNNEL_COMBINED_LEADS_PEOPLE = gql`
  query GET_FUNNEL_COMBINED_LEADS_PEOPLE(
    $funnelItems: [[FunnelItemsInput!]]!
    $timeRange: [TimeRangeInput]
    $limit: Int
    $offset: Int
  ) {
    getCombinedLeadsPeople(funnelItems: $funnelItems, timeRange: $timeRange, offset: $offset, limit: $limit) {
      ...combinedLeadsPeople
    }
    activeCampaignUrl
  }
  ${fragments.combinedLeadsPeople}
`;

export const GET_FUNNEL_CUSTOMERS_WITH_REVENUE = gql`
  query GET_FUNNEL_CUSTOMERS_WITH_REVENUE(
    $funnelItems: [[FunnelItemsInput!]]!
    $timeRange: [TimeRangeInput]
    $limit: Int
    $offset: Int
  ) {
    getFunnelCustomersPeopleWithRevenue(
      funnelItems: $funnelItems
      timeRange: $timeRange
      offset: $offset
      limit: $limit
    ) {
      contactId
      customerId
      email
      stripeProduct
      stripePrice
      metadata
      invoiceMetadata
      customerMetadata
      name
      address
      chargeDescription
      amount
      timestamp
    }
    getDefaultCurrency
    activeCampaignUrl
  }
`;

export const GET_FUNNEL_EMAILS_IN_AUTOMATION = gql`
  query GET_FUNNEL_EMAILS_IN_AUTOMATION(
    $funnelItems: [[FunnelItemsInput!]]!
    $timeRange: [TimeRangeInput]
    $limit: Int
    $offset: Int
  ) {
    getEmailOpensFromAutomationFunnel(
      funnelItems: $funnelItems
      timeRange: $timeRange
      limit: $limit
      offset: $offset
    ) {
      campaign
      campaignId
      uniqueOpens
      numberOfLeads
    }
    activeCampaignUrl
  }
`;
