import { Form, FormError, FieldError, Label, TextField, HiddenField, Submit } from '@redwoodjs/forms';
import { useAuth } from '@redwoodjs/auth';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useForm } from 'react-hook-form';
import { LoadingComponent } from 'src/components/Generic';
import PropTypes from 'prop-types';
import { formCSS } from 'src/lib/generic/commonClasses';
import { Flex, HSpacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';

const WildMailIntegrationForm = ({ error, loading, onSave }) => {
  const { formMethods } = useForm();
  const { currentUser } = useAuth();
  const { accountId } = currentUser;

  const onSubmit = (data) => {
    onSave(data);
  };

  return (
    <div className="box-border text-sm -mt-4 ">
      <Form onSubmit={onSubmit} error={error} validation={{ mode: 'onBlur' }} formMethods={formMethods}>
        <FormError
          error={error}
          wrapperClassName="p-4 bg-red-100 text-red-700 border border-red-300 rounded mt-4 mb-4"
          titleClassName="mt-0 font-semibold"
          listClassName="mt-2 list-disc list-inside"
        />

        <HiddenField name="provider" defaultValue="WILDMAIL" />

        <HiddenField name="accountId" defaultValue={accountId.toString()} />

        <Label name="url" className={formCSS.label} errorClassName={formCSS.labelError}>
          Url
        </Label>
        <TextField
          name="url"
          className={formCSS.input}
          errorClassName={formCSS.inputError}
          validation={{ required: true }}
        />
        <FieldError name="url" className={formCSS.errorMessage} />

        <Label name="apiKey" className={formCSS.label} errorClassName={formCSS.labelError}>
          Api key
        </Label>
        <TextField
          name="apiKey"
          className={formCSS.input}
          errorClassName={formCSS.inputError}
          validation={{
            required: true,
            minLength: { value: 60, mesage: 'Invalid format' },
          }}
        />
        <FieldError name="apiKey" className={formCSS.errorMessage} />

        <HiddenField name="status" defaultValue="Connecting" />
        {/* {loading && <LoadingComponent />} */}
        <div className="mt-8 flex justify-end w-full">
          <Submit
            disabled={loading}
            className="bg-wmxPrimary-100 text-white hover:bg-wmxPrimary-200 text-xs rounded px-4 py-2 uppercase font-semibold tracking-wide disabled:opacity-25 disabled:cursor-not-allowed"
          >
            <Flex>
              {loading && (
                <>
                  <Flex>
                    <LoadingComponent color="white" />
                  </Flex>
                  <HSpacer />
                </>
              )}
              Connect & Continue
            </Flex>
          </Submit>
        </div>
      </Form>
    </div>
  );
};

export default WildMailIntegrationForm;

WildMailIntegrationForm.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool,
  onSave: PropTypes.func,
};
