import { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import usePressOutsideContainer from 'src/customHooks/usePressOutsideContainer';
import { LinkArrow } from 'src/components/Generic';

const getSize = (size) => {
  // We do this to avoid concatenate the tailwind value
  switch (size) {
    case 'xxs': {
      return 'text-xxs px-1 py-0';
    }
    case 'xs': {
      return 'text-xs px-3 py-1';
    }
    case 'sm': {
      return 'text-sm px-3 py-1';
    }
    case 'md': {
      return 'text-md px-3 py-1';
    }
    case 'lg': {
      return 'text-lg px-3 py-1';
    }
    default:
      throw new Error(`Case not covered: ${size}`);
  }
};

const renderSwitch = ({
  variant,
  children,
  rest,
  size = 'xs',
  minWidth,
  buttonRef,
  className = '',
  isTextUpperCase = true,
}) => {
  const upperCase = isTextUpperCase ? 'uppercase' : '';

  const sharedClassNamesByEachBtn = `flex justify-center ${upperCase} text-white ${getSize(
    size
  )} tracking-wide rounded disabled:opacity-25 disabled:cursor-not-allowed`;

  // we repeat the whole component on each switch beacuse is more easy to ready,
  // the intelisense of tailwind and any future change would be easy
  switch (variant) {
    case 'transparent':
      return (
        <button
          type="button"
          ref={buttonRef}
          style={{ minWidth: `${minWidth}px` }}
          className={`${sharedClassNamesByEachBtn} hover:bg-white hover:text-wmxPrimary-50 min-w-full h-full ${className}`}
          {...rest}
        >
          <div className="leading-loose text-center">{children}</div>
        </button>
      );
    case 'danger':
      return (
        <button
          type="button"
          ref={buttonRef}
          style={{ minWidth: `${minWidth}px` }}
          className={`${sharedClassNamesByEachBtn} bg-wmxAlert-200 hover:bg-wmxAlert-300 max-w-xs ${className}`}
          {...rest}
        >
          <div className="leading-loose text-center"> {children} </div>
        </button>
      );
    case 'unstyled':
      return (
        <button
          type="button"
          className={`${upperCase} ${className}`}
          ref={buttonRef}
          style={{ minWidth: `${minWidth}px` }}
          {...rest}
        >
          <div className="leading-loose text-center"> {children} </div>
        </button>
      );
    default:
      return (
        <button
          type="button"
          ref={buttonRef}
          style={{ minWidth: `${minWidth}px` }}
          className={`${sharedClassNamesByEachBtn} bg-wmxPrimary-100 hover:bg-wmxPrimary-200 max-w-xs ${className}`}
          {...rest}
        >
          <div className="leading-loose text-center"> {children} </div>
        </button>
      );
  }
};

const Button = ({
  children,
  variant = 'primary',
  minWidth = 100,
  sm = false,
  md = false,
  lg = false,
  xs = false,
  xxs = false,
  styles,
  className,
  styles: { isLinkArrow } = {},
  buttonRef,
  ...rest
}) => {
  const size = (sm && 'sm') || (md && 'md') || (lg && 'lg') || (xs && 'xs') || (xxs && 'xxs') || 'xs';

  const renderedButton = () =>
    renderSwitch({ children, rest, variant, minWidth, size, buttonRef, className, ...styles });

  const ButtonWithLink = () => (
    <div className="flex flex-row group">
      {renderedButton()}
      <LinkArrow visibility={{ onlyWithHover: true }} />
    </div>
  );

  return isLinkArrow ? <ButtonWithLink /> : renderedButton();
};

Button.propTypes = {
  buttonProps: PropTypes.object,
  children: PropTypes.any,
  variant: PropTypes.string,
  minWidth: PropTypes.number,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xs: PropTypes.bool,
  xxs: PropTypes.bool,
  styles: PropTypes.shape({ isTextUpperCase: PropTypes.bool, isLinkArrow: PropTypes.bool }),
  className: PropTypes.string,
  buttonRef: PropTypes.any,
};

export default Button;

export function useIsButtonClicked({ buttonRef: paramButtonRef, onSecondClick }) {
  const defaultButtonRef = useRef(null);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const buttonRef = paramButtonRef || defaultButtonRef;

  usePressOutsideContainer({
    closeContainerHandler: () => setIsButtonClicked(false),
    containerRef: buttonRef,
  });

  const onConfirmedClick = () => {
    onSecondClick();
    setIsButtonClicked(false);
  };

  const onButtonClick = isButtonClicked ? onConfirmedClick : () => setIsButtonClicked(true);

  return {
    buttonRef,
    isButtonClicked,
    setIsButtonClicked,
    onButtonClick,
  };
}
