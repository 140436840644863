import { forwardRef } from 'react';
import { routes, NavLink, useMatch, useLocation } from '@redwoodjs/router';
import PropTypes from 'prop-types';
import * as Popover from '@radix-ui/react-popover';
import './MenuLink.css';
import { useSidebar } from 'src/layouts/SidebarLayout/SidebarLayout';

const ACTIVE_CLASSES = 'MenuLink MenuLink-active bg-wmxHighlightDark-100 shadow-lg';

export default function MenuLink({
  page,
  label,
  icon,
  nestedPages,
  parentMatch,
  submenuPopOver = false,
  openPopover = false,
  setOpenPopover,
  externalLink,
  onExternalLinkClick,
  className = '',
  activeClassNames,
  collapsable = true,
  customOnClick = false,
  disabled = false,
}) {
  const { pathname } = useLocation();
  const { collapsedSidebar } = useSidebar();
  const isActive =
    pathname.indexOf(parentMatch) > -1 ||
    nestedPages?.some((pageName) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useMatch(routes[pageName]()).match;
    });
  const defaultClasses =
    'w-full link group relative pl-2 py-1 md:py-3 align-middle text-wmxText-100 no-underline hover:bg-wmxHighlightDark-100 hover:shadow-lg  flex items-center rounded-md mt-2 ';
  const baseClasses = `MenuLink ${className || defaultClasses}`;
  const linkClassNames = `${baseClasses} ${(collapsedSidebar && collapsable && 'justify-center') || ''} ${
    (isActive && (activeClassNames || ACTIVE_CLASSES)) || ''
  }`;

  const disabledClasses = disabled ? ' cursor-not-allowed	pointer-events-none' : '';
  const notCollapsedOpacity = disabled ? ' opacity-60 ' : '';

  const LinkChildren = () => {
    return (
      <>
        <div className={`${disabledClasses} ${notCollapsedOpacity}`}>{icon && icon}</div>
        {(!collapsedSidebar || !collapsable) && (
          <span className={`ml-2 pb-1 md:pb-0 text-sm text-wmxText-100 ${disabledClasses} ${notCollapsedOpacity}`}>
            {label}
          </span>
        )}
        {collapsedSidebar && collapsable && (
          <span
            className={`absolute opacity-0 group-hover:opacity-100 py-1 px-2 left-0 rounded-md transform translate-x-12 ml-2 text-sm shadow-lg bg-wmxBgDark-400 pointer-events-none ${disabledClasses}`}
          >
            {label}
          </span>
        )}
      </>
    );
  };

  return (
    <li className={`my-1 md:my-0 list-none ${disabledClasses} ${notCollapsedOpacity}`}>
      {submenuPopOver && !externalLink && (
        <MenuPopOver
          open={openPopover}
          setOpen={setOpenPopover}
          trigger={
            <MenuItemWithPopOver isActive={isActive} linkClassNames={linkClassNames}>
              <LinkChildren />
            </MenuItemWithPopOver>
          }
        >
          {submenuPopOver}
        </MenuPopOver>
      )}

      {!submenuPopOver && !externalLink && !customOnClick && (
        <NavLink
          to={routes[page]()}
          active={isActive?.toString()}
          activeClassName={`${ACTIVE_CLASSES} ${disabledClasses} ${notCollapsedOpacity}`}
          className={linkClassNames}
        >
          <LinkChildren />
        </NavLink>
      )}

      {!submenuPopOver && externalLink && (
        <button type="button" className="w-full" onClick={onExternalLinkClick}>
          <a href={externalLink} target="_blank" className={linkClassNames} rel="noreferrer">
            <div className="flex items-center">
              <LinkChildren />
              <div className="externalLinkIcon ml-4">
                <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1H6V6" stroke="#F9FAFB" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6 1L1 6" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
          </a>
        </button>
      )}

      {!submenuPopOver && customOnClick && (
        <button type="button" onClick={customOnClick} className={linkClassNames}>
          <LinkChildren />
        </button>
      )}
    </li>
  );
}

MenuLink.propTypes = {
  activeClassNames: PropTypes.string,
  className: PropTypes.string,
  collapsable: PropTypes.bool,
  customOnClick: PropTypes.func,
  disabled: PropTypes.bool,
  externalLink: PropTypes.string,
  onExternalLinkClick: PropTypes.func,
  icon: PropTypes.object,
  label: PropTypes.string,
  nestedPages: PropTypes.array, // array of pageNames that are nested in submenu, for cases where the path is not related
  openPopover: PropTypes.bool,
  page: PropTypes.string,
  parentMatch: PropTypes.string, // string with a matching parent path to set as active, for example "/settings/user/", prefered over nestedPages
  setOpenPopover: PropTypes.func,
  submenuPopOver: PropTypes.object,
};

const MenuItemWithPopOver = ({ linkClassNames, children }) => {
  return <div className={`${linkClassNames} inline`}>{children}</div>;
};

MenuItemWithPopOver.propTypes = {
  linkClassNames: PropTypes.string,
  children: PropTypes.object,
};

// eslint-disable-next-line no-shadow
const MenuPopOver = forwardRef(({ trigger, children, open = false, setOpen, ...props }, forwardRef) => {
  return (
    <Popover.Root delayDuration={100} open={open} onOpenChange={() => setOpen(!open)}>
      <Popover.Trigger className="flex w-full items-center justify-center" onClick={(ev) => ev.stopPropagation()}>
        {trigger}
      </Popover.Trigger>
      <Popover.Content {...props} ref={forwardRef} side="right" align="start" alignOffset={-50}>
        <div className="bg-wmxBgDark-400 rounded-lg p-8">{children}</div>
        <Popover.Arrow className="fill-current text-wmxBgDark-200" />
      </Popover.Content>
    </Popover.Root>
  );
});
