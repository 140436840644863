import PropTypes from 'prop-types';
import { createContext, useContext, useReducer, Children, useEffect, cloneElement, useState, useMemo } from 'react';
import useCurrentUser from 'src/customHooks/useCurrentUser';

const OnboardingContext = createContext(null);

const updatePath = (slug) => {
  const str = window.location.href;
  const lastIndex = str.lastIndexOf('/');
  const path = str.substring(0, lastIndex);
  const currentSlug = str.substring(str.lastIndexOf('/') + 1);
  const newPath = `${path}/${slug}`;
  if (currentSlug !== slug) window.history.pushState('', '', newPath);
};

function getIndexBySlug(array, value) {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i].props.slug === value) {
      return i;
    }
  }
  return -1;
}

const stepsReducer = (state, action) => {
  switch (action.type) {
    case stepsReducer.action.type.next:
      return { ...state, direction: 'forward', step: state.step + 1 };
    case stepsReducer.action.type.back:
      return { ...state, direction: 'back', step: state.step - 1 };
    case stepsReducer.action.type.setIndexBySlug:
      return { ...state, step: action.payload };
    // case 'complete':
    //   alert('complete');
    //   return '';
    // case 'whatever':
    //   alert('whatever');
    //   return '';
    default:
      throw new Error(`Action type not defined: ${action.type}`);
  }
};

stepsReducer.action = {
  type: {
    next: 'next',
    back: 'back',
    setIndexBySlug: 'setIndexBySlug',
  },
};

export const OnboardingProvider = ({ children, slug, AcStatus, StripeStatus }) => {
  const steps = Children.toArray(children);
  const totalSteps = steps.length;
  const initialStep = { step: getIndexBySlug(steps, slug) };
  const [state, dispatch] = useReducer(stepsReducer, initialStep);

  const nextStep = () => dispatch({ type: stepsReducer.action.type.next });
  const prevStep = () => dispatch({ type: stepsReducer.action.type.back });
  const goTo = (newSlug) =>
    dispatch({ type: stepsReducer.action.type.setIndexBySlug, payload: getIndexBySlug(steps, newSlug) });
  const currentSlug = useMemo(() => steps[state.step].props.slug, [state.step, steps]);

  useEffect(() => {
    if (AcStatus && (AcStatus === 'Importing' || (AcStatus === 'Ready' && slug !== 'overview'))) {
      goTo('overview');
    }
    if (!AcStatus && slug !== 'esp') {
      goTo('esp');
    }
  }, []);

  useEffect(() => {
    console.log('currentSlug', currentSlug);
    updatePath(currentSlug);
  }, [currentSlug]);

  useEffect(() => {
    window.addEventListener('popstate', () => {
      const str = window.location.href;
      const newSlug = str.substring(str.lastIndexOf('/') + 1);
      console.log('newSlug', newSlug);
      goTo(newSlug);
    });

    return () => {
      window.removeEventListener('popstate', () => {});
    };
  }, []);

  const childrenWithProps = () =>
    steps.map((child) => {
      console.log('slug', slug);
      return cloneElement(child, {
        ...child.props,
        isActive: steps[state.step]?.props.slug === child?.props.slug,
        key: child.props.slug,
      });
    });

  const childrenWithPropsMemo = useMemo(childrenWithProps, [slug, childrenWithProps, state, steps]);

  return (
    <OnboardingContext.Provider value={{ state, dispatch, prevStep, nextStep, goTo, totalSteps }}>
      {childrenWithPropsMemo}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => useContext(OnboardingContext);

export const StepsProgress = () => {
  const {
    totalSteps,
    state: { step, direction },
  } = useOnboarding();

  const percent = ((step + 1) / totalSteps) * 100;
  const prevStep =
    step <= 0 && direction === 'forward'
      ? 0
      : step <= 0 && direction === 'back'
      ? step + 1
      : direction === 'forward'
      ? step - 1
      : step + 1;
  const initPercent = ((prevStep + 1) / totalSteps) * 100;

  const [style, setStyle] = useState({
    width: `${initPercent}%`,
    transition: `width 0.5s ease-in-out`,
  });

  useEffect(() => {
    setTimeout(() => {
      setStyle({
        width: `${percent}%`,
        transition: `width 1s ease-in-out`,
      });
    }, 10);
  }, [direction]);

  return (
    <>
      <div>
        <div className="flex justify-between w-100 mb-1">
          <div className="text-wmxText-100 text-xs">{`Step ${step + 1} of ${totalSteps}`}</div>
          <div className="text-wmxText-100 text-xs">{percent}%</div>
        </div>
        <div className="w-100 h-1 bg-wmxHighlightDark-75 relative">
          <div style={style} className="h-full bg-wmxSecondary-100" />
        </div>
      </div>
    </>
  );
};

export const Step = ({ children, isActive }) => {
  if (isActive) {
    return <>{children}</>;
  }
  return <></>;
};

Step.propTypes = {
  children: PropTypes.func,
  isActive: PropTypes.bool,
};

export const StepButton = ({ children, action }) => {
  const { dispatch } = useOnboarding();
  return (
    <button
      type="button"
      className="hover:bg-white hover:text-wmxPrimary-100 text-wmxText-200 text-xs font px-3 py-1 uppercase tracking-wide rounded max-w-xs disabled:opacity-25 disabled:cursor-not-allowed -ml-2"
      onClick={() => dispatch({ type: action })}
    >
      {children}
    </button>
  );
};

StepButton.propTypes = {
  children: PropTypes.func,
  action: PropTypes.string,
};

export const withOnboardingContext =
  (Component) =>
  ({ ...props }) => {
    const { nextStep } = useOnboarding();
    const onSuccess = () => {
      nextStep();
    };
    return <Component {...props} onSuccess={onSuccess} />;
  };

OnboardingProvider.propTypes = {
  children: PropTypes.func,
  slug: PropTypes.string,
};
