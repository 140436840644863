/* eslint-disable react/prop-types */
import InfiniteScroll from 'react-infinite-scroll-component';

import { LoadingComponent } from 'src/components/Generic';
import { scrollbarStyles } from 'src/lib/generic/commonClasses';

export default function LazyLoadingList({
  mainContainerRef,
  data,
  setNextChunk,
  hasMoreData,
  height,
  scrollableTarget,
  name,
  classNames,
  autoInfiniteScrollOverflow,
}) {
  const infiniteScrollOverflow = autoInfiniteScrollOverflow ? 'auto' : 'unset';
  return (
    <div
      id={`${name}listContainer`}
      ref={mainContainerRef}
      style={{ height }}
      className={`listContainer w-full h-full ${!autoInfiniteScrollOverflow && `overflow-scroll ${scrollbarStyles}`} ${
        classNames?.container || ''
      }`}
    >
      <InfiniteScroll
        dataLength={data.length}
        next={setNextChunk}
        hasMore={hasMoreData}
        loader={<LoadingComponent />}
        style={{ overflow: infiniteScrollOverflow }}
        height={height}
        scrollableTarget={scrollableTarget || `${name || ''}listContainer`}
        className={`${autoInfiniteScrollOverflow && scrollbarStyles}`}
      >
        <div className={`${classNames?.list || ''}`}>
          {data.map((item) => {
            return item;
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
}
