import PropTypes from 'prop-types';
import { useMutation } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/toast';

import AccountForm from 'src/components/Account/AccountForm/AccountForm';
import { Spacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { LoadingComponent } from 'src/components/Generic';
import Breadcrumb from 'src/components/Breadcrumb/Breadcrumb';
import { routes } from '@redwoodjs/router';

// eslint-disable-next-line no-undef
export const QUERY = gql`
  query EditAccountById($id: String!) {
    account: account(id: $id) {
      id
      createdAt
      modifiedAt
      adminUserId
      status
      type
      trialStart
      trialEnd
      customerId
      adminUser {
        email
      }
      integration {
        dataPhase
        status
      }
    }
  }
`;
// eslint-disable-next-line no-undef
const UPDATE_ACCOUNT_MUTATION = gql`
  mutation UpdateAccountMutation($id: String!, $input: UpdateAccountInput!, $email: String, $emitTagEvent: Boolean) {
    updateAccount(id: $id, input: $input, email: $email, emitTagEvent: $emitTagEvent) {
      id
      createdAt
      modifiedAt
      adminUserId
      status
      type
      trialStart
      trialEnd
      customerId
    }
  }
`;

export const Loading = () => <LoadingComponent />;

export const Failure = ({ error }) => <div style={{ color: 'red' }}>Error: {error.message}</div>;

Failure.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.any,
  }),
};

export const Success = ({ account }) => {
  const [updateAccount, { loading, error }] = useMutation(UPDATE_ACCOUNT_MUTATION, {
    onCompleted: () => {
      toast.success('Account updated');
    },
  });

  const onSave = (input, id, email) => {
    updateAccount({ variables: { id, input, email, emitTagEvent: true } });
  };

  return (
    <div className="rw-segment">
      <Spacer size="sm" />
      <header className="rw-segment-header">
        <Breadcrumb
          steps={[
            {
              route: () => routes.accounts(),
              label: 'Admin',
            },
            {
              route: () => routes.editAccount({ id: account.id }),
              label: account.adminUser.email,
            },
          ]}
        />
      </header>
      <Spacer size="sm" />
      <div className="rw-segment-main">
        <AccountForm account={account} onSave={onSave} error={error} loading={loading} />
      </div>
    </div>
  );
};

Success.propTypes = {
  account: PropTypes.shape({
    adminUser: PropTypes.shape({
      email: PropTypes.any,
    }),
    id: PropTypes.any,
  }),
};
