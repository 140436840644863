import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

export default function MetricSkeleton({ height = '100%', width = '100%', backgroundColor, foregroundColor }) {
  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="0" y="0" rx="8" ry="8" width="100%" height="100%" />
    </ContentLoader>
  );
}

MetricSkeleton.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  backgroundColor: PropTypes.string,
  foregroundColor: PropTypes.string,
};

export function MetricSkeletons(props) {
  const { boxesAmount } = props;

  const boxes = [];

  for (let i = 0; i < boxesAmount; i += 1) {
    boxes.push(<MetricSkeleton key={i} {...props} />);
  }

  return boxes;
}
