import PropTypes from 'prop-types';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { TooltipMessage } from 'src/components/Generic/Tooltip/Tooltip';

export default function RadixTooltip({ children, content, open, defaultOpen, onOpenChange, ...props }) {
  return (
    <TooltipPrimitive.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Content {...props}>
        {content ? <TooltipMessage>{content}</TooltipMessage> : <span />}
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Root>
  );
}

RadixTooltip.propTypes = {
  children: PropTypes.any,
  content: PropTypes.any,
  onOpenChange: PropTypes.func,
  open: PropTypes.bool,
  defaultOpen: PropTypes.bool,
};

export function OnHoverTooltip({ tooltipElement, style, classNames, children }) {
  return (
    <RadixTooltip content={tooltipElement}>
      <div style={style?.container} className={classNames?.container}>
        <div className="hover:underline" style={style?.text}>
          {children}
        </div>
      </div>
    </RadixTooltip>
  );
}
OnHoverTooltip.propTypes = {
  tooltipElement: PropTypes.any,
  style: PropTypes.shape({
    container: PropTypes.object,
    text: PropTypes.object,
  }),
  classNames: PropTypes.shape({
    container: PropTypes.string,
  }),
  children: PropTypes.any,
};
export function ButtonOnHoverTooltip({ tooltipElement, style, onButtonClick, iconElement, children }) {
  return (
    <RadixTooltip content={tooltipElement}>
      <div style={style?.container}>
        <button type="button" className="hover:underline" style={style?.button} onClick={onButtonClick}>
          {children}
        </button>
        {iconElement}
      </div>
    </RadixTooltip>
  );
}

ButtonOnHoverTooltip.propTypes = {
  tooltipElement: PropTypes.any,
  style: PropTypes.shape({
    container: PropTypes.object,
    button: PropTypes.object,
  }),
  onButtonClick: PropTypes.func,
  children: PropTypes.any,
  iconElement: PropTypes.any,
};
