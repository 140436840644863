import { useCallback, useMemo } from 'react';
import { useSegmentBuilder } from 'src/contexts/SegmentBuilder/SegmentBuilder';
import { useDashboard } from 'src/contexts/Dashboard';
import { useLazyQuery } from '@redwoodjs/web/node_modules/@apollo/client';
import { timeRange as timeRangeAtom } from 'src/atoms/timeRangeAtom';

import { RETRIEVE_DASHBOARD } from 'src/components/Dashboard/dashboardHelpers/dashboardQueries';
import { useSetRecoilState } from 'recoil';
import { getFixedRangeFromDynamicRange } from 'src/components/Generic/DatePicker/getTimeRange';
import { triggerTrackEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';
import useAccount from './useAccount';

const useDashboardLayout = () => {
  const {
    creatingNewDashboard,
    dashboardNamesState,
    editNameMode,
    metricsSettings,
    newDashboardName,
    setCreatingNewDashboard,
    setCurrentDashboard,
    setEditMode,
    setLayoutState,
    setMetricsState,
    setNewDashboardName,
    setSegmentsState,
    setShowEvents,
    setTabSelected,
    tabSelected,
    setEditNameMode,
    setIsCustomiseOpened,
    timerangeMemo,
  } = useDashboard();
  const { segmentsList } = useSegmentBuilder();
  const { accountId } = useAccount();
  const setTimerange = useSetRecoilState(timeRangeAtom);

  const metricsArr = useMemo(() => Object.values(metricsSettings), [metricsSettings]);
  const metricsIds = useMemo(() => metricsArr.map((e) => e.id), [metricsArr]);
  const segmentsIds = useMemo(() => segmentsList?.map((e) => e.id), [segmentsList]);

  const allIds = useMemo(() => {
    return segmentsIds ? [...metricsIds, ...segmentsIds, 'eventsStream'] : [...metricsIds, 'eventsStream'];
  }, [segmentsIds, metricsIds]);

  const createLayout = useCallback((ids, savedLayout) => {
    if (savedLayout) {
      return savedLayout;
    }
    const cols = 4;
    let yValue = 0;
    let xValue = 0;
    const result = [];
    for (let i = 0; i < ids.length; i += 1) {
      if (i % cols === 0 && i !== 0) {
        yValue += 1;
        xValue = 0;
      }
      if (ids[i] === 'eventsStream') {
        result.push({
          i: ids[i],
          x: xValue,
          y: yValue,
          w: 1,
          h: 1,
          maxW: 4,
          maxH: 5,
          isResizable: false,
          isDraggable: true,
        });
      } else {
        result.push({
          i: ids[i],
          x: xValue,
          y: yValue,
          w: 1,
          h: 1,
          maxW: 4,
          maxH: 4,
          isResizable: false,
          isDraggable: true,
        });
      }
      xValue += 1;
    }
    return result;
  }, []);

  const handleLayoutChange = useCallback(
    (newLayout) => {
      setLayoutState(newLayout);
    },
    [setLayoutState]
  );

  function handleResizeRules(layout, oldLayoutItem, layoutItem, placeholder) {
    function resizeHelper(num) {
      // eslint-disable-next-line no-param-reassign
      layoutItem.h = num;
      // eslint-disable-next-line no-param-reassign
      placeholder.h = num;
    }
    if (layoutItem.i !== 'eventsStream') {
      switch (layoutItem.w) {
        case 1:
          resizeHelper(1);
          break;
        case 2:
          resizeHelper(2);
          break;
        case 3:
          resizeHelper(3);
          break;
        case 4:
          resizeHelper(4);
          break;
        default:
          resizeHelper(1);
      }
    }
  }

  const resetSegments = useCallback(() => {
    setSegmentsState((previous) => {
      const newArr = [...previous];
      return newArr.map((el) => {
        return {
          ...el,
          dashboardSettings: {
            enabled: false,
          },
        };
      });
    });
  }, [setSegmentsState]);

  function resetDashboard() {
    setMetricsState((previous) => {
      const newArr = [...previous];
      return newArr.map((el) => {
        return {
          ...el,
          enabled: false,
        };
      });
    });
    resetSegments();
  }

  function retrieveCallbackSuccess(data) {
    const { layout, hasEventsStream, settings } = data.retrieveDashboard;
    triggerTrackEvent({
      eventName: SEGMENT_TRACK_EVENTS_NAMES.dashboardTabVisited,
      payload: { ...data },
    });
    setLayoutState(layout);
    setShowEvents(hasEventsStream);
    setCurrentDashboard(data.retrieveDashboard);
    setMetricsState(() => {
      return [...metricsArr].map((metric, index) => {
        return { ...metric, enabled: settings.metrics[index].enabled };
      });
    });
    setSegmentsState(() => {
      const updatedSegments = [...segmentsList].map((segment, index) => {
        const instance = settings.segments[index];
        if (instance) {
          return {
            ...segment,
            dashboardSettings: {
              enabled: instance.dashboardSettings.enabled,
            },
          };
        }
        return {
          ...segment,
          dashboardSettings: {
            enabled: false,
          },
        };
      });
      return setSegmentsState(updatedSegments);
    });
    if (data.retrieveDashboard.defaultTimerange) {
      setTimerange({
        label: data.retrieveDashboard.defaultTimerange,
        timeRange: {
          start: getFixedRangeFromDynamicRange(data.retrieveDashboard.defaultTimerange)[0],
          end: getFixedRangeFromDynamicRange(data.retrieveDashboard.defaultTimerange)[1],
        },
      });
    } else {
      setTimerange(timerangeMemo);
    }
    window.history.pushState('', '', `/dashboard/${data.retrieveDashboard.prettyHash}`);
  }

  const [retrieveDashboard] = useLazyQuery(RETRIEVE_DASHBOARD, {
    variables: { accountId, label: tabSelected },
    onCompleted: retrieveCallbackSuccess,
    fetchPolicy: 'network-only',
  });

  function handleTabClick(e) {
    if (creatingNewDashboard) {
      setCreatingNewDashboard(false);
      setNewDashboardName('');
    }
    setEditMode(false);
    setTabSelected(e.target.outerText);
    retrieveDashboard();
  }

  function createNewDashboard() {
    resetDashboard();
    setCreatingNewDashboard(true);
    setTabSelected(null);
    setCurrentDashboard(null);
    setEditMode(true);
    setShowEvents(false);
  }

  function cancelNewTab() {
    if (creatingNewDashboard && !newDashboardName) {
      setCreatingNewDashboard(false);
      setEditMode(false);
      setTabSelected(dashboardNamesState[0]);
      retrieveDashboard();
    }
  }

  function cancelEditName() {
    if (editNameMode) {
      setEditNameMode(false);
      setNewDashboardName('');
    }
  }

  function newTabHandler(e, enterPressCallback, ref) {
    if (enterPressCallback && e.key === 'Enter') {
      duplicateFound(enterPressCallback);
    } else if (e.key === 'Enter') {
      duplicateFound(() => {
        ref.current.blur();
        setIsCustomiseOpened(true);
      });
    }
    function duplicateFound(cb) {
      if (dashboardNamesState.includes(newDashboardName)) {
        alert('You already have a dashboard with this name');
      } else {
        cb();
      }
    }
  }

  return {
    allIds,
    cancelEditName,
    cancelNewTab,
    createLayout,
    createNewDashboard,
    handleLayoutChange,
    handleResizeRules,
    handleTabClick,
    metricsArr,
    metricsIds,
    resetDashboard,
    resetSegments,
    retrieveDashboard,
    newTabHandler,
    retrieveCallbackSuccess,
  };
};

export default useDashboardLayout;
