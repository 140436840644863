import getSymbolFromCurrency from 'currency-symbol-map';
import { setThousandsComma } from 'wmx-shared-code/currency/currency';

export function getCurrency(currency) {
  const lowerCaseCurrency = currency ? currency.toLowerCase() : null;

  const symbolFirst = ({ symbol, amount }) => `${symbol}${amount}`;
  // const symbolLast = ({ symbol, amount }) => `${amount}${symbol}`;
  const symboFirstOrThreeLetter = ({ symbol, threeLetters, amount }) =>
    symbol ? symbolFirst({ symbol, amount }) : `${amount}${threeLetters.toUpperCase()}`;

  const Currency = ({ descriptiveName, threeLetters, format }) => {
    const symbol = threeLetters ? getSymbolFromCurrency(threeLetters.toUpperCase()) : null;

    return {
      descriptiveName,
      symbol,
      threeLetters,
      formatAmount: (params) => format({ ...params, threeLetters, symbol }),
    };
  };

  const EuroCurrency = Currency({ descriptiveName: 'Euro', threeLetters: 'eur', format: symbolFirst });

  const currencySymbols = [
    EuroCurrency,
    Currency({ descriptiveName: 'US Dollar', threeLetters: 'usd', format: symbolFirst }),
    Currency({ descriptiveName: 'British Pound', threeLetters: 'gbp', format: symbolFirst }),
    Currency({ descriptiveName: 'Any currency', threeLetters: lowerCaseCurrency, format: symboFirstOrThreeLetter }),
  ];

  const currencyFinder = ({ symbol, threeLetters }) => {
    return symbol === lowerCaseCurrency || threeLetters === lowerCaseCurrency;
  };

  const userCurrency = lowerCaseCurrency ? currencySymbols.find(currencyFinder) : EuroCurrency;

  return userCurrency;
}

export function getAmountWithCurrencySymbol({ currency, amount: amountWithoutFormat }) {
  const amountWithCommas = setThousandsComma(amountWithoutFormat.toFixed(2));
  const { formatAmount } = getCurrency(currency);

  const formattedAmountForUserCurrency = formatAmount({ amount: amountWithCommas });

  return formattedAmountForUserCurrency;
}
