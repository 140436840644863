/* global gql */
import { useState, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useLocation } from '@redwoodjs/router';
import isEqual from 'lodash.isequal';

import {
  funnelByIdRetrieved as RecoilFunnelByIdRetrieved,
  funnelPageSource as RecoilFunnelPageSource,
  FUNNEL_PAGE_SOURCES,
} from 'src/atoms/funnelAtoms';

export const GET_FUNNEL_CONFIG_BY_ID = gql`
  query GET_FUNNEL_CONFIG_BY_ID($id: String!) {
    getSingleFunnelReportByAccount(id: $id) {
      id
      modifiedAt
      funnelItems
      name
    }
  }
`;

export const CREATE_FUNNEL_IN_DB = gql`
  mutation createNewFunnelReport($funnelItemsToDb: [[DbFunnelItems!]]!, $funnelName: String!) {
    createNewFunnelReport(funnelItemsToDb: $funnelItemsToDb, funnelName: $funnelName) {
      id
      modifiedAt
      funnelItems
      name
    }
  }
`;

export const UPDATE_FUNNEL_IN_DB = gql`
  mutation updateFunnelReport($id: String!, $funnelItemsToDb: [[DbFunnelItems!]]!, $funnelName: String!) {
    updateFunnelReport(id: $id, funnelItemsToDb: $funnelItemsToDb, funnelName: $funnelName) {
      id
      modifiedAt
      funnelItems
      name
    }
  }
`;

export const DELETE_FUNNEL_ITEM_IN_LIST = gql`
  mutation deleteFunnelReport($id: String!) {
    deleteFunnelReport(id: $id) {
      id
      modifiedAt
      funnelItems
      name
    }
  }
`;

export const useUpdateFunneRetrievedById = ({ dataMutationObj = {} }) => {
  const [isUrlChanged, setIsUrlChanged] = useState(false);
  const [funnelByIdRetrieved, setFunnelByIdRetrieved] = useRecoilState(RecoilFunnelByIdRetrieved);
  const setFunnelPageSource = useSetRecoilState(RecoilFunnelPageSource);

  const { pathname } = useLocation();

  useEffect(() => {
    const newFunnelPath = pathname.length >= 3 ? pathname.split('/')[2] : null;

    const {
      id: funnelIdFromDb,
      name: funnelName,
      funnelItems: createdFunnelItemsFromDb,
      modifiedAt: newModifiedAt,
    } = dataMutationObj;

    const updatedFunnelObj = {
      ...funnelByIdRetrieved,
      funnelName,
      funnelId: funnelIdFromDb,
      funnelItemsFromDb: createdFunnelItemsFromDb,
      modifiedAt: newModifiedAt,
    };

    const isDataMutationObjWithInfo = !!Object.keys(dataMutationObj).length;
    const isStateAlreadyUpdated = !isDataMutationObjWithInfo || isEqual(updatedFunnelObj, funnelByIdRetrieved);

    const isCreated = !isStateAlreadyUpdated && newFunnelPath === 'new' && funnelIdFromDb && !isUrlChanged;

    if (isCreated) {
      window.history.replaceState(null, '', funnelIdFromDb);
      setIsUrlChanged(true);
      setFunnelPageSource(FUNNEL_PAGE_SOURCES.newAndSaved);
    }

    if (!isStateAlreadyUpdated) setFunnelByIdRetrieved({ ...updatedFunnelObj });
  }, [dataMutationObj]);
};
