const { ORDER_COLUMN_DIRECTIONS } = require('./index');

const ORDER_BY_PEOPLE_ENUM = {
  email: 'EMAIL',
  createdAt: 'CREATED_AT',
};

module.exports = {
  ORDER_BY_PEOPLE_ENUM,
  ORDER_COLUMN_DIRECTIONS,
};
