import PropTypes from 'prop-types';
import Metric from 'src/components/Dashboard/MetricItem/MetricItem';
import { useDashboard } from 'src/contexts/Dashboard';

import { HSpacer } from '../Generic/LayoutUtils/LayoutUtils';

const RevenuePerLeadMetric = ({ value, change, prevValue }) => {
  const { editMode } = useDashboard();

  return (
    <Metric.Root editMode={editMode}>
      <Metric.Top openButton={false}>
        <Metric.Title>Revenue Per Lead</Metric.Title>
        <Metric.Tooltip absolute={false}>
          <div>This is the revenue divided the new leads over the time range.</div>
        </Metric.Tooltip>
      </Metric.Top>
      <Metric.Body>
        <Metric.Value>{value}</Metric.Value>
        <Metric.Change>{change}</Metric.Change>

        <HSpacer />
      </Metric.Body>

      <Metric.MetricSubtitle size="xs">compared to {prevValue}</Metric.MetricSubtitle>
    </Metric.Root>
  );
};

RevenuePerLeadMetric.propTypes = {
  change: PropTypes.any,
  value: PropTypes.any,
  prevValue: PropTypes.any,
};

export default RevenuePerLeadMetric;
