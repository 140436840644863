import PropTypes from 'prop-types';

import { setThousandsComma, formatReportsTimestamp, formatCurrencyAmounts } from 'src/lib/generic/handlers';
import { ProfileActiveCampaignLogo, ProfileStripeLogo } from 'src/components/Generic';
import {
  discoverChargeMetadataSchema,
  discoverInvoiceMetadataSchema,
  discoverCustomerMetadataSchema,
  discoverAddressSchema,
  getChargeMetadataProperties,
  getInvoiceMetadataProperties,
  getCustomerMetadataProperties,
  getAddressProperties,
  formatValues,
} from 'src/components/Generic/Tables/Table/tableUtils';

export const addProfiles = ({ row, url, profiles, columns, columnPosition }) => {
  const { contactId, customerId, email = '' } = row.original;
  const emailQuery = email.split('@').join('%40');
  const uriPath = customerId ? `customers/${customerId}` : `search?query=${emailQuery}`;

  const isEmail = email && email !== '-';

  const buttonLogos = profiles.map((logo) => {
    const profileSettings = {
      ...(logo === 'ac' && {
        link: `https://${url}.activehosted.com/app/contacts/${contactId}`,
        disabled: !contactId,
        element: (
          <ProfileActiveCampaignLogo classNames={{ raw: contactId ? 'hover:opacity-70' : 'hover:opacity-50' }} />
        ),
      }),
      ...(logo === 'stripe' && {
        link: `  https://dashboard.stripe.com/${uriPath}`,
        disabled: !isEmail && !customerId,
        element: <ProfileStripeLogo classNames={{ raw: isEmail ? 'hover:opacity-70' : 'hover:opacity-50' }} />,
      }),
    };

    const { link, element, disabled } = profileSettings;

    const disabledLinkClass = disabled ? 'cursor-default' : '';

    return (
      <button key={logo} type="button" className="first:ml-2 last:-mr-2 w-12">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={`${disabledLinkClass} text-sm block mr-1`}
          href={!disabled ? link : null}
          target="_blank"
          rel="noreferrer"
        >
          {element}
        </a>
      </button>
    );
  });

  return (
    <DefaultCellInRow columns={columns} columnPosition={columnPosition}>
      <div className="w-full flex justify-end mr-0">{buttonLogos}</div>
    </DefaultCellInRow>
  );
};

export const formatLeadsData = ({ rows }) => {
  return rows.map((row) => {
    const { timestamp } = row;

    return {
      ...row,
      profiles: 'Here will come the Logo',
      timestamp: formatReportsTimestamp(timestamp),
    };
  });
};

export const formatCustomerData = ({ rows, defaultCurrency }) => {
  const metaDataSchema = discoverChargeMetadataSchema({ items: rows });
  const invoiceMetadataSchema = discoverInvoiceMetadataSchema({ items: rows });
  const customerMetadataSchema = discoverCustomerMetadataSchema({ items: rows });
  const addressSchema = discoverAddressSchema({ items: rows });

  return rows.map((row) => {
    const { timestamp, amount } = row;

    let metaProperties = {};
    if (row.metadata) {
      metaProperties = getChargeMetadataProperties({ item: row, metaDataSchema });
    }
    let invoiceMetaProperties = {};
    if (row.invoiceMetadata) {
      invoiceMetaProperties = getInvoiceMetadataProperties({ item: row, invoiceMetadataSchema });
    }
    let customerMetaProperties = {};
    if (row.customerMetadata) {
      customerMetaProperties = getCustomerMetadataProperties({ item: row, customerMetadataSchema });
    }
    let addressMetaProperties = {};
    if (row.address) {
      addressMetaProperties = getAddressProperties({ item: row, addressSchema });
    }

    const rowAndMeta = {
      ...row,
      ...formatValues({
        data: {
          ...metaProperties,
          ...invoiceMetaProperties,
          ...customerMetaProperties,
          ...addressMetaProperties,
        },
      }),
    };

    return {
      ...rowAndMeta,
      ...(!row?.stripeProduct && { stripeProduct: '-' }),
      ...(!row?.stripePrice && { stripePrice: '-' }),
      ...(!row?.email && { email: '-' }),
      profiles: 'Here will come the Logo',
      timestamp: formatReportsTimestamp(timestamp),
      amount: formatCurrencyAmounts({ amount: amount / 100, defaultCurrency }),
    };
  });
};

export const formatAutomationData = ({ rows }) => {
  return rows.map((row) => {
    const { numberOfLeads, uniqueOpens } = row;

    return {
      ...row,
      numberOfLeads: setThousandsComma(numberOfLeads),
      uniqueOpens: setThousandsComma(uniqueOpens),
    };
  });
};

export function DefaultCellInRow({ children }) {
  return <>{children}</>;
}

DefaultCellInRow.propTypes = {
  children: PropTypes.any,
};
