/* eslint-disable no-undef */
import PropTypes from 'prop-types';

export const QUERY = gql`
  query FindAccountById($id: String!) {
    account: account(id: $id) {
      id
      adminUser {
        email
      }
    }
  }
`;

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'network-only' };
};

export const Loading = () => <>Loading...</>;

export const Empty = () => <>Empty</>;

export const Failure = ({ error }) => <div style={{ color: 'red' }}>Error: {error.message}</div>;

Failure.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.any,
  }),
};

export const Success = ({ account }) => {
  return <>{account?.adminUser?.email}</>;
};

Success.propTypes = {
  account: PropTypes.shape({
    adminUser: PropTypes.shape({
      email: PropTypes.any,
    }),
  }),
};
