import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactFlowProvider } from 'react-flow-renderer';
import classnames from 'classnames';
import isEqual from 'lodash.isequal';

import usePressOutsideContainer from 'src/customHooks/usePressOutsideContainer';

import { Flex, HelperText, HSpacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { LoadingComponent } from 'src/components/Generic';
import SearchItemsDialog from 'src/components/SearchItems/SearchItemsDialog/SearchItemsDialog';
import { RightPane, RightPaneHeader } from 'src/components/Funnel/FunnelRightPane/FunnelRightPane';
import useClientRect from 'src/customHooks/useClientRect';

import TagPricesBox from 'src/contexts/SegmentBuilder/TagPricesBox';
import { DEFAULT_INITIAL_SEGMENT_ELEMENTS } from 'src/lib/segmentBuilder/initialSegmentElements';
import { useSegmentBuilder } from './SegmentBuilder';
import SegmentBuilderComponent from './SegmentBuilderComponent';
import SearchItems from './SegmentBuilderSearchItems';

const searchTableModalClass = 'segmentSearchDialog';
const ariaContainerId = `aria-segmentBuilderSplitPaneContainer`;

export default function SegmentBuilderContainer() {
  const segmentBuilderRef = useRef();
  const [tagPricesContainerRect, tagPricesContainerRef] = useClientRect();
  const {
    isRightPaneOpen,
    segmentElements,
    getNodeElements,
    isDialogOpen,
    isTagPrices,
    onDialogClose,
    onDialogDismiss,
    setSegmentElements,
    segmentBuilderElementClickedId,
    onRightPaneClose,
    loadingSegmentsForSegmentBuilder,
  } = useSegmentBuilder();
  // For calculating height of dropdown absolute Div and be scrollable if necessary

  useEffect(() => {
    if (!isRightPaneOpen) return;
    const mainContainerYPosition = segmentBuilderRef?.current.getBoundingClientRect().top;
    const tagPricesBoxHeight = tagPricesContainerRect?.height || 0;

    const footerHeight = 102;
    const segmentBuilderHeight = window.innerHeight - mainContainerYPosition - footerHeight - tagPricesBoxHeight;
    const someBottomPadding = 8;

    segmentBuilderRef.current.style.height = `${segmentBuilderHeight - someBottomPadding}px`;
  });

  // For avoiding React Flow console warning. The height is being changed in useEffect
  const styleForAvoidingConsoleWarn = { height: '100%' };

  const segmentBuilderProps = {
    segmentElements,
    getNodeElements,
  };

  if (isRightPaneOpen) {
    return (
      <>
        <RightPane>
          <RightPaneHeader onCrossButtonClick={onRightPaneClose}>
            <SegmentBuilderHeader>
              <span className="text-base text-wmxText-200 px-2">Segment</span>
              <SavingInput />
            </SegmentBuilderHeader>
          </RightPaneHeader>
          <ReactFlowProvider>
            <div className="w-full h-full px-8 pt-2">
              <div className="SegmentBuilderWrap z-10" ref={segmentBuilderRef} style={styleForAvoidingConsoleWarn}>
                {(loadingSegmentsForSegmentBuilder && <LoadingComponent />) || (
                  <>
                    <SegmentBuilderComponent {...segmentBuilderProps} />
                    {isTagPrices && (
                      <TagPricesBox
                        {...{ containerRef: tagPricesContainerRef, containerRect: tagPricesContainerRect }}
                      />
                    )}
                    <SegmentBuilderFooter />
                  </>
                )}
              </div>
            </div>
          </ReactFlowProvider>
        </RightPane>
        {isDialogOpen && (
          <SearchItemsDialog
            onClose={onDialogClose}
            ariaParentId={ariaContainerId}
            modalClass={searchTableModalClass}
            onDismiss={onDialogDismiss}
          >
            <SearchItems
              closeDialog={onDialogClose}
              onDismiss={onDialogDismiss}
              segmentElements={segmentElements}
              setSegmentElements={setSegmentElements}
              segmentBuilderElementClickedId={segmentBuilderElementClickedId}
            />
          </SearchItemsDialog>
        )}
      </>
    );
  }

  return <></>;
}

/* ------------------------------------------------------------------------------------------------
 * Children components
 * -----------------------------------------------------------------------------------------------*/

function SegmentBuilderHeader({ children }) {
  return (
    <Flex col items="start">
      {children}
    </Flex>
  );
}

SegmentBuilderHeader.propTypes = {
  children: PropTypes.any,
};

const SavingInput = () => {
  const [isInputClicked, setIsInputClicked] = useState(false);
  const { useSegmentNameUpdate, errorSavingSegment, loadingSegmentsForSegmentBuilder } = useSegmentBuilder();
  const { segmentName, onInputChange, inputSize } = useSegmentNameUpdate();
  const inputRef = useRef();
  useEffect(() => {
    if (isInputClicked && inputRef) {
      inputRef.current.focus();
    }
  }, [isInputClicked, inputRef]);

  usePressOutsideContainer({
    closeContainerHandler: () => setIsInputClicked(false),
    containerRef: inputRef,
  });

  const onSubmitName = (e) => {
    e.preventDefault();
    setIsInputClicked(false);
  };
  const inputNameClickHandler = () => {
    setIsInputClicked(true);
  };

  if (loadingSegmentsForSegmentBuilder) return <></>;

  return (
    <>
      <Flex items="center">
        {((isInputClicked || errorSavingSegment) && (
          // onSubmit avoids the website to refresh. Autocomplete that password managers doesn't detect it
          <form className="flex items-center" autoComplete="off" onSubmit={onSubmitName}>
            <input
              onChange={onInputChange}
              size={inputSize}
              // This is not good for accesibility
              className={classnames(
                'bg-transparent focus:outline-none text-sm bg-wmxBgDark-300 border border-wmxBgDark-200 px-2 py-1 rounded-sm',
                {
                  'text-red-400': errorSavingSegment?.message,
                  'text-wmxText-200': !errorSavingSegment?.message,
                }
              )}
              placeholder="Give me a name"
              value={segmentName || ''}
              ref={inputRef}
            />
          </form>
        )) || (
          <button
            type="button"
            onClick={inputNameClickHandler}
            className={classnames('bg-transparent focus:outline-none text-sm px-2 py-1 border border-transparent', {
              'text-red-400': errorSavingSegment?.message,
              'text-wmxText-200': !errorSavingSegment?.message,
            })}
          >
            {segmentName || 'Give me a name'}
          </button>
        )}
        <div className="pl-4">
          {errorSavingSegment?.message && <HelperText variant="alert">{errorSavingSegment.message}</HelperText>}
        </div>
      </Flex>
    </>
  );
};

export function SegmentBuilderFooter({ containerRef, children }) {
  const {
    segmentElements,
    customPricesByTag,
    savingSegment,
    isReadyToSave,
    saveSegment,
    errorSavingSegment,
    updateTagsAmount,
  } = useSegmentBuilder();

  const onSaveClick = () => {
    saveSegment();
    updateTagsAmount({ variables: { customPricesByTag } });
  };

  const isSegmentEmpty = !segmentElements.length || isEqual(segmentElements, DEFAULT_INITIAL_SEGMENT_ELEMENTS);

  return (
    <div ref={containerRef} className="flex flex-row justify-between py-4 px-4 w-full">
      <div className="flex flex-col">
        {children && (
          <>
            <span className="text-white text-3xl font-bold">{children}</span>
            <span className="text-wmxText-200 text-xl">people</span>
          </>
        )}
      </div>
      <Flex justify="end">
        {errorSavingSegment?.message && (
          <>
            <HelperText variant="alert">{errorSavingSegment.message}</HelperText>
            <HSpacer />
          </>
        )}
        <div className="flex flex-col justify-center">
          <button
            type="button"
            disabled={!isReadyToSave || savingSegment || isSegmentEmpty}
            className="text-wmxText-100 px-8 py-2 bg-wmxPrimary-200 text-lg rounded-lg hover:bg-wmxPrimary-100 disabled:opacity-60"
            onClick={onSaveClick}
          >
            {(savingSegment && 'Saving...') || 'Save'}
          </button>
        </div>
      </Flex>
    </div>
  );
}
/* ------------------------------------------------------------------------------------------------
 * Prop Types
 * -----------------------------------------------------------------------------------------------*/

SegmentBuilderContainer.propTypes = {
  closeRightPane: PropTypes.func,
  onDoneClick: PropTypes.func,
  name: PropTypes.string,
  children: PropTypes.any,
  // eslint-disable-next-line react/no-unused-prop-types
  funnelPartName: PropTypes.string,
  renderPeopleCount: PropTypes.func,
  isReadyToSave: PropTypes.bool,
};

SegmentBuilderFooter.propTypes = {
  containerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
