import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { routes, navigate } from '@redwoodjs/router';
import { useRecoilValue } from 'recoil';

import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';

import { pretiffyLastUpdate } from 'src/lib/datetimeFormat/prettifyLastUpdate';

import useLocalStorage from 'src/customHooks/useLocalStorage';
import { useGetTableRows } from 'src/components/Funnel/FunnelManagementList/funnelListHooks';

import FunnelLayoutRoute from 'src/layouts/FunnelLayoutRoute/FunnelLayoutRoute';
import AppLayout from 'src/layouts/AppLayout/AppLayout';
import MainHeaderLayout from 'src/layouts/MainHeaderLayout/MainHeaderLayout';
import MainCardLayout from 'src/layouts/MainCardLayout/MainCardLayout';
import MainContainerLayout from 'src/layouts/MainContainerLayout/MainContainerLayout';
import { DatePicker, LoadingComponent } from 'src/components/Generic';
import { useRestartFunnelToDefaultValues, useFunnelData, FUNNEL_PAGE_SOURCES } from 'src/components/Funnel/funnelHooks';

export default function FunnelManagementLayout({ children, mainCardRef, prerender, mainCardClassNames }) {
  const appLayoutProps = {
    isHeader: false,
    prerender,
  };

  const appLayoutChildren = (
    <MainContainerLayout>
      {prerender ? <FunnelManagementHeaderForPrerender /> : <FunnelManagementHeader />}
      <MainCardLayout classNames={mainCardClassNames} containerRef={mainCardRef}>
        {children}
      </MainCardLayout>
    </MainContainerLayout>
  );

  return <AppLayout {...appLayoutProps}>{appLayoutChildren}</AppLayout>;
}

const FunnelManagementHeader = () => {
  const { setFunnelPageSource } = useFunnelData();
  const {
    tableRows: [tableRows = []],
  } = useGetTableRows();

  const { resetFunnel: resetFunnelToDefaultValues } = useRestartFunnelToDefaultValues();

  /*
    Everytime that someone reaches the funnelManagemnent list, we make sure that FunnelGlobal
    states are clean. This should only run the first render, because the dep shouldn't change
  */

  useEffect(() => {
    resetFunnelToDefaultValues();
  }, [resetFunnelToDefaultValues]);

  const onNewFunnelButtonClick = () => {
    setFunnelPageSource(FUNNEL_PAGE_SOURCES.new);
    navigate(routes.newFunnelPage());
  };

  const areRowsStillLoading = tableRows.some(({ isLoading }) => isLoading);

  const { lastFullTimestampUpdate } = useUpdateLastTimestamp({ areRowsStillLoading });

  const headerProps = {
    onNewFunnelButtonClick,
    areRowsStillLoading,
    lastFullTimestampUpdate,
  };

  return <FunnelManagementHeaderForPrerender {...headerProps} />;
};

function FunnelManagementHeaderForPrerender({ onNewFunnelButtonClick, areRowsStillLoading, lastFullTimestampUpdate }) {
  const lastUpdated = pretiffyLastUpdate(lastFullTimestampUpdate);
  const isUpdatedOnlySecsAgo = lastUpdated.includes('sec');

  return (
    <>
      <div className="flex flex-row justify-between mr-8 ml-4 mt-2 mb-4">
        <FunnelLayoutRoute />
        <div className="bg-wmxSecondary-200 rounded-md hover:bg-wmxSecondary-100">
          <button type="button" className="text-gray-50 px-4 py-1" onClick={onNewFunnelButtonClick}>
            <div className="flex items-center">
              <span className="text-2xl pr-3 plusIcon pointer-events-auto text-wmxText-200 font-bold" />
              <span className="font-bold">Track new funnel</span>
            </div>
          </button>
        </div>
      </div>
      <MainHeaderLayout>
        <div className="w-full flex flex-row items-center mb-0 sticky z-30 flex-grow-0">
          <div className="flex justify-start">
            <div className="mr-4">
              <DatePicker open placement="bottomStart" />
            </div>
            {areRowsStillLoading && (
              <div className="flex items-center">
                <LoadingComponent />
              </div>
            )}
            {lastUpdated && !isUpdatedOnlySecsAgo && (
              <span className="flex items-center text-white text-xs px-4">Last updated: {lastUpdated} ago</span>
            )}
          </div>
        </div>
      </MainHeaderLayout>
    </>
  );
}

FunnelManagementLayout.propTypes = {
  children: PropTypes.any,
  mainCardRef: PropTypes.any,
  prerender: PropTypes.bool,
  mainCardClassNames: PropTypes.object,
};

FunnelManagementHeaderForPrerender.propTypes = {
  onNewFunnelButtonClick: PropTypes.func,
  areRowsStillLoading: PropTypes.bool,
  lastFullTimestampUpdate: PropTypes.string,
};

function useUpdateLastTimestamp({ areRowsStillLoading }) {
  const { label: timeRangeLabel } = useRecoilValue(RecoilTimeRange);
  const [lastSessionMetrics = {}] = useLocalStorage('funnels');

  const cacheInTimeRange = lastSessionMetrics?.[timeRangeLabel];

  const [lastFullTimestampUpdate, setLastFullTimestampUpdate] = useState(cacheInTimeRange?.lastSession);
  const [shouldLastFullTimestampUpdate, setShouldLastFullTimestampUpdate] = useState(false);
  const [currentTimeRangeLabel, setCurrentTimeRangeLabel] = useState(false);

  useEffect(() => {
    if (!areRowsStillLoading && shouldLastFullTimestampUpdate) {
      setLastFullTimestampUpdate(cacheInTimeRange?.lastSession);
      setShouldLastFullTimestampUpdate(false);
    }
  }, [cacheInTimeRange, lastFullTimestampUpdate, areRowsStillLoading, shouldLastFullTimestampUpdate]);

  useEffect(() => {
    if (areRowsStillLoading && !shouldLastFullTimestampUpdate) {
      setShouldLastFullTimestampUpdate(true);
    }
  }, [areRowsStillLoading, shouldLastFullTimestampUpdate]);

  useEffect(() => {
    if (currentTimeRangeLabel !== timeRangeLabel) {
      setLastFullTimestampUpdate(cacheInTimeRange?.lastSession);
      setCurrentTimeRangeLabel(timeRangeLabel);
    }
  }, [timeRangeLabel, cacheInTimeRange, currentTimeRangeLabel]);

  return {
    lastFullTimestampUpdate,
  };
}
