import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { navigate, routes } from '@redwoodjs/router';

import { useDashboard } from 'src/contexts/Dashboard';
import { useTimeSeries } from 'src/lib/graphsHelpers/graphsHelpers';

import Metric from 'src/components/Dashboard/MetricItem/MetricItem';
import RevenueChartCell from 'src/components/Cells/RevenueChartCell/RevenueChartCell';
import { DefinePeopleErrorMessage } from 'src/layouts/PeopleLayout/PeopleLayout';
import { Spacer } from '../Generic/LayoutUtils/LayoutUtils';

const RevenueMetric = ({ value, change, prevValue }) => {
  const boxRef = useRef();

  const { timeRange, timeRangeLabel, editMode } = useDashboard();
  const { getTimeSerie } = useTimeSeries();
  const timeseries = useMemo(() => getTimeSerie(timeRange, timeRangeLabel), [timeRange, getTimeSerie, timeRangeLabel]);

  const boxWidth = boxRef?.current?.getBoundingClientRect().width;
  const boxHeight = boxRef?.current?.getBoundingClientRect().height;

  const tooltipText = `This includes total cash received from paid invoices, from subscriptions and one-time charges. Neither
  refunds nor credit card/transaction fees are deducted.`;

  return (
    <RevenueMetricLayout {...{ boxRef, tooltipText }}>
      <Metric.Body>
        <Metric.Value>{value}</Metric.Value>
        <Metric.Change>{change}</Metric.Change>
      </Metric.Body>
      <Metric.MetricSubtitle size="xs">compared to {prevValue}</Metric.MetricSubtitle>
      {boxHeight > 150 && !editMode ? (
        <>
          <Spacer size="xs" />
          <Metric.MetricChart>
            <RevenueChartCell timeSeries={timeseries} boxHeight={boxHeight} boxWidth={boxWidth} />
          </Metric.MetricChart>
        </>
      ) : (
        <></>
      )}
    </RevenueMetricLayout>
  );
};

function RevenueMetricLayout({ children, boxRef, tooltipText }) {
  const { editMode } = useDashboard();

  return (
    <div ref={boxRef} className="h-full w-full">
      <Metric.Root editMode={editMode}>
        <Metric.Top openButton={false}>
          <Metric.Title>Gross Cash Flow</Metric.Title>
          <Metric.Tooltip absolute={false}>
            <div>{tooltipText}</div>
          </Metric.Tooltip>
        </Metric.Top>
        {children}
      </Metric.Root>
    </div>
  );
}

export function WithoutTagsPricesDefined() {
  const tooltipText = 'To calculate the revenue, please define the prices of each tag in your customer segment';

  return (
    <RevenueMetricLayout {...{ tooltipText }}>
      <Metric.Value>
        <DefineCustomerSegmentMessage onClick={() => navigate(routes.peopleCustomersEdit())} />
      </Metric.Value>
    </RevenueMetricLayout>
  );
}

function DefineCustomerSegmentMessage({ onClick }) {
  return <DefinePeopleErrorMessage onClick={onClick} text="to start tracking your revenue." />;
}

RevenueMetric.propTypes = {
  change: PropTypes.any,
  value: PropTypes.any,
  prevValue: PropTypes.any,
};

RevenueMetricLayout.propTypes = {
  children: PropTypes.any,
  boxRef: PropTypes.any,
  tooltipText: PropTypes.string,
};

DefineCustomerSegmentMessage.propTypes = {
  onClick: PropTypes.func,
};

export default RevenueMetric;
