/* eslint-disable camelcase */

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import getObjectPath from 'lodash.get';
import isEqual from 'lodash.isequal';
import uniqBy from 'lodash.uniqBy';
import { routes, navigate } from '@redwoodjs/router';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';

import useInfiniteScroll from 'src/customHooks/useInfiniteScroll';
import useUpdateMetricsInLocalCache from 'src/customHooks/useUpdateMetricsInLocalCache';
import useTableHeight from 'src/customHooks/useTableHeight';
import withApolloQuery from 'src/lib/HOC/withApolloQuery';
import GetFunnelListMetricsCell from 'src/components/Cells/getFunnelListMetricsCell/getFunnelListMetricsCell';

import { SEGMENT_TRACK_EVENTS_NAMES, triggerTrackEvent } from 'src/lib/segmentJuneEvents/segmentJuneEvents';

import {
  funnelByIdRetrieved as RecoilFunnelByIdRetrieved,
  funnelPageSource as RecoilFunnelPageSource,
  FUNNEL_PAGE_SOURCES,
} from 'src/atoms/funnelAtoms';
import { tableRows as RecoilTableRows, globalDataChunk as RecoilGlobalDataChunk } from 'src/atoms/funnelListAtoms';
import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';
import { useGetTableRows } from 'src/components/Funnel/FunnelManagementList/funnelListHooks';

import FunnelListMutationsPopover from 'src/components/Funnel/FunnelManagementList/FunnelListMutationsPopover/FunnelListMutationsPopover';
import {
  transformTimeRangeToDate,
  container_marginTop,
  table_paddingBottom,
  headers_height,
  headers_paddingTop,
  headers_paddingBottom,
  cells_height,
  cells_paddingTop,
  cells_paddingBottom,
} from 'src/components/Funnel/FunnelManagementList/listHelpers';
import { getLastTwClassNameValue } from 'src/lib/generic/handlers';
import { EmptyComponent, Table } from 'src/components/Generic';
import './funnelManagementList.css';

/**
 * This component has a particularity with renders, because is being called by LoadingComponent
 * and showing localCacheData when waiting for the network answer, and then, by SuccessComponent
 * when the data has arrived.
 * It looks on the first glance, that it is only one lifecycle, but when the LoadingComponent is unmounted
 * this component is as well, and it starts new from SuccessComponent
 * localCacheData only comes in when the LoadingComponent is being rendered.
 */
export function FunnelManagementList({ queryParams, data, dataPath, fetchMore, emptyComponentText, localCacheData }) {
  const twMarginTopNum = getLastTwClassNameValue(container_marginTop);

  const setFunnelByIdRetrieved = useSetRecoilState(RecoilFunnelByIdRetrieved);
  const setFunnelPageSource = useSetRecoilState(RecoilFunnelPageSource);
  const { tableHeight, mainTableContainerRef } = useTableHeight({ twMarginTopNum });
  const scrollLength = queryParams?.variables?.limit;

  const { hasMoreData, dataChunk, fetchMoreData } = useInfiniteScroll({
    scrollLength,
    incomingData: {
      dataArray: getObjectPath(data, dataPath),
      path: dataPath,
    },
    fetchMore,
    fetchMoreParams: queryParams,
  });

  const { tableRows, isLocalCacheData } = useUpdateTableRows({
    dataChunk,
    localCacheData,
    limit: scrollLength,
  });

  const getTableProps = () => {
    const columns = [
      {
        accessor: 'name',
        Header: 'Name',
      },
      {
        accessor: 'revenue',
        Header: 'Revenue',
      },
      {
        accessor: 'numberOfCustomers',
        Header: 'Customers',
      },
      {
        accessor: 'numberOfLeads',
        Header: 'People',
      },
      {
        accessor: 'settings',
        Header: '',
      },
    ];

    const classNames = {
      container: `funnelList align-middle block max-w-full bg-transparent rounded-bl-lg rounded-br-lg mr-1 flex-grow ${container_marginTop}`,
      table: `w-full pl-6 pr-6 ${table_paddingBottom} table-fixed`,
      body: {
        tbody: 'bg-wmxBgDark-400 pl-8 overflow-auto',
        bodyRow: `z-0 rounded-md shadow-2xl cursor-pointer bg-wmxHighlightDark-100 hover:bg-opacity-70 hover:bg-wmxHighlightDark-200 `,
        bodyCell: `${cells_height} text-left text-white pl-8 pr-4 ${cells_paddingTop} ${cells_paddingBottom} first:rounded-l-md last:rounded-r-md`,
      },
      header: {
        headerCell: `z-10 text-left ${headers_height} pl-6 pr-2 ${headers_paddingTop} ${headers_paddingBottom} border-t-0 leading-4 text-white tracking-wider bg-wmxBgDark-200 text-xs font-normal first:w-5/12 last:w-1/12`,
      },
    };

    const navigateToFunnel = ({ row }) => {
      const {
        original: { funnelItems, id: funnelId, name: funnelName, modifiedAt },
      } = row;

      setFunnelByIdRetrieved({
        funnelId,
        funnelName,
        funnelItemsFromDb: transformTimeRangeToDate(funnelItems),
        modifiedAt,
      });

      setFunnelPageSource(FUNNEL_PAGE_SOURCES.clickedInList);

      triggerTrackEvent({ eventName: SEGMENT_TRACK_EVENTS_NAMES.funnelAnalysed, payload: { funnelId, funnelName } });

      navigate(routes.funnelPageById({ funnelId }));
    };

    const extendRows = {
      columns: [
        {
          accessor: columns.length - 1,
          cellChildren: (params) => {
            const {
              row,
              row: {
                id: funnelIdxInList,
                original: { funnelItems, name: funnelName, id: funnelId },
              },
            } = params;

            const tooltipProps = {
              funnelId,
              funnelName,
              funnelItemsToDb: funnelItems,
              funnelIdxInList: parseInt(funnelIdxInList, 10),
              navigateToFunnel: () => navigateToFunnel({ row }),
            };

            return <FunnelListMutationsPopover {...tooltipProps} />;
          },
        },
      ],
      getRowClassNames: ({ row }) => {
        const {
          original: { isDuplicatingLoading = null },
        } = row || {};

        return isDuplicatingLoading ? 'opacity-50' : '';
      },
    };

    return {
      name: 'funnelManagementList',
      hasMoreData,
      setNextChunk: fetchMoreData,
      data: isLocalCacheData ? localCacheData : tableRows,
      columns,
      height: tableHeight,
      mainContainerRef: mainTableContainerRef,
      extendRows,
      classNames,
      onRowClick: navigateToFunnel,
    };
  };

  const tableProps = getTableProps();
  const tableAndRowProps = {
    tableProps,
  };

  return (tableRows && tableRows.length) || (isLocalCacheData && localCacheData.length) ? (
    <TableAndRowsQueries {...tableAndRowProps} localCacheData={localCacheData} />
  ) : (
    <EmptyComponent text={emptyComponentText} />
  );
}

export default withApolloQuery(FunnelManagementList);

function TableAndRowsQueries({ tableProps, localCacheData }) {
  const { timeRange } = useRecoilValue(RecoilTimeRange);
  const {
    tableRows: [tableRows],
  } = useGetTableRows();

  const rowsCells = tableRows.map(({ funnelItems, id }, rowIndex) => {
    return (
      <GetFunnelListMetricsCell
        key={id}
        funnelItemsFromDb={funnelItems}
        funnelId={id}
        timeRange={timeRange}
        withFunnelItemsFromDbCalculated
        withRevenue
        withOpens={false}
        withSquares={false}
        rowIndex={rowIndex}
      />
    );
  });

  return (
    <>
      <Table {...tableProps} />
      {!localCacheData && rowsCells}
    </>
  );
}
FunnelManagementList.propTypes = {
  dataPath: PropTypes.string,
  fetchMore: PropTypes.func,
  emptyComponentText: PropTypes.string,
  data: PropTypes.shape({
    getFunnelReportsByAccount: PropTypes.array,
  }),
  localCacheData: PropTypes.array, // like tableRows array
  queryParams: PropTypes.shape({
    fetchPolicy: PropTypes.string,
    variables: PropTypes.shape({
      offset: PropTypes.number,
      limit: PropTypes.number,
      timeRange: PropTypes.shape({
        start: PropTypes.object,
        end: PropTypes.object,
      }),
    }), // Going directly to withApolloQuery
  }),
};

TableAndRowsQueries.propTypes = {
  tableProps: PropTypes.any,
  tableRows: PropTypes.array,
  setTableRows: PropTypes.func,
  localCacheData: PropTypes.array,
};

/*
  This hook should cover the cases in which we update the list and this happens:
  - The timeRangeLabel changes
  - Scroll down in the funnel
  - Open Funnel list from another page/view
*/

/*
  We leave tableRows without a default array as initializer (tableRows = []) beacuse we want
  to know when tableRows is null. This means that we are in the first app render, and we want
  to diferentiate it from other states
*/

/*
  tableRows and dataChunk will differ in four different scenarios
  - dataChunk arrived and changed the localCache values of tableRows
  - dataChunk arrived with more paginated dat, and has more items than tableRows
  - the user deleted or duplicated one row and tableRows has one more or one less than dataChunk
 */

export function useUpdateTableRows({ dataChunk, localCacheData, limit }) {
  const [tableRows, setTableRows] = useRecoilState(RecoilTableRows);
  const [globalDataChunk, setGlobalDataChunk] = useRecoilState(RecoilGlobalDataChunk);
  const {
    deletedRows: [deletedTableRows],
  } = useGetTableRows();
  const [isLocalCacheAlreadyUpdated, setIsLocalCacheAlreadyUpdated] = useState(false);

  const hasDataChunkChanged = !isEqual(dataChunk, globalDataChunk);

  const isFirstAppRender = tableRows === null;

  const useUpdateDataChunkClone = () => {
    useEffect(() => {
      if (hasDataChunkChanged) {
        setGlobalDataChunk(dataChunk);
      }
    });
  };

  const useUpdateTableRowsWithLocalCacheWhileLoadingComponent = () => {
    useEffect(() => {
      if (!localCacheData || isLocalCacheAlreadyUpdated) return;

      const shouldUpdateWithLocalCache = !!localCacheData.length;
      const isListUpdatedWithoutCache = !localCacheData.length && !isFirstAppRender && !!tableRows.length;

      if (shouldUpdateWithLocalCache) {
        setIsLocalCacheAlreadyUpdated(true);

        const localCacheRows = localCacheData.map((localCacheRow) => {
          return {
            ...localCacheRow,
            isLoading: true,
          };
        });

        setTableRows(localCacheRows);
      }

      if (isListUpdatedWithoutCache) {
        const newEmptyRows = tableRows.map((row) => {
          return {
            ...row,
            numberOfLeads: '-',
            numberOfCustomers: '-',
            revenue: '-',
            isLoading: true,
          };
        });

        setIsLocalCacheAlreadyUpdated(true);
        setTableRows(newEmptyRows);
      }
    });
  };

  const useUpdateTableRowsWhenDataHasArrivedWithoutLocalCache = () => {
    useEffect(() => {
      if (localCacheData) return;

      const getRowInTableRowsFromDataChunk = (arr, row) => {
        const funnelIdxInTableRows = !isFirstAppRender ? arr.findIndex(({ id: funnelId }) => funnelId === row.id) : -1;

        return funnelIdxInTableRows !== -1 ? tableRows[funnelIdxInTableRows] : null;
      };

      const removeDeletedRows = (refreshedData) => {
        return refreshedData.reduce((accum, row) => {
          const rowInTableRows = getRowInTableRowsFromDataChunk(deletedTableRows, row);

          if (rowInTableRows) return accum;

          accum.push(row);

          return accum;
        }, []);
      };

      const addDuplicatedRows = (refreshedData) => {
        const clonedRefreshedData = refreshedData.slice();

        const duplicatedRows = !isFirstAppRender ? tableRows.filter(({ isDuplicated }) => isDuplicated) : [];
        duplicatedRows.forEach((row) => {
          const isInDataChunk = refreshedData.find(({ id: funnelId }) => funnelId === row.id);

          if (!isInDataChunk) {
            const indexInTableRows = tableRows.findIndex(({ id: funnelId }) => funnelId === row.id);
            clonedRefreshedData.splice(indexInTableRows, 0, row);
          }
        });

        return clonedRefreshedData;
      };

      if (hasDataChunkChanged) {
        const funnelListRefreshedData = dataChunk.map((row) => {
          const rowInTableRows = getRowInTableRowsFromDataChunk(tableRows, row);

          return {
            ...row,
            numberOfLeads: (rowInTableRows && rowInTableRows?.numberOfLeads) || '-',
            numberOfCustomers: (rowInTableRows && rowInTableRows?.numberOfCustomers) || '-',
            revenue: (rowInTableRows && rowInTableRows?.revenue) || '-',
            isLoading: true,
          };
        });

        // This happens when the database list changed because of adding funnel copies
        // In the beginning the offset 5 was Funnel X that after adding 2 funnel copies
        // is going to be in offset 7 and retrieved again in datachunk
        const removeDatachunkDuplicates = uniqBy(funnelListRefreshedData, 'id');
        const removedRows = removeDeletedRows(removeDatachunkDuplicates);
        const withDuplicatedRows = addDuplicatedRows(removedRows);

        setTableRows(withDuplicatedRows);
      }
    });
  };

  useUpdateDataChunkClone();
  useUpdateTableRowsWithLocalCacheWhileLoadingComponent();
  useUpdateTableRowsWhenDataHasArrivedWithoutLocalCache();

  useUpdateMetricsInLocalCache({
    networkMetrics: !isFirstAppRender ? { tableRows } : null,
    storageKey: 'funnels',
    additionals: { isPagination: !isFirstAppRender && tableRows.length > limit },
  });

  return {
    tableRows,
    dataChunk,
    isLocalCacheData: localCacheData && localCacheData.length,
  };
}
