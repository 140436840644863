import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { Redirect, routes, useLocation } from '@redwoodjs/router';

import { PROVIDERS } from 'wmx-shared-code/accountGlobalVariables';
import useCurrentUser from 'src/customHooks/useCurrentUser';
import useLocalStorage from 'src/customHooks/useLocalStorage';
import { useUpdateSingleMetric } from 'src/customHooks/useUpdateMetricsInLocalCache';
import {
  metricsKey,
  useDatapointsOverview,
  useLastSyncsProvider,
  useLastDailyResync,
  useSearchBox,
} from 'src/components/IntegrationTopic/integrationContextHooks';

import { ProfileActiveCampaignLogo, ProfileStripeLogo, LoadingComponent } from 'src/components/Generic';
import { useIntegrationMutations } from 'src/components/IntegrationTopic/integrationQueries';

export { metricsKey };
const {
  datapointsOverviewTable: datapointsOverviewTableKey,
  lastSyncsByProvider: lastSyncsByProviderKey,
  lastDailyResync: lastDailyResyncByProviderKey,
} = metricsKey;

const IntegrationContext = createContext(null);
const STORAGE_KEY = 'integrations-datapoints-overview';

export default function IntegrationsProvider({ children, urlProps }) {
  const {
    provider: currentProviderShown,
    datapointURLParam,
    isURLWithIntegrationsSettings,
    isNotAllowedURL,
  } = useHandleURLParams({ urlProps });

  const { acUrl, baseAcUrl } = useCurrentUser();

  const [currentDatapointRecordsNames, setCurrentDatapointRecordsNames] = useState({
    URLName: datapointURLParam,
    datapointName: null,
  });

  const {
    [datapointsOverviewTableKey]: datapointsOverviewConfig,
    value: datapointsOverview,
    datapointResyncNames,
    datapointURLNames,
    datapointTotals,
  } = useDatapointsOverview({ currentProviderShown });

  const { searchBoxKeyword, onSearchBoxChange } = useSearchBox();

  const { [lastSyncsByProviderKey]: lastSyncsByProviderConfig, value: lastSyncsByProvider } = useLastSyncsProvider();

  const { [lastDailyResyncByProviderKey]: lastDailyResyncByProviderConfig, value: lastDailyResyncByProvider } =
    useLastDailyResync();

  const { disconnectAcIntegration, disconnectStripeIntegration, resyncAc, isAnyMutationLoading } =
    useIntegrationMutations();

  if (isNotAllowedURL) return <Redirect to={routes.integrations()} />;

  const settingsByProvider = {
    [PROVIDERS.activeCampaign]: {
      name: 'ActiveCampaign',
      logo: <ProfileActiveCampaignLogo />,
      title: 'ActiveCampaign',
      subtitle:
        'ActiveCampaign gives you the email marketing, marketing automation, and CRM tools you need to create incredible customer experiences.',
      url: acUrl,
      dashboardHref: `https://${baseAcUrl}.activehosted.com/overview/`,
      deleteIntegration: disconnectAcIntegration,
      resyncIntegration: resyncAc,
      datapointsOverview: datapointsOverview?.activecampaign,
      datapointResyncNames: datapointResyncNames?.activecampaign,
      datapointURLNames: datapointURLNames?.activecampaign,
      datapointTotals: datapointTotals?.activecampaign,
      isAnyMutationLoading,
      ...lastSyncsByProvider?.activecampaign,
      ...lastDailyResyncByProvider?.activecampaign,
    },
    [PROVIDERS.stripe]: {
      name: 'Stripe',
      logo: <ProfileStripeLogo />,
      title: 'Stripe',
      subtitle: '',
      deleteIntegration: disconnectStripeIntegration,
      datapointsOverview: datapointsOverview?.stripe,
      datapointResyncNames: datapointResyncNames?.stripe,
      datapointURLNames: datapointURLNames?.stripe,
      datapointTotals: datapointTotals?.stripe,
      isAnyMutationLoading,
      ...lastSyncsByProvider?.stripe,
      ...lastDailyResyncByProvider?.stripe,
    },
  };

  const settingsByClickedProvider = settingsByProvider?.[currentProviderShown];

  const isCurrentDatapointRecordsTable =
    currentDatapointRecordsNames && !!Object.values(currentDatapointRecordsNames).filter((value) => !!value).length;

  const integrationsValue = {
    isURLWithIntegrationsSettings,
    isAnyMutationLoading,
    settingsByProvider,
    settingsByClickedProvider,
    searchBoxKeyword,
    onSearchBoxChange,
    currentProviderShown,
    isCurrentDatapointRecordsTable,
    currentDatapointRecordsNames,
    setCurrentDatapointRecordsNames,
    [datapointsOverviewTableKey]: datapointsOverviewConfig,
    [lastSyncsByProviderKey]: lastSyncsByProviderConfig,
    [lastDailyResyncByProviderKey]: lastDailyResyncByProviderConfig,
  };

  return <IntegrationContext.Provider value={{ ...integrationsValue }}>{children}</IntegrationContext.Provider>;
}

IntegrationsProvider.propTypes = {
  children: PropTypes.any,
  urlProps: PropTypes.shape({
    segment: PropTypes.oneOf(['customer-segment']),
    provider: PropTypes.oneOf([...Object.values(PROVIDERS)]),
  }),
};

export const useIntegrationsContext = () => useContext(IntegrationContext);

export function useUpdateContextValues({ metric: metricKey, newValue: newRawValue, shouldUpdateContextValue } = {}) {
  const context = useIntegrationsContext();

  useEffect(() => {
    if (!shouldUpdateContextValue) return;

    const isUpdatableMetric = Object.values(metricsKey).includes(metricKey);

    if (metricKey && !isUpdatableMetric) {
      console.log('The metric key provided to useUpdateContextValues does not exist');
    }

    if (metricKey && isUpdatableMetric) {
      const defaultGetValue = (value) => value;

      const { value: rawValue, get: getValueToCompare = defaultGetValue, set: updateValue } = context[metricKey];
      const value = getValueToCompare(rawValue);
      const newValue = getValueToCompare(newRawValue);

      const isSameValue = isEqual(value, newValue);
      if (isSameValue) return;

      updateValue(newValue);
    }
  }, [context, metricKey, newRawValue, shouldUpdateContextValue]);
}

export function useMetricLoading({ metric, renderComponent, shouldUpdateContextValue }) {
  if (!renderComponent && !shouldUpdateContextValue) {
    throw new Error('This hooks needs a renderComponent function or update the context value to work');
  }

  const [lastSession = {}] = useLocalStorage(STORAGE_KEY);

  const values = lastSession?.[metric];

  const isWithValues = !!values;
  useUpdateContextValues({
    metric,
    newValue: { ...values },
    shouldUpdateContextValue: shouldUpdateContextValue && isWithValues,
  });

  return renderComponent ? renderComponent(values) : <LoadingComponent />;
}

export function useUpdateMetric({ metric, newValue, shouldUpdateContextValue }) {
  useUpdateSingleMetric({
    networkMetric: newValue,
    metricKey: metric,
    storageKey: STORAGE_KEY,
  });

  useUpdateContextValues({ metric, newValue, shouldUpdateContextValue });
}

function useHandleURLParams({ urlProps }) {
  const { provider } = urlProps;
  const { pathname } = useLocation();
  const [, , , firstURLParam, datapointURLParam] = pathname.split('/');

  const allowedURLParams = [...Object.values(PROVIDERS)];

  const isProviderURLParam = Object.values(PROVIDERS).includes(firstURLParam);

  const untilStripeDetailsIsSupported = firstURLParam !== PROVIDERS.stripe;
  const isAllowedURL = allowedURLParams.includes(firstURLParam) && untilStripeDetailsIsSupported;
  const isNotAllowedURL = firstURLParam && !isAllowedURL;

  return {
    isNotAllowedURL,
    isURLWithIntegrationsSettings: !!firstURLParam || !!provider,
    provider: provider || isProviderURLParam ? firstURLParam : null,
    datapointURLParam,
  };
}

const beaconTypes = {
  failedNightlyResync: 'failedNightlyResync',
};

const integrationsBeaconReducer = ({ type, payload }) => {
  const beaconsMessages = {
    [beaconTypes.failedNightlyResync]: window.Beacon('prefill', {
      email: `${payload.email}`,
      subject: 'Data inconsistency spotted',
      text: `Hello, I detected data inconsistency in my account while checking my ActiveCampaign Integrations settings.`,
    }),
  };

  if (beaconsMessages[type]) return console.warn('No Integration Beacon type found');

  window.Beacon('open');
  const BeaconFabButtonFrame = document.querySelector('.BeaconFabButtonFrame');
  BeaconFabButtonFrame.style.visibility = 'visible';

  return beaconsMessages[type];
};

const handleFailedResync = ({ email }) => {
  integrationsBeaconReducer({ type: beaconTypes.failedNightlyResync, payload: { email } });
};

export const useIntegrationsBeacon = () => {
  const { email } = useCurrentUser();

  if (!window.Beacon) return null;

  return {
    handleFailedResync: () => handleFailedResync({ email }),
  };
};
