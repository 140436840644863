/* eslint-disable no-use-before-define */
import PropTypes from 'prop-types';

import MenuLink from 'src/components/MenuLink/MenuLink';
import MenuIcon from 'src/components/MenuIcon/MenuIcon';

import useCurrentUser from 'src/customHooks/useCurrentUser';
import useAccount from 'src/customHooks/useAccount';
import { useSidebar } from 'src/layouts/SidebarLayout/SidebarLayout';
import { useMemo } from 'react';
import { INTEGRATION_STATUSES } from 'wmx-shared-code/accountGlobalVariables';

const Menu = (props) => {
  const { userFunnels: { hasAtLeastOneCreatedFunnel = null } = {} } = useCurrentUser();

  const funnelIconPage = hasAtLeastOneCreatedFunnel ? 'funnelList' : 'newFunnelPage';

  return <MenuContainer {...props} funnelIconPage={funnelIconPage} />;
};

const MenuContainer = ({ funnelIconPage = 'newFunnelPage' }) => {
  const { trial, isTrialExpired, acIntegration: { isReady, status: acStatus } = {} } = useAccount();
  const { collapsedSidebar } = useSidebar();
  const disabled = !isReady || isTrialExpired;

  const isImportingOrImported = useMemo(
    () => acStatus === INTEGRATION_STATUSES.importing || acStatus === INTEGRATION_STATUSES.ready,
    [acStatus]
  );
  return (
    <ul className="list-reset mb-auto mt-8 w-full">
      {(trial || !isReady) && (
        <MenuLink
          icon={ONBOARDING_ICON}
          page={(isImportingOrImported && 'onboardingPageOverview') || 'onboardingPageStep1'}
          label="Onboarding"
          collapsedSidebar={collapsedSidebar}
          disabled={isTrialExpired}
        />
      )}
      <MenuLink
        icon={DASHBOARD_ICON}
        page="dashboard"
        label="Dashboard"
        collapsedSidebar={collapsedSidebar}
        disabled={disabled}
      />
      <MenuLink
        icon={FUNNEL_ICON}
        page={funnelIconPage}
        label="Funnels"
        collapsedSidebar={collapsedSidebar}
        disabled={disabled}
      />
      <MenuLink
        icon={REPORT_ICON}
        page="emailsOverview"
        label="Reports"
        parentMatch="/reports"
        collapsedSidebar={collapsedSidebar}
        disabled={disabled}
      />
      <MenuLink
        icon={PEOPLE_ICON}
        page="peopleLeads"
        label="Segments"
        parentMatch="/segments"
        collapsedSidebar={collapsedSidebar}
        disabled={disabled}
      />
    </ul>
  );
};

MenuContainer.propTypes = {
  funnelIconPage: PropTypes.string,
};

const ONBOARDING_ICON = (
  <MenuIcon
    svg={
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="16" fill="#1C2731" />
        <rect
          width="16.6548"
          height="3.33097"
          rx="1.66548"
          transform="matrix(-0.75741 0.65294 -0.75741 -0.65294 23.567 12.2705)"
          fill="#D1D5DB"
        />
        <rect
          width="9.99291"
          height="3.33097"
          rx="1.66548"
          transform="matrix(0.75741 0.65294 -0.75741 0.65294 16.4312 5.3501)"
          fill="#9CA3AF"
        />
        <rect
          width="9.99291"
          height="3.33097"
          rx="1.66548"
          transform="matrix(-0.75741 -0.65294 0.75741 -0.65294 15.5392 27.6497)"
          fill="#9CA3AF"
        />
      </svg>
    }
  />
);

const DASHBOARD_ICON = (
  <MenuIcon
    svg={
      <svg width="14" height="16" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          width="16.5971"
          height="3.31941"
          rx="1.65971"
          transform="matrix(-4.37123e-08 1 -0.989086 0.147337 9.6416 0.111328)"
          fill="#9CA3AF"
        />
        <rect
          width="9.95824"
          height="3.31941"
          rx="1.65971"
          transform="matrix(-4.3713e-08 1 -0.989087 -0.147335 15.7275 4.13477)"
          fill="#D1D5DB"
        />
        <rect
          width="9.95824"
          height="3.31941"
          rx="1.65971"
          transform="matrix(-4.3713e-08 -1 0.989087 0.147335 0 13.6035)"
          fill="#9CA3AF"
        />
      </svg>
    }
  />
);

const FUNNEL_ICON = (
  <MenuIcon
    svg={
      <svg width="16" height="13" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          width="16.5971"
          height="3.31941"
          rx="1.65971"
          transform="matrix(1 0 0.147337 0.989086 0.320801 0)"
          fill="#9CA3AF"
        />
        <rect
          width="9.95824"
          height="3.31941"
          rx="1.65971"
          transform="matrix(1 0 -0.147335 0.989087 4.34399 6)"
          fill="#D1D5DB"
        />
        <rect
          width="4.4731"
          height="3.31941"
          rx="1.65971"
          transform="matrix(-1 8.74244e-08 0.147335 -0.989087 11.0706 15)"
          fill="#9CA3AF"
        />
      </svg>
    }
  />
);

const REPORT_ICON = (
  <MenuIcon
    svg={
      <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="14.5" cy="15.0552" rx="14" ry="14.5" fill="#1C2731" />
        <path d="M16.9307 6.89891L13.7036 13.6041L16.8508 20.021H23.2502L16.9307 6.89891Z" fill="#D1D5DB" />
        <path d="M5.75 20.0211H10.1459H14.236L9.99299 11.2109L5.75 20.0211Z" fill="#9CA3AF" />
      </svg>
    }
  />
);

const PEOPLE_ICON = (
  <MenuIcon
    svg={
      <svg width="11" height="16" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.63907 8.45695C6.02614 7.80192 6.97386 7.80193 7.36093 8.45695L9.75 12.5L12.1085 16.4913C12.5024 17.1579 12.0219 18 11.2476 18H1.75245C0.978148 18 0.497613 17.1579 0.891522 16.4913L3.25 12.5L5.63907 8.45695Z"
          fill="#9CA3AF"
        />
        <circle cx="6.55556" cy="3.55556" r="3.55556" fill="#D1D5DB" />
      </svg>
    }
  />
);

export default Menu;
