/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import { Link, routes } from '@redwoodjs/router';
import Accounts from 'src/components/Account/Accounts';
import { LoadingComponent } from 'src/components/Generic';

export const QUERY = gql`
  query FindAccounts($orderByRelation: OrderByAccount, $orderByDirection: OrderByDirection) {
    accounts(orderByRelation: $orderByRelation, orderByDirection: $orderByDirection) {
      id
      status
      type
      trialEnd
      createdAt
      adminUser {
        email
      }
      integration {
        status
        dataPhase
      }
    }
  }
`;

export const Loading = () => <LoadingComponent />;

export const Empty = () => {
  return (
    <div className="rw-text-center">
      {'No accounts yet. '}
      <Link to={routes.newAccount()} className="rw-link">
        Create one?
      </Link>
    </div>
  );
};

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'cache-and-network' };
};

export const Failure = ({ error }) => <div style={{ color: 'red' }}>Error: {error.message}</div>;

Failure.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.any,
  }),
};

export const Success = ({ accounts }) => {
  return <Accounts accounts={accounts} />;
};

Success.propTypes = {
  accounts: PropTypes.any,
};
