import { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useFunnelTrackingPath } from 'src/components/Funnel/FunnelTrackingPane/funnelTrackingHooks';
import { useFunnelData } from 'src/components/Funnel/funnelHooks';
import { capitalize } from 'src/lib/generic/handlers';
import { scrollbarStyles } from 'src/lib/generic/commonClasses';
import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';
import { useRecoilValue } from 'recoil';

import { FunnelTrackingButton } from 'src/components/Funnel/FunnelTrackingPane/FunnelTrackingButton/FunnelTrackingButton';
import SearchItemsDialog from 'src/components/SearchItems/SearchItemsDialog/SearchItemsDialog';
import SegmentBuilderContainer from 'src/components/Funnel/SegmentBuilder/SegmentBuilderContainer/SegmentBuilderContainer';
import FunnelSegmentBuilder from 'src/components/Funnel/SegmentBuilder/FunnelSegmentBuilder/FunnelSegmentBuilder';
import SearchItemsFunnel from 'src/components/Funnel/SearchItems/SearchItemsFunnel/SearchItemsFunnel';
import SquareLeadsCount from 'src/components/SquareLeadsCount/SquareLeadsCount';
import { RightPane, RightPaneHeader } from 'src/components/Funnel/FunnelRightPane/FunnelRightPane';
import { SegmentBuilderFooter } from 'src/components/Funnel/SegmentBuilder/SegmentBuilderContainer';
import { BackArrow } from 'src/components/Generic';

const noop = () => {};
const searchTableModalClass = 'funnelSearchDialog';

const getAccessor = (accessor) => {
  if (accessor === 'stripeProductItem' || accessor === 'stripeProductTrial') {
    return 'stripeProduct';
  }
  return accessor;
};
export default function FunnelTrackingPane({ onCrossButtonClick, rightPaneRef }) {
  const [showDialog, setShowDialog] = useState(false);
  const [showSegmentBuilder, setShowSegmentBuilder] = useState(false);
  const [isFinalLocation, setIsFinalLocation] = useState(false);

  const { timeRange } = useRecoilValue(RecoilTimeRange);

  const {
    currentSquareIndex,
    currentSquare,
    currentFunnelPart,
    funnelData,
    shouldDialogOpen,
    updateCurrentSquarePathLocation,
    setClickedElementId,
    handleOnDialogCloseByCrossClick,
  } = useFunnelData();

  const onDialogClose = () => {
    setShowDialog(false);
    const defaultSegmentElementId = 'first';
    setClickedElementId(defaultSegmentElementId);
  };

  const onDialogOpen = () => setShowDialog(true);
  const onUpdateLocation = (paths) => {
    updateCurrentSquarePathLocation(paths);
  };

  const { trackingPath, nextSteps, addStepToPath, removeStepsFromPath, currentLocation, goToPath } =
    useFunnelTrackingPath(onUpdateLocation, funnelData[currentFunnelPart]);

  useEffect(() => {
    const initPath = funnelData[currentFunnelPart]?.squares[currentSquareIndex]?.panePath || [
      { accessor: currentFunnelPart, name: capitalize(currentFunnelPart) },
    ];

    goToPath(initPath);
  }, [currentFunnelPart, currentSquareIndex, funnelData, goToPath]);

  useEffect(() => {
    setShowDialog(shouldDialogOpen);
    setShowSegmentBuilder(currentLocation.segmentBuilder);
    setIsFinalLocation(currentLocation.final);
  }, [currentLocation, shouldDialogOpen]);

  const onHeaderCrossButtonClick = () => {
    onDialogClose();
    handleOnDialogCloseByCrossClick();
  };

  const ariaContainerId = 'aria-righSplitPaneContainer';
  return (
    <RightPane mainContainerRef={rightPaneRef}>
      <div className="animate-fade-in-left h-full flex flex-col">
        <PathHeader
          onCrossButtonClick={onCrossButtonClick}
          path={trackingPath}
          removeSteps={removeStepsFromPath}
          goToPath={goToPath}
          onUpdateLocation={onUpdateLocation}
        />
        {(showSegmentBuilder && (
          <SegmentBuilderContainer
            name={currentLocation.name}
            closeRightPane={onCrossButtonClick}
            disableButton={!currentSquare?.readyToQuery || showDialog}
            renderPeopleCount={(timeRangeProp) => (
              <SquareLeadsCount
                squareIdx={currentSquareIndex}
                funnelPart={currentFunnelPart}
                timeRange={timeRangeProp}
              />
            )}
          >
            <FunnelSegmentBuilder
              onDialogOpen={onDialogOpen}
              onDialogClose={onDialogClose}
              isDialogOpen={showDialog}
              itemAccessor={currentLocation.accessor}
            />
          </SegmentBuilderContainer>
        )) ||
          (isFinalLocation && (
            <div className="w-full h-full flex flex-col items-center justify-between px-8 pb-0">
              <h2 className="pb-10 pt-24 text-white text-xl text-center">
                <span className="text-wmxText-200 text-base">Currently tracking</span>
                <br />
                {currentLocation.name}
              </h2>
              <SegmentBuilderFooter onDoneClick={onCrossButtonClick} disableButton={showDialog}>
                <SquareLeadsCount squareIdx={currentSquareIndex} funnelPart={currentFunnelPart} timeRange={timeRange} />
              </SegmentBuilderFooter>
            </div>
          )) || (
            <div className="w-full h-full flex flex-col items-center pt-12 pb-8 ">
              <h2 className="pb-10 text-white text-xl">Choose what to track</h2>
              <NextStepButtons buttons={nextSteps} updateNextSteps={addStepToPath} />
            </div>
          )}
        {showDialog && (
          <SearchItemsDialog
            onDismiss={onHeaderCrossButtonClick}
            ariaParentId={ariaContainerId}
            modalClass={searchTableModalClass}
            className="p-0 z-60"
          >
            <SearchItemsFunnel
              typeOfItemName={currentLocation.name}
              itemAccessor={getAccessor(currentLocation.accessor)}
              onDismiss={onHeaderCrossButtonClick}
              onSuccessClose={onDialogClose}
            />
          </SearchItemsDialog>
        )}
      </div>
    </RightPane>
  );
}

/* ------------------------------------------------------------------------------------------------
 * Children components
 * -----------------------------------------------------------------------------------------------*/

function PathHeader({ onCrossButtonClick, path, removeSteps, goToPath, onUpdateLocation }) {
  const navigateBack = () => {
    if (path.length === 1) {
      onCrossButtonClick();
      return;
    }
    const clonedPath = [...path];
    clonedPath.pop();
    goToPath(clonedPath);
    onUpdateLocation(clonedPath);
  };

  const currentPath = path.map((step, stepIdx) => {
    const { name, accessor } = step;
    const isLast = path.length - 1 === stepIdx;
    const lastStepClassname = isLast ? 'cursor-default' : 'hover:text-wmxText-100 hover:bg-wmxHighlightDark-50';

    return (
      <Fragment key={name}>
        <button
          type="button"
          onClick={() => !isLast && removeSteps({ clickedStepAccessor: accessor })}
          className={`funnelButtonPath px-1 py-1 mx-2 text-wmxText-200 text-sm border border-transparent rounded-md ${lastStepClassname}`}
        >
          {name}
        </button>
        <span className="text-sm text-wmxText-200">/</span>
      </Fragment>
    );
  });

  return (
    <RightPaneHeader onCrossButtonClick={onCrossButtonClick}>
      <div className="flex items-center">
        <BackArrow onClick={navigateBack} />
        {currentPath}
      </div>
    </RightPaneHeader>
  );
}

function NextStepButtons({ buttons, updateNextSteps }) {
  const isOdd = buttons.length > 1 && buttons.length % 2 === 1;
  const buttonsComponents = buttons.map((button) => {
    const { name, onClick: addedOnClick = noop, comingSoon, disabled } = button;
    const onButtonClick = () => {
      updateNextSteps({ newPath: button });
      addedOnClick();
    };

    return (
      <FunnelTrackingButton key={name} onClick={onButtonClick} comingSoon={comingSoon} disabled={disabled}>
        {name}
      </FunnelTrackingButton>
    );
  });

  return (
    <div className={`w-full flex flex-wrap max-w-xl justify-center px-2 overflow-y-auto pb-12 ${scrollbarStyles} pt-2`}>
      {buttonsComponents}
      {isOdd && <div className="invisibleLayoutingSpace w-56  m-4 py-6 opacity-0" />}
    </div>
  );
}

/* ------------------------------------------------------------------------------------------------
 * Prop Types
 * -----------------------------------------------------------------------------------------------*/

const FunnelTrackingPaneProps = {
  onCrossButtonClick: PropTypes.func,
  funnelPartName: PropTypes.oneOf(['Top', 'Middle', 'Bottom']),
  onRightPaneClickOutside: PropTypes.func,
  funnelPartBoxContainerClass: PropTypes.string,
};

FunnelTrackingPane.propTypes = {
  ...FunnelTrackingPaneProps,
};

PathHeader.propTypes = {
  onCrossButtonClick: PropTypes.func,
  path: PropTypes.array,
  removeSteps: PropTypes.func,
  goToPath: PropTypes.func,
  onUpdateLocation: PropTypes.func,
};

NextStepButtons.propTypes = {
  buttons: PropTypes.array,
  updateNextSteps: PropTypes.func,
};
