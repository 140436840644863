import PropTypes from 'prop-types';
import { INTEGRATION_STATUSES } from 'wmx-shared-code/accountGlobalVariables';

export default function IntegrationStatusColour({ status, isAllowed = true }) {
  const getStatusClasses = () => {
    return `${
      status === INTEGRATION_STATUSES.ready && isAllowed
        ? 'text-green-500'
        : status === INTEGRATION_STATUSES.notConnected || !isAllowed
        ? 'text-red-600'
        : status === INTEGRATION_STATUSES.disconnecting || !isAllowed
        ? 'text-red-600'
        : 'text-orange-300'
    }`;
  };

  const colourClassName = getStatusClasses();

  return <span className={`${colourClassName} text-lg`}>•</span>;
}

IntegrationStatusColour.propTypes = {
  status: PropTypes.oneOf([...Object.values(INTEGRATION_STATUSES)]),
  isAllowed: PropTypes.bool,
};
