import PropTypes from 'prop-types';

import { useIntegrationsContext } from 'src/contexts/Integrations';

import { triggerTrackEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';

import Button, { useIsButtonClicked } from 'src/components/Generic/Button/Button';
import { PROVIDERS } from 'wmx-shared-code/accountGlobalVariables';

export default function DeleteIntegrationButton({ provider: providerParam }) {
  const { settingsByProvider, currentProviderShown: providerClicked, isAnyMutationLoading } = useIntegrationsContext();
  const provider = providerParam || providerClicked;

  const { deleteIntegration } = settingsByProvider[provider];

  const onDisconnectProvider = () => {
    triggerTrackEvent({ eventName: SEGMENT_TRACK_EVENTS_NAMES.integrationDisconnected, payload: { provider } });
    deleteIntegration();
  };

  const { buttonRef, isButtonClicked, onButtonClick } = useIsButtonClicked({ onSecondClick: onDisconnectProvider });

  const deleteBtnText = isButtonClicked ? 'Confirm Delete' : 'Delete';

  if (!provider) {
    throw new Error('You must define a provider to use DeleteIntegrationButton');
  }

  return (
    <Button
      buttonRef={buttonRef}
      disabled={isAnyMutationLoading}
      type="button"
      variant="danger"
      onClick={onButtonClick}
    >
      <div className="ml-1 leading-loose">{deleteBtnText}</div>
    </Button>
  );
}

DeleteIntegrationButton.propTypes = {
  provider: PropTypes.oneOf([...Object.values(PROVIDERS)]),
};
