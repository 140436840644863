/* global gql */

const fragments = {
  datapointCount: gql`
    fragment datapointCount on DatapointCount {
      count
      lastUpdated
      name
      description
      URLName
      resyncName
    }
  `,
  lastProviderSync: gql`
    fragment lastProviderSync on ProviderSync {
      lastSuccessfulAPISync
      lastSuccessfulWebhookSync
    }
  `,
  lastDailyResync: gql`
    fragment lastDailyResync on DailyResyncResponse {
      wasDailyResyncSuccessful
      failedDatapoints
    }
  `,
};

export const ACTIVE_CAMPAIGN_DATAPOINTS_OVERVIEW = {
  query: gql`
    query ACTIVE_CAMPAIGN_DATAPOINTS_OVERVIEW {
      datapointsOverviewByCount {
        ...datapointCount
      }
    }
    ${fragments.datapointCount}
  `,
  queryName: 'datapointsOverviewByCount',
};

export const GET_LAST_PROVIDERS_SYNC = gql`
  query getLastProviderSync {
    getLastProvidersSync {
      activecampaign {
        ...lastProviderSync
      }
      stripe {
        ...lastProviderSync
      }
    }
  }
  ${fragments.lastProviderSync}
`;

export const GET_LAST_DAILY_RESYNC = gql`
  query getLastDailyResync {
    getLastDailyResync {
      activecampaign {
        ...lastDailyResync
      }
    }
  }
  ${fragments.lastDailyResync}
`;
