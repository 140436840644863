import { METADATA_TYPES } from 'wmx-shared-code/tablesGlobalsVariables';
// eslint-disable-next-line import/no-extraneous-dependencies
import { uniqBy } from 'lodash';
import { capitalize } from 'src/lib/generic/handlers';

const hasMetadata = ({ item, key = 'metadata' }) => {
  return item?.[key] && JSON.parse(item[key]) && Object.keys(JSON.parse(item[key])).length;
};

const discoverMetadataSchema = ({ items, key = 'metadata' }) => {
  if (!items) return [];
  const item = items.find((i) => {
    return hasMetadata({ item: i, key });
  });

  if (!item) return [];

  const schema = items.reduce((a, v) => {
    if (!v[key]) return { ...a };
    return { ...a, ...Object.keys(JSON.parse(v[key])) };
  }, {});

  const columnsProps = Object.values(schema).map((property) => {
    return { accessor: `${property}-${key}`, label: capitalize(property) };
  });

  return uniqBy(columnsProps, (e) => {
    return e.accessor;
  });
};

const normalizeItems = (item) => {
  return Object.keys(item).reduce((a, v) => {
    return { ...a, [capitalize(v)]: item[v] };
  }, {});
};

const getMetadataProperties = ({ item, metaDataSchema, key = 'metadata' }) => {
  return (
    metaDataSchema &&
    metaDataSchema.reduce((a, v) => {
      if (!item[key]) return { ...a };
      const parsedItem = JSON.parse(item[key]);
      const normalizedItems = normalizeItems(parsedItem);
      return { ...a, [v.accessor]: normalizedItems[v.label] || '-' };
    }, {})
  );
};

export const discoverChargeMetadataSchema = ({ items }) => {
  return discoverMetadataSchema({ items });
};

export const discoverAddressSchema = ({ items }) => {
  return discoverMetadataSchema({ items, key: 'address' });
};

export const getChargeMetadataProperties = ({ item, metaDataSchema }) => {
  return { ...getMetadataProperties({ item, metaDataSchema }), metaType: METADATA_TYPES.charge };
};

export const getInvoiceMetadataProperties = ({ item, invoiceMetadataSchema }) => {
  return {
    ...getMetadataProperties({ item, metaDataSchema: invoiceMetadataSchema, key: 'invoiceMetadata' }),
    metaType: METADATA_TYPES.invoice,
  };
};

export const discoverInvoiceMetadataSchema = ({ items }) => {
  return discoverMetadataSchema({ items, key: 'invoiceMetadata' });
};

export const discoverCustomerMetadataSchema = ({ items }) => {
  return discoverMetadataSchema({ items, key: 'customerMetadata' });
};

export const getCustomerMetadataProperties = ({ item, customerMetadataSchema }) => {
  return {
    ...getMetadataProperties({ item, metaDataSchema: customerMetadataSchema, key: 'customerMetadata' }),
    metaType: METADATA_TYPES.customer,
  };
};

export const getAddressProperties = ({ item, addressSchema }) => {
  return {
    ...getMetadataProperties({ item, metaDataSchema: addressSchema, key: 'address' }),
    metaType: METADATA_TYPES.address,
  };
};

export const getMetadataColumns = ({ metadataSchema, metaType }) => {
  if (!metadataSchema || metadataSchema.length === 0) return [];
  return metadataSchema.map((key) => {
    return {
      accessor: key.accessor,
      metadata: true,
      metaType,
      Header: key.label,
    };
  });
};

export const formatValues = ({ data }) => {
  const result = Object.keys(data).reduce((a, v) => {
    const { metaType, ...withoutMetaType } = data;
    return { ...a, [`${v}`]: withoutMetaType[v] || '-' };
  }, {});
  return result;
};
