import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { INTEGRATION_STATUSES } from 'wmx-shared-code/accountGlobalVariables';
import NewStripeIntegrationOnboarding from 'src/components/IntegrationTopic/NewStripeIntegration/NewStripeIntegrationOnboarding';
import ActiveCampaignForm from 'src/components/ActiveCampaignForm';
import {
  StepButton,
  withOnboardingContext,
  StepsProgress,
  useOnboarding,
} from 'src/components/OnboardingFlow/useOnboardingFlow';
import { openPopupWidget } from 'react-calendly';
import IntegrationProgress from 'src/components/IntegrationProgress/IntegrationProgress';
import useCurrentUser from 'src/customHooks/useCurrentUser';
import { Button } from 'src/components/Generic';
import { Title, Text, Spacer, Subtitle, HSpacer, Card } from '../Generic/LayoutUtils/LayoutUtils';

const ACFormWithOnboarding = withOnboardingContext(ActiveCampaignForm);

export const StepCard = ({ children }) => {
  return <Card color={100}>{children}</Card>;
};

export const AcStep = () => {
  return (
    <>
      <StepCard>
        <Title>Hey and welcome to Wildmetrics!👋</Title>
        <Spacer />
        <Text>
          We have prepared a 4-step onboarding process for you to get you started in 5 minutes or less. To start using
          Wildmetrics, first connect your ActiveCampaign account below. For help, follow the instructions inside this
          <a
            className="underline ml-1"
            rel="noreferrer"
            href="https://docs.wildmetrics.io/start/guide#2-integrate-your-activecampaign-account"
            target="_blank"
          >
            support article.
          </a>
        </Text>
        <Spacer size="md" />
        <Subtitle>ActiveCampaign Details</Subtitle>
        <Spacer />
        <ACFormWithOnboarding />
        <Spacer size="md" />
      </StepCard>
    </>
  );
};

export const StripeStep = () => {
  return (
    <StepCard>
      <>
        <Title>Do you use Stripe?</Title>
        <Spacer />
        <Text>
          You successfully connected ActiveCampaign. You can now integrate your payment provider. We currently support
          Stripe, and others are coming very soon. For help, follow the instructions inside this
          <a
            className="ml-1 underline"
            rel="noreferrer"
            href="https://docs.wildmetrics.io/start/guide#3-finish-onboarding"
            target="_blank"
          >
            support article.
          </a>
        </Text>
        <Spacer size="md" />
        <div className="w-auto flex justify-between">
          <NewStripeIntegrationOnboarding />
        </div>
        <Spacer size="md" />
      </>
    </StepCard>
  );
};

export const CalendlyStep = () => {
  const { dispatch, nextStep } = useOnboarding();

  useEffect(() => {
    window.addEventListener('onEventScheduled', () => {
      dispatch({ type: 'next' });
    });

    return () => {
      window.removeEventListener('onEventScheduled', () => {});
    };
  }, []);

  return (
    <StepCard>
      <>
        <Title>Do you want a demo?</Title>
        <Spacer />
        <Text>
          While we prepare your data, would you like to schedule a call with us so that we can create a tracking &
          analytics plan together with you?.
        </Text>
        <Spacer />
        <div className="flex mt-12 mb-8 w-full">
          <div className="mr-auto">
            <StepButton action="back">
              <div className="ml-1 leading-loose">Back</div>
            </StepButton>
          </div>
          <div className="flex">
            <CalendlyButton url="https://calendly.com/wildaudience/wmx-demo?hide_gdpr_banner=1&primary_color=1e1287" />
            <HSpacer />
            <Button variant="transparent" onClick={() => nextStep()}>
              No, skip
            </Button>
          </div>
        </div>
      </>
    </StepCard>
  );
};

export const LoomStep = () => {
  return (
    <StepCard>
      <Title>Ready to start?</Title>
      <Spacer />
      <Text>
        Here&apos;s a short welcome video to get started. For more help visit our
        <a className="ml-1 underline" rel="noreferrer" href="https://docs.wildmetrics.io/start/guide" target="_blank">
          launch guide.
        </a>
      </Text>
      <Spacer size="md" />
      <LoomVideo />
      <Spacer size="md" />
    </StepCard>
  );
};

export const StepsProgressCustom = () => {
  return (
    <>
      <div className="rounded-lg overflow-hidden px-6">
        <Title>Onboarding</Title>
        <Spacer />
        <StepsProgress />
      </div>
      <Spacer size="md" />
    </>
  );
};

const CalendlyButton = ({ url, prefill, pageSettings, utm }) => {
  const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });
  return <Button onClick={onClick}>Schedule Demo</Button>;
};

const LoomVideo = () => {
  return (
    <iframe
      title="Welcome video"
      src="https://player.vimeo.com/video/626261919?h=11f369e15b"
      width="100%"
      height="340"
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      mozallowfullscreen
    />
  );
};

export const ProgressCard = () => {
  const { reauthenticate, ...currentUser } = useCurrentUser();
  const { acIntegration, stripeIntegration } = currentUser;

  useEffect(() => {
    reauthenticate();
  }, []);

  const { status: acStatus } = acIntegration;
  const { status: stripeStatus } = stripeIntegration;

  return (
    <div className="bg-wmxBgDark-200 rounded-lg overflow-hidden h-full">
      <div className="pt-6 p-4 rounded-md w-100">
        <div className="p-4">
          <Title>Good news! We are preparing your data right now.</Title>
          <Spacer />
          <Text>
            Below you can follow how fast we are importing, cleaning and preparing your data. This happens in the
            background which means you can close this page anytime. During your 10-day trial you will see data of the
            last 6 months. Unlimited data is available via our Pro plan.
          </Text>
        </div>
        <div className="w-100 flex">
          {acIntegration && acStatus !== INTEGRATION_STATUSES.notConnected && (
            <IntegrationProgress provider="activecampaign" providerLabel="ActiveCampaign" />
          )}
          {stripeIntegration && stripeStatus !== INTEGRATION_STATUSES.notConnected && (
            <IntegrationProgress provider="stripe" providerLabel="Stripe" />
          )}
        </div>
      </div>
    </div>
  );
};

CalendlyButton.propTypes = {
  pageSettings: PropTypes.object,
  prefill: PropTypes.any,
  url: PropTypes.any,
  utm: PropTypes.any,
};

StepCard.propTypes = {
  children: PropTypes.any,
};
