import { useEffect } from 'react';
import isEqual from 'lodash.isequal';
import pick from 'lodash.pick';

import useLocalStorage from 'src/customHooks/useLocalStorage';

import { makeId } from 'src/lib/generic/handlers';
import { getFixedRangeFromDynamicRange } from 'src/components/Generic/DatePicker/getTimeRange';

export const makeSegmentItemId = () => makeId(4);

export const getIsSegmentBuilderWithElements = (elements) => elements.some(({ value = {} }) => value?.itemName);

const [startTimeRange, endTimeRange] = getFixedRangeFromDynamicRange('All');

export const integrationsTimeRange = {
  start: startTimeRange,
  end: endTimeRange,
};

export function useUpdateIntegrationLocalStorage({ networkObj, cacheKey }) {
  const [lastSessionObj = {}, setLastSession] = useLocalStorage(cacheKey);

  useEffect(() => {
    const integrationKeys = Object.keys(networkObj);

    const integrationsShapeObj = pick(lastSessionObj, [...integrationKeys]);
    const areChanges = !isEqual(networkObj, integrationsShapeObj);
    if (areChanges) setLastSession(networkObj);
  }, [lastSessionObj, networkObj, setLastSession]);
}

export const getStripeState = ({ accountId, origin }) => {
  return encodeURIComponent(JSON.stringify({ accountId, origin }));
};
