import React from 'react';
import { Redirect, routes } from '@redwoodjs/router';
import PropTypes from 'prop-types';

import useCurrentUser from 'src/customHooks/useCurrentUser';

import DashboardLayout from 'src/layouts/DashboardLayout/DashboardLayout';
import { useTriggerPageSeenEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';

import useAccount from 'src/customHooks/useAccount';
import DefaultDashboardCell from 'src/components/DefaultDashboardCell/DefaultDashboardCell';
import { DashboardProvider } from 'src/contexts/Dashboard';
import { SegmentBuilderProvider } from 'src/contexts/SegmentBuilder/SegmentBuilder';
import { INTEGRATION_STATUSES, ACCOUNT_STATUSES } from 'wmx-shared-code/accountGlobalVariables';

export const SEGMENTS_FEATURE = true;
export const METRICS_OPTIONS = true;

export default function DashboardPage({ dashboardId }) {
  const { accountStatus, acIntegration: { status: AcStatus } = {} } = useCurrentUser();
  const { accountId } = useAccount();
  const conditionsToRenderPage = {
    acIsNotConnected: !AcStatus,
    isDashboardTab: dashboardId, // When a user arrives to a /dashboard/{dashboardId} url, still will trigger the render page
    trialExpired: !!AcStatus && accountStatus === ACCOUNT_STATUSES.trialExpired.dbValue,
    acIsImporting: !!AcStatus && AcStatus === INTEGRATION_STATUSES.importing,
  };

  const shouldEventBeSent = !Object.values(conditionsToRenderPage).includes(true);

  useTriggerPageSeenEvent({
    pageName: SEGMENT_TRACK_EVENTS_NAMES.dashboardPage,
    shouldEventBeSent,
  });

  if (conditionsToRenderPage.acIsNotConnected) {
    return <Redirect to={routes.onboardingPage({ slug: 'esp' })} />;
  }

  if (conditionsToRenderPage.acIsImporting) {
    return <Redirect to={routes.onboardingPage({ slug: 'overview' })} />;
  }

  return (
    <SegmentBuilderProvider>
      <DashboardProvider>
        <DashboardLayout>
          <DefaultDashboardCell accountId={accountId} dashboardId={dashboardId || null} />
        </DashboardLayout>
      </DashboardProvider>
    </SegmentBuilderProvider>
  );
}

DashboardPage.propTypes = {
  dashboardId: PropTypes.any,
};
