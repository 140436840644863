import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';

import Metric from 'src/components/Dashboard/MetricItem/MetricItem';
import { useDashboard } from 'src/contexts/Dashboard';
import { useTimeSeries } from 'src/lib/graphsHelpers/graphsHelpers';
import SubscribersChartCell from 'src/components/Cells/SubscribersChartCell/SubscribersChartCell';
import { Spacer, HSpacer } from '../Generic/LayoutUtils/LayoutUtils';

const SubscribersMetric = ({ value, change, page, prevValue }) => {
  const { timeRange, timeRangeLabel, editMode } = useDashboard(); // object that has a start and end property
  const { getTimeSerie } = useTimeSeries();
  const timeseries = useMemo(() => getTimeSerie(timeRange, timeRangeLabel), [timeRange, getTimeSerie, timeRangeLabel]);
  const boxRef = useRef();
  const boxWidth = boxRef?.current?.getBoundingClientRect().width;
  const boxHeight = boxRef?.current?.getBoundingClientRect().height;
  return (
    <div ref={boxRef} className="h-full w-full">
      <Metric.Root editMode={editMode}>
        <Metric.Top openButton={!editMode}>
          <Metric.Title>Leads</Metric.Title>
          <Metric.Tooltip absolute={false}>
            <div>
              This is the amount of new leads generated over the time range. A contact becomes a lead when it is
              createad in Active campaign.
            </div>
          </Metric.Tooltip>
          {!editMode && <Metric.Button page={page} />}
        </Metric.Top>

        <Metric.Body>
          <Metric.Value>{value}</Metric.Value>
          <Metric.Change>{change}</Metric.Change>
          <HSpacer />
        </Metric.Body>
        <Metric.MetricSubtitle size="xs">compared to {prevValue}</Metric.MetricSubtitle>
        {boxHeight > 150 && !editMode ? (
          <>
            <Spacer size="xs" />
            <Metric.MetricChart>
              <SubscribersChartCell timeSeries={timeseries} boxHeight={boxHeight} boxWidth={boxWidth} />
            </Metric.MetricChart>
          </>
        ) : (
          <></>
        )}
      </Metric.Root>
    </div>
  );
};

SubscribersMetric.propTypes = {
  change: PropTypes.any,
  page: PropTypes.any,
  value: PropTypes.any,
  prevValue: PropTypes.any,
};

export default SubscribersMetric;
