/* global gql */
import { ORDER_BY_EMAILS_ENUM, ORDER_BY_CUSTOMERS_ENUM } from 'wmx-shared-code/reports/emailsOverview';

const fragments = {
  emailsQuery: gql`
    fragment emailsQuery on Campaign {
      campaignId
      automationId
      name
      numberOfLeads
      numberOfCustomers
      uniqueOpens
      convRate
    }
  `,
  leads: gql`
    fragment leads on OpenLead {
      contactId
      email
    }
  `,
  automationsQuery: gql`
    fragment automationsQuery on Automation {
      automationId
      name
      numberOfLeads
      numberOfCustomers
      uniqueOpens
      convRate
    }
  `,
  stripeCustomers: gql`
    fragment stripeCustomers on Customer {
      contactId
      customerId
      productName
      priceName
      metadata
      invoiceMetadata
      customerMetadata
      chargeDescription
      name
      address
      email
      amount
      purchaseDate
    }
  `,
};

export const ORDER_BY_EMAILS_ENUM_VALUES = Object.values(ORDER_BY_EMAILS_ENUM);
export const ORDER_BY_CUSTOMERS_ENUM_VALUES = Object.values(ORDER_BY_CUSTOMERS_ENUM);

export const EMAILS_QUERY_WITH_URL = {
  query: gql`
    query EMAILS_QUERY_WITH_URL(
      $offset: Int
      $limit: Int
      $timeRange: TimeRangeInput!
      $convWindow: Int!
      $orderBy: OrderByEmails
      $orderByDirection: OrderByDirection
    ) {
      getEmailsOverviewByAccount(
        offset: $offset
        limit: $limit
        timeRange: $timeRange
        convWindow: $convWindow
        orderBy: $orderBy
        orderByDirection: $orderByDirection
      ) {
        ...emailsQuery
        revenue
      }
      activeCampaignUrl
    }
    ${fragments.emailsQuery}
  `,
  queryName: 'getEmailsOverviewByAccount',
};

export const EMAILS_LEADS_QUERY_WITH_URL = {
  query: gql`
    query EMAILS_LEADS_QUERY_WITH_URL($offset: Int, $limit: Int, $campaignId: String!, $timeRange: TimeRangeInput!) {
      getEmailLeadsOpen(offset: $offset, limit: $limit, campaignId: $campaignId, timeRange: $timeRange) {
        ...leads
      }
      activeCampaignUrl
    }
    ${fragments.leads}
  `,
  queryName: 'getEmailLeadsOpen',
};

export const EMAILS_CUSTOMERS_QUERY = {
  query: gql`
    query EMAILS_CUSTOMERS_QUERY(
      $offset: Int
      $limit: Int
      $campaignId: String!
      $timeRange: TimeRangeInput!
      $convWindow: Int!
      $orderBy: OrderByCustomers
      $orderByDirection: OrderByDirection
    ) {
      getEmailCustomers(
        offset: $offset
        limit: $limit
        campaignId: $campaignId
        timeRange: $timeRange
        convWindow: $convWindow
        orderBy: $orderBy
        orderByDirection: $orderByDirection
      ) {
        ...stripeCustomers
      }
    }
    ${fragments.stripeCustomers}
  `,
  queryName: 'getEmailCustomers',
};

export const AUTOMATIONS_QUERY = {
  query: gql`
    query AUTOMATIONS_QUERY(
      $offset: Int
      $limit: Int
      $timeRange: TimeRangeInput!
      $convWindow: Int!
      $orderBy: OrderByEmails
      $orderByDirection: OrderByDirection
    ) {
      getAutomationsOverview(
        offset: $offset
        limit: $limit
        timeRange: $timeRange
        convWindow: $convWindow
        orderBy: $orderBy
        orderByDirection: $orderByDirection
      ) {
        ...automationsQuery
        revenue
      }
      activeCampaignUrl
    }
    ${fragments.automationsQuery}
  `,
  queryName: 'getAutomationsOverview',
};

export const AUTOMATIONS_LEADS_QUERY_WITH_URL = {
  query: gql`
    query AUTOMATIONS_LEADS_QUERY_WITH_COUNT_AND_URL(
      $offset: Int
      $limit: Int
      $automationId: String!
      $timeRange: TimeRangeInput!
    ) {
      getAutomationLeadsOpen(offset: $offset, limit: $limit, automationId: $automationId, timeRange: $timeRange) {
        ...leads
        openedEmails
      }
      activeCampaignUrl
    }
    ${fragments.leads}
  `,
  queryName: 'getAutomationLeadsOpen',
};

export const AUTOMATIONS_CUSTOMERS_QUERY = {
  query: gql`
    query AUTOMATIONS_CUSTOMERS_QUERY(
      $offset: Int
      $limit: Int
      $automationId: String!
      $timeRange: TimeRangeInput!
      $convWindow: Int!
      $orderBy: OrderByCustomers
      $orderByDirection: OrderByDirection
    ) {
      getAutomationCustomers(
        offset: $offset
        limit: $limit
        automationId: $automationId
        timeRange: $timeRange
        convWindow: $convWindow
        orderBy: $orderBy
        orderByDirection: $orderByDirection
      ) {
        ...stripeCustomers
      }
    }
    ${fragments.stripeCustomers}
  `,
  queryName: 'getAutomationCustomers',
};

export const EMAILS_IN_AUTOMATION_WITH_URL = {
  query: gql`
    query EMAILS_IN_AUTOMATION_WITH_URL(
      $offset: Int
      $limit: Int
      $timeRange: TimeRangeInput!
      $convWindow: Int!
      $automationId: String!
      $orderBy: OrderByEmails
      $orderByDirection: OrderByDirection
    ) {
      getEmailsOverviewByAutomation(
        offset: $offset
        limit: $limit
        automationId: $automationId
        timeRange: $timeRange
        convWindow: $convWindow
        orderBy: $orderBy
        orderByDirection: $orderByDirection
      ) {
        ...emailsQuery
        revenue
      }
      activeCampaignUrl
    }
    ${fragments.emailsQuery}
  `,
  queryName: 'getEmailsOverviewByAutomation',
};
