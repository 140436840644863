/* eslint-disable no-use-before-define */

// transform the itemAccessor from UI to funnelItems arrays accessors
export const transformTypeOfItem = (typeOfItem, direction = 'toBackend') => {
  const typeOfItems = [
    {
      toBackend: 'newContacts',
      toFrontend: 'anyNewLead',
    },
    {
      toBackend: 'newContactsByList',
      toFrontend: 'list',
    },
    {
      toBackend: 'newCustomers',
      toFrontend: 'anyProduct',
    },
    {
      toBackend: 'stripeProduct',
      toFrontend: 'stripeProductItem',
    },
  ];

  const otherDirection = direction === 'toBackend' ? 'toFrontend' : 'toBackend';
  const itemToTransform = typeOfItems.find((item) => item[otherDirection] === typeOfItem);

  if (!itemToTransform) return typeOfItem;

  return itemToTransform[direction];
};

export const transformTypeOfItemFromBackendToFrontend = (typeOfItem) => {
  switch (typeOfItem) {
    case 'newContacts':
      return 'anyNewLead';
    case 'newContactsByList':
      return 'list';
    case 'newCustomers':
      return 'anyProduct';
    case 'stripeProduct':
      return 'stripeProductItem';
    default:
      return typeOfItem;
  }
};

// transform item accesor to display human friendly, like stripeProducts to Product Name
export const pretiffyTypeOfItem = (typeOfItem) => {
  switch (typeOfItem) {
    case 'anyNewLead':
      return 'All';
    case 'stripeProduct':
      return 'product';
    case 'stripeProductItem':
      return 'product';
    case 'anyProduct':
      return 'All products';
    default:
      return typeOfItem;
  }
};

export const isAnyTypeOFItem = (typeOfItem) => {
  return typeOfItem.indexOf('any') > -1;
};

export const getQueryFormattedByTypeOfItem = (item, timeRange) => {
  const { typeOfItem, segmentElements, readyToQuery } = item;

  if (!readyToQuery) return {};

  if (typeOfItem === 'anyNewLead' || typeOfItem === 'anyProduct') {
    return timeRange;
  }
  if (hasSegmentElementsWithValue(item)) {
    return { ids: getGroupsOfIds(segmentElements) };
  }
  return {};
};

const getGroupsOfIds = (elements) => {
  const groups = [[]];
  let groupI = 0;
  elements.forEach((item, i) => {
    if (item.connection === 'AND' && i > 0 && item.value) {
      groupI += 1;
      groups.push([]);
    }
    if (item.value?.itemsIds) {
      groups[groupI] =
        item.value.itemsIds.length > 1
          ? [...groups[groupI], ...item.value.itemsIds]
          : [...groups[groupI], item.value.itemsIds[0]];
    }
  });
  return groups;
};

const hasSegmentElementsWithValue = (item) => {
  // check if at least one item id is set looking into the first item of the set
  if (item) {
    if (
      item.segmentElements?.filter((it) => {
        return it.value;
      }).length > 0
    ) {
      return true;
    }
  }
  return false;
};

// transform reactFlow elements into funnelItems ready for query
export const getSegmentQueryObject = ({ elements, typeOfItem, timeRange }) => {
  if (typeOfItem === 'anyNewLead') {
    return [{ newContacts: timeRange }];
  }
  if (typeOfItem === 'anyProduct') {
    return [{ newCustomers: timeRange }];
  }

  const groups = [[]];
  let groupI = 0;
  elements.forEach((item, i) => {
    const isValueDefined = item.value && Object.keys(item.value).length !== 0;

    if (item.connection === 'AND' && i > 0 && isValueDefined) {
      groupI += 1;
      groups.push([]);
    }
    if (item.value?.itemsIds) {
      groups[groupI] =
        item.value.itemsIds.length > 1
          ? [...groups[groupI], ...item.value.itemsIds]
          : [...groups[groupI], item.value.itemsIds[0]];
    }
  });

  return [
    {
      [transformTypeOfItem(typeOfItem)]: {
        ids: groups,
      },
    },
  ];
};

export const withFunnelListQuery = (FunnelListComponent, { ...restProps } = {}) => {
  const {
    maxScroll = 0,
    LoadingComponent = null,
    EmptyComponent = null,
    fetchPolicy = 'cache-and-network',
    timeRange,
  } = restProps;
  const RENDER_EACH_SCROLL_FOR_TABLE = maxScroll || 60;

  // eslint-disable-next-line no-undef
  const FUNNEL_MANAGEMENT_LIST_QUERY = gql`
    query getFunnelReportsByAccount($limit: Int, $offset: Int, $timeRange: TimeRangeInput) {
      getFunnelReportsByAccount(offset: $offset, limit: $limit, timeRange: $timeRange) {
        id
        createdAt
        modifiedAt
        funnelItems
        name
      }
    }
  `;

  const intialQueryParams = {
    fetchPolicy,
    variables: {
      offset: 0,
      limit: RENDER_EACH_SCROLL_FOR_TABLE,
      timeRange,
    },
  };

  const queryProps = {
    initialQuery: FUNNEL_MANAGEMENT_LIST_QUERY,
    dataPath: 'getFunnelReportsByAccount',
    emptyComponentText: "You don't have any funnels saved yet",
    emptyComponentProps: {
      className: {
        text: 'text-xs',
      },
    },
    queryParams: {
      ...intialQueryParams,
    },
    ...restProps,
  };

  const listProps = {
    ...queryProps,
    ...(LoadingComponent && { LoadingComponent: () => <LoadingComponent {...queryProps} /> }),
    ...(EmptyComponent && { EmptyComponent: () => <EmptyComponent {...queryProps} /> }),
  };

  return <FunnelListComponent {...listProps} />;
};
