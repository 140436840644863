import PropTypes from 'prop-types';
import EditAccountCell from 'src/components/Account/EditAccountCell/EditAccountCell';

const EditAccountPage = ({ id }) => {
  return <EditAccountCell id={id} />;
};

EditAccountPage.propTypes = {
  id: PropTypes.any,
};

export default EditAccountPage;
