import PropTypes from 'prop-types';

import { Dropdown } from 'src/components/Generic';
import { dropdownPropTypes } from 'src/components/Generic/Dropdown/Dropdown';

export default function DatepickerDropdown({ label, dropdownProps, classNames, datepicker, showDatepicker }) {
  const addToDropdownProps = { ...dropdownProps };
  addToDropdownProps.classNames.buttonTrigger = `${addToDropdownProps.classNames?.buttonTrigger} rounded-l-none`;

  return (
    <div className={`flex flex-row pl-2 shadow-sm rounded-md ${classNames?.container}`}>
      {label && (
        <div className={`flex items-center px-2 ${classNames?.label}`}>
          <span className="text-wmxText-200 text-xs">{label}</span>
        </div>
      )}
      {showDatepicker && datepicker}
      <Dropdown {...dropdownProps} />
    </div>
  );
}

DatepickerDropdown.propTypes = {
  dropdownProps: PropTypes.shape({
    ...dropdownPropTypes,
  }),
  label: PropTypes.string,
  classNames: PropTypes.shape({
    container: PropTypes.string,
    label: PropTypes.string,
  }),
  datepicker: PropTypes.object,
  showDatepicker: PropTypes.bool,
};

DatepickerDropdown.defaultProps = {
  classNames: {
    container: '',
    label: '',
  },
};
