import DeleteIntegrationButton from 'src/components/IntegrationTopic/DeleteIntegrationButton/DeleteIntegrationButton';
import ResyncIntegrationButton from 'src/components/IntegrationTopic/ResyncIntegrationButton/ResyncIntegrationButton';

export default function ButtonsLayer() {
  const buttonsConfig = [
    {
      name: 'resync button',
      renderElement: () => <ResyncIntegrationButton text="Resync all" confirmText="Confirm resync all" />,
    },
    { name: 'delete button', renderElement: () => <DeleteIntegrationButton /> },
  ];

  const buttons = buttonsConfig.map(({ name, renderElement }, idx) => {
    const isLastOne = idx === buttonsConfig.length - 1;
    const className = !isLastOne ? 'pr-4' : null;
    return (
      <div key={name} className={className}>
        {renderElement()}
      </div>
    );
  });

  return <div className="flex flex-row justify-end my-8">{buttons}</div>;
}
