/* eslint-disable no-use-before-define */
import useCurrentUser from 'src/customHooks/useCurrentUser';
import { ACCOUNT_STATUSES, ACCOUNT_TYPES } from 'wmx-shared-code/accountGlobalVariables';

const useAccount = () => {
  const { email, accountStatus, daysLeft, accountType, accountId, reauthenticate, acIntegration } = useCurrentUser();

  return {
    currentEmail: email,
    updateEmail,
    updatePassword,
    deleteAccount,
    deleteIntegration,
    updateAccount,
    getBillingDetails,
    help,
    trial: accountType === ACCOUNT_TYPES.trial,
    daysLeft,
    accountStatus,
    accountType,
    isTrialExpired: accountStatus === ACCOUNT_STATUSES.trialExpired.dbValue,
    accountId,
    acIntegration,
    reauthenticate,
  };
};

const help = ({ email }) => {
  beaconReducer({
    type: beaconReducer.types.help,
    payload: { email },
  });
};

const getBillingDetails = ({ email }) => {
  beaconReducer({
    type: beaconReducer.types.billingDetails,
    payload: { email },
  });
};

const updateAccount = ({ email, currentPlan }) => {
  beaconReducer({
    type: beaconReducer.types.updateAccount,
    payload: { email, currentPlan },
  });
};

const deleteIntegration = ({ email, provider }) => {
  beaconReducer({
    type: beaconReducer.types.deleteIntegration,
    payload: { email, provider },
  });
};

const updateEmail = ({ email }) => {
  beaconReducer({
    type: beaconReducer.types.email,
    payload: { newEmail: email, email },
  });
};

const updatePassword = ({ email, password }) => {
  beaconReducer({
    type: beaconReducer.types.password,
    payload: { newPass: password, email },
  });
};

const deleteAccount = ({ email }) => {
  beaconReducer({
    type: beaconReducer.types.delete,
    payload: { email },
  });
};

const beaconReducer = ({ type, payload }) => {
  if (!window.Beacon) return;
  switch (type) {
    case beaconReducer.types.billingDetails:
      window.Beacon('prefill', {
        email: `${payload.email}`,
        subject: 'Billing details',
        text: `Hello, I want to update/download my billing details.`,
      });
      break;
    case beaconReducer.types.updateAccount:
      window.Beacon('prefill', {
        email: `${payload.email}`,
        subject: 'Upgrade Plan',
        text: `Hello, I want to upgrade my plan from ${payload.currentPlan}`,
      });
      break;
    case beaconReducer.types.email:
      window.Beacon('prefill', {
        email: `${payload.email}`,
        subject: 'Email Change',
        text: `Hello, I want to change my email to ${payload.newEmail}`,
      });
      break;
    case beaconReducer.types.password:
      window.Beacon('prefill', {
        email: `${payload.email}`,
        subject: 'Password Change',
        text: `Hello, I want to change my password to ${payload.newPass}`,
      });
      break;
    case beaconReducer.types.delete:
      window.Beacon('prefill', {
        email: `${payload.email}`,
        subject: 'Delete Account',
        text: `Hello, I want to delete my account`,
      });
      break;
    case beaconReducer.types.deleteIntegration:
      window.Beacon('prefill', {
        email: `${payload.email}`,
        subject: 'Delete Integration',
        text: `Hello, I want to delete my ${payload.provider} integration`,
      });
      break;
    case beaconReducer.types.help:
      window.Beacon('prefill', {
        email: `${payload.email}`,
        subject: '',
        text: '',
      });
      break;
    default:
      break;
  }
  window.Beacon('open');
  const BeaconFabButtonFrame = document.querySelector('.BeaconFabButtonFrame');
  BeaconFabButtonFrame.style.visibility = 'visible';
};
beaconReducer.types = {
  email: 'email',
  password: 'password',
  delete: 'delete',
  deleteIntegration: 'deleteIntegration',
  billingDetails: 'billingDetails',
  updateAccount: 'updateAccount',
  help: 'help',
};

export default useAccount;
