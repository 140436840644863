import { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { scrollbarStyles } from 'src/lib/generic/commonClasses';
import { MAIN_PADDING } from 'src/layouts/MainLayout';

const DEFAULT_HEIGHT = '1000';

export default function MainCardLayout({ children, containerRef, classNames = {}, scrollable = true }) {
  const { divRef, height } = useMainCardLayout();

  const { flex = 'justify-between' } = classNames;
  return (
    <div ref={divRef} className="h-full w-full z-0 relative ">
      <Card padding="sm" bg={200}>
        <div
          ref={containerRef}
          style={{
            height: `${height}px`,
          }}
          className={`MainCardLayout flex flex-col ${flex} z-10 h-full w-full ${
            (scrollable && `overflow-auto ${scrollbarStyles}`) || 'overflow-hidden'
          }`}
        >
          {children}
        </div>
      </Card>
    </div>
  );
}

function useMainCardLayout() {
  const [height, setHeight] = useState(DEFAULT_HEIGHT);

  const divRef = useRef();

  const heightHandler = useCallback(
    (divRefLocal) => {
      if (!divRefLocal?.current) return;
      const { y: yPosition } = divRefLocal?.current?.getBoundingClientRect();
      setHeight(window.innerHeight - yPosition - MAIN_PADDING * 2);
    },
    [setHeight]
  );

  useEffect(() => {
    heightHandler(divRef);
  }, [divRef, heightHandler]);

  useEffect(() => {
    window.addEventListener('resize', () => heightHandler(divRef));

    return () => {
      window.removeEventListener('resize', () => heightHandler(divRef));
    };
  }, [heightHandler, divRef]);

  return { divRef, height };
}

MainCardLayout.propTypes = {
  children: PropTypes.any,
  classNames: PropTypes.shape({
    flex: PropTypes.string,
  }),
  containerRef: PropTypes.any,
  scrollable: PropTypes.bool,
};
