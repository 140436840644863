/* eslint-disable camelcase */

import { formatDatabaseTimeInput } from 'src/lib/handleTimezone';
import { getLastTwClassNameValue } from 'src/lib/generic/handlers';

export const transformTimeRangeToDate = (funnelItemsFromDb) => {
  return funnelItemsFromDb.map((funnelPart) => {
    return funnelPart.map((block) => {
      const { query } = block;

      if (query?.newContacts || query?.newCustomers) {
        const itemNameInService = Object.keys(query)[0];

        const newTimeRange = {
          start: formatDatabaseTimeInput({ date: query[itemNameInService]?.start }),
          end: formatDatabaseTimeInput({ date: query[itemNameInService]?.end }),
        };

        return {
          ...block,
          query: {
            [itemNameInService]: {
              ...newTimeRange,
            },
          },
        };
      }

      return {
        ...block,
      };
    });
  });
};

/* Tailwind purge does not allow string concatenation */

export const container_marginTop = 'mt-4';
export const table_paddingBottom = 'pb-4';
export const headers_height = 'h-12';
export const headers_paddingTop = 'pt-2';
export const headers_paddingBottom = 'pb-2';
export const cells_height = 'h-10';
export const cells_paddingTop = 'pt-3';
export const cells_paddingBottom = 'pb-3';

export const twTableVerticalMeasurements = {
  container: {
    marginTop: getLastTwClassNameValue(container_marginTop),
  },
  table: {
    paddingBottom: getLastTwClassNameValue(table_paddingBottom),
  },
  headers: {
    height: getLastTwClassNameValue(headers_height),
    paddingTop: getLastTwClassNameValue(headers_paddingTop),
    paddingBottom: getLastTwClassNameValue(headers_paddingBottom),
  },
  cells: {
    height: getLastTwClassNameValue(cells_height),
    paddingTop: getLastTwClassNameValue(cells_paddingTop),
    paddingBottom: getLastTwClassNameValue(cells_paddingBottom),
  },
};
