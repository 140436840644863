import PropTypes from 'prop-types';
import { useDashboard } from 'src/contexts/Dashboard';
import { Flex, HelperText, Spacer, HSpacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { Switch } from 'src/components/Generic';
import { useEffect } from 'react';

const switchColors = {
  borderActive: 'border-wmxHighlightDark-50',
  bgActive: 'bg-wmxHighlightDark-300',
  bg: 'bg-wmxBgDark-400',
  toggleBg: 'bg-wmxHighlightDark-50',
  toggleBgActive: 'bg-wmxPrimary-100',
};

export const MetricSwitch = ({ metric, index }) => {
  const { setMetricsState } = useDashboard();
  if (!metric.available) return <></>;

  function switchMetric() {
    setMetricsState((previous) => {
      const newArr = [...previous];
      return newArr.map((metricItem, i) => {
        if (i === index) {
          return {
            ...metricItem,
            enabled: !metricItem.enabled,
          };
        }
        return metricItem;
      });
    });
  }

  return (
    <>
      <Flex>
        <Switch active={metric.enabled} handleChange={switchMetric} colors={switchColors} />
        <HSpacer size="xs" />
        <HelperText>
          <label htmlFor={`${metric}-check`} className="cursor-pointer noCloseRightPane">
            {metric.label}
          </label>
        </HelperText>
      </Flex>
      <Spacer size="xs" />
    </>
  );
};

export const SegmentSwitch = ({ segment, index }) => {
  const { setSegmentsState } = useDashboard();

  const handleChange = () => {
    setSegmentsState((previous) => {
      const objToReplace = {
        dashboardSettings: {
          enabled: !previous[index].dashboardSettings.enabled,
        },
        id: previous[index].id,
        segmentName: previous[index].segmentName,
        __typename: 'PeopleTagsSegment',
      };
      const newArr = [...previous];
      newArr[index] = objToReplace;
      return newArr;
    });
  };

  return (
    <>
      <Flex key={segment.id}>
        <Switch active={segment.dashboardSettings.enabled} handleChange={handleChange} colors={switchColors} />
        <HSpacer size="xs" />
        <HelperText>
          <label htmlFor={`${segment.id}-check`} className="cursor-pointer noCloseRightPane">
            {segment.segmentName}
          </label>
        </HelperText>
      </Flex>
      <Spacer size="xs" />
    </>
  );
};

export const SwitchEvents = () => {
  const { showEvents, setShowEvents } = useDashboard();
  function toggleEvents() {
    setShowEvents(!showEvents);
  }

  return (
    <>
      <Flex>
        <Switch active={showEvents} handleChange={toggleEvents} colors={switchColors} />
        <HSpacer size="xs" />
        <HelperText>
          <p className="cursor-pointer noCloseRightPane">Events Stream</p>
        </HelperText>
      </Flex>

      <Spacer size="xs" />
    </>
  );
};

export const SwitchTimerange = () => {
  const { saveTimeRangeEnabled, setSaveTimeRangeEnabled, currentDashboard } = useDashboard();

  useEffect(() => {
    if (currentDashboard?.defaultTimerange) {
      setSaveTimeRangeEnabled(true);
    } else {
      setSaveTimeRangeEnabled(false);
    }
  }, [currentDashboard?.defaultTimerange, setSaveTimeRangeEnabled]);

  return (
    <>
      <Flex>
        <Switch
          active={saveTimeRangeEnabled}
          handleChange={() => {
            setSaveTimeRangeEnabled(!saveTimeRangeEnabled);
          }}
          colors={switchColors}
        />
        <HSpacer size="xs" />
        <HelperText>
          <p className="cursor-pointer noCloseRightPane">Save Timerange</p>
        </HelperText>
      </Flex>

      <Spacer size="xs" />
    </>
  );
};

MetricSwitch.propTypes = {
  metric: PropTypes.any,
  index: PropTypes.any,
};

SegmentSwitch.propTypes = {
  segment: PropTypes.shape({
    dashboardSettings: PropTypes.shape({
      enabled: PropTypes.any,
    }),
    id: PropTypes.any,
    segmentName: PropTypes.any,
  }),
  index: PropTypes.any,
};
