import PropTypes from 'prop-types';
import { Redirect, routes } from '@redwoodjs/router';

import useCurrentUser from 'src/customHooks/useCurrentUser';

import OverviewReportsProvider from 'src/components/Analyze/Overview/hooks/overviewProvider';
import OverviewView from 'src/components/Analyze/Overview/OverviewView/OverviewView';
import AnalyzeLayout from 'src/layouts/AnalyzeLayout/AnalyzeLayout';

export default function AnalyzePage({ modalType, convWindow: initialConvWindow }) {
  const { acIntegration: { isReady: isUserWithAc } = {} } = useCurrentUser();

  if (!isUserWithAc) {
    return <Redirect to={routes.onboardingPage({ slug: 'esp' })} />;
  }
  return (
    <OverviewReportsProvider>
      <AnalyzeLayout>
        <OverviewView isModalOpen={!!modalType} initialConvWindow={initialConvWindow} />
      </AnalyzeLayout>
    </OverviewReportsProvider>
  );
}

AnalyzePage.propTypes = {
  modalType: PropTypes.string,
  convWindow: PropTypes.oneOf(['7', '30', '∞']),
};
