import { useEffect } from 'react';
import { navigate, routes, useLocation } from '@redwoodjs/router';
import { useAuth } from '@redwoodjs/auth';
import { ACCOUNT_STATUSES } from 'wmx-shared-code/accountGlobalVariables';
import { useSidebar } from '../SidebarLayout/SidebarLayout';
import { isBrowser } from '@redwoodjs/prerender/browserUtils';
import { ACCOUNT_PAGE_PATH, BILLING_PAGE_PATH } from 'src/lib/routesPath';

const ALLOWED_URLS_WHEN_TRIAL_EXPIRED = [ACCOUNT_PAGE_PATH, BILLING_PAGE_PATH];

export default function useAppLayout() {
  const { currentUser } = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    const isAllowedUrlForExpiredTrials = ALLOWED_URLS_WHEN_TRIAL_EXPIRED.some((url) => pathname.includes(url));
    if (currentUser?.accountStatus === ACCOUNT_STATUSES.trialExpired.dbValue && !isAllowedUrlForExpiredTrials) {
      navigate(routes.billing());
    }
  }, [pathname, currentUser]);

  const collapsedSidebar = useSidebar();

  //disabled because is not working, maybe needs a subscription (hotjar and heap)
  //useTracking();
  return {
    collapsedSidebar,
  };
};

export const useTracking = () => {
  if (!isBrowser) return null;
  const { pathname, search, hash } = useLocation();
  useEffect(() => {
    // heap integration
    // eslint-disable-next-line no-undef
    // eslint-disable-next-line no-unused-expressions
    // eslint-disable-next-line prettier/prettier
    // eslint-disable-next-line no-unused-expressions
    // eslint-disable-next-line prettier/prettier
    (window.heap = window.heap || []),
      (heap.load = function (e, t) {
        // eslint-disable-next-line no-param-reassign
        (window.heap.appid = e), (window.heap.config = t = t || {});
        const r = document.createElement('script');
        (r.type = 'text/javascript'), (r.async = !0), (r.src = `https://cdn.heapanalytics.com/js/heap-${e}.js`);
        const a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(r, a);
        for (
          let n = function (e) {
              return function () {
                heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
              };
            },
            p = [
              'addEventProperties',
              'addUserProperties',
              'clearEventProperties',
              'identify',
              'resetIdentity',
              'removeEventProperty',
              'setEventProperties',
              'track',
              'unsetEventProperty',
            ],
            o = 0;
          o < p.length;
          o++
        )
          heap[p[o]] = n(p[o]);
      });
    // eslint-disable-next-line prettier/prettier
    heap.load(process.env.HEAP_APP_ID);

    // Hotjar Tracking Code for https://www.wildmetrics.io/
    // eslint-disable-next-line prettier/prettier
    (function (h, o, t, j, a, r) {
      // eslint-disable-next-line prettier/prettier
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      // eslint-disable-next-line prettier/prettier
      h._hjSettings = { hjid: 1913299, hjsv: 6 };
      // eslint-disable-next-line prettier/prettier
      a = o.getElementsByTagName('head')[0];
      // eslint-disable-next-line prettier/prettier
      r = o.createElement('script');
      r.async = 1;
      // eslint-disable-next-line prettier/prettier
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
      // eslint-disable-next-line prettier/prettier
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }, [pathname, search, hash]);
};
