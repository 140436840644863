/* global analytics */
import { useState, useMemo, useEffect } from 'react';
import useCurrentUser from 'src/customHooks/useCurrentUser';
import { capitalize } from 'src/lib/generic/handlers';
import { SEGMENT_TRACK_EVENTS_NAMES as segmentEventNames } from 'wmx-shared-code/productEvents';

export const SEGMENT_TRACK_EVENTS_NAMES = segmentEventNames;

const prodStage = 'prod';

export const triggerIdentifyEvent = ({ accountId, payload }) => {
  if (process.env.STAGE !== prodStage) return;

  analytics.identify(accountId, payload);
};

export const triggerTrackEvent = ({ eventName, payload }) => {
  if (process.env.STAGE !== prodStage) return;

  analytics.track(eventName, payload);
};

export const triggerPageEvent = ({ pageName, payload }) => {
  if (process.env.STAGE !== prodStage) return;

  const capitalizedPageName = capitalize(pageName);

  analytics.page(capitalizedPageName, payload);
};

export const useIdentifyUserPayload = () => {
  const {
    acIntegration: { status: acIntegrationStatus } = {},
    stripeIntegration: { status: stripeIntegrationStatus } = {},
    accountId,
    accountType,
    authType,
    baseAcUrl,
    createdAt,
    customerId,
    daysLeft: trialDaysLeft,
    defaultCurrency,
    email,
    hasAtLeastOneCreatedFunnel,
    hasTagsCustomerSegment,
    loggedInAs,
    roles,
    user: { isUserLoggedIn, setIsUserLoggedIn },
  } = useCurrentUser();

  const userPayload = useMemo(() => {
    return {
      acIntegrationStatus,
      stripeIntegrationStatus,
      accountId,
      accountType,
      authType,
      baseAcUrl,
      createdAt,
      customerId,
      defaultCurrency,
      email,
      hasAtLeastOneCreatedFunnel,
      hasTagsCustomerSegment,
      name: email,
      roles,
      trialDaysLeft,
    };
  }, [
    acIntegrationStatus,
    accountId,
    accountType,
    authType,
    baseAcUrl,
    createdAt,
    customerId,
    defaultCurrency,
    email,
    hasAtLeastOneCreatedFunnel,
    hasTagsCustomerSegment,
    roles,
    stripeIntegrationStatus,
    trialDaysLeft,
  ]);

  return {
    userPayload,
    loggedInAs,
    isUserLoggedIn,
    setIsUserLoggedIn,
  };
};

export const sendPageSeenEvent = ({ pageName, payload }) => {
  triggerPageEvent({ pageName, payload });
  triggerTrackEvent({ eventName: pageName, payload });
};

export const useTriggerPageSeenEvent = ({ pageName, payload, shouldEventBeSent = true }) => {
  const [isSeenPageEventTriggered, setIsSeenPageEventTriggered] = useState(false);

  useEffect(() => {
    if (!pageName) return;
    if (!shouldEventBeSent) return;

    if (!isSeenPageEventTriggered) {
      sendPageSeenEvent({ pageName, payload });
      setIsSeenPageEventTriggered(true);
    }
  }, [isSeenPageEventTriggered, pageName, shouldEventBeSent, payload]);
};
