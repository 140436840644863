import PropTypes from 'prop-types';
import useLocalStorage from 'src/customHooks/useLocalStorage';
import { createContext, useContext, useMemo, useCallback } from 'react';
import { useTable } from 'react-table';
import { useState } from 'react';
import { isEqual } from 'lodash';

export const useColumnsOptions = ({ tableName }) => {
  const [hiddenColumns, setHiddenColumns] = useLocalStorage(`table-${tableName}-columns`);

  const getHiddenColumns = ({ columns, compulsoryColumns, defaultColumns }) => {
    return columns
      .filter((column) => {
        if (compulsoryColumns.includes(column.Header)) return false;
        if (hiddenColumns[column.accessor] === true) return false;
        if (hiddenColumns[column.accessor] === false) return true;
        return !defaultColumns.includes(column.Header);
      })
      .map((c) => c.accessor);
  };

  return { getHiddenColumns, setHiddenColumns, hiddenColumns };
};

const WmxTableContext = createContext(null);

export const TableProvider = ({ children, tableProps }) => {
  console.log('tableProps TableProvider', tableProps);

  const {
    columns = [],
    mainContainerRef,
    data = [],
    headerTooltips,
    extendRows,
    extendHeader,
    onRowClick,
    setNextChunk,
    hasMoreData,
    height,
    scrollableTarget,
    name,
    classNames,
    styles,
    autoInfiniteScrollOverflow,
    columnsOptions = false,
    compulsoryColumns = [],
    defaultColumns = [],
  } = tableProps;

  console.log('hasMoreData', hasMoreData);

  const { getHiddenColumns, setHiddenColumns, hiddenColumns } = useColumnsOptions({ tableName: name });
  const memoColumns = useMemo(() => columns, [columns]);
  const memoData = useMemo(() => data, [data]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns } = useTable({
    columns: memoColumns || [],
    data: memoData || [],
    ...(columnsOptions &&
      memoColumns && {
        initialState: {
          hiddenColumns: getHiddenColumns({ columns: memoColumns, compulsoryColumns, defaultColumns }),
        },
      }),
  });

  const allTableProps = {
    ...tableProps,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setHiddenColumns,
    hiddenColumns,
  };

  return <WmxTableContext.Provider value={{ ...allTableProps }}>{children}</WmxTableContext.Provider>;
};

export const useWmxTable = () => useContext(WmxTableContext);

TableProvider.propTypes = {
  children: PropTypes.any,
  tableProps: PropTypes.shape({
    autoInfiniteScrollOverflow: PropTypes.any,
    classNames: PropTypes.any,
    columns: PropTypes.any,
    columnsOptions: PropTypes.bool,
    compulsoryColumns: PropTypes.array,
    data: PropTypes.any,
    defaultColumns: PropTypes.array,
    extendHeader: PropTypes.any,
    extendRows: PropTypes.any,
    hasMoreData: PropTypes.any,
    headerTooltips: PropTypes.any,
    height: PropTypes.any,
    mainContainerRef: PropTypes.any,
    name: PropTypes.any,
    onRowClick: PropTypes.any,
    scrollableTarget: PropTypes.any,
    setNextChunk: PropTypes.any,
    styles: PropTypes.any,
  }),
};
