/* eslint-disable no-use-before-define */

import { useEffect, useState, useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import { useMutation } from '@redwoodjs/web';
import isEqual from 'lodash.isequal';

import { triggerTrackEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';

import {
  funnelData as RecoilFunnelData,
  currentFunnelPart as RecoilCurrentFunnelPart,
  currentSquare as RecoilCurrentSquare,
  eachPartLastSquareGlobalIdx as RecoilEachPartLastSquareGlobalIdx,
  funnelItems as RecoilFunnelItems,
  isEditViewRightPaneOpen as RecoilIsRightPaneOpen,
  editViewRightPaneDefaultSize as RecoilRightPaneDefaultSize,
  segmentBuilderElementClickedId,
  minorGlobalSquareModified as RecoilMinorGlobalSquareModified,
  funnelByIdRetrieved as RecoilFunnelByIdRetrieved,
  funnelPageSource as RecoilFunnelPageSource,
  funnelItemsArgumentToDb as RecoilFunnelItemsArgumentToDb,
  shouldFunnelInDbUpdate as RecoilShouldFunnelInDbUpdate,
  isPresentationViewRightPaneOpen as RecoilIsPresentatioViewRightPaneOpen,
  dataFromInitialFetch as RecoilDataFromInitialFetch,
  presentationViewRightPaneDefaultSize,
  clickedRowInPresentationView,
  FUNNEL_PAGE_SOURCES as RecoilFunnelPageSources,
} from 'src/atoms/funnelAtoms';
import { timeRange as RecoilTimeRange, initialTimeRangeAtom } from 'src/atoms/timeRangeAtom';

import useRightPane from 'src/customHooks/useRightPane';
import useDebounce from 'src/customHooks/useDebounce';

import { getSegmentQueryObject } from 'src/lib/funnelHelpers/funnelHelpers';
import { DEFAULT_INITIAL_SEGMENT_ELEMENTS } from 'src/lib/segmentBuilder/initialSegmentElements';
import { UPDATE_FUNNEL_IN_DB } from 'src/components/Funnel/funnelQueriesAndMutations';

export const FUNNEL_PAGE_SOURCES = RecoilFunnelPageSources;

const hasAnyElementData = (elements) => {
  // eslint-disable-next-line no-restricted-syntax
  if (!elements) return false;
  // eslint-disable-next-line no-restricted-syntax
  for (const el of elements) {
    if (Object.keys(el.value).length > 0) return true;
  }
  return false;
};

const isReadyToQueryHandler = (path, currentSquareSegmentElements) => {
  if (!path || !path[path.length - 1].final) {
    return false;
  }
  if (path[path.length - 1].segmentBuilder && !hasAnyElementData(currentSquareSegmentElements)) {
    return false;
  }
  return true;
};

const getShouldDialogOpen = ({ currentFunnelPart, currentSegmentElements, currentSquareIndex, funnelData }) => {
  const currentPanePath = funnelData[currentFunnelPart]?.squares[currentSquareIndex]?.panePath;
  const isInSegmentBuilderStep = currentPanePath?.[currentPanePath.length - 1].segmentBuilder;
  return isInSegmentBuilderStep && !hasAnyElementData(currentSegmentElements);
};

export const useFunnelData = () => {
  const [isRightPaneOpen, setIsRightPaneOpen] = useRecoilState(RecoilIsRightPaneOpen);
  const [rightPaneDefaultSize, setRightPaneDefaultSize] = useRecoilState(RecoilRightPaneDefaultSize);
  const [funnelData, setFunnelDataRecoil] = useRecoilState(RecoilFunnelData);
  const [currentFunnelPart, setCurrentFunnelPart] = useRecoilState(RecoilCurrentFunnelPart);
  const [minorGlobalSquareModified, setMinorGlobalSquareModified] = useRecoilState(RecoilMinorGlobalSquareModified);
  const [funnelByIdRetrieved, setFunnelByIdRetrieved] = useRecoilState(RecoilFunnelByIdRetrieved);
  const [funnelPageSource, setFunnelPageSource] = useRecoilState(RecoilFunnelPageSource);
  const [dataFromInitialFetch, setDataFromInitialFetch] = useRecoilState(RecoilDataFromInitialFetch);

  const setFunnelData = useCallback(
    (funnelDataObject) => {
      const funnelDataNoChange =
        isEqual(funnelDataObject.top, funnelData.top) &&
        isEqual(funnelDataObject.bottom, funnelData.bottom) &&
        isEqual(funnelDataObject.middle, funnelData.middle);

      if (funnelDataNoChange) return;
      setFunnelDataRecoil(funnelDataObject);
    },
    [setFunnelDataRecoil, funnelData.top, funnelData.bottom, funnelData.middle]
  );

  const { currentSquare, currentSquareIndex, currentSegmentElements, currentTypeOfItem } =
    useRecoilValue(RecoilCurrentSquare);

  const funnelItems = useRecoilValue(RecoilFunnelItems);
  const eachPartLastSquareGlobalIdx = useRecoilValue(RecoilEachPartLastSquareGlobalIdx);
  const { timeRange } = useRecoilValue(RecoilTimeRange);

  const [segmentItemsValue, setSegmentItems] = useState();
  const [isCurrentLastSquare, setIsCurrentLastSquare] = useState();
  const setClickedElementId = useSetRecoilState(segmentBuilderElementClickedId);
  const [shouldFunnelInDbUpdate, setShouldFunnelInDbUpdate] = useRecoilState(RecoilShouldFunnelInDbUpdate);

  const initialGlobalRecoil = {
    isRightPaneOpen,
    setIsRightPaneOpen,
    rightPaneDefaultSize,
    setRightPaneDefaultSize,
  };

  const {
    rightPaneOpenDefaultSize,
    onRightPaneOpen,
    onRightPaneClose,
    onRightPaneDragStarted,
    onRightPaneDragFinished,
    rightPaneStyles,
    onExtraConditionHandlerForPressingOutside,
    rightPaneRef,
  } = useRightPane({ globalRecoil: initialGlobalRecoil });

  const getSquareItems = (funnelPart, squareIdx) => {
    if (
      funnelData[funnelPart] &&
      funnelData[funnelPart].squares.length > 0 &&
      funnelData[funnelPart].squares[squareIdx]
    ) {
      const { typeOfItem, readyToQuery } = funnelData[funnelPart].squares[squareIdx];
      if (!readyToQuery) return [];
      const elements = funnelData[funnelPart].squares[squareIdx].segmentElements;
      return getSegmentQueryObject({ elements, typeOfItem, timeRange });
    }
    return [];
  };

  useEffect(() => {
    if (currentSegmentElements) {
      const currentSegmentItems = getSegmentQueryObject({
        elements: currentSegmentElements,
        typeOfItem: currentTypeOfItem,
        timeRange,
      });
      setSegmentItems(currentSegmentItems);
    }
  }, [currentSegmentElements, currentTypeOfItem, timeRange]);

  useEffect(() => {
    const squaresLength = funnelData[currentFunnelPart]?.squares?.length;
    setIsCurrentLastSquare(squaresLength - 1 === currentSquareIndex);
  }, [funnelData, currentSquareIndex, currentFunnelPart]);

  // should the dialog be open? return true/false
  const shouldDialogOpen = useMemo(
    () =>
      getShouldDialogOpen({
        currentFunnelPart,
        currentSegmentElements,
        currentSquareIndex,
        funnelData,
      }),
    [currentFunnelPart, currentSegmentElements, currentSquareIndex, funnelData]
  );

  // should the slide in be closed? return true/false
  const getShouldSlideClose = () => {
    if (!funnelData[currentFunnelPart]?.squares[currentSquareIndex].isSelected) {
      return false;
    }
    const currentPanePath = funnelData[currentFunnelPart]?.squares[currentSquareIndex]?.panePath;
    if (currentPanePath) {
      const isInSegmentBuilderStep = currentPanePath?.[currentPanePath.length - 1].segmentBuilder;
      const isFinalStep = currentPanePath?.[currentPanePath.length - 1].final;
      return !isInSegmentBuilderStep && isFinalStep;
    }
    return false;
  };

  // should the slide in be closed? return true/false
  const getIsSquareFinalStep = (squareIdx) => {
    const currentPanePath = funnelData[currentFunnelPart]?.squares[squareIdx]?.panePath;
    if (currentPanePath) {
      const isFinalStep = currentPanePath?.[currentPanePath.length - 1].final;
      return isFinalStep;
    }
    return false;
  };

  const toggleSelectedSquares = (funnelPartProp, selectIdx) => {
    // toggle all squares to unselected and optionally pass un square index to select
    const toggledSquares = funnelData[funnelPartProp]?.squares.map((block, idx) => {
      if (selectIdx === idx) {
        return {
          ...block,
          isSelected: true,
          segmentElements: block.segmentElements.filter((el, elIdx) => {
            return elIdx === 0 || Object.keys(el.value).length > 0;
          }),
        };
      }
      return {
        ...block,
        // Filter empty elements to avoid segment items without a value  except the first one
        isSelected: false,
      };
    });

    setFunnelData({
      ...funnelData,
      [funnelPartProp]: {
        ...funnelData[funnelPartProp],
        squares: toggledSquares,
      },
    });
  };

  const toggleAllFunnelParts = useCallback(
    (funnelDataRef) => {
      // toggle all squares to unselected and optionally pass un square index to select
      const parts = Object.keys(funnelDataRef);
      let newFunnelData = {};
      parts.forEach((part) => {
        newFunnelData = {
          ...newFunnelData,
          [part]: {
            ...funnelDataRef[part],
            squares: filterEmptySquares(funnelDataRef[part].squares),
          },
        };
      });
      setFunnelData(newFunnelData);
    },
    [setFunnelData]
  );

  const filterEmptySquares = (squares) => {
    const filteredSquares = squares.filter((square, idx) => {
      return idx === 0 || !square.isEmpty;
    });

    return filteredSquares.map((square, idx) => {
      return {
        ...square,
        isSelected: false,
        isLastSquare: !filteredSquares.length > 0 || filteredSquares.length - 1 === idx,
      };
    });
  };

  const deleteSquare = (selectIdx) => {
    // delete the square by idx
    const isUniqueSquare = funnelData[currentFunnelPart]?.squares.length === 1;
    const { squareGlobalIdx, typeOfItem } = funnelData[currentFunnelPart]?.squares[selectIdx];

    if (isUniqueSquare) {
      const emptySquare = getNewSquare(currentFunnelPart);
      const newSquares = [{ ...emptySquare, isSelected: false }];

      onFunnelItemsQueryUpdate({ newSquares, squareGlobalIdx });
    } else {
      const clonedSquares = funnelData[currentFunnelPart]?.squares.slice();
      clonedSquares.splice(selectIdx, 1);
      const squaresLength = clonedSquares.length;
      const newSquaresArray = clonedSquares.map((block, idx) => {
        return {
          ...block,
          isLastSquare: !squaresLength > 0 || squaresLength - 1 === idx,
        };
      });

      const whenLeadsIsInORrelationshipInTofu =
        squareGlobalIdx === 1 && currentFunnelPart === 'top' && typeOfItem !== 'stripeProductItem'
          ? 0
          : squareGlobalIdx;

      onFunnelItemsQueryUpdate({ newSquares: newSquaresArray, squareGlobalIdx: whenLeadsIsInORrelationshipInTofu });
    }
    onRightPaneClose();
  };

  const unsetAllPrevSquares = (funnelPart) => {
    setClickedElementId('first');
    // unselect and set lastSquare to false
    return funnelData[funnelPart].squares.map((square) => {
      return {
        ...square,
        isSelected: false,
        isLastSquare: false,
      };
    });
  };

  const getNewSquare = (funnelPartProp) => {
    return {
      typeOfItem: '',
      isSelected: true,
      isEmpty: true,
      isLastSquare: true,
      segmentElements: DEFAULT_INITIAL_SEGMENT_ELEMENTS,
      funnelPart: funnelPartProp,
    };
  };

  const addNewSquare = (newSquare, funnelPart) => {
    setClickedElementId('first');
    const squares = [...unsetAllPrevSquares(funnelPart), newSquare];
    setFunnelData({
      ...funnelData,
      [funnelPart]: {
        ...funnelData[funnelPart],
        squares,
      },
    });
  };

  const isSquareEmptyHandler = (path) => {
    if (!path) {
      return true;
    }
    if (!path[path.length - 1].final) {
      return true;
    }
    if (path[path.length - 1].segmentBuilder && !hasAnyElementData(currentSquare.segmentElements)) {
      return true;
    }
    return false;
  };

  const updateCurrentSquarePathLocation = (path) => {
    const modSquare = {
      ...funnelData[currentFunnelPart]?.squares[currentSquareIndex],
      panePath: path,
      segmentElements:
        funnelData[currentFunnelPart]?.squares[currentSquareIndex]?.segmentElements || DEFAULT_INITIAL_SEGMENT_ELEMENTS,
      isEmpty: isSquareEmptyHandler(path),
      readyToQuery: isReadyToQueryHandler(
        path,
        funnelData[currentFunnelPart]?.squares[currentSquareIndex]?.segmentElements
      ),
      isLastSquare: currentSquareIndex === funnelData[currentFunnelPart]?.squares.length - 1,
      typeOfItem: path[path.length - 1].final ? path[path.length - 1].accessor : null,
      ...(isSquareEmptyHandler(path) && { segmentElements: DEFAULT_INITIAL_SEGMENT_ELEMENTS }),
    };
    const clonedSquares = funnelData[currentFunnelPart]?.squares.slice();
    clonedSquares.splice(currentSquareIndex, 1, modSquare);

    onFunnelItemsQueryUpdate({ newSquares: clonedSquares });
  };

  const handleOnDialogCloseByCrossClick = () => {
    const elements = funnelData[currentFunnelPart].squares[currentSquareIndex].segmentElements;
    const onlyOneEmptyElement = elements.length === 1 && !hasAnyElementData(elements);
    if (onlyOneEmptyElement) {
      const clonedPath = funnelData[currentFunnelPart].squares[currentSquareIndex].panePath.slice();
      clonedPath.pop();
      const direction = 'back';
      updateCurrentSquarePathLocation(clonedPath, direction);
    } else {
      const emptySegmentIdx = funnelData[currentFunnelPart].squares[currentSquareIndex].segmentElements.findIndex(
        (item) => {
          return !item.value;
        }
      );
      if (emptySegmentIdx > -1) {
        onRemoveElement(emptySegmentIdx);
      }
    }
  };

  const onRemoveElement = (nodeIdx) => {
    let modSquare = {};
    const currentSquareInFunnelPart = funnelData[currentFunnelPart]?.squares[currentSquareIndex];

    const clonedElements = [...currentSegmentElements];
    clonedElements.splice(nodeIdx, 1);

    const isFirstElement = nodeIdx === 0;
    const isThereNextElement = currentSegmentElements.length > 1;

    // convert to AND an OR that is coming after a removed AND, to keep the group logic.
    const currentElementIsAnd = currentSegmentElements[nodeIdx]?.connection === 'AND';
    const nextElementIsOr = currentSegmentElements[nodeIdx + 1]?.connection === 'OR';
    if (currentElementIsAnd && nextElementIsOr) {
      clonedElements[nodeIdx] = { ...clonedElements[nodeIdx], connection: 'AND' };
    }

    // convert to null an when the first element was removed.
    if (isFirstElement && isThereNextElement) {
      clonedElements[nodeIdx] = { ...clonedElements[nodeIdx], connection: null };
    }

    // Filter empty elements to avoid segment items without a value  except the first one
    const filteredNotEmptyElements = clonedElements.filter((el, idx) => {
      return idx === 0 || Object.keys(el.value).length > 0;
    });

    if (nodeIdx === 0 && currentSegmentElements.length === 1) {
      modSquare = {
        ...currentSquareInFunnelPart,
        isEmpty: true,
        readyToQuery: false,
        segmentElements: DEFAULT_INITIAL_SEGMENT_ELEMENTS,
      };
    } else {
      modSquare = {
        ...currentSquareInFunnelPart,
        readyToQuery: hasAnyElementData(filteredNotEmptyElements),
        isEmpty: !hasAnyElementData(filteredNotEmptyElements),
        segmentElements: filteredNotEmptyElements,
      };
    }
    const clonedSquares = funnelData[currentFunnelPart]?.squares.slice();
    clonedSquares.splice(currentSquareIndex, 1, modSquare);

    onFunnelItemsQueryUpdate({ newSquares: clonedSquares });
  };

  const getQueryGroupIdx = (funnelPartName) => {
    // The query reponse in groups of people, each part of the funnel get a different group according to the position
    // This function search for the corresponding part of the respond of the service
    if (funnelPartName !== 'Top') {
      // for the middle and the bottom it is always the last square of the part
      return getLastSquareOfPartGlobalIndex(funnelPartName);
    }
    if (hasFrontendPurchaseAtTheEnd()) {
      return getLastGroupIdx(funnelPartName);
    }

    return null;
  };

  const getSquareInfo = (funnelPartName, squareIndex) => {
    return funnelItems[`funnelItems${funnelPartName}`].squaresInfo[squareIndex];
  };

  const getLastSquareOfPartGlobalIndex = (funnelPartName) => {
    const currentSquaresInfo = funnelItems[`funnelItems${funnelPartName}`].squaresInfo;
    const currentSquaresInfoLength = currentSquaresInfo.length;
    const lastSquareInfo = currentSquaresInfoLength && currentSquaresInfo[currentSquaresInfoLength - 1];
    return lastSquareInfo.squareIndexInService;
  };

  const hasFrontendPurchaseAtTheEnd = () => {
    const currentSquaresInfo = funnelItems.funnelItemsTop.squaresInfo;
    const currentSquaresInfoLength = currentSquaresInfo.length;
    const lastSquareInfo = currentSquaresInfoLength && currentSquaresInfo[currentSquaresInfoLength - 1];
    return lastSquareInfo?.squareName?.accessor === 'frontEndPurchase';
  };

  const isCurrentFrontEndPurchase = (funnelPartName, squareIndex) => {
    const currentSquaresInfo = funnelData[funnelPartName.toLowerCase()]?.squares;
    const currentSquareInfo = currentSquaresInfo && currentSquaresInfo[squareIndex];
    const currentAccessor =
      currentSquareInfo &&
      currentSquareInfo.panePath &&
      currentSquareInfo.panePath.length >= 2 &&
      currentSquareInfo.panePath[1].accessor;
    return currentAccessor === 'frontEndPurchase';
  };

  const isCurrentAnyProduct = (funnelPartName, squareIndex) => {
    const currentSquaresInfo = funnelData[funnelPartName.toLowerCase()]?.squares;
    const currentSquareInfo = currentSquaresInfo && currentSquaresInfo[squareIndex];
    const currentAccessor =
      currentSquareInfo &&
      currentSquareInfo.panePath &&
      currentSquareInfo.panePath.length >= 2 &&
      currentSquareInfo.panePath[currentSquareInfo.panePath.length - 1].accessor;
    return currentAccessor === 'anyProduct';
  };

  const nextIsFrontEndPurchase = (funnelPartName, squareIndex) => {
    /* This is based on funnelData and not funnelItems because we nee to get teh data before funnelItems is updated */
    const currentSquaresInfo = funnelData[funnelPartName.toLowerCase()].squares;
    const nextSquareInfo = currentSquaresInfo[squareIndex + 1];
    const nextAccessor =
      nextSquareInfo &&
      nextSquareInfo.panePath &&
      nextSquareInfo.panePath.length >= 2 &&
      nextSquareInfo.panePath[1].accessor;
    return nextAccessor && nextAccessor === 'frontEndPurchase';
  };

  const isSquareReadyToQuery = (funnelPart, squareIndex) => {
    /* This is based on funnelData and not funnelItems because we nee to get teh data before funnelItems is updated */
    if (!funnelPart || squareIndex === null || squareIndex === '' || typeof squareIndex === 'undefined') return false;
    return funnelData[funnelPart]?.squares[squareIndex]?.readyToQuery;
  };

  const getLastGroupIdx = (funnelPartName) => {
    const lastItemIdx = funnelItems[`funnelItems${funnelPartName}`].funnelPart.length - 1;
    return lastItemIdx;
  };

  function onFunnelItemsQueryUpdate({ newSquares, squareGlobalIdx }) {
    updateMinorGlobalSquareModified({ squareGlobalIdx });

    const { funnelId } = funnelByIdRetrieved;
    if (funnelId) {
      setShouldFunnelInDbUpdate({
        requestId: shouldFunnelInDbUpdate.requestId + 1,
        shouldUpdate: true,
      });
    }

    setFunnelData({
      ...funnelData,
      [currentFunnelPart]: {
        ...funnelData[currentFunnelPart],
        squares: newSquares,
      },
    });
  }

  function updateMinorGlobalSquareModified({ squareGlobalIdx } = {}) {
    const currentSquareInFunnelPart = funnelData[currentFunnelPart]?.squares[currentSquareIndex];

    const {
      squareGlobalIdx: currentSquareGlobalIdx = squareGlobalIdx,
      funnelPart: funnelPartName = currentFunnelPart,
      funnelPartNameIdx = currentFunnelPart === 'top' ? 0 : currentFunnelPart === 'middle' ? 1 : 2,
    } = currentSquareInFunnelPart || {};

    const { globalSquareIndex: minorGlobalSquareIndexModified } = minorGlobalSquareModified;

    const newMinorGlobalSquareModified =
      minorGlobalSquareIndexModified === null
        ? currentSquareGlobalIdx
        : minorGlobalSquareIndexModified > currentSquareGlobalIdx
        ? currentSquareGlobalIdx
        : minorGlobalSquareIndexModified;

    if (newMinorGlobalSquareModified !== minorGlobalSquareIndexModified) {
      setMinorGlobalSquareModified({
        funnelPartName,
        funnelPartNameIdx,
        globalSquareIndex: newMinorGlobalSquareModified,
      });
    }
  }

  return {
    currentSquare,
    currentSquareIndex,
    currentSegmentElements,
    toggleSelectedSquares,
    isCurrentLastSquare,
    isSquareReadyToQuery,
    isReadyToQueryHandler,
    isSquareEmptyHandler,
    getIsSquareFinalStep,
    setCurrentFunnelPart,
    currentFunnelPart,
    setFunnelData,
    funnelData,
    segmentItemsValue,
    getSquareItems,
    deleteSquare,
    shouldDialogOpen,
    getShouldSlideClose,
    handleOnDialogCloseByCrossClick,
    eachPartLastSquareGlobalIdx,
    getNewSquare,
    addNewSquare,
    unsetAllPrevSquares,
    updateCurrentSquarePathLocation,
    funnelItems,
    toggleAllFunnelParts,
    isRightPaneOpen,
    setIsRightPaneOpen,
    rightPaneDefaultSize,
    setRightPaneDefaultSize,
    rightPaneOpenDefaultSize,
    onRightPaneOpen,
    onRightPaneClose,
    onRightPaneDragStarted,
    onRightPaneDragFinished,
    rightPaneStyles,
    rightPaneRef,
    onExtraConditionHandlerForPressingOutside,
    onRemoveElement,
    getQueryGroupIdx,
    hasFrontendPurchaseAtTheEnd,
    nextIsFrontEndPurchase,
    isCurrentFrontEndPurchase,
    isCurrentAnyProduct,
    getLastGroupIdx,
    getSquareInfo,
    setClickedElementId,
    hasAnyElementData,
    minorGlobalSquareModified,
    setMinorGlobalSquareModified,
    updateMinorGlobalSquareModified,
    funnelByIdRetrieved,
    setFunnelByIdRetrieved,
    funnelPageSource,
    setFunnelPageSource,
    onFunnelItemsQueryUpdate,
    dataFromInitialFetch,
    setDataFromInitialFetch,
  };
};

export function useMinorGlobalSquareModifiedByTimeRange() {
  /* ------------------------------------------------------------------------------------------------
   * When global Time Range changes, we want that every number changes
   * When no modification was done in a funnel retrieved by id, getFunnelById
   * will be called again
   * -----------------------------------------------------------------------------------------------*/

  const [minorGlobalSquareModified, setMinorGlobalSquareModified] = useRecoilState(RecoilMinorGlobalSquareModified);
  const { timeRange } = useRecoilValue(RecoilTimeRange);

  useEffect(() => {
    const { globalSquareIndex } = minorGlobalSquareModified;
    if (globalSquareIndex === null) return;

    const isInitialTimeRange = isEqual(initialTimeRangeAtom, timeRange);

    if (globalSquareIndex !== 0 && !isInitialTimeRange) {
      setMinorGlobalSquareModified({
        funnelPartName: 'top',
        funnelPartNameIdx: 0,
        globalSquareIndex: 0,
      });
    }
  });
}

export function useUpdateFunnelInDb() {
  const funnelItemsCalculatedToDb = useRecoilValue(RecoilFunnelItemsArgumentToDb);
  const funnelByIdRetrieved = useRecoilValue(RecoilFunnelByIdRetrieved);
  const [shouldFunnelInDbUpdate, setShouldFunnelInDbUpdate] = useRecoilState(RecoilShouldFunnelInDbUpdate);

  const { funnelId: retrievedFunnelId, funnelName } = funnelByIdRetrieved;
  const { requestId: globalRequestId, shouldUpdate } = shouldFunnelInDbUpdate;

  const [updateFunnelById, { loading, data: { updateFunnelReport } = {} }] = useMutation(UPDATE_FUNNEL_IN_DB);
  const debouncedUpdateRequestId = useDebounce(globalRequestId, 1000);

  useEffect(() => {
    if (debouncedUpdateRequestId && shouldUpdate) {
      setShouldFunnelInDbUpdate({
        requestId: globalRequestId,
        shouldUpdate: false,
      });

      updateFunnelById({
        variables: { funnelItemsToDb: funnelItemsCalculatedToDb, id: retrievedFunnelId, funnelName },
      });
    }
  }, [
    debouncedUpdateRequestId,
    funnelItemsCalculatedToDb,
    funnelName,
    globalRequestId,
    retrievedFunnelId,
    setShouldFunnelInDbUpdate,
    shouldUpdate,
    updateFunnelById,
  ]);

  return {
    updatingFunnelInDbLoading: loading,
    updatingFunnelInDbResponse: updateFunnelReport,
  };
}

export function useUpdateSquaresIndexes() {
  const [funnelData, setFunnelData] = useRecoilState(RecoilFunnelData);

  useEffect(() => {
    const topSquares = funnelData.top.squares;
    const middleSquares = funnelData.middle.squares;
    const bottomSquares = funnelData.bottom.squares;

    const addIndexesToFunnelData = (funnelPartSquares, funnelPartName) => {
      return funnelPartSquares.map((square, squareIdx) => {
        const globalIdx =
          funnelPartName === 'top'
            ? squareIdx
            : funnelPartName === 'middle'
            ? topSquares.length + squareIdx
            : topSquares.length + middleSquares.length + squareIdx;

        const funnelPartNameIdx = funnelPartName === 'top' ? 0 : funnelPartName === 'middle' ? 1 : 2;

        return {
          ...square,
          squareGlobalIdx: globalIdx,
          squareIdxInPart: squareIdx,
          funnelPartNameIdx,
        };
      });
    };

    const topSquaresWithIndexes = addIndexesToFunnelData(topSquares, 'top');
    const middleSquaresWithIndexes = addIndexesToFunnelData(middleSquares, 'middle');
    const bottomSquaresWithIndexes = addIndexesToFunnelData(bottomSquares, 'bottom');

    const isTopSquaresEqualToAddedIndexes = isEqual(topSquaresWithIndexes, topSquares);
    const isMiddleSquaresEqualToAddedIndexes = isEqual(middleSquaresWithIndexes, middleSquares);
    const isBottomSquaresEqualToAddedIndexes = isEqual(bottomSquaresWithIndexes, bottomSquares);

    if (
      !isTopSquaresEqualToAddedIndexes ||
      !isMiddleSquaresEqualToAddedIndexes ||
      !isBottomSquaresEqualToAddedIndexes
    ) {
      setFunnelData({
        ...funnelData,
        top: { ...funnelData.top, squares: [...topSquaresWithIndexes] },
        middle: { ...funnelData.middle, squares: [...middleSquaresWithIndexes] },
        bottom: { ...funnelData.bottom, squares: [...bottomSquaresWithIndexes] },
      });
    }
  }, [funnelData, setFunnelData]);
}

export function useTriggerFunnelFirstStepEvent() {
  const [isFirstStepEventTriggered, setIsFirstStepEventTriggered] = useState(false);
  const {
    totals: { service: totalsInFunnel },
  } = useRecoilValue(RecoilFunnelItems);
  const currentFunnelPart = useRecoilValue(RecoilCurrentFunnelPart);
  const funnelPageSource = useRecoilValue(RecoilFunnelPageSource);

  useEffect(() => {
    const isNewFunnel = funnelPageSource === FUNNEL_PAGE_SOURCES.new;
    const isFunnelWithData = totalsInFunnel.length;

    if (!isFirstStepEventTriggered && isFunnelWithData && isNewFunnel) {
      setIsFirstStepEventTriggered(true);

      const typeOfItem = Object.keys(totalsInFunnel[0][0])[0];
      const eventPayload = { typeOfItem, funnelPart: currentFunnelPart };
      triggerTrackEvent({ eventName: SEGMENT_TRACK_EVENTS_NAMES.firstFunnelStepSet, payload: eventPayload });
    }
  }, [currentFunnelPart, funnelPageSource, isFirstStepEventTriggered, totalsInFunnel]);
}

export function useRestartFunnelToDefaultValues() {
  const resetFunnelData = useResetRecoilState(RecoilFunnelData);
  const resetSegmentBuilderClickedId = useResetRecoilState(segmentBuilderElementClickedId);
  const resetMinorGlobalSquareModified = useResetRecoilState(RecoilMinorGlobalSquareModified);
  const resetFunnelByIdRetrieved = useResetRecoilState(RecoilFunnelByIdRetrieved);
  const resetIsRightPaneOpen = useResetRecoilState(RecoilIsRightPaneOpen);
  const resetRightPaneDefaultSize = useResetRecoilState(RecoilRightPaneDefaultSize);
  const resetIsPresentatioViewRightPaneOpen = useResetRecoilState(RecoilIsPresentatioViewRightPaneOpen);
  const resetPresentationViewRightPaneDefaultSize = useResetRecoilState(presentationViewRightPaneDefaultSize);
  const resetClickedRowInPresentationView = useResetRecoilState(clickedRowInPresentationView);
  const resetCurrentFunnelPart = useResetRecoilState(RecoilCurrentFunnelPart);
  const resetFunnelPageSource = useResetRecoilState(RecoilFunnelPageSource);
  const resetDataFromInitialFetch = useResetRecoilState(RecoilDataFromInitialFetch);
  const resetShouldFunnelInDbUpdate = useResetRecoilState(RecoilShouldFunnelInDbUpdate);

  const resetFunnelToDefaultValues = useCallback(() => {
    resetFunnelData();
    resetSegmentBuilderClickedId();
    resetMinorGlobalSquareModified();
    resetFunnelByIdRetrieved();
    resetIsRightPaneOpen();
    resetRightPaneDefaultSize();
    resetIsPresentatioViewRightPaneOpen();
    resetPresentationViewRightPaneDefaultSize();
    resetClickedRowInPresentationView();
    resetCurrentFunnelPart();
    resetFunnelPageSource();
    resetDataFromInitialFetch();
    resetShouldFunnelInDbUpdate();
  }, [
    resetFunnelData,
    resetSegmentBuilderClickedId,
    resetMinorGlobalSquareModified,
    resetFunnelByIdRetrieved,
    resetIsRightPaneOpen,
    resetRightPaneDefaultSize,
    resetIsPresentatioViewRightPaneOpen,
    resetPresentationViewRightPaneDefaultSize,
    resetClickedRowInPresentationView,
    resetCurrentFunnelPart,
    resetFunnelPageSource,
    resetDataFromInitialFetch,
    resetShouldFunnelInDbUpdate,
  ]);

  return {
    resetFunnel: resetFunnelToDefaultValues,
  };
}
