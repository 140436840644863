import { discoverCustomerMetadataSchema, getMetadataColumns } from 'src/components/Generic/Tables/Table/tableUtils';
import {
  formatPeopleData,
  ProfilesButtons,
  getColumnsWithOrderBy,
} from 'src/components/PeopleReportsMenu/PeopleReportsTable/peopleTableSettingsHelpers';
import { ColumnsTooltipText } from 'src/components/Generic/Tooltip/Tooltip';

import { ORDER_BY_PEOPLE_ENUM, ORDER_COLUMN_DIRECTIONS } from 'wmx-shared-code/reports/people';
import { METADATA_TYPES } from 'wmx-shared-code/tablesGlobalsVariables';

const emailTooltipStyles = {
  container: {
    maxWidth: 'fit-content',
  },
};

const creationHeaderName = 'Created';

const peopleColumns = [
  {
    Header: 'Email',
    accessor: 'email',
    orderBy: ORDER_BY_PEOPLE_ENUM.email,
    orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.asc,
    tooltipElement: <ColumnsTooltipText text="The email linked to the contact." />,
    triggerStyles: emailTooltipStyles,
  },
  {
    Header: creationHeaderName,
    accessor: 'createdAt',
    orderBy: ORDER_BY_PEOPLE_ENUM.createdAt,
    orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
    tooltipElement: <ColumnsTooltipText text="The contact creation date." />,
  },
  {
    Header: 'Profiles',
    accessor: 'profiles',
    tooltipElement: <ColumnsTooltipText text="The profiles linked to the contact." />,
  },
];

const segmentColumns = [
  {
    Header: 'Email',
    accessor: 'email',
    orderBy: ORDER_BY_PEOPLE_ENUM.email,
    orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.asc,
    tooltipElement: <ColumnsTooltipText text="The email linked to the contact." />,
    triggerStyles: emailTooltipStyles,
  },
  {
    Header: 'Date',
    accessor: 'createdAt',
    orderBy: ORDER_BY_PEOPLE_ENUM.createdAt,
    orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
    tooltipElement: <ColumnsTooltipText text="The contact creation date." />,
  },
  {
    Header: 'Profiles',
    accessor: 'profiles',
  },
];

export const getAllPeopleProps = ({ dataChunk, onOrderBy, isUserWithStripe }) => {
  const columns = peopleColumns;
  const customerMetadataSchema = discoverCustomerMetadataSchema({ items: dataChunk });

  const columnsWithMeta = [
    ...columns,
    ...getMetadataColumns({ metadataSchema: customerMetadataSchema, metaType: METADATA_TYPES.customer }),
  ];

  const columnsWithOrderByConfig = getColumnsWithOrderBy({ columns, onOrderBy });

  const extendedHeaders = {
    columns: columnsWithOrderByConfig,
  };

  return {
    name: 'allPeopleReportTable',
    title: 'All',
    titleTooltip: isUserWithStripe ? (
      <ColumnsTooltipText text="List of contacts generated over the time range. A person becomes a contact either when it is createad in Active campaign or when it makes its first successful charge through Stripe. The first occurrence of this two options, will set the contact creation date." />
    ) : (
      <ColumnsTooltipText text="List of contacts generated over the time range. A person becomes a contact when it is createad in Active campaign." />
    ),
    data: formatPeopleData({ rows: dataChunk }),
    columns: columnsWithMeta,
    extendHeader: extendedHeaders,
    extendRows: {
      columns: [
        {
          accessor: 0, // "Email"
          className: 'max-w-xs',
        },
        {
          accessor: 'profiles',
          cellChildren: (cellParams) => <ProfilesButtons {...cellParams} />,
        },
      ],
    },
    columnsOptions: true,
    compulsoryColumns: ['Email'],
    defaultColumns: [creationHeaderName, 'Profiles'],
  };
};

export const getCustomersPeopleProps = ({ dataChunk, onOrderBy, isUserWithStripe, title }) => {
  const columns = peopleColumns;
  // const customerMetadataSchema = discoverCustomerMetadataSchema({ items: dataChunk });
  const columnsWithMeta = [
    ...columns,
    // ...getMetadataColumns({ metadataSchema: customerMetadataSchema, metaType: METADATA_TYPES.customer }),
  ];
  const columnsWithOrderByConfig = getColumnsWithOrderBy({ columns, onOrderBy });

  const extendedHeaders = {
    columns: columnsWithOrderByConfig,
  };

  return {
    name: 'customersPeopleReportTable',
    title: title || 'Customers',
    titleTooltip: isUserWithStripe ? (
      <ColumnsTooltipText text="List of new customers generated over the time range. A contact becomes a customer when a first successful charge is made." />
    ) : (
      <ColumnsTooltipText text="List of new customers generated over the time range." />
    ),
    data: formatPeopleData({ rows: dataChunk }),
    columns: columnsWithMeta,
    extendHeader: extendedHeaders,
    extendRows: {
      columns: [
        {
          accessor: 0, // "Email"
          className: 'max-w-xs',
        },
        {
          accessor: 'profiles',
          cellChildren: (cellParams) => <ProfilesButtons {...cellParams} />,
        },
      ],
    },
    columnsOptions: true,
    compulsoryColumns: ['Email'],
    defaultColumns: [creationHeaderName, 'Profiles'],
  };
};

export const getSegmentPeopleProps = ({ dataChunk, onOrderBy }) => {
  const columns = segmentColumns;
  // const customerMetadataSchema = discoverCustomerMetadataSchema({ items: dataChunk });
  const columnsWithMeta = [
    ...columns,
    // ...getMetadataColumns({ metadataSchema: customerMetadataSchema, metaType: METADATA_TYPES.customer }),
  ];
  const columnsWithOrderByConfig = getColumnsWithOrderBy({ columns, onOrderBy });

  const extendedHeaders = {
    columns: columnsWithOrderByConfig,
  };

  return {
    name: 'segmentPeopleReportTable',
    data: formatPeopleData({ rows: dataChunk }),
    columns: columnsWithMeta,
    extendHeader: extendedHeaders,
    extendRows: {
      columns: [
        {
          accessor: 0, // "Email"
          className: 'max-w-xs',
        },
        {
          accessor: 'profiles',
          cellChildren: (cellParams) => <ProfilesButtons {...cellParams} />,
        },
      ],
    },
    columnsOptions: true,
    compulsoryColumns: ['Email'],
    defaultColumns: ['Date', 'Profiles'],
  };
};

export const getLeadsPeopleProps = ({ dataChunk, onOrderBy }) => {
  const columns = peopleColumns;
  const customerMetadataSchema = discoverCustomerMetadataSchema({ items: dataChunk });
  const columnsWithMeta = [
    ...columns,
    ...getMetadataColumns({ metadataSchema: customerMetadataSchema, metaType: METADATA_TYPES.customer }),
  ];

  const columnsWithOrderByConfig = getColumnsWithOrderBy({ columns, onOrderBy });

  const extendedHeaders = {
    columns: columnsWithOrderByConfig,
  };

  return {
    name: 'leadsPeopleReportTable',
    data: formatPeopleData({ rows: dataChunk }),
    title: 'Leads',
    titleTooltip: (
      <ColumnsTooltipText text="List of leads generated over the time range. A contact becomes a lead when it is createad in Active campaign." />
    ),
    columns: columnsWithMeta,
    extendHeader: extendedHeaders,
    extendRows: {
      columns: [
        {
          accessor: 0, // "Email"
          className: 'max-w-xs',
        },
        {
          accessor: 'profiles',
          cellChildren: (cellParams) => <ProfilesButtons {...cellParams} buttons={{ ac: true, stripe: true }} />,
        },
      ],
    },
    columnsOptions: true,
    compulsoryColumns: ['Email'],
    defaultColumns: [creationHeaderName, 'Profiles'],
  };
};
