import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { setThousandsComma } from 'wmx-shared-code/currency/currency';

import { Flex, HelperText, Spacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { useDashboard } from 'src/contexts/Dashboard';

import { isBiggerThan } from 'src/lib/chargesTransformations/chargesTransformations';
import { navigate, routes } from '@redwoodjs/router';
import items from 'src/components/Generic/Tooltip/icons';
import * as RadixTooltip from '@radix-ui/react-tooltip';

export function Tooltip({ children, absolute = true }) {
  const icon = items.strongInfoBox({ bgColor: 'text-wmxText-100' });

  return (
    <div className={absolute ? 'absolute right-3 top-4' : ''}>
      <RadixTooltip.Provider delayDuration={300}>
        <RadixTooltip.Root>
          <RadixTooltip.Trigger>
            <div className="ml-1.5">{icon}</div>
          </RadixTooltip.Trigger>
          <RadixTooltip.Content align="start">
            <div className="text-wmxText-100 max-w-xs bg-black p-2">{children}</div>
          </RadixTooltip.Content>
        </RadixTooltip.Root>
      </RadixTooltip.Provider>
    </div>
  );
}

function Body({ children }) {
  return (
    <Flex gap={1} shrink items="end">
      {children}
    </Flex>
  );
}

function Chart({ children }) {
  return (
    <Flex gap={1} shrink items="end" wfull>
      {children}
    </Flex>
  );
}

function MetricChart({ children }) {
  return <div className="w-full flex flex-1 justify-center items-center">{children}</div>;
}

export function Root({ children, classNames, editMode }) {
  return (
    <div
      className={`h-full w-full bg-wmxHighlightDark-100 rounded-lg p-2 px-4 ${
        editMode ? 'border border-wmxPrimary-100 rounded-lg cursor-grabbing' : ''
      }`}
    >
      <div className={`${classNames?.subContainer || ''} h-full w-full flex flex-col `}>
        <Spacer size="xs" />
        {children}
        <Spacer size="xs" />
      </div>
    </div>
  );
}

export function Top({ children, openButton = true }) {
  return <div className={`flex ${openButton ? 'justify-center' : ''}  items-center w-full h-8`}>{children}</div>;
}

export function Value({ children, autoScale, size = 'lg' }) {
  return (
    <>
      <div
        className={classnames('text-wmxText-100  font-bold leading-none', {
          'transform origin-left scale-90': isBiggerThan(children, 6, 7) && autoScale,
          'text-md': size === 'sm',
          'text-lg': size === 'md',
          'text-2xl': size === 'lg',
          'text-3xl': size === 'xl',
        })}
      >
        {children || 0}
      </div>
      <Spacer size="md" />
    </>
  );
}

const getDirection = (change) => {
  if (change > 0) return 'up';
  if (change < 0) return 'down';
  return 'none';
};

export function Change({ children }) {
  const direction = getDirection(children);
  const { timeRangeLabel } = useDashboard();
  if (!children || timeRangeLabel === 'All') return <></>;
  return (
    <HelperText secondary={false}>
      <div
        className={classnames('', {
          'text-red-400': direction === 'down',
          'text-green-400': direction === 'up',
        })}
      >
        <Flex gap={1}>
          <ChangeArrow direction={direction} />
          <p>{setThousandsComma(children)}%</p>
        </Flex>
      </div>
    </HelperText>
  );
}

function ChangeArrow({ direction = 'up' }) {
  if (direction === 'down') {
    return (
      <svg width="8" height="8" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.9"
          d="M6.31046 9.94433C5.92556 10.611 4.9633 10.611 4.5784 9.94434L0.152054 2.27767C-0.232847 1.611 0.248278 0.777668 1.01808 0.777668L9.87078 0.777669C10.6406 0.777669 11.1217 1.611 10.7368 2.27767L6.31046 9.94433Z"
          fill="rgba(248, 113, 113)"
        />
      </svg>
    );
  }

  return (
    <svg width="8" height="8" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.9"
        d="M4.5784 0.722169C4.9633 0.055502 5.92556 0.0555014 6.31046 0.722168L10.7368 8.38883C11.1217 9.0555 10.6406 9.88883 9.87078 9.88883L1.01808 9.88883C0.248279 9.88883 -0.232847 9.0555 0.152053 8.38883L4.5784 0.722169Z"
        fill="rgb(52, 211, 153)"
      />
    </svg>
  );
}

export function Title({ children }) {
  if (!children) return <></>;
  return (
    <HelperText fontBold secondary={false}>
      {children}
    </HelperText>
  );
}

export function MetricSubtitle({ size, children }) {
  if (!children) return <></>;
  return (
    <p
      className={classnames('text-wmxText-200 flex items-center', {
        'text-xs': size === 'xs',
        'text-sm': size === 'sm' || size === 'lg' || size === 'xl',
      })}
    >
      {children}
    </p>
  );
}

export function MetricValues({ value, prevValue }) {
  return (
    <>
      <Value autoScale>{value}</Value>
      <Change>{prevValue}</Change>
    </>
  );
}

export function Button({ page, segmentId }) {
  return (
    <button
      className="ml-auto transition-all duration-500 hover:bg-white hover:text-black text-xs text-wmxText-200 rounded-md relative px-2 py-1"
      type="button"
      onClick={() => {
        if (page) navigate(routes[page]());
        if (segmentId) navigate(routes.peopleSegmentPage({ segmentId }));
      }}
    >
      Open →
    </button>
  );
}

const Metric = {
  Title,
  Root,
  Top,
  Body,
  Chart,
  Tooltip,
  Value,
  Change,
  MetricSubtitle,
  MetricChart,
  Button,
};

export default Metric;

Value.propTypes = {
  autoScale: PropTypes.any,
  children: PropTypes.any,
  size: PropTypes.string,
};

Chart.propTypes = {
  children: PropTypes.any,
};

Button.propTypes = {
  page: PropTypes.any,
  segmentId: PropTypes.any,
};

Body.propTypes = {
  children: PropTypes.any,
};
Tooltip.propTypes = {
  absolute: PropTypes.bool,
  children: PropTypes.any,
};

Root.propTypes = {
  children: PropTypes.any,
  classNames: PropTypes.shape({
    container: PropTypes.string,
    subContainer: PropTypes.string,
  }),
  editMode: PropTypes.any,
};

Top.propTypes = {
  children: PropTypes.any,
  openButton: PropTypes.any,
};
MetricSubtitle.propTypes = {
  children: PropTypes.any,
  size: PropTypes.string,
};

MetricValues.propTypes = {
  prevValue: PropTypes.any,
  value: PropTypes.any,
};

Title.propTypes = {
  children: PropTypes.any,
};

ChangeArrow.propTypes = {
  direction: PropTypes.string,
};

Change.propTypes = {
  children: PropTypes.any,
};
MetricChart.propTypes = {
  children: PropTypes.any,
};
