/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import { useMutation } from '@redwoodjs/web';
import WildMailIntegrationForm from 'src/components/IntegrationTopic/WildMailIntegrationForm/WildMailIntegrationForm';
import { useUpdateIntegrationLocalStorage } from '../IntegrationTopic/integrationsHelpers';

const CREATE_INTEGRATION_MUTATION = gql`
  mutation CreateIntegrationMutation($input: CreateIntegrationInput!) {
    createIntegration(input: $input) {
      id
    }
  }
`;

const ActiveCampaignForm = ({ onSuccess }) => {
  const [createIntegration, { loading, error, data }] = useMutation(CREATE_INTEGRATION_MUTATION, {
    onCompleted: () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      if (data) useUpdateIntegrationLocalStorage({ networkObj: data, cacheKey: 'acIntegration' });
      onSuccess();
    },
  });

  const onSave = (input) => {
    createIntegration({ variables: { input } });
  };
  return (
    <>
      <WildMailIntegrationForm onSave={onSave} loading={loading} error={error} />
    </>
  );
};

ActiveCampaignForm.propTypes = {
  onSuccess: PropTypes.func,
};

export default ActiveCampaignForm;
