/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* global gql */
import PropTypes from 'prop-types';
import { EmptyComponent, LoadingComponent, Error } from 'src/components/Generic';
import { setThousandsComma, capitalize } from 'src/lib/generic/handlers';
import { useFunnelData } from 'src/components/Funnel/funnelHooks';

export const QUERY = gql`
  query GET_FUNNEL_COMBINED_LEADS($funnelItems: [[FunnelItemsInput!]]!, $timeRange: [TimeRangeInput]) {
    getCombinedLeadsCount(funnelItems: $funnelItems, timeRange: $timeRange)
  }
`;

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'cache-first' };
};

export const Loading = () => {
  return <LoadingComponent />;
};

export const Empty = () => <EmptyComponent />;

export const Failure = ({ error }) => <Error errorMessage={error.message} />;

export const Success = ({ getCombinedLeadsCount, squareIdx, funnelPart }) => {
  const countWithoutFormat = useGetCountFromResults({
    squareIdx,
    funnelPart,
    queryResult: getCombinedLeadsCount,
  });

  const count = setThousandsComma(countWithoutFormat);

  return <span>{count}</span>;
};

Success.propTypes = {
  getCombinedLeadsCount: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  squareIdx: PropTypes.number,
};

const useGetCountFromResults = ({ squareIdx, funnelPart, queryResult }) => {
  const { isCurrentFrontEndPurchase, getLastGroupIdx, getSquareInfo } = useFunnelData();

  const isSquareIdx = squareIdx !== '' && squareIdx !== null && typeof squareIdx !== 'undefined';
  const otherThanTop = funnelPart !== 'top';

  if (!isSquareIdx) {
    return queryResult[0][0];
  }

  if (otherThanTop) {
    const currentSquareInfo = getSquareInfo(capitalize(funnelPart), squareIdx);
    const index = currentSquareInfo?.squareIndexInService || 0;
    return queryResult[0][index];
  }
  if (isCurrentFrontEndPurchase(capitalize(funnelPart), squareIdx)) {
    const lastGroupIdx = getLastGroupIdx(capitalize(funnelPart));
    return queryResult[0][lastGroupIdx];
  }

  return queryResult[0][0];
};
