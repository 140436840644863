/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';

import { segmentBuilderElementClickedId } from 'src/atoms/funnelAtoms';
import { useFunnelData } from 'src/components/Funnel/funnelHooks';
import { transformTypeOfItem, pretiffyTypeOfItem } from 'src/lib/funnelHelpers/funnelHelpers';

import { makeId } from 'src/lib/generic/handlers';
import SegmentBuilder from 'src/components/SegmentBuilder/SegmentBuilder';

import { getSegmentItems } from 'src/lib/segmentBuilder/segmentBuilderHelpers';

export default function FunnelSegmentBuilder({ onDialogOpen, itemAccessor, onDialogClose, isDialogOpen }) {
  const setClickedElementId = useSetRecoilState(segmentBuilderElementClickedId);
  const {
    currentSquareIndex,
    currentSegmentElements,
    currentFunnelPart,
    setFunnelData,
    funnelData,
    onRemoveElement,
    currentSquare,
  } = useFunnelData();

  const getFunnelItemNameInService = () => {
    const panePath = currentSquare?.panePath;
    const segmentBuilderAccessor = (panePath && currentSquare?.panePath[panePath.length - 1].accessor) || null;

    return transformTypeOfItem(segmentBuilderAccessor);
  };

  const getTypeOfItem = () => {
    const panePath = currentSquare?.panePath;
    const segmentBuilderAccessor = (panePath && currentSquare?.panePath[panePath.length - 1].accessor) || null;
    return pretiffyTypeOfItem(segmentBuilderAccessor);
  };

  const getItemsToQuery = (nodeIdx) => {
    const panePath = currentSquare?.panePath;
    const segmentBuilderAccessor = (panePath && currentSquare?.panePath[panePath.length - 1].accessor) || null;
    const segmentBuilderItemsIds = currentSegmentElements[nodeIdx]?.value?.itemsIds;
    const segmentBuilderItemsToQuery =
      (segmentBuilderItemsIds &&
        segmentBuilderAccessor && [
          {
            [transformTypeOfItem(segmentBuilderAccessor)]: { ids: [segmentBuilderItemsIds] },
          },
        ]) ||
      null;

    return segmentBuilderItemsToQuery;
  };

  const onAndOrButtonsClick = (connection) => {
    const newId = makeId(4);

    const newElement = {
      id: newId,
      value: '',
      connection: connection.toUpperCase(),
    };

    const clonedElements = currentSegmentElements.slice();
    clonedElements.push(newElement);

    const modSquare = {
      ...funnelData[currentFunnelPart]?.squares[currentSquareIndex],
      segmentElements: clonedElements,
      typeOfItem: itemAccessor,
    };
    const clonedSquares = funnelData[currentFunnelPart]?.squares.slice();
    clonedSquares.splice(currentSquareIndex, 1, modSquare);

    setFunnelData({
      ...funnelData,
      [currentFunnelPart]: {
        ...funnelData[currentFunnelPart],
        squares: clonedSquares,
      },
    });

    setClickedElementId(newId);
    onDialogOpen();
  };

  const onClickItem = (elementId) => {
    setClickedElementId(elementId);
    onDialogOpen();
  };

  const getNodeElements = ({ elementsStyles }) => {
    const firstItemIsDefined = currentSegmentElements[0]?.value.itemName;
    return [
      {
        id: 'mainBuilder',
        data: {
          label: getSegmentItems({
            segmentElements: currentSegmentElements,
            getTypeOfItem,
            getItemsToQuery,
            onRemoveElement,
            onClickItem,
            onDialogOpen,
            onDialogClose,
            isDialogOpen,
            firstItemIsDefined,
            onAndOrButtonsClick,
            itemNameInService: getFunnelItemNameInService(),
          }),
        },
        style: elementsStyles,
        position: { x: 0, y: 60 },
        draggable: false,
      },
    ];
  };

  const segmentBuilderProps = {
    segmentElements: currentSegmentElements,
    getNodeElements,
  };

  return <SegmentBuilder {...segmentBuilderProps} />;
}

FunnelSegmentBuilder.propTypes = {
  isDialogOpen: PropTypes.bool,
  onDialogClose: PropTypes.func,
  onDialogOpen: PropTypes.func,
  itemAccessor: PropTypes.string,
};
