import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/toast';

import { DropdownWithSearchBar } from 'src/components/Generic';
import useCurrentUser from 'src/customHooks/useCurrentUser';

import { UPDATE_CURRENCY } from 'src/pages/GeneralSettingsPage/GeneralSettingsQueries';
import { useDefaultCurrency } from 'src/lib/localStorageKeys';

import { getDropdownClassNames } from 'src/pages/GeneralSettingsPage/GeneralSettingsHelpers';

export default function CurrencyDropdown({ currencies = [] }) {
  const {
    defaultCurrency: { threeLetters: threeLettersCurrency },
    reauthenticate,
  } = useCurrentUser();

  const [updateDefaultCurrency] = useMutation(UPDATE_CURRENCY, {
    onCompleted: () => {
      toast.success('Default currency updated');
    },
  });

  const defaultCurrencyLocalStorage = useDefaultCurrency();
  const defaultCurrencyObj = defaultCurrencyLocalStorage.get() || {};

  const { name: initialCurrencyName } =
    findCurrencyFromAllCurrenciesArray({ threeLetters: threeLettersCurrency, currenciesArray: currencies }) ||
    defaultCurrencyObj ||
    {};

  const [selectedCurrency, setSelectedCurrency] = useState(initialCurrencyName || 'Euro');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onOptionClick = async (currencyName) => {
    setSelectedCurrency(currencyName);

    const { threeLetters: newThreeLettersCurrency, name: newCurrencyName } =
      findCurrencyFromAllCurrenciesArray({
        name: currencyName,
        currenciesArray: currencies,
      }) || {};
    await updateDefaultCurrency({ variables: { currency: newThreeLettersCurrency } });

    defaultCurrencyLocalStorage.set({
      name: newCurrencyName,
      threeLetters: newThreeLettersCurrency,
    });

    reauthenticate();
  };

  const { classNames: dropdownClassNames, classNamesByType } = getDropdownClassNames({ isDropdownOpen });

  return (
    <div className="flex flex-row shadow-sm rounded-md">
      <DropdownWithSearchBar
        {...{
          isOpen: isDropdownOpen,
          setIsOpen: setIsDropdownOpen,
          optionsName: currencies.map(({ name }) => name),
          selectedOption: selectedCurrency,
          setSelectedOption: onOptionClick,
          classNames: dropdownClassNames,
          classNamesByType,
        }}
      />
    </div>
  );
}

function findCurrencyFromAllCurrenciesArray({
  currenciesArray: currencies,
  threeLetters: threeLettersParam = '',
  name: nameParam,
}) {
  const foundCurrency = currencies.find(({ threeLetters, name }) => {
    return threeLetters === threeLettersParam.toLowerCase() || name === nameParam;
  });

  return foundCurrency;
}

export const currenciesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({ name: PropTypes.string, symbol: PropTypes.string })
);

CurrencyDropdown.propTypes = {
  currencies: currenciesPropTypes,
};
