import { atom } from 'recoil';

export const hasAtLeastOneCreatedFunnel = atom({
  key: 'hasAtLeastOneCreatedFunnel',
  default: null,
});

export const isUserLoggedIn = atom({
  key: 'isUserLoggedIn',
  default: false,
});
