import {
  CREATE_DASHBOARD_MUTATION,
  UPDATE_DASHBOARD_MUTATION,
  DASHBOARD_NAME_MUTATION,
} from 'src/components/Dashboard/dashboardHelpers/dashboardQueries';
import { useMutation } from '@redwoodjs/web';
import { useDashboard } from 'src/contexts/Dashboard';
import useAccount from 'src/customHooks/useAccount';
import { triggerTrackEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';
import { navigate, routes } from '@redwoodjs/router';
import { makeId } from 'src/lib/generic/handlers';

function useDashboardQueriesHandlers() {
  const {
    currentDashboard,
    dashboardNamesState,
    layoutState,
    metricsState,
    newDashboardName,
    segmentsState,
    setCreatingNewDashboard,
    setCurrentDashboard,
    setDashboardNamesState,
    setEditMode,
    setLayoutState,
    setNewDashboardName,
    setTabSelected,
    showEvents,
    tabSelected,
    timeRangeLabel,
    setEditNameMode,
    saveTimeRangeEnabled,
  } = useDashboard();
  const { accountId } = useAccount();

  const [editDashboardNameMutation] = useMutation(DASHBOARD_NAME_MUTATION);
  const [createDashboardMutation] = useMutation(CREATE_DASHBOARD_MUTATION);
  const [updateDashboardMutation] = useMutation(UPDATE_DASHBOARD_MUTATION);

  function saveLayout() {
    setEditMode(false);
    const dashboardSettings = currentDashboard
      ? {
          accountId,
          name: currentDashboard.name,
          hasEventsStream: showEvents,
          layout: layoutState.map((el) => {
            return { ...el, isResizable: false };
          }),
          isDefaultDashboard: false,
          defaultTimerange: saveTimeRangeEnabled ? timeRangeLabel : null,
          id: currentDashboard.id,
          settings: {
            metrics: metricsState.map((metric) => {
              return {
                id: metric.id,
                enabled: metric.enabled,
              };
            }),
            segments: segmentsState,
          },
          prettyHash: makeId(6),
        }
      : {
          accountId,
          name: dashboardNamesState.length ? newDashboardName : 'Home',
          hasEventsStream: showEvents,
          layout: layoutState.map((el) => {
            return { ...el, isResizable: false };
          }),
          isDefaultDashboard: !dashboardNamesState.length,
          defaultTimerange: saveTimeRangeEnabled ? timeRangeLabel : null,
          settings: {
            metrics: metricsState.map((metric) => {
              return {
                id: metric.id,
                enabled: metric.enabled,
              };
            }),
            segments: segmentsState,
          },
          prettyHash: makeId(6),
        };

    // if there is a dashboard selected we update  it
    if (currentDashboard) {
      updateDashboardMutation({ variables: { dashboardSettings } }).then(() => {
        setLayoutState((previous) => {
          return [...previous].map((el) => {
            return { ...el, isResizable: false };
          });
        });
      });
      // otherwise we create the new Dashboard
    } else {
      createDashboardMutation({ variables: { dashboardSettings } }).then((data) => {
        triggerTrackEvent({
          eventName: SEGMENT_TRACK_EVENTS_NAMES.dashboardTabCreated,
          payload: { ...data },
        });

        setLayoutState(data.data.createDashboard.layout);
        setCurrentDashboard(data.data.createDashboard);
        setCreatingNewDashboard(false);
        setNewDashboardName('');
        setDashboardNamesState((previous) => {
          const newArr = [...previous, data.data.createDashboard.name];
          return newArr;
        });
        setTabSelected(data.data.createDashboard.name);
        navigate(routes.dashboardTab({ dashboardId: data.data.createDashboard.prettyHash }));
      });
    }
  }

  function editNameCallback() {
    const previousName = tabSelected;
    editDashboardNameMutation({ variables: { id: currentDashboard.id, newName: newDashboardName } }).then((data) => {
      const newName = data.data.editDashboardName.name;
      // Update list of names
      setDashboardNamesState((previous) => {
        return [...previous].map((name) => {
          if (name === previousName) {
            return newName;
          }
          return name;
        });
      });
      setTabSelected(newName);
      // disable editName
      setEditNameMode(false);
      setNewDashboardName('');
    });
  }

  return {
    saveLayout,
    editNameCallback,
  };
}

export default useDashboardQueriesHandlers;
