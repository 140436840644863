import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Badge from 'src/components/Generic/Badge/Badge';
import { Flex, HelperText, HSpacer, Spacer, Text } from '../Generic/LayoutUtils/LayoutUtils';
import './integration-progress.css';
import { LoadingComponent } from '../Generic';
import { useIntegrationProgress } from './useIntegrationProgress';
import ScaleLoader from 'react-spinners/ScaleLoader';

const IntegrationProgress = ({ provider, providerLabel }) => {
  const {
    datapointsPlan,
    getCurrentProgress,
    getTotalDuration,
    totalCompleted,
    acIsReady,
    update,
    startTime,
    isFailed,
  } = useIntegrationProgress({
    provider,
  });
  return (
    <div className="w-full px-4">
      <>
        <Spacer />
        <HelperText>
          <Badge variant="gray" absolute={false}>
            {providerLabel}
          </Badge>
        </HelperText>
        <Spacer />
        {isFailed && <ProgressError />}
        {!isFailed && (
          <ProgressBarsGroup
            update={update}
            startTime={startTime}
            datapointsPlan={datapointsPlan}
            getCurrentProgress={getCurrentProgress}
            getTotalDuration={getTotalDuration}
            totalCompleted={totalCompleted}
            acIsReady={acIsReady}
          />
        )}
      </>
    </div>
  );
};

IntegrationProgress.propTypes = {
  provider: PropTypes.any,
  providerLabel: PropTypes.any,
};

function ProgressError({ detail }) {
  return (
    <Text>
      <span className="mr-1">⚠️ </span> Something went wrong, please contact support
    </Text>
  );
}

function ProgressBarsGroup({
  datapointsPlan,
  getCurrentProgress,
  getTotalDuration,
  totalCompleted,
  update,
  startTime,
}) {
  const progressBars = datapointsPlan.map((datapoint, index) => {
    const totalDuration = getTotalDuration(datapoint);
    const thisDatapointIsLast = index === datapointsPlan.length - 1;
    return (
      <>
        <ProgressBar
          update={update}
          startTime={startTime}
          totalDuration={totalDuration}
          datapoint={datapoint}
          getCurrentProgress={getCurrentProgress}
          thisDatapointIsLast={thisDatapointIsLast}
          totalCompleted={totalCompleted}
        />
        <Spacer />
      </>
    );
  });

  if (!datapointsPlan || datapointsPlan?.length < 0) {
    return (
      <Flex>
        <HelperText>Initializating importation</HelperText>
        <HSpacer /> <LoadingComponent />
      </Flex>
    );
  }

  return (
    <>
      {progressBars}
      {totalCompleted && <ImportationCompleted />}
      {!totalCompleted && <Importing />}
    </>
  );
}

const ProgressBar = ({ datapoint, getCurrentProgress, thisDatapointIsLast, totalCompleted, update, startTime }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentProgress = getCurrentProgress({ datapoint });
      setProgress(currentProgress);
      const total = thisDatapointIsLast ? 90 : 100;
      if (currentProgress >= total || totalCompleted) {
        clearInterval(interval);
      }
    }, 1000);

    if (totalCompleted) clearInterval(interval);
    return () => clearInterval(interval);
  }, [progress, totalCompleted, update, datapoint, thisDatapointIsLast, getCurrentProgress, startTime]);

  useEffect(() => {
    if (totalCompleted) {
      setProgress(100);
    }
  }, [totalCompleted, setProgress]);

  const style = {
    width: `${progress}%`,
    transition: `width 1s ease-in-out`,
  };

  return (
    <>
      <div className="flex justify-between w-100 mb-1">
        <div className="text-wmxText-100 text-xs">{datapoint}</div>
        {/* <div className="text-wmxText-100 text-xs">{Math.trunc(progress)}%</div> */}
      </div>
      <div className="w-100 h-1 bg-wmxHighlightDark-75 relative bg-wmxSecondary-100 ">
        <div style={style} className="h-full overflow-hidden">
          <div className={`${totalCompleted ? ' bg-wmxSecondary-100 done' : 'importing'}`} />
        </div>
      </div>
    </>
  );
};

const ImportationCompleted = () => {
  return (
    <p className="text-wmxText-100 text-xs ml-1 mb-4">
      ✅ <HSpacer size="xs" /> Importation completed!
    </p>
  );
};

const Importing = () => {
  return (
    <Flex items="start" justify="start">
      <ScaleLoader height={15} width={2} margin={1} color="#3022D3" loading />
      <HSpacer size="xs" />
      <p className="text-wmxText-100 text-xs ml-1 mb-4">Importing</p>
    </Flex>
  );
};

export default IntegrationProgress;

ProgressBarsGroup.propTypes = {
  datapointsPlan: PropTypes.array,
  getCurrentProgress: PropTypes.func,
  getTotalDuration: PropTypes.func,
  startTime: PropTypes.any,
  totalCompleted: PropTypes.bool,
  update: PropTypes.any,
};

ProgressBar.propTypes = {
  datapoint: PropTypes.object,
  getCurrentProgress: PropTypes.func,
  startTime: PropTypes.any,
  thisDatapointIsLast: PropTypes.bool,
  totalCompleted: PropTypes.bool,
  update: PropTypes.any,
};
