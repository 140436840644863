const ProfileWmxLogo = () => {
  return (
    <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.743652" width="27" height="25" rx="12.5" fill="#101725" />
      <path
        d="M21.1207 10.1155L18.6072 15.3723L15.5772 9.0345C15.358 8.57607 15.0089 8.1881 14.5708 7.91625C14.1328 7.6444 13.624 7.49999 13.1045 7.5L12.2431 7.5L14.0008 11.1767L11.995 15.3723L10.077 11.3623C9.85789 10.904 9.50885 10.5161 9.07093 10.2443C8.63302 9.97242 8.12444 9.82795 7.60503 9.82783H6.74365L10.4118 17.5H11.4633L12.6346 15.0488H15.0835L14.8834 14.6303H12.8353L14.2443 11.6845L17.024 17.5H18.0755L21.7437 9.82783H21.5842C21.4868 9.82784 21.3915 9.85491 21.3093 9.90587C21.2272 9.95684 21.1617 10.0296 21.1207 10.1155Z"
        fill="white"
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default ProfileWmxLogo;
