import { addCampaignLink } from 'src/components/Analyze/Overview/OverviewTable/tableSettingsHelpers';
import useTableHeight from 'src/customHooks/useTableHeight';
import {
  discoverChargeMetadataSchema,
  discoverInvoiceMetadataSchema,
  discoverAddressSchema,
  discoverCustomerMetadataSchema,
  getMetadataColumns,
} from 'src/components/Generic/Tables/Table/tableUtils';
import { getLastTwClassNameValue } from 'src/lib/generic/handlers';
import { METADATA_TYPES } from 'wmx-shared-code/tablesGlobalsVariables';
import {
  DefaultCellInRow,
  addProfiles,
  formatLeadsData,
  formatCustomerData,
  formatAutomationData,
} from './peopleDetailsHelpers';

const extendTableHeaders = {
  borderRadiusHeaderTwClassName: 'md',
};

const defaultCellChildren = (params) => {
  const { renderedCell, columns } = params;
  return (
    <DefaultCellInRow columns={columns} {...params}>
      {renderedCell}
    </DefaultCellInRow>
  );
};

const containerMarginTop = 'mt-4';
const twMarginTopNum = getLastTwClassNameValue(containerMarginTop);

const classNames = {
  container: `p-4 pt-0 ${containerMarginTop}`,
  table: 'w-full spacingTable',
  header: {
    headerCell:
      'z-10 text-left h-12 pl-6 p-2 border-t-0 leading-4 text-white tracking-wider bg-wmxBgDark-200 text-xs font-normal',
    thead: 'text-white',
  },
  body: {
    tbody: 'bg-wmxBgDark-100 overflow-auto bg-wmxBgDark-400',
    bodyCell:
      'h-10 text-left pl-6 text-white my-1 py-3 text-sm p-4 pb-4 first:rounded-l-md last:rounded-r-md  last:pr-4 ',
    bodyRow: `z-0 hover:bg-wmxBgDark-300 hover:bg-opacity-70 bg-wmxHighlightDark-100 hover:bg-wmxHighlightDark-200 my-2 rounded-md shadow-2xl `,
  },
};

export const useCombinedLeadsProps = ({ dataChunk, acUrl }) => {
  const { tableHeight, mainTableContainerRef } = useTableHeight({ twMarginTopNum });

  const columns = [
    {
      accessor: 'contactEmail',
      Header: 'Email',
    },
    {
      accessor: 'timestamp',
      Header: 'Date',
    },
    {
      accessor: 'profiles',
      Header: 'Profiles',
    },
  ];

  const extendRows = {
    columns: [
      {
        accessor: 'Profiles',
        cellChildren: ({ row }) =>
          addProfiles({
            row,
            profiles: ['ac'],
            url: acUrl,
            columns,
          }),
      },
    ],
    defaultCellChildren: (params) => defaultCellChildren({ ...params, columns }),
  };

  return {
    data: formatLeadsData({ rows: dataChunk }),
    columns,
    extendRows,
    extendHeader: extendTableHeaders,
    mainContainerRef: mainTableContainerRef,
    height: tableHeight,
    columnsOptions: true,
    compulsoryColumns: ['Email'],
    defaultColumns: ['Date', 'Profiles'],
    classNames: {
      ...classNames,
      header: {
        ...classNames.header,
        headerCell:
          'z-10 text-left h-12 pl-6 p-2 border-t-0 leading-4 text-white tracking-wider bg-wmxBgDark-200 text-xs font-normal last:text-right last:pr-6 last:pl-0',
      },
      body: {
        ...classNames.body,
        bodyCell:
          'h-10 text-left pl-6 text-white my-1 py-3 text-sm p-4 pb-4 first:rounded-l-md last:rounded-r-md  last:pr-4  last:pl-0 last:text-right last:pr-6 last:pl-0',
      },
    },
  };
};

export const useCustomerProps = ({ dataChunk, acUrl, defaultCurrency }) => {
  const { tableHeight, mainTableContainerRef } = useTableHeight({ twMarginTopNum });

  const metadataSchema = discoverChargeMetadataSchema({ items: dataChunk });
  const invoiceMetadataSchema = discoverInvoiceMetadataSchema({ items: dataChunk });
  const customerMetadataSchema = discoverCustomerMetadataSchema({ items: dataChunk });
  const addressSchema = discoverAddressSchema({ items: dataChunk });

  const columns = [
    {
      accessor: 'email',
      Header: 'Email',
    },
    {
      accessor: 'timestamp',
      Header: 'Date',
    },
    {
      accessor: 'stripeProduct',
      Header: 'Product',
    },
    {
      accessor: 'stripePrice',
      Header: 'Price',
    },
    {
      accessor: 'chargeDescription',
      Header: 'Description',
    },
    ...getMetadataColumns({ metadataSchema, metaType: METADATA_TYPES.charge }),
    ...getMetadataColumns({ metadataSchema: invoiceMetadataSchema, metaType: METADATA_TYPES.invoice }),
    ...getMetadataColumns({ metadataSchema: customerMetadataSchema, metaType: METADATA_TYPES.customer }),
    ...getMetadataColumns({ metadataSchema: addressSchema, metaType: METADATA_TYPES.address }),
    {
      accessor: 'amount',
      Header: 'Amount',
    },
    {
      accessor: 'profiles',
      Header: 'Profiles',
    },
  ];

  const extendRows = {
    columns: [
      {
        accessor: 'Profiles',
        cellChildren: ({ row }) =>
          addProfiles({
            row,
            profiles: ['ac', 'stripe'],
            url: acUrl,
            columns,
          }),
      },
    ],
    defaultCellChildren: (params) => defaultCellChildren({ ...params, columns }),
  };

  const dataFormatted = formatCustomerData({ rows: dataChunk, defaultCurrency });

  return {
    data: dataFormatted,
    columns,
    extendRows,
    extendHeader: extendTableHeaders,
    mainContainerRef: mainTableContainerRef,
    height: tableHeight,
    columnsOptions: true,
    compulsoryColumns: ['Email'],
    defaultColumns: ['Date', 'Product', 'Amount', 'Profiles'],
    classNames: {
      ...classNames,
      header: {
        ...classNames.header,
        headerCell:
          'z-10 text-left h-12 pl-6 p-2 border-t-0 leading-4 text-white tracking-wider bg-wmxBgDark-200 text-xs font-normal last:text-right last:pr-6 last:pl-0',
      },
      body: {
        ...classNames.body,
        bodyCell:
          'h-10 text-left pl-6 text-white my-1 py-3 text-sm p-4 pb-4 first:rounded-l-md last:rounded-r-md  last:pr-4  last:pl-0 last:text-right last:pr-6 last:pl-0',
      },
    },
  };
};

export const useAutomationsProps = ({ dataChunk, acUrl }) => {
  const { tableHeight, mainTableContainerRef } = useTableHeight({ twMarginTopNum });

  const columns = [
    {
      accessor: 'campaign',
      Header: 'Name',
    },
    {
      accessor: 'numberOfLeads',
      Header: 'Leads',
    },
    {
      accessor: 'uniqueOpens',
      Header: 'Opens',
    },
  ];

  const extendRows = {
    columns: [
      {
        accessor: 'Name',
        cellChildren: (params) => {
          const { row, renderedCell } = params;
          return (
            <DefaultCellInRow columns={columns} {...params}>
              {addCampaignLink({ renderedCell, row, acUrl })}
            </DefaultCellInRow>
          );
        },
      },
    ],
    defaultCellChildren: (params) => defaultCellChildren({ ...params, columns }),
  };

  return {
    data: formatAutomationData({ rows: dataChunk }),
    columns,
    extendRows,
    extendHeader: extendTableHeaders,
    mainContainerRef: mainTableContainerRef,
    height: tableHeight,
    classNames,
    columnsOptions: true,
    compulsoryColumns: ['Name'],
    defaultColumns: ['Leads', 'Opens'],
  };
};
