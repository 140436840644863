import FunnelManagementLayout from 'src/layouts/FunnelManagementLayout/FunnelManagementLayout';

import { RowSkeletons } from 'src/components/Generic/Skeletons';

export default function FunnelListSkeleton() {
  return (
    <FunnelManagementLayout prerender mainCardClassNames={{ flex: 'flex-col' }}>
      <RowSkeletons rowsAmount={20} height={64} width="100%" foregroundColor="#1C2631" backgroundColor="#304254" />
    </FunnelManagementLayout>
  );
}
