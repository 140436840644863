import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@redwoodjs/web';
import { useMutation, useLazyQuery } from '@redwoodjs/web/node_modules/@apollo/client';
import { useLocation } from '@redwoodjs/router';

import { errors, returns } from 'wmx-shared-code/segments/segments';

import { DEFAULT_INITIAL_SEGMENT_ELEMENTS } from 'src/lib/segmentBuilder/initialSegmentElements';
import GetCountOfCombinedLeadsCell from 'src/components/Cells/GetCountOfCombinedLeadsCell/GetCountOfCombinedLeadsCell';
import { getSegmentQueryObject } from 'src/lib/funnelHelpers/funnelHelpers';
import { getFixedRangeFromDynamicRange } from 'src/components/Generic/DatePicker/getTimeRange';
import { convertSegmentDataToFrontendFormat, saveSegmentInDb } from 'src/lib/segmentBuilder/segmentBuilderHelpers';
import { getIsSegmentBuilderWithElements } from 'src/components/IntegrationTopic/integrationsHelpers';

import useCustomerSegment from 'src/customHooks/useCustomerSegment';
import { SEGMENT_TRACK_EVENTS_NAMES, triggerTrackEvent } from 'src/lib/segmentJuneEvents/segmentJuneEvents';
import { CUSTOMERS_SEGMENT_PATH } from 'src/lib/routesPath';
import { QUERY as PEOPLE_SEGMENTS_LIST } from 'src/components/PeopleSegmentListCell/PeopleSegmentListCell';

import { toast } from '@redwoodjs/web/dist/toast';
import {
  CREATE_PEOPLE_SEGMENT,
  GET_TAGS_CUSTOM_PRICES,
  GET_TAGS_CUSTOM_PRICES_BY_CONTAINS_KEWORD,
  UPDATE_TAGS_CUSTOM_PRICE,
  UPDATE_PEOPLE_SEGMENT,
  DELETE_PEOPLE_SEGMENT,
  GET_USER_SEGMENT_READY_FOR_SEGMENT_BUILDER,
} from './SegmentBuilderQueries';

import useNodeElements from './useNodeElements';

const SegmentBuilderContext = React.createContext(null);
export const useSegmentBuilder = () => React.useContext(SegmentBuilderContext);

export const SegmentBuilderProvider = ({ children }) => {
  const {
    isAcCustomersSegmentNotDefined,
    hasCustomerSegment,
    customerSegmentId,
    isCustomersSegmentPage,
    customersSegmentName,
  } = useACOnlyCustomerSegment();

  const shouldDefineCustomersAcSegment = isAcCustomersSegmentNotDefined && isCustomersSegmentPage;
  const initialSegment = {
    ...(isCustomersSegmentPage && { segmentName: customersSegmentName }),
  };

  const [currentSegment, setCurrentSegment] = useState(initialSegment);
  const [isRightPaneOpen, setIsRightPaneOpen] = useState(shouldDefineCustomersAcSegment);
  const [segmentElements, setSegmentElements] = useState(DEFAULT_INITIAL_SEGMENT_ELEMENTS);
  const [editMode, setEditMode] = useState(false);
  const [showDialog, setShowDialog] = useState(shouldDefineCustomersAcSegment);
  const [editingSegment, setEditingSegment] = useState(false);
  // Name in this case means accessor or type of datapoint
  const [name, setName] = useState('tag');
  const [segmentBuilderElementClickedId, setSegmentBuilderElementClickedId] = useState('first');
  // By the moment the time range is fixed to 'All'. We don't remove the variable to re-use funnel logic
  const [startTimeRange, endTimeRange] = getFixedRangeFromDynamicRange('All');
  const [timeRange, setTimeRange] = useState({ start: startTimeRange, end: endTimeRange });

  const { customPricesByTag, isTagPrices, getIsPriceDefined, setCustomPrice, updateCustomPricesState } = useTagPrices({
    isCustomersSegmentPage,
  });

  const setNewEmptySegment = ({ initSegment } = {}) => {
    setSegmentElements(DEFAULT_INITIAL_SEGMENT_ELEMENTS);
    setCurrentSegment(initSegment || initialSegment);
  };

  const useSegmentQueriesParam = {
    currentSegment,
    datapointName: name,
    editingSegment,
    isCustomersSegmentPage,
    segmentElements,
    setCurrentSegment,
    setIsRightPaneOpen,
    setNewEmptySegment,
    setSegmentElements,
    updateCustomPricesState,
  };

  const {
    getCustomPricesByTags,
    getCustomPricesByContains,
    getDeleteSegment,
    getSegmentsList,
    getSegmentReadyForSegmentBuilder,
    saveSegment,
    updateTagsAmount,
    updateUserSegment,
    setIsCurrentCustomerSegment,
  } = useSegmentQueries(useSegmentQueriesParam);

  const onRightPaneOpen = useCallback(() => {
    setIsRightPaneOpen(true);
  }, []);

  const onRightPaneClose = useCallback(() => {
    setIsRightPaneOpen(false);
  }, []);

  const onDialogClose = useCallback(() => {
    setShowDialog(false);
    const defaultSegmentElementId = 'first';
    setSegmentBuilderElementClickedId(defaultSegmentElementId);
  }, []);

  const rightOpenRef = useRef();
  useEffect(() => {
    if (isRightPaneOpen && !rightOpenRef.current) {
      rightOpenRef.current = true;
      const isSegmentBuilderWithElements = getIsSegmentBuilderWithElements(segmentElements);

      return !isSegmentBuilderWithElements && !editingSegment ? setShowDialog(true) : setShowDialog(false);
    }

    rightOpenRef.current = isRightPaneOpen;
    return null;
  }, [isRightPaneOpen, segmentElements, editingSegment]);

  const onDialogOpen = () => {
    setShowDialog(true);
  };

  const handleDismiss = () => {
    setSegmentElements((prevState) => {
      return prevState.filter((el, elIdx) => {
        return elIdx === 0 || Object.keys(el.value).length > 0;
      });
    });
  };

  const onDialogDismiss = useCallback(() => {
    onDialogClose();
    handleDismiss();
  }, [onDialogClose]);

  const getNodeElements = useNodeElements({
    onDialogOpen,
    onDialogClose,
    isDialogOpen: showDialog,
    setSegmentBuilderElementClickedId,
    segmentElements,
    setSegmentElements,
    timeRange,
  });

  const isFirstSegmentElementDefined = segmentElements[0]?.value?.itemsIds;

  const renderPeopleFooterCount = useCallback(
    () =>
      isFirstSegmentElementDefined ? (
        <GetCountOfCombinedLeadsCell
          funnelItems={getSegmentQueryObject({ elements: segmentElements, typeOfItem: 'tag' })}
          timeRange={timeRange}
        />
      ) : (
        <>0</>
      ),
    [isFirstSegmentElementDefined, segmentElements, timeRange]
  );

  const isReadyToSave = useMemo(() => !!currentSegment?.segmentName, [currentSegment.segmentName]);

  const addNewSegment = () => {
    setIsCurrentCustomerSegment(false);
    setNewEmptySegment({ initSegment: { segmentName: null } });
    setIsRightPaneOpen(true);
    setEditingSegment(false);
  };

  const getPricesByTag = ({ segment: segmentTagsDefinitionInDb = [], segmentElementsInFrontEnd = [] }) => {
    const getTagIds = () => {
      if (segmentElementsInFrontEnd.length) {
        const tagsBySelectId = segmentElementsInFrontEnd.filter(({ condition }) => condition === 'select');
        return tagsBySelectId.map(({ value }) => value?.itemsIds[0]);
      }

      const segmentTagsWithANDConnectionsRemoved = segmentTagsDefinitionInDb.flat();
      return segmentTagsWithANDConnectionsRemoved.filter(({ select }) => select).map(({ select }) => select);
    };

    const getContainsKeywords = () => {
      if (segmentElementsInFrontEnd.length) {
        const tagsByContains = segmentElementsInFrontEnd.filter(({ condition }) => condition === 'contains');
        return tagsByContains.map(({ name: keyword }) => keyword);
      }

      const segmentTagsWithANDConnectionsRemoved = segmentTagsDefinitionInDb.flat();
      return segmentTagsWithANDConnectionsRemoved.filter(({ contains }) => contains).map(({ contains }) => contains);
    };

    const tagIds = getTagIds();
    const tagsByContains = getContainsKeywords();

    if (tagIds && tagIds.length) getCustomPricesByTags.handler({ variables: { tagIds } });

    if (tagsByContains && tagsByContains.length) {
      getCustomPricesByContains.handler({ variables: { containsKeywords: tagsByContains } });
    }
  };

  const editSegment = ({ segmentId, segmentName, segment: segmentTagsDefinition }) => {
    const isCustomerSegmentId = segmentId === customerSegmentId;

    const fetchSegment = () => {
      getPricesByTag({ segment: segmentTagsDefinition });
      return getSegmentReadyForSegmentBuilder.handler({ timeRange, segmentId });
    };

    if (segmentName) setCurrentSegment({ segmentId, segmentName });
    setEditMode(false);
    setIsRightPaneOpen(true);

    if (isCustomerSegmentId && !hasCustomerSegment) {
      onDialogOpen();
      return setNewEmptySegment();
    }

    setEditingSegment(true);

    return fetchSegment();
  };

  const value = {
    addNewSegment,
    customPricesByTag,
    currentSegment,
    editMode,
    editingSegment,
    editSegment,
    errorSavingSegment: saveSegment.error,
    errorSegments: getSegmentsList.error,
    getDeleteSegment,
    getIsPriceDefined,
    getNodeElements,
    getPricesByTag,
    isAcCustomersSegmentNotDefined,
    isDialogOpen: showDialog,
    isReadyToSave,
    isRightPaneOpen,
    isTagPrices,
    loadingPricesByTags: getCustomPricesByTags.isLoading || getCustomPricesByContains.isLoading,
    loadingSegments: getSegmentsList.isLoading,
    loadingSegmentsForSegmentBuilder: getSegmentReadyForSegmentBuilder.isLoading,
    name,
    onDialogClose,
    onDialogDismiss,
    onDialogOpen,
    onRightPaneClose,
    onRightPaneOpen,
    renderPeopleCount: renderPeopleFooterCount,
    saveSegment: saveSegment.handler,
    savingSegment: saveSegment.isLoading,
    segmentBuilderElementClickedId,
    segmentElements,
    segmentsList: getSegmentsList?.data?.listPeopleSegments,
    setCurrentSegment,
    setCustomPrice,
    setEditingSegment,
    setEditMode,
    setIsCurrentCustomerSegment,
    setName,
    setNewEmptySegment,
    setSegmentElements,
    setSegmentBuilderElementClickedId,
    setTimeRange,
    timeRange,
    updateSegment: updateUserSegment,
    updateTagsAmount: updateTagsAmount.handler,
    useSegmentNameUpdate,
  };

  return <SegmentBuilderContext.Provider value={value}>{children}</SegmentBuilderContext.Provider>;
};

function useSegmentNameUpdate() {
  const { currentSegment, setCurrentSegment } = useSegmentBuilder();
  const { segmentName } = currentSegment;

  const [inputCharLength, setInputCharLength] = useState(segmentName ? segmentName.length : 0);

  const onInputChange = (ev) => {
    const newSegmentName = ev.target.value;
    setInputCharLength(newSegmentName?.length);
    setCurrentSegment({ ...currentSegment, segmentName: newSegmentName });
  };

  return {
    segmentName,
    onInputChange,
    inputSize: inputCharLength,
  };
}

SegmentBuilderProvider.propTypes = {
  children: PropTypes.any,
};

function useSegmentQueries({
  currentSegment,
  datapointName,
  editingSegment,
  isCustomersSegmentPage,
  segmentElements,
  setIsRightPaneOpen,
  setCurrentSegment,
  setNewEmptySegment,
  setSegmentElements,
  updateCustomPricesState,
}) {
  const [isCurrentCustomerSegment, setIsCurrentCustomerSegment] = useState(isCustomersSegmentPage);

  const onSegmentFetchedCompleted = ({
    segmentElements: newSegmentElements,
    segmentId: newSegmentId,
    segmentName: newSegmentName,
  }) => {
    const fetchedElementsWithFrontendFormat = convertSegmentDataToFrontendFormat({
      segmentElements: newSegmentElements,
    });

    setSegmentElements(fetchedElementsWithFrontendFormat);
    setCurrentSegment({ ...fetchedElementsWithFrontendFormat, segmentId: newSegmentId, segmentName: newSegmentName });
  };
  const {
    deletedSegment: deletedCustomerSegment,
    deleteSegmentInDb: deleteCustomerSegment,
    errorDeletingSegment: errorDeletingCustomerSegment,
    errorSavingSegment,
    getSegmentReadyForSegmentBuilder: getCustomerSegmentMetrics,
    loadingSegmentDeletion: loadingCustomerSegmentDeletion,
    loadingGetSegmentReady: loadingCustomerSegmentMetrics,
    saveSegmentInDb: saveCustomerSegmentInDb,
    savingSegment: savingCustomerSegment,
    segmentId: customerSegmentId,
  } = useCustomerSegment({ onGetSegmentCompleted: onSegmentFetchedCompleted, setEmptySegment: setNewEmptySegment });

  const {
    loading: loadingSegmentsList,
    error: errorSegmentsList,
    data: dataSegmentsList,
  } = useQuery(PEOPLE_SEGMENTS_LIST);

  const [createSegment, { loading: loadingCreateSegment, error: errorCreatingSegment }] = useMutation(
    CREATE_PEOPLE_SEGMENT,
    {
      onCompleted: () => setIsRightPaneOpen(false),
      refetchQueries: [{ query: PEOPLE_SEGMENTS_LIST }],
    }
  );

  const [deleteSegment, { data: segmentDeleted, loading: deletingSegment, error: errorDeletingSegment }] = useMutation(
    DELETE_PEOPLE_SEGMENT,
    {
      refetchQueries: [{ query: PEOPLE_SEGMENTS_LIST }],
    }
  );

  const [updateSegment, { loading: updatingSegment, error: errorUpdatingSegment }] = useMutation(
    UPDATE_PEOPLE_SEGMENT,
    {
      refetchQueries: [{ query: PEOPLE_SEGMENTS_LIST }],
    }
  );

  const [updateTagsAmount, { loading: updatingTagsAmount, error: errorUpdatingTagsAmount }] = useMutation(
    UPDATE_TAGS_CUSTOM_PRICE,
    {
      onCompleted: ({ updateTagsAmount: response }) => {
        return response === returns.updatingTagCustomPrice.successful
          ? triggerTrackEvent({ eventName: SEGMENT_TRACK_EVENTS_NAMES.revenueForTagsUpdated })
          : null;
      },
    }
  );

  const [getCustomPricesByTags, { loading: customPricesByTagsLoading }] = useLazyQuery(GET_TAGS_CUSTOM_PRICES, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getTagsCustomPricesByTagId: tags }) => {
      const fetchedCustomPrices = tags.map(({ tagId, customPrice }) => ({ tagId, customPrice }));

      return updateCustomPricesState({ fetchedCustomPrices });
    },
    onError: ({ message }) => toast.error(message),
  });

  const [getCustomPricesByContainsKeyword, { loading: customPricesByContainsLoading }] = useLazyQuery(
    GET_TAGS_CUSTOM_PRICES_BY_CONTAINS_KEWORD,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ getTagCustomPricesByContains: tags }) => {
        const fetchedCustomPrices = tags.map(({ containsKeyword, customPrice }) => ({ containsKeyword, customPrice }));

        return updateCustomPricesState({ fetchedCustomPrices });
      },
      onError: ({ message }) => toast.error(message),
    }
  );

  const [getUserSegmentMetrics, { loading: loadingPeopleSegment }] = useLazyQuery(
    GET_USER_SEGMENT_READY_FOR_SEGMENT_BUILDER,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ getPeopleTagsSegmentReadyForSegmentBuilder: segmentConfig }) => {
        // When running reauthenticate in customer segment, this function is triggered when should not

        if (isCurrentCustomerSegment) return null;
        return onSegmentFetchedCompleted({ ...segmentConfig });
      },
      onError: ({ message }) => {
        const isANonExistingTag = message === errors.nonExistingItemIdCallback({ itemNameInService: datapointName });

        return isANonExistingTag ? setNewEmptySegment({ initSegment: currentSegment }) : toast.error(message);
      },
    }
  );

  const saveUserSegment = (frontEndSegmentElements) => {
    const onSegmentUpdate = (apiSegmentElements) => {
      return updateSegment({
        variables: {
          id: currentSegment.segmentId,
          segment: apiSegmentElements,
          segmentName: currentSegment.segmentName,
        },
      });
    };
    const onSegmentCreate = (apiSegmentElements) => {
      return createSegment({ variables: { segment: apiSegmentElements, segmentName: currentSegment.segmentName } });
    };

    const eventPayload = {
      segment: frontEndSegmentElements,
      segmentName: currentSegment.segmentName,
    };

    const juneEvent = editingSegment
      ? SEGMENT_TRACK_EVENTS_NAMES.segmentUpdated
      : SEGMENT_TRACK_EVENTS_NAMES.segmentCreated;

    triggerTrackEvent({ eventName: juneEvent, payload: eventPayload });

    const handler = editingSegment ? onSegmentUpdate : onSegmentCreate;

    return saveSegmentInDb(frontEndSegmentElements, handler);
  };

  const getSegmentsList = {
    isLoading: loadingSegmentsList,
    error: errorSegmentsList,
    data: dataSegmentsList,
  };

  const saveSegment = isCurrentCustomerSegment
    ? {
        handler: () => saveCustomerSegmentInDb(segmentElements, currentSegment.segmentName),
        isLoading: savingCustomerSegment,
        error: errorSavingSegment,
      }
    : {
        handler: () => saveUserSegment(segmentElements),
        isLoading: updatingSegment || loadingCreateSegment,
        error: errorCreatingSegment || errorUpdatingSegment,
      };

  const getSegmentReadyForSegmentBuilder = {
    handler: ({ timeRange, segmentId }) => {
      const isCustomerSegmentBeingFetched = customerSegmentId === segmentId;
      setIsCurrentCustomerSegment(isCustomerSegmentBeingFetched);

      return isCustomerSegmentBeingFetched
        ? getCustomerSegmentMetrics({ variables: { timeRange } })
        : getUserSegmentMetrics({ variables: { timeRange, segmentId } });
    },
    isLoading: loadingPeopleSegment || loadingCustomerSegmentMetrics,
  };

  const getDeleteSegment = ({ isCustomerSegment }) => {
    return isCustomerSegment
      ? {
          data: deletedCustomerSegment,
          handler: ({ segmentId }) => deleteCustomerSegment({ variables: { id: segmentId } }),
          isLoading: loadingCustomerSegmentDeletion,
          error: errorDeletingCustomerSegment,
        }
      : {
          data: segmentDeleted,
          handler: ({ segmentId }) => deleteSegment({ variables: { segmentId } }),
          isLoading: deletingSegment,
          error: errorDeletingSegment,
        };
  };

  const getCustomPricesByTagsObj = {
    handler: getCustomPricesByTags,
    isLoading: customPricesByTagsLoading,
  };

  const getCustomPricesByContainssObj = {
    handler: getCustomPricesByContainsKeyword,
    isLoading: customPricesByContainsLoading,
  };

  const updateTagsAmountObj = {
    handler: updateTagsAmount,
    isLoading: updatingTagsAmount,
    error: errorUpdatingTagsAmount,
  };

  return {
    getCustomPricesByTags: getCustomPricesByTagsObj,
    getCustomPricesByContains: getCustomPricesByContainssObj,
    getDeleteSegment,
    getSegmentsList,
    getSegmentReadyForSegmentBuilder,
    saveSegment,
    setIsCurrentCustomerSegment,
    updateTagsAmount: updateTagsAmountObj,
    updateUserSegment: updateSegment,
  };
}

function useACOnlyCustomerSegment() {
  const {
    hasCustomerSegment,
    segmentId: customerSegmentId,
    isUserWithStripe,
    segmentName: customersSegmentName,
  } = useCustomerSegment();
  const { pathname } = useLocation();

  const isCustomersSegmentPage = pathname.includes(CUSTOMERS_SEGMENT_PATH);
  const isAcCustomersSegmentNotDefined = !isUserWithStripe && !hasCustomerSegment;

  return {
    isAcCustomersSegmentNotDefined,
    hasCustomerSegment,
    customerSegmentId,
    isCustomersSegmentPage,
    customersSegmentName,
  };
}

function useTagPrices({ isCustomersSegmentPage }) {
  const [isTagPrices, setIsTagPrices] = useState(isCustomersSegmentPage);
  const [customPricesByTag, setCustomPricesByTag] = useState([]);

  const getIsPriceDefined = (value) => value !== '' && value !== undefined && value !== null;

  const updateCustomPricesState = ({ fetchedCustomPrices }) => {
    const fetchedCustomPricesClone = fetchedCustomPrices.slice();

    setCustomPricesByTag((prevState) => {
      const prevStateUpdated = prevState.map((prevStateTag) => {
        const { tagId: prevStateTagId, containsKeyword: prevStateContainsKeyord } = prevStateTag;

        const fetchedTagIdx = fetchedCustomPricesClone.findIndex(
          ({ tagId: fetchedTagId, containsKeyword: fetchedKeyword }) => {
            const isTagId = getIsPriceDefined(fetchedKeyword);
            const isContains = getIsPriceDefined(fetchedTagId);

            return (
              (isTagId && fetchedTagId === prevStateTagId) || (isContains && fetchedKeyword === prevStateContainsKeyord)
            );
          }
        );

        const fetchedTag = fetchedCustomPricesClone[fetchedTagIdx];
        if (fetchedTag) fetchedCustomPricesClone.splice(fetchedTagIdx, 1);

        return fetchedTag || prevStateTag;
      });

      return [...prevStateUpdated, ...fetchedCustomPricesClone];
    });
  };

  const setCustomPrice = ({ tagId, value, containsKeyword }) => {
    const customPricesByTagClone = customPricesByTag.slice();

    const findTagByContainsOrTagId = ({ tagId: stateTagId, containsKeyword: stateContainsKeyword }) => {
      const isTagId = getIsPriceDefined(tagId);
      const isContains = getIsPriceDefined(containsKeyword);

      return (isTagId && tagId === stateTagId) || (isContains && stateContainsKeyword === containsKeyword);
    };

    const tagIndexInArray = customPricesByTagClone.findIndex(findTagByContainsOrTagId);

    const newValue = {
      tagId,
      containsKeyword,
      customPrice: +value,
    };

    if (tagIndexInArray !== -1) {
      customPricesByTagClone[tagIndexInArray] = newValue;
    } else {
      customPricesByTagClone.push(newValue);
    }

    setCustomPricesByTag([...customPricesByTagClone]);
  };

  useEffect(() => {
    if (isCustomersSegmentPage && !isTagPrices) setIsTagPrices(true);
    if (!isCustomersSegmentPage && isTagPrices) setIsTagPrices(false);
  }, [isCustomersSegmentPage, isTagPrices]);

  return {
    customPricesByTag,
    getIsPriceDefined,
    isTagPrices,
    setCustomPrice,
    updateCustomPricesState,
  };
}
