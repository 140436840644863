import { useMutation } from '@redwoodjs/web';
import { useLazyQuery } from '@redwoodjs/web/node_modules/@apollo/client';
import { toast } from '@redwoodjs/web/toast';

import { errors } from 'wmx-shared-code/segments/segments';
import { saveSegmentInDb as saveSegment } from 'src/lib/segmentBuilder/segmentBuilderHelpers';

import {
  CREATE_TAG_CUSTOMER_SEGMENT,
  UPDATE_TAG_CUSTOMER_SEGMENT,
  DELETE_TAG_CUSTOMER_SEGMENT,
  GET_USER_TAG_CUSTOMER_SEGMENT_READY_FOR_SEGMENT_BUILDER,
} from 'src/components/IntegrationTopic/IntegrationSegmentBuilder/segmentMutations';
import useCurrentUser from 'src/customHooks/useCurrentUser';

import { triggerTrackEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';

export default function useCustomerSegment({
  onGetSegmentCompleted = () => {},
  datapointName = 'tag',
  setEmptySegment,
} = {}) {
  const {
    stripeIntegration: { isReady: isUserWithStripe },
    hasTagsCustomerSegment: hasCustomerSegment,
    reauthenticate,
    tagsCustomerSegment: segmentElementsJSON,
    tagsCustomerSegmentName,
    tagsCustomerSegmentId: customerSegmentId,
  } = useCurrentUser();

  const defaultSegmentName = 'Customers';
  const segmentName = tagsCustomerSegmentName || defaultSegmentName;

  const onMutationCompleted = () => {
    reauthenticate();
  };

  const onError = ({ message } = {}) => toast.error(message);

  const [createSegment, { loading: creatingSegment, error: errorCreatingSegment }] = useMutation(
    CREATE_TAG_CUSTOMER_SEGMENT,
    {
      onCompleted: onMutationCompleted,
      onError,
    }
  );

  const [updateSegment, { loading: updatingSegment, error: errorUpdatingSegment }] = useMutation(
    UPDATE_TAG_CUSTOMER_SEGMENT,
    {
      onCompleted: onMutationCompleted,
      onError,
    }
  );

  const [deleteSegment, { data: deletedSegment, loading: deletingSegment, error: errorDeletingSegment }] = useMutation(
    DELETE_TAG_CUSTOMER_SEGMENT,
    {
      onCompleted: ({ segmentName: deletedSegmentName = segmentName }) => {
        onMutationCompleted();
        toast.success(`${deletedSegmentName} has been successfully deleted`);
      },
      onError,
    }
  );

  const [getSegmentReadyForSegmentBuilder, { data: segmentMetrics, loading: loadingGetSegmentReady }] = useLazyQuery(
    GET_USER_TAG_CUSTOMER_SEGMENT_READY_FOR_SEGMENT_BUILDER,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ getUserTagCustomerSegmentReadyForSegmentBuilder }) => {
        return onGetSegmentCompleted({
          ...getUserTagCustomerSegmentReadyForSegmentBuilder,
          segmentName,
        });
      },
      onError: ({ message }) => {
        const isANonExistingTag = message === errors.nonExistingItemIdCallback({ itemNameInService: datapointName });

        return isANonExistingTag ? setEmptySegment() : toast.error(message);
      },
    }
  );

  const onSegmentCreation = (frotnEndSegmentElements, newSegmentName) => {
    triggerTrackEvent({
      eventName: SEGMENT_TRACK_EVENTS_NAMES.customerSegmenteCreated,
      payload: { segment: frotnEndSegmentElements },
    });

    const createCallback = (apiSegmentElements) => {
      return createSegment({ variables: { segment: apiSegmentElements, segmentName: newSegmentName } });
    };

    return saveSegment(frotnEndSegmentElements, createCallback);
  };

  const onSegmentUpdate = (frotnEndSegmentElements, newSegmentName) => {
    const updateCallback = (apiSegmentElements) => {
      return updateSegment({
        variables: { id: customerSegmentId, segment: apiSegmentElements, segmentName: newSegmentName },
      });
    };

    return saveSegment(frotnEndSegmentElements, updateCallback);
  };

  return {
    customerSegmentMetrics: segmentMetrics,
    deletedSegment,
    deleteSegmentInDb: deleteSegment,
    errorDeletingSegment,
    errorSavingSegment: errorCreatingSegment || errorUpdatingSegment,
    getSegmentReadyForSegmentBuilder,
    hasCustomerSegment,
    isUserWithStripe,
    loadingGetSegmentReady,
    loadingSegmentDeletion: deletingSegment,
    saveSegmentInDb: hasCustomerSegment ? onSegmentUpdate : onSegmentCreation,
    savingSegment: updatingSegment || creatingSegment,
    segmentElementsInDb: segmentElementsJSON,
    segmentId: customerSegmentId,
    segmentName,
  };
}
