import PropTypes from 'prop-types';

export default function AddSegmentItemBox({ name, onClick, disabled }) {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={() => onClick(name)}
      className="AddSegmentItemBox flex flex-row items-center justify-between h-10 py-1 px-4 bg-wmxHighlightDark-100 hover:bg-wmxHighlightDark-75 rounded-lg"
    >
      <div className="flex items-center justify-center rounded-full h-5 w-5 bg-wmxBgDark-200 mr-4">
        <span className="text-xl px-2 plusIcon text-white" />
      </div>
      <span className="text-sm text-white">{name.toUpperCase()}</span>
    </button>
  );
}

AddSegmentItemBox.propTypes = {
  name: PropTypes.oneOf(['and', 'or']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
