import IntegrationsWebsocketActiveCampaign from 'src/components/IntegrationsWebsocketActiveCampaign/IntegrationsWebsocketActiveCampaign';

const ActiveCampaignImportationPage = () => {
  return (
    <>
      <IntegrationsWebsocketActiveCampaign />
    </>
  );
};

export default ActiveCampaignImportationPage;
