const METRICS = {
  revenue: 'revenue',
  subscribers: 'subscribers',
  newCustomers: 'newCustomers',
  totalCustomers: 'totalCustomers',
  totalSubscribers: 'totalSubscribers',
  revenuePerLead: 'revenuePerLead',
  conversionRate: 'conversionRate',
};

const errors = {
  revenueByTag: {
    emptyPrices: 'There are no prices defined in customer segment',
  },
};

module.exports = {
  METRICS,
  errors,
};
