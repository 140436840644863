import { useIntegrationsContext } from 'src/contexts/Integrations';

export default function VendorOverviewSubtitle() {
  const { settingsByClickedProvider } = useIntegrationsContext();

  const { subtitle } = settingsByClickedProvider;
  return (
    <div className="w-2/3">
      <span className="text-white text-sm w-full">{subtitle}</span>
    </div>
  );
}
