const SEGMENT_TRACK_EVENTS_NAMES = {
  accountSettingsPage: 'Account settings visited',
  acDatapointsVisited: 'Active campaign datapoints records visited',
  acIntegrationStarted: 'Active campaign integration started',
  acSettingsVisited: 'Active campaign settings visited',
  automationReportEngaged: 'Automation report engaged',
  automationReportPage: 'Automation report visited',
  billingPage: 'Billing visited',
  bugReported: 'Bug reported',
  clickedCustomTimerange: 'Custom time range clicked',
  clickedFunnelEditView: 'Funnel edit view clicked',
  clickedFunnelAnalyzeView: 'Funnel analyze view clicked',
  clickedLabelTimerange: 'Label time range clicked',
  customerSegmenteCreated: 'Customer segment created',
  customerSegmenteUpdated: 'Customer segment updated',
  dashboardTabCreated: 'Dashboard tab created',
  dashboardTabVisited: 'Dashboard tab visited',
  dashboardPage: 'Dashboard visited',
  datePickerClicked: 'Date picker clicked',
  emailReportEngaged: 'Email report engaged',
  emailReportPage: 'Email reports visited',
  featureRequestSubmitted: 'Feature request submitted',
  firstFunnelStepSet: 'First funnel step set',
  funnelAnalysed: 'Funnel analysed',
  funnelListPage: 'Funnel list visited',
  generalSettingsPage: 'General settings visited',
  integrationDisconnected: 'Integration deleted',
  integrationsPage: 'Integrations visited',
  logIn: 'User logged in',
  logOut: 'User logged out',
  manageBillingDetailsClicked: 'Manage billing details clicked',
  newFunnelPage: 'Funnel canvas visited',
  revenueForTagsUpdated: 'Revenue for tags updated',
  roadmapViewed: 'Roadmap viewed',
  savedFunnel: 'Funnel saved',
  segmentCreated: 'Segment created',
  segmentUpdated: 'Segment updated',
  segmentVisited: 'Segment visited',
  signUp: 'User signed up',
  stripeIntegrationStarted: 'Stripe integration started',
  supportMessageSent: 'Support message sent',
  triggerResync: 'Resync triggered',
  triggerAcRestImportation: 'Active campaign rest integration started',
  upgradedPlan: 'Plan upgraded',
  workspaceDeleted: 'Workspace deleted',
};

module.exports = {
  SEGMENT_TRACK_EVENTS_NAMES,
};
