import PropTypes from 'prop-types';

export default function LinkArrow({ visibility, visibility: { always, onlyWithHover } } = {}) {
  const isMoreThanOneVisibilityOptionSelected = Object.values(visibility).length > 1;

  if (isMoreThanOneVisibilityOptionSelected) console.error('More than one visibility option selected');

  const visibilityClassName = onlyWithHover ? 'opacity-0 group-hover:opacity-100' : always ? '' : '';

  return (
    <div className={`blankTargetIcon ${visibilityClassName} flex items-center ml-2`}>
      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1H6V6" stroke="#F9FAFB" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 1L1 6" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
}

LinkArrow.propTypes = {
  visibility: PropTypes.shape({
    always: PropTypes.bool,
    onlyWithHover: PropTypes.bool,
  }),
};
