import PropTypes from 'prop-types';

import AppLayout from 'src/layouts/AppLayout/AppLayout';
import SubmenuLink from 'src/components/SubmenuLink/SubmenuLink';

export default function AnalyzeLayout({ children, prerender = false }) {
  const layoutProps = {
    isHeader: false,
    prerender,
    submenu: {
      nav: <ReportsMenu />,
      title: 'Reports',
      isOpen: true,
    },
  };

  const layoutChildren = (
    <div className="funnelLayoutContainer flex flex-col relative w-full h-full rounded-lg">{children}</div>
  );

  return <AppLayout {...layoutProps}>{layoutChildren}</AppLayout>;
}

AnalyzeLayout.propTypes = {
  children: PropTypes.any,
  prerender: PropTypes.bool,
};

// Should follow the same structure as <Header /> like the others Layouts
const ReportsMenu = () => {
  return (
    <nav id="navReports" className="flex flex-col w-full">
      <SubmenuLink page="emailsOverview" parentMatch="reports/emails">
        Emails
      </SubmenuLink>
      <SubmenuLink page="automationsOverview" parentMatch="reports/automations">
        Automations
      </SubmenuLink>
    </nav>
  );
};
