/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { useMutation } from '@redwoodjs/web';
import { navigate, routes } from '@redwoodjs/router';
import { toast } from '@redwoodjs/web/toast';
import { ScaleLoader } from 'react-spinners';
import { ACCOUNT_TYPES } from 'wmx-shared-code/accountGlobalVariables';

import { Button, Badge } from 'src/components/Generic';
import {
  Card,
  Line,
  Spacer,
  Text,
  Title,
  Link,
  Subtitle,
  Flex,
  HSpacer,
  Container,
  HelperText,
} from 'src/components/Generic/LayoutUtils/LayoutUtils';
import useAccount from 'src/customHooks/useAccount';
import {
  useTriggerPageSeenEvent,
  SEGMENT_TRACK_EVENTS_NAMES,
  triggerTrackEvent,
} from 'src/lib/segmentJuneEvents/segmentJuneEvents';
import { REST_INTEGRATION_MUTATION, REACTIVATE_TRIAL } from 'src/components/Account/Account/accountMutations';

const PRICING_LINK = 'https://docs.wildmetrics.io/start/pricing';
const text2022 = 'In 2022 we offer our Pro plan 100% for free';

const BillingPage = () => {
  useTriggerPageSeenEvent({ pageName: SEGMENT_TRACK_EVENTS_NAMES.billingPage });

  return (
    <>
      <Container size="max">
        <Card>
          <Title>Billing</Title>
          <Spacer size="xs" />
          <Line />

          <Spacer />
          <Text>
            You can find more details about our available pricing plans
            <Link target="_blank" href={PRICING_LINK}>
              here
            </Link>
            .
          </Text>
          <PlanDetails />
        </Card>
      </Container>
    </>
  );
};

const PlanDetails = () => {
  const { trial: isTrial, accountType } = useAccount();
  const isFreeAccount = accountType === ACCOUNT_TYPES.free;
  const isPlanHeaderFirst = isTrial || isFreeAccount;

  // eslint-disable-next-line react/prop-types
  const PlanHeaderWithProps = () => <PlanHeader daysLeftBlock={(isTrial && <DaysLeft />) || ''} />;

  return (
    <PlanLayout
      isHeaderTopSpacer={isPlanHeaderFirst}
      HeaderBlock={isPlanHeaderFirst ? () => <PlanHeaderWithProps /> : () => <BillingDescription />}
      DescriptionBlock={isPlanHeaderFirst ? () => <BillingDescription /> : () => <PlanHeaderWithProps />}
    />
  );
};

const DaysLeft = () => {
  const { daysLeft } = useAccount();
  const daysOrDay = (daysLeft === 1 && 'day') || 'days';
  return (
    <>
      <Badge variant={(daysLeft > 0 && 'outline') || 'danger'} absolute={false}>
        <HelperText>
          {daysLeft} {daysOrDay} left
        </HelperText>
      </Badge>
    </>
  );
};

const BillingDescription = () => {
  const { trial: isTrial, isTrialExpired, daysLeft } = useAccount();

  const numberOfDaysExpiring = daysLeft === 1 ? `1 day` : `${daysLeft} days`;

  const trialDescription = isTrialExpired ? (
    <Text>Your 10-day trial has expired. Please reactivate your free trial below.</Text>
  ) : isTrial ? (
    <Text>
      Your 10-day trial will expire in {numberOfDaysExpiring}. If you want to continue with Wildmetrics, please upgrade
      to Pro below. {text2022}.
    </Text>
  ) : (
    <Text>{text2022}.</Text>
  );

  return (
    <>
      {trialDescription}
      <Spacer />
      {!isTrialExpired && <ProPlanFeatures />}
    </>
  );
};

function PlanHeader({ daysLeftBlock }) {
  const { accountType } = useAccount();

  return (
    <Flex justify="between" grow>
      <Flex grow>
        <Flex>
          <Subtitle secondary>Plan:</Subtitle>
          <HSpacer size="xs" />
          <Subtitle fontBold>{accountType}</Subtitle>
        </Flex>
        {daysLeftBlock && (
          <>
            <HSpacer size="sm" />
            {daysLeftBlock}
          </>
        )}
      </Flex>
      <HSpacer />
    </Flex>
  );
}

const ProPlanFeatures = () => {
  const featuresIncluded = [
    'Unlimited contacts',
    'All features',
    'ActiveCampaign integration',
    'Stripe integration',
    'Unlimited data history',
    'Fast & friendly support',
  ];

  const listItems = featuresIncluded.map((item) => (
    <li key={item}>
      <Text>{item}</Text>
    </li>
  ));

  return (
    <>
      <Flex row>
        <Text>
          The{' '}
          <Subtitle row fontBold>
            Pro
          </Subtitle>{' '}
          plan includes:
        </Text>
      </Flex>
      <ul className="list-disc pl-8 text-wmxText-200">{listItems}</ul>
    </>
  );
};

const UpgradeButton = () => {
  const {
    accountId,
    accountType,
    isTrialExpired,
    reauthenticate,
    acIntegration: { isReady: isAcConnected } = {},
  } = useAccount();
  const [triggerRestIntegration, { loading: triggerRestIntegrationLoading, data: triggerRestIntegrationData }] =
    useMutation(REST_INTEGRATION_MUTATION, {
      onCompleted: () => {
        toast.success(`You have upgraded to Pro. We're importing the rest of your data.`);
        reauthenticate();
      },
      onError: () => {
        toast.error(`There was an error while upgrading to Pro. Try again, or contact support.`);
      },
    });

  const isTrialExpiredWithoutData = isTrialExpired && !isAcConnected;

  const [reactivateTrial, { loading: reactivateTrialLoading }] = useMutation(REACTIVATE_TRIAL, {
    onCompleted: async () => {
      toast.success(`You have reactivated your trial.`);
      await reauthenticate();
      return isTrialExpiredWithoutData ? navigate(routes.onboardingPageStep1()) : navigate(routes.dashboard());
    },
    onError: () => {
      toast.error(`There was an error while reactivating the trial. Try again, or contact support.`);
    },
  });

  const isFreeAccount = accountType === ACCOUNT_TYPES.free;
  const isRestTriggered = !!triggerRestIntegrationData;
  const isButtonDisabled = isRestTriggered;
  const isButtonLoading = reactivateTrialLoading || triggerRestIntegrationLoading;

  const onClick = () => {
    if (isTrialExpired) return reactivateTrial();

    const payload = {
      upgradedTo: 'Pro',
      previousPlan: accountType,
    };

    triggerTrackEvent({ eventName: SEGMENT_TRACK_EVENTS_NAMES.upgradedPlan, payload });
    return triggerRestIntegration({ variables: { accountId, turnToPro: true } });
  };

  const btnText =
    (isButtonLoading && <ScaleLoader size={2} height={5} width={2} color="white" />) ||
    (isTrialExpired && 'Reactivate') ||
    (isFreeAccount && 'Upgrade to Pro') ||
    'Upgrade';

  return (
    <Button disabled={isButtonDisabled} onClick={onClick}>
      {btnText}
    </Button>
  );
};

function PlanLayout({ HeaderBlock, DescriptionBlock, isHeaderTopSpacer }) {
  const { trial: isTrial, accountType } = useAccount();
  const isDescriptionBlock = !!DescriptionBlock;
  const isFreeAccount = accountType === ACCOUNT_TYPES.free;
  const isButton = isTrial || isFreeAccount;

  const isCol = isDescriptionBlock || isButton;

  return (
    <Flex col={isCol} items="start" justify="between" grow>
      {isHeaderTopSpacer && <Spacer size="sm" />}
      {HeaderBlock && <HeaderBlock />}

      {isDescriptionBlock && (
        <>
          <Spacer />
          <DescriptionBlock />
        </>
      )}
      {isButton && (
        <>
          <Spacer size="md" />
          <UpgradeButton />
        </>
      )}
    </Flex>
  );
}

PlanLayout.propTypes = {
  isHeaderTopSpacer: PropTypes.bool,
  DescriptionBlock: PropTypes.any,
  HeaderBlock: PropTypes.any,
};

PlanHeader.propTypes = {
  daysLeftBlock: PropTypes.any,
};

export default BillingPage;
