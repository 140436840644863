export const scrollbarStyles =
  'scrollbar-thin scrollbar-thumb-wmxHighlightDark-50 scrollbar-track-wmxBgDark-400 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full mr-2';

export const formCSS = {
  label: 'block mt-6 text-white text-sm',
  labelError: 'block mt-6 text-white text-sm',
  input:
    'bg-wmxHighlightDark-50 text-sm block w-full p-2 border border-wmxBgDark-100 text-white rounded focus:outline-none focus:border-wmxHighlightDark-200 ',
  select:
    'appearance-none relative bg-wmxHighlightDark-50 text-sm block mt-2 w-full p-2 border border-wmxBgDark-100 text-white rounded focus:outline-none focus:border-wmxHighlightDark-200 ',
  disabledInput:
    'bg-transparent text-sm block w-full p-2 border border-wmxBgDark-100 text-wmxText-200 rounded focus:outline-none focus:border-wmxHighlightDark-200 ',
  inputError:
    'bg-wmxBgDark-300 block w-full p-2 border border-red-700 text-wmxText-100 text-sm rounded focus:outline-none',
  errorMessage: 'block mt-1  text-sm text-wmxText-100',
};
