import { useEffect, useMemo } from 'react';
import isEqual from 'lodash.isequal';
import pick from 'lodash.pick';
import dayjs from 'dayjs';
import { useRecoilValue } from 'recoil';

import useLocalStorage from 'src/customHooks/useLocalStorage';

import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';

export default function useUpdateMetricsInLocalCache({ networkMetrics, storageKey, additionals = {} }) {
  const [lastSessionMetrics = {}, setLastSessionMetrics] = useLocalStorage(storageKey);
  const { label: timeRangeLabel } = useRecoilValue(RecoilTimeRange);

  const { offset, convWindow, isPagination } = additionals;

  const lastSessionClone = useMemo(() => {
    return { ...lastSessionMetrics };
  }, [lastSessionMetrics]);

  const metricsInCache = useMemo(() => {
    const cacheInTimeRange = lastSessionClone?.[timeRangeLabel] || {};

    return (convWindow ? cacheInTimeRange[convWindow] : cacheInTimeRange) || {};
  }, [convWindow, lastSessionClone, timeRangeLabel]);

  useEffect(() => {
    if (timeRangeLabel === 'Custom') return;
    if (!networkMetrics) return;
    if (offset > 0) return;
    if (isPagination) return;

    const metricsKeys = Object.keys(networkMetrics);
    const addedKeys = ['timeRangeLabel', 'convWindow'];

    const networkMetricsWithInfo = {
      ...networkMetrics,
      timeRangeLabel,
      convWindow,
    };

    const metricsShapeObj = pick(metricsInCache, [...metricsKeys, ...addedKeys]);
    const areMetricsWithChanges = !isEqual(networkMetricsWithInfo, metricsShapeObj);

    const now = dayjs();
    const { lastSession: lastUpdatedInCache } = metricsInCache;

    const updatedMetrics = {
      ...networkMetricsWithInfo,
      lastSession: now,
    };

    lastSessionClone[timeRangeLabel] = {
      ...lastSessionClone[timeRangeLabel],
      ...(convWindow ? { [convWindow]: { ...updatedMetrics } } : { ...updatedMetrics }),
    };

    const differenceBtwNowAndLastUpdatedInSecs = now.diff(lastUpdatedInCache, 's');
    const atLeastOneMinutePassed = differenceBtwNowAndLastUpdatedInSecs >= 60;

    if (areMetricsWithChanges || atLeastOneMinutePassed) setLastSessionMetrics(lastSessionClone);
  }, [
    timeRangeLabel,
    networkMetrics,
    lastSessionMetrics,
    setLastSessionMetrics,
    additionals,
    offset,
    convWindow,
    metricsInCache,
    lastSessionClone,
    isPagination,
  ]);

  return {
    lastSessionState: lastSessionMetrics,
    lastSessionInTimeRange: metricsInCache,
  };
}

/*
  additionals: PropTypes.shape({
    offset: PropTypes.number,
    isPagination: PropTypes.bool,
    convWindow: PropTypes.string,
  }),
*/

export function useUpdateSingleMetric({ storageKey, networkMetric, metricKey }) {
  const [lastSessionMetrics = {}, setLastSessionMetrics] = useLocalStorage(storageKey);

  useEffect(() => {
    if (!networkMetric) return;

    const areMetricsWithChanges = !isEqual(networkMetric, lastSessionMetrics?.[metricKey]);

    if (areMetricsWithChanges) setLastSessionMetrics({ ...lastSessionMetrics, [metricKey]: networkMetric });
  });
}
