/* global gql */
import PropTypes from 'prop-types';
import FunnelCustomersAndRevenueMetric from 'src/components/Funnel/PresentationView/Metrics/FunnelCustomersAndRevenueMetric/FunnelCustomersAndRevenueMetric';
import { EmptyComponent, Error } from 'src/components/Generic';

export const QUERY = gql`
  query GET_FUNNEL_CUSTOMERS_AND_REVENUE($funnelItems: [[FunnelItemsInput!]]!, $timeRange: [TimeRangeInput]) {
    getFunnelCustomersAndRevenue(funnelItems: $funnelItems, timeRange: $timeRange) {
      customers
      revenue
    }
  }
`;

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'cache-first' };
};

export const Loading = (props) => {
  return <FunnelCustomersAndRevenueMetric {...props} customers={null} revenue={null} />;
};

export const Empty = () => <EmptyComponent />;

export const Failure = ({ error }) => <Error errorMessage={error.message} />;

export const Success = (props) => {
  const {
    getFunnelCustomersAndRevenue: [{ customers, revenue: revenueWithoutFormat }],
  } = props;

  return <FunnelCustomersAndRevenueMetric {...props} customers={customers} revenue={revenueWithoutFormat} />;
};

Success.propTypes = {
  getFunnelCustomersAndRevenue: PropTypes.array,
};
