import PropTypes from 'prop-types';
import { pretiffyLastUpdate } from 'src/lib/datetimeFormat/prettifyLastUpdate';

import { LabelDropdown, LoadingComponent, DatePicker } from 'src/components/Generic';
import MainContainerLayout from 'src/layouts/MainContainerLayout/MainContainerLayout';
import MainHeaderLayout from 'src/layouts/MainHeaderLayout/MainHeaderLayout';
import MainCardLayout from 'src/layouts/MainCardLayout/MainCardLayout';
import * as WmxTable from 'src/components/Generic/Tables/WmxTable/WmxTable';
import { Flex } from 'src/components/Generic/LayoutUtils/LayoutUtils';

export default function OverviewTableLayout({
  withLocalCache = false,
  children,
  headerProps = {},
  lastUpdated,
  mainCardClassNames,
}) {
  return (
    <MainContainerLayout>
      <OverviewHeader {...headerProps} isLoading={withLocalCache} lastUpdated={lastUpdated} />
      <MainCardLayout scrollable classNames={mainCardClassNames}>
        {children}
      </MainCardLayout>
    </MainContainerLayout>
  );
}

OverviewTableLayout.propTypes = {
  withLocalCache: PropTypes.bool,
  children: PropTypes.any,
  headerProps: PropTypes.shape({
    selectedConvWindow: PropTypes.any,
    setSelectedConvWindow: PropTypes.func,
  }),
  lastUpdated: PropTypes.string,
  mainCardClassNames: PropTypes.object,
};

export function OverviewHeader({
  selectedConvWindow,
  setSelectedConvWindow,
  lastUpdated: lastUpdatedTimestamp,
  isLoading,
}) {
  const lastUpdated = pretiffyLastUpdate(lastUpdatedTimestamp);
  const isUpdatedOnlySecsAgo = lastUpdated.includes('sec');

  const optionsProps = {
    selectedConvWindow,
    setSelectedConvWindow,
    lastUpdated,
    isLoading,
    isUpdatedOnlySecsAgo,
  };

  return (
    <MainHeaderLayout>
      <div className="w-full mb-0 sticky z-30">
        <Flex justify="between">
          <OverviewQueryOptions {...optionsProps} />
          <WmxTable.TableSettings />
        </Flex>
      </div>
    </MainHeaderLayout>
  );
}

export const OverviewQueryOptions = ({
  selectedConvWindow,
  setSelectedConvWindow,
  lastUpdated,
  isLoading,
  isUpdatedOnlySecsAgo,
}) => {
  return (
    <Flex justify="start">
      <div className="mr-4">
        <DatePicker open placement="bottomStart" />
      </div>
      <div className="mr-4">
        <LabelDropdown
          label="Conv. Window"
          dropdownProps={{
            optionsName: ['7', '30', '∞'],
            selectedOption: selectedConvWindow,
            setSelectedOption: setSelectedConvWindow,
            classNames: { buttonTrigger: 'w-auto pl-1 pr-2 border-none justify-start' },
            styles: { list: { width: 'w-auto' } },
            showSelectedValue: true,
          }}
        />
      </div>
      {isLoading && (
        <div className="flex items-center">
          <LoadingComponent />
        </div>
      )}
      {lastUpdated && !isUpdatedOnlySecsAgo && (
        <span className="flex items-center text-white text-xs px-4">Last updated: {lastUpdated} ago</span>
      )}
    </Flex>
  );
};

OverviewQueryOptions.propTypes = {
  isLoading: PropTypes.any,
  isUpdatedOnlySecsAgo: PropTypes.any,
  lastUpdated: PropTypes.any,
  selectedConvWindow: PropTypes.any,
  setSelectedConvWindow: PropTypes.any,
};

OverviewHeader.propTypes = {
  selectedConvWindow: PropTypes.any,
  setSelectedConvWindow: PropTypes.func,
  lastUpdated: PropTypes.string,
  isLoading: PropTypes.bool,
};
