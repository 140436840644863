import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

export default function BarSkeleton({ height = '100%', width = '100%', backgroundColor, foregroundColor }) {
  return (
    <ContentLoader
      speed={2}
      width={width * 0.87}
      height={height * 0.6}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      viewBox="0 0 589 230"
    >
      <rect y="146" width="37" height="75" />
      <rect x="46" y="129" width="37" height="92" />
      <rect x="92" y="146" width="37" height="75" />
      <rect x="138" y="104" width="37" height="117" />
      <rect x="184" y="123" width="37" height="98" />
      <rect x="230" y="98" width="37" height="123" />
      <rect x="276" y="104" width="37" height="117" />
      <rect x="322" y="118" width="37" height="103" />
      <rect x="368" y="91" width="37" height="130" />
      <rect x="414" y="81" width="37" height="140" />
      <rect x="460" y="41" width="37" height="180" />
      <rect x="506" y="24" width="37" height="197" />
      <rect x="552" width="37" height="221" />
    </ContentLoader>
  );
}

BarSkeleton.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  backgroundColor: PropTypes.string,
  foregroundColor: PropTypes.string,
};

export function BarsChartsSkeletons() {
  const bars = [
    {
      h: 100,
      w: 37,
    },
    {
      h: 150,
      w: 37,
    },
    {
      h: 175,
      w: 37,
    },
    {
      h: 168,
      w: 37,
    },
    {
      h: 180,
      w: 37,
    },
    {
      h: 160,
      w: 37,
    },
  ];

  // eslint-disable-next-line react/no-array-index-key
  return bars.map((b, i) => <rect key={`bar-${i}`} height={b.h} width={b.w} />);
}
