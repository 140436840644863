import { useState } from 'react';
import useDebounce from 'src/customHooks/useDebounce';

import { PROVIDERS } from 'wmx-shared-code/accountGlobalVariables';

export const metricsKey = {
  lastSyncsByProvider: 'lastSyncsByProvider',
  lastDailyResync: 'lastDailyResync',
  datapointsOverviewTable: 'datapointsOverviewTable',
};

export function useDatapointsOverview({ currentProviderShown }) {
  const [datapointsOverview, setDatapointsOverview] = useState({
    [PROVIDERS.stripe]: [],
    [PROVIDERS.activeCampaign]: [],
  });

  const setDatapointOverviewByProvider = (tableDataByProvider) => {
    const vendorDatapoints = Array.isArray(tableDataByProvider) ? tableDataByProvider : [];
    return setDatapointsOverview({ ...datapointsOverview, [currentProviderShown]: vendorDatapoints });
  };

  const getDatapointOverviewByProvider = (value) => {
    return value?.[currentProviderShown] || value;
  };

  const getDatapointTotals = () => {
    const providers = Object.values(PROVIDERS);

    const getDatapointByProvider = (provider) => {
      return datapointsOverview[provider].reduce((accum, { count }) => {
        return accum + count;
      }, 0);
    };

    return providers.reduce((accum, provider) => {
      // eslint-disable-next-line no-param-reassign
      accum[provider] = getDatapointByProvider(provider);

      return accum;
    }, {});
  };

  const getDatapointNames = (key) => {
    const providers = Object.values(PROVIDERS);

    const getDatapointByProvider = (provider) => {
      const names = datapointsOverview[provider].map((overview) => overview[key]);

      return [...new Set(names)]; // removes array duplicates
    };

    return providers.reduce((accum, provider) => {
      // eslint-disable-next-line no-param-reassign
      accum[provider] = getDatapointByProvider(provider);

      return accum;
    }, {});
  };

  const isOverviewDefined = !!datapointsOverview?.[currentProviderShown];
  const datapointTotals = isOverviewDefined ? getDatapointTotals() : 0;
  const datapointResyncNames = isOverviewDefined ? getDatapointNames('resyncName') : [];
  const datapointURLNames = isOverviewDefined ? getDatapointNames('URLName') : [];

  const value = datapointsOverview;
  return {
    [metricsKey.datapointsOverviewTable]: {
      value,
      get: getDatapointOverviewByProvider,
      set: setDatapointOverviewByProvider,
    },
    datapointTotals,
    datapointResyncNames,
    datapointURLNames,
    value,
  };
}

export function useLastSyncsProvider() {
  const initialLastSync = { lastSuccessfulAPISync: null, lastSuccessfulWebhookSync: null };
  const [lastSyncsByProvider, setLastSyncsByProvider] = useState({
    [PROVIDERS.stripe]: initialLastSync,
    [PROVIDERS.activeCampaign]: initialLastSync,
  });

  const value = lastSyncsByProvider;

  return {
    [metricsKey.lastSyncsByProvider]: {
      value,
      set: setLastSyncsByProvider,
    },
    value,
  };
}

export function useLastDailyResync() {
  const initialDailyResync = { wasDailyResyncSuccessful: false, failedDatapointsInLastDailyResync: [] };

  const [lastDailyResync, setLastDailyResync] = useState({
    [PROVIDERS.activeCampaign]: initialDailyResync,
  });

  const value = lastDailyResync;

  return {
    [metricsKey.lastDailyResync]: {
      value,
      set: setLastDailyResync,
    },
    value,
  };
}

export function useSearchBox({ initialSearchKeword, debounce = 500 } = {}) {
  const [searchKeyword, setSearchKeyword] = useState(initialSearchKeword);
  const debouncedSearchKeyword = useDebounce(searchKeyword, debounce);

  const onSearchBoxChange = (e) => setSearchKeyword(e.target.value);
  const resetSearchBoxValue = () => setSearchKeyword(initialSearchKeword);

  return {
    onSearchBoxChange,
    resetSearchBoxValue,
    searchBoxKeyword: debouncedSearchKeyword,
  };
}
