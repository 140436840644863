import { useCallback } from 'react';
import { useDashboard } from 'src/contexts/Dashboard';
import { useSegmentBuilder } from 'src/contexts/SegmentBuilder';
import { useSetRecoilState } from 'recoil';
import { timeRange as timeRangeAtom } from 'src/atoms/timeRangeAtom';
import { getFixedRangeFromDynamicRange } from 'src/components/Generic/DatePicker/getTimeRange';
import useDashboardLayout from './useDashboardLayout';

function useGetDashboardDataOnLoad() {
  const {
    setCurrentDashboard,
    setDashboardNamesState,
    setLayoutState,
    setMetricsState,
    setSegmentsState,
    setShowEvents,
    setTabSelected,
  } = useDashboard();
  const { metricsArr, createLayout, allIds, resetSegments } = useDashboardLayout();
  const { segmentsList } = useSegmentBuilder();
  const setTimerange = useSetRecoilState(timeRangeAtom);

  const setStatesOnSuccessLoad = useCallback(
    (defaultDashboard, dashboardNames, dashboardId) => {
      setShowEvents(defaultDashboard.hasEventsStream);
      setLayoutState(defaultDashboard.layout);
      if (defaultDashboard.defaultTimerange) {
        setTimerange({
          label: defaultDashboard.defaultTimerange,
          timeRange: {
            start: getFixedRangeFromDynamicRange(defaultDashboard.defaultTimerange)[0],
            end: getFixedRangeFromDynamicRange(defaultDashboard.defaultTimerange)[1],
          },
        });
      }
      setCurrentDashboard(defaultDashboard);
      setMetricsState(() => {
        return [...metricsArr].map((metric, index) => {
          return { ...metric, enabled: defaultDashboard.settings.metrics[index].enabled };
        });
      });
      if (defaultDashboard.settings.segments.length) {
        setSegmentsState(() => {
          const updatedSegments = [...segmentsList].map((segment, index) => {
            const instance = defaultDashboard.settings.segments[index];
            if (instance) {
              return {
                ...segment,
                dashboardSettings: {
                  enabled: instance.dashboardSettings.enabled,
                },
              };
            }
            return {
              ...segment,
              dashboardSettings: {
                enabled: false,
              },
            };
          });
          return setSegmentsState(updatedSegments);
        });
      } else {
        setSegmentsState(segmentsList);
        if (segmentsList) resetSegments();
      }
      if (dashboardId) {
        setTabSelected(defaultDashboard.name);
      } else {
        setTabSelected(dashboardNames[0]);
      }
      setDashboardNamesState(dashboardNames);
    },
    [
      metricsArr,
      resetSegments,
      segmentsList,
      setCurrentDashboard,
      setDashboardNamesState,
      setLayoutState,
      setMetricsState,
      setSegmentsState,
      setShowEvents,
      setTabSelected,
      setTimerange,
    ]
  );

  const getDashboardDataOnLoad = useCallback(
    (defaultDashboard, dashboardNames, dashboardId) => {
      setMetricsState(metricsArr);
      setSegmentsState(segmentsList);
      if (!defaultDashboard && segmentsList) resetSegments();
      if (defaultDashboard && dashboardNames && segmentsList) {
        setStatesOnSuccessLoad(defaultDashboard, dashboardNames, dashboardId);
      } else {
        setLayoutState(createLayout(allIds));
      }
    },
    [
      allIds,
      createLayout,
      metricsArr,
      resetSegments,
      segmentsList,
      setLayoutState,
      setMetricsState,
      setSegmentsState,
      setStatesOnSuccessLoad,
    ]
  );

  return { getDashboardDataOnLoad };
}

export default useGetDashboardDataOnLoad;
