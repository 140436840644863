import PropTypes from 'prop-types';
import { useDashboard } from 'src/contexts/Dashboard';
import { setThousandsComma } from 'wmx-shared-code/currency/currency';

import { percentageChange } from 'src/lib/chargesTransformations/chargesTransformations';

import React from 'react';
import MetricSkeleton from 'src/components/Generic/Skeletons/MetricSkeleton/MetricSkeleton';
import SegmentMetric from '../SegmentMetric/SegmentMetric';

// eslint-disable-next-line no-undef
export const QUERY = gql`
  query PEOPLE_SEGMENT_METRIC($segmentId: String, $timeRange: TimeRangeInput) {
    peopleSegmentMetric(segmentId: $segmentId, timeRange: $timeRange) {
      segmentId
      segmentName
      totalPeople
      dashboardSettings
      prevTotalPeople
    }
  }
`;

export const beforeQuery = ({ timeRange, segmentId }) => {
  return {
    variables: { timeRange, segmentId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  };
};

export const Loading = ({ segmentId }) => {
  const { useMetricLoading } = useDashboard();
  const result = useMetricLoading({
    metric: `peopleSegmentMetric-${segmentId}`,
    getValues: useSegmentMetric,
    // eslint-disable-next-line react/prop-types
    renderComponent: ({ segmentName, totalPeople, change, prevTotalPeople }) => (
      <SegmentMetric
        name={segmentName}
        value={totalPeople}
        segmentId={segmentId}
        change={change}
        prevValue={prevTotalPeople}
      />
    ),
    LoadingComponent: () => <MetricSkeleton foregroundColor="#1C2631" backgroundColor="#304254" />,
  });
  return result;
};

export const Empty = () => <div>Empty</div>;

export const Failure = ({ error }) => <div style={{ color: 'red' }}>{error.message}</div>;

Failure.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.any,
  }),
};

export const Success = ({ peopleSegmentMetric }) => {
  const segmentObject = useSegmentMetric({ peopleSegmentMetric });

  const { useUpdateMetric } = useDashboard();
  useUpdateMetric({ metric: `peopleSegmentMetric-${segmentObject.segmentId}`, value: peopleSegmentMetric });
  return (
    <SegmentMetric
      name={segmentObject.segmentName}
      value={segmentObject.totalPeople}
      segmentId={segmentObject.segmentId}
      prevValue={segmentObject.prevTotalPeople}
      change={segmentObject.change}
    />
  );
};

Success.propTypes = {
  peopleSegmentMetric: PropTypes.any,
};

function useSegmentMetric(props) {
  if (!props) return {};
  const key = Object.keys(props)[0];
  const segmentObject = props[key];
  const change = percentageChange(segmentObject?.prevTotalPeople, segmentObject?.totalPeople);
  return {
    ...segmentObject,
    totalPeople: setThousandsComma(segmentObject?.totalPeople),
    prevTotalPeople: setThousandsComma(segmentObject?.prevTotalPeople),
    change,
  };
}
