/* eslint-disable react/prop-types */
/* global gql */
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Error } from 'src/components/Generic';
import { useFunnelData } from 'src/components/Funnel/funnelHooks';

export const QUERY = gql`
  query getFunnelItemNamesByAccount($itemNameInService: String!, $idsArray: [String!]!) {
    getFunnelItemNamesByAccount(itemNameInService: $itemNameInService, idsArray: $idsArray) {
      name
    }
  }
`;

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'cache-first' };
};

export const Loading = () => <></>;

export const Empty = ({ renderSpan, typeOfItem }) => renderSpan(typeOfItem);

export const Failure = ({ error }) => <Error errorMessage={error.message} />;

export const Success = ({ getFunnelItemNamesByAccount: [{ name }], renderSpan, segmentItemIdx }) => {
  useUpdateSegmentItemNameInFunnelData({ segmentItemIdx, itemName: name });

  return renderSpan(name);
};

Success.propTypes = {
  getFunnelItemNamesByAccount: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  renderSpan: PropTypes.func,
};

function useUpdateSegmentItemNameInFunnelData({ segmentItemIdx, itemName }) {
  const {
    funnelData,
    setFunnelData,
    currentFunnelPart,
    currentSquareIndex: currSquareIndexInFunnelPart,
  } = useFunnelData();

  useEffect(() => {
    const clonedSquares = funnelData[currentFunnelPart].squares.slice();
    const currSquare = clonedSquares[currSquareIndexInFunnelPart];
    const currSegmentElements = currSquare.segmentElements.slice();
    const currSegmentItem = currSegmentElements[segmentItemIdx];

    const currSegmentItemUpdatedvalue = {
      ...currSegmentItem,
      value: {
        ...currSegmentItem?.value,
        itemName,
      },
    };

    currSegmentElements.splice(segmentItemIdx, 1, currSegmentItemUpdatedvalue);

    const updatedSquare = {
      ...currSquare,
      segmentElements: currSegmentElements,
    };

    clonedSquares.splice(currSquareIndexInFunnelPart, 1, updatedSquare);

    setFunnelData({
      ...funnelData,
      [currentFunnelPart]: {
        ...funnelData[currentFunnelPart],
        squares: clonedSquares,
      },
    });
  }, []);
}
