import { useState, useCallback, useEffect } from 'react';

export default function useTableHeight({ twMarginTopNum }) {
  const [tableHeight, setTableHeight] = useState(0);
  const [resize, setResize] = useState(0);

  const handleResize = () => {
    setResize(resize + 1);
  };

  const mainTableContainerRef = useCallback(
    (node) => {
      if (!node) {
        return;
      }
      const { y: yPosition } = node.getBoundingClientRect();
      setTableHeight(window.innerHeight - yPosition - twMarginTopNum * 4);
    },
    [twMarginTopNum]
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return {
    tableHeight,
    mainTableContainerRef,
  };
}
