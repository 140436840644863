import PropTypes from 'prop-types';

export const rigthPaneButtonStyles =
  'rightPaneButton w-56 text-white rounded bg-wmxHighlightDark-100 hover:bg-wmxHighlightDark-50 m-4 py-6 relative disabled:opacity-50 shadow-xl hover:shadow-buttonHover';
export function FunnelTrackingButton({ onClick, children, comingSoon, disabled }) {
  return (
    <button type="button" className={rigthPaneButtonStyles} onClick={onClick} disabled={comingSoon || disabled}>
      {children}
      {comingSoon && <Badge>soon</Badge>}
    </button>
  );
}

function Badge({ children }) {
  return <span className="absolute top-2 right-2 rounded-lg bg-wmxSecondary-200 px-2 py-1 text-xs">{children}</span>;
}

FunnelTrackingButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  comingSoon: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

Badge.propTypes = {
  children: PropTypes.any,
};
