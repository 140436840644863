/* global gql */

export const CREATE_DASHBOARD_MUTATION = gql`
  mutation CreateDashboardMutation($dashboardSettings: JSON) {
    createDashboard(dashboardSettings: $dashboardSettings) {
      name
      layout
      id
      prettyHash
    }
  }
`;

export const UPDATE_DASHBOARD_MUTATION = gql`
  mutation UpdateDashboardMutation($dashboardSettings: JSON) {
    updateDashboard(dashboardSettings: $dashboardSettings) {
      id
      layout
      hasEventsStream
      name
      defaultTimerange
      settings
      isDefaultDashboard
    }
  }
`;

export const RETRIEVE_DASHBOARD = gql`
  query retrieveDashboard($accountId: String!, $label: String!) {
    retrieveDashboard(accountId: $accountId, label: $label) {
      id
      layout
      hasEventsStream
      name
      defaultTimerange
      settings
      isDefaultDashboard
      prettyHash
    }
  }
`;

export const DASHBOARD_NAME_MUTATION = gql`
  mutation DashboardNameMutation($id: Int!, $newName: String!) {
    editDashboardName(id: $id, newName: $newName) {
      id
      layout
      name
    }
  }
`;

export const DELETE_DASHBOARD_MUTATION = gql`
  mutation deleteDashboard($accountId: String!, $id: Int!) {
    deleteDashboard(accountId: $accountId, id: $id) {
      name
      layout
      id
      settings
    }
  }
`;
