import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Title } from 'src/components/Generic/LayoutUtils/LayoutUtils';

import withApolloQuery from 'src/lib/HOC/withApolloQuery';
import { LoadingComponent, EmptyComponent, Error } from 'src/components/Generic';
import { RowSkeletons } from 'src/components/Generic/Skeletons';

import { useDashboard } from 'src/contexts/Dashboard';
import useRecentActivities, { RECENT_ACTIVITIES_QUERY, SCROLL_LENGTH } from './useRecentActivities';
import { ROW_FORMAT_BY_EVENT } from './EventRow';

function RowsLayout({ children }) {
  const { editMode } = useDashboard();
  return (
    <div
      className={`h-full w-full pb-3 pr-3 ${editMode ? 'border border-wmxPrimary-100 rounded-lg' : ''} flex flex-col`}
    >
      <div className="h-12 pl-5 py-3 flex items-center">
        <div className="rounded-full bg-green-300 animate-pulse h-2 w-2 mr-2" />
        <Title sm>Events stream</Title>
      </div>
      {children}
    </div>
  );
}

const RecentActivitiesData = withApolloQuery(({ data, fetchMore, queryParams }) => {
  const { editMode } = useDashboard();
  return <RowsLayout>{!editMode && <EventsRows {...{ data, fetchMore, queryParams }} />}</RowsLayout>;
});

export default function RecentActivitiesContainer() {
  return (
    <RecentActivitiesData
      {...{
        dataKey: RECENT_ACTIVITIES_QUERY.queryName,
        initialQuery: RECENT_ACTIVITIES_QUERY.query,
        LoadingComponent: () => (
          <RowsLayout>
            <RowSkeletons
              {...{
                rowsAmount: 14,
                height: 32,
                width: '100%',
                foregroundColor: '#1C2631',
                backgroundColor: '#304254',
                styles: { paddingTop: 'pt-0' },
              }}
            />
          </RowsLayout>
        ),
        EmptyComponent: () => (
          <RowsLayout>
            <EmptyComponent text="No events found." className={{ text: 'text-wmxText-200', container: 'mt-6' }} />
          </RowsLayout>
        ),
        // eslint-disable-next-line react/prop-types
        Error: ({ message } = {}) => {
          return <Error errorMessage={message} />;
        },
        queryParams: {
          fetchPolicy: 'network-only',
          variables: {
            offset: 0,
            limit: SCROLL_LENGTH,
          },
        },
      }}
    />
  );
}

function Row({ children }) {
  return <div className="p-1">{children}</div>;
}

function EventsRows({ data, fetchMore, queryParams }) {
  const eventsStreamContainerId = 'events-stream';
  const { hasMoreData, fetchMoreData, dataChunk } = useRecentActivities({
    data,
    dataKey: RECENT_ACTIVITIES_QUERY.queryName,
    fetchMore,
    queryParams,
  });

  const events = dataChunk.map((event, idx) => {
    const key = `${event?.email}-${idx}}`;
    const row = getRow(event);

    return <Row key={key}>{row}</Row>;
  });

  return (
    <div id={eventsStreamContainerId} className="flex-1 pl-3 overflow-y-scroll">
      <InfiniteScroll
        dataLength={dataChunk.length}
        next={fetchMoreData}
        hasMore={hasMoreData}
        loader={<LoadingComponent />}
        scrollableTarget={eventsStreamContainerId}
      >
        {events}
      </InfiniteScroll>
    </div>
  );
}

function getRow({ type, timestamp, name, email, amount }) {
  return ROW_FORMAT_BY_EVENT[type]({ email, timestamp, name, amount });
}

RowsLayout.propTypes = {
  children: PropTypes.any,
};
EventsRows.propTypes = {
  data: PropTypes.object,
  fetchMore: PropTypes.func,
  queryParams: PropTypes.object,
};

RecentActivitiesData.propTypes = {
  data: PropTypes.object,
};

Row.propTypes = {
  children: PropTypes.any,
};
