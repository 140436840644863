import { useAuth } from '@redwoodjs/auth';
import { ACCOUNT_STATUSES } from 'wmx-shared-code/accountGlobalVariables';

import useCurrentUser from 'src/customHooks/useCurrentUser';

import AppLayout from 'src/layouts/AppLayout/AppLayout';
import PropTypes from 'prop-types';
import SubmenuLink from 'src/components/SubmenuLink/SubmenuLink';
import MainCardLayout from 'src/layouts/MainCardLayout/MainCardLayout';
import { FlexCol, Container } from 'src/components/Generic/LayoutUtils/LayoutUtils';

export default function SettingsLayout({ children, prerender = false }) {
  const layoutProps = {
    classNames: { container: 'pr-2' },
    title: 'Settings',
    prerender,
    submenu: {
      nav: <SettingsSubMenu />,
      title: 'Settings',
      isOpen: true,
    },
  };

  const layoutChildren = (
    <MainCardLayout>
      <FlexCol grow>
        <Container size="max">{children}</Container>
      </FlexCol>
    </MainCardLayout>
  );

  return <AppLayout {...layoutProps}>{layoutChildren}</AppLayout>;
}

SettingsLayout.propTypes = {
  children: PropTypes.any,
  prerender: PropTypes.bool,
};

// Should follow the same structure as <Header /> like the others Layouts
const SettingsSubMenu = () => {
  const { isAuthenticated, hasRole } = useAuth();
  const { acIntegration: { isReady } = {}, accountStatus } = useCurrentUser();
  const isTrialExpired = accountStatus === ACCOUNT_STATUSES.trialExpired.dbValue;
  const disableWhenTrialIsExpired = isTrialExpired;
  const disableWhenNoIntegrationAndActiveTrial = !isReady && !isTrialExpired;

  const submenuLinksConfig = [
    {
      title: 'Account',
      page: 'account',
      parentMatch: '/account',
      disabled: false,
      isDefaultPage: true,
    },
    {
      title: 'General',
      page: 'generalSettings',
      parentMatch: '/general',
      disabled: disableWhenTrialIsExpired || disableWhenNoIntegrationAndActiveTrial,
    },
    {
      title: 'Billing',
      page: 'billing',
      parentMatch: '/billing',
      disabled: disableWhenNoIntegrationAndActiveTrial,
    },
    {
      title: 'Integrations',
      page: 'integrations',
      parentMatch: '/integrations',
      disabled: disableWhenTrialIsExpired || disableWhenNoIntegrationAndActiveTrial,
    },
    ...(isAuthenticated && hasRole('superadmin')
      ? [
          {
            title: 'Admin',
            page: 'accounts',
            parentMatch: '/admin',
          },
        ]
      : []),
  ];

  const submenuLinksElements = submenuLinksConfig.map((submenuConfig) => {
    const { title } = submenuConfig;

    return (
      <SubmenuLink key={title} {...submenuConfig}>
        {title}
      </SubmenuLink>
    );
  });

  return <>{submenuLinksElements}</>;
};
