import PropTypes from 'prop-types';
import { setThousandsComma } from 'wmx-shared-code/currency/currency';
import { percentageChange } from 'src/lib/chargesTransformations/chargesTransformations';
import { useDashboard } from 'src/contexts/Dashboard';
import React from 'react';
import SubscribersMetric from 'src/components/SubscribersMetric/SubscribersMetric';
import MetricSkeleton from 'src/components/Generic/Skeletons/MetricSkeleton/MetricSkeleton';

// eslint-disable-next-line no-undef
export const QUERY = gql`
  query DASHBOARD_LEADS($timeRange: TimeRangeInput) {
    leadsMetrics(timeRange: $timeRange) {
      partialSubscribers
      previousSubscribers
    }
  }
`;

export const beforeQuery = ({ timeRange }) => {
  return { variables: { timeRange }, fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-and-network' };
};

export const Loading = () => {
  const { useMetricLoading } = useDashboard();
  const result = useMetricLoading({
    metric: 'leadsMetrics',
    getValues: useNewSubscribers,
    // eslint-disable-next-line react/prop-types
    renderComponent: ({ value, prev, prevValue }) => (
      <SubscribersMetric value={value} change={prev} prevValue={prevValue} />
    ),
    LoadingComponent: () => <MetricSkeleton foregroundColor="#1C2631" backgroundColor="#304254" />,
  });
  return result;
};

export const Empty = () => <div>Empty</div>;

export const Failure = ({ error }) => <div style={{ color: 'red' }}>{error.message}</div>;

Failure.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.any,
  }),
};

export const Success = ({ leadsMetrics, page }) => {
  const { value, prev, prevValue } = useNewSubscribers({ leadsMetrics });

  const { useUpdateMetric } = useDashboard();
  useUpdateMetric({ metric: 'leadsMetrics', value: leadsMetrics });
  return <SubscribersMetric value={value} change={prev} page={page} prevValue={prevValue} />;
};

Success.propTypes = {
  leadsMetrics: PropTypes.any,
  page: PropTypes.any,
};

function useNewSubscribers({ leadsMetrics }) {
  if (!leadsMetrics) return {};
  return {
    value: setThousandsComma(leadsMetrics.partialSubscribers),
    prev: percentageChange(leadsMetrics.previousSubscribers, leadsMetrics.partialSubscribers),
    prevValue: setThousandsComma(leadsMetrics.previousSubscribers),
  };
}
