import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { METRICS } from 'wmx-shared-code/dashboard/metrics';

import { useSegmentBuilder } from 'src/contexts/SegmentBuilder/SegmentBuilder';
import useLocalStorage from 'src/customHooks/useLocalStorage';
import useUpdateMetricsInLocalCache from 'src/customHooks/useUpdateMetricsInLocalCache';
import useCurrentUser from 'src/customHooks/useCurrentUser';
import useCustomerSegment from 'src/customHooks/useCustomerSegment';
import { defaultTimeRange } from 'src/lib/localStorageKeys';

import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';

import RevenueMetricCell from 'src/components/RevenueMetricCell/RevenueMetricCell';
import SubscribersMetricCell from 'src/components/SubscribersMetricCell/SubscribersMetricCell';
import NewCustomerMetricCell from 'src/components/NewCustomerMetricCell/NewCustomerMetricCell';
import RevenuePerLeadMetricCell from 'src/components/Cells/RevenuePerLeadMetricCell/RevenuePerLeadMetricCell';
import { METRICS_OPTIONS } from 'src/pages/DashboardPage/DashboardPage';

const STORAGE_KEY = 'dashboardV3';

const DashboardContext = React.createContext(null);
export const useDashboard = () => React.useContext(DashboardContext);

const removeItem = (array, item) => {
  const index = array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  }
  return [...new Set(array)];
};

const useRemoveCellLoading = ({ metricKey }) => {
  const { setMetricsLoading, metricsLoading } = useDashboard();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const metricsLoadingClone = React.useMemo(() => [...metricsLoading], []);
  React.useEffect(() => {
    setMetricsLoading(removeItem(metricsLoadingClone, metricKey));
  }, [setMetricsLoading, metricsLoadingClone, metricKey]);
};

const useMetricLoading = ({ metric, getValues, LoadingComponent, renderComponent }) => {
  if (!renderComponent || !LoadingComponent)
    throw new Error('This hooks needs a LoadingComponent and a renderComponent function to work');

  const { timeRangeLabel, setMetricsLoading } = useDashboard();
  const [dashboardLastSession = {}] = useLocalStorage(STORAGE_KEY);

  const dashboardCacheInTimeRange = dashboardLastSession?.[timeRangeLabel];

  React.useEffect(() => {
    setMetricsLoading((prev) => [...prev, metric]);
  }, [setMetricsLoading, metric]);

  const values = getValues({ [metric]: dashboardCacheInTimeRange?.[metric] });

  if (renderComponent) return dashboardCacheInTimeRange?.[metric] ? renderComponent(values) : <LoadingComponent />;

  return <></>;
};

export const useUpdateMetric = ({ metric, value }) => {
  // this a global object with all the current dashboard metrics
  const { setDashboardMetrics, timeRangeLabel } = useDashboard();
  // this a local object for each metric for the cache to work

  React.useEffect(() => {
    setDashboardMetrics((dm) => ({ ...dm, [timeRangeLabel]: { ...dm[timeRangeLabel], [metric]: value } }));
  }, [value, metric, setDashboardMetrics, timeRangeLabel]);

  useUpdateMetricsInLocalCache({
    networkMetrics: { [metric]: value },
    storageKey: STORAGE_KEY,
  });

  useRemoveCellLoading({ metricKey: metric });
};

const switchMetricsReducer = (state, action) => {
  let newState;
  switch (action.type) {
    case 'switchMetric':
      newState = {
        ...state,
        [action.payload.metric]: { ...state[action.payload.metric], enabled: !state[action.payload.metric].enabled },
      };
      break;
    default:
      throw new Error();
  }

  return newState;
};

const renderMetric = ({ enabled, available, chart, bg, timeRange, page, key, editMode, label }) => {
  if (METRICS_OPTIONS && (!enabled || !available)) return <></>;
  switch (label) {
    case 'Gross Cash Flow':
      return (
        <div key={key} className={`${editMode ? 'draggable' : 'disabled'}`}>
          <RevenueMetricCell timeRange={timeRange} chart={chart} bg={bg} page={page} />
        </div>
      );
    case 'Leads':
      return (
        <div key={key} className={`${editMode ? 'draggable' : 'disabled'}`}>
          <SubscribersMetricCell timeRange={timeRange} chart={chart} bg={bg} page={page} />
        </div>
      );
    case 'Customers':
      return (
        <div key={key} className={`${editMode ? 'draggable' : 'disabled'}`}>
          <NewCustomerMetricCell timeRange={timeRange} chart={chart} bg={bg} page={page} />
        </div>
      );
    case 'Revenue per lead':
      return (
        <div key={key} className={`${editMode ? 'draggable' : 'disabled'}`}>
          <RevenuePerLeadMetricCell timeRange={timeRange} chart={chart} bg={bg} page={page} />
        </div>
      );
    default:
      return <h1>hello</h1>;
  }
};

const setMetricsGroupWidthHandler = (ref, setMetricGroupWidth) => {
  if (!ref.current) {
    return () => {};
  }
  const { width } = ref.current.getBoundingClientRect();
  return setMetricGroupWidth(width);
};

const useSetGroupWidth = () => {
  const [metricsGroupWidth, setMetricGroupWidth] = React.useState(null);
  const metricGroupRef = React.useRef(null);

  React.useEffect(() => {
    setMetricsGroupWidthHandler(metricGroupRef, setMetricGroupWidth);
  }, [metricGroupRef, setMetricGroupWidth]);

  React.useEffect(() => {
    window.addEventListener('resize', () => setMetricsGroupWidthHandler(metricGroupRef, setMetricGroupWidth));

    return () => {
      window.removeEventListener('resize', () => setMetricsGroupWidthHandler());
    };
  }, []);

  return { metricsGroupWidth, metricGroupRef };
};

export const DashboardProvider = ({ children }) => {
  const {
    stripeIntegration: { isReady: isUserWithStripe },
    hasTagsCustomerSegment,
  } = useCurrentUser();

  const [dashboardLastSession = {}] = useLocalStorage(STORAGE_KEY);
  const { label: timeRangeLabel, timeRange } = useRecoilValue(RecoilTimeRange);

  const [metricsDefaultSettings, storeMetricSettings] = useMetricsSettings({
    hasUserTagsCustomerSegment: hasTagsCustomerSegment,
    isUserWithStripe,
  });
  const [metricsSettings, dispatchMetrics] = React.useReducer(switchMetricsReducer, metricsDefaultSettings);
  const dashboardCacheInTimeRange = dashboardLastSession?.[timeRangeLabel];
  const { lastSession: lastSessionTimestamp } = dashboardCacheInTimeRange || {};
  const [metricsLoading, setMetricsLoading] = React.useState([]);
  const [dashboardMetrics, setDashboardMetrics] = React.useState({});
  const { updateSegment } = useSegmentBuilder();
  const [segmentsListMetrics, setSegmentsListMetrics] = React.useState([]);
  const { metricsGroupWidth, metricGroupRef } = useSetGroupWidth();
  const [editMode, setEditMode] = React.useState(false);
  const [layoutState, setLayoutState] = React.useState(null);
  const [showEvents, setShowEvents] = React.useState(false);
  const [currentDashboard, setCurrentDashboard] = React.useState(null);
  const [dashboardNamesState, setDashboardNamesState] = React.useState([]);
  const [metricsState, setMetricsState] = React.useState(null);
  const [segmentsState, setSegmentsState] = React.useState(null);
  const [creatingNewDashboard, setCreatingNewDashboard] = React.useState(false);
  const [newDashboardName, setNewDashboardName] = React.useState('');
  const [isCustomiseOpened, setIsCustomiseOpened] = React.useState(false);
  const [tabSelected, setTabSelected] = React.useState(null);
  const [editNameMode, setEditNameMode] = React.useState(false);
  const [saveTimeRangeEnabled, setSaveTimeRangeEnabled] = React.useState(false);
  const [timerangeMemo, setTimerangeMemo] = React.useState(defaultTimeRange.get().label);

  useEffect(() => {
    if (!currentDashboard?.defaultTimerange)
      setTimerangeMemo({
        label: timeRangeLabel,
        timeRange,
      });
  }, [timeRangeLabel, timeRange, currentDashboard]);

  const switchMetric = React.useCallback(
    (metric) => {
      storeMetricSettings(metric, metricsSettings);
      return dispatchMetrics({ type: 'switchMetric', payload: { metric } });
    },
    [storeMetricSettings, metricsSettings]
  );

  const switchSegment = React.useCallback(
    ({ id, enabled }) => {
      const dashboardSettings = { enabled };
      if (!enabled) {
        // remove item from list
        setSegmentsListMetrics(segmentsListMetrics.filter((item) => item.id !== id));
      }
      return updateSegment({ variables: { id, dashboardSettings } });
    },
    [updateSegment, segmentsListMetrics]
  );

  const value = React.useMemo(
    () => ({
      creatingNewDashboard,
      currentDashboard,
      dashboardMetrics,
      dashboardNamesState,
      editMode,
      editNameMode,
      isCellLoading: metricsLoading.length > 0,
      isCustomiseOpened,
      lastSessionTimestamp,
      layoutState,
      metricGroupRef,
      metricsGroupWidth,
      metricsLoading,
      metricsSettings,
      metricsState,
      newDashboardName,
      renderMetric,
      saveTimeRangeEnabled,
      segmentsListMetrics,
      segmentsState,
      setCreatingNewDashboard,
      setCurrentDashboard,
      setDashboardMetrics,
      setDashboardNamesState,
      setEditMode,
      setEditNameMode,
      setIsCustomiseOpened,
      setLayoutState,
      setMetricsLoading,
      setMetricsState,
      setNewDashboardName,
      setSaveTimeRangeEnabled,
      setSegmentsListMetrics,
      setSegmentsState,
      setShowEvents,
      setTabSelected,
      showEvents,
      switchMetric,
      switchSegment,
      tabSelected,
      timeRange,
      timeRangeLabel,
      useMetricLoading,
      useUpdateMetric,
      timerangeMemo,
      setTimerangeMemo,
    }),
    [
      creatingNewDashboard,
      currentDashboard,
      dashboardMetrics,
      dashboardNamesState,
      editMode,
      editNameMode,
      isCustomiseOpened,
      lastSessionTimestamp,
      layoutState,
      metricGroupRef,
      metricsGroupWidth,
      metricsLoading,
      metricsSettings,
      metricsState,
      newDashboardName,
      saveTimeRangeEnabled,
      segmentsListMetrics,
      segmentsState,
      setCreatingNewDashboard,
      setCurrentDashboard,
      setDashboardNamesState,
      setEditMode,
      setEditNameMode,
      setIsCustomiseOpened,
      setLayoutState,
      setMetricsState,
      setNewDashboardName,
      setSaveTimeRangeEnabled,
      setSegmentsListMetrics,
      setSegmentsState,
      setShowEvents,
      setTabSelected,
      showEvents,
      switchMetric,
      switchSegment,
      tabSelected,
      timeRange,
      timeRangeLabel,
      timerangeMemo,
      setTimerangeMemo,
    ]
  );

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};

function useMetricsSettings({ hasUserTagsCustomerSegment, isUserWithStripe }) {
  const [storedSettings, setStoredSettings] = useLocalStorage('metricsSettings-v1');
  const { segmentName: customersSegmentName } = useCustomerSegment();

  const hasCustomers = isUserWithStripe || hasUserTagsCustomerSegment;

  const storeSettings = (metric, metricsSettings) => {
    setStoredSettings({ ...storedSettings, [METRICS[metric]]: { enabled: !metricsSettings[metric].enabled } });
  };

  const settings = React.useMemo(
    () => ({
      [METRICS.revenue]: {
        id: 'aH72d',
        enabled: storedSettings[METRICS.revenue]?.enabled !== false,
        available: hasCustomers,
        chart: 'on',
        label: 'Gross Cash Flow',
      },
      [METRICS.subscribers]: {
        id: 'Jksu20',
        enabled: storedSettings[METRICS.subscribers]?.enabled !== false,
        available: true,
        chart: 'off',
        label: 'Leads',
        page: (hasCustomers && 'peopleLeads') || 'allPeople',
      },
      [METRICS.newCustomers]: {
        id: 'Los92e',
        enabled: storedSettings[METRICS.newCustomers]?.enabled !== false,
        available: true,
        chart: (!isUserWithStripe && hasUserTagsCustomerSegment && 'on') || 'off',
        label: customersSegmentName,
        page: (hasCustomers && 'peopleCustomers') || '',
      },
      [METRICS.revenuePerLead]: {
        id: 'Ijs87',
        enabled: storedSettings[METRICS.revenuePerLead]?.enabled !== false,
        available: isUserWithStripe,
        chart: 'off',
        label: 'Revenue per lead',
      },
    }),
    [customersSegmentName, hasCustomers, hasUserTagsCustomerSegment, isUserWithStripe, storedSettings]
  );

  return [settings, storeSettings];
}

DashboardProvider.propTypes = {
  children: PropTypes.any,
};
