import PropTypes from 'prop-types';
import * as Portal from '@radix-ui/react-portal';
import { CrossButton } from 'src/components/Generic';

export function RightPane({ mainContainerRef, children, width = '44%', maxWidth = '1000px' }) {
  const ariaContainerId = 'aria-righSplitPaneContainer';

  const style = {
    zIndex: 999999,
    width,
    maxWidth,
  };
  return (
    <Portal.Root>
      <div
        ref={mainContainerRef}
        style={style}
        className="FunnelRightPane Pane2 h-full py-0 top-0 right-0 bottom-0 fixed bg-wmxBgDark-200 shadow-rightPane rounded-lg pr-6 pb-2 pl-2 flex flex-col "
      >
        <div id={ariaContainerId} className="h-full">
          {children}
        </div>
      </div>
    </Portal.Root>
  );
}

export default function FunnelRightPane({ mainContainerRef, children, classNames }) {
  const ariaContainerId = 'aria-righSplitPaneContainer';

  return (
    <div ref={mainContainerRef} className={`h-full py-0 ${classNames?.container}`} style={{ zIndex: 99999 }}>
      <div
        id={ariaContainerId}
        className={`h-full bg-wmxBgDark-200 shadow-rightPane rounded-lg pr-6 pb-8 -mr-4 flex flex-col ${
          classNames?.subContainer || ''
        } `}
      >
        {children}
      </div>
    </div>
  );
}

FunnelRightPane.propTypes = {
  children: PropTypes.any,
  mainContainerRef: PropTypes.any,
  classNames: PropTypes.shape({
    container: PropTypes.string,
    subContainer: PropTypes.string,
  }),
};

RightPane.propTypes = {
  children: PropTypes.any,
  mainContainerRef: PropTypes.any,
  width: PropTypes.string, // example '44%'
  maxWidth: PropTypes.string, // example ''1000px''
};

export function RightPaneHeader({ onCrossButtonClick, children, classNames }) {
  const crossButtonClassNames = {
    button: 'text-white text-opacity-50 bg-transparent hover:text-opacity-100',
    span: 'text-2xl',
  };

  return (
    <div className={classNames?.container}>
      <div className="flex pt-6 pb-2 px-4 flex-row items-center">
        <div className="w-full">{children}</div>
        <CrossButton classNames={crossButtonClassNames} onClick={onCrossButtonClick} />
      </div>
      <div className="DividerLine mt-2 mb-2 h-0.5 w-full bg-wmxBgDark-100" />
    </div>
  );
}

RightPaneHeader.propTypes = {
  classNames: PropTypes.shape({
    container: PropTypes.string,
  }),
  onCrossButtonClick: PropTypes.func,
  children: PropTypes.any,
};
