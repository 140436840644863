/* global gql */

export const CREATE_TAG_CUSTOMER_SEGMENT = gql`
  mutation createTagCustomerSegment($segment: [[SegmentInput!]]!, $segmentName: String) {
    createTagCustomerSegment(segment: $segment, segmentName: $segmentName) {
      id
      segment
    }
  }
`;

export const UPDATE_TAG_CUSTOMER_SEGMENT = gql`
  mutation updateTagCustomerSegment($id: String!, $segment: [[SegmentInput!]]!, $segmentName: String) {
    updateTagCustomerSegment(id: $id, segment: $segment, segmentName: $segmentName) {
      id
      segment
    }
  }
`;

export const DELETE_TAG_CUSTOMER_SEGMENT = gql`
  mutation deleteTagCustomerSegment($id: String!) {
    deleteTagCustomerSegment(id: $id) {
      id
      segmentName
      accountId
    }
  }
`;

export const GET_USER_TAG_CUSTOMER_SEGMENT_READY_FOR_SEGMENT_BUILDER = gql`
  query GET_USER_TAG_CUSTOMER_SEGMENT_READY_FOR_SEGMENT_BUILDER($timeRange: TimeRangeInput!) {
    getUserTagCustomerSegmentReadyForSegmentBuilder(timeRange: $timeRange) {
      segmentId
      totalPeople
      segmentElements {
        itemName
        people
        itemsIds
        connection
        condition
      }
    }
  }
`;
