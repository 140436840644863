import { DATE_RANGES_PLAN } from 'wmx-shared-code/accountGlobalVariables';
import { FIXED_TIMERANGES, CUSTOM_LABEL } from 'wmx-shared-code/timeRange';
import { getDynamicRangeLabels } from 'src/components/Generic/DatePicker/getDynamicRangeLabels';
import { defaultTimeRange, defaultTimezone } from 'src/lib/localStorageKeys';

import { getFixedTimeRangesByTimezone } from 'src/lib/handleTimezone';

// to add a supported range we should also add the case in getFixedRangeFromDynamicRange

export const supportedRanges = Object.values(FIXED_TIMERANGES);

export const filteredSupportedRangesByPlan = ({ plan }) => {
  return supportedRanges.filter((range) => {
    if (!DATE_RANGES_PLAN[plan]) return true;
    return DATE_RANGES_PLAN[plan].includes(range) || DATE_RANGES_PLAN[plan] === 'all';
  });
};

const { label: localDefaultLabel } = defaultTimeRange.get();
const { timezone: localDefaultTimezone } = defaultTimezone.get();

// This works for those accounts that have not set their own default time range
const globalDefaultTimeRange = {
  label: FIXED_TIMERANGES.last30d,
  timeRange: getFixedTimeRangesByTimezone(localDefaultTimezone).last30d,
};

export const initialLabel = localDefaultLabel || globalDefaultTimeRange.label;
export const initialFixedTimeRange = localDefaultLabel
  ? getFixedRangeFromDynamicRange(localDefaultLabel)
  : globalDefaultTimeRange.timeRange;

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function getFixedRangeFromDynamicRange(dynamicRange, tz) {
  if (!dynamicRange) return initialFixedTimeRange;
  const key = getKeyByValue(FIXED_TIMERANGES, dynamicRange);
  const fixedTimeRangesWithTimezone = getFixedTimeRangesByTimezone(tz || localDefaultTimezone);
  return fixedTimeRangesWithTimezone[key];
}

// According to account plan and selected labels it will return those necessary labels for the Date Picker
export function getDatePickerLabels({ withCustomDatePicker, accountType, shouldUpdateGlobalDatePicker }) {
  const supportedRangesByPlan = filteredSupportedRangesByPlan({ plan: accountType });

  const rangesWithoutCustom = supportedRanges.filter((labelName) => labelName !== CUSTOM_LABEL);
  const labelsToShow = withCustomDatePicker ? supportedRanges : rangesWithoutCustom;
  const dynamicRanges = getDynamicRangeLabels({
    allRanges: labelsToShow,
    supportedRanges: supportedRangesByPlan,
    shouldUpdateGlobalDatePicker,
  });

  return dynamicRanges;
}

export function getDatepickerDropdownOption({ dynamicRanges }) {
  return dynamicRanges.map((obj) => {
    return {
      label: obj.label,
      value: obj.text,
      hasSubmenu: obj.text === 'Custom',
    };
  });
}
