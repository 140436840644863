/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { XYChart, Tooltip, LineSeries, buildChartTheme, BarGroup, BarSeries } from '@visx/xychart';
import { curveMonotoneX } from '@visx/curve';

import { percentageChange } from 'src/lib/chargesTransformations/chargesTransformations';

import './chart.css';

import { Change } from 'src/components/Dashboard/MetricItem/MetricItem';

import { formatDatabaseRecords } from 'src/lib/handleTimezone';
import { PERIOD_DETAIL_FORMAT } from 'src/lib/graphsHelpers/graphsPeriods';
import { useDashboard } from 'src/contexts/Dashboard';
import { Card, HelperText, Spacer, Title, Flex, HSpacer } from '../Generic/LayoutUtils/LayoutUtils';

const x = (d) => formatDatabaseRecords({ date: d.timestamp.start });
const y = (d) => {
  return d.amount || 0;
};

const defaultMargins = { top: 4, bottom: 4, left: 0, right: 0 };

const customTheme = buildChartTheme({
  backgroundColor: '#1c273100',
  colors: ['#ffffff', '#7a8eff44'],
  gridColor: '#336d88',
  gridColorDark: '#1d1b38',
  svgLabelBig: { fill: '#1d1b38' },
});

const MetricChart = ({
  data,
  prevData,
  type = 'segment',
  getX = x,
  getY = y,
  margin = defaultMargins,
  timeSeriePeriod,
  tooltipFormatter = () => '',
  boxHeight,
  boxWidth,
}) => {
  let values;
  if (type === 'leads' || type === 'customers' || type === 'revenue') {
    values = data.map((e) => e.amount);
  } else {
    values = data.map((e) => e.amount[0]);
  }
  const xScale = { type: 'band' };
  const yScale = { type: 'linear', domain: [0, Math.max(...values)] };
  const [showTooltip, setShowTooltip] = useState(true);
  return (
    <XYChart
      theme={customTheme}
      xScale={xScale}
      yScale={yScale}
      height={boxHeight * 0.6}
      width={boxWidth * 0.87}
      margin={margin}
      onPointerOut={() => setShowTooltip(false)}
      onPointerMove={() => !showTooltip && setShowTooltip(true)}
    >
      <LineSeries
        dataKey="lines"
        data={data}
        xAccessor={getX}
        yAccessor={getY}
        fillOpacity={0.4}
        curve={curveMonotoneX}
        lineProps={{
          stroke: '#95a2eeff',
        }}
      />
      <BarGroup>
        <BarSeries dataKey="bars" data={data} xAccessor={getX} yAccessor={getY} radiusTop radius={1} enableEvents />
      </BarGroup>
      {showTooltip && (
        <Tooltip
          showHorizontalCrosshair
          showVerticalCrosshair
          showDatumGlyph
          horizontalCrosshairStyle={{ stroke: '#4464f3' }}
          verticalCrosshairStyle={{ stroke: '#4464f3' }}
          applyPositionStyle
          debounce={0.01}
          zIndex={99999}
          renderGlyph={() => renderGlyph()}
          renderTooltip={({ tooltipData }) => (
            <GraphTooltip
              data={tooltipData}
              getX={getX}
              getY={getY}
              timeSeriePeriod={timeSeriePeriod}
              prevData={prevData}
              tooltipFormatter={tooltipFormatter}
            />
          )}
        />
      )}
    </XYChart>
  );
};

function renderGlyph() {
  return <circle cx={10} cy={10} r={3} fill="#2F20D3" />;
}

function GraphTooltip({ data, getY, timeSeriePeriod, prevData, tooltipFormatter }) {
  const { timeRangeLabel } = useDashboard();
  function formatTooltipDate() {
    if (timeRangeLabel !== 'Last 24h') {
      return PERIOD_DETAIL_FORMAT[timeSeriePeriod]({
        start: data?.nearestDatum?.datum.timestamp.start,
        end: data?.nearestDatum?.datum.timestamp.end,
      });
    }
    return PERIOD_DETAIL_FORMAT.hourly24h({
      start: data?.nearestDatum?.datum.timestamp.start,
      end: data?.nearestDatum?.datum.timestamp.end,
    });
  }
  return (
    <Card shadow="md" padding="sm" border={{ position: 'left', color: 'primary' }} bg={400}>
      {data?.nearestDatum?.datum && (
        <HelperText>
          <div className="font-normal">{formatTooltipDate()}</div>
        </HelperText>
      )}
      <Spacer />
      <Flex>
        <Title>{data?.nearestDatum?.datum && tooltipFormatter(getY(data?.nearestDatum?.datum))}</Title>
        <HSpacer size="xs" />
        {prevData && <Change>{percentageChange(getPrevData(data, prevData), getY(data?.nearestDatum?.datum))}</Change>}
      </Flex>
    </Card>
  );
}

GraphTooltip.propTypes = {
  data: PropTypes.shape({
    nearestDatum: PropTypes.shape({
      datum: PropTypes.func,
    }),
  }),
  getY: PropTypes.func,
  prevData: PropTypes.any,
  timeSeriePeriod: PropTypes.any,
  tooltipFormatter: PropTypes.func,
};

function getPrevData(data, prevData) {
  if (!prevData) return null;
  const index = data.nearestDatum.datum.t;
  return prevData[index]?.amount;
}

MetricChart.propTypes = {
  data: PropTypes.any,
  getX: PropTypes.any,
  getY: PropTypes.any,
  margin: PropTypes.shape({
    bottom: PropTypes.any,
    left: PropTypes.any,
    right: PropTypes.any,
    top: PropTypes.any,
  }),
  prevData: PropTypes.any,
  timeSeriePeriod: PropTypes.any,
  boxHeight: PropTypes.any,
  boxWidth: PropTypes.any,
  tooltipFormatter: PropTypes.func,
  type: PropTypes.string,
};

export default MetricChart;
