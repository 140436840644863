import PropTypes from 'prop-types';
import ReactSplitPane from 'react-split-pane';
import { navigate, routes } from '@redwoodjs/router';

import { isBrowser } from '@redwoodjs/prerender/browserUtils';

import useRightPane from 'src/customHooks/useRightPane';
import useCurrentUser from 'src/customHooks/useCurrentUser';

import { useTriggerPageSeenEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';
import { INTEGRATION_STATUSES, PROVIDERS } from 'wmx-shared-code/accountGlobalVariables';

import IntegrationsProvider, { useIntegrationsContext } from 'src/contexts/Integrations';
import VendorOverviewRightPane from 'src/components/IntegrationTopic/VendorOverviewRightPane/VendorOverviewRightPane';
import ActiveCampaignIntegrationCell from 'src/components/IntegrationTopic/ActiveCampaignIntegrationCell/ActiveCampaignIntegrationCell';
import StripeIntegrationCell from 'src/components/IntegrationTopic/StripeIntegrationCell/StripeIntegrationCell';
import { Flex, Spacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';

const rightPanesTypes = {
  vendorOverview: 'VendorOverview',
};

export default function IntegrationsPage(props) {
  return (
    <IntegrationsProvider urlProps={props}>
      <IntegrationsPageComponent {...props} />
    </IntegrationsProvider>
  );
}

function IntegrationsPageComponent() {
  const {
    stripeIntegration: { status: stripeStatus = INTEGRATION_STATUSES.notConnected } = {},
    acIntegration: { status: acStatus = INTEGRATION_STATUSES.notConnected } = {},
  } = useCurrentUser();

  const { currentProviderShown, isURLWithIntegrationsSettings } = useIntegrationsContext() || {};

  useTriggerPageSeenEvent({
    pageName: SEGMENT_TRACK_EVENTS_NAMES.integrationsPage,
    shouldEventBeSent: !currentProviderShown,
  });

  const isUrlTakingToProvider = !!currentProviderShown;
  const initialType = isUrlTakingToProvider ? rightPanesTypes.vendorOverview : null;

  const {
    rightPaneDefaultSize,
    onRightPaneOpen,
    isRightPaneOpen,
    onRightPaneClose,
    onRightPaneDragStarted,
    onRightPaneDragFinished,
    rightPaneStyles,
    rightPaneRef,
    rightPaneType,
    onOutsideClickResizerConditionHandler,
  } = useRightPane({
    initialOpenState: !!initialType,
    initialType,
    rightDefaultSize: isBrowser ? window.innerWidth / 1.85 : 0,
  });

  const onPaneClose = () => {
    onRightPaneClose();
    navigate(routes.integrations());
  };

  const vendorOverviewPaneProps = {
    onRightPaneClose: onPaneClose,
    onOutsideClickResizerConditionHandler,
    rightPaneRef,
  };

  const componentByPaneType = {
    [rightPanesTypes.vendorOverview]: () => <VendorOverviewRightPane {...vendorOverviewPaneProps} />,
    Default: () => Error('No component matched'),
  };

  const renderRightPaneComponent = componentByPaneType[rightPaneType] || componentByPaneType.Default;

  const notAllowLeftPaneCursor = rightPaneType === rightPanesTypes.customerSegment ? 'cursor-not-allowed' : '';

  const onEditClick = (vendor) => {
    navigate(routes.providerSettings({ provider: vendor }));
    onRightPaneOpen({ type: rightPanesTypes.vendorOverview });
  };

  return (
    <>
      <ReactSplitPane
        split="vertical"
        style={{ height: null }}
        maxSize={0}
        size={rightPaneDefaultSize}
        primary="second"
        pane2Style={rightPaneStyles}
        onDragStarted={onRightPaneDragStarted}
        onDragFinished={onRightPaneDragFinished}
      >
        <Flex col>
          <ActiveCampaignIntegrationCell onEditClick={onEditClick} acStatus={acStatus} />
          <Spacer />
          <StripeIntegrationCell stripeStatus={stripeStatus} shouldBeCached={isURLWithIntegrationsSettings} />
        </Flex>

        {isRightPaneOpen && renderRightPaneComponent()}
      </ReactSplitPane>
      {isRightPaneOpen && (
        <div
          className={`overlayPane fixed w-full h-full top-0 left-0 ${notAllowLeftPaneCursor} bg-transparent`}
          style={{ zIndex: 99 }}
        />
      )}
    </>
  );
}

const integrationsPageProps = {
  segment: PropTypes.string,
  provider: PropTypes.oneOf(Object.values(PROVIDERS)),
};

IntegrationsPage.propTypes = {
  ...integrationsPageProps,
};

IntegrationsPageComponent.propTypes = {
  ...integrationsPageProps,
};
