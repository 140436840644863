import { useRecoilValue } from 'recoil';

import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';
import { usePeopleReportsContext, useTriggerPeoplePageVisited } from 'src/layouts/PeopleLayout/peopleReportsProvider';
import useOrderBy from 'src/components/Analyze/Overview/hooks/useOrderBy';

import { LEADS_PEOPLE_REPORT_QUERY } from 'src/components/PeopleReportsMenu/PeopleReportsTable/peopleReportsQueries';

import PeopleReportsTableLoadingSkeleton from 'src/components/PeopleReportsMenu/PeopleReportsTableLoadingSkeleton/PeopleReportsTableLoadingSkeleton';
import PeopleReportsTable, {
  PeopleReportsTable as PeopleReportsTableWithoutHOC,
  SegmentsHeader,
} from 'src/components/PeopleReportsMenu/PeopleReportsTable/PeopleReportsTable';

import { getLeadsPeopleProps } from 'src/components/PeopleReportsMenu/PeopleReportsTable/peopleTableSettings';
import {
  getPeopleReportsOrderByDefaults as getOrderByDefaults,
  segmentDefaultTypes,
} from 'src/components/PeopleReportsMenu/PeopleReportsTable/peopleTableSettingsHelpers';
import MainHeaderLayout from 'src/layouts/MainHeaderLayout/MainHeaderLayout';
import { PeopleQueryOptions } from 'src/layouts/PeopleLayout/PeopleLayout';
import MainContainerLayout from 'src/layouts/MainContainerLayout/MainContainerLayout';
import MainCardLayout from 'src/layouts/MainCardLayout/MainCardLayout';
import { EmptyComponent } from 'src/components/Generic';

const RENDER_EACH_SCROLL_FOR_TABLE = 60;

export default function PeopleLeadsPage() {
  const { isTableFetching, setIsTableFetching, peopleTableData } = usePeopleReportsContext();
  const { timeRange } = useRecoilValue(RecoilTimeRange);

  useTriggerPeoplePageVisited({ segmentType: segmentDefaultTypes.leadsPeople });

  const { columnOrderBy, columnOrderByDirection, onOrderBy } = useOrderBy({
    typeOfReport: segmentDefaultTypes.leadsPeople,
    getOrderByDefaults,
    onClick: () => setIsTableFetching(true),
  });

  const customersPeopleProps = {
    dataKey: LEADS_PEOPLE_REPORT_QUERY.queryName,
    initialQuery: LEADS_PEOPLE_REPORT_QUERY.query,
    onOrderBy,
    LoadingComponent: () => <LoadingComponent />,
    EmptyComponent: () => (
      <MainContainerLayout>
        <SegmentsHeader title="Leads" />
        <MainHeaderLayout>
          <PeopleQueryOptions />
        </MainHeaderLayout>
        <MainCardLayout>
          <EmptyComponent />
        </MainCardLayout>
      </MainContainerLayout>
    ),
    queryParams: {
      fetchPolicy: 'cache-first',
      variables: {
        offset: 0,
        limit: RENDER_EACH_SCROLL_FOR_TABLE,
        orderBy: columnOrderBy,
        orderByDirection: columnOrderByDirection,
        timeRange,
      },
    },
    getTableProps: getLeadsPeopleProps,
  };

  function LoadingComponent() {
    return isTableFetching ? (
      <PeopleReportsTableWithoutHOC {...customersPeopleProps} data={peopleTableData} isLoadingTable />
    ) : (
      <MainContainerLayout>
        <SegmentsHeader title="Leads" />
        <MainHeaderLayout>
          <PeopleQueryOptions />
        </MainHeaderLayout>
        <MainCardLayout>
          <PeopleReportsTableLoadingSkeleton />
        </MainCardLayout>
      </MainContainerLayout>
    );
  }

  return <PeopleReportsTable {...customersPeopleProps} />;
}
