import PropTypes from 'prop-types';
import { useMemo } from 'react';

import useCurrentUser from 'src/customHooks/useCurrentUser';
import useInfiniteScroll from 'src/customHooks/useInfiniteScroll';
import useUpdateMetricsInLocalCache from 'src/customHooks/useUpdateMetricsInLocalCache';
import withApolloQuery from 'src/lib/HOC/withApolloQuery';
import {
  useUpdateRightPaneCurrentData,
  useRightPaneNotLoadingAnymore,
} from 'src/components/Analyze/Overview/hooks/overviewProvider';

import * as WmxTable from 'src/components/Generic/Tables/WmxTable/WmxTable';
import { OverviewQueryOptions } from 'src/components/Analyze/Overview/OverviewTableLayout/OverviewTableLayout';
import { getOverviewTableClassNames } from 'src/components/Analyze/Overview/OverviewTable/tableSettings';

import {
  ORDER_BY_EMAILS_ENUM_VALUES,
  ORDER_BY_CUSTOMERS_ENUM_VALUES,
} from 'src/components/Analyze/Overview/OverviewTable/tableQueries';
import { ORDER_COLUMN_DIRECTIONS } from 'wmx-shared-code/reports/emailsOverview';
import MainHeaderLayout from 'src/layouts/MainHeaderLayout/MainHeaderLayout';
import { pretiffyLastUpdate } from 'src/lib/datetimeFormat/prettifyLastUpdate';
import MainContainerLayout from 'src/layouts/MainContainerLayout/MainContainerLayout';
import MainCardLayout from 'src/layouts/MainCardLayout/MainCardLayout';
import { RightPaneHeader } from 'src/components/Funnel/FunnelRightPane/FunnelRightPane';
import { Navigation } from '../../DetailModal/Tables/CustomersTable/CustomersTable';

export function OverviewTable({
  activeTable,
  onCellClick,
  onOrderBy,
  classNames,
  dataKey,
  getTableProps,
  headerProps,
  localCacheData,
  data,
  queryParams,
  fetchMore,
  isInsideModal = false,
  withLayout = false,
  isLoadingRightPaneTable,
  navigateBack,
  onlyTypes,
  clickAnalyzePath,
  isRightPaneFetchingData,
  onRightPaneClosing,
}) {
  // eslint-disable-next-line no-use-before-define
  const { tableProps, cache } = useOverviewTableProps({
    activeTable,
    onCellClick,
    onOrderBy,
    classNames,
    dataKey,
    getTableProps,
    data,
    queryParams,
    fetchMore,
    isInsideModal,
    isLoadingRightPaneTable,
    localCacheData,
    headerProps,
  });

  // eslint-disable-next-line no-use-before-define
  const queryOptions = useHeaderOptions({
    ...headerProps,
    isLoading: !!localCacheData,
    lastUpdated: cache.lastSessionInTimeRange?.lastSession,
  });

  const navigationProps = { navigateBack, onlyTypes, clickAnalyzePath, isRightPaneFetchingData, onRightPaneClosing };

  return (
    <WmxTable.TableProvider tableProps={tableProps}>
      {withLayout ? (
        <MainContainerLayout>
          <MainHeaderLayout>
            <OverviewQueryOptions {...queryOptions} />
            <WmxTable.TableSettings />
          </MainHeaderLayout>
          <MainCardLayout>
            <WmxTable.Table />
          </MainCardLayout>
        </MainContainerLayout>
      ) : (
        <>
          <RightPaneHeader onCrossButtonClick={onRightPaneClosing}>
            <Navigation {...navigationProps} />
          </RightPaneHeader>
          <WmxTable.Table />
        </>
      )}
    </WmxTable.TableProvider>
  );
}

const useHeaderOptions = ({ selectedConvWindow, setSelectedConvWindow, lastUpdated, isLoading }) => {
  const lastUpdatedPretty = pretiffyLastUpdate(lastUpdated);
  const isUpdatedOnlySecsAgo = lastUpdatedPretty.includes('sec');

  return {
    selectedConvWindow,
    setSelectedConvWindow,
    lastUpdated: lastUpdatedPretty,
    isLoading,
    isUpdatedOnlySecsAgo,
  };
};

const useOverviewTableProps = ({
  activeTable,
  onCellClick,
  onOrderBy,
  classNames,
  dataKey,
  getTableProps,
  data,
  queryParams,
  fetchMore,
  isInsideModal = false,
  isLoadingRightPaneTable,
  localCacheData,
  headerProps,
}) => {
  const {
    defaultCurrency: { threeLetters: defaultCurrencyThreeLetters },
    hasTagsCustomerSegment: isCustomerSegmentInDatabaseCreated,
    isUserWithCustomerData,
    stripeIntegration: { isReady: isUserWithStripe },
  } = useCurrentUser();

  useUpdateRightPaneCurrentData({ data: data || [], shouldUpdate: isInsideModal });
  useRightPaneNotLoadingAnymore({ isLoadingTable: isLoadingRightPaneTable });

  const scrollLength = queryParams?.variables?.limit;

  const freshAvailableData = data || localCacheData || {};

  const { hasMoreData, dataChunk, fetchMoreData } = useInfiniteScroll({
    scrollLength,
    incomingData: {
      dataArray: freshAvailableData?.[dataKey],
      key: dataKey,
    },
    fetchMore,
    fetchMoreParams: queryParams,
  });

  const localCacheAdditionals = {
    isPagination: dataChunk.length > scrollLength,
    convWindow: headerProps?.selectedConvWindow,
  };

  const { lastSessionInTimeRange } = useUpdateMetricsInLocalCache({
    networkMetrics: data,
    storageKey: activeTable,
    additionals: localCacheAdditionals,
  });

  const overviewTableClassNames = getOverviewTableClassNames({ activeTable, isInsideModal });

  const overviewTableProps = useMemo(
    () => ({
      data: dataChunk, // can be overwritten by getTableProps
      hasMoreData,
      setNextChunk: fetchMoreData,
      classNames: classNames || overviewTableClassNames,
    }),
    [dataChunk, hasMoreData, fetchMoreData, classNames, overviewTableClassNames]
  );

  const tableSettings = getTableProps({
    activeTable,
    dataChunk,
    onCellClick,
    onOrderBy,
    acUrl: freshAvailableData?.activeCampaignUrl,
    isInsideModal,
    isUserWithStripe,
    isCustomerSegmentInDatabaseCreated,
    isUserWithCustomerData,
    defaultCurrencyThreeLetters,
  });

  return {
    tableProps: {
      ...overviewTableProps,
      ...tableSettings,
      columnsOptions: true,
      compulsoryColumns: ['Name'],
      defaultColumns: ['Conv. Rate', 'Revenue', 'Leads', 'Customers', 'Opens'],
    },
    cache: {
      lastSessionInTimeRange,
    },
  };
};

OverviewTable.propTypes = {
  activeTable: PropTypes.oneOf(['emails', 'automations']),
  classNames: PropTypes.object,
  clickAnalyzePath: PropTypes.any,
  data: PropTypes.shape({
    activeCampaignUrl: PropTypes.string,
  }),
  dataKey: PropTypes.string,
  fetchMore: PropTypes.func,
  getTableProps: PropTypes.func,
  headerProps: PropTypes.shape({
    selectedConvWindow: PropTypes.string,
    setSelectedConvWindow: PropTypes.func,
  }),
  isInsideModal: PropTypes.bool,
  isLoadingRightPaneTable: PropTypes.bool,
  isRightPaneFetchingData: PropTypes.any,
  localCacheData: PropTypes.object,
  navigateBack: PropTypes.any,
  onCellClick: PropTypes.func,
  onOrderBy: PropTypes.shape({
    columnOrderBy: PropTypes.oneOf([...ORDER_BY_EMAILS_ENUM_VALUES, ...ORDER_BY_CUSTOMERS_ENUM_VALUES]),
    columnOrderByDirection: PropTypes.oneOf([...Object.values(ORDER_COLUMN_DIRECTIONS)]),
    onHeaderClick: PropTypes.func,
  }),
  onRightPaneClosing: PropTypes.any,
  onlyTypes: PropTypes.any,
  queryParams: PropTypes.shape({
    fetchPolicy: PropTypes.string,
    variables: PropTypes.shape({
      offset: PropTypes.number,
      limit: PropTypes.number,
      timeRange: PropTypes.shape({
        start: PropTypes.object,
        end: PropTypes.object,
      }),
    }), // Going directly to withApolloQuery
  }),
  withLayout: PropTypes.bool,
};

export default withApolloQuery(OverviewTable);
