import { useMemo } from 'react';
import { routes, navigate, useLocation } from '@redwoodjs/router';
import PropTypes from 'prop-types';

import { useUpgradeProDialog } from 'src/components/UpgradeProDialog/UpgradeProDialog';
import useAccount from 'src/customHooks/useAccount';
import { useIntegrationsContext } from 'src/contexts/Integrations';

import IntegrationStatusColour from 'src/components/IntegrationTopic/IntegrationStatusColour/IntegrationStatusColour';
import DeleteIntegrationButton from 'src/components/IntegrationTopic/DeleteIntegrationButton/DeleteIntegrationButton';
import {
  INTEGRATION_STATUSES,
  INTEGRATION_ORIGINS,
  REDIRECTS_STRIPE,
  PROVIDERS,
} from 'wmx-shared-code/accountGlobalVariables';

import { Card, Flex, HSpacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { LoadingComponent, Button } from 'src/components/Generic';

import { getStripeState } from 'src/components/IntegrationTopic/integrationsHelpers';

const getConnectPageByProvider = (provider, accountId, origin) => {
  switch (provider) {
    case 'activecampaign':
      navigate(routes.newWildMailIntegration());
      break;
    case 'stripe':
      window.location.href = `
      https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${
        process.env.REDWOOD_ENV_STRIPE_CLIENT_ID
      }&scope=read_only&state=${getStripeState({ accountId, origin })}&redirect_uri=${
        REDIRECTS_STRIPE[process.env.STAGE]
      }`;
      break;
    default:
      break;
  }
};

export default function IntegrationStatusCard({
  provider,
  status,
  onEditClick,
  logo,
  allowed = true,
  withStatus = true,
  hasEditButton,
}) {
  const { accountId } = useAccount();
  const { settingsByProvider } = useIntegrationsContext();
  const { name: vendorName } = settingsByProvider[provider];

  const buttonProps = {
    status,
    provider,
    onEditClick,
    accountId,
    allowed,
    hasEditButton,
  };

  const statusProps = {
    status,
    withStatus,
    allowed,
  };

  return (
    <Card padding="sm" paddingY="md">
      <Flex justify="between">
        <Flex>
          {logo}
          <HSpacer />
          <Flex col items="start">
            <div className="ml-1  text-wmxText-100 font-bold">{vendorName}</div>
            <Status {...statusProps} />
          </Flex>
        </Flex>

        <Buttons {...buttonProps} />
      </Flex>
    </Card>
  );
}

const Status = ({ status, withStatus, allowed }) => {
  return (
    <>
      {status === 'Loading' ? (
        <LoadingComponent />
      ) : (
        withStatus && (
          <div className="ml-1 text-sm text-wmxText-200 ">
            <IntegrationStatusColour status={status} isAllowed={allowed} />{' '}
            {allowed ? status : INTEGRATION_STATUSES.notConnected}
          </div>
        )
      )}
    </>
  );
};

const Buttons = ({ status, provider, onEditClick, accountId, allowed = true, hasEditButton }) => {
  const { pathname } = useLocation();
  const { open } = useUpgradeProDialog();

  const origin = useMemo(() => {
    if (pathname.indexOf('onboarding') > 0) return INTEGRATION_ORIGINS.onboarding;
    return INTEGRATION_ORIGINS.integrations;
  }, [pathname]);

  const handleIntegrationConnectionClick = () => {
    if (!allowed) {
      open();
    }
    if (status === INTEGRATION_STATUSES.notConnected && allowed) {
      getConnectPageByProvider(provider, accountId, origin);
    }
  };

  const isAlreadyConnected = status === INTEGRATION_STATUSES.ready;
  const isConnectedOrBeingConnected = (isAlreadyConnected || status === INTEGRATION_STATUSES.importing) && allowed;

  return (
    <Flex justify="start" items="center">
      {hasEditButton && isAlreadyConnected && (
        <div className="mr-4">
          <Button type="button" variant="primary" onClick={() => onEditClick(provider)}>
            <div className="ml-1 mr-2 leading-loose">Edit</div>
          </Button>
        </div>
      )}

      {status === INTEGRATION_STATUSES.notConnected && allowed && (
        <Button
          type="button"
          variant="primary"
          disabled={status === 'Syncing'}
          onClick={handleIntegrationConnectionClick}
        >
          <div className="ml-1 leading-loose">Connect</div>
        </Button>
      )}

      {isConnectedOrBeingConnected && <DeleteIntegrationButton provider={provider} />}
    </Flex>
  );
};

Buttons.propTypes = {
  accountId: PropTypes.any,
  allowed: PropTypes.bool,
  provider: PropTypes.any,
  status: PropTypes.string,
  onEditClick: PropTypes.func,
  hasEditButton: PropTypes.bool,
};

Status.propTypes = {
  allowed: PropTypes.any,
  status: PropTypes.string,
  withStatus: PropTypes.any,
};

IntegrationStatusCard.propTypes = {
  allowed: PropTypes.any,
  hasEditButton: PropTypes.bool,
  connectOrUpgrade: PropTypes.string,
  logo: PropTypes.any,
  onEditClick: PropTypes.func,
  provider: PropTypes.oneOf([...Object.values(PROVIDERS)]),
  status: PropTypes.string,
  withStatus: PropTypes.bool,
  statusQuery: PropTypes.any, // gql query to get status
};
