/* global gql */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';

import useCurrentUser from 'src/customHooks/useCurrentUser';

import { setThousandsComma } from 'src/lib/generic/handlers';
import { getAmountWithCurrencySymbol } from 'src/lib/generic/currency';

import { useGetTableRows } from 'src/components/Funnel/FunnelManagementList/funnelListHooks';

export const QUERY = gql`
  query getFunnelById(
    $funnelId: String!
    $funnelItemsFromDb: [[DbFunnelItems!]]!
    $timeRange: TimeRangeInput!
    $withFunnelItemsFromDbCalculated: Boolean!
    $withOpens: Boolean
    $withRevenue: Boolean
    $withSquares: Boolean
  ) {
    getFunnelById(
      funnelId: $funnelId
      funnelItemsFromDb: $funnelItemsFromDb
      timeRange: $timeRange
      withFunnelItemsFromDbCalculated: $withFunnelItemsFromDbCalculated
      withOpens: $withOpens
      withRevenue: $withRevenue
      withSquares: $withSquares
    ) {
      funnelItems {
        funnelPartInString
        people
        customersAndRevenue {
          customers
          revenue
        }
      }
    }
  }
`;

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'no-cache' };
};

export const Loading = () => <></>;

export const Empty = () => <></>;

export const Failure = () => <></>;

export const Success = (props) => {
  const {
    getFunnelById: { funnelItems },
    rowIndex,
  } = props;

  const {
    tableRows: [tableRows = [], setTableRows],
  } = useGetTableRows();

  const {
    defaultCurrency: { threeLetters: defaultCurrencyThreeLetters },
    stripeIntegration: { isReady: isUserWithStripe },
  } = useCurrentUser();

  useEffect(() => {
    const clonedRows = tableRows.slice();
    const clonedQueriedRow = clonedRows[rowIndex];

    const handleEmptyFunnel = () => {
      const queriedRowWithMetrics = {
        ...clonedQueriedRow,
        numberOfLeads: 0,
        numberOfCustomers: 0,
        revenue: isUserWithStripe
          ? getAmountWithCurrencySymbol({ currency: defaultCurrencyThreeLetters, amount: 0 })
          : '-',
        isLoading: false,
      };

      const isRetrievedDataEqual = isEqual(clonedQueriedRow, queriedRowWithMetrics);

      if (!isRetrievedDataEqual) {
        clonedRows.splice(rowIndex, 1, queriedRowWithMetrics);

        setTableRows(clonedRows);
      }
    };

    const handleStripeUsers = ({ lastFunnelPart }) => {
      const { customersAndRevenue, people } = lastFunnelPart;

      const getRevenue = () => {
        return getAmountWithCurrencySymbol({
          currency: defaultCurrencyThreeLetters,
          amount: customersAndRevenue.revenue / 100,
        });
      };

      const numberOfCustomers = customersAndRevenue
        ? setThousandsComma(customersAndRevenue.customers)
        : setThousandsComma(people);

      const revenue =
        customersAndRevenue && isUserWithStripe
          ? getRevenue()
          : getAmountWithCurrencySymbol({ currency: defaultCurrencyThreeLetters, amount: 0 });

      return {
        numberOfCustomers,
        revenue,
      };
    };

    const handleAcOnlyUsers = ({ lastFunnelPart }) => {
      const isWithBofu = lastFunnelPart.funnelPartInString === 'bottom';

      const numberOfCustomers = isWithBofu ? setThousandsComma(lastFunnelPart.people) : 0;

      return {
        numberOfCustomers,
        revenue: '-',
      };
    };

    const isFunnelWithDataDefined = !!funnelItems.length;

    if (!isFunnelWithDataDefined) {
      handleEmptyFunnel();

      return;
    }

    const lastFunnelPart = funnelItems[funnelItems.length - 1];

    const numberOfLeads = setThousandsComma(funnelItems[0]?.people);

    const { numberOfCustomers, revenue } = isUserWithStripe
      ? handleStripeUsers({ lastFunnelPart })
      : handleAcOnlyUsers({ lastFunnelPart });

    const queriedRowWithMetrics = {
      ...clonedQueriedRow,
      numberOfLeads,
      numberOfCustomers,
      revenue,
      isLoading: false,
    };

    const isRetrievedDataEqual = isEqual(clonedQueriedRow, queriedRowWithMetrics);

    if (!isRetrievedDataEqual) {
      clonedRows.splice(rowIndex, 1, queriedRowWithMetrics);

      setTableRows(clonedRows);
    }
  }, [funnelItems, rowIndex, setTableRows, tableRows, defaultCurrencyThreeLetters, isUserWithStripe]);

  return <></>;
};

const propTypes = {
  getFunnelById: PropTypes.shape({
    funnelItems: PropTypes.arrayOf(
      PropTypes.shape({
        funnelPartInString: PropTypes.string,
        people: PropTypes.number,
        customersAndRevenue: PropTypes.shape({
          customers: PropTypes.number,
          revenue: PropTypes.number,
        }),
      })
    ),
  }),
  tableRows: PropTypes.array,
  setTableRows: PropTypes.func,
  rowIndex: PropTypes.number,
};

Success.propTypes = {
  ...propTypes,
};
