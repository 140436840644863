import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@redwoodjs/web';

import * as Popover from '@radix-ui/react-popover';
import { CREATE_FUNNEL_IN_DB, DELETE_FUNNEL_ITEM_IN_LIST } from 'src/components/Funnel/funnelQueriesAndMutations';
import useCurrentUser from 'src/customHooks/useCurrentUser';
import { useGetTableRows } from 'src/components/Funnel/FunnelManagementList/funnelListHooks';

import { DotsHorizontalSvg, CopySvg, BinSvg, PencilSvg } from 'src/components/Generic';

export default function FunnelListMutationsPopover({
  funnelId,
  funnelName,
  funnelItemsToDb,
  funnelIdxInList,
  navigateToFunnel,
}) {
  const {
    tableRows: [tableRows = [], setTableRows],
  } = useGetTableRows();

  const [isLoadingDuplication, setIsLoadingDuplication] = useState(false);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);

  const [duplicateFunnel, { data: { createNewFunnelReport: duplicateFunnelReport } = {} }] =
    useMutation(CREATE_FUNNEL_IN_DB);

  const onDuplicationBtnClick = (ev) => {
    ev.stopPropagation();

    const newFunnelName = `${funnelName} copy`;
    const newReport = { funnelName: newFunnelName, funnelItemsToDb };

    duplicateFunnel({ variables: newReport });

    const clonedRows = tableRows.slice();
    const originalRowBeingDuplicated = tableRows[funnelIdxInList];
    const duplicatedRowPosition = funnelIdxInList + 1;
    const duplicatedRow = {
      ...originalRowBeingDuplicated,
      id: newFunnelName,
      name: newFunnelName,
      isDuplicatingLoading: true,
      isDuplicated: true,
    };

    clonedRows.splice(duplicatedRowPosition, 0, duplicatedRow);

    setIsLoadingDuplication(true);
    setTableRows(clonedRows);
  };

  useEffect(() => {
    const updateDuplicatedRowWithDbResponse = () => {
      const { id, modifiedAt } = duplicateFunnelReport || {};

      const clonedRows = tableRows.slice();
      const duplicatedRowPosition = funnelIdxInList + 1;
      const loadingDuplication = tableRows[duplicatedRowPosition];
      const duplicatedRow = { ...loadingDuplication, isDuplicatingLoading: false, id, modifiedAt };

      clonedRows.splice(duplicatedRowPosition, 1, duplicatedRow);

      setIsLoadingDuplication(false);
      setTableRows(clonedRows);
    };

    if (isLoadingDuplication && duplicateFunnelReport) updateDuplicatedRowWithDbResponse();
  }, [duplicateFunnelReport, funnelIdxInList, isLoadingDuplication, setTableRows, tableRows]);

  const MutationsButtons = () => {
    const deleteConfirmationProps = {
      funnelId,
      funnelIdxInList,
      setIsDeleteClicked,
    };

    const onDeleteClick = (ev) => {
      ev.preventDefault();
      ev.stopPropagation();

      setIsDeleteClicked(true);
    };

    return !isDeleteClicked ? (
      <>
        <Popover.Close className="hoverStrokeSecondary" onClick={onDuplicationBtnClick}>
          <CopySvg pathClassName="stroke-gray" svgClassName="text-wmxBgDark-200" />
        </Popover.Close>
        <Popover.Close className="hoverStrokeSecondary" onClick={navigateToFunnel}>
          <PencilSvg pathClassName="stroke-gray" svgClassName="text-wmxBgDark-200" />
        </Popover.Close>
        <Popover.Close className="hoverStrokeAlert" onClick={onDeleteClick}>
          <BinButton />
        </Popover.Close>
      </>
    ) : (
      <DeleteConfirmationButton {...deleteConfirmationProps} />
    );
  };

  return (
    <Popover.Root delayDuration={100}>
      <Popover.Trigger className="text-xl" onClick={(ev) => ev.stopPropagation()}>
        <DotsHorizontalSvg svgClassName="text-gray-200" />
      </Popover.Trigger>
      <Popover.Content onInteractOutside={() => setIsDeleteClicked(false)}>
        <div className="bg-wmxBgDark-200 rounded-lg px-2 pt-1">
          <MutationsButtons />
        </div>
        <Popover.Arrow className="fill-current text-wmxBgDark-200" />
      </Popover.Content>
    </Popover.Root>
  );
}

FunnelListMutationsPopover.propTypes = {
  funnelId: PropTypes.string,
  funnelName: PropTypes.string,
  funnelItemsToDb: PropTypes.array,
  funnelIdxInList: PropTypes.number,
  navigateToFunnel: PropTypes.func,
};

function DeleteConfirmationButton({ funnelId, funnelIdxInList, setIsDeleteClicked }) {
  const {
    userFunnels: { setHasAtLeastOneCreatedFunnel },
  } = useCurrentUser();

  const {
    tableRows: [tableRows = [], setTableRows],
    deletedRows: [deletedTableRows, setDeletedTableRows],
  } = useGetTableRows();

  const [deleteFunnel] = useMutation(DELETE_FUNNEL_ITEM_IN_LIST);

  const onDeleteBtnClick = (ev) => {
    ev.stopPropagation();

    const deleteVariables = { id: funnelId };
    deleteFunnel({ variables: deleteVariables });

    const clonedRows = tableRows.slice();
    const deletedRow = tableRows[funnelIdxInList];

    clonedRows.splice(funnelIdxInList, 1);

    setIsDeleteClicked(false);
    setTableRows(clonedRows);
    setDeletedTableRows([...deletedTableRows, deletedRow]);

    if (clonedRows.length === 0) {
      setHasAtLeastOneCreatedFunnel(false);
    }
  };

  return (
    <Popover.Close className="hoverStrokeAlert flex items-center pb-1 pl-1" onClick={onDeleteBtnClick}>
      <span className="text-white pr-2">Confirm</span>
      <BinButton />
    </Popover.Close>
  );
}

DeleteConfirmationButton.propTypes = {
  funnelIdxInList: PropTypes.number,
  funnelId: PropTypes.string,
  setIsDeleteClicked: PropTypes.func,
};

const BinButton = () => <BinSvg pathClassName="stroke-gray" svgClassName="text-wmxBgDark-200" />;
