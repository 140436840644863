/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { funnelItems } from 'src/atoms/funnelAtoms';
import useCurrentUser from 'src/customHooks/useCurrentUser';

import { getFunnelByIdProps } from 'src/components/Cells/getFunnelByIdCell/GetFunnelByIdCell';
import FunnelPresentationPart from 'src/components/Funnel/PresentationView/FunnelPresentationPart/FunnelPresentationPart';

import { capitalize, getLastTwClassNameValue } from 'src/lib/generic/handlers';
import { useFunnelPresentationView } from 'src/components/Funnel/PresentationView/presentationViewHooks';
import { getItemTitleWithRoute } from 'src/components/Funnel/FunnelTrackingPane/funnelTrackingHooks';
import {
  removeDuplicatedSquaresOfLeads,
  presentationTableTwClasses,
} from 'src/components/Funnel/PresentationView/presentationViewHelpers';
import { Flex, HelperText, HSpacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';

export default function FunnelPresentationTable({ funnelId, funnelItemsFromDb, dataFromInitialFetch }) {
  const { funnelPresentationRows } = usePresentationRows({
    funnelId,
    funnelItemsFromDb,
    dataFromInitialFetch,
  });

  const { totalColumnsWidth, metricsColumnStyles, metricHeaderLeftPadding } = usePresentationStyling();

  return (
    <>
      <Flex col justify="start">
        <Flex row>
          <span style={{ marginLeft: metricHeaderLeftPadding }} className={`${metricsColumnStyles.width} py-0`}>
            <HelperText secondary={false}>Steps</HelperText>
          </span>
          <span className={`${totalColumnsWidth} text-center ${metricsColumnStyles.padding} py-0`}>
            <HelperText secondary={false}>Total</HelperText>
          </span>
        </Flex>

        <div className="mt-0">{funnelPresentationRows}</div>
      </Flex>
      <HSpacer size="md" />
    </>
  );
}

function usePresentationStyling() {
  const { metricsColumnStyles, rotatedWordsWidth, totalColumnsWidth, funnelRowSeparationToBorder } =
    presentationTableTwClasses;

  const metricColumnPaddingNum = getLastTwClassNameValue(metricsColumnStyles.padding);
  const rotatedWordsWidthNum = getLastTwClassNameValue(rotatedWordsWidth);
  const funnelRowSeparationToBorderNum = getLastTwClassNameValue(funnelRowSeparationToBorder);
  const metricHeaderLeftPadding = (metricColumnPaddingNum + rotatedWordsWidthNum + funnelRowSeparationToBorderNum) * 4;

  return {
    totalColumnsWidth,
    metricsColumnStyles,
    metricHeaderLeftPadding,
  };
}

function usePresentationRows({ funnelId, dataFromInitialFetch, funnelItemsFromDb }) {
  const {
    stripeIntegration: { isReady: isUserWithStripe },
  } = useCurrentUser();

  const {
    funnelItemsTop: { service: topFunnelService, squaresInfo: topInfoSquares },
    funnelItemsBottom: { service: bottomFunnelService, partialItems: bottomFunnelItems },
    totals: { squaresInfo: globalSquaresInfo },
  } = useRecoilValue(funnelItems);
  const { getSquareNameFromFunnelItems } = useFunnelPresentationView();

  const { isInitialFetch } = dataFromInitialFetch;

  const squaresWithoutDuplLeads = removeDuplicatedSquaresOfLeads({ squaresArray: globalSquaresInfo });

  const getSettingsForEachRow = ({
    accum,
    squareIndexInService,
    squareGlobalIdx = null,
    funnelItemName = 'Automation',
    funnelPartIdxOfCurrSquare,
    funnelItemAccessor,
    squarePartIndex,
    isLeadDefinedInTop,
    funnelItemTitle,
    funnelItemsToFetch,
    funnelItemNameInService,
    topSquaresLength,
    topServiceItemsLength,
    bottomServiceItemsLength,
  }) => {
    // When frontendPurchase is inside the OR group of leads
    if (isLeadDefinedInTop && funnelItemAccessor === 'frontEndPurchase' && squareIndexInService === 0) return accum;

    const showAutomationMetric = () => {
      const automationSegmentBuilderItems = bottomFunnelService[squareIndexInService][0]?.automation?.ids.flat(1);

      return automationSegmentBuilderItems.length === 1 && funnelItemName ? `${funnelItemName}` : 'Automations';
    };

    const isBotFunnelDefinedWithStripe = funnelPartIdxOfCurrSquare === 2 && funnelItemAccessor === 'stripeProduct';
    const isMoreThanOneProduct = bottomServiceItemsLength > 1;

    const onlyCustomers = {
      metric: isMoreThanOneProduct ? `Product ${squarePartIndex + 1}` : `Customers`,
      funnelPart: 'bottom',
      ...(isMoreThanOneProduct && { metricHighlight: 'Customers' }),
    };

    const withRevenue = {
      funnelPart: 'bottom',
      typeOfService: 'revenue',
      metrics: [
        onlyCustomers,
        {
          metric: isMoreThanOneProduct ? `Product ${squarePartIndex + 1}` : `Revenue`,
          ...(isMoreThanOneProduct && { metricHighlight: 'Revenue' }),
        },
      ],
    };

    const isFrontendPurchaseMetric =
      (!isLeadDefinedInTop && topServiceItemsLength === 1) ||
      (topServiceItemsLength === 2 && squarePartIndex === topSquaresLength - 1);

    const rowsSettings = [
      {
        metric: isFrontendPurchaseMetric ? 'Frontend purchases' : 'Leads',
        funnelPart: 'top',
      },
      {
        metric: funnelItemAccessor === 'automation' && !isInitialFetch ? showAutomationMetric() : `${funnelItemTitle}s`,
        ...(funnelItemAccessor === 'automation' && { typeOfService: 'automation', numberHighlight: 'opens' }),
        funnelPart: 'middle',
      },
      isBotFunnelDefinedWithStripe ? withRevenue : onlyCustomers,
    ];

    const settingsForCurrentSquare = {
      ...rowsSettings[funnelPartIdxOfCurrSquare],
      funnelItemsToFetch,
      squareIndexOnFunnel: squareIndexInService,
      funnelItemNameInService,
      squareGlobalIdx,
    };

    const currentFunnelPart = accum[funnelPartIdxOfCurrSquare] || [];
    currentFunnelPart.push(settingsForCurrentSquare);

    // eslint-disable-next-line no-param-reassign
    accum[funnelPartIdxOfCurrSquare] = currentFunnelPart;
    return accum;
  };

  const getSettingsForEachRowWhenFunnelIsFetchedById = () => {
    const getItemsFromFunnelPart = (funnelPartInString) => {
      return funnelItemsFromDb.filter((funnelPart) =>
        funnelPart.some(({ path: route }) => route[0] === funnelPartInString)
      );
    };

    let squarePartIndex = 0;

    return funnelItemsFromDb.reduce((accum, fetchedFunnelPart, squareIndexInService) => {
      const firstItemIn_OR_group = fetchedFunnelPart[0];
      const { path, query } = firstItemIn_OR_group;

      const [funnelPartOfCurrSquare, funnelItemAccessor] = path;
      const funnelItemNameInService = Object.keys(query)[0];

      const topFunnelItems = getItemsFromFunnelPart('top');
      const bottomPartialItems = getItemsFromFunnelPart('bottom');

      const topSquaresLength = topFunnelItems.reduce((acc, topItem) => acc + topItem.length, 0);

      const isLeadDefinedInTop = funnelItemsFromDb
        .map((funnelPart) => funnelPart.some(({ path: route }) => route[1] === 'newLead'))
        .includes(true);

      const pathToGetFunnelItemTitle = path.slice(0, 2);
      const funnelItemTitle = getItemTitleWithRoute({ isUserWithStripe, pathArray: pathToGetFunnelItemTitle });

      getSettingsForEachRow({
        accum,
        squareIndexInService,
        funnelPartIdxOfCurrSquare: funnelPartOfCurrSquare === 'top' ? 0 : funnelPartOfCurrSquare === 'middle' ? 1 : 2,
        funnelItemAccessor,
        squarePartIndex,
        isLeadDefinedInTop,
        funnelItemName: null,
        funnelItemTitle,
        funnelItemsToFetch: null,
        funnelItemNameInService,
        bottomServiceItemsLength: bottomPartialItems.length,
        topServiceItemsLength: topFunnelItems.length,
        topSquaresLength,
      });

      const funnelPartOfNextSquare =
        squareIndexInService === funnelItemsFromDb.length - 1
          ? 'bottom'
          : funnelItemsFromDb[squareIndexInService + 1][0].path[0];

      squarePartIndex =
        funnelPartOfCurrSquare !== funnelPartOfNextSquare ? 0 : squarePartIndex + fetchedFunnelPart.length;

      return accum;
    }, []);
  };

  const getSettingsForEachRowWhenIsNotFetchedById = () => {
    return squaresWithoutDuplLeads.reduce((accum, squareInfo) => {
      const {
        squareIndexInService,
        squareName,
        squarePartIndex,
        funnelItemNameInService,
        funnelPartIdxOfCurrSquare,
        squareGlobalIdx,
      } = squareInfo;
      const { name: funnelItemTitle, accessor: funnelItemAccessor } = squareName;

      const isLeadDefinedInTop = topInfoSquares.some(({ squareName: sqName }) => sqName?.accessor === 'newLead');

      const funnelItemsToFetch = bottomFunnelService.slice(0, squareIndexInService + 1);

      const { funnelItemName } = getSquareNameFromFunnelItems({
        funnelSquareIdxInCurrPart: squarePartIndex,
        funnelPartIdxOfCurrSquare,
      });

      getSettingsForEachRow({
        accum,
        squareIndexInService,
        squareGlobalIdx,
        funnelPartIdxOfCurrSquare,
        funnelItemAccessor,
        squarePartIndex,
        isLeadDefinedInTop,
        funnelItemName,
        funnelItemTitle,
        funnelItemsToFetch,
        funnelItemNameInService,
        totalServiceItemsLength: bottomFunnelItems.length,
        topServiceItemsLength: topFunnelService.length,
        topSquaresLength: topInfoSquares.length,
      });

      return accum;
    }, []);
  };

  const settingsForEachRow =
    funnelId && isInitialFetch
      ? getSettingsForEachRowWhenFunnelIsFetchedById()
      : getSettingsForEachRowWhenIsNotFetchedById();

  const settingsForEachRowFiltered = settingsForEachRow.filter((item) => item && item.length > 0);
  const funnelPresentationRows = settingsForEachRowFiltered.map((funnelPartSettings, i) => {
    const funnelPartName = capitalize(funnelPartSettings[0].funnelPart);
    const isLast = i === settingsForEachRowFiltered.length - 1;

    return (
      <>
        <RowConnectorWrap>
          {i > 0 && <ConnectorTop />}
          <FunnelPresentationPart
            key={funnelPartName}
            rows={funnelPartSettings}
            funnelPartName={funnelPartName}
            dataFromInitialFetch={dataFromInitialFetch}
          />
          {!isLast && <ConnectorBottom />}
        </RowConnectorWrap>
      </>
    );
  });

  return {
    funnelPresentationRows,
  };
}

export function RowConnectorWrap({ children }) {
  return <div className="relative">{children}</div>;
}

RowConnectorWrap.propTypes = {
  children: PropTypes.any,
};

export function ConnectorBottom() {
  return (
    <>
      <span className="absolute left-16 -ml-1 -bottom-16 h-16 w-8 border-2 border-r-1 border-l-0  border-b-0 border-t-0 border-wmxPrimary-200 opacity-100 rounded-xl rounded-b-none rounded-r-none z-0" />
      <span className="absolute left-24 -ml-2 bottom-0 h-1 w-1 transform scale-125 translate-x-0.5 rounded-full bg-wmxPrimary-50 z-50" />
    </>
  );
}

export function ConnectorTop() {
  return (
    <>
      <span className="absolute left-16 -ml-1 -top-16 h-16 w-8 border-2 border-r-1 border-l-0  border-b-0 border-t-0 border-wmxPrimary-200 opacity-100 rounded-xl rounded-b-none rounded-r-none z-0" />
      <span className="absolute left-24 -ml-2 top-0 h-1 w-1 transform scale-125 translate-x-0.5 rounded-full bg-wmxPrimary-50 z-50" />
    </>
  );
}

FunnelPresentationTable.propTypes = {
  funnelId: PropTypes.string,
  funnelItemsFromDb: PropTypes.array,
  dataFromInitialFetch: PropTypes.shape({
    initialFetchedData: PropTypes.shape({
      ...getFunnelByIdProps,
    }),
    isInitialFetch: PropTypes.bool,
    setInitialFetch: PropTypes.func,
  }),
};
