import PropTypes from 'prop-types';
import { useIntegrationsContext, useIntegrationsBeacon } from 'src/contexts/Integrations';
import { Button, LinkArrow, CircleCheckMark, CircleXMark } from 'src/components/Generic';
import { setThousandsComma, formatReportsTimestamp } from 'src/lib/generic/handlers';
import MetricSkeleton from 'src/components/Generic/Skeletons/MetricSkeleton/MetricSkeleton';
import { emptyProviderSyncString } from 'src/components/Cells/LastProviderSyncCell/LastProvidersSyncCell';

export default function VendorOverviewDetails() {
  const { settingsByClickedProvider } = useIntegrationsContext();
  const { handleFailedResync } = useIntegrationsBeacon();

  const {
    url,
    dashboardHref,
    lastSuccessfulAPISync,
    lastSuccessfulWebhookSync,
    datapointTotals,
    wasDailyResyncSuccessful,
  } = settingsByClickedProvider;

  const formatIntegrationTimeValues = (value) => formatReportsTimestamp(value, 'DD MMM YY - HH:mm:ss');

  const lastAPISyncValue =
    lastSuccessfulAPISync && lastSuccessfulAPISync !== emptyProviderSyncString
      ? formatIntegrationTimeValues(lastSuccessfulAPISync)
      : emptyProviderSyncString;

  const lastRealTimeUpdate =
    lastSuccessfulWebhookSync && lastSuccessfulWebhookSync !== emptyProviderSyncString
      ? formatIntegrationTimeValues(lastSuccessfulWebhookSync)
      : emptyProviderSyncString;

  const totalDatapointsTracked = datapointTotals ? setThousandsComma(datapointTotals) : 0;

  const successfulDailyResync = () => (
    <div className="flex flex-row">
      <CircleCheckMark svgClassName="text-green-400" height={20} width={20} />
      <span className="text-white ml-2 text-sm">All good</span>
    </div>
  );
  const failedDailyResync = () => (
    <div className="flex flex-row">
      <CircleXMark svgClassName="text-wmxAlert-200" height={20} width={20} />
      <span className="text-white ml-2 text-sm">
        We found data inconsistency. Please resync your data or&nbsp;
        <button type="button" className="underline" onClick={handleFailedResync}>
          contact support
        </button>
        .
      </span>
    </div>
  );

  const itemsConfig = [
    {
      title: 'Account url',
      value: url,
      link: { href: dashboardHref },
    },
    {
      title: 'Last API sync',
      value: lastAPISyncValue,
      isLoading: !lastSuccessfulAPISync,
    },
    {
      title: 'Last real time update',
      value: lastRealTimeUpdate,
      isLoading: !lastSuccessfulWebhookSync,
    },
    {
      title: 'Total datapoints tracked',
      value: totalDatapointsTracked,
      isLoading: !datapointTotals,
    },
    {
      title: 'Data correctness',
      renderElement: wasDailyResyncSuccessful ? successfulDailyResync : failedDailyResync,
      isLoading: !datapointTotals,
    },
  ];

  const items = itemsConfig.map((props, idx) => {
    const isLastItem = idx === itemsConfig.length - 1;
    // eslint-disable-next-line react/prop-types
    return <Item key={props.title} {...props} separationWithNextOne={!isLastItem} />;
  });

  return <div className="bg-wmxBgDark-100 px-4 my-10 pb-4 pt-2 rounded-lg">{items}</div>;
}

function ActionLink({ children, href }) {
  return (
    <div className="flex flex-row group">
      <a className="text-white hover:underline" href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
      <LinkArrow visibility={{ always: true }} />
    </div>
  );
}

function ActionButton({ onClick, title, href }) {
  return (
    <a className="flex justify-end w-full mr-2" href={href} target="_blank" rel="noreferrer">
      <Button variant="primary" onClick={onClick} styles={{ isTextUpperCase: false, isLinkArrow: true }}>
        {title}
      </Button>
    </a>
  );
}

function Item({ title, value, link, button = {}, renderElement, separationWithNextOne, isLoading }) {
  const isButton = !!Object.keys(button).length;
  const marginSeparation = separationWithNextOne ? 'mb-4' : '';

  const LoadingMetric = () => (
    <MetricSkeleton width={80} height={20} foregroundColor="#1C2631" backgroundColor="#304254" />
  );

  const spanElement = renderElement ? renderElement() : <span className="text-white text-sm">{value}</span>;

  return (
    <div className={marginSeparation}>
      <span className="text-xs text-gray-400">{title}</span>
      <div className="flex flex-row items-center">
        {isLoading ? (
          <LoadingMetric />
        ) : (
          <>
            <div className="w-full">{link?.href ? <ActionLink {...link}>{spanElement}</ActionLink> : spanElement}</div>
            {isButton && <ActionButton {...button} />}
          </>
        )}
      </div>
    </div>
  );
}

ActionButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  href: PropTypes.string,
};

Item.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  renderElement: PropTypes.func,
  link: PropTypes.shape({
    href: PropTypes.string,
  }),
  button: PropTypes.shape({
    title: PropTypes.string,
    onClick: PropTypes.func,
  }),
  separationWithNextOne: PropTypes.bool,
  isLoading: PropTypes.bool,
};

ActionLink.propTypes = {
  children: PropTypes.any,
  href: PropTypes.string,
};
