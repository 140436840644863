import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';
import useOrderBy from 'src/components/Analyze/Overview/hooks/useOrderBy';
import { useOverviewReportsContext } from 'src/components/Analyze/Overview/hooks/overviewProvider';

import LeadsTable from 'src/components/Analyze/DetailModal/Tables/LeadsTable/LeadsTable';
import CustomersTable, {
  CustomersTable as CustomersTableWithouthHOC,
} from 'src/components/Analyze/DetailModal/Tables/CustomersTable/CustomersTable';
import CellLayout from 'src/components/Analyze/DetailModal/Tables/CellLayout/CellLayout';
import { RowSkeletons } from 'src/components/Generic/Skeletons';
import { getEmailTableProps } from 'src/components/Analyze/Overview/OverviewTable/tableSettings';
import { getEmailsOverviewOrderByDefaults as getOrderByDefaults } from 'src/components/Analyze/Overview/OverviewTable/tableSettingsHelpers';

import OverviewTableWithHOC, {
  OverviewTable as OverviewTableWithoutHOC,
} from '../Overview/OverviewTable/OverviewTable';

const RENDER_EACH_SCROLL_FOR_TABLE = 60;

export default function DetailModal({
  type,
  onCellClick,
  queryVariables,
  convWindow,
  dataKey,
  initialQuery,
  fetchMoreQuery,
  onlyTypes,
  navigateBack,
  clickAnalyzePath,
  onRightPaneClosing,
}) {
  const {
    rightPane: { rightPaneCurrentData, isRightPaneFetchingData, setIsRightPaneFetchingData },
  } = useOverviewReportsContext();

  const { timeRange } = useRecoilValue(RecoilTimeRange);
  const renderDefaultCell = ({ renderedCell }) => <CellLayout>{renderedCell}</CellLayout>;

  const { columnOrderBy, columnOrderByDirection, onOrderBy } = useOrderBy({
    typeOfReport: type,
    getOrderByDefaults,
    onClick: () => setIsRightPaneFetchingData(true),
  });

  const tableGeneralClassNames = {
    container:
      'overviewTable align-middle block max-w-full bg-transparent rounded-bl-lg rounded-br-lg mt-0 mr-1 flex-grow px-4 pb-16',
    table: 'w-full',
    body: {
      tbody: 'bg-wmxBgDark-100 overflow-auto bg-wmxBgDark-400',
      bodyCell:
        'h-10 text-left pl-6 text-white my-1 py-3 text-sm p-4 pb-4 first:rounded-l-md last:rounded-r-md max-w-58 pr-6  last:pr-4  last:pl-0',
      bodyRow: `z-0 hover:bg-wmxBgDark-300 hover:bg-opacity-70 bg-wmxHighlightDark-100 hover:bg-wmxHighlightDark-200 my-2 rounded-md shadow-2xl h-18`,
    },
    header: {
      headerCell:
        'z-10 text-left h-12 pl-6 p-2 border-t-0 leading-4 text-white tracking-wider bg-wmxBgDark-200 text-xs font-normal',
    },
  };

  const navigationProps = { navigateBack, onlyTypes, clickAnalyzePath, isRightPaneFetchingData };

  const tablesProps = {
    ...navigationProps,
    onRightPaneClosing,
    classNames: tableGeneralClassNames,
    initialQuery,
    fetchMoreQuery,
    dataKey,
    onOrderBy,
    LoadingComponent: () => <LoadingComponent />,
    queryParams: {
      fetchPolicy: 'cache-first',
      variables: {
        offset: 0,
        limit: RENDER_EACH_SCROLL_FOR_TABLE,
        timeRange,
        convWindow,
        orderBy: columnOrderBy,
        orderByDirection: columnOrderByDirection,
        ...queryVariables,
      },
    },
  };

  const customersAndLeadsTableProps = {
    ...tablesProps,
    renderDefaultCell,
    scrollLength: RENDER_EACH_SCROLL_FOR_TABLE,
  };

  const emailsTableProps = {
    ...tablesProps,
    activeTable: 'emails',
    onCellClick,
    isInsideModal: true,
    getTableProps: getEmailTableProps,
  };

  const tablePerType = {
    customers: <CustomersTable {...customersAndLeadsTableProps} />,
    opens: <LeadsTable {...customersAndLeadsTableProps} />,
    emails: <OverviewTableWithHOC {...emailsTableProps} />,
  };

  const loadingTablePerType = {
    customers: (
      <CustomersTableWithouthHOC {...customersAndLeadsTableProps} data={rightPaneCurrentData} isLoadingTable />
    ),
    emails: <OverviewTableWithoutHOC {...emailsTableProps} data={rightPaneCurrentData} isLoadingRightPaneTable />,
  };

  // eslint-disable-next-line react/prop-types
  const TableContainer = ({ children }) => <div className="h-full w-full container">{children}</div>;

  function LoadingComponent() {
    const loadingTable = loadingTablePerType[type];

    return isRightPaneFetchingData && loadingTable ? (
      <TableContainer>{loadingTable}</TableContainer>
    ) : (
      <RowSkeletons rowsAmount={20} height={64} width="100%" foregroundColor="#1C2631" backgroundColor="#304254" />
    );
  }

  return <TableContainer>{tablePerType[type]}</TableContainer>;
}

DetailModal.propTypes = {
  clickAnalyzePath: PropTypes.func,
  convWindow: PropTypes.number,
  dataKey: PropTypes.string,
  fetchMoreQuery: PropTypes.object,
  initialQuery: PropTypes.object,
  isOpen: PropTypes.bool,
  navigateBack: PropTypes.any,
  onCellClick: PropTypes.func,
  onModalClose: PropTypes.func,
  onRightPaneClosing: PropTypes.any,
  onlyTypes: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  }),
  queryVariables: PropTypes.shape({
    automationId: PropTypes.string,
    campaignId: PropTypes.string,
  }),
  type: PropTypes.oneOf(['customers', 'leads', 'opens', 'emails']),
};
