/* global gql */
import PropTypes from 'prop-types';
import { EmptyComponent, LoadingComponent, Error } from 'src/components/Generic';
import { setThousandsComma } from 'src/lib/generic/handlers';

export const QUERY = gql`
  query GET_FUNNEL_COMBINED_LEADS($funnelItems: [[FunnelItemsInput!]]!, $timeRange: [TimeRangeInput]) {
    getCombinedLeadsCount(funnelItems: $funnelItems, timeRange: $timeRange)
  }
`;

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'cache-first' };
};

export const Loading = () => {
  return <LoadingComponent />;
};

export const Empty = () => <EmptyComponent />;

export const Failure = ({ error }) => <Error errorMessage={error.message} />;

export const Success = ({ getCombinedLeadsCount, squareIdx, funnelPart, funnelItems, name }) => {
  const countWithoutFormat =
    squareIdx !== '' && squareIdx !== null && typeof squareIdx !== 'undefined'
      ? getCombinedLeadsCount[0][squareIdx]
      : getCombinedLeadsCount[0][0];
  const count = setThousandsComma(countWithoutFormat);

  return <span>{count}</span>;
};

Success.propTypes = {
  getCombinedLeadsCount: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  squareIdx: PropTypes.number,
};
