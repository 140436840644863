import { Redirect, routes } from '@redwoodjs/router';

import { errors, SHARED_SETTINGS_BY_DATAPOINT } from 'wmx-shared-code/datapointsRecords/datapointsRecords';

import useOrderBy from 'src/components/Analyze/Overview/hooks/useOrderBy';

import { useIntegrationsContext } from 'src/contexts/Integrations';

import { Error, EmptyComponent } from 'src/components/Generic';
import { RowSkeletons } from 'src/components/Generic/Skeletons';

import { useTriggerPageSeenEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';
import { PROVIDERS } from 'wmx-shared-code/accountGlobalVariables';

import DatapointRecordsTable from './DatapointRecordsTable';
import { GET_DATAPOINT_RECORDS } from './datapointRecordsQueries';

const RENDER_EACH_SCROLL_FOR_TABLE = 60;

export default function DatapointRecordsContainer() {
  const {
    currentProviderShown,
    currentDatapointRecordsNames: selectedDatapoint,
    searchBoxKeyword,
  } = useIntegrationsContext();
  const { URLName: URLDatapointName } = selectedDatapoint || {};

  const { initialOrderBy, initialOrderDirection } = getInitialOrderBy({ URLDatapointName });
  const { columnOrderBy, columnOrderByDirection, onOrderBy } = useOrderBy({ initialOrderBy, initialOrderDirection });

  useTriggerPageSeenEvent({
    pageName: SEGMENT_TRACK_EVENTS_NAMES.acDatapointsVisited,
    payload: { datapoint: selectedDatapoint },
    shouldEventBeSent: currentProviderShown === PROVIDERS.activeCampaign,
  });

  const tableProps = {
    dataKey: GET_DATAPOINT_RECORDS.queryName,
    initialQuery: GET_DATAPOINT_RECORDS.query,
    onOrderBy,
    LoadingComponent: () => (
      <RowSkeletons rowsAmount={20} height={64} width="100%" foregroundColor="#1C2631" backgroundColor="#304254" />
    ),
    EmptyComponent: () => (
      <EmptyComponent
        text="No items that match the current search"
        className={{ text: 'text-wmxText-200', container: 'mt-6' }}
      />
    ),
    // eslint-disable-next-line react/prop-types
    Error: ({ message } = {}) => {
      if (message === errors.notSupportedDatapointProvided) {
        return <Redirect to={routes.providerSettings({ provider: currentProviderShown })} />;
      }

      return <Error errorMessage={message} />;
    },
    queryParams: {
      fetchPolicy: 'network-only',
      variables: {
        datapoint: URLDatapointName,
        offset: 0,
        limit: RENDER_EACH_SCROLL_FOR_TABLE,
        orderBy: columnOrderBy,
        orderByDirection: columnOrderByDirection,
        searchKeyword: searchBoxKeyword,
      },
    },
  };

  return <DatapointRecordsTable {...tableProps} />;
}

function getInitialOrderBy({ URLDatapointName }) {
  const datapointNames = Object.keys(SHARED_SETTINGS_BY_DATAPOINT);

  const datapointName = datapointNames.find((name) => {
    return SHARED_SETTINGS_BY_DATAPOINT[name].URLName === URLDatapointName;
  });

  const initialValues = {
    initialOrderBy: datapointName ? SHARED_SETTINGS_BY_DATAPOINT[datapointName].defaultOrderBy.column : null,
    initialOrderDirection: datapointName ? SHARED_SETTINGS_BY_DATAPOINT[datapointName].defaultOrderBy.direction : null,
  };

  return initialValues;
}
