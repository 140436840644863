import PropTypes from 'prop-types';

import { getLastTwClassNameValue, setThousandsComma, formatReportsTimestamp } from 'src/lib/generic/handlers';
import useTableHeight from 'src/customHooks/useTableHeight';
import { useIntegrationsContext } from 'src/contexts/Integrations';
import useOrderBy, { orderByWithJs } from 'src/components/Analyze/Overview/hooks/useOrderBy';

import { Tooltip as GenericTooltip, Button, CircleCheckMark, CircleXMark } from 'src/components/Generic';
import { ORDER_COLUMN_DIRECTIONS } from 'src/components/IntegrationTopic/integrationQueries';
import { getColumnsWithOrderBy as getColumnsOrderBy } from 'src/components/Analyze/Overview/OverviewTable/tableSettingsHelpers';
import ResyncIntegrationButton from 'src/components/IntegrationTopic/ResyncIntegrationButton/ResyncIntegrationButton';
import { navigate, routes } from '@redwoodjs/router';

export default function useVendorOverviewTableProps({ data }) {
  const headers = {
    datapointName: { name: 'Datapoint name', accessor: 'name' },
    count: { name: 'Count', accessor: 'count' },
    lastUpdated: { name: 'Last updated', accessor: 'lastUpdated' },
    status: { name: 'Status', accessor: 'status' },
    buttons: { name: '', accessor: 'buttons' },
  };

  const { settingsByClickedProvider } = useIntegrationsContext();
  const { failedDatapointsInLastDailyResync = [] } = settingsByClickedProvider;

  const containerMarginTop = 'mt-0';
  const twMarginTopNum = getLastTwClassNameValue(containerMarginTop);
  const { mainTableContainerRef } = useTableHeight({ twMarginTopNum });
  const { columnOrderBy, columnOrderByDirection, onOrderBy } = useOrderBy({
    initialOrderBy: headers.lastUpdated.accessor,
    initialOrderDirection: ORDER_COLUMN_DIRECTIONS.desc,
  });

  const stylesToOverwrite = {
    container: {
      marginRight: 0,
    },
    table: {
      paddingRight: 0,
    },
  };

  const classNames = {
    container: `pt-0 ${containerMarginTop}`,
    table: 'w-full spacingTable',
    header: {
      headerCell:
        'z-10 text-left h-12 pl-6 p-2 border-t-0 leading-4 text-white tracking-wider bg-wmxBgDark-200 text-xs font-normal',
      thead: 'text-white',
    },
    body: {
      tbody: 'bg-wmxBgDark-100 overflow-auto bg-wmxBgDark-400',
      bodyCell: 'h-10 text-left text-white my-1 text-sm pr-4 pl-6 pt-3 pb-4 first:rounded-l-md last:rounded-r-md',
      bodyRow: `z-0 hover:bg-wmxBgDark-300 hover:bg-opacity-70 bg-wmxHighlightDark-100 hover:bg-wmxHighlightDark-200 my-2 rounded-md shadow-2xl `,
    },
  };

  const columns = [
    {
      accessor: headers.datapointName.accessor,
      Header: headers.datapointName.name,
      orderBy: headers.datapointName.accessor,
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
    },
    {
      accessor: headers.count.accessor,
      Header: headers.count.name,
      orderBy: headers.count.accessor,
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
    },
    {
      accessor: headers.lastUpdated.accessor,
      Header: headers.lastUpdated.name,
      orderBy: headers.lastUpdated.accessor,
      orderByDefaultDirection: ORDER_COLUMN_DIRECTIONS.desc,
    },
    {
      accessor: headers.status.accessor,
      Header: headers.status.name,
      className: 'text-center',
    },
    {
      accessor: headers.buttons.accessor,
      Header: headers.buttons.name,
    },
  ];

  const extendRows = {
    columns: [
      {
        accessor: headers.datapointName.accessor,
        // eslint-disable-next-line react/prop-types
        cellChildren: ({ renderedCell, row }) => <DatapointColumn datapointName={renderedCell} row={row} />,
      },
      {
        accessor: headers.status.accessor,
        // eslint-disable-next-line react/prop-types
        cellChildren: ({ row }) => {
          const { original: { resyncName } = {} } = row || {};

          const isFailedDatapoint = !failedDatapointsInLastDailyResync.includes(resyncName);

          const successfulDailyResync = () => <CircleCheckMark svgClassName="text-green-400" height={20} width={20} />;
          const failedDailyResync = () => <CircleXMark svgClassName="text-wmxAlert-200" height={20} width={20} />;

          const element = isFailedDatapoint ? successfulDailyResync() : failedDailyResync();

          return <div className="flex justify-center items-center">{element}</div>;
        },
      },
      {
        accessor: headers.buttons.accessor,
        // eslint-disable-next-line react/prop-types
        cellChildren: ({ row }) => <ButtonsColumn row={row} headers={headers} />,
      },
    ],
  };

  const columnsWithOrderByConfig = getColumnsOrderBy({ columns, onOrderBy });
  const extendHeader = {
    columns: [...columnsWithOrderByConfig],
  };

  const orderedAndFormattedData = formatAndOrderTableData({
    rows: data,
    headers,
    orderByKey: columnOrderBy,
    selectedDirection: columnOrderByDirection,
  });

  return {
    name: `vendorOverview`,
    data: orderedAndFormattedData,
    columns,
    mainContainerRef: mainTableContainerRef,
    autoInfiniteScrollOverflow: true,
    styles: stylesToOverwrite,
    extendHeader,
    extendRows,
    classNames,
  };
}

function formatAndOrderTableData({ rows, headers, selectedDirection, orderByKey }) {
  const orderedData = orderByWithJs({ direction: selectedDirection, data: rows, orderByKey });

  const formattedData = orderedData.map((row) => {
    const {
      count: { accessor: countAccessor },
      lastUpdated: { accessor: lastUpdatedAccessor },
      status: { accessor: statusAccessor },
      buttons: { accessor: buttonsAccesor },
    } = headers;

    return {
      ...row,
      [lastUpdatedAccessor]: row[lastUpdatedAccessor]
        ? formatReportsTimestamp(row[lastUpdatedAccessor], 'D MMM YY - HH:mm:ss')
        : '-',
      [countAccessor]: setThousandsComma(row[countAccessor]),
      [statusAccessor]: 'Here comes the status sign',
      [buttonsAccesor]: 'Here come the buttons',
    };
  });

  return formattedData;
}

function DatapointColumn({ row, datapointName }) {
  const { original: { description: tooltipText } = {} } = row;

  const tooltipElement = <span>{tooltipText}</span>;
  return (
    <div className="flex flex-row">
      <span>{datapointName}</span>
      <GenericTooltip
        icon="strongInfoBox"
        iconColor="text-wmxText-100"
        iconSize="scale-75"
        styles={{ button: { marginRight: 'mr-2' } }}
        stayOnMessageHover
      >
        {tooltipElement}
      </GenericTooltip>
    </div>
  );
}

DatapointColumn.propTypes = {
  row: PropTypes.object,
  datapointName: PropTypes.object,
};

function ButtonsColumn({ row, headers }) {
  const { currentProviderShown } = useIntegrationsContext();
  const {
    count: { accessor: countAccessor },
  } = headers;

  const isDatapointWithRecords = !!Number.parseInt(row.original?.[countAccessor], 10);

  const urlDatapointName = row.original?.URLName;

  const onOpenDatapointRecordsClick = () => {
    navigate(routes.datapointRecords({ provider: currentProviderShown, datapoint: urlDatapointName }));
  };

  return (
    <div className="flex flex-row justify-end">
      <Button
        variant="primary"
        disabled={!isDatapointWithRecords}
        // eslint-disable-next-line react/prop-types
        onClick={onOpenDatapointRecordsClick}
        className="mr-4"
      >
        Open
      </Button>
      <ResyncIntegrationButton datapointName={row.original?.resyncName} />
    </div>
  );
}

ButtonsColumn.propTypes = {
  row: PropTypes.object,
  headers: PropTypes.object,
};
