/* global gql */
import PropTypes from 'prop-types';
import { useState, createContext, useContext, useMemo } from 'react';
import { routes, navigate } from '@redwoodjs/router';
import useCurrentUser from 'src/customHooks/useCurrentUser';

const { useMutation } = require('@redwoodjs/web');
const { toast } = require('@redwoodjs/web/dist/toast');

const UPDATE_LOGGED_AS_MUTATION = gql`
  mutation UpdateLoggedAsMutation($loggedAsAccountId: String!, $realAccountId: String!) {
    updateLoggedAs(loggedAsAccountId: $loggedAsAccountId, realAccountId: $realAccountId) {
      id
      loggedAs
    }
  }
`;

const useLoggedAsLogic = () => {
  const { loggedInAs: currentLoggedAs, accountId, realAccount, reauthenticate } = useCurrentUser();

  const [loggedAs, setLoggedAs] = useState(currentLoggedAs);

  const realAccountId = useMemo(() => {
    if (realAccount) return realAccount.id;
    return accountId;
  }, [realAccount, accountId]);

  const [updateLoggedAs, { loading, error, data }] = useMutation(UPDATE_LOGGED_AS_MUTATION, {
    onCompleted: () => {
      toast.success(`Log in status changed, reloading...`);
      reauthenticate().then(() => {
        navigate(routes.accounts());
      });
    },
    onError: () => {
      toast.error(`Something fail, retry...`);
    },
  });

  // eslint-disable-next-line no-shadow
  const login = async ({ loggedAsAccountId, realAccountId }) => {
    const result = await updateLoggedAs({ variables: { loggedAsAccountId, realAccountId } });
    setLoggedAs(result?.data?.updateLoggedAs?.loggedAs);
  };

  // eslint-disable-next-line no-shadow
  const logOut = async ({ realAccountId }) => {
    const result = await updateLoggedAs({ variables: { loggedAsAccountId: '', realAccountId } });
    setLoggedAs(result?.data?.updateLoggedAs?.loggedAs);
  };

  return {
    login,
    logOut,
    loading,
    error,
    data,
    loggedAs,
    setLoggedAs,
    accountId,
    realAccount,
    realAccountId,
  };
};

const LoggedAsContext = createContext(null);

export const LoggedAsProvider = ({ children }) => {
  const providerValue = useLoggedAsLogic();
  return <LoggedAsContext.Provider value={{ ...providerValue }}>{children}</LoggedAsContext.Provider>;
};

LoggedAsProvider.propTypes = {
  children: PropTypes.any,
};

export const useLoggedAs = () => useContext(LoggedAsContext);
