import PropTypes from 'prop-types';

import useCurrentUser from 'src/customHooks/useCurrentUser';

import FunnelPresentationRow, {
  presentationRowProps,
} from 'src/components/Funnel/PresentationView/FunnelPresentationRow/FunnelPresentationRow';
import { setThousandsComma } from 'src/lib/generic/handlers';
import { getAmountWithCurrencySymbol } from 'src/lib/generic/currency';

export default function FunnelCustomersAndRevenueMetric({
  customers = null,
  revenue: revenueWithoutFormat = null,
  metrics,
  squareIndexOnFunnel,
  funnelItemNameInService,
  funnelPartName,
}) {
  const {
    defaultCurrency: { threeLetters: defaultCurrencyThreeLetters },
  } = useCurrentUser();

  const customersWithComma = setThousandsComma(customers);
  const revenue = getAmountWithCurrencySymbol({
    amount: revenueWithoutFormat / 100,
    currency: defaultCurrencyThreeLetters,
  });

  const propsForBothRows = {
    squareIndexOnFunnel,
    funnelItemNameInService,
    funnelPartName,
  };

  return (
    <>
      <FunnelPresentationRow
        metric={metrics[0]?.metric}
        metricHighlight={metrics[0]?.metricHighlight}
        classNames={{ container: 'pb-2' }}
        {...propsForBothRows}
      >
        {customers !== null ? customersWithComma : customers}
      </FunnelPresentationRow>
      <FunnelPresentationRow
        metric={metrics[1]?.metric}
        metricHighlight={metrics[1]?.metricHighlight}
        classNames={{ container: 'pb-4' }}
        {...propsForBothRows}
      >
        {revenueWithoutFormat !== null ? revenue : revenueWithoutFormat}
      </FunnelPresentationRow>
    </>
  );
}

FunnelCustomersAndRevenueMetric.propTypes = {
  customers: PropTypes.number,
  revenue: PropTypes.number,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      metric: PropTypes.string,
    })
  ),
  ...presentationRowProps,
};
