/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import React from 'react';
import { funnelItems } from 'src/atoms/funnelAtoms';
import useCurrentUser from 'src/customHooks/useCurrentUser';

import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';
import GetConvRateOfCombinedLeadsCell from 'src/components/Cells/GetConvRateOfCombinedLeadsCell/GetConvRateOfCombinedLeadsCell';
import ConvRateOfCombinedLeads from 'src/components/Funnel/PresentationView/Metrics/ConvRateOfCombinedLeads/ConvRateOfCombinedLeads';
import { getFunnelByIdProps } from 'src/components/Cells/getFunnelByIdCell/GetFunnelByIdCell';
import { useFunnelPresentationView } from 'src/components/Funnel/PresentationView/presentationViewHooks';

import { getItemTitleWithRoute } from 'src/components/Funnel/FunnelTrackingPane/funnelTrackingHooks';
import { capitalize } from 'src/lib/generic/handlers';
import { Flex, HelperText, Line, Spacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { removeDuplicatedSquaresOfLeads } from '../presentationViewHelpers';

const TYPES_OF_KPIS = {
  conversion: 'conversion',
  metric: 'metric',
};

export default function FunnelOverviewKPIs({ funnelId, funnelItemsFromDb, dataFromInitialFetch }) {
  const {
    funnelItemsTop: { service: topFunnelService, squaresInfo: topInfoSquares },
    funnelItemsMiddle: { partialItems: middleFunnelItems },
    funnelItemsBottom: { service: bottomFunnelService, partialItems: bottomFunnelItems },
    totals: { squaresInfo: globalSquaresInfo },
  } = useRecoilValue(funnelItems);

  const {
    stripeIntegration: { isReady: isUserWithStripe },
  } = useCurrentUser();

  const { isInitialFetch } = dataFromInitialFetch;

  const getSettingsForEachKPIBox = ({
    accum,
    reducedArray,
    isLeadDefinedInTop,
    currSquarePartIndex,
    currFunnelItemTitle,
    currSquareIndexInService,
    currFunnelItemAccessor,
    currSquareGlobalIndex,
    prevFunnelItemTitle,
    prevSquareIndexInService,
    prevSquarePartIndex,
    funnelPartIdxOfPrevSquare,
    funnelPartIdxOfCurrSquare,
    funnelItemsToFetch,
    topServiceItemsLength,
    topSquaresLength,
    middleFunnelItemsLength,
    totalServiceLength,
  }) => {
    const sharedReturnsForEachKPI = {
      funnelItemsToFetch,
      currSquareGlobalIndex,
      squareIndexInService: currSquareIndexInService,
    };

    const calculateConvRateBetweenCurrAndPrevSquare = () => {
      const getTopMetricName = (squarePartIndex) => {
        const isFrontendPurchaseMetric =
          (!isLeadDefinedInTop && topServiceItemsLength === 1) ||
          (topServiceItemsLength === 2 && squarePartIndex === topSquaresLength - 1);

        return isFrontendPurchaseMetric ? 'Frontend' : 'Lead';
      };

      const boxSettingsPerFunnelPart = [
        {
          funnelPart: 'top',
          getMetricName: getTopMetricName,
          prevItemMetricParam: prevSquarePartIndex,
          currItemMetricParam: currSquarePartIndex,
        },
        {
          funnelPart: 'middle',
          getMetricName: (itemTitle) => `${capitalize(itemTitle)}`,
          prevItemMetricParam: prevFunnelItemTitle,
          currItemMetricParam: currFunnelItemTitle,
        },
        {
          funnelPart: 'bottom',
          getMetricName: (bofuIdx) => (funnelPartIdxOfPrevSquare === 2 ? `Product ${bofuIdx + 1}` : 'Customer'),
          prevItemMetricParam: prevSquarePartIndex,
          currItemMetricParam: currSquarePartIndex,
        },
      ];

      const { prevItemMetricParam: prevParam } = boxSettingsPerFunnelPart[funnelPartIdxOfPrevSquare];
      const { currItemMetricParam: currParam } = boxSettingsPerFunnelPart[funnelPartIdxOfCurrSquare];

      const prevBoxMetricName = boxSettingsPerFunnelPart[funnelPartIdxOfPrevSquare].getMetricName(prevParam);
      const currBoxMetricName = boxSettingsPerFunnelPart[funnelPartIdxOfCurrSquare].getMetricName(currParam);

      const settingsForCurrentBox = {
        ...boxSettingsPerFunnelPart[funnelPartIdxOfCurrSquare],
        ...sharedReturnsForEachKPI,
        comparableSquareIndex: prevSquareIndexInService,
        boxName: `${prevBoxMetricName} to ${currBoxMetricName}`,
        type: TYPES_OF_KPIS.conversion,
      };

      accum.push(settingsForCurrentBox);

      return accum;
    };

    const addBotFunnelRelatedBoxes = () => {
      /* ------------------------------------------------------------------------------------------------
       * Because when only one more is selected, then the compare with prev items will already calculate it
       * -----------------------------------------------------------------------------------------------*/
      const isThreeSquaresWithConvRate = reducedArray.length >= 3;
      const isMidOrTopDefined = topServiceItemsLength || middleFunnelItemsLength;
      const isLastBottomFunnelItem = currSquareIndexInService === totalServiceLength - 1;

      const firstSquareName = isLeadDefinedInTop ? 'Lead' : 'Frontend';

      if (isThreeSquaresWithConvRate && isMidOrTopDefined) {
        const topToBottomBox = {
          ...sharedReturnsForEachKPI,
          funnelPart: 'bottom',
          boxName: `${firstSquareName} to Customer`,
          comparableSquareIndex: 0,
          type: TYPES_OF_KPIS.conversion,
        };

        accum.push(topToBottomBox);
      }

      /* ------------------------------------------------------------------------------------------------
       * Revenue per lead will be triggered when there is a Lead square and a stripe product square
       * -----------------------------------------------------------------------------------------------*/

      const isWithStripeProduct = currFunnelItemAccessor === 'stripeProduct';

      if (isWithStripeProduct && isLeadDefinedInTop) {
        const revenuePerLead = {
          ...sharedReturnsForEachKPI,
          funnelPart: 'bottom',
          boxName: 'Revenue per Lead',
          comparableSquareIndex: isLeadDefinedInTop ? 0 : null,
          typeOfExtraService: 'revenue',
          type: TYPES_OF_KPIS.metric,
        };

        accum.push(revenuePerLead);
      }

      const firstProductGlobalIdx = topServiceItemsLength + middleFunnelItemsLength;

      if (bottomFunnelItems.length >= 3 && isLastBottomFunnelItem) {
        const productToLastProduct = {
          funnelPart: 'bottom',
          boxName: `Product 1 to Product ${currSquarePartIndex + 1}`,
          comparableSquareIndex: firstProductGlobalIdx,
          type: TYPES_OF_KPIS.conversion,
        };

        accum.push(productToLastProduct);
      }
    };

    /* -------------------------------------------------------------------------------------------------
     * Execution Starting
     * -----------------------------------------------------------------------------------------------*/
    // When frontendPurchase is inside the OR group of leads
    if (isLeadDefinedInTop && currFunnelItemAccessor === 'frontEndPurchase' && currSquareIndexInService === 0)
      return accum;

    calculateConvRateBetweenCurrAndPrevSquare();

    if (funnelPartIdxOfCurrSquare === 2) addBotFunnelRelatedBoxes();

    return accum;
  };

  const getSettingsForEachKPIBoxWhenIsNotFetchedById = () => {
    const withoutAutomations = globalSquaresInfo.filter(({ squareName }) => squareName?.accessor !== 'automation');
    const squaresWithoutDuplLeads = removeDuplicatedSquaresOfLeads({ squaresArray: withoutAutomations });

    return squaresWithoutDuplLeads.reduce((accum, squareInfo, reduceIndex) => {
      if (reduceIndex === 0) return accum;

      const {
        squareIndexInService: currSquareIndexInService,
        squareName,
        squarePartIndex: currSquarePartIndex,
        funnelPartIdxOfCurrSquare,
        squareGlobalIdx: currSquareGlobalIndex,
      } = squareInfo;

      const { name: currFunnelItemTitle, accessor: currFunnelItemAccessor } = squareName;

      const isLeadDefinedInTop = topInfoSquares.some(({ squareName: sqName }) => sqName?.accessor === 'newLead');

      const funnelItemsToFetch = bottomFunnelService.slice(0, currSquareIndexInService + 1);

      const prevReduceIdx = reduceIndex - 1;

      const {
        squareName: { name: prevFunnelItemTitle },
        squareIndexInService: prevSquareIndexInService,
        squarePartIndex: prevSquarePartIndex,
        funnelPartIdxOfCurrSquare: funnelPartIdxOfPrevSquare,
      } = squaresWithoutDuplLeads[prevReduceIdx] || {};

      getSettingsForEachKPIBox({
        accum,
        reducedArray: squaresWithoutDuplLeads,
        isLeadDefinedInTop,
        currSquarePartIndex,
        currFunnelItemTitle,
        currSquareIndexInService,
        currFunnelItemAccessor,
        currSquareGlobalIndex,
        prevFunnelItemTitle,
        prevSquareIndexInService,
        prevSquarePartIndex,
        funnelPartIdxOfPrevSquare,
        funnelPartIdxOfCurrSquare,
        funnelItemsToFetch,
        topServiceItemsLength: topFunnelService.length,
        topSquaresLength: topInfoSquares.length,
        middleFunnelItemsLength: middleFunnelItems.length,
        totalServiceLength: bottomFunnelService.length,
      });

      return accum;
    }, []);
  };

  const getSettingsForEachKPIBoxWhenFunnelIsFetchedById = () => {
    const getItemsFromFunnelPart = (funnelPartInString) => {
      return funnelItemsFromDb.filter((funnelPart) =>
        funnelPart.some(({ path: route }) => route[0] === funnelPartInString)
      );
    };

    let squarePartIndex = 0;

    const withoutAutomations = funnelItemsFromDb
      .map((funnelPart, funnelPartIdx) => {
        return funnelPart.map((funnelItem) => {
          const addIndexesToObject = {
            ...funnelItem,
            indexInService: funnelPartIdx,
            squareIndexInFunnelPart: squarePartIndex,
          };

          const funnelPartOfCurrSquare = funnelItem.path[0];
          const funnelPartOfNextSquare =
            funnelPartIdx === funnelItemsFromDb.length - 1 ? 'bottom' : funnelItemsFromDb[funnelPartIdx + 1][0].path[0];

          squarePartIndex = funnelPartOfCurrSquare !== funnelPartOfNextSquare ? 0 : (squarePartIndex += 1);

          return addIndexesToObject;
        });
      })
      .filter((funnelPart) => funnelPart.some(({ path }) => path[1] !== 'automation'));

    return withoutAutomations.reduce((accum, fetchedFunnelPart, reduceIndex) => {
      if (reduceIndex === 0) return accum;
      const prevReduceIdx = reduceIndex - 1;

      const currFirstItemIn_OR_group = fetchedFunnelPart[0];
      const {
        path: currPath,
        indexInService: currSquareIndexInService,
        squareIndexInFunnelPart: currSquarePartIndex,
      } = currFirstItemIn_OR_group;

      const [funnelPartOfCurrSquare, currFunnelItemAccessor] = currPath;

      const prevFirstItemIn_OR_group = withoutAutomations[prevReduceIdx][0];
      const {
        path: prevPath,
        indexInService: prevSquareIndexInService,
        squareIndexInFunnelPart: prevSquarePartIndex,
      } = prevFirstItemIn_OR_group;

      const [funnelPartOfPrevSquare] = prevPath;

      const topFunnelItems = getItemsFromFunnelPart('top');
      const middlePartialItems = getItemsFromFunnelPart('middle');

      const topSquaresLength = topFunnelItems.reduce((acc, topItem) => acc + topItem.length, 0);

      const isLeadDefinedInTop = withoutAutomations
        .map((funnelPart) => funnelPart.some(({ path: route }) => route[1] === 'newLead'))
        .includes(true);

      const pathToGetFunnelItemTitle = currPath.slice(0, 2);
      const currFunnelItemTitle = getItemTitleWithRoute({ isUserWithStripe, pathArray: pathToGetFunnelItemTitle });

      const pathToGettoPrevFunnelItemTitle = prevPath.slice(0, 2);
      const prevFunnelItemTitle = getItemTitleWithRoute({
        isUserWithStripe,
        pathArray: pathToGettoPrevFunnelItemTitle,
      });

      getSettingsForEachKPIBox({
        accum,
        reducedArray: withoutAutomations,
        isLeadDefinedInTop,
        currSquarePartIndex,
        currFunnelItemTitle,
        currSquareIndexInService,
        currFunnelItemAccessor,
        funnelPartIdxOfCurrSquare: funnelPartOfCurrSquare === 'top' ? 0 : funnelPartOfCurrSquare === 'middle' ? 1 : 2,
        prevFunnelItemTitle,
        prevSquareIndexInService,
        prevSquarePartIndex,
        funnelPartIdxOfPrevSquare: funnelPartOfPrevSquare === 'top' ? 0 : funnelPartOfPrevSquare === 'middle' ? 1 : 2,
        funnelItemsToFetch: null,
        topServiceItemsLength: topFunnelItems.length,
        topSquaresLength,
        middleFunnelItemsLength: middlePartialItems.length,
        totalServiceLength: withoutAutomations.length,
      });

      return accum;
    }, []);
  };

  const settingsForEachBox =
    funnelId && isInitialFetch
      ? getSettingsForEachKPIBoxWhenFunnelIsFetchedById()
      : getSettingsForEachKPIBoxWhenIsNotFetchedById();

  const getBoxesJSX = (boxesSettings) => {
    return boxesSettings.map((boxSettings, boxIdx) => {
      const boxExtraProps = {
        dataFromInitialFetch,
      };

      const key = `key-${boxIdx}`;
      return <KPIBox key={key} {...boxSettings} {...boxExtraProps} />;
    });
  };

  const KPIMetricsSettings = settingsForEachBox.filter((s) => s.type === TYPES_OF_KPIS.metric);
  const KPIConversionSettings = settingsForEachBox.filter((s) => s.type === TYPES_OF_KPIS.conversion);

  const metricBoxes = getBoxesJSX(KPIMetricsSettings);
  const conversionBoxes = getBoxesJSX(KPIConversionSettings);

  return (
    <KPISections>
      {!!conversionBoxes.length && <KPISection sectionTitle="Conversion Rates" items={conversionBoxes} />}
      {!!metricBoxes.length && <KPISection sectionTitle="Metrics" items={metricBoxes} />}
    </KPISections>
  );
}

FunnelOverviewKPIs.propTypes = {
  getFunnelItemFromSquare: PropTypes.func,
  ...getFunnelByIdProps,
};

/* ------------------------------------------------------------------------------------------------
 * Children components
 * -----------------------------------------------------------------------------------------------*/

function KPISections({ children }) {
  // const { isSubmenuOpen } = useSidebar();
  return <div className="flex-wrap">{children}</div>;
}

KPISections.propTypes = {
  children: PropTypes.any,
};

function KPISection({ sectionTitle, items }) {
  return (
    <>
      <SectionTitle>{sectionTitle}</SectionTitle>
      <ItemsWrapper>{items}</ItemsWrapper>
      <Spacer size="md" />
    </>
  );
}

KPISection.propTypes = {
  items: PropTypes.any,
  sectionTitle: PropTypes.any,
};

const SectionTitle = ({ children }) => {
  return (
    <div className="pl-2 inline-block">
      <HelperText secondary={false}>{children}</HelperText>
      <Spacer size="xs" />
      <Line />
    </div>
  );
};

SectionTitle.propTypes = {
  children: PropTypes.any,
};

const ItemsWrapper = ({ children }) => {
  return <Flex wrap>{children}</Flex>;
};

ItemsWrapper.propTypes = {
  children: PropTypes.any,
};

function KPIBox({
  boxName,
  funnelItemsToFetch,
  squareIndexInService,
  comparableSquareIndex,
  currSquareGlobalIndex,
  typeOfExtraService,
  dataFromInitialFetch,
}) {
  const { timeRange } = useRecoilValue(RecoilTimeRange);
  const { getIsFunnelItemDataChanged } = useFunnelPresentationView();

  const { initialFetchedData } = dataFromInitialFetch || {};
  const { funnelItems: fetchedFunnel } = initialFetchedData || {};

  const peopleArrayToBeCompared = fetchedFunnel ? fetchedFunnel.map(({ people }) => people) : null;

  const { customersAndRevenue } =
    fetchedFunnel && fetchedFunnel[squareIndexInService] ? fetchedFunnel[squareIndexInService] : {};

  const isInitialFetchedDataChanged = getIsFunnelItemDataChanged({ globalSquareIndex: currSquareGlobalIndex });

  const convRateProps = {
    funnelItems: funnelItemsToFetch,
    timeRange,
    comparableSquareIndex,
    currSquareIndexInService: squareIndexInService,
    typeOfExtraService,
  };

  return (
    <KPIBoxContainer>
      <HelperText secondary={false}>{boxName}</HelperText>
      <Spacer size="xs" />
      {isInitialFetchedDataChanged ? (
        <GetConvRateOfCombinedLeadsCell {...convRateProps} />
      ) : (
        <ConvRateOfCombinedLeads
          {...convRateProps}
          getCombinedLeadsCount={peopleArrayToBeCompared}
          revenueWithoutFormat={customersAndRevenue?.revenue}
        />
      )}
    </KPIBoxContainer>
  );
}

function KPIBoxContainer({ children }) {
  return (
    <div style={{ minWidth: '150px' }} className="bg-wmxHighlightDark-100 p-4 mt-4 mr-3 rounded-md shadow-xl">
      {children}
    </div>
  );
}

KPIBoxContainer.propTypes = {
  children: PropTypes.any,
};

FunnelOverviewKPIs.propTypes = {
  funnelItemsFromDb: PropTypes.array,
  dataFromInitialFetch: PropTypes.shape({
    initialFetchedData: PropTypes.shape({
      ...getFunnelByIdProps,
    }),
    isInitialFetch: PropTypes.bool,
    setInitialFetch: PropTypes.func,
  }),
};

KPIBox.propTypes = {
  boxName: PropTypes.string,
  funnelItemsToFetch: PropTypes.array,
  comparableSquareIndex: PropTypes.number,
  squareIndexInService: PropTypes.number,
  typeOfExtraService: PropTypes.string,
  ...getFunnelByIdProps,
};
