import { useEffect } from 'react';

export default function usePressOutsideContainer({
  containerRef,
  closeContainerHandler,
  extraConditionHandler = null,
  onClose = () => {},
}) {
  const handleClickInsideOutsideContainer = (event) => {
    // eslint-disable-next-line no-use-before-define
    const extraCondition = getExtracondition(extraConditionHandler, event);
    if (containerRef?.current) {
      if (!containerRef.current !== event.target && !containerRef.current.contains(event.target) && extraCondition) {
        closeContainerHandler();
        onClose(event);
      }
    }
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClickInsideOutsideContainer);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickInsideOutsideContainer);
    };
  }, [extraConditionHandler]);
}

const getExtracondition = (extraConditionHandler, event) => {
  if (typeof extraConditionHandler === 'function') {
    return extraConditionHandler(event);
  }
  return true;
};
