import PropTypes from 'prop-types';
import { OnboardingProvider, Step } from 'src/components/OnboardingFlow/useOnboardingFlow';
import {
  AcStep,
  StripeStep,
  LoomStep,
  ProgressCard,
  CalendlyStep,
  StepsProgressCustom,
} from 'src/components/OnboardingFlow/Steps';
import OnboardingLayout from 'src/layouts/OnboardingLayout/OnboardingLayout';
import useCurrentUser from 'src/customHooks/useCurrentUser';

const OnboardingFlow = ({ slug }) => {
  const {
    acIntegration: { status: AcStatus } = {},
    stripeIntegration: { status: StripeStatus },
  } = useCurrentUser();

  return (
    <OnboardingProvider slug={slug} AcStatus={AcStatus} StripeStatus={StripeStatus}>
      <Step slug="esp">
        <OnboardingLayout
          Left={
            <div>
              <StepsProgressCustom />
              <AcStep />
            </div>
          }
        />
      </Step>
      <Step slug="payments">
        <OnboardingLayout
          Left={
            <div>
              <StepsProgressCustom />
              <StripeStep />
            </div>
          }
        />
      </Step>
      <Step slug="demo">
        <OnboardingLayout
          Left={
            <div>
              <StepsProgressCustom />
              <CalendlyStep />
            </div>
          }
        />
      </Step>
      <Step slug="overview">
        <OnboardingLayout
          Left={
            <div>
              <StepsProgressCustom />
              <LoomStep />
            </div>
          }
          Right={<ProgressCard />}
        />
      </Step>
    </OnboardingProvider>
  );
};

OnboardingFlow.propTypes = {
  slug: PropTypes.string,
};

export default OnboardingFlow;
