import { useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { segmentBuilderElementClickedId } from 'src/atoms/funnelAtoms';
import { useFunnelData } from 'src/components/Funnel/funnelHooks';
import SearchItemsFromDb from 'src/components/SearchItems/SearchItemsFromDb/SearchItemsFromDb';

export default function SearchItemsFunnel({ itemAccessor, typeOfItemName, onSuccessClose, onDismiss }) {
  const [inputValue, setInputValue] = useState('');
  const [tagsIdContains, setTagsIdContains] = useState([]);

  const clickedElementIdInSegmentBuilder = useRecoilValue(segmentBuilderElementClickedId);
  const { currentSquareIndex, currentSegmentElements, currentFunnelPart, funnelData, onFunnelItemsQueryUpdate } =
    useFunnelData();

  const currentSquareInFunnelPart = funnelData[currentFunnelPart]?.squares[currentSquareIndex];

  const onDoneClick = () => {
    const clonedElements = currentSegmentElements.slice();
    const elementIdxIfExists = currentSegmentElements.findIndex(({ id }) => id === clickedElementIdInSegmentBuilder);
    const condition = 'contains';

    const newElement = {
      id: clickedElementIdInSegmentBuilder,
      value: {
        itemName: inputValue,
        people: '...',
        itemsIds: tagsIdContains,
        itemAccessor,
        condition,
      },
      ...(currentSegmentElements[elementIdxIfExists] && {
        connection: currentSegmentElements[elementIdxIfExists].connection,
      }),
    };

    if (elementIdxIfExists !== -1) {
      clonedElements.splice(elementIdxIfExists, 1, newElement);
    } else {
      clonedElements.push(newElement);
    }

    const modSquare = {
      ...currentSquareInFunnelPart,
      segmentElements: clonedElements,
      typeOfItem: itemAccessor,
      isEmpty: false,
      readyToQuery: true,
    };
    const clonedSquares = funnelData[currentFunnelPart]?.squares.slice();
    clonedSquares.splice(currentSquareIndex, 1, modSquare);

    onFunnelItemsQueryUpdate({ newSquares: clonedSquares });

    const onSuccesClosePayload = {
      selectionType: condition,
      containsKeyWord: inputValue,
      funnelPart: currentFunnelPart,
      typeOfItem: itemAccessor,
    };
    onSuccessClose(onSuccesClosePayload);
  };

  const onRowClick = ({ row, dataChunk, condition }) => {
    // to do: move the logic of this to the funnelHooks
    if (condition !== 'Select') {
      return;
    }
    const { values } = row;
    const { itemName } = values || {};
    const clonedElements = currentSegmentElements.slice();
    const elementIdxIfExists = currentSegmentElements.findIndex(({ id }) => id === clickedElementIdInSegmentBuilder);

    const itemsIds = dataChunk
      .filter((item) => {
        return item.name === itemName;
      })
      .map((item) => {
        return item.id;
      });

    const newElement = {
      id: clickedElementIdInSegmentBuilder,
      value: {
        itemName,
        people: values.people,
        itemsIds,
        itemAccessor,
        condition: 'select',
      },
      ...(currentSegmentElements[elementIdxIfExists] && {
        connection: currentSegmentElements[elementIdxIfExists].connection,
      }),
    };

    if (elementIdxIfExists !== -1) {
      clonedElements.splice(elementIdxIfExists, 1, newElement);
    } else {
      clonedElements.push(newElement);
    }
    const modSquare = {
      ...funnelData[currentFunnelPart]?.squares[currentSquareIndex],
      segmentElements: clonedElements,
      typeOfItem: itemAccessor,
      isEmpty: false,
      readyToQuery: true,
      isLastSquare: funnelData[currentFunnelPart]?.squares.length - 1 === currentSquareIndex,
    };

    const clonedSquares = funnelData[currentFunnelPart]?.squares.slice();
    clonedSquares.splice(currentSquareIndex, 1, modSquare);

    onFunnelItemsQueryUpdate({ newSquares: clonedSquares });

    const onSuccesClosePayload = {
      selectionType: condition,
      itemName,
      funnelPart: currentFunnelPart,
      typeOfItem: itemAccessor,
    };
    onSuccessClose(onSuccesClosePayload);
  };

  const searchItemsFromDbProps = {
    onDoneClick,
    onRowClick,
    inputValue,
    setInputValue,
    itemAccessor,
    typeOfItemName,
    setTagsIdContains,
    onDismiss,
  };

  return <SearchItemsFromDb {...searchItemsFromDbProps} />;
}

SearchItemsFunnel.propTypes = {
  itemAccessor: PropTypes.string,
  typeOfItemName: PropTypes.string,
  onSuccessClose: PropTypes.func,
  onDismiss: PropTypes.func,
};
