const TRIAL_DAYS = 10;

const ACCOUNT_STATUSES = {
  trialing: {
    dbValue: 'trialing',
    label: 'Trialing',
  },
  trialExpired: {
    dbValue: 'trial_expired',
    label: 'Trial Expired',
  },
  active: {
    dbValue: 'active',
    label: 'Active',
  },
  inactive: {
    dbValue: 'inactive',
    label: 'Inactive',
  },
  paidDue: {
    dbValue: 'paid_due',
    label: 'Paid Due',
  },
  deleting: {
    dbValue: 'deleting',
    label: 'Deleting',
  },
};

const INTEGRATION_RUNNING_TYPES = {
  all: 'all',
  rest: 'rest',
  resync: 'resync',
};

const INTEGRATION_STATUSES = {
  importing: 'Importing',
  ready: 'Ready',
  notConnected: 'Not connected',
  disconnecting: 'Disconnecting',
  notAllowed: 'Upgrade to enable',
};

const INTEGRATION_DATAPHASES = {
  restDone: 'restDone',
  importingRest: 'importingRest',
  firstPhaseDone: 'firstPhaseDone',
  importingFirstPhase: 'importingFirstPhase',
};

const INTEGRATION_DATAPHASES_LABELS = {
  restDone: 'Rest Done',
  importingRest: 'Importing Rest',
  firstPhaseDone: 'First Phase Done',
  importingFirstPhase: 'Importing First Phase',
};

const MAP_STATUS_DATAPHASE = {
  [INTEGRATION_STATUSES.ready]: {
    [INTEGRATION_RUNNING_TYPES.all]: INTEGRATION_DATAPHASES.firstPhaseDone,
    [INTEGRATION_RUNNING_TYPES.rest]: INTEGRATION_DATAPHASES.restDone,
    [INTEGRATION_RUNNING_TYPES.resync]: '', // the resync type doesn't change the dataPhase status
  },
  [INTEGRATION_STATUSES.importing]: {
    [INTEGRATION_RUNNING_TYPES.all]: INTEGRATION_DATAPHASES.firstPhaseDone,
    [INTEGRATION_RUNNING_TYPES.rest]: INTEGRATION_DATAPHASES.restDone,
    [INTEGRATION_RUNNING_TYPES.resync]: '', // the resync type doesn't change the dataPhase status
  },
};

const ACCOUNT_TYPES = {
  free: 'Free',
  pro: 'Pro',
  trial: 'Trial',
};

const ACCOUNT_STATUSES_BY_TYPE = {
  [ACCOUNT_TYPES.free]: {
    active: ACCOUNT_STATUSES.active,
    inactive: ACCOUNT_STATUSES.inactive,
  },
  [ACCOUNT_TYPES.pro]: {
    active: ACCOUNT_STATUSES.active,
    inactive: ACCOUNT_STATUSES.inactive,
    paidDue: ACCOUNT_STATUSES.paidDue,
  },
  [ACCOUNT_TYPES.trial]: {
    trialing: ACCOUNT_STATUSES.trialing,
    trialExpired: ACCOUNT_STATUSES.trialExpired,
  },
};

const PROVIDERS = {
  stripe: 'stripe',
  activeCampaign: 'activecampaign',
};

const PROVIDER_PLAN = {
  [PROVIDERS.stripe]: [ACCOUNT_TYPES.pro, ACCOUNT_TYPES.trial],
  [PROVIDERS.activeCampaign]: 'all',
};

const DATE_RANGES_PLAN = {
  [ACCOUNT_TYPES.pro]: 'all',
  [ACCOUNT_TYPES.free]: ['Today', 'Yesterday', 'Last 7d', 'Last 30d', 'Last 3m', 'Last 6m'],
  [ACCOUNT_TYPES.trial]: ['Today', 'Yesterday', 'Last 7d', 'Last 30d', 'Last 3m', 'Last 6m'],
};

const FEATURES = {
  extendedDateRanges: 'Extended date ranges',
  stripeIntegration: 'Stripe integration',
};

const INTEGRATION_ORIGINS = {
  onboarding: 'onb',
  integrations: 'int',
};

const REDIRECTS_STRIPE = {
  local: 'https://localhost:8910/prod/api/connectCreateStripeIntegration',
  prev: 'https://dev.wildmetrics.io/prev/api/stripe-connect',
  dev: 'https://dev.wildmetrics.io/prev/api/stripe-connect',
  prod: 'https://app.wildmetrics.io/prod/api/stripe-connect',
};

const AUTH_TYPES = {
  logIn: 'login',
  signUp: 'signup',
};

const ACCOUNT_ORDER_BY = {
  type: 'TYPE',
  email: 'EMAIL',
  createdAt: 'CREATED_AT',
};

module.exports = {
  TRIAL_DAYS,
  ACCOUNT_STATUSES,
  ACCOUNT_TYPES,
  INTEGRATION_STATUSES,
  INTEGRATION_RUNNING_TYPES,
  PROVIDER_PLAN,
  PROVIDERS,
  INTEGRATION_ORIGINS,
  REDIRECTS_STRIPE,
  DATE_RANGES_PLAN,
  ACCOUNT_STATUSES_BY_TYPE,
  FEATURES,
  INTEGRATION_DATAPHASES,
  AUTH_TYPES,
  INTEGRATION_DATAPHASES_LABELS,
  MAP_STATUS_DATAPHASE,
  ACCOUNT_ORDER_BY,
};
