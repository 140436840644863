import { useState, useEffect } from 'react';
import { useAuth } from '@redwoodjs/auth';
import { useRecoilState, useSetRecoilState } from 'recoil';
import isEqual from 'lodash.isequal';

import { hasAtLeastOneCreatedFunnel as RecoilHasAtLeastOneCreatedFunnel } from 'src/atoms/userAtom';
import { AUTH_TYPES } from 'wmx-shared-code/accountGlobalVariables';

import {
  useIdentifyUserPayload,
  triggerIdentifyEvent,
  triggerTrackEvent,
  SEGMENT_TRACK_EVENTS_NAMES,
} from 'src/lib/segmentJuneEvents/segmentJuneEvents';

import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';
import { getFixedRangeFromDynamicRange } from 'src/components/Generic/DatePicker/getTimeRange';
import { getGuessedTimezone } from 'src/lib/handleTimezone';
import { useDefaultTimeZone, useDefaultTimeRange } from 'src/lib/localStorageKeys';

export default function SetAppInitialValues({ children }) {
  const { currentUser } = useAuth();
  const [user, setUser] = useState(currentUser);

  const { defaultTimeRange: dbDefaultTimeRange, defaultTimezone: dbDefaultTimezone } = currentUser || {};
  const { set: setLocalStorageDefaultTimeRange, get: getLocalStorageDefaultTimeRange } = useDefaultTimeRange();
  const { set: setLocalStorageDefaultTimeZone, get: getLocalStorageDefaultTimeZone } = useDefaultTimeZone();
  const { label: localDefaultTimeRange } = getLocalStorageDefaultTimeRange();
  const { timezone: localDefaultTimezone } = getLocalStorageDefaultTimeZone;

  const { hasUserLoggedIn } = useUpdateUserLoggedIn({ user, setUser, currentUser });
  useUpdateTimeRange({ hasUserLoggedIn, defaultTimeRange: dbDefaultTimeRange, defaultTimezone: dbDefaultTimezone });
  useUpdateCurrentUser({ hasUserLoggedIn, currentUser });

  if (!currentUser) return children;

  const guessedTimezone = getGuessedTimezone();

  if (localDefaultTimeRange !== dbDefaultTimeRange) setLocalStorageDefaultTimeRange({ label: dbDefaultTimeRange });
  if (localDefaultTimezone !== dbDefaultTimezone)
    setLocalStorageDefaultTimeZone({ timezone: dbDefaultTimezone || guessedTimezone });

  return children;
}

function useUpdateUserLoggedIn({ user, setUser, currentUser }) {
  const isSameUser = isEqual(user, currentUser);
  const { userPayload: realUserPayload, loggedInAs: isLoggedAs } = useIdentifyUserPayload() || {};

  useEffect(() => {
    const { logIn: logInEventName, signUp: signUpEventName } = SEGMENT_TRACK_EVENTS_NAMES;

    const triggerUserIdentificationEvent = () => {
      const userPayload = isLoggedAs
        ? { email: 'loggedAs@wildmetrics.io', loggedAs: realUserPayload, loggedAsEmail: realUserPayload.email }
        : realUserPayload;

      triggerIdentifyEvent({
        accountId: isLoggedAs ? 'loggedAs' : realUserPayload.accountId,
        payload: userPayload,
      });

      const isSignUp = userPayload.authType === AUTH_TYPES.signUp;
      const eventName = isSignUp ? signUpEventName : logInEventName;

      triggerTrackEvent({ eventName, payload: userPayload });
    };

    if (!isSameUser) {
      triggerUserIdentificationEvent();
      setUser(currentUser);
    }
  });

  return { hasUserLoggedIn: !isSameUser };
}

// We use this hook to update the initial time range value from the db as our source of truth in
function useUpdateTimeRange({
  hasUserLoggedIn,
  defaultTimeRange: dbDefaultTimeRange,
  defaultTimezone: dbDefaultTimezone,
}) {
  const setTimeRange = useSetRecoilState(RecoilTimeRange);

  useEffect(() => {
    if (hasUserLoggedIn && dbDefaultTimeRange) {
      const [start, end] = getFixedRangeFromDynamicRange(dbDefaultTimeRange, dbDefaultTimezone);

      setTimeRange({ label: dbDefaultTimeRange, timeRange: { start, end } });
    }
  });
}

function useUpdateCurrentUser({ hasUserLoggedIn, currentUser }) {
  const { hasAtLeastOneCreatedFunnel: initialHasAtLeastOneCreatedFunnel } = currentUser || {};

  const [hasAtLeastOneCreatedFunnel, setHasAtLeastOneCreatedFunnel] = useRecoilState(RecoilHasAtLeastOneCreatedFunnel);

  useEffect(() => {
    if (hasUserLoggedIn) {
      // createdFunnelState
      if (hasAtLeastOneCreatedFunnel === null) {
        setHasAtLeastOneCreatedFunnel(initialHasAtLeastOneCreatedFunnel);
      }
    }
  });
}
