export const getDropdownClassNames = ({ isDropdownOpen } = {}) => {
  const containerBorder = isDropdownOpen ? '' : 'border-2 border-wmxHighlightDark-50';
  const hiddenWhenOpen = isDropdownOpen ? 'hidden' : '';
  const classNames = {
    container: `${containerBorder} rounded pl-2`,
    list: 'left-0',
    buttonTrigger: `${hiddenWhenOpen} border-none pl-1 pr-2 text-left justify-start`,
  };

  const classNamesByType = {
    buttonTrigger: {
      paddingY: 'py-2',
    },
  };

  return { classNames, classNamesByType };
};
