import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { timeRange as RecoilTimeRange } from 'src/atoms/timeRangeAtom';

import { usePeopleReportsContext, useTriggerPeoplePageVisited } from 'src/layouts/PeopleLayout/peopleReportsProvider';
import useOrderBy from 'src/components/Analyze/Overview/hooks/useOrderBy';
import { errors } from 'wmx-shared-code/segments/segments';

import { SEGMENT_PEOPLE_REPORT_QUERY } from 'src/components/PeopleReportsMenu/PeopleReportsTable/peopleReportsQueries';

import PeopleReportsTableLoadingSkeleton from 'src/components/PeopleReportsMenu/PeopleReportsTableLoadingSkeleton/PeopleReportsTableLoadingSkeleton';
import PeopleReportsTable, {
  PeopleReportsTable as PeopleReportsTableWithoutHOC,
  SegmentsHeader,
} from 'src/components/PeopleReportsMenu/PeopleReportsTable/PeopleReportsTable';

import { getSegmentPeopleProps } from 'src/components/PeopleReportsMenu/PeopleReportsTable/peopleTableSettings';
import { getPeopleReportsOrderByDefaults as getOrderByDefaults } from 'src/components/PeopleReportsMenu/PeopleReportsTable/peopleTableSettingsHelpers';
import MainHeaderLayout from 'src/layouts/MainHeaderLayout/MainHeaderLayout';
import { PeopleQueryOptions, DefinePeopleErrorMessage } from 'src/layouts/PeopleLayout/PeopleLayout';
import MainContainerLayout from 'src/layouts/MainContainerLayout/MainContainerLayout';
import MainCardLayout from 'src/layouts/MainCardLayout/MainCardLayout';
import EmptyComponent from 'src/components/Generic/EmptyComponent';
import { useSegmentBuilder } from 'src/contexts/SegmentBuilder/SegmentBuilder';

const RENDER_EACH_SCROLL_FOR_TABLE = 60;

export default function PeopleSegmentPage({ segmentId }) {
  const { isTableFetching, setIsTableFetching, peopleTableData } = usePeopleReportsContext();
  const { timeRange } = useRecoilValue(RecoilTimeRange);
  const { segmentsList } = useSegmentBuilder();

  const currentSegmentName = useMemo(() => {
    if (!segmentsList?.length) return '';
    return segmentsList.find((segment) => segment.id === segmentId)?.segmentName;
  }, [segmentsList, segmentId]);

  const { columnOrderBy, columnOrderByDirection, onOrderBy } = useOrderBy({
    typeOfReport: 'customersPeople',
    getOrderByDefaults,
    onClick: () => setIsTableFetching(true),
  });

  const segmentPeopleProps = {
    dataKey: SEGMENT_PEOPLE_REPORT_QUERY.queryName,
    initialQuery: SEGMENT_PEOPLE_REPORT_QUERY.query,
    onOrderBy,
    LoadingComponent: () => <LoadingComponent />,
    EmptyComponent: () => (
      <TableLayout>
        <EmptyComponent />
      </TableLayout>
    ),
    Error: (props) => <ErrorComponent {...props} />,
    queryParams: {
      fetchPolicy: 'cache-first',
      variables: {
        offset: 0,
        limit: RENDER_EACH_SCROLL_FOR_TABLE,
        orderBy: columnOrderBy,
        orderByDirection: columnOrderByDirection,
        timeRange,
        segmentId,
      },
    },
    getTableProps: getSegmentPeopleProps,
    title: currentSegmentName,
  };

  function LoadingComponent() {
    return isTableFetching ? (
      <PeopleReportsTableWithoutHOC {...segmentPeopleProps} data={peopleTableData} isLoadingTable />
    ) : (
      <TableLayout>
        <PeopleReportsTableLoadingSkeleton />
      </TableLayout>
    );
  }

  // eslint-disable-next-line react/prop-types
  function ErrorComponent({ message }) {
    const { setEditingSegment, onDialogOpen, onRightPaneOpen, setNewEmptySegment } = useSegmentBuilder();
    const isANonExistingTag = message === errors.nonExistingItemIdCallback({ itemNameInService: 'tag' });

    const onClick = () => {
      onDialogOpen();
      onRightPaneOpen();
      setEditingSegment(true);
      setNewEmptySegment({ initSegment: { segmentId, segmentName: currentSegmentName } });
    };

    return (
      <TableLayout>
        <EmptyComponent>
          {isANonExistingTag ? (
            <>
              <span className="text-white  text-sm">
                One or more tags defined in this segment do not exist anymore.&nbsp;
              </span>
              <DefinePeopleErrorMessage text="to define your segment again." onClick={onClick} />
            </>
          ) : (
            <span>{message}</span>
          )}
        </EmptyComponent>
      </TableLayout>
    );
  }

  // eslint-disable-next-line react/prop-types
  function TableLayout({ children }) {
    return (
      <MainContainerLayout>
        <SegmentsHeader title={currentSegmentName} type="customSegment" />
        <MainHeaderLayout>
          <PeopleQueryOptions />
        </MainHeaderLayout>
        <MainCardLayout>{children}</MainCardLayout>
      </MainContainerLayout>
    );
  }

  return (
    <>
      {currentSegmentName && <TriggerEvent segmentName={currentSegmentName} />}
      <PeopleReportsTable {...segmentPeopleProps} />;
    </>
  );
}

function TriggerEvent({ segmentName }) {
  useTriggerPeoplePageVisited({ segmentType: segmentName });
  return <></>;
}

PeopleSegmentPage.propTypes = {
  segmentId: PropTypes.any,
  segmentName: PropTypes.any,
};

TriggerEvent.propTypes = {
  segmentName: PropTypes.any,
};
