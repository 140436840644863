/* eslint-disable react/prop-types */
import ScaleLoader from 'react-spinners/ScaleLoader';
import { pretiffyTypeOfItem } from 'src/lib/funnelHelpers/funnelHelpers';

// eslint-disable-next-line no-undef
export const QUERY = gql`
  query GET_COUNT_OF_CONTAINS($itemAccessor: String, $keyword: String) {
    getCountOfContains(keyword: $keyword, itemAccessor: $itemAccessor)
  }
`;

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'cache-first' };
};

export const Loading = ({ showLoading = true }) => {
  return (
    <>
      {showLoading && (
        <div className="flex items-center">
          <ScaleLoader height={7} width={2} margin={1} color="#fff" loading />
        </div>
      )}
    </>
  );
};

export const Empty = () => <div>Empty</div>;

export const Failure = ({ error }) => <div>Error: {error.message}</div>;

export const Success = ({ getCountOfContains, itemAccessor }) => {
  return (
    <span className="text-white text-sm flex-inline items-center">
      {getCountOfContains} {pretiffyTypeOfItem(itemAccessor)}s
    </span>
  );
};
