/* eslint-disable no-use-before-define */
import PropTypes from 'prop-types';

import GetCountOfCombinedLeadsCell from 'src/components/Cells/GetCountOfCombinedLeadsCell/GetCountOfCombinedLeadsCell';
import { useFunnelData } from 'src/components/Funnel/funnelHooks';

const FunnelPartLeadsCount = ({ itemsQuery, timeRange, funnelPartName }) => {
  const { getQueryGroupIdx } = useFunnelData();

  return (
    <>
      <GetCountOfCombinedLeadsCell
        funnelItems={itemsQuery}
        timeRange={timeRange}
        squareIdx={getQueryGroupIdx(funnelPartName)}
      />
    </>
  );
};

FunnelPartLeadsCount.propTypes = {
  itemsQuery: PropTypes.array,
  timeRange: PropTypes.object,
  funnelPartName: PropTypes.string,
};

export default FunnelPartLeadsCount;
