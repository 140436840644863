import { useRecoilState } from 'recoil';
import { tableRows as RecoilTableRows, deletedTableRows as RecoilDeletedTableRows } from 'src/atoms/funnelListAtoms';

export function useGetTableRows() {
  const [initTableRows = [], setTableRows] = useRecoilState(RecoilTableRows);
  const [deletedRows, setDeletedRows] = useRecoilState(RecoilDeletedTableRows);

  const tableRows = initTableRows || [];

  return { tableRows: [tableRows, setTableRows], deletedRows: [deletedRows, setDeletedRows] };
}
