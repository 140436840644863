import { useState } from 'react';
import PropTypes from 'prop-types';

import SearchItemsFromDb from 'src/components/SearchItems/SearchItemsFromDb/SearchItemsFromDb';
import { useSegmentBuilder } from './SegmentBuilder';

export default function SearchItems() {
  const {
    getPricesByTag,
    name,
    onDialogClose,
    onDialogDismiss,
    segmentElements,
    setSegmentElements,
    segmentBuilderElementClickedId,
  } = useSegmentBuilder();

  const itemAccessor = name;
  const [inputValue, setInputValue] = useState('');
  const [tagsIdContains, setTagsIdContains] = useState([]);

  const onDoneClick = () => {
    const clonedElements = segmentElements.slice();
    const elementIdxIfExists = segmentElements.findIndex(({ id }) => id === segmentBuilderElementClickedId);

    const newElement = {
      id: segmentBuilderElementClickedId,
      value: {
        itemName: inputValue,
        people: '...',
        itemsIds: tagsIdContains,
        itemAccessor,
        condition: 'contains',
      },
      ...(segmentElements[elementIdxIfExists] && {
        connection: segmentElements[elementIdxIfExists].connection,
      }),
    };

    if (elementIdxIfExists !== -1) {
      clonedElements.splice(elementIdxIfExists, 1, newElement);
    } else {
      clonedElements.push(newElement);
    }

    setSegmentElements(clonedElements);
    onDialogClose();
  };

  const onRowClick = ({ row, dataChunk, condition }) => {
    if (condition !== 'Select') {
      return;
    }

    const { values } = row;
    const clonedElements = segmentElements.slice();
    const elementIdxIfExists = segmentElements.findIndex(({ id }) => id === segmentBuilderElementClickedId);

    const itemsIds = dataChunk
      .filter((item) => {
        return item.name === values.itemName;
      })
      .map((item) => {
        return item.id;
      });

    const newElement = {
      id: segmentBuilderElementClickedId,
      value: {
        itemName: values.itemName,
        people: values.people,
        itemsIds,
        itemAccessor,
        condition: 'select',
      },
      ...(segmentElements[elementIdxIfExists] && {
        connection: segmentElements[elementIdxIfExists].connection,
      }),
    };

    if (elementIdxIfExists !== -1) {
      clonedElements.splice(elementIdxIfExists, 1, newElement);
    } else {
      clonedElements.push(newElement);
    }

    setSegmentElements(clonedElements);
    onDialogClose();
    getPricesByTag({ segmentElementsInFrontEnd: clonedElements });
  };

  const searchItemsFromDbProps = {
    onDoneClick,
    onRowClick,
    inputValue,
    setInputValue,
    itemAccessor,
    typeOfItemName: 'Tag',
    setTagsIdContains,
    onDismiss: onDialogDismiss,
    onClose: onDialogClose,
  };

  return <SearchItemsFromDb {...searchItemsFromDbProps} />;
}

SearchItems.propTypes = {
  segmentElements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.shape({
        itemName: PropTypes.string,
        people: PropTypes.string, // count turned to string
        itemsIds: PropTypes.array,
        itemAccessor: PropTypes.string,
        condition: PropTypes.oneOf(['select', 'contains']),
      }),
      connection: PropTypes.oneOf(['AND', 'OR']),
    })
  ),
  setSegmentElements: PropTypes.func,
  segmentBuilderElementClickedId: PropTypes.string,
  itemAccessor: PropTypes.string,
  typeOfItemName: PropTypes.string,
  onDialogClose: PropTypes.func,
  onDismiss: PropTypes.func,
};
