export const presentationTableTwClasses = {
  metricsColumnStyles: {
    // width: 96,
    width: 'w-72',
    // padding: 4,
    padding: 'p-4',
  },
  // rotatedWordsWidth: 12,
  rotatedWordsWidth: 'w-12',
  // totalColumnsWidth: 36,
  totalColumnsWidth: 'w-36',
  // funnelRowSeparationToBorder: 8,
  funnelRowSeparationToBorder: 'pl-8',
};

export const removeDuplicatedSquaresOfLeads = ({ squaresArray }) => {
  return squaresArray.reduce((accum, squareInfo, index) => {
    const { squareName } = squareInfo;
    const newLeadAccessor = 'newLead';

    const isNewLead = squareName?.accessor === newLeadAccessor;
    const isThereNextNewLead = squaresArray.some(
      ({ squareName: sqName }, sqIndex) => sqName?.accessor === newLeadAccessor && index < sqIndex
    );

    if (isNewLead && isThereNextNewLead) return accum;

    accum.push(squareInfo);

    return accum;
  }, []);
};

export const getFunnelItemsToFetchFromOrSquares = ({
  funnelPartName,
  squareIndexInService,
  bottomFunnelService,
  currSquarePartIndex,
  currSquareNameAccessor,
}) => {
  if (funnelPartName === 'top' && currSquareNameAccessor === 'newLead') {
    const groupToCall = bottomFunnelService[0].filter((group, idxGroup) => idxGroup === currSquarePartIndex);

    return [groupToCall];
  }

  return bottomFunnelService.slice(0, squareIndexInService + 1);
};
