import { useAuth } from '@redwoodjs/auth';
import { useOnboarding } from 'src/components/OnboardingFlow/useOnboardingFlow';
import { HSpacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { useLocation } from '@redwoodjs/router';
import { useMemo } from 'react';

import { Button } from 'src/components/Generic';

import { INTEGRATION_ORIGINS, REDIRECTS_STRIPE } from 'wmx-shared-code/accountGlobalVariables';
import { getStripeState } from 'src/components/IntegrationTopic/integrationsHelpers';

const NewStripeIntegrationOnboarding = () => {
  const { dispatch } = useOnboarding();
  const { currentUser } = useAuth();
  const { accountId } = currentUser;

  const { pathname } = useLocation();

  const origin = useMemo(() => {
    console.log('pathname', pathname);
    console.log('pathname.indexOf', pathname.indexOf('onboarding'));
    if (pathname.indexOf('onboarding') > 0) return INTEGRATION_ORIGINS.onboarding;
    return INTEGRATION_ORIGINS.integrations;
  }, [pathname]);

  console.log('process.env', process.env);
  console.log('REDIRECTS_STRIPE', REDIRECTS_STRIPE[process.env.STAGE]);

  const onButtonClick = () => {
    window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${
      process.env.REDWOOD_ENV_STRIPE_CLIENT_ID
    }&scope=read_only&state=${getStripeState({ accountId, origin })}&redirect_uri=${
      REDIRECTS_STRIPE[process.env.STAGE]
    }`;
  };

  return (
    <>
      <div className="flex justify-between w-full">
        <div className="mr-auto" />
        <div className="flex">
          <Button onClick={onButtonClick}>Yes, connect Stripe</Button>
          <HSpacer />
          <Button variant="transparent" onClick={() => dispatch({ type: 'next' })}>
            No, skip
          </Button>
        </div>
      </div>
    </>
  );
};

export default NewStripeIntegrationOnboarding;
