import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { setThousandsComma } from 'wmx-shared-code/currency/currency';

import Metric from 'src/components/Dashboard/MetricItem/MetricItem';
import SegmentChartCell from 'src/components/Cells/SegmentChartCell/SegmentChartCell';
import { useDashboard } from 'src/contexts/Dashboard';
import { useTimeSeries } from 'src/lib/graphsHelpers/graphsHelpers';
import { Spacer } from '../Generic/LayoutUtils/LayoutUtils';

const SegmentMetric = ({ value, change, name, segmentId, prevValue, graphFeature = false }) => {
  const { timeRange, timeRangeLabel, editMode } = useDashboard(); // object that has a start and end property
  const { getTimeSerie } = useTimeSeries();
  const timeseries = useMemo(() => getTimeSerie(timeRange, timeRangeLabel), [timeRange, getTimeSerie, timeRangeLabel]);
  return (
    <Metric.Root editMode={editMode}>
      <Metric.Top openButton={!editMode}>
        <Metric.Title>{name}</Metric.Title>
        <Metric.Tooltip absolute={false}>
          <div>This is a custom combination of tags you have choosen.</div>
        </Metric.Tooltip>
        {!editMode && <Metric.Button segmentId={segmentId} />}
      </Metric.Top>
      <Metric.Body>
        <Metric.Value size="xl">{setThousandsComma(value)}</Metric.Value>
        <Metric.Change>{change}</Metric.Change>
      </Metric.Body>
      <Metric.MetricSubtitle size="xs">compared to {prevValue}</Metric.MetricSubtitle>
      {graphFeature && (
        <>
          <Spacer size="xs" />
          <Metric.MetricChart>
            <SegmentChartCell timeSeries={timeseries} segmentId={segmentId} />
          </Metric.MetricChart>
        </>
      )}
    </Metric.Root>
  );
};

SegmentMetric.propTypes = {
  change: PropTypes.any,
  name: PropTypes.any,
  prevValue: PropTypes.any,
  segmentId: PropTypes.any,
  value: PropTypes.any,
  graphFeature: PropTypes.any,
};

export default SegmentMetric;
