const METADATA_HEADERS = {
  charges: 'Charges',
  invoices: 'Invoices',
  customers: 'Customers',
};

const METADATA_TYPES = {
  charge: 'charge',
  invoice: 'invoice',
  customer: 'customer',
  address: 'address',
};

module.exports = {
  METADATA_TYPES,
  METADATA_HEADERS,
};
