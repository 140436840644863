import PropTypes from 'prop-types';
import { SideBarLayoutContainer, SidebarProvider } from 'src/layouts/SidebarLayout/SidebarLayout';
import Main from 'src/layouts/MainLayout/MainLayout';
import { Toaster } from '@redwoodjs/web/dist/toast';
import LoggedAsBar from 'src/lib/LoggedAs/LoggedAsBar/LoggedAsBar';
import { LoggedAsProvider, useLoggedAs } from 'src/lib/LoggedAs/useLoggedAs';
import UpgradeProDialogProvider from 'src/components/UpgradeProDialog/UpgradeProDialog';
import useAppLayout from './useAppLayout';

export const subMenuNavMarginTop = 'mt-8';

export default function AppLayout({ children, classNames, submenu }) {
  useAppLayout();

  return (
    <LoggedAsProvider>
      <UpgradeProDialogProvider>
        <LoggedAsBar />
        <Toaster />
        <AppContainer>
          <SidebarProvider>
            <SideBarLayoutContainer submenu={submenu} />
            <Main classNames={classNames}>{children}</Main>
          </SidebarProvider>
        </AppContainer>
      </UpgradeProDialogProvider>
    </LoggedAsProvider>
  );
}

const AppContainer = ({ children }) => {
  const { loggedAs } = useLoggedAs();

  const paddingTop = loggedAs ? 'pt-6' : 'pt-0';
  return (
    <div
      className={`AppLayout ${paddingTop} flex w-full h-screen  min-h-screen justify-items-left bg-wmxBgDark-400 overflow-hidden`}
    >
      {children}
    </div>
  );
};

AppContainer.propTypes = {
  children: PropTypes.any,
};

AppLayout.propTypes = {
  children: PropTypes.any,
  classNames: PropTypes.any,
  submenu: PropTypes.any,
};
