/* global gql */
import PropTypes from 'prop-types';
import { EmptyComponent, LoadingComponent, Error } from 'src/components/Generic';
import ConvRateOfCombinedLeads from 'src/components/Funnel/PresentationView/Metrics/ConvRateOfCombinedLeads/ConvRateOfCombinedLeads';

export const QUERY = gql`
  query GET_FUNNEL_COMBINED_LEADS($funnelItems: [[FunnelItemsInput!]]!, $timeRange: [TimeRangeInput]) {
    getCombinedLeadsCount(funnelItems: $funnelItems, timeRange: $timeRange)
  }
`;

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'cache-first' };
};

export const Loading = () => <LoadingComponent justifyFlex="justify-start" />;

export const Empty = () => <EmptyComponent />;

export const Failure = ({ error }) => <Error errorMessage={error.message} />;

Failure.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.any,
  }),
};

export const Success = (props) => {
  const { getCombinedLeadsCount } = props;
  return <ConvRateOfCombinedLeads {...props} getCombinedLeadsCount={getCombinedLeadsCount[0]} />;
};

Success.propTypes = {
  getCombinedLeadsCount: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
};
