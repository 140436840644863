import PropTypes from 'prop-types';
import { Flex } from 'src/components/Generic/LayoutUtils/LayoutUtils';

const MainHeaderLayout = ({ children }) => {
  return (
    <div className="flex justify-start bg-wmxHighlightDark-100 rounded-b-none rounded-lg p-2 shadow-xl relative w-full">
      <div className="w-full mb-0 sticky z-30">
        <Flex justify="between">{children || <div className="h-6" />}</Flex>
      </div>
    </div>
  );
};

MainHeaderLayout.propTypes = {
  children: PropTypes.any,
};

export default MainHeaderLayout;
