import PropTypes from 'prop-types';
import { useDashboard } from 'src/contexts/Dashboard';
import React from 'react';
import { formatDateTick } from 'src/lib/graphsHelpers/graphsPeriods';

import BarSkeleton from 'src/components/Generic/Skeletons/BarChartSkeleton/BarChartSkeleton';
import { useAmountFormatter } from 'src/lib/graphsHelpers/graphsHelpers';
import MetricChart from 'src/components/MetricChart/MetricChart';

// eslint-disable-next-line no-undef
export const QUERY = gql`
  query CUSTOMERS_TIMESERIES($timeRange: TimeRangeInput, $timeSeries: JSON) {
    customersTimeseries(timeRange: $timeRange, timeSeries: $timeSeries) {
      customersTimeserie
      timeSeriePeriod
    }
  }
`;

export const beforeQuery = ({ timeSeries }) => {
  return { variables: { timeSeries }, fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-and-network' };
};

export const Loading = ({ boxWidth, boxHeight }) => {
  const { useMetricLoading, metricsGroupWidth } = useDashboard();

  const result = useMetricLoading({
    metric: 'customersTimeseries',
    getValues: useTotals,

    // eslint-disable-next-line react/prop-types
    renderComponent: ({ value, formatXTick, formatYTick, timeSeriePeriod }) => {
      return (
        <MetricChart
          data={value}
          formatXTick={formatXTick}
          width={metricsGroupWidth}
          formatYTick={formatYTick}
          timeSeriePeriod={timeSeriePeriod}
          type="customers"
          boxHeight={boxHeight}
          boxWidth={boxWidth}
        />
      );
    },

    LoadingComponent: () => (
      <BarSkeleton width={boxWidth} height={boxHeight} foregroundColor="#1C2631" backgroundColor="#304254" />
    ),
  });
  return result;
};

export const Empty = () => <div>Empty</div>;

export const Failure = ({ error }) => <div style={{ color: 'red' }}>Error: {error.message}</div>;

Failure.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.any,
  }),
};

export const Success = ({ customersTimeseries, boxHeight, boxWidth }) => {
  const { value, formatXTick, formatYTick, timeSeriePeriod } = useTotals({ customersTimeseries });
  const { useUpdateMetric, metricsGroupWidth } = useDashboard();
  const { tooltipPeopleFormatter } = useAmountFormatter();
  useUpdateMetric({ metric: 'customersTimeseries', value: customersTimeseries });

  return (
    <MetricChart
      data={value}
      formatXTick={formatXTick}
      width={metricsGroupWidth}
      formatYTick={formatYTick}
      timeSeriePeriod={timeSeriePeriod}
      tooltipFormatter={tooltipPeopleFormatter({ type: 'customer' })}
      type="customers"
      boxHeight={boxHeight}
      boxWidth={boxWidth}
    />
  );
};

Success.propTypes = {
  customersTimeseries: PropTypes.any,
  boxHeight: PropTypes.any,
  boxWidth: PropTypes.any,
};

function useTotals({ customersTimeseries }) {
  const { tickPeopleFormatter } = useAmountFormatter();
  return {
    value: customersTimeseries?.customersTimeserie,
    formatXTick: formatDateTick(customersTimeseries?.timeSeriePeriod),
    formatYTick: tickPeopleFormatter,
    timeSeriePeriod: customersTimeseries?.timeSeriePeriod,
  };
}
