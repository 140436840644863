/* global gql */
import PropTypes from 'prop-types';

import CombinedLeadsMetric from 'src/components/Funnel/PresentationView/Metrics/CombinedLeadsMetric/CombinedLeadsMetric';
import { EmptyComponent, LoadingComponent, Error } from 'src/components/Generic';

export const QUERY = gql`
  query GET_FUNNEL_COMBINED_LEADS($funnelItems: [[FunnelItemsInput!]]!, $timeRange: [TimeRangeInput]) {
    getCombinedLeadsCount(funnelItems: $funnelItems, timeRange: $timeRange)
  }
`;

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'cache-first' };
};

export const Loading = () => {
  return <LoadingComponent />;
};

export const Empty = () => <EmptyComponent />;

export const Failure = ({ error }) => <Error errorMessage={error.message} />;

export const Success = ({ getCombinedLeadsCount, squareIdx }) => {
  return <CombinedLeadsMetric queryResult={getCombinedLeadsCount} squareIndexInService={squareIdx} />;
};

Success.propTypes = {
  getCombinedLeadsCount: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  squareIdx: PropTypes.number,
};
