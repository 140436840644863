import PropTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';
import { routes, navigate } from '@redwoodjs/router';
import { Button, Badge } from 'src/components/Generic';

import Dialog from '../Dialog/Dialog';
import { Card, Title, Text, Flex, Spacer, Link, HSpacer } from '../Generic/LayoutUtils/LayoutUtils';
import CrossButton from '../Generic/CrossButton/CrossButton';

export const UpgradeProDialog = createContext();
export const useUpgradeProDialog = () => useContext(UpgradeProDialog);

const UpgradeProDialogProvider = ({ children }) => {
  const [showDialog, setShowDialog] = useState(false);

  const open = () => {
    setShowDialog(true);
  };

  const close = () => setShowDialog(false);

  const onCrossButtonClick = () => {
    close();
  };

  const crossButtonClassNames = {
    button: 'text-white opacity-70 bg-transparent hover:opacity-100 absolute -top-28 right-0',
    span: 'text-2xl',
    container: 'relative',
  };

  const goToBilling = () => {
    navigate(routes.billing());
  };

  return (
    <UpgradeProDialog.Provider value={{ open, close }}>
      {children}
      <Dialog isOpen={showDialog} close={close} ariaParentId="Upgrade Pro">
        <Flex items="center" justify="center">
          <Card>
            <Spacer />
            <Title lg>
              This is a
              <HSpacer size="xs" />
              <Badge size="sm" absolute={false} variant="primary">
                PRO
              </Badge>
              <HSpacer size="xs" />
              feature
            </Title>
            <Spacer size="xs" />
            <Text>In order to use this feature please upgrade your account to Pro.</Text>
            <Spacer size="lg" />
            <Flex>
              <Button onClick={goToBilling}>Upgrade to Pro</Button>
              <HSpacer />
              <Text>
                <Link href="https://docs.wildmetrics.io/pricing" underline={false} target="_blank">
                  Learn more
                </Link>
              </Text>
            </Flex>
          </Card>
          <CrossButton classNames={crossButtonClassNames} onClick={onCrossButtonClick} />
        </Flex>
      </Dialog>
    </UpgradeProDialog.Provider>
  );
};

UpgradeProDialogProvider.propTypes = {
  children: PropTypes.any,
};

export default UpgradeProDialogProvider;
