import React from 'react';
import PropTypes from 'prop-types';

import { getAmountWithCurrencySymbol } from 'src/lib/generic/currency';
import useCurrentUser from 'src/customHooks/useCurrentUser';
import { useDashboard } from 'src/contexts/Dashboard';

import { errors } from 'wmx-shared-code/dashboard/metrics';

import MetricSkeleton from 'src/components/Generic/Skeletons/MetricSkeleton/MetricSkeleton';

import { percentageChange } from 'src/lib/chargesTransformations/chargesTransformations';
import RevenueMetric, { WithoutTagsPricesDefined } from '../RevenueMetric/RevenueMetric';

// eslint-disable-next-line no-undef
export const QUERY = gql`
  query DASHBOARD_REVENUE($timeRange: TimeRangeInput) {
    revenueMetrics(timeRange: $timeRange) {
      partialRevenue
      revenuePrevious
    }
  }
`;

export const beforeQuery = ({ timeRange }) => {
  return { variables: { timeRange }, fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-and-network' };
};

export const Loading = ({ chart }) => {
  const { useMetricLoading } = useDashboard();
  const result = useMetricLoading({
    metric: 'revenueMetrics',
    getValues: useRevenue,
    // eslint-disable-next-line react/prop-types
    renderComponent: ({ value, prev }) => <RevenueMetric value={value} change={prev} chart={chart} />,
    LoadingComponent: () => <MetricSkeleton foregroundColor="#1C2631" backgroundColor="#304254" />,
  });
  return result;
};

export const Failure = ({ error }) => {
  if (error.message === errors.revenueByTag.emptyPrices) return <WithoutTagsPricesDefined />;

  return <div style={{ color: 'white' }}>⚠️ Please reload.</div>;
};

Failure.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.any,
  }),
};

export const Success = ({ revenueMetrics, chart }) => {
  const { value, prev, prevValue } = useRevenue({ revenueMetrics });
  const { useUpdateMetric } = useDashboard();
  useUpdateMetric({ metric: 'revenueMetrics', value: revenueMetrics });
  return <RevenueMetric value={value} change={prev} chart={chart} prevValue={prevValue} />;
};

Success.propTypes = {
  chart: PropTypes.any,
  revenueMetrics: PropTypes.any,
};

function useRevenue({ revenueMetrics }) {
  const {
    defaultCurrency: { threeLetters: defaultCurrencyThreeLetters },
  } = useCurrentUser();

  if (!revenueMetrics) return {};

  return {
    value: getAmountWithCurrencySymbol({
      currency: defaultCurrencyThreeLetters,
      amount: revenueMetrics.partialRevenue / 100,
    }),
    prev: percentageChange(revenueMetrics.revenuePrevious, revenueMetrics.partialRevenue),
    prevValue: getAmountWithCurrencySymbol({
      currency: defaultCurrencyThreeLetters,
      amount: revenueMetrics.revenuePrevious / 100,
    }),
  };
}
