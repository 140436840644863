import PropTypes from 'prop-types';

import VendorOverviewTable, {
  VendorOverviewTable as VendorOverviewTableWithoutHOC,
} from 'src/components/VendorOverviewTable/VendorOverviewTable';
import usePressOutsideContainer from 'src/customHooks/usePressOutsideContainer';
import FunnelRightPane, { RightPaneHeader } from 'src/components/Funnel/FunnelRightPane/FunnelRightPane';
import LastProvidersSyncCell from 'src/components/Cells/LastProviderSyncCell/LastProvidersSyncCell';
import LastDailyResyncCell from 'src/components/Cells/LastDailyResyncCell/LastDailyResyncCell';
import { RowSkeletons } from 'src/components/Generic/Skeletons';

import { metricsKey, useIntegrationsContext, useMetricLoading } from 'src/contexts/Integrations';
import { useTriggerPageSeenEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';

import {
  ButtonsLayer,
  VendorOverviewHeader,
  VendorOverviewSubtitle,
  VendorOverviewDetails,
} from 'src/components/IntegrationTopic/VendorOverviewRightPane/Utils';
import DatapointRecordsContainer from 'src/components/IntegrationTopic/DatapointRecordsTable/DatapointRecordsContainer';

import { ACTIVE_CAMPAIGN_DATAPOINTS_OVERVIEW } from 'src/components/VendorOverviewTable/vendorOverviewQueries';
import { PROVIDERS } from 'wmx-shared-code/accountGlobalVariables';

export default function VendorOverviewRightPane({
  rightPaneRef,
  onRightPaneClose,
  onOutsideClickResizerConditionHandler,
}) {
  const { isCurrentDatapointRecordsTable: isSelectedDatapoint, currentProviderShown: currentProvider } =
    useIntegrationsContext();

  useTriggerPageSeenEvent({
    pageName: SEGMENT_TRACK_EVENTS_NAMES.acSettingsVisited,
    shouldEventBeSent: currentProvider === PROVIDERS.activeCampaign && !isSelectedDatapoint,
  });

  usePressOutsideContainer({
    closeContainerHandler: onRightPaneClose,
    containerRef: rightPaneRef,
    // To let the user pres the resizer to drag, and not close the Pane
    extraConditionHandler: onOutsideClickResizerConditionHandler,
  });

  return (
    <FunnelRightPane mainContainerRef={rightPaneRef} classNames={{ subContainer: 'overflow-auto' }}>
      <RightPaneHeader
        classNames={{ container: 'sticky top-0 z-20 bg-wmxBgDark-200' }}
        onCrossButtonClick={onRightPaneClose}
      >
        <VendorOverviewHeader />
      </RightPaneHeader>
      <div className="m-6 mt-2">{isSelectedDatapoint ? <DatapointRecordsContainer /> : <OverviewRightPane />}</div>
    </FunnelRightPane>
  );
}

function OverviewRightPane({ onRightPaneClose }) {
  const tableProps = {
    queryParams: { fetchPolicy: 'network-only' },
    initialQuery: ACTIVE_CAMPAIGN_DATAPOINTS_OVERVIEW.query,
    queryName: ACTIVE_CAMPAIGN_DATAPOINTS_OVERVIEW.queryName,
    onRightPaneClose,
    LoadingComponent: () => <LoadingTableComponent />,
  };

  return (
    <>
      <VendorOverviewSubtitle />
      <VendorOverviewDetails />
      <VendorOverviewTable {...tableProps} />
      <LastProvidersSyncCell />
      <LastDailyResyncCell />
      <ButtonsLayer />
    </>
  );
}

function LoadingTableComponent() {
  const LoadingComponent = useMetricLoading({
    metric: metricsKey.datapointsOverviewTable,
    shouldUpdateContextValue: true,
    renderComponent: (data) => {
      return data ? (
        <VendorOverviewTableWithoutHOC data={data} />
      ) : (
        <RowSkeletons rowsAmount={20} height={64} width="100%" foregroundColor="#1C2631" backgroundColor="#304254" />
      );
    },
  });

  return LoadingComponent;
}

VendorOverviewRightPane.propTypes = {
  rightPaneRef: PropTypes.any,
  onRightPaneClose: PropTypes.func,
  onOutsideClickResizerConditionHandler: PropTypes.func,
};

OverviewRightPane.propTypes = {
  onRightPaneClose: PropTypes.func,
};
