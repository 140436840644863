import PropTypes from 'prop-types';

export default function CellLayout({ children, onClick, classNames }) {
  const { textSize = 'text-sm', padding = 'p-0' } = classNames;

  return (
    <button
      type="button"
      onClick={onClick}
      className={`h-10 inline-flex justify-center items-center ${padding} ${textSize} text-white ${classNames} cursor-default`}
    >
      {children}
    </button>
  );
}

CellLayout.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  classNames: PropTypes.shape({
    textSize: PropTypes.string,
    padding: PropTypes.string,
  }),
};

CellLayout.defaultProps = {
  classNames: {},
};
