import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

export default function BoxSkeleton({ height, width, backgroundColor, foregroundColor, speed = 2 }) {
  return (
    <div className="flex flex-row relative">
      <ContentLoader
        speed={speed}
        width={width}
        height={height}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <rect x="0" y="0" rx="8" ry="8" width="100%" height="100%" />
      </ContentLoader>
    </div>
  );
}

BoxSkeleton.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  backgroundColor: PropTypes.string,
  foregroundColor: PropTypes.string,
  speed: PropTypes.number,
};

export function BoxSkeletons(props) {
  const { boxesAmount } = props;

  const boxes = [];

  for (let i = 0; i < boxesAmount; i += 1) {
    boxes.push(<BoxSkeleton key={i} {...props} />);
  }

  return boxes;
}
