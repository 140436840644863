import { useState, useCallback } from 'react';

export default function useCustomRef() {
  const [elementProps, setElementProps] = useState(null);

  const ref = useCallback((node) => {
    if (node !== null) {
      setElementProps({
        rect: node.getBoundingClientRect(),
        HTMLChildren: node.children,
      });
    }
  }, []);
  return [elementProps, ref];
}
