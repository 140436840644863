import { atom } from 'recoil';
import { initialLabel, initialFixedTimeRange } from 'src/components/Generic/DatePicker/getTimeRange';

const [initialStart, initialEnd] = initialFixedTimeRange;
export const initialTimeRangeAtom = { start: initialStart, end: initialEnd };

export const timeRange = atom({
  key: 'timeRange',
  default: {
    label: initialLabel,
    timeRange: initialTimeRangeAtom,
  },
});
