import PropTypes from 'prop-types';

import SegmentItemLeadsCount from 'src/components/SegmentItemLeadsCount/SegmentItemLeadsCount';
import GetFunnelItemNameByIdCell from 'src/components/Cells/GetFunnelItemNameByIdCell/GetFunnelItemNameByIdCell';
import { HSpacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';

export default function SegmentItem({
  name,
  nodeIdx,
  onRemoveElement,
  segmentId,
  itemNameInService = null,
  funnelItemIds = [],
  customTimeRange,
  peopleInTimeRange = null,
  condition,
  typeOfItem,
  itemsToQuery,
  onDialogOpen,
  onDialogClose,
  isDialogOpen,
  isTheOnlyOne,
  itemsCount,
  onClick,
  connection,
  isFirstItem, // firstItem of all the segment
  firstOfGroup, // first item of the OR group
  currentIsGroup, // if there is more than one item on the group
  withItemsCount = true,
}) {
  const onRemoveClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onRemoveElement(nodeIdx);
    onDialogClose();
    if (isTheOnlyOne) {
      onDialogOpen();
    }
  };

  const isPeopleInTimeRange = peopleInTimeRange !== null;

  const shouldShowConnector = () => {
    return !isFirstItem;
  };

  const connectorLineProps = {
    connection,
    firstOfGroup,
    currentIsGroup,
  };

  return (
    <div id={segmentId} className="SegmentItem-wrapper flex flex-col items-center justify-center">
      {shouldShowConnector() && <ConnectorLine {...connectorLineProps} />}

      <button
        onClick={() => onClick(segmentId)}
        disabled={isDialogOpen}
        type="button"
        className={`cursor-pointer pl-3 flex flex-row items-center justify-between  bg-wmxSecondary-200 rounded-xl relative ${
          withItemsCount ? 'p-2' : 'p-3'
        }`}
      >
        {condition === 'contains' && (
          <div className="text-white text-sm pl-2">
            <span className="font-bold">{itemsCount}</span> {typeOfItem}
            {itemsCount > 0 && 's'} containing <span className="font-bold pr-4">{name}</span>
          </div>
        )}
        {condition !== 'contains' && (
          <>
            <SegmentItemNameInSelectCondition
              itemIdArray={funnelItemIds}
              name={name}
              itemNameInService={itemNameInService}
              typeOfItem={typeOfItem}
              segementItemIdxInSegmentBuilder={nodeIdx}
            />
            {withItemsCount && itemsToQuery && <HSpacer />}
          </>
        )}
        {withItemsCount && itemsToQuery && (
          <div className="bg-wmxSecondary-300 p-2 rounded-xl">
            <span className="text-white text-sm">
              {isPeopleInTimeRange ? (
                <span>{peopleInTimeRange}</span>
              ) : (
                <SegmentItemLeadsCount customTimeRange={customTimeRange} itemsQuery={itemsToQuery} />
              )}
            </span>
          </div>
        )}

        {!itemsToQuery && <PlusButton />}
        {(nodeIdx !== 0 || itemsToQuery) && (
          <DeleteItem disabled={isDialogOpen} classNames="-right-6 absolute" onClick={onRemoveClick} />
        )}
      </button>
    </div>
  );
}

const ConnectorLine = ({ connection, firstOfGroup, currentIsGroup }) => {
  const shouldAddMargin = () => {
    return firstOfGroup && currentIsGroup;
  };

  return (
    <div className={`ConnectorLine flex flex-col items-center justify-center ${(shouldAddMargin() && 'mb-8') || ''}`}>
      <div className="topLine relative">
        <div className="h-7 w-0.5 bg-wmxPrimary-200 opacity-100 z-0" />
        <span
          style={{ left: '-0.5px' }}
          className="absolute left-0 right-0 m-auto top-0 h-1 w-1 transform scale-125 rounded-full bg-wmxPrimary-50 z-20"
        />
      </div>
      <span className="rounded-lg bg-wmxBgDark-300 px-2 py-1 text-xxs text-white">{connection}</span>
      <div className="bottomLine relative">
        <div className="h-7 w-0.5 bg-wmxPrimary-200 opacity-100 z-0" />
        <span
          style={{ left: '-0.5px' }}
          className="absolute left-0 right-0 m-auto bottom-0 h-1 w-1 transform scale-125 rounded-full bg-wmxPrimary-50 z-20"
        />
      </div>
    </div>
  );
};

const DeleteItem = ({ onClick, classNames, disabled }) => {
  return (
    <div className={`DeleteItem-Wrapper ${classNames}`}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        disabled={disabled}
        type="button"
        role="button"
        onClick={onClick}
        className="cursor-pointer block text-xs ml-auto text-wmxText-200 hover:text-red-400"
      >
        <i className="fas fa-minus-circle fa-fw fa-md" />
      </div>
    </div>
  );
};

function PlusButton({ className }) {
  return (
    <span
      className={`w-6 h-6 flex items-center justify-center rounded-full pointer-events-auto cursor-pointer bg-wmxPrimary-200 group-hover:bg-wmxPrimary-100 group-hover:shadow-md transition-all duration-250  ${className} `}
    >
      <span className="text-2xl px-3 plusIcon pointer-events-auto text-wmxText-200" />
    </span>
  );
}

function SegmentItemNameInSelectCondition({
  itemIdArray,
  name,
  itemNameInService,
  typeOfItem,
  segementItemIdxInSegmentBuilder,
}) {
  const renderSpan = (spanName) => {
    if (!spanName) return <></>;
    return <p className="text-white text-sm font-bold truncate ... max-w-xs">{spanName}</p>;
  };

  const cellProps = {
    itemNameInService,
    idsArray: itemIdArray,
    renderSpan,
    typeOfItem,
    segmentItemIdx: segementItemIdxInSegmentBuilder,
  };

  if (!name && itemIdArray.length) return <GetFunnelItemNameByIdCell {...cellProps} />;

  return renderSpan(name);
}
SegmentItem.propTypes = {
  condition: PropTypes.string,
  connection: PropTypes.string,
  currentIsGroup: PropTypes.bool,
  customTimeRange: PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string,
  }),
  firstOfGroup: PropTypes.bool,
  funnelItemIds: PropTypes.arrayOf(PropTypes.string),
  isDialogOpen: PropTypes.bool,
  isFirstItem: PropTypes.bool,
  isTheOnlyOne: PropTypes.bool,
  itemNameInService: PropTypes.string,
  itemsCount: PropTypes.number,
  itemsToQuery: PropTypes.array, // Funnel Items type of Array
  name: PropTypes.string,
  nodeIdx: PropTypes.number,
  onClick: PropTypes.func,
  onDialogClose: PropTypes.func,
  onDialogOpen: PropTypes.func,
  onRemoveElement: PropTypes.func,
  peopleInTimeRange: PropTypes.string, // Number turned to string
  segmentId: PropTypes.string,
  typeOfItem: PropTypes.string,
  withItemsCount: PropTypes.bool,
};

DeleteItem.propTypes = {
  onClick: PropTypes.func,
  classNames: PropTypes.string,
  disabled: PropTypes.bool,
};

PlusButton.propTypes = {
  className: PropTypes.string,
};

ConnectorLine.propTypes = {
  connection: PropTypes.string,
  firstOfGroup: PropTypes.bool,
  currentIsGroup: PropTypes.bool,
};
