const setThousandsComma = (num) => {
  const isNumber = num !== '' && num !== null && typeof num !== 'undefined';

  if (!isNumber) console.warn('A wrong number parameter has been provided to the function');
  return isNumber ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
};

const getCurrencySymbol = (currencyThreeLetters) => {
  const symbols = {
    eur: '€',
    usd: '$',
    gbp: '£',
    default: '$',
  };

  return symbols[currencyThreeLetters];
};

module.exports = {
  setThousandsComma,
  getCurrencySymbol,
};
