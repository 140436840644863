import useInfiniteScroll from 'src/customHooks/useInfiniteScroll';
import useCurrentUser from 'src/customHooks/useCurrentUser';
import { useStopOrderByLoading, useUpdatePeopleTableData } from 'src/layouts/PeopleLayout/peopleReportsProvider';

export const usePeopleTableProps = ({
  data,
  dataKey,
  fetchMore,
  classNames,
  queryParams,
  onOrderBy,
  title,
  getTableProps,
  isLoadingTable = false,
}) => {
  const scrollLength = queryParams?.variables?.limit;

  const {
    stripeIntegration: { isReady: isUserWithStripe },
  } = useCurrentUser();

  useStopOrderByLoading({ isLoadingTable });
  useUpdatePeopleTableData({ freshData: data });

  const { hasMoreData, dataChunk, fetchMoreData } = useInfiniteScroll({
    scrollLength,
    incomingData: {
      dataArray: data?.[dataKey],
      key: dataKey,
    },
    fetchMore,
    fetchMoreParams: queryParams,
  });

  const peopleTableProps = {
    data: dataChunk, // can be overwritten by getTableProps
    hasMoreData,
    setNextChunk: fetchMoreData,
    classNames,
  };

  const tableSettings = getTableProps({ dataChunk, onOrderBy, isUserWithStripe, title });

  const tableProps = {
    ...peopleTableProps,
    ...tableSettings,
  };

  return tableProps;
};
