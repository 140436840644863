/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
// import ReactSplitPane from 'react-split-pane';
import { useState, useEffect, useRef } from 'react';
import { Redirect, routes } from '@redwoodjs/router';
import ReactFlow, { Background, ReactFlowProvider } from 'react-flow-renderer';
// import 'src/css/splitPane.css';
import PropTypes from 'prop-types';

import { GET_FUNNEL_CONFIG_BY_ID } from 'src/components/Funnel/funnelQueriesAndMutations';
import useCurrentUser from 'src/customHooks/useCurrentUser';
import {
  useFunnelData,
  useMinorGlobalSquareModifiedByTimeRange,
  useUpdateSquaresIndexes,
  useTriggerFunnelFirstStepEvent,
} from 'src/components/Funnel/funnelHooks';
import { useTriggerPageSeenEvent, SEGMENT_TRACK_EVENTS_NAMES } from 'src/lib/segmentJuneEvents/segmentJuneEvents';

import FunnelPartBox from 'src/components/Funnel/FunnelPartBox/FunnelPartBox';
import FunnelTrackingPane from 'src/components/Funnel/FunnelTrackingPane/FunnelTrackingPane';
import FunnelPresentationView from 'src/components/Funnel/PresentationView/FunnelPresentationView/FunnelPresentationView';
import FunnelLayout from 'src/layouts/FunnelLayout/FunnelLayout';
import { LoadingComponent } from 'src/components/Generic';

export const funnelBuilderFlowContainerClass = 'funnelBuilderFlow';

export default function FunnelPage({ funnelId }) {
  const { isUserWithAc, nodeElements, funnelBlockTrackingProps, funnelPresentationViewQuery, isRightPaneOpen } =
    useFunnelPage({ funnelId });

  if (!isUserWithAc) {
    return <Redirect to={routes.onboardingPage({ slug: 'esp' })} />;
  }

  return (
    <>
      <FunnelLayout
        editView={
          <>
            <ReactFlowProvider>
              <Flow nodeElements={nodeElements} />
            </ReactFlowProvider>

            {(isRightPaneOpen && <FunnelTrackingPane {...funnelBlockTrackingProps} />) || <></>}
          </>
        }
        presentationView={<FunnelPresentationView funnelId={funnelId} {...funnelPresentationViewQuery} />}
        withFunnelId={!!funnelId}
      />
      {isRightPaneOpen && (
        <div
          className="overlayPane fixed w-full h-full top-0 left-0 cursor-not-allowed bg-transparent"
          style={{ zIndex: 99 }}
        />
      )}
    </>
  );
}

/* ------------------------------------------------------------------------------------------------
 * Page Bussiness Logic
 * -----------------------------------------------------------------------------------------------*/

function useFunnelPage({ funnelId }) {
  const { acIntegration: { isReady: isUserWithAc } = {} } = useCurrentUser();

  const [nodeElements, setNodeElements] = useState();
  const {
    currentFunnelPart,
    setCurrentFunnelPart,
    toggleAllFunnelParts,
    funnelData,
    currentSquare,
    isRightPaneOpen,
    rightPaneDefaultSize,
    onRightPaneOpen,
    onRightPaneClose: onPaneClose,
    onRightPaneDragStarted,
    onRightPaneDragFinished,
    rightPaneStyles,
    rightPaneRef,
    funnelPageSource,
  } = useFunnelData();

  const flowRef = useRef();
  const funnelDataRef = useRef(funnelData);

  useMinorGlobalSquareModifiedByTimeRange();
  useUpdateSquaresIndexes();
  useTriggerFunnelFirstStepEvent();
  useTriggerPageSeenEvent({ pageName: funnelPageSource === 'new' ? SEGMENT_TRACK_EVENTS_NAMES.newFunnelPage : null });

  const onRightPaneClose = () => {
    const beforeClose = () => toggleAllFunnelParts(funnelDataRef.current);
    onPaneClose({ beforeClose });
  };

  useEffect(() => {
    funnelDataRef.current = funnelData;
  }, [funnelData]);

  useEffect(() => {
    if (currentSquare?.isSelected) {
      onRightPaneOpen(true);
    }
  }, [currentSquare, onRightPaneOpen]);

  useEffect(() => {
    // reset current funnel part
    setCurrentFunnelPart('');
  }, [setCurrentFunnelPart]);

  const funnelPartProps = {
    onRightPaneOpen,
    numberOfPeople: '0',
    isRightPaneOpen,
    onRightPaneClose,
  };

  // const onRightPaneClickOutside = (event) => {
  //   if (!isRightPaneOpen) {
  //     event.stopPropagation();
  //     toggleAllFunnelParts(funnelDataRef.current);
  //     setCurrentFunnelPart('');
  //   }
  // };

  const funnelPartBoxContainerClass = 'funnelFlow-Wrapper';

  const funnelBlockTrackingProps = {
    funnelPartName: currentFunnelPart && currentFunnelPart.charAt(0).toUpperCase() + currentFunnelPart.slice(1),
    isSegment: false,
    funnelPartBoxContainerClass,
    onCrossButtonClick: onRightPaneClose,
    rightPaneRef,
    // onRightPaneClickOutside,
  };

  /* ------------------------------------------------------------------------------------------------
   * React Flow Elements Building
   * -----------------------------------------------------------------------------------------------*/
  const elementsStyles = {
    padding: '0',
    backgroundColor: 'transparent',
    width: 'auto',
    border: 'none',
  };

  useEffect(() => {
    const nodes = [
      {
        id: 'funnelWrapper',
        data: {
          label: (
            <div ref={flowRef} className={`${funnelPartBoxContainerClass} w-full flex flex-col relative`}>
              <FunnelPartBox funnelOrderId={0} funnelPartName="Top" {...funnelPartProps} />
              <FunnelPartBox funnelOrderId={1} funnelPartName="Middle" {...funnelPartProps} />
              <FunnelPartBox funnelOrderId={2} funnelPartName="Bottom" {...funnelPartProps} />
            </div>
          ),
        },
        position: {
          x: 150,
          y: 50,
        },
        style: elementsStyles,
        draggable: false,
        connectable: false,
      },
    ];
    setNodeElements(nodes);
  }, []);

  const queryParams = {
    fetchPolicy: 'cache-first',
    variables: {
      id: funnelId,
    },
  };

  const funnelPresentationViewQuery = {
    queryParams,
    /*
      funnelPageSource is null because in every other case with funnelId more than 'byUrl',
      the funnelPageSource is set previously
    */
    initialQuery: !!funnelId && !funnelPageSource ? GET_FUNNEL_CONFIG_BY_ID : null,
    LoadingComponent: () => (
      <div className="h-screen flex items-center bg-wmxBgDark-300 ">
        <LoadingComponent />
      </div>
    ),
  };

  return {
    isUserWithAc,
    nodeElements,
    rightPaneDefaultSize,
    onRightPaneDragStarted,
    onRightPaneDragFinished,
    rightPaneStyles,
    funnelBlockTrackingProps,
    funnelPresentationViewQuery,
    isRightPaneOpen,
    flowRef,
  };
}

/* ------------------------------------------------------------------------------------------------
 * Children Components
 * -----------------------------------------------------------------------------------------------*/

const Flow = ({ nodeElements }) => {
  return (
    <div
      className={`${funnelBuilderFlowContainerClass} bg-wmxBgDark-200 h-screen w-full flex flex-col overflow-hidden`}
    >
      <ReactFlow elements={nodeElements} className="z-10">
        <Background gap={24} size={0.5} color="#fff" />
      </ReactFlow>
    </div>
  );
};

FunnelPage.propTypes = {
  funnelId: PropTypes.string,
};

Flow.propTypes = {
  nodeElements: PropTypes.array,
};
