import { useMutation } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/toast';
import { Link, routes, navigate } from '@redwoodjs/router';

import { ApolloClient } from '@apollo/client';

const DELETE_ACCOUNT_MUTATION = gql`
  mutation DeleteAccountMutation($id: String!) {
    deleteAccount(id: $id) {
      id
    }
  }
`;

const jsonDisplay = (obj) => {
  return (
    <pre>
      <code>{JSON.stringify(obj, null, 2)}</code>
    </pre>
  );
};

const timeTag = (datetime) => {
  return (
    <time dateTime={datetime} title={datetime}>
      {new Date(datetime).toUTCString()}
    </time>
  );
};

const checkboxInputTag = (checked) => {
  return <input type="checkbox" checked={checked} disabled />;
};

const Account = ({ account }) => {
  const [deleteAccount] = useMutation(DELETE_ACCOUNT_MUTATION, {
    onCompleted: () => {
      toast.success('Account deleted');
      navigate(routes.accounts());
    },
  });

  const onDeleteClick = (id) => {
    if (confirm('Are you sure you want to delete account ' + id + '?')) {
      deleteAccount({ variables: { id } });
    }
  };

  return (
    <>
      <div className="rw-segment">
        <header className="rw-segment-header">
          <h2 className="rw-heading rw-heading-secondary">Account {account.id} Detail</h2>
        </header>
        <table className="rw-table">
          <tbody>
            <tr>
              <th>Id</th>
              <td>{account.id}</td>
            </tr>
            <tr>
              <th>Created at</th>
              <td>{timeTag(account.createdAt)}</td>
            </tr>
            <tr>
              <th>Modified at</th>
              <td>{timeTag(account.modifiedAt)}</td>
            </tr>
            <tr>
              <th>Admin user id</th>
              <td>{account.adminUserId}</td>
            </tr>
            <tr>
              <th>Status</th>
              <td>{account.status}</td>
            </tr>
            <tr>
              <th>Type</th>
              <td>{account.type}</td>
            </tr>
            <tr>
              <th>Trial start</th>
              <td>{timeTag(account.trialStart)}</td>
            </tr>
            <tr>
              <th>Trial end</th>
              <td>{timeTag(account.trialEnd)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <nav className="rw-button-group">
        <Link to={routes.editAccount({ id: account.id })} className="rw-button rw-button-blue">
          Edit
        </Link>
        <button type="button" className="rw-button rw-button-red" onClick={() => onDeleteClick(account.id)}>
          Delete
        </button>
      </nav>
    </>
  );
};

export default Account;
