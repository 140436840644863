import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useSegmentBuilder } from 'src/contexts/SegmentBuilder';
import { useDashboard } from 'src/contexts/Dashboard';
import useAccount from 'src/customHooks/useAccount';
import { METRICS_OPTIONS, SEGMENTS_FEATURE } from 'src/pages/DashboardPage/DashboardPage';
import {
  ScrollableArea,
  Card,
  Flex,
  HelperText,
  Spacer,
  HSpacer,
  Line,
} from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { LoadingComponent } from 'src/components/Generic';

import * as Popover from '@radix-ui/react-popover';

import { useMutation } from '@redwoodjs/web/node_modules/@apollo/client';
import { MetricSwitch, SegmentSwitch, SwitchEvents, SwitchTimerange } from '../CustomiseSwitches/CustomiseSwitches';
import { DELETE_DASHBOARD_MUTATION } from '../dashboardHelpers/dashboardQueries';

export const DashboardOptions = () => {
  const { loadingSegments } = useSegmentBuilder();
  const { metricsState, segmentsState, creatingNewDashboard, currentDashboard } = useDashboard();
  const [deleteClicked, setDeleteClicked] = useState(false);

  if (!METRICS_OPTIONS) return <></>;

  return (
    <div className="w-1/12">
      <DashboardOptionPopover
        trigger={
          <>
            <div className="px-4">
              <Spacer size="xs" />
              <HelperText>Customise</HelperText>
              <Spacer size="xs" />
            </div>
          </>
        }
      >
        <Flex items="start" justify="around" col>
          <Flex col items="start">
            <HelperText>Metrics</HelperText>
            <Spacer size="xs" />
            <Line />
            <Spacer />
            {metricsState?.map((metric, index) => (
              <MetricSwitch metric={metric} key={`metricSwitch-${metric}`} index={index} />
            ))}
            <SwitchEvents />
          </Flex>
          {SEGMENTS_FEATURE && <SegmentsOptions loadingSegments={loadingSegments} segmentsState={segmentsState} />}
          <HelperText>Settings</HelperText>
          <Spacer size="xs" />
          <Line />
          <Spacer />
          <SwitchTimerange />
          {currentDashboard?.defaultTimerange ? (
            <HelperText>Current: {currentDashboard?.defaultTimerange}</HelperText>
          ) : (
            <></>
          )}

          <Spacer size="md" />

          {!creatingNewDashboard && !currentDashboard?.isDefaultDashboard && (
            <>
              <Line />
              <Spacer size="md" />
              <div className="w-full flex flex-col justify-center items-center ">
                {!deleteClicked && <DeleteButton setDeleteClicked={setDeleteClicked} />}
                {deleteClicked && <ConfirmDeletion setDeleteClicked={setDeleteClicked} />}
              </div>
            </>
          )}
        </Flex>
      </DashboardOptionPopover>
    </div>
  );
};

export function DashboardOptionPopover({ children, trigger }) {
  const { setEditMode, setLayoutState, isCustomiseOpened, setIsCustomiseOpened } = useDashboard();

  function enableEditMode() {
    setLayoutState((previous) => {
      const newArr = [...previous];
      return newArr.map((el) => {
        return { ...el, isResizable: true };
      });
    });
    setEditMode(true);
    setIsCustomiseOpened(!isCustomiseOpened);
  }
  return (
    <Popover.Root modal className="noCloseRightPane" onOpenChange={enableEditMode} open={isCustomiseOpened}>
      <Popover.Trigger className="w-auto ml-auto noCloseRightPane">{trigger}</Popover.Trigger>
      <Popover.Content className="noCloseRightPane">
        <ScrollableArea height="700px">
          <Card classNames={{ container: 'noCloseRightPane' }} bg={100}>
            {children}
          </Card>
        </ScrollableArea>
        <Popover.Close className="fill-current text-wmxText-100" />
        <Popover.Arrow className="fill-current text-wmxBgDark-200" />
      </Popover.Content>
    </Popover.Root>
  );
}

DashboardOptionPopover.propTypes = {
  children: PropTypes.any,
  trigger: PropTypes.any,
};

function SegmentsOptions({ loadingSegments, segmentsState }) {
  return (
    <>
      <Spacer size="md" />
      <Flex col items="start">
        <HelperText>Segments</HelperText>
        <Spacer size="xs" />
        <Line />
        <Spacer size="sm" />
        {loadingSegments && <LoadingComponent />}
        {!loadingSegments &&
          segmentsState?.length > 0 &&
          segmentsState.map((segment, index) => <SegmentSwitch key={segment.id} segment={segment} index={index} />)}
        <Spacer size="md" />
      </Flex>
    </>
  );
}

SegmentsOptions.propTypes = {
  loadingSegments: PropTypes.any,
  segmentsState: PropTypes.any,
};

function ConfirmDeletion({ setDeleteClicked }) {
  const [deleting, setDeleting] = useState(false);

  return (
    <>
      {!deleting ? (
        <ConfirmationDeleteFlow setDeleting={setDeleting} setDeleteClicked={setDeleteClicked} />
      ) : (
        <LoadingComponent />
      )}
    </>
  );
}

ConfirmDeletion.propTypes = {
  setDeleteClicked: PropTypes.any,
};

function DeleteButton({ setDeleteClicked }) {
  return (
    <button
      onClick={() => {
        setDeleteClicked(true);
      }}
      type="button"
      className=" bg-wmxAlert-300 text-white w-12 rounded hover:bg-wmxAlert-200 w-24 h-8"
    >
      Delete
    </button>
  );
}

DeleteButton.propTypes = {
  setDeleteClicked: PropTypes.any,
};

function ConfirmationDeleteFlow({ setDeleting, setDeleteClicked }) {
  const { accountId } = useAccount();
  const { currentDashboard } = useDashboard();
  const [deleteDashboardMutation] = useMutation(DELETE_DASHBOARD_MUTATION);

  return (
    <>
      <p className="text-white">Are you sure ?</p>
      <Spacer />
      <div className="flex">
        <button
          type="button"
          className=" bg-wmxAlert-300 text-white w-12 rounded hover:bg-wmxAlert-200"
          onClick={() => {
            setDeleting(true);
            deleteDashboardMutation({
              variables: {
                accountId,
                id: currentDashboard.id,
              },
            }).then(() => {
              document.location.href = '/dashboard';
            });
          }}
        >
          YES
        </button>
        <HSpacer />
        <button
          type="button"
          className="hover:bg-white w-12 rounded hover:text-wmxPrimary-50"
          onClick={() => setDeleteClicked(false)}
        >
          NO
        </button>
      </div>
    </>
  );
}

ConfirmationDeleteFlow.propTypes = {
  setDeleteClicked: PropTypes.any,
  setDeleting: PropTypes.any,
};
