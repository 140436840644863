import { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { ORDER_COLUMN_DIRECTIONS } from 'wmx-shared-code/reports/emailsOverview';
import { ACCOUNT_ORDER_BY } from 'wmx-shared-code/accountGlobalVariables';

const AccountPageContext = createContext(null);

export function AccountPageProvider({ children }) {
  const initialOrderedColumn = ACCOUNT_ORDER_BY.createdAt;
  const [columnOrderBy, setColumnOrderBy] = useState(initialOrderedColumn);
  const [columnOrderByDirection, setColumnOrderByDirection] = useState(ORDER_COLUMN_DIRECTIONS.desc);

  const providerValue = {
    columnOrderBy,
    setColumnOrderBy,
    columnOrderByDirection,
    setColumnOrderByDirection,
  };

  return <AccountPageContext.Provider value={{ ...providerValue }}>{children}</AccountPageContext.Provider>;
}

AccountPageProvider.propTypes = {
  children: PropTypes.any,
};

export const useAccountPageContext = () => useContext(AccountPageContext);

export const orderByEnum = Object.values(ACCOUNT_ORDER_BY);
