const EVENT_TYPES = {
  tag_add: 'tag_add',
  tag_remove: 'tag_remove',
  open: 'open',
  subscribe: 'subscribe',
  payment: 'payment',
};

module.exports = {
  EVENT_TYPES,
};
