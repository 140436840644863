export const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      console.log('JSON.parse(savedValue', JSON.parse(savedValue));
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      console.log('newValue', newValue);
      localStorage.setItem(key, JSON.stringify(newValue));
    });
  };
