import dayjs from 'dayjs';
import isObject from 'lodash.isobject';
import useCurrentUser from './useCurrentUser';
import { isBrowser } from '@redwoodjs/prerender/browserUtils';

const loggedInAsValue = '{}';
const loggedInAsSetter = () => {};

export default function useLocalStorage(key) {
  const { loggedInAs } = useCurrentUser();

  const storedValueNew = getLocalStorageValue(key);

  if (loggedInAs) return [loggedInAsValue, loggedInAsSetter];
  return [storedValueNew, (valueToStore) => setLocalStorageValue(key, valueToStore)];
}

export function getLocalStorageValue(key, { isLoggedInAs } = {}) {
  if (isLoggedInAs) return loggedInAsValue;

  const storedValueWithoutParse = isBrowser ? window.localStorage.getItem(key) || loggedInAsValue : loggedInAsValue;
  const storedValueNew = JSON.parse(storedValueWithoutParse);

  return storedValueNew;
}

export function setLocalStorageValue(key, valueToStore, { isLoggedInAs } = {}) {
  if (isLoggedInAs) return loggedInAsSetter;

  try {
    const isValueToStoreAnObject = isObject(valueToStore);

    const valueToStoreWithTimestamp = {
      ...(isValueToStoreAnObject ? valueToStore : { value: valueToStore }),
      lastSession: dayjs(),
    };

    window.localStorage.setItem(key, JSON.stringify(valueToStoreWithTimestamp));
  } catch (error) {
    console.error(error);
  }
}
