import PropTypes from 'prop-types';
import { EVENT_TYPES } from 'wmx-shared-code/events';

import { getAmountWithCurrencySymbol } from 'src/lib/generic/currency';
import useCurrentUser from 'src/customHooks/useCurrentUser';
import { formatDatabaseRecords } from 'src/lib/handleTimezone';

import { HelperText, Flex, HSpacer } from 'src/components/Generic/LayoutUtils/LayoutUtils';
import { Badge } from 'src/components/Generic';

export const ROW_FORMAT_BY_EVENT = {
  [EVENT_TYPES.tag_add]: (props) => {
    return <TagsAddRow {...props} />;
  },
  [EVENT_TYPES.open]: (props) => {
    return <OpenRow {...props} />;
  },
  [EVENT_TYPES.subscribe]: (props) => {
    return <SubscribeRow {...props} />;
  },
  [EVENT_TYPES.payment]: (props) => {
    return <PaymentRow {...props} />;
  },
};

const EventRow = {
  Contact: EventContact,
  Date: EventDate,
  Label: EventLabel,
  ItemName: EventItemName,
  Root: EventRowRoot,
};

function EventRowRoot({ children }) {
  return (
    <HelperText secondary={false}>
      <Flex>{children}</Flex>
    </HelperText>
  );
}

function EventItemName({ children }) {
  return <span className=" text-wmxText-100 whitespace-nowrap ">{children}</span>;
}

function EventContact({ children }) {
  return <span className="text-wmxText-100 whitespace-nowrap ml-1">{children}</span>;
}

function EventDate({ timestamp }) {
  return (
    <Badge
      absolute={false}
      variant="gray"
      size="xs"
      className="text-xs py-1 px-1 rounded-sm bg-wmxHighlightDark-100 whitespace-nowrap flex items-center"
    >
      {formatDatabaseRecords({ date: timestamp, format: 'MM/DD HH:mm:ss' })}
    </Badge>
  );
}

function EventLabel({ bgColor, children }) {
  return <span className={`${bgColor} rounded-sm p-1 ml-2 whitespace-nowrap`}>{children}</span>;
}

function OpenRow({ timestamp, name, email }) {
  return (
    <EventRow.Root>
      <EventRow.Date timestamp={timestamp} />
      <EventRow.Label bgColor="bg-sky-700">Email opened</EventRow.Label>
      <HSpacer size="xs" />
      <EventRow.ItemName>{name}</EventRow.ItemName>
      <EventRow.Contact>by {email}</EventRow.Contact>
    </EventRow.Root>
  );
}

function SubscribeRow({ timestamp, name, email }) {
  return (
    <EventRow.Root>
      <EventRow.Date timestamp={timestamp} />
      <EventRow.Label bgColor="bg-purple-600">Lead subscribed</EventRow.Label>
      <HSpacer size="xs" />
      <EventRow.ItemName>{email}</EventRow.ItemName>
      <EventRow.Contact>to {name}</EventRow.Contact>
    </EventRow.Root>
  );
}

function PaymentRow({ timestamp, email, amount }) {
  const {
    defaultCurrency: { threeLetters: defaultCurrencyThreeLetters },
  } = useCurrentUser();

  return (
    <EventRow.Root>
      <EventRow.Date timestamp={timestamp} />
      <EventRow.Label bgColor="bg-green-600">Customer paid</EventRow.Label>
      <HSpacer size="xs" />
      <EventRow.ItemName>{email}</EventRow.ItemName>
      <EventRow.Contact>
        {getAmountWithCurrencySymbol({ currency: defaultCurrencyThreeLetters, amount: amount / 100 })}
      </EventRow.Contact>
    </EventRow.Root>
  );
}

function TagsAddRow({ timestamp, name, email }) {
  return (
    <EventRow.Root>
      <EventRow.Date timestamp={timestamp} />
      <EventRow.Label bgColor="bg-cyan-600">Tag added</EventRow.Label>
      <HSpacer size="xs" />
      <EventRow.ItemName>{name}</EventRow.ItemName>
      <EventRow.Contact>to {email}</EventRow.Contact>
    </EventRow.Root>
  );
}

const eventsTypeProps = {
  timestamp: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  amount: PropTypes.number,
};

EventRowRoot.propTypes = {
  children: PropTypes.any,
};

EventItemName.propTypes = {
  children: PropTypes.any,
};

EventContact.propTypes = {
  children: PropTypes.any,
};

EventDate.propTypes = {
  timestamp: PropTypes.string,
};

EventLabel.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.any,
};

OpenRow.propTypes = {
  ...eventsTypeProps,
};

SubscribeRow.propTypes = {
  ...eventsTypeProps,
};

PaymentRow.propTypes = {
  ...eventsTypeProps,
};

TagsAddRow.propTypes = {
  ...eventsTypeProps,
};
