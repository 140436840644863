import PropTypes from 'prop-types';
import PeopleSegmentMetricCell from 'src/components/PeopleSegmentMetricCell/PeopleSegmentMetricCell';
import GridLayout from 'react-grid-layout';
import useDashboardLayout from 'src/customHooks/useDashboardLayout';
import { useDashboard } from 'src/contexts/Dashboard';
import { SEGMENTS_FEATURE } from 'src/pages/DashboardPage/DashboardPage';
import { RecentActivityLayout } from 'src/components/Dashboard/DashboardBoxes/DashboardBoxes';
import RecentActivities from 'src/components/RecentActivities/RecentActivities';

function GridLayoutContainer({ layoutState, width }) {
  const { handleResizeRules, handleLayoutChange } = useDashboardLayout();
  const { timeRangeLabel, renderMetric, metricsState, segmentsState, editMode, timeRange, showEvents } = useDashboard();

  return (
    <GridLayout
      className="layout"
      layout={layoutState}
      rowHeight={130}
      cols={4}
      width={width}
      onLayoutChange={handleLayoutChange}
      onResize={handleResizeRules}
      draggableHandle=".draggable"
    >
      {metricsState.map((m) => {
        return renderMetric({
          key: m?.id,
          ...m,
          timeRange,
          timeRangeLabel,
          editMode,
        });
      })}
      {SEGMENTS_FEATURE &&
        segmentsState &&
        segmentsState.map((item) => {
          return (
            item?.dashboardSettings?.enabled && (
              <div key={item.id} className={`${editMode ? 'draggable' : 'disabled'}`}>
                <PeopleSegmentMetricCell key={item.id} segmentId={item.id} timeRange={timeRange} />
              </div>
            )
          );
        })}
      {showEvents && (
        <div key="eventsStream" className={`${editMode ? 'draggable' : 'disabled'}`}>
          <RecentActivityLayout>
            <RecentActivities />
          </RecentActivityLayout>
        </div>
      )}
    </GridLayout>
  );
}

GridLayoutContainer.propTypes = {
  layoutState: PropTypes.any,
  width: PropTypes.any,
};

export default GridLayoutContainer;
